import { request } from 'services/http/dataset/instance'

import type {
  CreateGenderControlSetRequest,
  GenderControlSet,
  GenderControlSetResponse,
  UpdateGenderControlSetRequest,
} from './types'

import {
  mapGenderControlSetForList,
  mapGenderControlSetForRequest,
  mapGenderControlSetForResponse,
} from './mappers'

export const create = (data: CreateGenderControlSetRequest) =>
  request<GenderControlSet>({
    method: 'post',
    url: 'gender-control-set',
    data,
    mappers: {
      req: mapGenderControlSetForRequest,
    },
  })

export const get = () =>
  request<GenderControlSetResponse>({
    method: 'get',
    url: 'gender-control-set',
    mappers: {
      res: mapGenderControlSetForList,
    },
  })

export const getById = (id: number) =>
  request<GenderControlSet>({
    method: 'get',
    url: `gender-control-set/${id}`,
    mappers: {
      res: mapGenderControlSetForResponse,
    },
  })

export const update = (data: UpdateGenderControlSetRequest) =>
  request<GenderControlSet>({
    method: 'put',
    url: `gender-control-set/${data.id}`,
    data,
    mappers: {
      req: mapGenderControlSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `gender-control-set/${id}`,
  })
