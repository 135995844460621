import React from 'react'
import DataBox from '@zera-admin/alice-data-box'
import EmptyState from '@zera-admin/empty-state'

import { EmailProps } from './types'

const Email: React.FunctionComponent<EmailProps> = ({ data }) => {
  return data && Object.keys(data).length > 0 ? (
    <DataBox
      values={[
        {
          text: `${data.firstname} ${data.lastname}`,
          title: 'İsim',
        },
        {
          text: data.email,
          title: 'E-Posta',
        },
        {
          text: data.gender === 'female' ? 'Kadın' : 'Erkek',
          title: 'Cinsiyet',
        },
        {
          text: data.phone,
          title: 'Telefon',
        },
        {
          text: data.city,
          title: 'Şehir',
        },
        {
          text: new Date(data.dob).toLocaleString(),
          title: 'Dob',
        },
        {
          text: data.site_url,
          title: 'Site URL',
        },
        {
          text: data.user_ip,
          title: 'IP',
        },
        {
          text: new Date(data.visit_date).toLocaleString(),
          title: 'Ziyaret tarihi',
        },
        {
          text: data.zip,
          title: 'Zip',
        },
        {
          text: data.state,
          title: 'Durum',
        },
      ]}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Email
