import styled from 'styled-components'

export const Google = styled.div`
  color: #000;
  font-size: 15px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  p {
    margin: 0;
    margin-bottom: 5px;
  }

  & > a:nth-child(1) {
    font-size: 11px;
    color: #000;
    text-decoration-line: none;
  }

  & > a:nth-child(2) {
    color: #1a0dab;
  }

  & > p:last-child {
    font-size: 13px;
  }
`
