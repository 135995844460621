import { BotConclusionEnum } from '../enums'
import { BotClassification, BotClassificationResponse } from './types'

export const mapBotClassificationForRequest = (
  raw: BotClassification
): unknown => ({
  id: raw.id,
  is_bot: raw.isBot === BotConclusionEnum.Bot ? true : false,
  account_entity_key: String(raw.accountEntityKey),
  source_id: raw.sourceId,
  entity_key: raw.entityKey,
  is_certain_update: raw.isCertainUpdate,
})

export const mapBotClassificationForResponse = (
  raw: any
): BotClassification => ({
  id: raw.data.id,
  accountCreatedDate: raw.data.account_created_date,
  currentVersion: raw.data.current_version,
  followedCount: raw.data.followed_count,
  followersCount: raw.data.followers_count,
  hasBio: raw.data.has_bio,
  hasProfilePicture: raw.data.has_profile_picture,
  isBot: raw.data.is_bot,
  lessTweetCount: raw.data.less_tweet_count,
  likeTweetCount: raw.data.like_tweet_count,
  maxSendingTweetTime: raw.data.max_sending_tweet_time,
  maxTweetLength: raw.data.max_tweet_length,
  minTweetLength: raw.data.min_tweet_length,
  personUserId: raw.person_user_id,
  sameRetweetAccountCount: raw.data.same_retweet_account_count,
  sourceId: raw.data.source_id,
  sourceName: raw.data.source_name,
  trainingGroupId: raw.data.training_group_id,
  trainingGroupName: raw.data.training_group_name,
  twitterUserName: raw.data.twitter_user_name,
  userId: raw.data.user_id,
})

export const mapBotClassificationForList = (
  raw: any
): BotClassificationResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapBotClassificationForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
