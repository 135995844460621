import styled from 'styled-components'

export const WidgetComponent = styled.div<{ theme: boolean }>`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background: ${(props) =>
    props.theme === 'dark' ? 'rgb(66, 66, 66)' : '#fff'};
`

export const WidgetVisualization = styled.div`
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
`

export const WidgetComponentHeader = styled.div`
  p {
    font-size: 0.85em;
    margin: 5px 0px;
  }

  button {
    display: flex;
    margin-left: auto;
  }
`

export const WidgetComponentEmpty = styled.div`
  padding: 30px 0px 20px 0px;

  & > div {
    margin: 0 auto;
    font-size: 0.8rem;

    img {
      max-height: 100px;
    }

    p {
      margin-bottom: 12px;
    }
  }
`
