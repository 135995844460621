import styled from 'styled-components'

export const NameValue = styled.div<{ theme: boolean }>`
  max-height: calc(50vh);
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 12px;
  width: 100%;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 20px;
  }
`
