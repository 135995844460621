import { RadioProps } from '@zera-admin/radio/dist/types/types'

export const streamLanguageOptions = [
  {
    label: 'Türkçe',
    value: 1,
  },
  {
    label: 'Yabancı',
    value: 2,
  },
]

export const databaseOptions = [
  { label: 'Neo4j', value: 'neo4j' },
  { label: 'Oracle', value: 'oracle' },
]

export const crawlingType: RadioProps[] = [
  {
    label: 'Stream',
    value: 'stream',
  },
  {
    label: 'Fetch',
    value: 'fetch',
  },
]

export const definitionType: RadioProps[] = [
  {
    label: 'Account',
    value: 'account',
  },
  {
    label: 'Keyword',
    value: 'keyword',
  },
  {
    label: 'Reply',
    value: 'reply',
  },
  {
    label: 'Favorite',
    value: 'favorite',
  },
  {
    label: 'User Favorite',
    value: 'user-favorite',
  },
  {
    label: 'Follower',
    value: 'follower',
  },
  {
    label: 'Friend',
    value: 'friend',
  },
]
