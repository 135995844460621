import { useEffect } from 'react'

import SectionDesigner from './SectionDesigner'

import { SectionProps } from './types'
import { useSchemaService } from 'app/library/layout-builder'

const Section: React.FunctionComponent<SectionProps> = (props) => {
  const schemaService = useSchemaService()

  useEffect(() => {
    if (props.designing && props.height === undefined) {
      schemaService.changeProperties(props.id as string, { height: 400 })
    }
  }, [])

  const style = props.designing
    ? {
        minHeight: '100px',
        padding: '0px',
      }
    : {
        minHeight: 'auto',
      }

  return (
    <div
      data-schema-type="section"
      data-schema-id={props.id}
      className={`fa-draggable fa-Col ${props.designing ? 'designing' : ''} ${
        props.defaultElement ? 'is-default' : ''
      }`}
      style={{ ...style, position: 'relative' }}
    >
      {props.designing ? (
        <SectionDesigner
          {...props}
          height={props.height !== undefined ? props.height : 400}
        />
      ) : (
        props.children
      )}
    </div>
  )
}

export default Section
