import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'

import { validation } from 'app/functions'
import { PartOfSpeech } from 'services/http/dataset/part-of-speech'

import { PartOfSpeechFormProps } from './types'

const validations = {
  longName: yup.string().required('Uzun kelime parçaları metni girmelisiniz'),
  name: yup.string().required('Kelime parçaları metni girmelisiniz'),
}

const PartOfSpeechForm: React.FunctionComponent<PartOfSpeechFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const form = useForm<PartOfSpeech>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = (props: FormHookSubmitProps<PartOfSpeech>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Geçerli bir Kelime parçası metni giriniz"
              errorMessage={form.errors.name}
              label="Kelime parçası metni"
              name="name"
              placeholder="Örnek metin"
              value={form.values.name}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Input
              description="Geçerli bir uzun Kelime parçası giriniz"
              errorMessage={form.errors.longName}
              label="Uzun Kelime parçası"
              name="longName"
              placeholder="Örnek: tr, en, vs.."
              value={form.values.longName}
              onChange={form.handleChange}
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default PartOfSpeechForm
