import React, { useEffect, useState } from 'react'

import { zpad } from 'app/functions/string'

import { CountDownProps } from './types'
import * as Styled from './CountDown.styled'

const CountDown: React.FunctionComponent<CountDownProps> = ({ onTimeout }) => {
  const [time, setTime] = useState<number>(120)

  useEffect(() => {
    const datetime = new Date(Date.now() + 1000 * 60 * 2).getTime()
    const interval = setInterval(() => {
      const now = new Date().getTime()
      const distance = datetime - now

      setTime((time) => time - 1)

      if (distance <= 0) {
        clearInterval(interval)
        onTimeout()
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [onTimeout])

  const getTime = () => {
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60

    return `${zpad(minutes)}:${zpad(seconds)}`
  }

  return (
    <Styled.CountDown>
      Kalan süre: <strong>{time !== 0 ? getTime() : 'Süre dolmuştur'}</strong>
    </Styled.CountDown>
  )
}

export default CountDown
