import { NamedEntityRecognition, NamedEntityRecognitionResponse } from './types'

export const mapNamedEntityRecognitionForRequest = (
  raw: NamedEntityRecognition
): unknown => ({
  language_code: raw.languageCode,
  source_id: raw.sourceId,
  text: raw.text,
  word_list: raw?.wordList?.map((word: any) => ({
    dependent: word.dependent,
    entity_type_id: word.entityTypeId,
    order: word.order,
    text: word.text,
  })),
})

export const mapNamedEntityRecognitionForResponse = (
  raw: any
): NamedEntityRecognition => ({
  id: raw.data.id,
  createdDate: raw.data.created_date,
  currentVersion: raw.data.current_version,
  languageCode: raw.data.language_code,
  sourceId: raw.data.source_id,
  sourceName: raw.data.source_name,
  text: raw.data.text,
  trainingGroupId: raw.data.training_group_id,
  trainingGroupName: raw.data.training_group_name,
  userId: raw.data.user_id,
  wordList: raw.data.word_list.map((word: any) => ({
    dependent: word.dependent,
    text: word.word_text,
    entityTypeId: word.entity_type_id,
    order: word.order,
  })),
})

export const mapNamedEntityRecognitionForList = (
  raw: any
): NamedEntityRecognitionResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapNamedEntityRecognitionForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
