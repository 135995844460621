import { COORDINATES } from 'components/google-maps/utils/constants'
import { QueryRunResponse } from 'services/http/bi-tool/query'

import {
  InfluentialMapValueProps,
  InfluentialMapVisualizationOptions,
} from '../types'

export const mapQueryForInfluentialMap = (
  raw: QueryRunResponse,
  options: InfluentialMapVisualizationOptions
): InfluentialMapValueProps[] =>
  (raw.rows || []).map((row) => ({
    country: row[options?.country as string],
    count: row[options?.count as string],
  }))

export const mapInfluentialMapProps = (values: InfluentialMapValueProps[]) =>
  values.map((value) => {
    const coordinate = COORDINATES.find(
      (coordinate) => coordinate.alpha3 === value.country
    ) || {
      latitude: 30,
      longitude: -35,
    }

    return {
      country: value.country,
      label: value.count?.toString(),
      position: {
        lat: coordinate?.latitude,
        lng: coordinate?.longitude,
      },
    }
  })
