import { lazy } from 'react'

import { RouteProps } from 'app/shared/types'
import ForgotPasssord from './forgot-password/ForgotPassord'
import AccountPassword from './account/password'
import AccountProfile from './account/profile'
import British from './british/British'
import Home from './home'
import SignIn from './membership/signin'
import SignOut from './membership/signout'
import VerifyAccount from './membership/verify-account'
import AgendaReportMobile from './agenda-report/mobile'
import AgendaReportStres from './agenda-report/stres'
import AgendaReportSecond from './agenda-report/secondreport'
import BotClassificationCreate from './bot-classification/CreateBotClassification'
import BotClassificationList from './bot-classification/BotClassificationList'
import BotClassificationUpdate from './bot-classification/UpdateBotClassification'
import BotControlSetCreate from './bot-control-set/CreateBotControlSet'
import BotControlSetList from './bot-control-set/BotControlSetList'
import BotControlSetUpdate from './bot-control-set/UpdateBotControlSet'
import BlackListCreate from './black-list/CreateBlackList'
import BlackListList from './black-list/BlackList'
import BlackListUpdate from './black-list/UpdateBlackList'
import ClassificationCategoryCreate from './classification-category/CreateClassificationCategory'
import ClassificationCategoryList from './classification-category/ClassificationCategoryList'
import ClassificationCategoryUpdate from './classification-category/UpdateClassificationCategory'
import ClassificationControlSetCreate from './classification-control-set/CreateClassificationControlSet'
import ClassificationControlSetList from './classification-control-set/ClassificationControlSetList'
import ClassificationControlSetUpdate from './classification-control-set/UpdateClassificationControlSet'
import ClassificationDataSetCreate from './classification-data-set/CreateClassificationDataSet'
import ClassificationDataSetList from './classification-data-set/ClassificationDataSetList'
import ClassificationDataSetUpdate from './classification-data-set/UpdateClassificationDataSet'
import DefinitionsCreate from './definition/CreateDefinition'
import DefinitionsList from './definition/DefinitionList'
import DefinitionsDetail from './definition/DetailDefinition'
import DefinitionsQuery from './definition/definition-query/CreateDefinitionQuery'
import DefinitionTagCreate from './definition-tag/CreateDefinitionTag'
import DefinitionTagList from './definition-tag/DefinitionTagList'
import DefinitionTagUpdate from './definition-tag/UpdateDefinitionTag'
import SocialDefinitionCreateTwitter from './definition/social-media/Twitter'
import SocialDefinitionCreateEksi from './definition/social-media/EksiSozluk'
import SocialDefinitionCreateInciSozluk from './definition/social-media/InciSozluk'
import SocialDefinitionCreateBooking from './definition/social-media/Booking'
import SocialDefinitionCreateGoogleTrends from './definition/social-media/GoogleTrends'
import SocialDefinitionCreateAjansPress from './definition/social-media/AjansPress'
import SocialDefinitionCreateReddit from './definition/social-media/Reddit'
import SocialDefinitionCreateInstagram from './definition/social-media/Instagram'
import SocialDefinitionCreateLinkedin from './definition/social-media/Linkedin'
import SocialDefinitionCreateQuora from './definition/social-media/Quora'
import SocialDefinitionCreateMemurlar from './definition/social-media/Memurlar'
import SocialDefinitionCreateMastodon from './definition/social-media/Mastodon'
import SocialDefinitionCreateNews from './definition/social-media/News'
import SocialDefinitionCreateTumblr from './definition/social-media/Tumblr'
import SocialDefinitionCreateVK from './definition/social-media/Vk'
import SocialDefinitionCreateYoutube from './definition/social-media/Youtube'
import SocialDefinitionCreateStarngage from './definition/social-media/StarnGage'
import SocialDefinitionCreateTiktok from './definition/social-media/Tiktok'
import SocialDefinitionCreateTripAdvisor from './definition/social-media/TripAdvisor'
import SocialDefinitionCreateUludag from './definition/social-media/UludagSozluk'
import SocialDefinitionCreateWebsite from './definition/social-media/Website'
import SocialDefinitionBluesky from './definition/social-media/Bluesky'
import SocialDefinitionQueryTwitter from './definition/definition-query/social-media/Twitter'
import EntityCategoryTypeCreate from './entity-category-type/CreateEntityCategoryType'
import EntityCategoryTypeList from './entity-category-type/EntityCategoryTypeList'
import EntityCategoryTypeUpdate from './entity-category-type/UpdateEntityCategoryType'
import EntityTypeCreate from './entity-type/CreateEntityType'
import EntityTypeList from './entity-type/EntityTypeList'
import EntityTypeUpdate from './entity-type/UpdateEntityType'
import GenderControlSetCreate from './gender-control-set/CreateGenderControlSet'
import GenderControlSetList from './gender-control-set/GenderControlSetList'
import GenderControlSetUpdate from './gender-control-set/UpdateGenderControlSet'
import GenderDataSetCreate from './gender-data-set/CreateGenderDataSet'
import GenderDataSetList from './gender-data-set/GenderDataSetList'
import GenderDataSetUpdate from './gender-data-set/UpdateGenderDataSet'
import NamedEntityRecognitionCreate from './named-entity-recognition/CreateNamedEntityRecognition'
import NamedEntityRecognitionList from './named-entity-recognition/NamedEntityRecognitionList'
import NamedEntityRecognitionUpdate from './named-entity-recognition/UpdateNamedEntityRecognition'
import NamedEntityControlSetCreate from './named-entity-control-set/CreateNamedEntityControlSet'
import NamedEntityControlSetList from './named-entity-control-set/NamedEntityControlSetList'
import NamedEntityControlSetUpdate from './named-entity-control-set/UpdateNamedEntityControlSet'
import PersonReportCreate from './person-report/CreatePersonReport'
import PersonReportDetail from './person-report/DetailPersonReport'
import PersonReportReport from './person-report/Report'
import PersonReportQue from './person-report/PersonReportQueList'
import PersonReportProcess from './person-report/ProcessPersonReport'
import PersonReportPdfReport from './person-report/PdfReport'
import PersonReportPdf from './person-report/Pdf'
import ProfileReportCreate from './profile-report/CreateProfileReport'
import ProfileReportDetail from './profile-report/DetailProfileReport'
import ProfileReportReport from './profile-report/Report'
import ProfileReportQue from './profile-report/ProfileReportQueList'
import ProfileReportProcess from './profile-report/ProcessProfileReport'
import ProfileReportPdfReport from './profile-report/PdfReport'
import ProfileReportPdf from './profile-report/Pdf'
import SentimentDataSetCreate from './sentiment-data-set/CreateSentimentDataSet'
import SentimentDataSetList from './sentiment-data-set/SentimentDataSetList'
import SentimentDataSetUpdate from './sentiment-data-set/UpdateSentimentDataSet'
import SentimentControlSetCreate from './sentiment-control-set/CreateSentimentControlSet'
import SentimentControlSetList from './sentiment-control-set/SentimentControlSetList'
import SentimentControlSetUpdate from './sentiment-control-set/UpdateSentimentControlSet'
import SourceCreate from './source/CreateSource'
import SourceList from './source/SourceList'
import SourceUpdate from './source/UpdateSource'
import PartOfSpeechCreate from './part-of-speech/CreatePartOfSpeech'
import PartOfSpeechList from './part-of-speech/PartOfSpeechList'
import PartOfSpeechUpdate from './part-of-speech/UpdatePartOfSpeech'
import TrainingGroupCreate from './training-group/CreateTrainingGroup'
import TrainingGroupList from './training-group/TrainingGroupList'
import TrainingGroupUpdate from './training-group/UpdateTrainingGroup'
import BIToolWidgetCreate from './bi-tool/widget/create-widget'
import BIToolWidgetList from './bi-tool/widget/widget-list'
import BIToolWidgetUpdate from './bi-tool/widget/update-widget'
import BIToolDashboardCreate from './bi-tool/dashboard/create-dashboard'
import BIToolDashboardList from './bi-tool/dashboard/dashboard-list'
import BIToolDashboardUpdate from './bi-tool/dashboard/update-dashboard'
import BIToolDashboardUpdateForSS from './bi-tool/dashboard/update-dashboard/index2'
import AppCreate from './identity-server/app/CreateApp'
import AppList from './identity-server/app/AppList'
import AppUpdate from './identity-server/app/UpdateApp'
import ClaimCreate from './identity-server/claim/CreateClaim'
import ClaimList from './identity-server/claim/ClaimList'
import ClaimUpdate from './identity-server/claim/UpdateClaim'
import UserCreate from './identity-server/user/CreateUser'
import UserList from './identity-server/user/UserList'
import UserUpdate from './identity-server/user/UpdateUser'
import RoleCreate from './identity-server/role/CreateRole'
import RoleList from './identity-server/role/RoleList'
import RoleUpdate from './identity-server/role/UpdateRole'
import InfluentialPeopleList from './influential-people/InfluentialUserList'
import NewsAlarmsList from './news-alarms/NewsAlarmsList'
import ManualAlarmCreate from './manual-alarm/ManualAlarm'
import NewsListList from './news-list/NewsList'
import NewsListUpdate from './news-list/UpdateNewsList'
import AlarmsCreate from './bi-tool/alarms/create-alarms/CreateAlarms'
import AlarmsDesigner from './bi-tool/alarms/alarm-designer/AlarmDesigner'
import AlarmsDetail from './bi-tool/alarms/detail'
import AlarmsList from './bi-tool/alarms/list'
import AlarmsManualAlarmCreate from './bi-tool/alarms/manual-alarm-create'
import AlarmCommonRuleCreate from './bi-tool/alarms/common-rule/CreateCommonRule'
import AlarmCommonRuleList from './bi-tool/alarms/common-rule/CommonRuleList'
import AgendaReportV4Create from './agenda-report-v4/create'
import AgendaReportV4Detail from './agenda-report-v4/detail'
import AgendaReportV4TaskDetail from './agenda-report-v4/task-detail'
import AgendaReportV4List from './agenda-report-v4/list'

const routes: RouteProps[] = [
  {
    component: Home,
    path: ['/', '/home', '/dashboard'],
  },
  {
    component: AccountProfile,
    path: '/account/profile',
  },
  {
    component: AccountPassword,
    path: '/account/password',
  },
  {
    component: British,
    path: '/british',
  },
  {
    component: SignIn,
    path: '/signin',
    auth: 'public',
  },
  {
    component: SignOut,
    path: ['/signout', '/signout/:uid'],
    auth: 'public',
  },
  {
    component: VerifyAccount,
    path: '/verify-account',
    auth: 'public',
  },
  {
    component: BIToolWidgetCreate,
    path: '/bi-tool/widget/create',
  },
  {
    component: BIToolWidgetUpdate,
    path: '/bi-tool/widget/update/:id',
  },
  {
    component: BIToolWidgetList,
    path: '/bi-tool/widgets',
  },
  {
    component: BIToolDashboardCreate,
    path: '/bi-tool/dashboard/create',
  },
  {
    component: BIToolDashboardUpdate,
    path: '/bi-tool/dashboard/update/:id',
  },
  {
    component: BIToolDashboardUpdateForSS,
    path: '/bi-tool/dashboard/updateForSS/:id',
  },
  {
    component: BIToolDashboardList,
    path: '/bi-tool/dashboards',
  },
  {
    component: AgendaReportMobile,
    path: '/agenda-report/mobile',
  },
  {
    component: AgendaReportStres,
    path: '/agenda-report/stres',
  },
  {
    component: AgendaReportSecond,
    path: '/agenda-report/secondreport',
  },
  {
    component: BotClassificationCreate,
    path: '/bot-classification/create',
  },
  {
    component: BotClassificationUpdate,
    path: '/bot-classification/update/:id',
  },
  {
    component: BotClassificationList,
    path: '/bot-classification',
  },
  {
    component: BotControlSetCreate,
    path: '/bot-control-set/create',
  },
  {
    component: BotControlSetUpdate,
    path: '/bot-control-set/update/:id',
  },
  {
    component: BotControlSetList,
    path: '/bot-control-set',
  },
  {
    component: BlackListCreate,
    path: '/black-list/create',
  },
  {
    component: BlackListUpdate,
    path: '/black-list/update/:id',
  },
  {
    component: BlackListList,
    path: '/black-list',
  },
  {
    component: ManualAlarmCreate,
    path: '/manual-alarm/Create',
  },
  {
    component: ClassificationCategoryCreate,
    path: '/classification-category/create',
  },
  {
    component: ClassificationCategoryUpdate,
    path: '/classification-category/update/:id',
  },
  {
    component: ClassificationCategoryList,
    path: '/classification-category',
  },
  {
    component: ClassificationControlSetCreate,
    path: '/classification-control-set/create',
  },
  {
    component: ClassificationControlSetUpdate,
    path: '/classification-control-set/update/:id',
  },
  {
    component: ClassificationControlSetList,
    path: '/classification-control-set',
  },
  {
    component: ClassificationDataSetCreate,
    path: '/classification-data-set/create',
  },
  {
    component: ClassificationDataSetUpdate,
    path: '/classification-data-set/update/:id',
  },
  {
    component: ClassificationDataSetList,
    path: '/classification-data-set',
  },
  {
    component: DefinitionsCreate,
    path: '/definition/create',
  },
  {
    component: DefinitionsList,
    path: '/definition',
  },
  {
    component: DefinitionsDetail,
    path: '/definition/detail/:id',
  },
  {
    component: DefinitionTagCreate,
    path: '/definition-tag/create',
  },
  {
    component: DefinitionTagList,
    path: '/definition-tag',
  },
  {
    component: DefinitionTagUpdate,
    path: '/definition-tag/update/:id',
  },
  {
    component: SocialDefinitionCreateTwitter,
    path: '/definition/create/x',
  },
  {
    component: SocialDefinitionCreateEksi,
    path: '/definition/create/eksi-sozluk',
  },
  {
    component: SocialDefinitionCreateInciSozluk,
    path: '/definition/create/inci-sozluk',
  },
  {
    component: SocialDefinitionBluesky,
    path: '/definition/create/bluesky',
  },
  {
    component: SocialDefinitionCreateBooking,
    path: '/definition/create/booking',
  },
  {
    component: SocialDefinitionCreateGoogleTrends,
    path: '/definition/create/google-trends',
  },

  {
    component: SocialDefinitionCreateAjansPress,
    path: '/definition/create/ajans-press',
  },
  {
    component: SocialDefinitionCreateReddit,
    path: '/definition/create/reddit',
  },
  {
    component: SocialDefinitionCreateInstagram,
    path: '/definition/create/instagram',
  },
  {
    component: SocialDefinitionCreateLinkedin,
    path: '/definition/create/linkedin',
  },
  {
    component: SocialDefinitionCreateQuora,
    path: '/definition/create/quora',
  },
  {
    component: SocialDefinitionCreateMemurlar,
    path: '/definition/create/memurlar',
  },
  {
    component: SocialDefinitionCreateMastodon,
    path: '/definition/create/mastodon',
  },
  {
    component: SocialDefinitionCreateNews,
    path: '/definition/create/news',
  },
  {
    component: SocialDefinitionCreateTumblr,
    path: '/definition/create/tumblr',
  },
  {
    component: SocialDefinitionCreateVK,
    path: '/definition/create/vk',
  },
  {
    component: SocialDefinitionCreateYoutube,
    path: '/definition/create/youtube',
  },
  {
    component: SocialDefinitionCreateStarngage,
    path: '/definition/create/starngage',
  },

  {
    component: SocialDefinitionCreateTiktok,
    path: '/definition/create/tiktok',
  },

  {
    component: SocialDefinitionCreateTripAdvisor,
    path: '/definition/create/tripadvisor',
  },

  {
    component: SocialDefinitionCreateUludag,
    path: '/definition/create/uludag-sozluk',
  },
  {
    component: SocialDefinitionCreateWebsite,
    path: '/definition/create/website',
  },
  {
    component: DefinitionsQuery,
    path: '/definition/query',
  },
  {
    component: SocialDefinitionQueryTwitter,
    path: '/definition/query/x',
  },
  {
    component: EntityCategoryTypeCreate,
    path: '/entity-category-type/create',
  },
  {
    component: EntityCategoryTypeUpdate,
    path: '/entity-category-type/update/:id',
  },
  {
    component: EntityCategoryTypeList,
    path: '/entity-category-type',
  },
  {
    component: EntityTypeCreate,
    path: '/entity-type/create',
  },
  {
    component: EntityTypeUpdate,
    path: '/entity-type/update/:id',
  },
  {
    component: EntityTypeList,
    path: '/entity-type',
  },
  {
    component: ForgotPasssord,
    path: '/forgot-password',
  },
  {
    component: GenderControlSetCreate,
    path: '/gender-control-set/create',
  },
  {
    component: GenderControlSetUpdate,
    path: '/gender-control-set/update/:id',
  },
  {
    component: GenderControlSetList,
    path: '/gender-control-set',
  },
  {
    component: GenderDataSetCreate,
    path: '/gender-data-set/create',
  },
  {
    component: GenderDataSetUpdate,
    path: '/gender-data-set/update/:id',
  },
  {
    component: GenderDataSetList,
    path: '/gender-data-set',
  },
  {
    component: NamedEntityRecognitionCreate,
    path: '/named-entity-recognition/create',
  },
  {
    component: NamedEntityRecognitionUpdate,
    path: '/named-entity-recognition/update/:id',
  },
  {
    component: NamedEntityRecognitionList,
    path: '/named-entity-recognition',
  },
  {
    component: NamedEntityControlSetCreate,
    path: '/named-entity-control-set/create',
  },
  {
    component: NamedEntityControlSetUpdate,
    path: '/named-entity-control-set/update/:id',
  },
  {
    component: NamedEntityControlSetList,
    path: '/named-entity-control-set',
  },
  {
    component: PersonReportReport,
    path: '/report/:id',
  },
  {
    component: PersonReportCreate,
    path: '/person-report/create',
  },
  {
    component: PersonReportDetail,
    path: '/person-report/detail/:id',
  },
  {
    component: PersonReportQue,
    path: '/person-report',
  },
  {
    component: PersonReportPdfReport,
    path: '/person-report/pdf-report/:id',
  },
  {
    component: PersonReportPdf,
    path: '/person-report/pdf',
  },
  {
    component: PersonReportProcess,
    path: '/person-report/process/detail/:id',
  },
  {
    component: SentimentDataSetCreate,
    path: '/sentiment-data-set/create',
  },
  {
    component: SentimentDataSetUpdate,
    path: '/sentiment-data-set/update/:id',
  },
  {
    component: SentimentDataSetList,
    path: '/sentiment-data-set',
  },
  {
    component: SentimentControlSetCreate,
    path: '/sentiment-control-set/create',
  },
  {
    component: SentimentControlSetUpdate,
    path: '/sentiment-control-set/update/:id',
  },
  {
    component: SentimentControlSetList,
    path: '/sentiment-control-set',
  },
  {
    component: ProfileReportReport,
    path: '/report/:id',
  },
  {
    component: ProfileReportCreate,
    path: '/profile-report/create',
  },
  {
    component: ProfileReportDetail,
    path: '/profile-report/detail/:id',
  },
  {
    component: ProfileReportQue,
    path: '/profile-report',
  },
  {
    component: ProfileReportPdfReport,
    path: '/profile-report/pdf-report/:id',
  },
  {
    component: ProfileReportPdf,
    path: '/profile-report/pdf',
  },
  {
    component: ProfileReportProcess,
    path: '/profile-report/process/detail/:id',
  },
  {
    component: PartOfSpeechCreate,
    path: '/part-of-speech/create',
  },
  {
    component: PartOfSpeechList,
    path: '/part-of-speech',
  },
  {
    component: PartOfSpeechUpdate,
    path: '/part-of-speech/update/:id',
  },
  {
    component: SourceCreate,
    path: '/source/create',
  },
  {
    component: SourceList,
    path: '/source',
  },
  {
    component: SourceUpdate,
    path: '/source/update/:id',
  },
  {
    component: TrainingGroupList,
    path: '/training-group',
  },
  {
    component: TrainingGroupCreate,
    path: '/training-group/create',
  },
  {
    component: TrainingGroupUpdate,
    path: '/training-group/update/:id',
  },
  {
    component: AppCreate,
    path: '/app/create',
  },
  {
    component: AppList,
    path: '/apps',
  },
  {
    component: AppUpdate,
    path: '/app/update/:id',
  },
  {
    component: ClaimCreate,
    path: '/claim/create',
  },
  {
    component: ClaimList,
    path: '/claims',
  },
  {
    component: ClaimUpdate,
    path: '/claim/update/:id',
  },
  {
    component: UserCreate,
    path: '/user/create',
  },
  {
    component: UserList,
    path: '/users',
  },
  {
    component: UserUpdate,
    path: '/user/update/:id',
  },
  {
    component: RoleCreate,
    path: '/role/create',
  },
  {
    component: RoleList,
    path: '/roles',
  },
  {
    component: RoleUpdate,
    path: '/role/update/:id',
  },
  { component: InfluentialPeopleList, path: '/influential-people' },
  {
    component: NewsListUpdate,
    path: '/news-list/update/:id',
  },
  {
    component: NewsListList,
    path: '/news-list',
  },
  {
    component: NewsAlarmsList,
    path: '/news-alarms',
  },
  {
    component: AlarmsManualAlarmCreate,
    path: '/manual-alarm-create',
  },
  {
    component: AlarmsList,
    path: '/alarms',
  },
  {
    component: AlarmsCreate,
    path: '/alarms/create',
  },
  {
    component: AlarmsDetail,
    path: '/alarms/detail/:id',
  },
  {
    component: AlarmCommonRuleList,
    path: '/alarms/common-rule',
  },
  {
    component: AlarmCommonRuleCreate,
    path: '/alarms/common-rule/create',
  },
  {
    component: AgendaReportV4Create,
    path: '/agenda-reportv4/create',
  },
  {
    component: AgendaReportV4Detail,
    path: '/agenda-reportv4/detail/:id',
  },
  {
    component: AgendaReportV4TaskDetail,
    path: '/agenda-reportv4/task-detail/:id',
  },
  {
    component: AgendaReportV4List,
    path: '/agenda-reportv4',
  },
]

export default routes
