import { QueryRunResponse } from 'services/http/bi-tool/query'

import {
  SocialNumbersValueProps,
  SocialNumbersVisualizationOptions,
} from '../types'
export const mapQueryForSocialNumbers = (
  raw: QueryRunResponse,
  options: SocialNumbersVisualizationOptions
): SocialNumbersValueProps[] =>
  (raw.rows || []).map((row) => ({
    totalTweetCount: row[options?.fields?.totalTweetCount as string],
    totalOriginTweetCount: row[options?.fields?.totalOriginalCount as string],
    totalRetweetCount: row[options?.fields?.totalRetweetCount as string],
    totalFollowersCountOfTweetedAccounts:
      row[options?.fields?.totalFollowersCountOfTweetedAccounts as string],
    uniqueAccountCount: row[options?.fields?.uniqueAccountCount as string],
    totalImpressionCount: row[options?.fields?.totalImpressionCount as string],
    youtubecount: row[options?.fields?.youtubecount as string],
    newspapercount: row[options?.fields?.newspapercount as string],
    instagramcount: row[options?.fields?.instagramcount as string],
    redditcount: row[options?.fields?.redditcount as string],
  }))
