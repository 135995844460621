import React, { useState } from 'react'
import * as yup from 'yup'
import { Column, Row } from '@zera-admin/page'
import Form, { useForm } from '@zera-admin/form'
import Modal from '@zera-admin/modal'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { languageCode, sentiment } from 'app/shared/constants'

import { SentimentDataSetProps } from '../types'
import { useFlagcardContext } from 'app/contexts/flagcard'
import sentimenDataSetsService, {
  CreateSentimentDataSetRequest,
} from 'services/http/dataset/sentiment-data-set'
import { validation } from 'app/functions'

const validations = {
  genderType: yup.string().required('Cinsiyet seçmelisiniz'),
}

const SentimentDataSet: React.FunctionComponent<SentimentDataSetProps> = ({
  onClose,
  values,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()

  const form = useForm<CreateSentimentDataSetRequest>({
    initialValues: values,
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = () => {
    setIsLoading(true)
    sentimenDataSetsService
      .create(form.values)
      .then(() => {
        flagcard.add({
          appearance: 'success',
          title: 'Kayıt işlemi başarılı',
          children:
            'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
        })
        onClose?.()
      })
      .catch((error) => {
        console.log(error)

        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <Modal
      actions={[
        {
          appearance: 'primary',
          text: 'Kaydet',
          onClick: () => handleSubmit(),
          isLoading: isLoading,
        },
        {
          appearance: 'subtle',
          text: 'Vazgeç',
          onClick: () => onClose,
        },
      ]}
      width="medium"
      onClose={onClose}
      title="Duygu veri seti eğitme"
    >
      <Form onSubmit={handleSubmit}>
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Select
              errorMessage={form.errors.semanticScore}
              label="Semantic seçiniz"
              name="semanticScore"
              value={sentiment.find(
                (item) => item.value === form.values.semanticScore
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'semanticScore',
                  (props as SelectOptionProps).value
                )
              }
              options={sentiment}
              position="fixed"
            />
          </Column>
          <Column>
            <Select
              errorMessage={form.errors.languageCode}
              label="Dil kodu"
              name="languageCode"
              value={languageCode.find(
                (item) => item.value === form.values.languageCode
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'languageCode',
                  (props as SelectOptionProps).value
                )
              }
              options={languageCode}
              position="fixed"
            />
          </Column>
        </Row>
      </Form>
    </Modal>
  )
}

export default SentimentDataSet
