import React, { useImperativeHandle, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Row } from '@zera-admin/page'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'
import WidgetInfluentialMap from 'widgets/influential-people-map'

import { getCriteriaInterval } from '../visualization/getters'
import * as Styled from './InfluentialMap.styled'
import {
  mapInfluentialMapProps,
  mapQueryForInfluentialMap,
} from './utils/mappers'
import {
  InfluentialMapProps,
  InfluentialMapValueProps,
  InfluentialMapVisualizationOptions,
} from './types'

const InfluentialMap: React.ForwardRefRenderFunction<
  WidgetHandlerRef<InfluentialMapVisualizationOptions>,
  InfluentialMapProps
> = (props, ref) => {
  const { theme, onClickRow, widget } = props

  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<InfluentialMapValueProps[]>([])
  const [error, setError] = useState<string>()

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const runQuery = (widget: Widget<InfluentialMapVisualizationOptions>) => {
    setIsLoading(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
      })
      .then((res) => {
        setValues(
          mapQueryForInfluentialMap(
            res.data as QueryRunResponse,
            widget.visualization?.options
          )
        )
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderInfluentialMap = () => {
    return isLoading ? (
      <Row xs={{ align: 'center', justify: 'center' }}>
        <Spinner className="spinner" size="medium" />
      </Row>
    ) : values?.length ? (
      <Styled.InfluentialMap>
        <WidgetInfluentialMap
          height={500}
          values={mapInfluentialMapProps(values)}
          theme={theme === 'light' ? 'silver' : 'dark'}
          onClick={(value) => {
            if (onClickRow)
              onClickRow(widget.visualization, widget.query, value)
          }}
        />
      </Styled.InfluentialMap>
    ) : (
      <Alert
        appearance={error ? 'error' : 'warning'}
        title="Etkin kişi haritası oluşturulamadı"
      >
        {error ||
          'Etkin kişi haritasını oluşturacak veri bulunamadı. Lütfen sorgularınızı ve harita ayarlarınızı kontrol ediniz.'}
      </Alert>
    )
  }

  return renderInfluentialMap()
}

export default React.forwardRef(InfluentialMap)
