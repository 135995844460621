import React, { useEffect, useState } from 'react'
import { uid } from '@zera-admin/utils'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { ValidationError } from 'app/shared/types'
import MainLayout from 'layouts/main'
import entityTypesService, {
  EntityType,
  UpdateEntityTypeRequest,
} from 'services/http/dataset/entity-type'

import { UpdateEntityTypeProps } from './types'
import EntityTypeForm from './EntityTypeForm'

const UpdateEntityType: React.FunctionComponent<UpdateEntityTypeProps> = ({
  match,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(true)
  const [entityType, setEntityType] = useState<EntityType>()
  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    const id = parseInt(match.params.id)

    entityTypesService
      .getById(id)
      .then((res) => {
        setEntityType(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsReady(false)
      })
  }, [match.params.id, history])

  const handleSubmit = (data: UpdateEntityTypeRequest) => {
    if (data) {
      setIsLoading(true)

      entityTypesService
        .update(data)
        .then(() => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: 'İşlem Başarılı',
            children:
              'Geçerli veri, sistem üzerinde başarıyla güncellenmiştir.',
          })
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Hata Oluştu',
            children:
              'Geçerli veri güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleError = (error?: ValidationError) => {
    console.log(error)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Varlık türü" href="/entity-type" />
      <BreadcrumbItem text="Varlık türü güncelle" />
    </Breadcrumb>
  )

  return (
    <MainLayout isLoading={isReady}>
      <Container width="medium">
        <Page>
          <PageHeader
            // actions={<Button appearance="error">Delete user</Button>}
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Aşağıdaki bilgileri doldurarak geçerli varlık türü
                güncelleyebilirsiniz.
              </p>
            }
          >
            Veri güncelleniyor: {entityType ? entityType.name : null}
          </PageHeader>
          <EntityTypeForm
            actions={
              <ButtonGroup>
                <Button
                  appearance="primary"
                  children="Güncelle"
                  isLoading={isLoading}
                  type="submit"
                />
                <LinkButton href="/entity-type">İptal</LinkButton>
              </ButtonGroup>
            }
            operation="update"
            onSubmit={handleSubmit}
            onError={handleError}
            values={entityType}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default UpdateEntityType
