import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { NewsVisualizationFields, NewsVisualizationOptions } from '../types'

export const generateNewsOptions = (
  options: NewsVisualizationOptions,
  query: Query,
  fields: Field[]
): NewsVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      fields: {
        ...result.fields,
        [group.field]: group.field as string,
      },
    }
  })

  return result
}

export const generateNewsVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as NewsVisualizationFields)

  return {
    type: 'news',
    options: {
      fields: {
        createdAt: options['createdAt'],
        image: options['image'],
        impressionCount: options['impressionCount'],
        interactionCount: options['interactionCount'],
        rank: options['rank'],
        text: options['text'],
        title: options['title'],
        source: options['source'],
        url: options['url'],
      },
    },
  }
}
