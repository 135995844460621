import React from 'react'
import { InfluentialPeople } from '../../types'
import * as Styled from './Users.styled'
export interface PeopleCard {
  people?: InfluentialPeople
}

const Users: React.FunctionComponent<PeopleCard> = ({ people }) => {
  return (
    <Styled.Container>
      <a href={`https://twitter.com/${people?.screenName}`}>
        <div className="news-logo-background">
          <img src={people?.userProfileBannerUrl} alt="" />
        </div>
        <div className="news-image-container">
          <div className="news-image">
            <img src={people?.profileImageUrl} alt="deneme" />
          </div>
        </div>
        <div className="news-content" style={{ backgroundColor: 'white' }}>
          <div className="news-content-title">
            <span className="news-title">{people?.name}</span>
            <span className="news-uri">@{people?.screenName}</span>
          </div>
          <div className="news-action-container">
            <div className="news-action-location">
              <span>
                <span>
                  {' '}
                  <i className="fa-solid fa-location-dot" />
                </span>
                <span>{people?.location || '-'}</span>
              </span>
            </div>
            <div className="news-action-social">
              <span className="news-action-title">
                Post Sayisi{' '}
                <span className="news-action-count">
                  {people?.statusesCountStr}
                </span>
              </span>
              <span className="news-action-title">
                Takip Edilen{' '}
                <span className="news-action-count">
                  {people?.followersCountStr}
                </span>
              </span>
              <span className="news-action-title">
                Takipçi{' '}
                <span className="news-action-count">
                  {people?.followersCountStr}
                </span>
              </span>
            </div>
          </div>
        </div>
      </a>
    </Styled.Container>
  )
}

export default Users
