import styled from 'styled-components'

export const Reddit = styled.div<{ isLoaded?: boolean; isRecent?: boolean }>`
  width: 100%;
  position: relative;
  transform-origin: center;
  opacity: 1;
  background-color: transparent;
  top: 0px;

  &.is-loaded {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
    transition: all, 0.75s;
    -moz-transition: all, 0.75s;
    -webkit-transition: all, 0.75s;
    top: 0;
  }
`

export const RedditLozenge = styled.span`
  position: absolute;
  right: 60px;
  top: 16px;
`

export const RedditList = styled.div`
  max-height: calc(50vh);
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 12px;
  transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -webkit-transition: all, 0.5s;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 20px;
  }
`
