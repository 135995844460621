import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import entityCategoryTypesService, {
  EntityCategoryType,
  EntityCategoryTypeResponse,
} from 'services/http/dataset/entity-category-type'

import { EntityCategoryTypeListProps } from './types'

const EntityCategoryTypeList: React.FunctionComponent<
  EntityCategoryTypeListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [entityCategoryType, setEntityCategoryType] =
    useState<EntityCategoryTypeResponse>()

  useEffect(() => {
    getEntityCategoryTypes()
  }, [])

  const getEntityCategoryTypes = async () => {
    try {
      const response = await entityCategoryTypesService.get()

      setEntityCategoryType(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/entity-category-type/create">
        Varlık kategori tipi oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Varlık Kategori tipi" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm varlık kategorisi türlerini görüntüleyebilir, yeni oluşturabilir,
        varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Varlık kategori türleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'name',
        text: 'Varlık kategori türü metni',
      },
      {
        name: 'parentName',
        text: 'Üst varlık kategori türü metni',
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: EntityCategoryType) => (
          <LinkButton
            appearance="subtle-link"
            href={`/entity-category-type/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (entityCategoryType) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={entityCategoryType.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={entityCategoryType.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default EntityCategoryTypeList
