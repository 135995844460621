import styled from 'styled-components'

export const ExecutiveSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ExecutiveSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`
