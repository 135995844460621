import styled from 'styled-components'

export const PieChart = styled.div<{ theme: boolean }>`
  width: 100%;
  margin: 0px auto;
  background: ${(props) =>
    props.theme === 'dark' ? 'rgb(66, 66, 66)' : 'transparent'};
  padding: ${(props) => (props.theme === 'dark' ? '2rem' : 'inherit')};
  box-sizing: border-box;
`
