import React, { useState, useEffect } from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import Icon from '@zera-admin/icon'
import { Row, Column } from '@zera-admin/page'

import { WidgetVisualization } from 'services/http/bi-tool/widget'
import { VisualizationDefinitions } from 'bi-tool/visualizations/visualization'

import * as Styled from './WidgetVisualTypes.styled'
import { WidgetVisualizationOptions, WidgetVisualTypesProps } from './types'

const WidgetVisualTypes: React.FunctionComponent<WidgetVisualTypesProps> = (
  props
) => {
  const [values, setValues] = useState(props.values)
  const [activeVisualization, setActiveVisualization] =
    useState<WidgetVisualization | null>()

  useEffect(() => {
    if (props.values) {
      setValues(props.values)
    }
  }, [props.values])

  const renderVisualizations = (
    visualizations: WidgetVisualizationOptions[]
  ) => (
    <Styled.VisualizationFields>
      <Row>
        {visualizations.map((visualization, index) => (
          <Column key={index} xs={{ size: 4 }}>
            <Styled.VisualizationField
              key={index}
              onClick={() => {
                setValues({
                  field: values.field,
                  type: visualization.type,
                  options:
                    values?.type === visualization.type ? values.options : {},
                })

                if (!visualization.custom) {
                  setActiveVisualization({
                    field: values.field,
                    type: visualization.type,
                    options:
                      values?.type === visualization.type ? values.options : {},
                  })
                }
              }}
              isSelected={values?.type === visualization.type}
            >
              {visualization.custom && (
                <Styled.VisualizationFieldBrand>
                  ÖZEL
                </Styled.VisualizationFieldBrand>
              )}
              <Icon name={visualization.icon} />
              {visualization.title}
            </Styled.VisualizationField>
          </Column>
        ))}
      </Row>
    </Styled.VisualizationFields>
  )

  const renderActiveVisualization = () => (
    <Styled.VisualizationOptions>
      <h4>
        <Button
          appearance="subtle"
          onClick={() => setActiveVisualization(null)}
        >
          <Icon name="chevron-left" />
        </Button>
        Araç ayarları
      </h4>
      <p>
        Aşağıdan seçmiş olduğunuz görünüm tipine özel ayarlandırmaları
        yapabilirsiniz.
      </p>
      {renderVisualizationDefinitions()}
    </Styled.VisualizationOptions>
  )

  const renderVisualizationDefinitions = () => {
    if (activeVisualization?.type) {
      return (
        <VisualizationDefinitions
          aggregations={props.aggregations}
          fields={props.fields}
          groups={props.groups}
          onChange={setValues}
          options={values?.options}
          visualization={activeVisualization}
        />
      )
    }
  }

  const renderCharts = () => {
    const visualizations: WidgetVisualizationOptions[] = [
      { type: 'time-series', title: 'Time series', icon: 'calendar' },
      { type: 'bar-chart', title: 'Bar chart', icon: 'graph-bar' },
      { type: 'area-chart', title: 'Area chart', icon: 'graph-line' },
      { type: 'pie-chart', title: 'Pie chart', icon: 'discover' },
      { type: 'radar-chart', title: 'Radar chart', icon: 'decision' },
      { type: 'stacked-chart', title: 'Stacked chart', icon: 'dashboard' },
    ]

    return (
      <React.Fragment>
        <h4>Chart görünümleri</h4>
        <p>
          Aşağıdan bir chart görünümü seçebilir, seçtiğiniz görünüme özel
          ayarlamaları tıklayarak yapabilirsiniz.
        </p>
        {renderVisualizations(visualizations)}
      </React.Fragment>
    )
  }

  const renderMaps = () => {
    const visualizations: WidgetVisualizationOptions[] = [
      {
        type: 'influential-map',
        title: 'Etkin Kişiler',
        icon: 'world',
      },
      {
        type: 'colored-map',
        title: 'Renklendirilmiş',
        icon: 'lightbulb',
      },
    ]

    return (
      <React.Fragment>
        <h4>Harita görünümleri</h4>
        <p>
          Aşağıdan bir harita görünümü seçebilir, seçtiğiniz görünüme özel
          ayarlamaları yapabilirsiniz.
        </p>
        {renderVisualizations(visualizations)}
      </React.Fragment>
    )
  }

  const renderOthers = () => {
    const visualizations: WidgetVisualizationOptions[] = [
      { type: 'counter', title: 'Sayaç', icon: 'tray' },
      { type: 'table', title: 'Tablo', icon: 'table' },
      { type: 'tweet', title: 'Tweet', icon: 'quote' },
      { type: 'reddit', title: 'Reddit', icon: 'mention' },
      { type: 'user', title: 'Kullanıcı', icon: 'people' },
      {
        type: 'bar',
        title: 'Bar',
        icon: 'backlog',
      },
      {
        type: 'name-value',
        title: 'Metin - Değer',
        icon: 'bullet-list',
      },
      { type: 'news', title: 'Haberler', icon: 'detail-view' },
      { type: 'hotel', title: 'Otel Kart', icon: 'detail-view' },
      { type: 'word-cloud', title: 'Kelime bulutu', icon: 'upload' },
      {
        type: 'graph-chart',
        title: 'Graph Chart',
        icon: 'dashboard',
      },
      {
        type: 'social-numbers',
        title: 'Social Numbers',
        icon: 'dashboard',
      },
    ]

    return (
      <React.Fragment>
        <h4>Diğer görünümler</h4>
        <p>
          Aşağıdan bir görünüm seçebilir, seçtiğiniz görünüme özel ayarlamaları
          yapabilirsiniz.
        </p>
        {renderVisualizations(visualizations)}
      </React.Fragment>
    )
  }

  const renderDrawerBody = () => {
    if (activeVisualization) {
      return renderActiveVisualization()
    } else {
      return (
        <Row xs={{ direction: 'column' }}>
          <Column>{renderCharts()}</Column>
          <Column>{renderMaps()}</Column>
          <Column>{renderOthers()}</Column>
        </Row>
      )
    }
  }

  const renderDrawer = () => {
    const actions = (
      <ButtonGroup>
        <Button
          appearance="primary"
          onClick={() => {
            props.onSave(values)
            props.onDismiss()
          }}
        >
          Ayarları uygula
        </Button>
        <Button onClick={props.onDismiss}>Kapat</Button>
      </ButtonGroup>
    )

    return (
      <Drawer
        actions={actions}
        isOpen={props.show}
        onClose={props.onDismiss}
        width="medium"
        title="Görünüm tipleri"
        description="Aşağıdan widgetin görüntüleneceği görünüm tiplerinden birini
        seçebilirsiniz. Seçtiğiniz görünüm tipine göre veri eşleştirmesi
        yapabilirsiniz."
        isBlock
      >
        {renderDrawerBody()}
      </Drawer>
    )
  }

  return renderDrawer()
}

export default WidgetVisualTypes
