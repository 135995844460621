import styled from 'styled-components'

export const Analysis = styled.div`
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #1da4ea;
  border-radius: 20px;
  padding: 1.6rem;
  margin: auto 1rem;
  word-break: break-word;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .analysis-title {
    font-family: normal normal bold 32px/42px Poppins;
    color: #2e2f31;
    font-size: 2rem;
  }

  .analysis-text {
    font-family: normal normal normal 18px/24px Poppins;
    color: #2e2f31;
    font-size: 1rem;
  }
`

export const AnalysisContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfluentialTweetAnalysis = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1da4ea;
  border-radius: 20px;
  height: 100%;
  min-height: 100px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  h1 {
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    color: #2e2f31;
    padding: 1rem 1rem 0 1.3rem;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    color: #2e2f31;
    max-height: 180px;
    padding: 0 1.3rem;
    margin-top: 0px;
  }
`
