import { GenderControlSet, GenderControlSetResponse } from './types'

export const mapGenderControlSetForRequest = (
  raw: GenderControlSet
): unknown => ({
  gender_control_set_id: raw.id,
  conclusion: raw.conclusion,
  language_code: raw.languageCode,
  text: raw.text,
})

export const mapGenderControlSetForResponse = (raw: any): GenderControlSet => ({
  id: raw.data.id,
  conclusion: raw.data.conclusion,
  currentVersion: raw.data.current_version,
  languageCode: raw.data.language_code,
  text: raw.data.text,
  userId: raw.data.user_id,
})

export const mapGenderControlSetForList = (
  raw: any
): GenderControlSetResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapGenderControlSetForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
