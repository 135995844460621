import styled from 'styled-components'

export const TableContainer = styled.div`
  width: 100%;
  .table-container {
    border-collapse: collapse;
    border: 1px solid gray;
    border-top: none;
    border-left: none;
    width: 100%;
    padding: 50;
    background-color: white;
    border-top-left-radius: 10;
    border-top-right-radius: 10;
    @media (width >= 2440px) {
      font-size: 22px;
    }
  }
`
