import { useState, useEffect } from 'react'
import { SearchboxOptionProps } from '@zera-admin/searchbox'

import entityCategoryTypeService from 'services/http/dataset/entity-category-type'

const useEntityCategoryType = (notSet?: any) => {
  const [entityCategoryTypes, setEntityCategoryTypes] = useState<
    SearchboxOptionProps[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getEntityCategoryTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getEntityCategoryType = (id?: number, name?: string) => {
    if (id !== null && id !== undefined) {
      return {
        label:
          (entityCategoryTypes.find(
            (entityCategoryTypes) => entityCategoryTypes.value === id
          )?.label as string) ||
          name ||
          '',
        value: id,
      }
    }
    return null
  }

  const getEntityCategoryTypes = async (value?: string) => {
    setIsLoading(true)

    const response = await entityCategoryTypeService.get(value)
    const mapped = [
      ...(notSet ? [notSet] : []),
      ...response.data?.list.map((entityCategoryType) => ({
        label: entityCategoryType.name,
        value: entityCategoryType.id as number,
      })),
    ]

    setEntityCategoryTypes(mapped)
    setIsLoading(false)

    return mapped
  }

  return {
    entityCategoryTypes,
    getEntityCategoryTypes,
    getEntityCategoryType,
    isLoading,
  }
}

export default useEntityCategoryType
