import styled from 'styled-components'
export const SocialCardContainer = styled.div`
  width: 200px !important;
  height: 160px !important;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;
  min-width: 180px;

  @media (width >= 1440px) {
    width: 160px !important;
    height: 140px !important;
  }
  @media (width >= 1200px) {
    min-width: 100px;
    width: 280px !important;
    height: 180px !important;
  }

  @media (width >= 1600px) {
    width: 180px !important;
    height: 170px !important;
  }
  @media (width >= 1800px) {
    width: 220px !important;
    height: 200px !important;
  }
  @media (width >= 2160px) {
    width: 260px !important;
    height: 230px !important;
  }
  @media (max-width: 1100px) {
    min-width: 130px;
    width: 160px;
  }
  @media (width >= 2440px) {
    width: 380px !important;
    height: 320px !important;
  }
  @media (width >= 2600px) {
    width: 620px !important;
    height: 400px !important;
  }

  .tweet-box-icon {
    font-size: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (width >= 2440px) {
      font-size: 4rem;
    }
    @media (width >= 2600px) {
      font-size: 7rem;
    }
  }
  .tweet-box-count {
    font-size: 1.1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (width >= 2440px) {
      font-size: 1.7rem;
    }
    @media (width >= 2600px) {
      font-size: 3rem;
    }
  }
  .tweet-box-title {
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (width >= 2440px) {
      font-size: 1.3rem;
    }
  }
`
