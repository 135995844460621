import {
  mapAuditForResponse,
  mapMetaForResponse,
} from 'services/http/identity-server/mappers'

import {
  RoleCreateRequest,
  RoleListRequest,
  RoleListResponse,
  RoleResponse,
  RoleUpdateRequest,
} from './types'

export const mapRoleForResponse = (raw: any): RoleResponse => ({
  id: raw.data.id,
  title: raw.data.title,
  name: raw.data.name,
  claimIds: (raw.data.claimList || []).map((claim: any) => claim.id),
  audit: mapAuditForResponse(raw.data),
})

export const mapRoleForCreateRequest = (raw: RoleCreateRequest): any => ({
  title: raw.title,
  name: raw.name,
  claimIds: raw.claimIds || [],
})

export const mapRoleForUpdateRequest = (raw: RoleUpdateRequest): any => ({
  id: raw.id,
  title: raw.title,
  name: raw.name,
  claimIds: raw.claimIds || [],
})

export const mapRoleListForRequest = (raw: RoleListRequest) => ({
  PageIndex: raw.pageIndex,
  PageSize: raw.pageSize,
})

export const mapRoleListForResponse = (raw: any): RoleListResponse => ({
  data: raw.data.map((role: any) => mapRoleForResponse({ data: role })),
  meta: mapMetaForResponse(raw),
})
