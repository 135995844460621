import React, { useState, useEffect } from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import { uid } from '@zera-admin/utils'
import { Row, Column } from '@zera-admin/page'
import Input from '@zera-admin/input'
import Textarea from '@zera-admin/textarea'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'

import { WidgetQueryType } from 'services/http/bi-tool/widget'
import fieldService, { Field, FieldRequest } from 'services/http/bi-tool/field'
import { usePopupContext } from 'app/contexts/popup'

import * as Styled from './WidgetSettings.styled'
import {
  WIDGET_QUERY_TYPES,
  WIDGET_CRITERIAS,
  WIDGET_VERSIONS,
} from './constants'
import { WidgetSettingsProps } from './types'

const WidgetSettings: React.FunctionComponent<WidgetSettingsProps> = ({
  fields,
  onChangeTable,
  onDelete,
  onDismiss,
  onSave,
  show,
  sources,
  tables,
  values,
}) => {
  const [settings, setSettings] = useState(values)
  const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>([])
  const [popup] = usePopupContext()
  useEffect(() => {
    if (values) {
      setSettings(values)
    }
  }, [values])

  useEffect(() => {
    if (fields) {
      handleChangeFields(fields)
    }
  }, [fields])

  const getFields = (request: FieldRequest) => {
    fieldService
      .get(request)
      .then((res) => {
        if (res.data) {
          handleChangeFields(res.data)
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getSourceOption = () => {
    if (!settings.query.source) return undefined

    const source = sources.find((source) => source.id === settings.query.source)

    return {
      value: settings.query.source,
      label: source?.name as string,
    }
  }

  const getTableOption = () => {
    if (!settings.query.table) return undefined

    const table = tables.find((table) => table.id === settings.query.table)

    return {
      value: settings.query.table,
      label: table?.name as string,
    }
  }

  const getFieldOption = () => {
    if (!settings.field)
      return {
        value: '',
        label: '',
      }
    return {
      value: settings.field.field,
      label: string.textify(settings.field.field),
    }
  }

  const getCriteriaOption = () => {
    if (settings?.criteria) {
      return WIDGET_CRITERIAS.find(
        (criteria) => criteria.value === settings.criteria
      )
    } else {
      return WIDGET_CRITERIAS.find((criteria) => !criteria.value)
    }
  }

  const getTypeOption = () => {
    if (settings?.type) {
      return WIDGET_QUERY_TYPES.find((type) => type.value === settings.type)
    } else {
      return { value: 'json', label: 'JSON' }
    }
  }

  const handleDelete = () => {
    const popupId = uid()

    popup.add({
      id: popupId,
      appearance: 'warning',
      title: 'Widget silinsin mi?',
      children:
        "Geçerli widget'i silmek istiyorsanız 'Onayla' butonuna basabilirsiniz",
      actions: [
        {
          text: 'Onayla',
          onClick: () => {
            popup.remove(popupId)
            onDelete()
          },
        },
        {
          text: 'İptal',
          onClick: () => popup.remove(popupId),
          appearance: 'subtle-link',
        },
      ],
      onClose: () => {
        popup.remove(popupId)
      },
    })
  }

  const handleChangeFields = (fields: Field[] = []) => {
    setFieldOptions(
      fields.map((field) => ({
        label: string.textify(field.field || ''),
        value: field.field,
      }))
    )
  }

  const handleChangeSource = (value: string) => {
    if (value.length <= 0) {
      setSettings({
        ...settings,
        field: undefined,
      })
    } else {
      const option = {
        label: value,
        value,
      }
      const index = fieldOptions.findIndex(
        (reference) => reference.value === parseInt(option.value)
      )
      let optionsInstance = [...fieldOptions]

      if (value && index === -1) {
        optionsInstance[0] = option
      } else {
        optionsInstance = fields.map((field) => ({
          label: string.textify(field.field || ''),
          value: field.field,
        }))
      }

      setFieldOptions(optionsInstance)
    }
  }

  const renderDrawerBody = () => (
    <Styled.WidgetSettingsField>
      <Row xs={{ direction: 'column' }}>
        <Column>
          <h4>Widgetin Kullanıldığı Dashboardlar</h4>
        </Column>
        <Column>
          {values?.dashboardKeys?.map((item: any) => (
            <p>{item.title}</p>
          ))}
        </Column>
        <Column>
          <Input
            name="name"
            label="Widget adı"
            placeholder="Yeni widget 1"
            value={settings.title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSettings({ ...settings, title: event.target.value })
            }
          />
        </Column>
        <Column>
          <Textarea
            name="description"
            label="Widget açıklaması"
            value={settings.description}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setSettings({ ...settings, description: event.target.value })
            }
          />
        </Column>
        <Column>
          <Select
            name="source"
            label="Veri kaynağı"
            placeholder="Veri kaynağı seçiniz"
            options={sources.map((source) => ({
              label: source.name,
              value: source.id,
            }))}
            value={getSourceOption()}
            onChange={(props) => {
              setSettings({
                ...settings,
                query: {
                  ...settings.query,
                  source: (props as SelectOptionProps).value as string,
                },
              })
            }}
          />
        </Column>
        <Column>
          <Select
            key="table"
            name="table"
            label="Veri tablosu"
            isDisabled={!settings.query.source}
            placeholder="Tablo seçiniz"
            options={tables.map((table) => ({
              label: table.name,
              value: table.id,
            }))}
            value={getTableOption()}
            onChange={(props) => {
              const table = (props as SelectOptionProps).value as string

              getFields({ datasource: settings.query.source, table })
              setSettings({
                ...settings,
                query: {
                  ...settings.query,
                  table,
                },
              })
            }}
          />
        </Column>
        <Column>
          <Select
            name="source"
            options={fieldOptions}
            onChange={(props) => {
              const field = fieldOptions.find(
                (prop) =>
                  prop.value === ((props as SelectOptionProps).value as string)
              )
              if (field) {
                setSettings({
                  ...settings,
                  field: {
                    type: 'string',
                    field: field.value.toString(),
                  },
                })
              }
            }}
            onInputChange={handleChangeSource}
            value={getFieldOption()}
            label="İşlenen değer (Opsiyonel)"
            placeholder="Seçim yapınız"
            description="Dashboard üzerinde filtrelerde kullanılacak değer alanını seçin"
          />
        </Column>
        <Column>
          <Select
            key="criteria"
            name="criteria"
            label="Widget tipi"
            placeholder="Tip seçiniz"
            options={WIDGET_CRITERIAS}
            value={getCriteriaOption()}
            onChange={(props) =>
              setSettings({
                ...settings,
                criteria: (props as SelectOptionProps).value as string,
              })
            }
          />
        </Column>
        <Column>
          <Select
            key="type"
            name="type"
            label="Sorgu tipi"
            placeholder="Tip seçiniz"
            options={WIDGET_QUERY_TYPES}
            value={getTypeOption()}
            onChange={(props) =>
              setSettings({
                ...settings,
                type: (props as SelectOptionProps).value as WidgetQueryType,
              })
            }
          />
        </Column>
        <Column>
          <Select
            key="version"
            name="version"
            label="Widget versionu"
            placeholder="Widget versiyonunu seçin"
            options={WIDGET_VERSIONS}
            value={WIDGET_VERSIONS.find(
              (version) => version.value === settings.version
            )}
            onChange={(props) => {
              const version = (props as SelectOptionProps).value as string

              setSettings({
                ...settings,
                version,
              })
            }}
          />
        </Column>
        {values.id && (
          <Column>
            <Button
              className="button-subtle--delete"
              appearance="subtle"
              onClick={handleDelete}
            >
              Widget'i sil
            </Button>
          </Column>
        )}
      </Row>
    </Styled.WidgetSettingsField>
  )

  const renderDrawer = () => {
    const actions = (
      <ButtonGroup>
        <Button
          appearance="primary"
          onClick={() => {
            onSave(settings)
            onDismiss()
          }}
        >
          Ayarları uygula
        </Button>
        <Button onClick={onDismiss}>Kapat</Button>
      </ButtonGroup>
    )

    return (
      <Drawer
        isOpen={show}
        onClose={onDismiss}
        width="medium"
        actions={actions}
        title="Widget ayarları"
        description="Aşağıdan widget üzerinde genel ayarlandırmaları yapabilirsiniz."
      >
        {renderDrawerBody()}
      </Drawer>
    )
  }

  return renderDrawer()
}

export default WidgetSettings
