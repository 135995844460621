import styled from 'styled-components'

export const Root = styled.div({
  position: 'fixed',
  top: 72,
  right: 16,
  maxWidth: '100%',
  maxHeight: 'calc(100% - 72px)',
  width: 425,
  height: 'auto',
  zIndex: 100,
  padding: 8,
  overflowX: 'hidden',
  boxSizing: 'border-box',
})
