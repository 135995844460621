import React, { useEffect, useState } from 'react'

import * as Styled from './WordCloud.styled'
import ReactWordcloud from 'react-wordcloud'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import { WordCloudProps } from './types'
import { WordCount } from '../../widgets/types'

const WordCloud: React.FC<WordCloudProps> = ({
  editMode,
  onChange,
  setData,
  data,
}) => {
  const [word, setWord] = useState<string | undefined>('')
  const [count, setCount] = useState<number | undefined>(0)
  const [deleteds, setDeleteds] = useState<string[]>([])
  const [newAdds, setNewAdds] = useState<WordCount[]>([])

  const handleDeleteWord = (word: any) => {
    const deletedWord = word.text
    const newDeleteds = [...deleteds, deletedWord]

    setDeleteds(newDeleteds)
  }

  useEffect(() => {
    console.log('deleteds:', deleteds)
    if (deleteds.length > 0) {
      const newData = data?.filter((item: WordCount) => {
        return !deleteds.includes(item.Word)
      })

      setData(newData)
    }
  }, [deleteds])

  const handleAddNewWord = () => {
    if (word && count) {
      const newWord = {
        Word: word,
        TotalCount: count,
      }

      setNewAdds([...newAdds, newWord])
      setWord('')
      setCount(0)
    }
  }

  useEffect(() => {
    console.log('newAdds:', newAdds)
    if (newAdds.length > 0) {
      const newData = data ? [...data, ...newAdds] : newAdds
      setData(newData)
    }
  }, [newAdds])

  return (
    <Styled.WordCloudContentContainer editmode={editMode}>
      <div>
        {editMode && (
          <div className="word-cloud-add">
            <div className="word-cloud-add-input">
              <div className="word-cloud-add-input-content">
                <label htmlFor="word">Kelime</label>
                <input
                  type="text"
                  id="word"
                  value={word}
                  onChange={(e) => setWord(e.target.value)}
                />
              </div>
              <div className="word-cloud-add-input-content">
                <label htmlFor="count">Değer</label>
                <input
                  type="number"
                  id="count"
                  value={count}
                  onChange={(e) => setCount(parseInt(e.target.value))}
                />
              </div>
              <div className="word-cloud-add-input-content">
                <button onClick={handleAddNewWord}>Kelime Ekle</button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="word-cloud-index">
        <ReactWordcloud
          options={{
            colors: [
              '#1f77b4',
              '#ff7f0e',
              '#2ca02c',
              '#d62728',
              '#9467bd',
              '#8c564b',
            ],
            enableTooltip: true,
            deterministic: false,
            fontFamily: 'impact',
            fontSizes: [15, 75],
            fontStyle: 'normal',
            fontWeight: 'normal',
            padding: 2,
            rotations: 2,
            rotationAngles: [0, 0],
            scale: 'sqrt',
            spiral: 'archimedean',
            transitionDuration: 1000,
          }}
          size={[800, 600]}
          callbacks={{
            onWordClick: handleDeleteWord,
          }}
          words={
            data?.map((item: WordCount) => ({
              text: item.Word,
              value: item.TotalCount,
            })) || []
          }
        />
      </div>
    </Styled.WordCloudContentContainer>
  )
}

export default WordCloud
