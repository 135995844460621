import { Column } from '@zera-admin/page'
import PdfPageLayout from './PdfPageLayout'
import {
  FollowersBotDetection,
  FollowersByCountry,
  Hashtag,
  LanguagesUsedByUser,
} from '../report-detail'

import { PdfPageProps } from './types'
import Icon from '@zera-admin/icon'
import { getMonthAndYear } from 'app/functions'

const PageTwo: React.FunctionComponent<PdfPageProps> = ({ personReport }) => {
  return (
    <PdfPageLayout personReport={personReport}>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              En Çok Kullandığı Hashtagler
            </h2>
            <Hashtag
              data={personReport.data?.result?.Statistic?.mostUsedHashtags.slice(
                0,
                11
              )}
            />
          </Column>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Takipçilerinin Bot Olma Olasılığı
            </h2>
            <FollowersBotDetection
              data={personReport.data?.result?.Statistic?.followersBotDetection}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 9 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Takipçilerinin Ülkelere Göre Dağılımı
            </h2>
            <FollowersByCountry
              data={personReport.data?.result?.Statistic?.followersByCountry}
            />
          </Column>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 10 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Kullanıcının kullandığı dillerin dağılımı
            </h2>

            <LanguagesUsedByUser
              data={personReport.data?.result?.Statistic?.languagesUsedByUser.slice(
                0,
                8
              )}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
    </PdfPageLayout>
  )
}
export default PageTwo
