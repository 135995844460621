import { GenderDataSet, GenderDataSetResponse } from './types'

export const mapGenderDataSetForRequest = (raw: GenderDataSet): unknown => ({
  account_entity_key: String(raw.accountEntityKey),
  source_id: raw.sourceId,
  gender_type: raw.genderType,
  entity_key: raw.entityKey,
  text: raw.text,
})

export const mapGenderByUserForRequest = (raw: GenderDataSet): unknown => ({
  account_entity_key: String(raw.accountEntityKey),
  source_id: raw.sourceId,
  gender_type: raw.genderType,
})

export const mapGenderDataSetForResponse = (raw: any): GenderDataSet => ({
  id: raw.data.id,
  createdDate: raw.data.created_date,
  currentVersion: raw.data.current_version,
  genderType: raw.data.gender_type,
  sourceId: raw.data.source_id,
  sourceName: raw.data.source_name,
  text: raw.data.text,
  trainingGroupId: raw.data.training_group_id,
  trainingGroupName: raw.data.training_group_name,
  userId: raw.data.user_id,
  entityKey: raw.data.entity_key,
  classificationCategoryId: raw.data.classification_category_id,
})

export const mapGenderDataSetForList = (raw: any): GenderDataSetResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapGenderDataSetForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
