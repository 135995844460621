import React, { useEffect, useState } from 'react'
import * as Styled from './UsersInfluencingSpread.styled'
import { UsersInfluencingSpreadProps } from './types'
import { IUsersInfluencingSpreadGroup } from '../../widgets/types'
import WidgetLayout from '../../widgets/widget-layout'
import UpdateButton from '../../components/update-button/UpdateButton'
import { formatNumber, numberFormat } from 'app/functions/number'
import heart from './assets/heart.png'
import barChart from './assets/bar-chart.png'
import retweet from './assets/retweet.png'
import speechBubble from './assets/speech-bubble.png'
import openLink from '../../components/tweet-card/images/open-link.svg'
const FCUsersInfluencingSpread: React.FC<UsersInfluencingSpreadProps> = ({
  date,
  data,
  title,
  isEditValue,
  setIsEditValue,
  pageNumber,
  metaData,
}) => {
  const [tableData, setTableData] = useState<
    IUsersInfluencingSpreadGroup[] | undefined
  >(data)
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
    text: string
  }>({ title: title, pageNumber: pageNumber, text: metaData?.Text || '' })

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setTableData(data)
      setPageData({
        title: title,
        pageNumber: pageNumber,
        text: metaData?.Text || '',
      })
    }
  }, [isEditValue, data, title, pageNumber, metaData?.Text])

  const onChangeGroup = (
    index: number,
    key: string,
    value: string,
    baseKey: string
  ) => {
    const tmpData: any = Object.assign(tableData || {}, {})
    let tmpValue = value

    if (key === 'FollowersCount') {
      tmpValue = value.replace(/\./g, '').replace(',', '.')
    }
    tmpData[index][baseKey][key] = tmpValue

    setTableData(tmpData)
  }

  const onChangeConnection = (
    index: number,
    key: string,
    value: string,
    baseIndex: number
  ) => {
    const tmpData: any = Object.assign(tableData || {}, {})
    let tmpValue = value

    if (key === 'FollowersCount') {
      tmpValue = value.replace(/\./g, '').replace(',', '.')
    }
    tmpData[baseIndex]['Connections'][index][key] = tmpValue

    setTableData(tmpData)
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  console.log(tableData, 'tableData')

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      {data ? (
        <Styled.TableContent>
          <div className="edit-btn-container">
            <UpdateButton
              {...editProps}
              contentData={{
                UsersInfluencingSpread: {
                  Groups: tableData,
                  Text: pageData?.text,
                },
              }}
              title={pageData?.title}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </div>

          <Styled.TableContainer>
            <>
              {tableData?.map((table, index) => (
                <div className="grid-cols-2" key={`data_${index}`}>
                  <div>
                    <Styled.UserTweetCard>
                      <div className="user-tweet-header">
                        <div
                          className="user-tweet-header-profile-des"
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              width: '55%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              className="user-tweet-header-profile-des-link"
                              style={{
                                color: 'white',
                              }}
                            >
                              @{table?.Tweet?.UserScreenName}
                            </span>
                          </div>
                          <div
                            style={{
                              color: 'white',
                            }}
                          >
                            <div>
                              <strong
                                className="renk"
                                contentEditable={editMode}
                                onBlur={(e) =>
                                  onChangeGroup(
                                    index,
                                    'FollowersCount',
                                    e.target.innerText,
                                    'User'
                                  )
                                }
                              >
                                {formatNumber(table.User.FollowersCount)}
                              </strong>{' '}
                              Takipçi
                            </div>
                            <div>
                              Grup:{' '}
                              <strong
                                className="renk"
                                contentEditable={editMode}
                                onBlur={(e) =>
                                  onChangeGroup(
                                    index,
                                    'GroupNo',
                                    e.target.innerText,
                                    'User'
                                  )
                                }
                              >
                                {table.User.GroupNo}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`user-tweet-body`}>
                        <div className="user-tweet-body-content">
                          <div className="renk">Tweet</div>
                          <p
                            className="user-tweet-body-post-text"
                            contentEditable={editMode}
                            onBlur={(e) =>
                              onChangeGroup(
                                index,
                                'Text',
                                e.target.innerText,
                                'Tweet'
                              )
                            }
                          >
                            {table?.Tweet?.Text.length > 180
                              ? `${table?.Tweet?.Text?.substring(0, 180)} ...`
                              : table?.Tweet?.Text}
                          </p>
                          <div className="connections">
                            <div className="renk">Connections</div>
                            <div className="connection-items">
                              {table.Connections.map((connect, cIndex) => (
                                <div className="connect-item">
                                  <span
                                    contentEditable={editMode}
                                    onBlur={(e) =>
                                      onChangeConnection(
                                        cIndex,
                                        'Username',
                                        e.target.innerText,
                                        index
                                      )
                                    }
                                  >
                                    {connect.Username}
                                  </span>{' '}
                                  <br />(
                                  <strong
                                    contentEditable={editMode}
                                    onBlur={(e) =>
                                      onChangeConnection(
                                        cIndex,
                                        'FollowersCount',
                                        e.target.innerText,
                                        index
                                      )
                                    }
                                  >
                                    {numberFormat(connect.FollowersCount)}
                                  </strong>
                                  )
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* {table?.Tweet?.MediaUrl && (
                            <div className="user-tweet-body-post-image">
                              <img
                                src={table?.Tweet?.MediaUrl || ''}
                                alt="Tweet Resmi"
                              />
                            </div>
                          )} */}
                        </div>
                      </div>
                      <div className="user-tweet-footer">
                        <div className="user-tweet-footer-statistics">
                          <span>
                            <p>
                              {formatNumber(table?.Tweet?.ImpressionCount) ||
                                '0'}
                            </p>
                            <img
                              src={barChart}
                              alt="view"
                              width={16}
                              height={16}
                            />
                          </span>
                          <span>
                            <p>{formatNumber(table.Tweet?.RetweetCount)}</p>
                            <img
                              src={retweet}
                              alt="retweet"
                              width={16}
                              height={16}
                            />
                          </span>
                          <span>
                            <p>{formatNumber(table.Tweet?.QuoteCount)}</p>
                            <img
                              src={speechBubble}
                              alt="speech-bubble"
                              width={16}
                              height={16}
                            />
                          </span>
                          <span>
                            <p>{formatNumber(table.Tweet?.FavoriteCount)}</p>{' '}
                            <img
                              src={heart}
                              alt="like"
                              width={16}
                              height={16}
                            />
                          </span>
                        </div>
                        <div className="user-tweet-footer-link">
                          <a
                            href={`https://twitter.com/${table.Tweet?.UserScreenName}/status/${table?.Tweet?.EntityKey}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Post &apos;u görüntülemek için tıklayınız{' '}
                            <img src={openLink} alt="Open link logo" />
                          </a>
                        </div>
                      </div>
                    </Styled.UserTweetCard>
                  </div>
                </div>
              ))}
            </>
          </Styled.TableContainer>
          {/* {pageData?.text && (
            <div
              className="tweet"
              contentEditable={editMode}
              onBlur={(e) => onChangePageData('text', e.target.innerText)}
            >
              {pageData?.text}
            </div>
          )} */}
        </Styled.TableContent>
      ) : (
        <h3>Veri Bulunamadi</h3>
      )}
    </WidgetLayout>
  )
}

export default FCUsersInfluencingSpread
