import React from 'react'
import { PageHeader } from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { LinkButton } from '@zera-admin/button'

const ReportPageHeader = () => {
  return (
    <PageHeader
      breadcrumbs={
        <Breadcrumb>
          <BreadcrumbItem text="Anasayfa" href="/" />
          <BreadcrumbItem text="Gündem Raporu V4 Listesi" />
        </Breadcrumb>
      }
      actions={
        <LinkButton appearance="primary" href="/agenda-reportv4/create">
          Gündem raporu oluştur
        </LinkButton>
      }
      bottomBar={
        <p>
          Tüm Gündem Raporu V4 'leri görüntüleyebilir, yeni raporlar
          oluşturabilirsiniz.
        </p>
      }
    >
      Gündem Raporu V4
    </PageHeader>
  )
}

export default ReportPageHeader
