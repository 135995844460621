import { QueryRunResponse } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { CounterValueProps, CounterVisualizationOptions } from '../types'

export const mapCounterProps = (
  values: QueryRunResponse,
  visualization: WidgetVisualization<CounterVisualizationOptions>
): CounterValueProps => {
  const { value, label, secondLabel } = visualization.options
  let result: CounterValueProps = {} as CounterValueProps

  if (values.rows.length) {
    result = {
      value: values.rows[0][value?.field] as number,
      prefix: value.prefix,
      suffix: value.suffix,
      label,
      secondValue: values.rows[1]
        ? (values.rows[1][value?.field] as number)
        : 0,
      secondPrefix: value.prefix,
      secondSuffix: value.suffix,
      secondLabel,
    }
  }

  return result
}
