import { NewsAlarms, NewsAlarmsListResponse } from './types'

export const mapNewsAlarmsListForResponse = (raw: any): NewsAlarms => ({
  title: raw.data.title,
  description: raw.data.description,
  publishedDate: raw.data.publishedDate,
  url: raw.data.url,
  content: raw.data.content,
  newsSourceName: raw.data.newsSourceName,
  imageUrl: raw.data.imageUrl,
  dataSource: raw.data.dataSource,
  sentiment: raw.data.sentiment,
  sentimentScore: raw.data.sentimentScore,
  id: raw.data.id,
})

export const mapNewsAlarmsListForList = (raw: any): NewsAlarmsListResponse => ({
  list: raw.data.map((item: unknown) =>
    mapNewsAlarmsListForResponse({ data: item })
  ),
  dataCount: raw.dataCount,
  pageCount: raw.pageCount,
})

export const mapNewsAlarmsListForRequest = (
  raw: any
): NewsAlarmsListResponse => ({
  list: raw.data.map((item: unknown) =>
    mapNewsAlarmsListForResponse({ data: item })
  ),
  dataCount: raw.dataCount,
  pageCount: raw.pageCount,
})
