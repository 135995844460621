import { colors } from '@atlaskit/theme'

import { MembershipLayoutProps } from './types'
import {
  MembershipContent,
  MembershipFlex,
  MembershipHeader,
  MembershipWindow,
  Board,
  Links,
} from './Membership.styled'

import AliceLogo from 'assets/images/logo.png'

const MembershipLayout: React.FunctionComponent<MembershipLayoutProps> = ({
  board,
  children,
  links,
}) => {
  return (
    <MembershipFlex>
      <MembershipHeader>
        <img src={AliceLogo} alt="Alice" />
      </MembershipHeader>
      <MembershipContent>
        <MembershipWindow>
          {children}
          {links && <Links>{links}</Links>}
        </MembershipWindow>
        {board && <Board>{board}</Board>}
      </MembershipContent>
      <style>{`body { background-color: ${colors.N20}; }`}</style>
    </MembershipFlex>
  )
}

export default MembershipLayout
