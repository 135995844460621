import { PageHeader } from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'

export const renderPageHeader = () => {
  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Eğitim grubu" />
    </Breadcrumb>
  )

  const bottomBar = (
    <p>
      Tüm eğitim gruplarını, yeni oluşturabilir, varolanları
      düzenleyebilirsiniz.
    </p>
  )

  return (
    <PageHeader bottomBar={bottomBar} breadcrumbs={breadcrumbs}>
      Eğitim grubu
    </PageHeader>
  )
}
