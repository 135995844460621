import {
  BlackListEnum,
  NewsListEnum,
  BotConclusionEnum,
  DataSetTypeEnum,
  DependentEnum,
  GenderTypeEnum,
  LanguageCode,
  RunningStatusEnum,
  SentimentEnum,
  StatusEnum,
} from 'services/http/dataset'
import { LoginType } from 'services/http/identity-server/enums'
import { ClaimType } from 'services/http/identity-server/claim/enums'
import { DataSourceEnum } from './enums'

export const botConclusion = [
  {
    value: BotConclusionEnum.Bot,
    label: 'Bot',
  },
  {
    value: BotConclusionEnum.NotBot,
    label: 'Bot Değil',
  },
]

export const dataSetType = [
  {
    value: DataSetTypeEnum.BotClassification,
    label: 'Bot sınıflandırması',
  },
  {
    value: DataSetTypeEnum.ClassificationDataSet,
    label: 'Sınıflandırma veri seti',
  },
  {
    value: DataSetTypeEnum.GenderDataSet,
    label: 'Cinsiyet data seti',
  },
  {
    value: DataSetTypeEnum.NamedEntityRecognition,
    label: 'İsimlendirilmiş varlık tanıma seti',
  },
  {
    value: DataSetTypeEnum.SentimentDataSet,
    label: 'Duygu veri seti',
  },
]

export const genderType = [
  {
    value: GenderTypeEnum.Female,
    label: 'Erkek',
  },
  {
    value: GenderTypeEnum.Male,
    label: 'Kadın',
  },
  {
    value: GenderTypeEnum.Unknown,
    label: 'Belirtilmemiş',
  },
]

export const runnigStatus = [
  {
    value: RunningStatusEnum.TrainingEnded,
    label: 'Çalışma Bitti',
  },
  {
    value: RunningStatusEnum.TrainingError,
    label: 'Başarısız',
  },
  {
    value: RunningStatusEnum.Pending,
    label: 'Onay Bekliyor',
  },
  {
    value: RunningStatusEnum.Processing,
    label: 'İşleniyor',
  },
]

export const sentiment = [
  {
    value: SentimentEnum.Positive,
    label: 'Pozitif',
  },
  {
    value: SentimentEnum.Negative,
    label: 'Negatif',
  },
  {
    value: SentimentEnum.Neutral,
    label: 'Nötr',
  },
]

export const languageCode = [
  {
    value: LanguageCode.En,
    label: 'en',
  },
  {
    value: LanguageCode.Fs,
    label: 'fs',
  },
  {
    value: LanguageCode.Tr,
    label: 'tr',
  },
]

export const status = [
  {
    value: StatusEnum.Deleted,
    label: 'Silindi',
  },
  {
    value: StatusEnum.OldVersion,
    label: 'Eski versiyon',
  },
  {
    value: StatusEnum.OnGoingTrain,
    label: 'Onay Bekliyor',
  },
  {
    value: StatusEnum.Published,
    label: 'Yayınlandı',
  },
]

export const dependent = [
  {
    value: DependentEnum.Left,
    label: 'Sol',
  },
  {
    value: DependentEnum.Right,
    label: 'Sağ',
  },
]

export const DASHBOARD_TYPES = [
  {
    value: '',
    label: 'Varsayılan',
  },
  {
    value: 'user',
    label: 'Kullanıcı Dashboardu',
  },
  {
    value: 'tweet',
    label: 'Tweet Dashboardu',
  },
  {
    value: 'brandwatch',
    label: 'Brandwatch Dashboardu',
  },
  {
    value: 'reddit',
    label: 'Reddit Dashboardu',
  },
]

export const LOGIN_TYPES = [
  {
    value: LoginType.OnlyPassword,
    label: 'Sadece şifre',
  },
  {
    value: LoginType.TwoFactorWithMail,
    label: 'E-posta ile iki adımlı doğrulama',
  },
  {
    value: LoginType.TwoFactorWithSms,
    label: 'SMS ile iki adımlı doğrulama',
  },
]

export const CLAIM_TYPES = [
  {
    value: ClaimType.Api,
    label: 'API',
  },
  {
    value: ClaimType.Page,
    label: 'Sayfa',
  },
  {
    value: ClaimType.View,
    label: 'Diğer',
  },
]

export const CLAIM_FILTER_TYPES = [
  {
    value: '',
    label: 'Tüm tipler',
  },
  {
    value: ClaimType.Api,
    label: 'API',
  },
  {
    value: ClaimType.Page,
    label: 'Sayfa',
  },
  {
    value: ClaimType.View,
    label: 'Diğer',
  },
]

export const CLAIM_METHODS = [
  {
    value: 'get',
    label: 'GET',
  },
  {
    value: 'post',
    label: 'POST',
  },
  {
    value: 'put',
    label: 'PUT',
  },
  {
    value: 'patch',
    label: 'PATCH',
  },
  {
    value: 'delete',
    label: 'DELETE',
  },
]

export const blackList = [
  {
    value: BlackListEnum.User,
    label: 'Kullanıcı',
  },
  {
    value: BlackListEnum.Word,
    label: 'Kelime',
  },
]
export const newsList = [
  {
    value: NewsListEnum.User,
    label: 'Kullanıcı',
  },
  {
    value: NewsListEnum.Word,
    label: 'Kelime',
  },
]

export const dataSource = [
  {
    value: DataSourceEnum.Twitter,
    label: 'Twitter',
  },
  {
    value: DataSourceEnum.Reddit,
    label: 'Reddit',
  },
]

export const globalPageSize = [
  {
    label: '8',
    value: 8,
  },
  {
    label: '16',
    value: 16,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '32',
    value: 32,
  },
  {
    label: '64',
    value: 64,
  },
  {
    label: '128',
    value: 128,
  },
]
