import React, { useEffect, useState } from 'react'
import { MultiDirectedGraph } from 'graphology'

import {
  SigmaContainer,
  useLoadGraph,
  ControlsContainer,
  ZoomControl,
  FullScreenControl,
  SearchControl,
  useRegisterEvents,
} from '@react-sigma/core'
import getNodeProgramImage from 'sigma/rendering/webgl/programs/node.image'
import '@react-sigma/core/lib/react-sigma.min.css'
import type { GraphChartProps } from './types'
import dataList from './AKMV8.json'

export const LoadGraph = (item: any) => {
  const loadGraph = useLoadGraph()
  const registerEvents = useRegisterEvents()

  const [data, setData] = useState<any>(dataList)

  useEffect(() => {
    registerEvents({
      clickNode: (event: any) => item.onPress(event),
    })
  }, [registerEvents])
  useEffect(() => {
    const graph = new MultiDirectedGraph()

    data.nodes.forEach(
      (x: {
        country: string
        id: number
        image: string
        label: string
        size: number
        x: number
        y: number
        color: string
      }) =>
        graph.addNode(x.id, {
          x: x.x,
          y: x.y,
          image: x.size > 2 ? x.image : '',
          label: x.label,
          size: x.size,
          color: x.color,
        })
    )
    data.edges.forEach(
      (x: {
        id: number
        source: number
        target: number
        size: number
        time_start: string
        color: string
        text: string
        link: string
      }) => {
        graph.addEdgeWithKey(x.id, x.source, x.target, {
          label: x.text,
          color: x.color,
        })
      }
    )

    loadGraph(graph)
  }, [loadGraph])

  return null
}

const GraphChart: React.FunctionComponent<GraphChartProps> = (item: any) => {
  const renderContent = () => {
    return (
      <SigmaContainer
        settings={{
          nodeProgramClasses: { image: getNodeProgramImage() },
          defaultNodeType: 'image',
          defaultEdgeType: 'arrow',
          labelDensity: 0.07,
          labelGridCellSize: 60,
          labelRenderedSizeThreshold: 15,
          labelFont: 'Lato, sans-serif',
          zIndex: true,
        }}
        graph={MultiDirectedGraph}
        style={{ height: '500px', width: '90%' }}
      >
        <ControlsContainer position={'bottom-right'}>
          <ZoomControl />
          <FullScreenControl />
        </ControlsContainer>
        <ControlsContainer position={'top-right'}>
          <SearchControl style={{}} />
        </ControlsContainer>
        <LoadGraph onPress={(data: any) => item.nodePress(Number(data.node))} />
      </SigmaContainer>
    )
  }

  return <>{renderContent()}</>
}

export default GraphChart
