import { request } from 'services/http/backoffice/instance'

import type { CreateParams, ITelegramBotListenerMessageResponse } from './types'
import { mapCommonRuleListForList } from './mappers'

// import { mapBlackListForList } from './mappers'

export const get = (pageIndex?: number, pageSize?: number) =>
  request<ITelegramBotListenerMessageResponse>({
    method: 'get',
    url: 'back-office/common-rule/telegram-bot-listener-messages',
    params: {
      PageIndex: pageIndex,
      PageSize: pageSize,
      StartDate: '1990-10-10',
    },
    mappers: {
      res: mapCommonRuleListForList,
    },
  })

export const create = (params: CreateParams) =>
  request<any>({
    method: 'post',
    url: 'back-office/common-rule/add-user-to-bot',
    data: params,
    // mappers: {
    //   req: mapBlackListForRequest,
    // },
  })
