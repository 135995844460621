import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button, { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Icon from '@zera-admin/icon'
import Form, { useForm } from '@zera-admin/form'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Table from '@zera-admin/table'

import { turkishDateFormat } from 'app/functions'
import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import TwitterV2MainTelegramAlarmRuleResponseService, {
  TwitterV2MainTelegramAlarmRule,
  TwitterV2MainTelegramAlarmRuleResponse,
} from 'services/http/backoffice/alarm-keyword'

import {
  IAlarmListFilter,
  TwitterV2MainTelegramAlarmRuleListProps,
} from './types'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { renderAlarmType } from '../constant'

const TwitterV2MainTelegramAlarmRuleList: React.FunctionComponent<
  TwitterV2MainTelegramAlarmRuleListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [TwitterV2MainTelegramAlarmRules, setTwitterV2MainTelegramAlarmRules] =
    useState<TwitterV2MainTelegramAlarmRuleResponse>()
  const selectSources = [
    { label: 'Çalışan', value: 0 },
    { label: 'Duraklatılmış', value: 1 },
  ]

  const [flagcard] = useFlagcardContext()
  const history = useHistory()

  useEffect(() => {
    getTwitterV2MainTelegramAlarmRules(initialValues, 0)
  }, [])

  const clearFilter = () => {
    form?.handleReset()
    form.handleBatchUpdate(initialValues)
    history.push({
      search: 'pageIndex=1',
    })
    getTwitterV2MainTelegramAlarmRules(initialValues, 0)
  }

  const initialValues: IAlarmListFilter = {
    alarmNames: '',
    paused: 0,
  }

  const form = useForm<IAlarmListFilter>({
    onSubmit: () => handleFilter(),
    initialValues,
  })

  const handleFilter = () => {
    history.push({
      search: 'pageIndex=1',
    })
    getTwitterV2MainTelegramAlarmRules(form?.values, 0)
  }

  const getTwitterV2MainTelegramAlarmRules = async (
    formData: IAlarmListFilter,
    pageIndex: number
  ) => {
    const index = pageIndex ? pageIndex - 1 : 0
    try {
      const response = await TwitterV2MainTelegramAlarmRuleResponseService.get(
        formData.alarmNames,
        index,
        formData?.paused
      )
      setTwitterV2MainTelegramAlarmRules(response.data)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      setTwitterV2MainTelegramAlarmRules({
        list: [],
        dataCount: 0,
        message: '',
        pageCount: 0,
        success: false,
      })
    }
  }

  const handlePaused = async (id: string) => {
    if (id) {
      try {
        const response =
          await TwitterV2MainTelegramAlarmRuleResponseService.putById(id)

        if (response.code === 200) {
          setTwitterV2MainTelegramAlarmRules(
            (prevTwitterV2MainTelegramAlarmRules) => {
              if (prevTwitterV2MainTelegramAlarmRules) {
                const updatedList =
                  prevTwitterV2MainTelegramAlarmRules.list.map(
                    (TwitterV2MainTelegramAlarmRule) => {
                      if (TwitterV2MainTelegramAlarmRule.id === id) {
                        return {
                          ...TwitterV2MainTelegramAlarmRule,
                          paused: !TwitterV2MainTelegramAlarmRule.paused,
                        }
                      }
                      return TwitterV2MainTelegramAlarmRule
                    }
                  )

                return {
                  ...prevTwitterV2MainTelegramAlarmRules,
                  list: updatedList,
                }
              }
              return prevTwitterV2MainTelegramAlarmRules
            }
          )
        } else {
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'Süreç değiştirilirken bir hata oluştu',
          })
        }
        // window.location.reload()
      } catch {
        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children: 'Süreç değiştirilirken bir hata oluştu',
        })
      }
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/alarms/create">
        Alarm oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Alarmlar" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm alarmları görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )
    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Alarmlar
      </PageHeader>
    )
  }

  const renderFilter = () => {
    const pausedValue = selectSources?.find(
      (sourceKey: SelectOptionProps) =>
        sourceKey?.value === form?.values?.paused
    )

    return (
      <Form onSubmit={form.handleSubmit}>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 6 }}>
            <Input
              description="Geçerli bir alarm adı giriniz"
              label="Kanal Adı"
              name="alarmNames"
              placeholder="Örnek twitter alarm"
              value={form.values.alarmNames}
              onChange={form.handleChange}
            />
          </Column>

          <Column className="tweet-date-rs-f">
            <Select
              name="types"
              options={selectSources}
              onChange={(props: any) =>
                form.handleFieldChange('paused', props.value)
              }
              value={pausedValue || selectSources[0]}
            />
          </Column>

          <Column>
            <Button
              appearance="primary"
              children="Arama yap"
              iconBefore={<Icon name="search" />}
              type="submit"
            />
            <Button
              appearance="error"
              children="Filtreyi temizle"
              iconBefore={<Icon name="trash" />}
              style={{ marginLeft: 20 }}
              type="button"
              onClick={() => clearFilter()}
            />
          </Column>
        </Row>
      </Form>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'alarmNames',
        text: 'Kanal Adı',
        render: (props: TwitterV2MainTelegramAlarmRule) => {
          return props?.alarmNames?.join(', ')
        },
      },
      {
        name: 'description',
        text: 'Açıklama',
      },
      {
        name: 'alarmType',
        text: 'Alarm Tipi',
        render: (props: TwitterV2MainTelegramAlarmRule) => {
          return renderAlarmType(props.alarmType)
        },
      },
      {
        name: 'createdAt',
        text: 'Başlama tarihi',
        render: (props: TwitterV2MainTelegramAlarmRule) => {
          return turkishDateFormat(props.createdAt)
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: TwitterV2MainTelegramAlarmRule) => (
          <LinkButton
            appearance="subtle-link"
            href={`/alarms/detail/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
      {
        name: 'period',
        text: 'Süreç ',
        width: '100px',
        render: (item: TwitterV2MainTelegramAlarmRule) => (
          <Button
            appearance="subtle"
            iconBefore={
              item.paused === true ? (
                <Icon name="vid-play" />
              ) : (
                <Icon name="vid-pause" />
              )
            }
            style={{ marginLeft: 20 }}
            children={item.paused === true ? 'Sürdür' : 'Durdur'}
            type="button"
            onClick={() => handlePaused(item.id)}
          />
        ),
      },
    ]
    return (
      <React.Fragment>
        <Table
          columns={columns as any}
          rows={TwitterV2MainTelegramAlarmRules?.list || []}
          isLoading={isLoading}
          helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
        />
        <Pager
          pages={TwitterV2MainTelegramAlarmRules?.pageCount || 0}
          onChange={(index) =>
            getTwitterV2MainTelegramAlarmRules(form?.values, index)
          }
        />
      </React.Fragment>
    )
  }
  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderFilter()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default TwitterV2MainTelegramAlarmRuleList
