import React, { useImperativeHandle, useState } from 'react'
import ZeraTable from '@zera-admin/table'
import Alert from '@zera-admin/alert'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'

import { getCriteriaInterval } from '../visualization/getters'
import * as Styled from './Table.styled'
import { mapQueryForTable } from './utils/mappers'
import { TableProps, TableVisualizationOptions } from './types'

const Table: React.ForwardRefRenderFunction<
  WidgetHandlerRef<TableVisualizationOptions>,
  TableProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<QueryRunResponse>({
    headers: [],
    rows: [],
  })
  const [error, setError] = useState<string>()

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const runQuery = (widget: Widget<TableVisualizationOptions>) => {
    setIsLoading(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
      })
      .then((res) => {
        setValues(
          mapQueryForTable(
            res.data as QueryRunResponse,
            widget.visualization?.options?.columns || [],
            widget.type
          )
        )
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues({ ...values, rows: [] })
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderTable = () => {
    if (!error) {
      return (
        <Styled.Table>
          <ZeraTable
            columns={values.headers}
            height={`${window.innerHeight - 300}px`}
            isLoading={isLoading}
            rows={values.rows}
          />
          <Styled.TableSummary>
            <strong>{values.rows.length || 0}</strong> adet kayıt listeleniyor
          </Styled.TableSummary>
        </Styled.Table>
      )
    }
    return (
      <Alert appearance="error" title="Tablo verisi oluşturulamadı">
        {error ||
          'Tabloyu oluşturacak veri bulunamadı. Lütfen sorgularınızı ve ayarlarınızı kontrol ediniz.'}
      </Alert>
    )
  }

  return renderTable()
}

export default React.forwardRef(Table)
