import React, { useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'
import MainLayout from 'layouts/main'
import appService, { App } from 'services/http/identity-server/app'

import { CreateAppProps } from './types'
import AppForm from './components/AppForm'

const CreateApp: React.FunctionComponent<CreateAppProps> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()

  const handleCreate = async (data: App) => {
    setIsLoading(true)

    try {
      const response = await appService.create(data)

      flagcard.add({
        title: 'Uygulama oluşturuldu',
        children:
          'Geçerli uygulama başarıyla oluşturuldu. Detay sayfasına yönlendiriliyorsunuz.',
      })

      if (response.data.id) {
        history.push(`/app/update/${response.data.id}`)
      }
    } catch {
      popup.add({
        title: 'Uygulama oluşturulamadı',
        children:
          'Uygulama oluşturulurken bir sorun oluştu lütfen tekrar deneyin.',
      })
    }

    setIsLoading(false)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Uygulamalar" href="/apps" />
      <BreadcrumbItem text="Uygulama oluştur" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Container width="narrow">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Kullanıcı ve rol yönetimlerinin sağlanabilmesi adına bir
                uygulama oluşturun.
              </p>
            }
          >
            Uygulama oluştur
          </PageHeader>
          <AppForm
            loading={isLoading}
            values={{ name: '' }}
            operation="create"
            onSubmit={handleCreate}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default CreateApp
