import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import * as Styled from './PieChart.styled'
import { PieChartProps } from './types'
import { numberFormat } from 'app/functions/number'

const PieChart: React.FC<PieChartProps> = ({
  data,
  setData,
  editMode,
  onChange,
}) => {
  const [newCountry, setNewCountry] = useState<string>('')
  const [newCount, setNewCount] = useState<number | null>(0)

  const handleDelete = (index: number) => {
    const updatedData = data ? [...data] : []
    updatedData.splice(index, 1)
    setData(updatedData)
  }

  const handleAddNewData = () => {
    if (newCountry && newCount !== null) {
      const newData = {
        Country: newCountry,
        Count: newCount,
        Percentage: 0,
      }

      setNewCountry('')
      setNewCount(0)
      const newDataArray =
        data &&
        [...data, newData].sort((a, b) => {
          return b.Count - a.Count
        })

      setData(newDataArray)
    }
  }

  const countries = data?.filter((item) => item.Country)

  const handleBlurElement = (key: string, value: string, country: string) => {
    onChange(key, value, country)
  }

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'pie',
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: countries?.map((item) => item.Country),
  }

  return (
    <>
      <Styled.PieInputContainer>
        {editMode && (
          <div className="new-data-container">
            <input
              type="text"
              placeholder="Kelime"
              value={newCountry}
              onChange={(e) => setNewCountry(e.target.value)}
            />
            <input
              type="number"
              placeholder="Deger"
              value={newCount || 0}
              onChange={(e) => setNewCount(Number(e.target.value))}
            />
            <button onClick={handleAddNewData}>Kelime Ekle</button>
          </div>
        )}

        <Styled.PieChartContainer>
          {data?.length && (
            <>
              <div>
                <ReactApexChart
                  options={options}
                  series={countries?.map((item) => item.Count)}
                  type="pie"
                  height={550}
                />
              </div>

              <div className="pie-chart-container">
                <h5>Ülke Dağılımı</h5>
                <div className="country-list-container">
                  {countries?.map((item, index) => (
                    <div key={index}>
                      <span
                        contentEditable={editMode}
                        onBlur={(e) =>
                          handleBlurElement(
                            'Country',
                            e.target.innerText,
                            item.Country
                          )
                        }
                      >
                        {item.Country}
                      </span>
                      <span
                        contentEditable={editMode}
                        onBlur={(e) =>
                          handleBlurElement(
                            'Count',
                            e.target.innerText,
                            item.Country
                          )
                        }
                      >
                        {numberFormat(item.Count)}
                      </span>
                      {editMode && (
                        <button
                          onClick={() => {
                            handleDelete(index)
                          }}
                        >
                          X
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </Styled.PieChartContainer>
      </Styled.PieInputContainer>
    </>
  )
}

export default PieChart
