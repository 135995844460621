import styled from 'styled-components'

export const RedditAnalysisWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.print {
    align-items: center;
    align-self: center;
  }
`

export const RedditAnalysisContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .add-reddit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 1rem;
    input {
      width: 40%;
      padding: 0.5rem;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
    }

    button {
      background-color: #4caf50;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }

  .reddit {
    position: relative;
    width: 100%;
    height: fit-content;

    .delete-reddit {
      background-color: red;
      top: 0;
      right: 0;
      margin: 1rem;

      color: white;
      border: none;
      border-radius: 10px;
      padding: 0.5rem 1rem;
      cursor: pointer;
      position: absolute;
    }
  }

  .row {
    width: 100%;
    height: 100%;
  }
  .col {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .col:first-child {
    justify-content: center;
    & > div:first-child {
      margin: auto 1rem;
    }
  }

  .col:last-child {
    & > div:first-child {
      margin: auto 0vw;
      height: calc(540px - (25.6px * 2));
      width: 100%;
      overflow: auto;
    }
  }
`
