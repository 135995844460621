import { ActivePerson, ActivePersonListResponse, FilterResponse } from './types'

export const mapActivePersonListResponse = (raw: any): ActivePerson => ({
  userId: raw.data.userId,
  username: raw.data.username,
  name: raw.data.name,
  score: raw.data.number,
  followerCount: raw.data.followerCount,
  followingCount: raw.data.followingCount,
  profileImageUrl: raw.data.profileImageUrl,
  entityKey: raw.data.entityKey,
  country: raw.data.country,
  location: raw.data.location,
  description: raw.data.description,
  createdDate: raw.data.createdDate,
  sentiment: raw.data.sentiment,
  archaeology: raw.data.archaeology,
  arts: raw.data.arts,
  businessFinance: raw.data.businessFinance,
  education: raw.data.education,
  food: raw.data.food,
  health: raw.data.health,
  history: raw.data.history,
  lawHumanRights: raw.data.lawHumanRights,
  media: raw.data.media,
  military: raw.data.military,
  natureGeography: raw.data.natureGeography,
  politics: raw.data.politics,
  religion: raw.data.religion,
  scienceTech: raw.data.scienceTech,
  sports: raw.data.sports,
  terror: raw.data.terror,
  tourism: raw.data.tourism,
  userCategory: raw.data.userCategory,
  publishStatus: raw.data.publishStatus,
  activeStatus: raw.data.activeStatus,
  id: raw.data.id,
})

export const mapActivePersonForList = (raw: any): ActivePersonListResponse => ({
  data: raw.data.map((item: any) =>
    mapActivePersonListResponse({ data: item })
  ),
  pageCount: raw.pageCount,
  dataCount: raw.dataCount,
  statusCode: raw.statusCode,
})
export const mapFilterResponse = (raw: any): FilterResponse => ({
  countries: raw.data.countries,
  classifications: raw.data.classifications,
  availableDates: raw.data.availableDates,
})
