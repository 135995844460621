import { request } from 'services/http/backoffice/instance'

export const create = (data: any) =>
  request<any>({
    method: 'post',
    url: 'back-office/dashboard/create-scheduler-report',
    data,
    mappers: {
      //req: mapDefinitionForRequest,
    },
  })
