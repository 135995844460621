import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container, Row } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'

import MainLayout from 'layouts/main'
import profileReportsService from 'services/http/report/profile-report'

import { DetailPersonReportProps } from './types'
import { LoadingView } from './ProfileReport.styled'
import { ProfileContainer } from './Profile.styled'
import { PdfContainer } from 'pages/agenda-report/mobile/components/report-card'
import { Data } from 'services/http/report/profile-report/type'

const DetailPersonReport: React.FunctionComponent<DetailPersonReportProps> = ({
  match,
}) => {
  const [profileReport, setProfileReport] = useState<Data>()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getByGroupId = useCallback(() => {
    setIsLoading(true)
    const groupId = match.params.id
    profileReportsService
      .getByGroupId(groupId)
      .then((res) => {
        setProfileReport(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [match.params.id])

  useEffect(() => {
    getByGroupId()
  }, [getByGroupId])

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Profil raporu" href="/profile-report" />
      <BreadcrumbItem text="Profil raporu detayı" />
    </Breadcrumb>
  )

  return isLoading ? (
    <LoadingView>
      <Spinner size="large" />
      <h5>Rapor yükleniyor..</h5>
    </LoadingView>
  ) : (
    <MainLayout isLoading={isLoading}>
      <Container width="medium">
        <Page>
          <PageHeader breadcrumbs={breadcrumbs}>
            <div
              style={{ width: '100%', textAlign: 'right', fontSize: '20px' }}
            >
              <p>{new Date().toLocaleString()} </p>
            </div>
            Profil verisi listeleniyor: {profileReport?.reportName}
          </PageHeader>
          <Row
            xs={{ direction: 'column', align: 'center' }}
            style={{ marginTop: 40 }}
          >
            <PdfContainer
              style={{ width: '100%' }}
              id="printThis"
              className="fa-Page"
            >
              <ProfileContainer>
                <div className="profile-persondetail-container">
                  <div className="profile-img-container">
                    <img
                      src={profileReport?.result.TwitterProfile.ProfileImageUrl}
                      alt="profile"
                    />
                  </div>
                  <div className="profile-person-name">
                    <span>{profileReport?.result.TwitterProfile.Name}</span>
                    <span>
                      @{profileReport?.result.TwitterProfile.ScreenName}
                    </span>
                  </div>
                  <div className="profile-person-info">
                    <div className="profile-person-info-container">
                      <div className="profile-person-info-details">
                        <span className="profile-person-info-details-fbox">
                          Yaş:
                        </span>
                        <span>Belirsiz</span>
                      </div>
                      <div className="profile-person-info-details">
                        <span className="profile-person-info-details-fbox">
                          Konum:
                        </span>
                        <span>
                          {
                            profileReport?.result.TwitterProfile
                              .CountryDetection
                          }
                        </span>
                      </div>
                      <div className="profile-person-info-details">
                        <span className="profile-person-info-details-fbox">
                          Takipçi Sayısı:
                        </span>
                        <span>
                          {profileReport?.result.TwitterProfile?.FollowersCount?.toLocaleString()}
                        </span>
                      </div>
                      <div className="profile-person-info-details">
                        <span className="profile-person-info-details-fbox">
                          Arkadaş Sayısı
                        </span>
                        <span>
                          {profileReport?.result.TwitterProfile?.FriendsCount}
                        </span>
                      </div>
                      <div className="profile-person-info-details">
                        <span className="profile-person-info-details-fbox">
                          Meslek:
                        </span>
                        <span>-</span>
                      </div>
                    </div>
                    <div className="person-talents-container">
                      <div className="person-talents">
                        {profileReport?.result.TopInterestsByClassification?.map(
                          (itm) => (
                            <div>
                              <span>{itm}</span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-detail-container">
                  <div className="rs-upper-container">
                    <div className="rs-upper-detail-bar"></div>
                  </div>
                  <div className="rs-center-container">
                    <div className="person-interest-container">
                      <h3>Kullanıcının 5 kriterdeki ölçümü</h3>
                      <div className="all-person-interest-container">
                        {(
                          Object.keys(
                            profileReport?.result.AllBigFiveAnalysis as any
                          ) as any
                        ).map(
                          (
                            word:
                              | 'agreeableness'
                              | 'conscientiousness'
                              | 'extroversion'
                              | 'neuroticism'
                              | 'openness'
                          ) => (
                            <div className="person-interest">
                              <span>{word}</span>
                              <div className="person-bar-container">
                                <div
                                  className="person-bar"
                                  style={{
                                    width: `${
                                      (100 *
                                        Number(
                                          profileReport?.result
                                            ?.AllBigFiveAnalysis[word]?.score
                                        )) /
                                      5
                                    }%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="person-target-container">
                      <h3>Özellikleri</h3>
                      <div className="person-target-list">
                        <ul>
                          {profileReport?.result?.InterestsByBigFive?.WordsByBigFivePrediction?.slice(
                            0,
                            8
                          ).map((item) => (
                            <li>
                              <span>
                                <i className="fa-solid fa-circle" />
                              </span>
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="person-target-container">
                      <h3>Beklentileri</h3>
                      <div className="person-target-list">
                        <ul>
                          <li>
                            <span>
                              <i className="fa-solid fa-circle" />
                            </span>
                            <span>
                              Siyasi yetkililerin hızlı ve güvenilir açıklamalar
                              yapması.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="fa-solid fa-circle" />
                            </span>
                            <span>
                              {' '}
                              Eğitim sisteminin tüm çocuklar için ulaşılabilir
                              olması.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="person-target-container">
                      <h3>Kendi Hakkında </h3>
                      <div className="person-target-list">
                        <ul>
                          {profileReport?.result?.InterestsByBigFive?.SampleSentences?.map(
                            (sentence) => (
                              <li>
                                <span>
                                  <i className="fa-solid fa-circle" />
                                </span>
                                <span>{sentence}</span>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    {profileReport?.result?.FollowingLeaders && (
                      <div className="person-opinion-leaders-container">
                        <h3>Takip Ettiği Liderler</h3>
                        <div className="person-opinion-spans">
                          {profileReport?.result?.FollowingLeaders?.slice(
                            0,
                            3
                          ).map((item) => (
                            <span>{item.Name}</span>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="person-target-container">
                      <h3>Top Emotions</h3>
                      <div className="person-target-list">
                        <ul>
                          {profileReport?.result?.TopEmotions?.map((item) => (
                            <li>
                              <span>
                                <i className="fa-solid fa-circle" />
                              </span>
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {profileReport?.result?.AbsKeywordsBySentiments?.map(
                      (sentiment) =>
                        sentiment.Sentiment === 'positive' ? (
                          <div className="person-target-container">
                            <h3>ABS Keywords Olumlu</h3>
                            <div className="person-target-list">
                              <ul>
                                {sentiment?.AbsKeywords?.map((item) => (
                                  <li>
                                    <span>
                                      <i className="fa-solid fa-circle" />
                                    </span>
                                    <span>{item}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div className="person-target-container">
                            <h3>ABS Keywords Olumsuz</h3>
                            <div className="person-target-list">
                              <ul>
                                {sentiment?.AbsKeywords?.map((item) => (
                                  <li>
                                    <span>
                                      <i className="fa-solid fa-circle" />
                                    </span>
                                    <span>{item}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </ProfileContainer>
            </PdfContainer>
          </Row>
        </Page>
      </Container>
    </MainLayout>
  )
}

export default DetailPersonReport
