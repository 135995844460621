import {
  mapAuditForResponse,
  mapMetaForResponse,
} from 'services/http/identity-server/mappers'

import {
  UserResponse,
  UserChangePasswordRequest,
  UserCreateRequest,
  UserListRequest,
  UserListResponse,
  UserUpdateRequest,
} from './types'

export const mapUserForResponse = (raw: any): UserResponse => ({
  id: raw.data.id,
  name: raw.data.name,
  surname: raw.data.surname,
  userName: raw.data.userName,
  password: raw.data.password,
  email: raw.data.email,
  phoneNumber: raw.data.phoneNumber,
  country: raw.data.country,
  gender: raw.data.gender,
  birthDate: raw.data.birthDate,
  roles: raw.data.roles || [],
  apps: raw.data.apps || [],
  audit: mapAuditForResponse(raw.data),
})

export const mapUserForCreateRequest = (raw: UserCreateRequest): any => ({
  name: raw.name,
  surname: raw.surname,
  userName: raw.userName,
  password: raw.password,
  email: raw.email,
  phoneNumber: raw.phoneNumber,
  country: raw.country,
  gender: raw.gender,
  birthDate: raw.birthDate,
  roleIds: (raw.roles || []).map((role) => role.id),
  apps: raw.apps || [],
})

export const mapUserForUpdateRequest = (raw: UserUpdateRequest): any => ({
  id: raw.id,
  name: raw.name,
  surname: raw.surname,
  userName: raw.userName,
  password: raw.password,
  email: raw.email,
  phoneNumber: raw.phoneNumber,
  country: raw.country,
  gender: raw.gender,
  birthDate: raw.birthDate,
  roleIds: (raw.roles || []).map((role) => role.id),
  apps: raw.apps || [],
})

export const mapUserForChangePasswordRequest = (
  raw: UserChangePasswordRequest
): any => ({
  userId: raw.id,
  oldPassword: raw.oldPassword,
  newPassword: raw.newPassword,
  againNewPassword: raw.againNewPassword,
})

export const mapUserListForRequest = (raw: UserListRequest) => ({
  PageIndex: raw.pageIndex,
  PageSize: raw.pageSize,
  Email: raw.email,
  UserName: raw.userName,
})

export const mapUserListForResponse = (raw: any): UserListResponse => ({
  data: raw.data.map((user: any) => mapUserForResponse({ data: user })),
  meta: mapMetaForResponse(raw),
})
