import ReactDOM from 'react-dom'

import 'assets/styles/vendors.css'
import 'assets/styles/root.css'

import App from './bootstrap/App'
import Capsule from './bootstrap/Capsule'
import Provider from './bootstrap/Provider'
import Router from './bootstrap/Router'

ReactDOM.render(
  <Provider>
    <App>
      <Capsule>
        <Router />
      </Capsule>
    </App>
  </Provider>,
  document.getElementById('root')
)
