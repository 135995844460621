import React, { useState } from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'
import { Column, Row } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import Input from '@zera-admin/input'
import Icon from '@zera-admin/icon'

import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'
import { CounterVisualizationValue } from '../types'

import * as Styled from './CounterDefinitions.styled'
import { CounterDefinitionsProps } from './types'

const CounterDefinitions: React.FunctionComponent<CounterDefinitionsProps> = ({
  aggregations = [],
  fields = [],
  groups = [],
  options,
  onChange,
}) => {
  const [activeField, setActiveField] =
    useState<CounterVisualizationValue | null>()
  const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
    getFieldOptions(fields)
  )

  const handleChangeFieldInput = (value: string) => {
    const option = {
      label: value,
      value,
    }
    const index = fieldOptions.findIndex(
      (reference) => reference.value === parseInt(option.value)
    )
    let optionsInstance = [...fieldOptions]

    if (value && index === -1) {
      optionsInstance[0] = option
    } else {
      optionsInstance = getFieldOptions(fields)
    }

    setFieldOptions(optionsInstance)
  }

  const handleUpdateField = (key: string, value: string | object) => {
    const result = { ...activeField, [key]: value }

    setActiveField(result as CounterVisualizationValue)
  }

  const handleSaveFieldSettings = () => {
    const result = { ...options }

    if (activeField) {
      result.value = activeField

      onChange(result)
      setActiveField(null)
    }
  }

  const renderFieldNumberOptions = () => {
    return (
      <Row
        xs={{ direction: 'column' }}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <Column>
          <Input
            name="prefix"
            label="Ön ek"
            placeholder="Fiyat"
            value={activeField?.prefix}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleUpdateField('prefix', event.target.value)
            }
          />
        </Column>
        <Column>
          <Input
            name="suffix"
            label="Son ek"
            placeholder="Adet"
            value={activeField?.suffix}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleUpdateField('suffix', event.target.value)
            }
          />
        </Column>
      </Row>
    )
  }

  const renderFieldsDrawer = () => {
    if (!activeField) return null

    const actions = (
      <ButtonGroup>
        <Button appearance="primary" onClick={handleSaveFieldSettings}>
          Ayarları uygula
        </Button>
        <Button onClick={() => setActiveField(null)}>Kapat</Button>
      </ButtonGroup>
    )

    return (
      <Drawer
        actions={actions}
        isOpen={activeField ? true : false}
        onClose={() => setActiveField(null)}
        title={`${string.textify(activeField.field)} ayarları`}
        width="narrow"
        description="Aşağıdan geçerli alana ait özellikleri güncelleyebilirsiniz."
      >
        {renderFieldNumberOptions()}
      </Drawer>
    )
  }

  const renderTabs = () => {
    return (
      <Tabs id="tabs">
        <TabTriggerGroup>
          <TabTrigger>Veri</TabTrigger>
        </TabTriggerGroup>
        <TabPanel>{renderDataTab()}</TabPanel>
      </Tabs>
    )
  }

  const renderDataTab = () => {
    return (
      <Row xs={{ direction: 'column' }}>
        <Column>
          <Styled.CounterDefinitionField>
            <Row>
              <Column>
                <Input
                  name="suffix"
                  label="Açıklama"
                  placeholder="Toplam kayıt sayısı"
                  value={options.label}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange({
                      ...options,
                      label: event.target.value,
                    })
                  }
                />
              </Column>
              <Column>
                <Select
                  name="column"
                  options={fieldOptions}
                  onChange={(props) => {
                    onChange({
                      ...options,
                      value: {
                        field: (props as SelectOptionProps).value as string,
                        type: 'number',
                      },
                    })
                  }}
                  onInputChange={handleChangeFieldInput}
                  value={
                    options.value
                      ? {
                          label: string.textify(options.value.field),
                          value: options.value.field,
                        }
                      : { label: '', value: '' }
                  }
                  label="Değer"
                  placeholder="Seçim yapınız"
                  description="Sayaç üzerinde gösterilecek değeri seçin"
                  isDisabled={
                    aggregations.length || groups.length ? true : false
                  }
                />
              </Column>
              <Column>
                <Input
                  name="suffix"
                  label="İkinci açıklama"
                  placeholder="Toplam kayıt sayısı"
                  value={options.secondLabel}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange({
                      ...options,
                      secondLabel: event.target.value,
                    })
                  }
                />
              </Column>

              <Button
                className="button--settings"
                iconBefore={<Icon name="settings" size="small" />}
                onClick={() => setActiveField(options.value)}
                isDisabled={!options.value}
              />

              {renderFieldsDrawer()}
            </Row>
          </Styled.CounterDefinitionField>
        </Column>
      </Row>
    )
  }

  return <Styled.CounterDefinitions>{renderTabs()}</Styled.CounterDefinitions>
}

export default CounterDefinitions
