import { request } from '../instance'

import {
  mapRoleForCreateRequest,
  mapRoleForResponse,
  mapRoleForUpdateRequest,
  mapRoleListForRequest,
  mapRoleListForResponse,
} from './mappers'
import {
  RoleUpdateRequest,
  RoleCreateRequest,
  RoleListRequest,
  RoleListResponse,
  RoleResponse,
} from './types'

export const create = (data: RoleCreateRequest) =>
  request<RoleResponse>({
    method: 'post',
    url: 'role',
    data,
    mappers: {
      req: mapRoleForCreateRequest,
      res: mapRoleForResponse,
    },
  })

export const get = (params?: RoleListRequest) =>
  request<RoleListResponse>({
    method: 'get',
    params,
    url: 'role',
    mappers: {
      req: mapRoleListForRequest,
      res: mapRoleListForResponse,
    },
  })

export const getById = (id: number) =>
  request<RoleResponse>({
    method: 'get',
    url: `role/${id}`,
    mappers: {
      res: mapRoleForResponse,
    },
  })

export const update = (data: RoleUpdateRequest) =>
  request<RoleResponse>({
    method: 'put',
    url: 'role',
    data,
    mappers: {
      req: mapRoleForUpdateRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `role/${id}`,
  })
