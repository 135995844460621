import React, { useCallback, useEffect, useState } from 'react'

import { tableColumns } from './components/table-columns'
import { ListAgendaReportProps } from './types'

import ReportPageHeader from './components/page-header'
import ReportFilter from './components/report-filter'

import Page from '@zera-admin/page'
import Table from '@zera-admin/table'
import { useForm } from '@zera-admin/form'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import agendaReportService, {
  FilterProps,
  AgendaReportListResponse,
} from 'services/http/report/agenda-report-4'

const ListAgendaReport: React.FunctionComponent<ListAgendaReportProps> = () => {
  const [agendaReport, setAgendaReport] = useState<AgendaReportListResponse>()
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const form = useForm<FilterProps>({
    onSubmit: (props) => handleFilter(props),
  })

  const handleFilter = async ({ values }: any) => {
    setPageIndex(0)
    setIsLoading(true)

    try {
      const response = await agendaReportService.listAgendaReportV4({
        ReportName: values?.description ? values?.description : '',
        Status: values?.status,

        PageSize: 10,
        PageIndex: 0,
      })
      setAgendaReport(response.data)
    } catch (err) {
      setAgendaReport({ data: [], dataCount: 0, pageCount: 0 })
    } finally {
      setIsLoading(false)
    }
  }

  const getPersonReports = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await agendaReportService.listAgendaReportV4({
        ReportName: form.values?.description,
        Status: form.values?.status
          ? (form.values?.status.value as string)
          : '',

        PageSize: 10,
        PageIndex: pageIndex,
      })
      setAgendaReport(response.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }, [pageIndex])

  useEffect(() => {
    getPersonReports()
  }, [getPersonReports, pageIndex])

  useEffect(() => {
    window.history.pushState(
      null,
      '',
      `/agenda-reportv4?pageIndex=${pageIndex}`
    )
  }, [pageIndex])

  return (
    <MainLayout>
      <Page>
        <ReportPageHeader />
        <ReportFilter form={form} />
        <Table
          columns={tableColumns as any}
          rows={agendaReport?.data || []}
          isLoading={isLoading}
          helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
        />
        <Pager
          pages={agendaReport?.pageCount || 0}
          onChange={(index) => {
            setPageIndex(index - 1)
          }}
        />
      </Page>
    </MainLayout>
  )
}

export default ListAgendaReport
