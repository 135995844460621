import { QueryRunResponse } from 'services/http/bi-tool/query'

import { RedditValueProps, RedditVisualizationOptions } from '../types'

export const mapQueryForReddit = (
  raw: QueryRunResponse,
  options: RedditVisualizationOptions
): RedditValueProps[] =>
  (raw.rows || []).map((row) => ({
    createdAt: row[options?.fields?.createdAt as string],
    permaLink: row[options?.fields?.permaLink as string],
    postFullId: row[options?.fields?.postFullId as string],
    score: row[options?.fields?.score as string],
    title: row[options?.fields?.title as string],
    userIconImg: row[options?.fields?.userIconImg as string],
    userName: row[options?.fields?.userName as string],
  }))
