import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button, { LinkButton } from '@zera-admin/button'
import Form, { useForm } from '@zera-admin/form'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Spinner from '@zera-admin/spinner'
import { globalPageSize } from 'app/shared/constants'
import CompoundButton from 'components/compound-button'
import Pager from 'components/pager'
import BIToolLayout from 'layouts/bi-tool'
import widgetService, {
  Widget,
  WidgetResponse,
} from 'services/http/bi-tool/widget'

import { WidgetListProps } from './types'
import * as Styled from './widget.styled'
import { uid } from '@zera-admin/utils'
import { useFlagcardContext } from 'app/contexts/flagcard'
import Switch from '@zera-admin/switch'

const WidgetList: React.FunctionComponent<WidgetListProps> = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [widgets, setWidgets] = useState<WidgetResponse>()
  const [pageSize, setPageSize] = useState(24)
  const [resetable, setResetable] = useState<boolean>(true)
  const [selectedList, setSelectedList] = useState<any>([])
  const [edit, setEdit] = useState(false)
  const [withDashboardKeys, setWithDashboardKeys] = useState(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [handleDeletes, setHandleDeletes] = useState<boolean>(false)
  const urlSearchParams = new URLSearchParams(location.search)
  const [flagcard] = useFlagcardContext()

  const form = useForm<Widget>({
    onSubmit: (props) => handleFilter(props),
  })

  const handleFilter = ({ values }: any) => {
    getWidgets(values.title, 0, withDashboardKeys)
  }

  const handleButton = (params: any) => {
    const paramExists = selectedList.some((item: any) => item === params)

    if (paramExists) {
      setSelectedList(selectedList.filter((item: any) => item !== params))
    } else {
      setSelectedList([...selectedList, params])
    }
  }
  const handleSubmit = (deletes: boolean) => {
    if (deletes === true) {
      if (selectedList.length > 0) {
        setIsLoading(true)
        widgetService
          .delList(`ids=${selectedList[0]}`)
          .then((res) => {
            if (res) {
              flagcard.add({
                id: uid(),
                appearance: 'success',
                title: 'Widgetler silindi.',
                children: 'Seçmiş olduğunuz widgetler başarıyla silindi.',
              })
              getWidgets()
              setEdit(false)
              setIsDelete(false)
            }
          })
          .catch((error) => {
            console.log(error)
            flagcard.add({
              id: uid(),
              appearance: 'error',
              title: 'Bir hata oluştu',
              children: 'Widgetler silinirken bir hata oluştu.',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    } else {
      flagcard.add({
        id: uid(),
        appearance: 'error',
        title: 'Bir hata oluştu',
        children: 'Silmek için widget seçilmedi.',
      })
    }
  }
  const getWidgets = useCallback(
    async (title?: string, pageIndex?: number, withDashboardKeys?: boolean) => {
      const params = parseInt(urlSearchParams.get('pageIndex') || '0')
      const index = pageIndex ? pageIndex - 1 : params ? params - 1 : 0

      try {
        const response = await widgetService.get(
          title,
          index,
          pageSize,
          withDashboardKeys
        )
        setWidgets(response.data)
        setIsLoading(false)
      } catch (err) {
        const ex = err
        console.log(ex)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSize]
  )
  // const onShow = (edit: any, handleDelete: boolean) => {
  //   if (edit === true) {
  //     if (handleDelete === true) {
  //       setEdit(false)
  //       handleSubmit()
  //     }
  //   } else {
  //     setEdit(true)
  //   }
  // }

  useEffect(() => {
    getWidgets()
  }, [getWidgets])

  const clearFilter = () => {
    getWidgets()
    setResetable(false)
    setEdit(false)
    setSelectedList([])
    setIsDelete(false)
    form.handleFieldChange('title', '')
    setTimeout(() => {
      setResetable(true)
    }, 100)
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/bi-tool/widget/create">
        Widget oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Widgetlar" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        BI Tool üzerindeki tüm widgetları aşağıdan görüntüleyebilirsiniz,
        dilerseniz detayını görüntüleyip düzenleme yapabilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Widgetlar
      </PageHeader>
    )
  }

  const renderModal = () => {
    return (
      <>
        {isDelete === true && (
          <Styled.HandleDeleteContainer>
            <Row className="handle-delete-row">
              <Column>
                <h3>Seçilen Dashboardları silmek istediğinize eminmisiniz ?</h3>
              </Column>
              <Column className="btn-container">
                <Column xs={{ size: 3 }}>
                  <Button
                    appearance="error"
                    children="Sil"
                    type="button"
                    onClick={() => {
                      setHandleDeletes(true)
                      handleSubmit(true)
                    }}
                  />
                </Column>
                <Column xs={{ size: 3 }}>
                  <Button
                    appearance="primary"
                    children="Hayır"
                    type="button"
                    onClick={() => setIsDelete(false)}
                  />
                </Column>
              </Column>
            </Row>
          </Styled.HandleDeleteContainer>
        )}
      </>
    )
  }

  const renderList = () => {
    if (isLoading) {
      return <Spinner />
    } else {
      return (
        <div className="safearea">
          <Row>
            {widgets?.list?.map((widget, index) => (
              <Column key={index} xs={{ size: 3 }}>
                {(!withDashboardKeys && isClicked === false && (
                  <CompoundButton
                    iconBefore={<Icon name="graph-bar" />}
                    appearance="button"
                    href={
                      !edit ? `/bi-tool/widget/update/${widget.id}` : undefined
                    }
                  >
                    {widget.title}
                    <Styled.DashboardList
                      customProp={selectedList.includes(widget.id)}
                    >
                      {edit && (
                        <button
                          className="btn-i"
                          onClick={() => handleButton(widget.id)}
                        >
                          {!selectedList.includes(widget.id) ? (
                            <i
                              style={{ fontSize: '1rem' }}
                              className="fa-regular fa-circle-xmark"
                            />
                          ) : (
                            <i
                              style={{ fontSize: '1rem' }}
                              className="fa-regular fa-circle-check"
                            />
                          )}
                        </button>
                      )}
                    </Styled.DashboardList>
                    <p>
                      {widget.description || 'Tıklayarak görüntüleyebilirsiniz'}
                    </p>
                    {widget?.dashboardKeys?.length > 0 && (
                      <>
                        <div className="list-container">
                          <div>
                            <span>Kullanılan Dashboardlar</span>
                          </div>
                          <div className="dashboard-title-container">
                            {widget.dashboardKeys.map((dashboard: any) => (
                              <span
                                style={{ fontSize: '11px', opacity: 0.5 }}
                                className="dashboard-titles"
                              >
                                {dashboard.title}
                              </span>
                            ))}
                          </div>
                        </div>
                        <Row className="list-container">
                          <Column>
                            <span
                              style={{ display: 'flex' }}
                              className="list-dashboard"
                            >
                              <span style={{ display: 'flex' }}>
                                Kullanılan Dashboard sayısı: &nbsp;
                              </span>
                              <span style={{ opacity: 0.6 }}>
                                {widget.dashboardKeys?.length}
                              </span>
                            </span>
                          </Column>
                        </Row>
                      </>
                    )}
                  </CompoundButton>
                )) || (
                  <CompoundButton
                    appearance="button"
                    href={
                      !edit ? `/bi-tool/widget/update/${widget.id}` : undefined
                    }
                  >
                    {widget.title}
                    <Styled.DashboardList
                      customProp={selectedList.includes(widget.id)}
                    >
                      {edit && (
                        <button
                          className="btn-i"
                          onClick={() => handleButton(widget.id)}
                        >
                          {!selectedList.includes(widget.id) ? (
                            <i
                              style={{ fontSize: '1rem' }}
                              className="fa-regular fa-circle-xmark"
                            />
                          ) : (
                            <i
                              style={{ fontSize: '1rem' }}
                              className="fa-regular fa-circle-check"
                            />
                          )}
                        </button>
                      )}
                    </Styled.DashboardList>
                    <p>
                      {widget.description || 'Tıklayarak görüntüleyebilirsiniz'}
                    </p>
                    {widget?.dashboardKeys?.length > 0 && (
                      <>
                        <div className="list-container">
                          <div>
                            <span>Kullanılan Dashboardlar</span>
                          </div>
                          <div className="dashboard-title-container">
                            {widget.dashboardKeys.map((dashboard: any) => (
                              <span
                                style={{ fontSize: '11px', opacity: 0.5 }}
                                className="dashboard-titles"
                              >
                                {dashboard.title}
                              </span>
                            ))}
                          </div>
                        </div>
                        <Row className="list-container">
                          <Column>
                            <span
                              style={{ display: 'flex' }}
                              className="list-dashboard"
                            >
                              <span style={{ display: 'flex' }}>
                                Kullanılan Dashboard sayısı: &nbsp;
                              </span>
                              <span style={{ opacity: 0.6 }}>
                                {widget.dashboardKeys?.length}
                              </span>
                            </span>
                          </Column>
                        </Row>
                      </>
                    )}
                  </CompoundButton>
                )}
              </Column>
            ))}
          </Row>
        </div>
      )
    }
  }

  const renderFilter = () => {
    return (
      resetable && (
        <Form onSubmit={form.handleSubmit}>
          <Row xs={{ align: 'center' }}>
            <Column xs={{ size: 4 }}>
              <Input
                description="Geçerli bir widget adı giriniz"
                label="Widget adı"
                name="title"
                placeholder="Örnek metin"
                value={form.values.title}
                onChange={form.handleChange}
              />
            </Column>
            <Column xs={{ size: 4 }}>
              <Select
                errorMessage={form.errors.genderType}
                label="Gösterilecek kayıt sayısı"
                name="pageSize"
                options={globalPageSize}
                onChange={(props) =>
                  setPageSize(Number((props as SelectOptionProps)?.value))
                }
                value={globalPageSize.find((pager) => pager.value === pageSize)}
                description="Her sayfada görüntülenecek widget sayısını giriniz"
              />
            </Column>
            <Column>
              <Button
                appearance="primary"
                children="Arama yap"
                iconBefore={<Icon name="search" />}
                type="submit"
                onClick={() => setIsClicked(true)}
              />
              <Button
                appearance="error"
                children="Filtreyi temizle"
                iconBefore={<Icon name="unlink" />}
                style={{ marginLeft: 20 }}
                type="button"
                onClick={() => clearFilter()}
              />
              <Button
                appearance={edit ? 'error' : 'button'}
                children={edit ? 'Sil' : 'Düzenle'}
                iconBefore={<Icon name={edit ? 'trash' : 'edit'} />}
                style={{ marginLeft: 20 }}
                type="button"
                onClick={() => {
                  // onShow(edit, handleDeletes)
                  !edit && setEdit(true)
                  edit && setIsDelete(true)
                }}
              />
            </Column>
            <Switch
              isChecked={withDashboardKeys}
              name="dashboards"
              label="Kullanılan Dashboardları Göster"
              size="large"
              onChange={(event) => {
                setWithDashboardKeys(event.target.checked)
              }}
            />
          </Row>
        </Form>
      )
    )
  }

  const renderPager = () => {
    if (widgets) {
      return (
        <Pager
          pages={widgets.pageCount}
          onChange={(index) => getWidgets('', index as number)}
        />
      )
    }

    return null
  }

  return (
    <BIToolLayout>
      <Page>
        {renderPageHeader()}
        {renderFilter()}
        {renderList()}
        {renderPager()}
        {renderModal()}
      </Page>
    </BIToolLayout>
  )
}

export default WidgetList
