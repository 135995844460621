import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'
import EmptyState from '@zera-admin/empty-state'

import { TweetsCountBeforeProps } from './types'

const TweetsCountBefore: React.FunctionComponent<TweetsCountBeforeProps> = ({
  data,
}) => {
  const tweetsCountBefore: Props = {
    options: {
      chart: {
        id: 'tweetsCountBefore',
      },
      colors: [colors.B200],
      xaxis: {
        categories: data?.map((item: any) => item.tweetDate),
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          distributed: true,
        },
      },
      tooltip: {},
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: 'Attığı tweet sayısı',
        data: data?.map((item: any) => item?.tweetCount.toFixed(2)),
      },
    ],
  }

  return data && data.length > 0 ? (
    <Chart
      options={tweetsCountBefore.options}
      series={tweetsCountBefore.series}
      type="bar"
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default TweetsCountBefore
