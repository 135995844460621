import React from 'react'
import Icon from '@zera-admin/icon'
import Page, { Column, Container, PageHeader, Row } from '@zera-admin/page'

import MainLayout from 'layouts/main'
import CompoundButton from 'components/compound-button'

import { HomeProps } from './types'

const Home: React.FunctionComponent<HomeProps> = () => {
  const renderFrequentlyButtons = () => {
    return (
      <div className="safearea">
        <Row>
          <Column xs={{ size: 3 }}>
            <CompoundButton
              iconBefore={<Icon name="people" />}
              appearance="button"
              href="/person-report"
            >
              Kişi raporları
              <p>Tüm kişi raporlarını görüntüleyebilirsiniz.</p>
            </CompoundButton>
          </Column>
          <Column xs={{ size: 3 }}>
            <CompoundButton
              iconBefore={<Icon name="add" />}
              appearance="button"
              href="/person-report/create"
            >
              Kişi raporu oluştur
              <p>Hızlıca yeni kişi raporu oluşturabilirsiniz.</p>
            </CompoundButton>
          </Column>
          <Column xs={{ size: 3 }}>
            <CompoundButton
              iconBefore={<Icon name="document" />}
              appearance="button"
              href="/agenda-reportv4"
            >
              Gündem raporları
              <p>Tüm gündem raporlarını görüntüleyebilirsiniz.</p>
            </CompoundButton>
          </Column>
          <Column xs={{ size: 3 }}>
            <CompoundButton
              iconBefore={<Icon name="add" />}
              appearance="button"
              href="/agenda-reportv4/create"
            >
              Gündem raporu oluştur
              <p>Hızlıca yeni gündem raporu oluşturabilirsiniz.</p>
            </CompoundButton>
          </Column>
        </Row>
      </div>
    )
  }

  const renderBIToolButtons = () => {
    return (
      <div className="safearea">
        <Row>
          <Column xs={{ size: 3 }}>
            <CompoundButton
              iconBefore={<Icon name="dashboard" />}
              appearance="button"
              href="/bi-tool/dashboards"
            >
              Dashboardları görüntüle
              <p>BI Tool üzerindeki tüm dashboardları görüntüleyebilirsiniz.</p>
            </CompoundButton>
          </Column>
          <Column xs={{ size: 3 }}>
            <CompoundButton
              iconBefore={<Icon name="graph-bar" />}
              appearance="button"
              href="/bi-tool/widgets"
            >
              Widgetları görüntüle
              <p>BI Tool üzerindeki tüm widgetları görüntüleyebilirsiniz.</p>
            </CompoundButton>
          </Column>
        </Row>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <React.Fragment>
        <div className="safearea">
          <h3>Sık Kullanılanlar</h3>
          <p>
            Aşağıdan sistem üzerinde sık kullanılan yönetim ekranlarına hızlıca
            ulaşabilirsiniz.
          </p>
          {renderFrequentlyButtons()}
        </div>
        <div className="safearea">
          <h3>BI Tool</h3>
          <p>
            Aşağıdan BI Tool üzerinde yapmak istediğiniz işlemlere hızlıca
            ulaşabilirsiniz.
          </p>
          {renderBIToolButtons()}
        </div>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Container>
        <Page>
          <PageHeader
            bottomBar={
              <p>
                Alice - Yapay Zeka Platformu'na hoşgeldiniz! Aşağıdan ya da sol
                menüden yönetim ekranlarına hızlıca ulaşabilirsiniz.
              </p>
            }
          >
            Alice - Yapay Zeka Platformu
          </PageHeader>
          {renderContent()}
        </Page>
      </Container>
    </MainLayout>
  )
}

export default Home
