import styled from 'styled-components'

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  background: linear-gradient(to bottom, #08a0e5, #045073);
  height: 600px;
  padding: 3rem;

  .intro-header {
    margin-left: 1rem;
    &-title {
      @font face {
        font-family: 'EuClid';
        src: url('../../../../assets/fonts/EuClidBold');
      }
      font-family: 'EuClid', sans-serif !important;
      font: normal normal bold 50px/70px 'EuClid', sans-serif;

      color: #ffffff;
    }

    &-date {
      display: block;
      margin-top: 18px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
      font-family: Poppins, sans-serif;
      color: #ffffff;
    }
  }

  .intro-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 1.5rem;
    border-top: 1px solid #8ac9e6;
    padding: 2rem 0;
    margin-top: 3rem;
    img {
      height: 80px;
    }

    /* & > img:first-child {
      width: 214px;
    }

    & > img:last-child {
      height: 60px;
      padding-left: 3rem;
      border-left: 1px solid #8ac9e6;
    } */
  }
`
