export const NUMBER_FORMATS = [
  {
    label: 'Varsayılan',
    value: 'default',
  },
  {
    label: 'Sayı',
    value: 'number',
  },
  {
    label: 'Yüzde',
    value: 'percent',
  },
  {
    label: 'Fiyat / Döviz',
    value: 'currency',
  },
]

export const DATE_FORMATS = [
  {
    label: 'Varsayılan',
    value: '',
  },
  {
    label: 'Ocak 05, 2021',
    value: 'mm dd, yyyy',
  },
  {
    label: '05 Ocak, 2021',
    value: 'dd mm, yyyy',
  },
  {
    label: '05/01/2021',
    value: 'DD/MM/YYYY',
  },
  {
    label: '01/05/2021',
    value: 'MM/DD/YYYY',
  },
  {
    label: '05-01-2021',
    value: 'DD-MM-YYYY',
  },
  {
    label: '01-05-2021',
    value: 'MM-DD-YYYY',
  },
  {
    label: '05/01/2021 10:00',
    value: 'DD/MM/YYYY HH:MM',
  },
  {
    label: '05/01/2021 10:00:00',
    value: 'DD/MM/YYYY HH:MM:SS',
  },
  {
    label: '05/01/2021 10:00:00.123',
    value: 'DD/MM/YYYY HH:MM:SS.MS',
  },
]

export const DATE_LOCALES = [
  {
    label: 'Türkiye',
    value: 'tr',
  },
  {
    label: 'Avrupa',
    value: 'en',
  },
]
