/* eslint-disable no-eval */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { uid } from '@zera-admin/utils'

import { druidOperator } from 'app/functions/parser/helpers'
import { QueryRequest } from 'services/http/bi-tool/query'
import { QueryCriteriaInterval } from 'services/http/bi-tool/query/enums'
import { getUserScreenNameList } from 'services/http/bi-tool/query/endpoints'
import { QueryRawInquerie } from 'services/http/bi-tool/query/types'

import { getIntervalAsISO, getIntervalPeriod } from './date'

export const druid = async (
  query: QueryRequest
): Promise<{ query: string; inqueries?: QueryRawInquerie[] }> => {
  let sql = ''
  let inqueries: [id: string, params: object, data: string[]][] = []

  if (query && query.raw) {
    const rules = query.where && query.where.rules
    const intervals = query.raw.intervals

    const filter = (
      key: string,
      value = '',
      operator = '=',
      seperator = 'AND'
    ) => {
      let result = ''

      if (rules) {
        value = value || key

        if (key.toLowerCase() === 'all') {
          let where = ''

          if (query.where) {
            where = query.where.rules
              .map((rule) => {
                if (rule.operator === 'in') {
                  return ` ${query.where?.condition} ${rule.field} IN(${(
                    rule.value as string[]
                  )
                    .map((id) => `'${id}'`)
                    .join(',')})`
                } else if (rule.operator === 'notIn') {
                  return ` ${query.where?.condition} ${rule.field} NOT IN(${(
                    rule.value as string[]
                  )
                    .map((id) => `'${id}'`)
                    .join(',')})`
                }

                return ` ${query.where?.condition} ${rule.field}${druidOperator(
                  rule.operator || ''
                )}'${rule.value}'`
              })
              .join('')
          }

          result = where
        } else {
          const ruleref = rules.find((rule) => rule.field === value)

          if (ruleref) {
            result = `${seperator} ${key}${operator}'${ruleref.value}'`
          }
        }
      }

      if (query.raw?.functions?.length) {
        query.raw.functions.forEach((item) => {
          result = `${result} AND ${item.value}`
        })
      }

      return result
    }

    const time = (key: string, ref = '', operator = '>') => {
      if (intervals && ref) {
        const timeref = intervals.find((interval) => interval.name === ref)

        if (timeref) {
          const formatted = getIntervalAsISO(timeref.interval)

          return `${key}>='${formatted.start}' AND ${key}<='${formatted.end}'`
        }
      } else {
        const formatted = getIntervalAsISO(query.interval)

        return `${key}>='${formatted.start}' AND ${key}<='${formatted.end}'`
      }

      return ''
    }

    const timeParse = (key: string, ref = '', operator = '>') => {
      if (intervals && ref) {
        const timeref = intervals.find((interval) => interval.name === ref)

        if (timeref) {
          const formatted = getIntervalAsISO(timeref.interval)

          return `TIME_PARSE(${key})>='${formatted.start}' AND TIME_PARSE(${key})<='${formatted.end}'`
        }
      } else {
        const formatted = getIntervalAsISO(query.interval)

        return `TIME_PARSE(${key})>='${formatted.start}' AND TIME_PARSE(${key})<='${formatted.end}'`
      }

      return ''
    }

    const perception = `AND (STRPOS(textInEnglish,'cruise') >1 OR	STRPOS(textInEnglish,'deluxe') >1 OR
          STRPOS(textInEnglish,'tourism') >1 OR STRPOS(textInEnglish,'tourist') >1 or
          STRPOS(textInEnglish,'travel') >1 OR STRPOS(textInEnglish,'visitor') >1 OR STRPOS(textInEnglish,'marina') >1 OR
          STRPOS(textInEnglish,'resort') >1 OR STRPOS(textInEnglish,'beach') >1 OR STRPOS(textInEnglish,'flats') >1 OR
          STRPOS(textInEnglish,'fleet') >1 OR STRPOS(textInEnglish,'ﬂight') >1 OR STRPOS(textInEnglish,'trip') >1 OR
          STRPOS(textInEnglish,'tour') >1 OR STRPOS(textInEnglish,'bistro') >1 OR STRPOS(textInEnglish,'abroad') >1 OR
          STRPOS(textInEnglish,'hostal') >1 OR STRPOS(textInEnglish,'hostel') >1 OR STRPOS(textInEnglish,'voyage') >1 OR
          STRPOS(textInEnglish,'Voyager') >1 OR
          STRPOS(textInEnglish,'vacation') >1 OR STRPOS(textInEnglish,'airline') >1 OR
          STRPOS(textInEnglish,'airport') >1 OR STRPOS(textInEnglish,'airways') >1 OR STRPOS(textInEnglish,'booking') >1 OR
          STRPOS(textInEnglish,'deposit') >1 OR STRPOS(textInEnglish,'passport') >1 OR
          STRPOS(textInEnglish,'terminal') >1 OR STRPOS(textInEnglish,'visit') >1 OR
          STRPOS(textInEnglish,'airplane') >1 OR STRPOS(textInEnglish,'catering') >1 OR
          STRPOS(textInEnglish,'spa hotel') >1 OR STRPOS(textInEnglish,'passenger') >1 OR
          STRPOS(textInEnglish,'off-season') >1 OR STRPOS(textInEnglish,'aerodrome') >1 OR
          STRPOS(textInEnglish,'snowsports') >1 OR STRPOS(textInEnglish,'seaview') >1
          ) AND STRPOS(textInEnglish,'ISIS') < 1 AND
          (classification1 not in ('MILITARY','POLITICS') AND classificationConfidence1 >0.90)`

    const extractSubstring = () => {
      if (query.interval) {
        const period = getIntervalPeriod(query.interval)

        if (period === 'hourly') {
          return `
            SUBSTR(createdAt,15,2) minutes,
            SUBSTR(createdAt,12,2) hours,
            SUBSTR(createdAt,9,2) days,
            SUBSTR(createdAt,6,2) months,
            SUBSTR(createdAt,1,4) years,
          `
        } else if (period === 'daily') {
          return `SUBSTR(createdAt,12,2) hours,
            SUBSTR(createdAt,9,2) days,
            SUBSTR(createdAt,6,2) months,
            SUBSTR(createdAt,1,4) years,
          `
        } else {
          return `SUBSTR(createdAt,9,2) days,
            SUBSTR(createdAt,6,2) months,
            SUBSTR(createdAt,1,4) years,
          `
        }
      }
    }

    const extractGroup = () => {
      if (query.interval) {
        const period = getIntervalPeriod(query.interval)

        if (period === 'hourly') {
          return `GROUP BY 1,2,3,4,5`
        } else if (period === 'daily') {
          return `GROUP BY 1,2,3,4`
        } else {
          return `GROUP BY 1,2,3`
        }
      }
    }

    const inquery = (key: string, params: object, seperator = 'AND') => {
      if (params) {
        const id = uid()

        inqueries.push([id, params, []])

        return `${seperator} ${key} IN(${id})`
      }

      return ''
    }

    try {
      sql = eval('`' + query.raw.sql + '`')

      if (inqueries.length) {
        const category =
          rules && rules.find((rule) => rule.field === 'classification1')
        const sentiment =
          rules && rules.find((rule) => rule.field === 'sentiment')
        const country =
          rules && rules.find((rule) => rule.field === 'countryDetection')
        const period = getIntervalPeriod(query.interval)

        let dateType = QueryCriteriaInterval.Daily

        if (period === 'monthly') {
          dateType = QueryCriteriaInterval.Monthly
        } else if (period === 'weekly') {
          dateType = QueryCriteriaInterval.Weekly
        }

        const values = await Promise.all(
          inqueries.map(([id, params]) =>
            getUserScreenNameList({
              ...params,
              sentiment: sentiment ? sentiment.value : null,
              country: country ? country.value : null,
              userCategory: category ? category.value : null,
              dateType,
            })
          )
        )

        inqueries.forEach(([id, params, data], index) => {
          const value = values[index]

          if (value) {
            sql = sql.replace(
              id,
              value.data.data.map((value) => `'${value}'`).join(',')
            )
          }

          inqueries[index][2] = values[index].data.data || []
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    query: sql.replace(/ {2}/gi, ' ').replace(/where and/gi, 'WHERE'),
    inqueries: inqueries.map(([id, params, data]) => ({
      name: id,
      params,
      values: data,
    })),
  }
}
