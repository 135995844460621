import { WidgetVersionType } from 'services/http/bi-tool/widget/enums'

export const WIDGET_CRITERIAS = [
  {
    label: 'Genel',
    value: '',
  },
  {
    label: 'Etkin Kişi',
    value: 'etkin_kisi',
  },
  {
    label: 'Gazete',
    value: 'gazete',
  },
  {
    label: 'Yazar',
    value: 'yazar',
  },
]

export const WIDGET_QUERY_TYPES = [
  {
    label: 'JSON',
    value: 'json',
  },
  {
    label: 'Raw (Özel Sorgu)',
    value: 'raw',
  },
  {
    label: 'HTTP (Web Servis)',
    value: 'http',
  },
]

export const WIDGET_VERSIONS = [
  {
    label: '1. Versiyon (SQL)',
    value: WidgetVersionType.Sql,
  },
  {
    label: '2. Versiyon (Druid)',
    value: WidgetVersionType.Druid,
  },
  {
    label: '3. Versiyon (Oracle)',
    value: WidgetVersionType.Oracle,
  },
]
