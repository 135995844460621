export const TWEET_FIELDS = [
  {
    label: 'Tweet ID',
    value: 'entityKey',
  },
  {
    label: 'Tweet metni',
    value: 'tweetText',
  },
  {
    label: 'Sınıflandırma',
    value: 'classifications',
  },
  {
    label: 'Konu',
    value: 'aspects',
  },
  {
    label: 'Yorum sayısı',
    value: 'commentCount',
  },
  {
    label: 'Beğeni sayısı',
    value: 'favoriteCount',
  },
  {
    label: 'Retweet sayısı',
    value: 'retweetCount',
  },
  {
    label: 'Ülke',
    value: 'countryDetection',
  },
  {
    label: 'Oluşturma tarihi',
    value: 'createdAt',
  },
  {
    label: 'AbsKeyword',
    value: 'absKeyword',
  },
  {
    label: 'AbsSentiment',
    value: 'absSentiment',
  },
  {
    label: 'Sentiment',
    value: 'sentiment',
  },
  {
    label: 'Galari',
    value: 'gallery',
  },
]

export const TWEET_USER_FIELDS = [
  {
    label: 'Kullanıcı ID',
    value: 'userEntityKey',
  },
  {
    label: 'Kullanıcı adı',
    value: 'userName',
  },
  {
    label: 'Kullanıcı görünen adı',
    value: 'userVisibleText',
  },
  {
    label: 'Kullanıcı takipçi sayısı',
    value: 'userFollowers',
  },
  {
    label: 'Kullanıcı takip sayısı',
    value: 'userFriends',
  },
  {
    label: 'Kullanıcı profil fotoğrafı',
    value: 'userProfileImage',
  },
  {
    label: 'Kullanıcı tweet sayısı',
    value: 'userTweetCount',
  },
  {
    label: 'Kullanıcı korumalı hesap',
    value: 'userProtected',
  },
  {
    label: 'Kullanıcı doğrulanmış hesap',
    value: 'userVerified',
  },
  {
    label: 'Kullanıcı oluşturulma tarihi',
    value: 'userCreatedAt',
  },
]
