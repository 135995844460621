import React from 'react'
import { Column, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import Textarea from '@zera-admin/textarea'

import * as Styled from '../FilterSettings.styled'

import { FilterTabProps } from '../types'

const FunctionsTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
}) => {
  const functions = [...(values.raw?.functions || [])]

  const renderFunctions = () => {
    return functions.map((item, index) => (
      <Styled.ListField key={index}>
        <Row xs={{ direction: 'row' }}>
          <Column>
            <Input
              name="name"
              label="Fonksiyon adı"
              placeholder="fonksiyon-1"
              value={item.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                functions[index].name = event.target.value
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')

                onChange({
                  ...values,
                  raw: { ...values.raw, functions },
                })
              }}
            />
          </Column>
          <Column>
            <Textarea
              name="value"
              label="SQL cümleciği"
              placeholder="SQL cümleciğini yazınız"
              value={item.value}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                try {
                  functions[index].value = event.currentTarget.value
                  onChange({ ...values, raw: { ...values.raw, functions } })
                } catch (err) {
                  console.log(err)
                }
              }}
            />
          </Column>
          <Button
            appearance="error"
            size="medium"
            iconBefore={<Icon name="cross" size="small" />}
            onClick={() => {
              functions.splice(index, 1)
              onChange({ ...values, raw: { ...values.raw, functions } })
            }}
          />
        </Row>
      </Styled.ListField>
    ))
  }

  return (
    <React.Fragment>
      <Row xs={{ direction: 'row' }}>
        <Column xs={{ size: 12 }}>
          <p>
            Aşağıdan sorgularınıza özel bir fonksiyon SQL cümleciği
            ekleyebilirsiniz. Eklediğiniz fonksiyonlar dashboard'a ait tüm
            widget'lara yansıyacaktır.
          </p>
        </Column>
      </Row>
      {renderFunctions()}
      <Button
        iconBefore={<Icon name="add" size="small" />}
        onClick={() => {
          functions.push({
            name: '',
            value: '',
          })

          onChange({ ...values, raw: { ...values.raw, functions } })
        }}
        style={{ marginTop: 12 }}
      >
        Fonksiyon ekle
      </Button>
    </React.Fragment>
  )
}

export default FunctionsTab
