import React from 'react'

import InfoCard from '../report-card'
import { NegativeCountriesReportProps } from './types'
import TwitterIcon from './twitter-new-logo-red.png'
const NegativeCountriesReport: React.FunctionComponent<NegativeCountriesReportProps> =
  ({ data }) => {
    return (
      <InfoCard icon={TwitterIcon} title="Olumsuz Ülkeler">
        <table className="info-card-table--three">
          <thead>
            <tr>
              <th>Country</th>
              <th>Post Sayıları</th>
              <th>Yüzdesi</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((location: any) => (
              <tr>
                <td>{location.userCountryDetection}</td>
                <td>{location.rowCount.toLocaleString('tr-TR')}</td>
                <td>{`${location.percent}%`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfoCard>
    )
  }
export default NegativeCountriesReport
