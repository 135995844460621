import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import Spinner from '@zera-admin/spinner'

import { PersonReportResponse } from 'services/http/report/person-report/type'
import personReportService from 'services/http/report/person-report'

import * as Styled from './Report.styled'
import AllReport from './report-detail/AllReport'

const Report: React.FunctionComponent = () => {
  const [report, setReport] = useState<PersonReportResponse>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const data = report?.data

  const router = useRouteMatch()

  const getReport = (id: string) => {
    setIsLoading(true)
    personReportService
      .getByGroupId(id)
      .then((res) => {
        setReport(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    // @ts-expect-error: Bilinçli olarak bir hata göz ardı ediliyor
    const groupId = router.params.id
    getReport(groupId)
    // @ts-expect-error: Bilinçli olarak bir hata göz ardı ediliyor
  }, [router.params.id])

  return isLoading ? (
    <Styled.LoadingView>
      <Spinner size="large" />
      <h5>Rapor yükleniyor</h5>
    </Styled.LoadingView>
  ) : (
    <Styled.Report>
      <AllReport data={data} />
      <div style={{ height: 400 }} />
    </Styled.Report>
  )
}

export default Report
