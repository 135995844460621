import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Lozenge from '@zera-admin/lozenge'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import { SentimentEnum } from 'services/http/dataset'
import sentimenttControlSetsService, {
  SentimentControlSet,
  SentimentControlSetResponse,
} from 'services/http/dataset/sentiment-control-set'

import { SentimentControlSetListProps } from './types'

const SentimentControlSetList: React.FunctionComponent<
  SentimentControlSetListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [sentimentControlSet, setSentimentControlSet] =
    useState<SentimentControlSetResponse>()

  useEffect(() => {
    getSentimentControlSets()
  }, [])

  const getSentimentControlSets = async () => {
    try {
      const response = await sentimenttControlSetsService.get()

      setSentimentControlSet(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/sentiment-control-set/create">
        Duygu kontrol seti oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Duygu kontrol setleri" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm duygu kontrol setlerini görüntüleyebilir, yeni oluşturabilir,
        varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Duygu kontrol setleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'text',
        text: 'Duygu kontrol seti metni',
      },
      {
        name: 'languageCode',
        text: 'Dil kodu',
      },
      {
        name: 'conclusion',
        text: 'Duygusallık durumu',
        render: (props: SentimentControlSet) => {
          if (props.conclusion === SentimentEnum.Positive) {
            return <Lozenge appearance="success">Pozitif</Lozenge>
          } else if (props.conclusion === SentimentEnum.Negative) {
            return <Lozenge appearance="error">Negatif</Lozenge>
          } else {
            return <Lozenge appearance="info">Nötr</Lozenge>
          }
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: SentimentControlSet) => (
          <LinkButton
            appearance="subtle-link"
            href={`/sentiment-control-set/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (sentimentControlSet) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={sentimentControlSet.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={sentimentControlSet.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default SentimentControlSetList
