import React, { useCallback, useEffect, useState } from 'react'
import Button, { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Table from '@zera-admin/table'
import Form from '@zera-admin/form'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import Lozenge from '@zera-admin/lozenge'

import { string } from 'app/functions'
import MainLayout from 'layouts/main'
import userService, {
  UserListRequest,
  UserListResponse,
  UserResponse,
} from 'services/http/identity-server/user'
import Pager from 'components/pager'

import { UserListProps } from './types'

const UserList: React.FunctionComponent<UserListProps> = ({
  location,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<UserListResponse>()
  const [filter, setFilter] = useState<UserListRequest>({
    pageSize: 15,
    pageIndex: 1,
  })

  const getValues = useCallback(async (request: UserListRequest) => {
    try {
      const response = await userService.get(request)

      setValues(response.data)
    } catch (err) {
      setValues({ data: [], meta: { totalData: 0, totalPage: 0 } })
    }

    setIsLoading(false)
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    getValues({
      ...filter,
      pageSize: 15,
      pageIndex: parseInt(searchParams.get('pageIndex') || '1') - 1,
    })
  }, [getValues, location.search, filter])

  const handleSearch = (values: UserListRequest) => {
    const searchParams = new URLSearchParams(location.search)

    searchParams.set('pageIndex', '1')
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })

    setIsLoading(true)
    setFilter(values)
  }

  const renderFilter = () => {
    return (
      <Form onSubmit={handleSearch} style={{ marginBottom: '10px' }}>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 3 }}>
            <Input
              description="Aramak istediğiniz kullanıcı adını giriniz."
              label="Kullanıcı adı"
              name="userName"
            />
          </Column>
          <Column xs={{ size: 3 }}>
            <Input
              description="Aramak istediğiniz kullanıcının e-posta adresini giriniz."
              label="E-posta adresi"
              name="email"
            />
          </Column>
          <Column>
            <Button
              appearance="primary"
              children="Arama yap"
              iconBefore={<Icon size="small" name="search" />}
              type="submit"
            />
          </Column>
        </Row>
      </Form>
    )
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Kullanıcılar" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Page>
        <PageHeader
          actions={
            <LinkButton appearance="primary" href="/user/create">
              Kullanıcı oluştur
            </LinkButton>
          }
          bottomBar={
            <p>
              Kullanıcı yönetimi üzerinde kayıtlı tüm kullanıcıları
              görüntüleyebilir, yeni kullanıcı oluşturabilir veya
              düzenleyebilirsiniz.
            </p>
          }
          breadcrumbs={breadcrumbs}
        >
          Kullanıcılar
        </PageHeader>
        {renderFilter()}
        <Table
          columns={
            [
              {
                name: 'name',
                text: 'İsim ve soyisim',
                width: '200px',
                render: (row: any) => (
                  <span>
                    {row.name || '-'} {row.surname || '-'}
                  </span>
                ),
              },
              {
                name: 'userName',
                text: 'Kullanıcı adı',
              },
              {
                name: 'email',
                text: 'E-posta adresi',
              },
              {
                name: 'phoneNumber',
                text: 'Telefon numarası',
                render: (row: any) => string.phoneNumber(row.phoneNumber),
              },
              {
                name: 'roles',
                text: 'Rol / Roller',
                render: (row: UserResponse) => (
                  <Row>
                    {(row.roles || []).slice(0, 2).map((role, index) => (
                      <span style={{ marginRight: 5 }} key={index}>
                        <Lozenge
                          key={index}
                          appearance="info"
                          children={role.title}
                        />
                      </span>
                    ))}
                    {row.roles.length > 2 ? '...' : ''}
                  </Row>
                ),
              },
              {
                name: 'operation',
                text: 'İşlem',
                width: '100px',
                render: (row: any) => (
                  <LinkButton
                    appearance="subtle-link"
                    href={`/user/update/${row.id}`}
                    size="none"
                    style={{ padding: '5px 0px' }}
                  >
                    Görüntüle
                  </LinkButton>
                ),
              },
            ] as any
          }
          rows={values ? values.data : []}
          helperMessage="Aradığınız kriterlere ait sonuç bulunamadı, lütfen tekrar deneyin."
          isLoading={isLoading}
        />
        <Pager pages={values?.meta.totalPage || 1} />
      </Page>
    </MainLayout>
  )
}

export default UserList
