import React, { useState } from 'react'

import ComponentHeader from 'bi-tool/drag-drop/ComponentHeader'
import Droppable from 'components/designer/Droppable'
import { useSchemaService } from 'app/library/layout-builder'

import { ColumnProps } from './types'
import Modal from '@zera-admin/modal'
import { Column, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'

const ColumnDesigner: React.FunctionComponent<ColumnProps> = ({
  children,
  defaultElement,
  entry,
  id,
  sizes,
}) => {
  const size = sizes?.lg || '6'

  const [modal, setModal] = useState<ColumnProps | null>(null)

  const schemaService = useSchemaService()

  const handleChangeProperties = (value: object) => {
    schemaService.changeProperties(id as string, value)
  }

  const renderComponentSettings = () => {
    if (modal) {
      const actions = [
        {
          text: 'Ayarları kaydet',
          onClick: () => {
            setModal(null)
            handleChangeProperties(modal)
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]

      return (
        <Modal
          title="Sütun ayarları"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>
            Dashboard'a sütun ekleyerek ve özelliklerini yöneterek
            görüntüleyebilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                name="xs"
                label="Mobil Genişlik"
                value={modal.sizes?.xs || 12}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({
                    ...modal,
                    sizes: { ...modal.sizes, xs: parseInt(event.target.value) },
                  })
                }
                type="number"
              />
            </Column>
            <Column>
              <Input
                name="sm"
                label="Tablet Genişlik"
                value={modal.sizes?.sm || 12}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({
                    ...modal,
                    sizes: { ...modal.sizes, sm: parseInt(event.target.value) },
                  })
                }
                type="number"
              />
            </Column>
            <Column>
              <Input
                name="md"
                label="Laptop Genişlik"
                value={modal.sizes?.md || 12}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({
                    ...modal,
                    sizes: { ...modal.sizes, md: parseInt(event.target.value) },
                  })
                }
                type="number"
              />
            </Column>
            <Column>
              <Input
                name="lg"
                label="Masaüstü Genişlik"
                value={modal.sizes?.lg || 12}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({
                    ...modal,
                    sizes: { ...modal.sizes, lg: parseInt(event.target.value) },
                  })
                }
                type="number"
              />
            </Column>
          </Row>
          <br />
        </Modal>
      )
    }
  }

  const renderInformation = () => {
    if ((children && children.length > 0) || !defaultElement) {
      return null
    }
  }

  return (
    <React.Fragment>
      {renderInformation()}
      {renderComponentSettings()}
      <ComponentHeader
        componentEntry={entry}
        key={id}
        titleRender={() => `${size} / 12`}
        componentSchemaId={id as string}
        componentIsColumn={!defaultElement}
        componentSize={size}
        componentIsDefault={defaultElement}
        onEdit={() => setModal({ sizes })}
      />
      <Droppable
        elementId={id as string}
        elementType="column"
        style={{ alignContent: 'baseline', paddingBottom: '50px' }}
        className="fa-droppable-act row"
      >
        {children}
      </Droppable>
    </React.Fragment>
  )
}

export default ColumnDesigner
