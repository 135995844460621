import React, { useState } from 'react'
import Modal from '@zera-admin/modal'
import { Column, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'
import EmptyState from '@zera-admin/empty-state'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'

import { useSchemaService } from 'app/library/layout-builder'
import NotFoundImage from 'assets/images/not-found.png'

import ComponentHeader from '../ComponentHeader'
import * as Styled from './Image.styled'
import { ImageProps } from './types'

const Image: React.FunctionComponent<ImageProps> = ({
  designing,
  entry,
  src,
  id,
}) => {
  const [modal, setModal] = useState<ImageProps | null>(null)

  const schemaService = useSchemaService()

  const handleChangeProperties = (value: object) => {
    schemaService.changeProperties(id as string, value)
  }

  const renderDesigner = () => (
    <Styled.ImageEmpty>
      <EmptyState
        title="Görsel"
        description="Görsellerinizi dashboard üzerinde görüntüleyebilirsiniz."
        image={{ src: src ? src : NotFoundImage }}
        actions={
          <Button
            iconBefore={<Icon name="preferences" size="small" />}
            onClick={() => {
              setModal({ src })
            }}
          >
            Görsel ayarları
          </Button>
        }
      />
    </Styled.ImageEmpty>
  )

  const renderComponentSettings = () => {
    if (modal) {
      const actions = [
        {
          text: 'Ayarları kaydet',
          onClick: () => {
            setModal(null)
            handleChangeProperties(modal)
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]

      return (
        <Modal
          title="Görsel ayarları"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>
            Aşağıdan görsel elementi için ayarlandırmalarınızı yapabilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                name="src"
                label="Görsel URL"
                value={modal.src}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({ ...modal, src: event.target.value })
                }
              />
            </Column>
          </Row>
          <br />
        </Modal>
      )
    }
  }

  return (
    <div
      data-schema-id={id}
      className={['dnd-widget', designing && 'fake-draggable']
        .filter((e) => !!e)
        .join(' ')}
    >
      {designing ? (
        <React.Fragment>
          {renderComponentSettings()}
          <ComponentHeader
            componentEntry={entry}
            componentSchemaId={id as string}
          />
          {renderDesigner()}
        </React.Fragment>
      ) : (
        <Styled.Image>
          <img src={src} alt="Element" />
        </Styled.Image>
      )}
    </div>
  )
}

export default Image
