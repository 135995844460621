import { ContainerEntry } from './entry'

export class Container {
  entries: ContainerEntry[]

  constructor(entries: ContainerEntry[]) {
    this.entries = entries
  }

  register(entry: ContainerEntry): void {
    let foundIndex = this.entries.findIndex((r) => r.name === entry.name)

    if (foundIndex >= 0) {
      this.entries[foundIndex] = entry
    } else {
      this.entries.push(entry)
    }
  }

  resolve(name: string): ContainerEntry {
    let entry = this.entries.find((x) => x.name === name)

    if (entry) return entry

    throw new Error("Couldn't find ContainerEntry with given Name: " + name)
  }
}
