export enum BotConclusionEnum {
  Bot = 'bot',
  NotBot = 'notBot',
}

export enum DependentEnum {
  Left = 1,
  Right = 2,
}

export enum GenderTypeEnum {
  Male = 'male',
  Female = 'female',
  Unknown = 'unknown',
}

export enum LanguageCode {
  Tr = 'tr',
  En = 'en',
  Fs = 'fs',
}

export enum RunningStatusEnum {
  Processing = 'processing',
  TrainingStarted = 'trainingStarted',
  TrainingEnded = 'trainingEnded',
  TrainingError = 'trainingError',
  Pending = 'pending',
}

export enum SentimentEnum {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral',
}

export enum StatusEnum {
  Published = 'published',
  OldVersion = 'oldVersion',
  OnGoingTrain = 'onGoingTrain',
  Deleted = 'deleted',
}

export enum DataSetTypeEnum {
  SentimentDataSet = 'sentimentDataSet',
  BotClassification = 'botClassification',
  NamedEntityRecognition = 'namedEntityRecognition',
  GenderDataSet = 'genderDataSet',
  ClassificationDataSet = 'classificationDataSet',
}

export enum BlackListEnum {
  User = 1,
  Word = 2,
}

export enum NewsListEnum {
  User = 1,
  Word = 2,
}
