import { Field, FieldRequest } from './types'

export const mapFieldForRequest = (raw: FieldRequest): unknown => ({
  datasource: raw.datasource,
  table: raw.table,
})

export const mapFieldForResponse = (raws: any[]): Field[] =>
  (raws || []).map((raw) => ({
    field: raw.field,
    alias: raw.alias,
    type: raw.type,
    input: raw.input,
    source: raw.source,
    data: raw.data,
  }))
