import { useEffect, useState } from 'react'
import { SocialCountProps } from './types'
import WidgetLayout from '../../widgets/widget-layout'
import UpdateButton from '../../components/update-button/UpdateButton'
import * as Styled from './SocialCounts.styled'
import SocialCard from './social-card/SocialCard'
import { Count } from '../../widgets/types'

const SocialCounts: React.FC<SocialCountProps> = ({
  data,
  title,
  isEditValue,
  setIsEditValue,
  date,
  pageNumber,
  metaData,
}) => {
  const [socialCountsData, setSocialCountsData] = useState<Count[] | undefined>(
    data
  )
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setSocialCountsData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChange = (
    key: string,
    value: string,
    source: string,
    type: string
  ) => {
    setSocialCountsData(
      socialCountsData?.map((item) => {
        if (item.Source === source && item.Type === type) {
          return {
            ...item,
            [key]: value,
          }
        }
        return item
      })
    )
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData?.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
    >
      <Styled.SocialCountsContainer>
        <UpdateButton
          {...editProps}
          contentData={{ Counts: socialCountsData }}
          title={pageData.title}
          editMode={editMode}
          setEditMode={setEditMode}
        />

        <Styled.SocialCountsContentContainer>
          <div className="twitter-container">
            {socialCountsData
              ?.filter((item) => item.Source === 'Twitter')
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>
          <div
            className={
              socialCountsData &&
              socialCountsData?.filter(
                (item) =>
                  item.Source === 'Reddit' ||
                  item.Source === 'News' ||
                  item.Source === 'EksiSozluk'
              ).length > 0
                ? 'reddit-container'
                : 'reddit-container hidden'
            }
          >
            {socialCountsData
              ?.filter(
                (item) =>
                  item.Source === 'Reddit' ||
                  item.Source === 'News' ||
                  item.Source === 'EksiSozluk'
              )
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>

          <div
            className={
              socialCountsData &&
              socialCountsData?.filter((item) => item.Source === 'Instagram')
                .length > 0
                ? 'instagram-container'
                : 'instagram-container hidden'
            }
          >
            {socialCountsData
              ?.filter((item) => item.Source === 'Instagram')
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>

          <div
            className={
              socialCountsData &&
              socialCountsData?.filter((item) => item.Source === 'Quora')
                .length > 0
                ? 'quora-container'
                : 'quora-container hidden'
            }
          >
            {socialCountsData
              ?.filter((item) => item.Source === 'Quora')
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>

          <div
            className={
              socialCountsData &&
              socialCountsData?.filter((item) => item.Source === 'Mastodon')
                .length > 0
                ? 'mastodon-container'
                : 'mastodon-container hidden'
            }
          >
            {socialCountsData
              ?.filter((item) => item.Source === 'Mastodon')
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>

          <div className="tiktok-container">
            {socialCountsData
              ?.filter((item) => item.Source === 'TikTok')
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>
          <div className="telegram-container">
            {socialCountsData
              ?.filter((item) => item.Source === 'Telegram')
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>
          <div className="youtube-container">
            {socialCountsData
              ?.filter((item) => item.Source === 'Youtube')
              .map((item, index) => (
                <SocialCard
                  key={index}
                  data={item}
                  editMode={editMode}
                  onChange={onChange}
                />
              ))}
          </div>
        </Styled.SocialCountsContentContainer>
      </Styled.SocialCountsContainer>
    </WidgetLayout>
  )
}

export default SocialCounts
