import styled from 'styled-components'

export const ParagraphEmpty = styled.div`
  padding: 30px 0px 20px 0px;

  & > div {
    margin: 0 auto;
    font-size: 0.8rem;

    img {
      max-height: 100px;
    }

    p {
      margin-bottom: 12px;
    }
  }
`

export const Paragraph = styled.div`
  padding: 1rem;
  box-sizing: border-box;
`
