import { request } from '../instance'

export const getVideoService = ({ key }: { key: string }) =>
  request<any>({
    method: 'get',
    url: 'api/common/video-url',
    params: {
      Key: key,
    },
  })
