import styled from 'styled-components'

export const Counter = styled.div<{ theme: boolean }>`
  margin: 7.5em auto;
  text-align: center;
  background: ${(props) =>
    props.theme === 'dark' ? 'rgb(66, 66, 66)' : 'transparent'};
  padding: ${(props) => (props.theme === 'dark' ? '2rem' : 'inherit')};
  color: ${(props) => (props.theme === 'dark' ? '#fff' : 'inherit')};
  box-sizing: border-box;

  h3 {
    margin: 0px;
    font-size: 1.35em;
    font-weight: 400;
    color: ${(props) => (props.theme === 'dark' ? '#fff' : 'inherit')};
  }
`
export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .counter {
    font-size: 7em;
    font-weight: 600;
    padding: 0 2rem;
  }

  span {
    font-size: 1.5rem;
  }
`
