import React, { useState } from 'react'
import * as yup from 'yup'
import { Column, Row } from '@zera-admin/page'
import Form, { useForm } from '@zera-admin/form'
import Modal from '@zera-admin/modal'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { validation } from 'app/functions'
import { botConclusion } from 'app/shared/constants'
import botClassificationsService, {
  CreateBotClassificationRequest,
} from 'services/http/dataset/bot-classification'

import { BotClassificationProps } from '../types'

const validations = {
  isBot: yup.string().required('Cinsiyet seçmelisiniz'),
}

const BotClassification: React.FunctionComponent<BotClassificationProps> = ({
  onClose,
  values,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()

  const form = useForm<CreateBotClassificationRequest>({
    initialValues: values,
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = () => {
    setIsLoading(true)
    botClassificationsService
      .create(form.values)
      .then(() => {
        flagcard.add({
          appearance: 'success',
          title: 'Kayıt işlemi başarılı',
          children:
            'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
        })
        onClose?.()
      })
      .catch((error) => {
        console.log(error)

        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Modal
      actions={[
        {
          appearance: 'primary',
          text: 'Kaydet',
          onClick: () => handleSubmit(),
          isLoading: isLoading,
        },
        {
          appearance: 'subtle',
          text: 'Vazgeç',
          onClick: onClose,
        },
      ]}
      width="medium"
      onClose={onClose}
      title="Bot sınıflandırması eğit"
    >
      <Form>
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Select
              errorMessage={form.errors.isBot}
              label="Bot durumu"
              name="isBot"
              options={botConclusion}
              placeholder="Seçim yapın"
              value={botConclusion.find(
                (bot) => bot.value === form.values.isBot
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'isBot',
                  (props as SelectOptionProps).value
                )
              }
              position="fixed"
            />
          </Column>
        </Row>
      </Form>
    </Modal>
  )
}

export default BotClassification
