import React from 'react'
import { IIntroProps } from './types'
import * as Styled from './Intro.styled'

import atmacaLogo from '../../widgets/widget-layout/logo/alice-white.png'

function Intro({ title, date }: IIntroProps) {
  const startDate = new Date(date?.startDate ? date?.startDate : '')
  const endDate = new Date(date?.endDate ? date?.endDate : '')

  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(endDate)

  function formatDate(date: any) {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const formattedDay = day < 10 ? `0${day}` : day
    const formattedMonth = month < 10 ? `0${month}` : month

    return `${formattedDay}-${formattedMonth}-${year}`
  }

  return (
    <Styled.IntroContainer>
      <div className="intro-header">
        <h1 className="intro-header-title">{title}</h1>
        <p className="intro-header-date">
          {formattedStartDate} - {formattedEndDate}
        </p>
      </div>
      <div className="intro-footer">
        <img src={atmacaLogo} alt="Alice-Project-Logo" />
      </div>
    </Styled.IntroContainer>
  )
}

export default Intro
