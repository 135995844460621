import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { getCategory, getCategoryName } from 'app/functions'

import { GeneralCategoriesProps } from './types'

const GeneralCategories: React.FunctionComponent<GeneralCategoriesProps> = ({
  data,
}) => {
  const generalCategories: Props = {
    options: {
      chart: {
        id: 'generalCategories',
      },
      colors: data?.map((item: any) => getCategory(item.name)),
      xaxis: {
        categories: data?.map((item: any) => getCategoryName(item?.name)),
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          distributed: true,
        },
      },
      tooltip: {},
      legend: {
        show: true,
        position: 'top',
      },
    },
    series: [
      {
        name: 'sayısı',
        data: data?.map((item: any) => item?.score.toFixed(2)),
      },
    ],
  }

  return data && data.length > 0 ? (
    <Chart
      options={generalCategories.options}
      series={generalCategories.series}
      type="bar"
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default GeneralCategories
