import { Tweet, TweetResponse } from './types'

export const mapTweetForResponse = (raw: any): Tweet => ({
  content: raw.data.content,
  entityKey: raw.data.entity_key,
  likeCount: raw.data.like_count,
  personId: raw.data.person_id,
  profilePicture: raw.data.profile_picture,
  retweetCount: raw.data.retweet_count,
  userName: raw.data.user_name,
})

export const mapTweetForList = (raw: any): TweetResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapTweetForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
