import React, { useState, useEffect } from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import { Row, Column } from '@zera-admin/page'
import Input from '@zera-admin/input'
import Textarea from '@zera-admin/textarea'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { uid } from '@zera-admin/utils'

import { usePopupContext } from 'app/contexts/popup'

import * as Styled from './DashboardSettings.styled'
import { DashboardSettingsProps, DashboardSettingsValues } from './types'

const DashboardSettings: React.FunctionComponent<DashboardSettingsProps> = ({
  onDelete,
  onDismiss,
  onSave,
  show,
  sources,
  tables,
  values,
}) => {
  const [settings, setSettings] = useState<DashboardSettingsValues>(values)
  const [popup] = usePopupContext()

  useEffect(() => {
    if (values) {
      setSettings(values)
    }
  }, [values])

  const getSourceOption = () => {
    if (!settings.query.source) return undefined

    const source = sources.find((source) => source.id === settings.query.source)

    return {
      value: settings.query.source,
      label: source?.name as string,
    }
  }

  const getTableOption = () => {
    if (!settings.query.table) return undefined

    const table = tables.find((table) => table.id === settings.query.table)

    return {
      value: settings.query.table,
      label: table?.name as string,
    }
  }

  const handleDelete = () => {
    const popupId = uid()

    popup.add({
      id: popupId,
      appearance: 'warning',
      title: 'Dashboard silinsin mi?',
      children:
        "Geçerli dashboard'ı silmek istiyorsanız 'Onayla' butonuna basabilirsiniz",
      actions: [
        {
          text: 'Onayla',
          onClick: () => {
            popup.remove(popupId)

            if (onDelete) onDelete()
          },
        },
        {
          text: 'İptal',
          onClick: () => popup.remove(popupId),
          appearance: 'subtle-link',
        },
      ],
      onClose: () => {
        popup.remove(popupId)
      },
    })
  }

  const renderDrawerBody = () => (
    <Styled.DashboardSettingsField>
      <Row xs={{ direction: 'column' }}>
        <Column>
          <Input
            name="name"
            label="Dashboard adı"
            placeholder="Yeni dashboard 1"
            value={settings.title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSettings({ ...settings, title: event.target.value })
            }
          />
        </Column>
        <Column>
          <Textarea
            name="description"
            label="Dashboard açıklaması"
            value={settings.description}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setSettings({ ...settings, description: event.target.value })
            }
          />
        </Column>
        <Column>
          <Select
            name="source"
            label="Veri kaynağı"
            placeholder="Veri kaynağı seçiniz"
            options={sources.map((source) => ({
              label: source.name,
              value: source.id,
            }))}
            value={getSourceOption()}
            onChange={(props) => {
              setSettings({
                ...settings,
                query: {
                  ...settings.query,
                  source: (props as SelectOptionProps).value as string,
                },
              })
            }}
          />
        </Column>
        <Column>
          <Select
            key="table"
            name="table"
            label="Veri tablosu"
            isDisabled={!settings.query.source}
            placeholder="Tablo seçiniz"
            options={tables.map((table) => ({
              label: table.name,
              value: table.id,
            }))}
            value={getTableOption()}
            onChange={(props) =>
              setSettings({
                ...settings,
                query: {
                  ...settings.query,
                  table: (props as SelectOptionProps).value as string,
                },
              })
            }
          />
        </Column>
        <Column>
          <Input
            name="key"
            label="Dashboard anahtarı"
            placeholder="tweet-dashboard"
            value={settings.key}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSettings({
                ...settings,
                key: event.target.value?.toLowerCase(),
              })
            }
          />
        </Column>
        {values.id && (
          <Column>
            <Button
              className="button-subtle--delete"
              appearance="subtle"
              onClick={handleDelete}
            >
              Dashboard'ı sil
            </Button>
          </Column>
        )}
      </Row>
    </Styled.DashboardSettingsField>
  )

  const renderDrawer = () => {
    const actions = (
      <ButtonGroup>
        <Button
          appearance="primary"
          onClick={() => {
            onSave(settings)
            onDismiss()
          }}
        >
          Ayarları uygula
        </Button>
        <Button onClick={onDismiss}>Kapat</Button>
      </ButtonGroup>
    )

    return (
      <Drawer
        isOpen={show}
        onClose={onDismiss}
        width="medium"
        actions={actions}
        title="Dashboard ayarları"
        description="Aşağıdan dashboard üzerinde genel ayarlandırmaları yapabilirsiniz."
      >
        {renderDrawerBody()}
      </Drawer>
    )
  }

  return renderDrawer()
}

export default DashboardSettings
