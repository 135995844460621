import React from 'react'
import { Column, Row } from '@zera-admin/page'
import ConditionBuilder, {
  ConditionBuilderValue,
} from '@zera-admin/condition-builder'

import { FieldStaticData } from 'services/http/bi-tool/field/types'

import { FilterTabProps } from '../types'

const ConditionTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
  fields,
  type,
}) => {
  const renderConditionTab = () => {
    const options =
      type !== 'raw' && type !== 'http'
        ? fields.map((value) => ({
            name: value.field,
            type: value.type,
            options:
              value.source === 'static'
                ? ((value.data as FieldStaticData)?.values || []).map(
                    (option) => ({
                      label: option.value,
                      value: option.key,
                    })
                  )
                : undefined,
          }))
        : []

    return (
      <Row xs={{ direction: 'column' }}>
        <Column>
          <p>
            Aşağıdan dinamik olarak, tablo alanlarına göre sorgularınızı
            oluşturabilirsiniz.
          </p>
        </Column>
        <Column>
          <Row xs={{ gutter: 'xlarge' }}>
            <ConditionBuilder
              defaultValue={values.where as ConditionBuilderValue}
              onChange={(value) => onChange({ ...values, where: value })}
              fields={options}
            />
          </Row>
        </Column>
      </Row>
    )
  }

  return renderConditionTab()
}

export default ConditionTab
