import { request } from '../instance'

import {
  mapClaimListForResponse,
  mapClaimListForRequest,
  mapClaimForUpdateRequest,
  mapClaimForResponse,
  mapClaimForCreateRequest,
} from './mappers'
import {
  ClaimUpdateRequest,
  ClaimListRequest,
  ClaimListResponse,
  ClaimCreateRequest,
  ClaimResponse,
} from './types'

export const create = (data: ClaimCreateRequest) =>
  request<ClaimResponse>({
    method: 'post',
    url: 'claim',
    data,
    mappers: {
      req: mapClaimForCreateRequest,
      res: mapClaimForResponse,
    },
  })

export const get = (params?: ClaimListRequest, value?: string) =>
  request<ClaimListResponse>({
    method: 'get',
    params,
    url: 'claim',
    mappers: {
      req: mapClaimListForRequest,
      res: mapClaimListForResponse,
    },
  })

export const getById = (id: number) =>
  request<ClaimResponse>({
    method: 'get',
    url: `claim/${id}`,
    mappers: {
      res: mapClaimForResponse,
    },
  })

export const update = (data: ClaimUpdateRequest) =>
  request<ClaimResponse>({
    method: 'put',
    url: 'claim',
    data,
    mappers: {
      req: mapClaimForUpdateRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `claim/${id}`,
  })
