import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { getMonths } from 'app/functions'

import { AspectProps } from './types'
import { colors } from '@atlaskit/theme'

const Aspect: React.FunctionComponent<AspectProps> = ({
  data,
  chartHeight,
}) => {
  const aspect: Props = {
    options: {
      chart: {
        id: 'aspect',
      },
      xaxis: {
        categories: data?.entities.map((entity: any) => entity.entity),
      },
      colors: [colors.G400, colors.R400, colors.DN100],
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      title: {
        align: 'center',
        text: getMonths(data?.month) || '',
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
      },
    },
    series: [
      {
        data: data?.entities?.map((pos: any) => pos?.posCount),
        name: 'Pozitif',
      },
      {
        data: data?.entities?.map((neg: any) => neg?.negCount),
        name: 'Negatif',
      },
      {
        data: data?.entities?.map((neu: any) => neu?.neuCount),
        name: 'Nötr',
      },
    ],
  }

  return data && Object.keys(data).length > 0 ? (
    <Chart
      options={aspect.options}
      series={aspect.series}
      type="bar"
      height={chartHeight}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Aspect
