import styled from 'styled-components'

export const WordCloudContentContainer = styled.div<{ editmode: boolean }>`
  display: flex;
  flex-direction: column;
  width: 98%;

  .word-cloud-index {
    height: 100%;
    margin: 0px auto;
    ${(props) =>
      props.editmode === false &&
      `transform: scale(1.2); z-index: -1 !important; `}
  }

  .word-cloud-add {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    &-input {
      display: flex;
      gap: 1rem;
      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & input {
          border: none;
          border-bottom: 1px solid black;
        }
        & button {
          margin-top: 1.1rem;
          background-color: #08a0e5;
          border: none;
          color: white;
          padding: 8px;
          border-radius: 8px;
          cursor: pointer;
          &:hover {
            transition: 0.3s;
            background-color: lightgreen;
          }
        }
      }
    }
  }
`
