/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Lozenge from '@zera-admin/lozenge'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import blackListService, {
  BlackList as BlackListProp,
  BlackListResponse,
} from 'services/http/backoffice/black-list'
// import { BlackListEnum } from 'services/http/dataset'

import { BlackListProps } from './types'

const BlackList: React.FunctionComponent<BlackListProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [blackList, setBlackList] = useState<BlackListResponse>()

  const [pageIndex, setPageIndex] = useState<number>(0)

  useEffect(() => {
    getBlackLists()
  }, [pageIndex])

  const getBlackLists = async () => {
    try {
      const response = await blackListService.get(pageIndex)

      setBlackList(response.data)
      setIsLoading(false)
    } catch (err) {
      const ex: any = err
      console.log(ex)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/black-list/create">
        Black List kaydı oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Black List" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm Black List'leri görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Black List
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'value',
        text: 'Değer',
      },
      {
        name: 'dataSource',
        text: 'Veri Kaynağı',
      },
      {
        name: 'createdAt',
        text: 'Oluşturulma tarihi',
        render: (props: BlackListProp) => {
          return new Date(props.createdAt).toLocaleString()
        },
      },
      {
        name: 'type',
        text: 'Tip',
        render: (props: BlackListProp) => {
          if (props.type === 'User') {
            return <Lozenge appearance="info">Kullanıcı</Lozenge>
          }
          return <Lozenge appearance="discovery">Kelime</Lozenge>
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: BlackListProp) => (
          <LinkButton
            appearance="subtle-link"
            href={`/black-list/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (blackList) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={blackList.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager
            pages={blackList.pageCount}
            onChange={(index) => setPageIndex(index - 1)}
          />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default BlackList
