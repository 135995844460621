import { useState } from 'react'
import { Link } from 'react-router-dom'
import { storage } from '@zera-admin/helpers'
import { uid } from '@zera-admin/utils'
import Button from '@zera-admin/button'
import Checkbox from '@zera-admin/checkbox'
import Form, { FormFooter, FormHeader, FormSection } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Page, { Row, Column } from '@zera-admin/page'

import { usePopupContext } from 'app/contexts/popup'
import MembershipLayout from 'layouts/membership'
import authService, {
  AuthLoginRequest,
} from 'services/http/identity-server/auth'
import { LoginType } from 'services/http/identity-server/enums'

import { SignInProps } from './types'

const board = (
  <ul>
    <li>
      <Link to="/#privacy-policy">Gizlilik politikası</Link>
    </li>
    <li>
      <Link to="/#user-notice">Kullanıcı sözleşmesi</Link>
    </li>
  </ul>
)

const SignIn: React.FunctionComponent<SignInProps> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [popup] = usePopupContext()

  const handleSubmit = async (data: AuthLoginRequest) => {
    setIsLoading(true)

    try {
      const request = { ...data, appName: 'backoffice' }
      const response = await authService.login(request)

      if (response.data) {
        const { loginType, accessToken, refreshToken, email } = response.data

        if (loginType === LoginType.OnlyPassword) {
          storage.set('token', accessToken)
          storage.set('refreshToken', refreshToken)
          history.push('/')
        } else {
          const encodedEmail = btoa(unescape(encodeURIComponent(email)))

          history.push(`/verify-account?email=${encodedEmail}`)
        }
      }
    } catch (err) {
      popup.add({
        id: uid(),
        title: 'Giriş yapılamadı',
        children: 'Kullanıcı adı ya da şifre hatalı',
      })
    }

    setIsLoading(false)
  }

  return (
    <MembershipLayout board={board}>
      <Page>
        <Form onSubmit={handleSubmit}>
          <FormHeader
            title="Alice - Yapay Zeka Platformu"
            description="Sisteme giriş yapmak için aşağıdaki bilgileri doldurunuz."
          />
          <FormSection>
            <Row>
              <Column xs={{ size: 12 }}>
                <Input name="email" placeholder="Kullanıcı adınızı giriniz" />
              </Column>
              <Column xs={{ size: 12 }}>
                <Input
                  name="password"
                  type="password"
                  placeholder="Şifre giriniz"
                />
              </Column>
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="remember"
                  label="Beni hatırla"
                  description="Tekrar giriş yaptığınızda bilgileriniz otomatik doldurulur"
                />
              </Column>
              <Button appearance="link">
                <Link to="/forgot-password">Şifremi unuttum</Link>
              </Button>
            </Row>
          </FormSection>
          <FormFooter>
            <Button
              appearance="primary"
              isFullWidth
              style={{ height: 38, alignItems: 'center' }}
              type="submit"
              isLoading={isLoading}
            >
              Sisteme giriş yap
            </Button>
          </FormFooter>
        </Form>
      </Page>
    </MembershipLayout>
  )
}

export default SignIn
