import { ClassificationCategory, ClassificationCategoryResponse } from './types'

export const mapCategoryTypeForRequest = (
  raw: ClassificationCategory
): unknown => ({
  id: raw.categoryTypeId,
  name: raw.name,
  parent_id: raw.parentId,
})

export const mapCategoryTypeForResponse = (
  raw: any
): ClassificationCategory => ({
  id: raw.data.id,
  name: raw.data.name,
  parentId: raw.data.parent_id,
  parentName: raw.data.parent_name,
})

export const mapCategoryTypeForList = (
  raw: any
): ClassificationCategoryResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapCategoryTypeForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
