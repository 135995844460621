import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .top-container {
    display: flex;
    justify-content: space-around;
  }
  .graph-container {
    width: 100%;
  }
  .tweet-box-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
  .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .line {
    width: 2px;
    height: 100%;
    background-color: gray;
    margin-right: 16px;
  }
`
export const HashtagsContainer = styled.div`
  width: 100%;
`

export const HashtagsCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1vw;
  @media (width >= 2440px) {
    margin-top: 5vh;
  }
`
export const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  margin-left: -2px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(50% - 1px); /* Div'in yarısının yarısı kadar bir yükseklik */
    width: 4px;
    background-color: white;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50%;
  }
`
export const LineComp = styled.div`
  border-left: 2px solid orange;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-color: orange;
  position: relative;
`
export const TitleWrapper = styled.div`
  background-color: orange;
  padding: 5px;
  width: fit-content;
  position: relative;
  left: 20px;
  top: -14px;
  border-radius: 6px;
`

export const Title = styled.span`
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  color: white;
  font-weight: 800;
`

export const Line = styled.div`
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 20px;
  background-color: orange;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 20px;
`
export const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 1170px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 520px) {
    flex-direction: column;
  }

  ul {
    list-style: none;
    padding: 0px;
    li {
      display: flex;
      gap: 0.5rem;
    }
  }

  .profile-persondetail-container {
    width: 45%;
    background-color: #8598db;
    @media only screen and (max-width: 1170px) {
      width: 100%;
    }

    @media only screen and (max-width: 520px) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    @media (width >= 2440px) {
      width: 50%;
    }
    .profile-img-container {
      width: 100%;
      height: 40%;
      @media (width >= 2440px) {
        height: 460px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .profile-person-name {
      width: 100%;
      background-color: #00229a;
      display: flex;
      justify-content: space-around;
      span {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 3rem;
      }
      span:nth-child(1) {
        font-size: 1.2rem;
      }
      span:nth-child(2) {
        font-size: 1.2rem;
      }
    }
    .profile-person-info {
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .profile-person-info-details-fbox {
        font-size: 1.2rem;
        font-weight: 500;
      }
      .profile-person-info-details {
        display: flex;
        color: white;
        width: 100%;
        span:nth-child(1) {
          width: 40%;
        }
        span:nth-child(2) {
          width: 60%;
          font-size: 1.15rem;
          font-weight: 200;
        }
      }
      .person-talents {
        display: flex;
        gap: 0.5rem;
        background-color: #00229a;
        width: 70%;
        padding: 2.4rem;
        flex-wrap: wrap;
        justify-content: center;

        border-radius: 0px 12px 12px 0px;
        div {
          background-color: white;
          padding: 4px 1.3rem;
          color: black;
          display: flex;
          border-radius: 12px;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          span {
            width: 100%;
          }
        }
      }
    }
  }
  .profile-person-container {
  }
  .profile-person-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    padding: 2rem 3rem;
    @media (width >= 2440px) {
      padding: 4rem 3rem;
    }
  }
  .person-talents-container {
    width: 100%;
    padding-bottom: 1rem;
  }
  .profile-detail-container {
    width: 100%;
    padding: 2rem 0px;
  }
  .rs-upper-container {
    width: 100%;
    position: relative;

    .rs-upper-detail-bar {
      background-color: #4e69ca;
      padding: 2.5rem 3rem;
      font-weight: 500;
      color: white;
      border-radius: 16px 0px 0px 16px;
      position: absolute;
      right: 0%;
      width: 100%;
      top: 0%;
      @media (width >= 2440px) {
        width: 100%;
      }
      @media (width >= 1600px) {
        width: 100%;
      }
      @media (width >= 1800px) {
        width: 100%;
      }
      @media (width >= 1920px) {
        width: 100%;
      }
      @media (width >= 2160px) {
        width: 100%;
      }
      @media (width >= 2880px) {
        width: 100%;
      }
      @media only screen and (max-width: 1170px) {
        width: 85% !important;
      }
      @media only screen and (max-width: 520px) {
        position: static;
        width: 74% !important;
      }
    }
  }
  .rs-center-container {
    @media only screen and (max-width: 2880px) {
      padding-top: 3vh;
    }
    @media only screen and (max-width: 520px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      gap: 1rem;
      display: flex;
      flex-direction: column;
      width: 88%;
    }
    @media only screen and (max-width: 1170px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
      gap: 2rem;
      display: flex;
      flex-direction: column;
      width: 88%;
    }
    margin-top: 8rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding: 0px 3rem;
    @media (width >= 2440px) {
      margin-top: 0 !important;
    }
    @media (width >= 1800px) {
      margin-top: 1rem !important;
    }
  }
  .person-interest-container {
    width: 90%;
    h3 {
      color: #00229a;
      font-size: 1.5rem;
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
  }
  .all-person-interest-container {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
  }
  .person-interest {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span:nth-child(1) {
      width: 50%;
      font-size: 1rem;
      letter-spacing: 1px;
    }
  }
  .person-bar-container {
    background-color: lightgray;
    width: 270px;
    height: 10px;
    border-radius: 8px;
  }
  .person-bar {
    background-color: #00229a;
    height: 100%;
    border-radius: 8px;
  }
  .person-target-container {
    width: 89%;
    h3 {
      color: #00229a;
      font-size: 1.5rem;
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
    i {
      font-size: 0.8rem;
    }
  }
  .person-opinion-leaders-container {
    h3 {
      color: #00229a;
      font-size: 1.5rem;
    }
  }
  .person-opinion-spans {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 2vh;
    width: 100%;
    flex-wrap: wrap;
    span {
      background-color: #8598db;
      padding: 0.2rem 0.9rem;
      border-radius: 14px;
      color: white;
      font-size: 1.05rem;
    }
  }
  .person-target-list {
    span {
      font-size: 1rem;
    }
  }
`
