import React, { useEffect, useState } from 'react'
import * as Styled from './HighestFollowedAccounts.styled'
import { HighestFollowedAccountProps, IHighestFollowedAccount } from './types'
import { numberFormat } from 'app/functions/number'
import WidgetLayout from 'pages/agenda-report-v4/detail/widgets/widget-layout'
import UpdateButton from 'pages/agenda-report-v4/detail/components/update-button/UpdateButton'

const HighestFollowedAccounts: React.FC<HighestFollowedAccountProps> = ({
  date,
  data,
  title,
  isEditValue,
  setIsEditValue,
  pageNumber,
  metaData,
}) => {
  const [tableData, setTableData] = useState<
    IHighestFollowedAccount[] | undefined
  >(data)
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setTableData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChange = (key: string, value: string, source: string) => {
    setTableData(
      tableData?.map((item) => {
        if (item.ScreenName === source) {
          return {
            ...item,
            [key]: value,
          }
        }
        return item
      })
    )
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      {data && data?.length > 0 ? (
        <Styled.TableContent>
          <div className="edit-btn-container">
            <UpdateButton
              {...editProps}
              contentData={{ Accounts: tableData }}
              title={pageData?.title}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </div>

          <Styled.TableContainer>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Kullanıcı Adı</th>
                  <th>Takipçi Sayısı</th>
                  <th>Takip Ettiği Kişi Sayısı</th>
                  <th>Ülke</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((row, index) => (
                  <tr key={index}>
                    <td contentEditable={editMode}>{index + 1}</td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange(
                          'ScreenName',
                          e.target.innerText,
                          row.ScreenName
                        )
                      }
                    >
                      {row.ScreenName ? (
                        <a
                          href={`https://${row.Url}`}
                          target="_blank"
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            alignContent: 'center',
                          }}
                          rel="noreferrer"
                        >
                          <img
                            src={row.ProfileImageUrl}
                            alt={row.ScreenName}
                            width={30}
                            height={30}
                            style={{ marginRight: 4 }}
                          />
                          {row.ScreenName}
                        </a>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange(
                          'FollowersCount',
                          e.target.innerText,
                          row.ScreenName
                        )
                      }
                    >
                      {row.FollowersCount
                        ? numberFormat(row.FollowersCount)
                        : '-'}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange(
                          'FriendsCount',
                          e.target.innerText,
                          row.ScreenName
                        )
                      }
                    >
                      {row.FriendsCount ? numberFormat(row.FriendsCount) : '-'}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange(
                          'CountryDetection',
                          e.target.innerText,
                          row.ScreenName
                        )
                      }
                    >
                      {row?.CountryDetection || '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Styled.TableContainer>
        </Styled.TableContent>
      ) : (
        <h3>Veri Bulunamadi</h3>
      )}
    </WidgetLayout>
  )
}

export default HighestFollowedAccounts
