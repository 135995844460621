export const USER_FIELDS = [
  {
    label: 'Kullanıcı ID',
    value: 'userEntityKey',
  },
  {
    label: 'Kullanıcı adı',
    value: 'userName',
  },
  {
    label: 'Kullanıcı görünen adı',
    value: 'userVisibleText',
  },
  {
    label: 'Kullanıcı takipçi sayısı',
    value: 'userFollowers',
  },
  {
    label: 'Kullanıcı takip sayısı',
    value: 'userFriends',
  },
  {
    label: 'Kullanıcı profil fotoğrafı',
    value: 'userProfileImage',
  },
  {
    label: 'Kullanıcı tweet sayısı',
    value: 'userTweetCount',
  },
  {
    label: 'Kullanıcı retweet Sayısı',
    value: 'retweetCount',
  },
  {
    label: 'Kullanıcı favori Sayısı',
    value: 'favoriteCount',
  },
  {
    label: 'Kullanıcı alıntı Sayısı',
    value: 'replyCount',
  },
  {
    label: 'Kullanıcı ülkesi',
    value: 'countryDetection',
  },
  {
    label: 'Kullanıcı korumalı hesap',
    value: 'userProtected',
  },
  {
    label: 'Kullanıcı doğrulanmış hesap',
    value: 'userVerified',
  },
  {
    label: 'Kullanıcı oluşturulma tarihi',
    value: 'userCreatedAt',
  },
]
