import {
  TwitterV2MainTelegramAlarmRule,
  TwitterV2MainTelegramAlarmRuleResponse,
} from './types'

export const mapTwitterV2MainTelegramAlarmRuleForResponse = (
  raw: any
): TwitterV2MainTelegramAlarmRule => ({
  id: raw.data.id,
  alarmNames: raw.data.alarmNames,
  createdAt: raw.data.createdAt,
  description: raw.data.description,
  criteriaGroup: raw.data.criteriaGroup,
  messageFields: raw.data.messageFields,
  functions: raw.data.functions,
  messageTemplate: raw.data.messageTemplate,
  containsControlWords: raw.data.containsControlWords,
  equalControlWords: raw.data.equalControlWords,
  timeRange: raw.data.timeRange,
  alarmScoreSettings: raw.data.alarmScoreSettings,
  telegramChat: raw.data.telegramChat,
  excludedKeywords: raw.data.excludedKeywords,
  paused: raw.data.paused,
  alarmType: raw.data.alarmType,
})

export const mapTwitterV2MainTelegramAlarmRuleForList = (
  raw: any
): TwitterV2MainTelegramAlarmRuleResponse => ({
  list: raw.data.map((item: unknown) =>
    mapTwitterV2MainTelegramAlarmRuleForResponse({ data: item })
  ),
  dataCount: raw.datacount,
  pageCount: raw.pageCount,
  message: raw.message,
  success: raw.success,
})
// export const mapBlackListForRequest = (raw: BlackList): unknown => ({
//   id: raw.id,
//   type: raw.type,
//   value: raw.value,
//   dataSource: raw.dataSource,
// })

// export const mapBlackListForResponse = (raw: any): BlackList => ({
//   id: raw.data.id,
//   type: raw.data.type,
//   value: raw.data.value,
//   createdAt: raw.data.createdAt,
//   dataSource: raw.data.dataSource,
// })

// export const mapBlackListForList = (raw: any): BlackListResponse => ({
//   list: raw.data.map((item: unknown) =>
//     mapBlackListForResponse({ data: item })
//   ),
//   dataCount: raw.dataCount,
//   pageCount: raw.pageCount,
//   message: raw.message,
//   success: raw.success,
// })
