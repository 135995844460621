import styled from 'styled-components'

export const AgendaTiktokCard = styled.div<{
  $isOpen: boolean
  $isLength: boolean | undefined
}>`
  font-family: 'Euclid Circular A', sans-serif;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media only screen and (min-width: 1405px) {
    gap: 2rem;
  }

  .post-image {
    overflow: hidden;
    border-radius: 10px;
    width: 296px;
    height: 520px;
  }

  .agenda-tiktok {
    padding: auto 1rem;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    height: fit-content;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.4rem;
      &-profile-image {
        img {
          width: 64px;
          border-radius: 50%;
        }
      }
      &-profile-des {
        &-name {
          font: normal normal bold 20px/25px Euclid Circular A;
          color: #000000;
        }
        &-link {
          display: block;
          font: normal normal medium 18px/23px Euclid Circular A;
          color: #707b93;
        }
      }
    }

    &-video {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      &-desc {
        ${(props) =>
          props.$isLength && !props.$isOpen
            ? 'height:  2.4rem;'
            : 'height: auto;'}
        overflow: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
        transition: all, 0.4s cubic-bezier(1, 0, 1, 0);
        margin-top: 0.6rem;
      }
      &-button {
        background-color: transparent;
        border: none;
        padding: 0;
        text-align: left;
        cursor: pointer;
        color: #111;
        font: normal normal bold 16px Euclid Circular A;
        letter-spacing: 0px;
      }
      &-date {
        display: block;
        font: normal normal normal 18px/23px Euclid Circular A;
        color: #707b93;
      }
    }

    &-post-infos {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;

      &-item {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;

        img {
          width: 24px;
        }

        &:not(:last-child) {
          margin-right: 1.8rem;
        }
      }
    }
  }
`
