import { useState, useEffect } from 'react'
import { SelectOptionProps } from '@zera-admin/select'

import classificationCategoryService from 'services/http/dataset/classification-category'

const useClassificationCategory = () => {
  const [classificationCategories, setClassificationCategories] = useState<
    SelectOptionProps[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getClassificationCategories()
  }, [])

  const getClassificationCategory = (id?: number, name?: string) => {
    if (id !== null && id !== undefined) {
      return {
        label:
          (classificationCategories.find(
            (classificationCategory) => classificationCategory.value === id
          )?.label as string) ||
          name ||
          '',
        value: id,
      }
    }
    return null
  }

  const getClassificationCategories = async () => {
    setIsLoading(true)

    const response = await classificationCategoryService.get()
    const mapped = response.data?.list.map((classificationCategory) => ({
      label: classificationCategory.name,
      value: classificationCategory.id as number,
    }))

    setClassificationCategories(mapped)
    setIsLoading(false)

    return mapped
  }

  return {
    classificationCategories,
    getClassificationCategory,
    getClassificationCategories,
    isLoading,
  }
}

export default useClassificationCategory
