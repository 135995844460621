import React, { useEffect, useState } from 'react'
import { uid } from '@zera-admin/utils'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { ValidationError } from 'app/shared/types'
import MainLayout from 'layouts/main'
import genderDataSetsService, {
  GenderDataSet,
  UpdateGenderDataSetRequest,
} from 'services/http/dataset/gender-data-set'

import { UpdateGenderDataSetProps } from './types'
import GenderDataSetForm from './GenderDataSetForm'

const UpdateGenderDataSet: React.FunctionComponent<UpdateGenderDataSetProps> =
  ({ match, history }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isReady, setIsReady] = useState(true)
    const [genderDataSet, setGenderDataSet] = useState<GenderDataSet>()
    const [flagcard] = useFlagcardContext()

    useEffect(() => {
      const id = parseInt(match.params.id)

      genderDataSetsService
        .getById(id)
        .then((res) => {
          setGenderDataSet(res.data)
        })
        .catch((err: Error) => {
          console.log(err)
        })
        .finally(() => {
          setIsReady(false)
        })
    }, [match.params.id, history])

    const handleSubmit = (data: UpdateGenderDataSetRequest) => {
      if (data) {
        setIsLoading(true)

        genderDataSetsService
          .update(data, parseInt(match.params.id))
          .then((response) => {
            flagcard.add({
              id: uid(),
              appearance: 'success',
              title: 'İşlem Başarılı',
              children:
                'Geçerli veri, sistem üzerinde başarıyla güncellenmiştir.',
            })

            history.push(
              match.path.replace(':id', response.data.id?.toString() || '')
            )
          })
          .catch((error) => {
            console.log(error)

            flagcard.add({
              id: uid(),
              appearance: 'error',
              title: 'Hata Oluştu',
              children:
                'Geçerli veri güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    const handleError = (error?: ValidationError) => {
      console.log(error)
    }

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Cinsiyet veri setleri" href="/gender-data-set" />
        <BreadcrumbItem text="Cinsiyet veri seti güncelle" />
      </Breadcrumb>
    )

    return (
      <MainLayout isLoading={isReady}>
        <Container width="medium">
          <Page>
            <PageHeader
              // actions={<Button appearance="error">Delete user</Button>}
              breadcrumbs={breadcrumbs}
              bottomBar={
                <p>
                  Aşağıdaki bilgileri doldurarak geçerli cinsiyet veri setini
                  güncelleyebilirsiniz.
                </p>
              }
            >
              Veri güncelleniyor:
              {genderDataSet ? genderDataSet.text : null}
            </PageHeader>
            <GenderDataSetForm
              actions={
                <ButtonGroup>
                  <Button
                    appearance="primary"
                    children="Güncelle"
                    isLoading={isLoading}
                    type="submit"
                  />
                  <LinkButton href="/gender-data-set">İptal</LinkButton>
                </ButtonGroup>
              }
              operation="update"
              onSubmit={handleSubmit}
              onError={handleError}
              values={genderDataSet}
            />
          </Page>
        </Container>
      </MainLayout>
    )
  }

export default UpdateGenderDataSet
