import styled from 'styled-components'
export const OverlayContainer = styled.div`

  color: #fff;
  padding: 1rem;
  background-color: #50aaf5;
  border-radius: 10px;

  .overlay-header {
    width: 95%;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      font-size: 1.8rem;
      @media (width >= 2440px) {
        font-size:4rem;
      }
    }
    .overlay-ls {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      @media (width >= 2440px) {
        font-size:1.4rem;
      }
      span {
        background-color: white;
        border-radius: 6px;
        padding: 0.2rem 0.5rem;
        font-size: 0.8rem;
        color: orange;
        font-weight: 800;
        @media (width >= 2440px) {
          font-size:1.4rem;
        }
      }
    }
  }
  .overlay-content-container {
    background-color: white;
    border-radius: 10px;
    margin-top: 1rem;
    display:flex;
    padding:0.1rem 0rem;
      flex-wrap:wrap;
      justify-content:space-around;
    }
  }
`
