import React, { useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button from '@zera-admin/button'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { ValidationError } from 'app/shared/types'
import MainLayout from 'layouts/main'
import backofficeDefinitionService, {
  CreateDefinitionRequest,
} from 'services/http/backoffice/definition'

import { CreateDefinitionProps } from '../types'
import DefinitionsForm from './DefinitionQueryForm'

const CreateDefinition: React.FunctionComponent<CreateDefinitionProps> = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()

  const handleSubmit = (
    values: CreateDefinitionRequest,
    handleReset: () => void
  ) => {
    if (values) {
      setIsLoading(true)

      backofficeDefinitionService
        .create(values)
        .then(() => {
          flagcard.add({
            appearance: 'success',
            title: 'Kayıt işlemi başarılı',
            children:
              'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
          })

          handleReset()
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleError = (error?: ValidationError) => {
    console.log(error)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Tanımlama" href="/definition" />
      <BreadcrumbItem text="Tanımlama Veri Sayısı Sorgulama" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Container width="medium">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Aşağıdaki bilgileri doldurarak yeni bir sorgulama
                yapabilirsiniz..
              </p>
            }
          >
            Tanımlama Veri Sayısı Sorgulama
          </PageHeader>
          <DefinitionsForm
            actions={
              <Button
                appearance="primary"
                children="Sorgu oluştur"
                isLoading={isLoading}
                type="submit"
              />
            }
            operation="create"
            onError={handleError}
            onSubmit={handleSubmit}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default CreateDefinition
