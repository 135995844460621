import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { validation } from 'app/functions'
import { EntityType } from 'services/http/dataset/entity-type'

import { EntityTypeFormProps } from './types'
import useEntityCategoryType from 'app/hooks/entity-category-type'

const validations = {
  longName: yup.string().required('Uzun varlık türü metni girmelisiniz'),
  name: yup.string().required('Varlık türü metni girmelisiniz'),
}

const EntityTypeForm: React.FunctionComponent<EntityTypeFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const form = useForm<EntityType>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const entityCategoryType = useEntityCategoryType()

  const handleSubmit = (props: FormHookSubmitProps<EntityType>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Geçerli bir varlık türü metni giriniz"
              errorMessage={form.errors.name}
              label="Varlık türü metni"
              name="name"
              placeholder="Örnek metin"
              value={form.values.name}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Input
              description="Geçerli bir uzun varlık türü giriniz"
              errorMessage={form.errors.longName}
              label="Uzun varlık türü"
              name="longName"
              placeholder="Örnek: tr, en, vs.."
              value={form.values.longName}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Select
              description="Geçerli bir varlık kategori listesi seçin"
              isMultiple
              label="Varlık kategori listesi seçiniz"
              name="entityCategoryIdList"
              value={form.values.entityCategoryIdList}
              onChange={(props) =>
                form.handleFieldChange(
                  'entityCategoryIdList',
                  props as SelectOptionProps
                )
              }
              options={entityCategoryType.entityCategoryTypes}
              isLoading={entityCategoryType.isLoading}
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default EntityTypeForm
