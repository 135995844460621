import React from 'react'
import * as Styled from './TwitterTweetCard.styled'
import { Tweet } from '../../types'

export interface TweetCard {
  tweet: Tweet
}
const TwitterTweetCard: React.FunctionComponent<TweetCard> = ({ tweet }) => {
  const dates = new Date(tweet.createdAt)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    second: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  const formattedDate = dates.toLocaleDateString('tr-TR', options)
  return (
    <Styled.TwitterTweetCard>
      <a
        href={`https://twitter.com/${tweet.userScreenName}/status/${tweet.entityKey}`}
      >
        <div className="tweet-card-header">
          <div className="twitter-card-header-img">
            <img id="profileShadow" src={tweet.userProfileImageUrl} alt="" />
          </div>
          <div className="twitter-card-header-title">
            <span className="title">{tweet.userName}</span>
            <span className="uri">@{tweet.userScreenName}</span>
          </div>
        </div>
        <div
          style={{
            marginTop: tweet.medias[0] ? '0' : '3vh',
          }}
          className="twitter-follow-container"
        >
          <span className="twitter-follow-count-container" id="card-box">
            <span className="span-weight">
              <i className="fa-solid fa-user-large"></i>Takip Edilen:{' '}
              {tweet.userFriendsCount}
            </span>
          </span>
          <span className="twitter-follow-count-container" id="card-box">
            <span className="span-weight span-second">
              <i className="fa-solid fa-user-group"></i>Takipçi:{' '}
              {tweet.userFollowersCount}
            </span>
          </span>
        </div>
        <div className="twitter-content-text-container">
          <p style={{ color: 'black' }}>{tweet.text}</p>
        </div>
        {tweet.medias[0] && (
          <div className="twitter-content-thumbnail-container">
            <img src={tweet.medias[0]} alt="" />
          </div>
        )}

        <div className="twitter-action-content-container">
          <div className="twitter-action-date">
            <span>{formattedDate}</span>{' '}
            {/* <span>
            <span className="thumbnail-mn">{tweet.userStatusesCountStr} </span>
            Goruntuleme
          </span> */}
          </div>
          <div className="twitter-footer-spans">
            <div className="twitter-footer-span">
              <span className="twitter-footer-count">{tweet.retweetCount}</span>
              <span className="twitter-footer-title">Retweet</span>
            </div>
            <div className="twitter-footer-span">
              <span className="twitter-footer-count">{tweet.replyCount}</span>
              <span className="twitter-footer-title">Alinti Tweet</span>
            </div>
            <div className="twitter-footer-span">
              <span className="twitter-footer-count">
                {tweet.favoriteCount}
              </span>
              <span className="twitter-footer-title">Begeni</span>
            </div>
          </div>
        </div>
      </a>
    </Styled.TwitterTweetCard>
  )
}

export default TwitterTweetCard
