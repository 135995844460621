export const HOTEL_FIELDS = [
  {
    label: 'Otel İsmi',
    value: 'hotelName',
  },
  {
    label: 'Url',
    value: 'hotelLink',
  },
  {
    label: 'Açıklama',
    value: 'hotelDescription',
  },
  {
    label: 'Görseller',
    value: 'mediaList',
  },
  {
    label: 'Bilgi',
    value: 'arrangement',
  },
  {
    label: 'Puan',
    value: 'rating',
  },
  {
    label: 'Adres',
    value: 'adress',
  },
]
