import * as yup from 'yup'

import { FormErrors } from '@zera-admin/form'

export function validation(
  validations: { [key: string]: any },
  values: { [key: string]: any },
  prop?: string
): Promise<FormErrors> {
  const schema = yup
    .object()
    .shape(prop ? { [prop]: validations[prop] } : validations)

  return new Promise<FormErrors>((resolve) => {
    schema
      .validate(prop ? { [prop]: values[prop] } : values, { abortEarly: false })
      .then(() => resolve({}))
      .catch((error) => {
        if (error.inner && error.inner.length) {
          const errors: FormErrors = {}

          error.inner.forEach((item: { message: string; path: string }) => {
            errors[item.path] = item.message
          })

          resolve(errors)
        }
      })
  })
}
