export enum QueryCriteriaInterval {
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

export enum QueryHttpPerception {
  General = 1,
  Tourism = 2,
}

export enum QueryHttpSource {
  Turkey = 1,
  Other = 2,
  OtherAndTurkey = 3,
}

export enum QueryHttpSentiment {
  General = 1,
  Positive = 2,
  Negative = 3,
}

export enum QueryHttpTime {
  Hourly = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
}
