import React, { useEffect, useState } from 'react'
import { ITimeSeries } from './types'
import WidgetLayout from '../../widgets/widget-layout'
import DashedLineChart from '../../components/dashed-line-chart/DashedLineChart'
import * as Styled from './TimeSeries.styled'
import UpdateButton from '../../components/update-button/UpdateButton'

const TimeSeries: React.FC<ITimeSeries> = ({
  data,
  title,
  pageNumber,
  date,
  isEditValue,
  setIsEditValue,
  metaData,
}) => {
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })
  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      <Styled.ContentContainer>
        <UpdateButton
          {...editProps}
          contentData={{ TimeSeries: data }}
          title={pageData?.title}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <div className="chart">
          <DashedLineChart data={data} title={title} />
        </div>
      </Styled.ContentContainer>
    </WidgetLayout>
  )
}

export default TimeSeries
