import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { TweetVisualizationFields, TweetVisualizationOptions } from '../types'

export const generateTweetOptions = (
  options: TweetVisualizationOptions,
  query: Query,
  fields: Field[]
): TweetVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      fields: {
        ...result.fields,
        [group.field]: group.field as string,
      },
    }
  })

  return result
}

export const generateTweetVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as TweetVisualizationFields)

  return {
    type: 'tweet',
    options: {
      stream: {},
      fields: {
        classifications: options['classifications'],
        aspects: options['absKeywords'],
        commentCount: options['replyCount'],
        entityKey: options['entityKey'],
        favoriteCount: options['favoriteCount'],
        userProfileImage: options['userProfileImageUrl'],
        retweetCount: options['retweetCount'],
        tweetText: options['text'],
        userName: options['userName'],
        createdAt: options['createdAt'],
        sentiment: options['sentiment'],
        countryDetection: options['userCountryDetection'],
        userCreatedAt: options['userCreatedAt'],
        userFollowers: options['userFollowersCount'],
        userFriends: options['userFriendsCount'],
        userVisibleText: options['userScreenName'],
        userProtected: options['userProtected'],
        userVerified: options['userVerified'],
        userTweetCount: options['userStatusesCount'],
      },
    },
  }
}
