import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { LanguagesUsedByUserProps } from './types'

const LanguagesUsedByUser: React.FunctionComponent<LanguagesUsedByUserProps> =
  ({ data }) => {
    const languagesUsedByUser: Props = {
      options: {
        chart: {
          id: 'languagesUsedByUser',
        },
        colors: ['#ff6f00'],
        xaxis: {
          categories: data?.map((item: any) => item?.Language),
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'sayısı',
          data: data?.map((item: any) => item?.TweetCount),
        },
      ],
    }

    return data && data.length > 0 ? (
      <Chart
        options={languagesUsedByUser.options}
        series={languagesUsedByUser.series}
        type="bar"
      />
    ) : (
      <EmptyState
        title="Veri bulunamadı"
        description="Kullanıcı verisi bulunamadı"
      />
    )
  }

export default LanguagesUsedByUser
