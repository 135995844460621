import React, { useEffect, useState } from 'react'
import * as Styled from './ExecutiveSummary.styled'
import { IAnalysisData, IExecutiveSummary } from './types'
import WidgetLayout from '../../widgets/widget-layout'
import UpdateButton from '../../components/update-button/UpdateButton'
import ExecutiveSummaryAnalysis from '../../components/analysis/ExecutiveSummaryAnalysis'

const ExecutiveSummary: React.FC<IExecutiveSummary> = ({
  data,
  title,
  date,
  isEditValue,
  pageNumber,
  setIsEditValue,
  metaData,
}) => {
  const [formData, setFormData] = useState<IAnalysisData>(data)
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setFormData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
    >
      <Styled.ExecutiveSummaryWrapper>
        <UpdateButton
          {...editProps}
          contentData={formData}
          title={pageData?.title}
          editMode={editMode}
          setEditMode={setEditMode}
        />

        <ExecutiveSummaryAnalysis
          editMode={editMode}
          data={formData}
          onChange={onChange}
        />
      </Styled.ExecutiveSummaryWrapper>
    </WidgetLayout>
  )
}

export default ExecutiveSummary
