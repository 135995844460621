import * as React from 'react'

import { SchemaNode, SchemaTree, SchemaObject } from './schema'
import { Container, ContainerEntryEnum } from './container'

function _renderNode(
  schemaNode: SchemaNode,
  container: Container,
  nodes: SchemaNode[],
  bindProps: any
): any {
  const schemaTree = SchemaTree.parse(nodes)
  const containerEntry = container.resolve(schemaNode.getType())
  const types = schemaTree
    .getDistinctNodes()
    .filter((node) => node.getType() === schemaNode.getType())
  const index = types.findIndex(
    (type) => type.getDefinition('id') === schemaNode.getDefinition('id')
  )
  const { component } = containerEntry
  const props = {
    ...schemaNode.props,
    ...bindProps,
    key: schemaNode.getDefinition('id') + new Date().valueOf(),
    id: schemaNode.getDefinition('id'),
    alias: schemaNode.getDefinition('alias'),
    schemaName: schemaNode.getName(),
    schemaNode: schemaNode,
    container,
    entry: containerEntry,
    index,
  }

  let children = []

  if (containerEntry.entryType === ContainerEntryEnum.Component) {
    children = schemaNode.children
  } else {
    children = SchemaTree.parse(containerEntry.children).rootNodes
  }

  let childReactNodes = children.map((node) =>
    _renderNode(node, container, nodes, bindProps)
  )

  return React.createElement(component as any, props, childReactNodes)
}

export function renderNodes(
  nodes: SchemaNode[],
  container: Container,
  bindProps = {}
) {
  return nodes.map((schemaNode) =>
    _renderNode(schemaNode, container, nodes, bindProps)
  )
}

export function renderSchema(
  schemaTree: SchemaTree,
  container: Container,
  bindProps = {}
) {
  return renderNodes(schemaTree.rootNodes, container, bindProps)
}

export function render(
  schemaArray: SchemaObject[],
  container: Container,
  bindProps = {}
) {
  return renderSchema(SchemaTree.parse(schemaArray), container, bindProps)
}
