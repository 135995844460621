import styled from 'styled-components'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
export const TwitterBackground = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 180px;
  background-color: #2c2cf0;
  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 5px;
  }
`
export const TwitterProfileImage = styled.div`
  position: relative;
  width: 100%;
  height: 75px;
  background-color: #15202b;
  img {
    position: absolute;
    left: 40px;
    top: -75px;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
`
export const TwitterPersonProfileInfo = styled.div`
  padding: 0px 0px 0px 36px;
  background-color: #15202b;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ccc9c9;
    font-size: 15px;
    span {
      color: #ffffff;
    }
  }
`
