import { DashboardAccountProps, DashboardCountryProps } from './types'

export const mapProfileDetailResponse = (raw: any): DashboardAccountProps => ({
  CountryDetection: raw.data.country_detection,
  CreatedAt: raw.data.created_at,
  Description: raw.data.description,
  EntityKey: raw.data.entity_key,
  FavoritesCount: raw.data.favorites_count,
  FollowersCount: raw.data.followers_count,
  FriendsCount: raw.data.friends_count,
  Name: raw.data.name,
  ProfileImageUrl: raw.data.profile_image_url,
  Protected: raw.data.protected,
  Location: raw.data.location,
  Verified: raw.data.verified,
  ScreenName: raw.data.screen_name,
  StatusesCount: raw.data.statuses_count,
})

export const mapCountryDetailResponse = (raw: any): DashboardCountryProps => {
  return {
    source: {
      country: raw.data?.main_account_country?.country_code,
      countryName: raw.data?.main_account_country?.country_name,
    },
    targets: (raw.data?.account_countries || []).map((country: any) => ({
      country: country.country_code,
      countryName: country?.country_name,
      count: country?.count || 0,
    })),
  }
}
