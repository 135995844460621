import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'

import MainLayout from 'layouts/main'
import personReportsService from 'services/http/report/person-report'

import { DetailPersonReportProps } from './types'
import { LoadingView } from './PersonReport.styled'
import AllReport from './report-detail/AllReport'
import BotDetection from './report-detail/bot-detection'
import { fontSize } from '@atlaskit/theme'

const DetailPersonReport: React.FunctionComponent<DetailPersonReportProps> = ({
  match,
}) => {
  const [personReport, setPersonReport] = useState<any>()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getByGroupId = useCallback(() => {
    setIsLoading(true)
    const groupId = match.params.id
    personReportsService
      .getByGroupId(groupId)
      .then((res) => {
        setPersonReport(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [match.params.id])

  useEffect(() => {
    getByGroupId()
  }, [getByGroupId])

  const date = new Date().toLocaleDateString('tr-TR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Kişi raporu" href="/person-report" />
      <BreadcrumbItem text="Kişi raporu detayı" />
    </Breadcrumb>
  )

  return isLoading ? (
    <LoadingView>
      <Spinner size="large" />
      <h5>Rapor yükleniyor..</h5>
    </LoadingView>
  ) : (
    <MainLayout isLoading={isLoading}>
      <Container width="medium">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={<p>Kişinin detaylarını görüntülüyorsunuz</p>}
          >
            <div
              style={{ width: '100%', textAlign: 'right', fontSize: '20px' }}
            >
              <p>{new Date().toLocaleString()} </p>
            </div>
            Kullanıcı verisi listeleniyor:{' '}
            <Link
              style={{ textDecorationLine: 'none', color: '#000' }}
              to={{
                pathname: personReport?.result
                  ? `https://twitter.com/${personReport.result?.TrackingUser?.ScreenName}`
                  : '',
              }}
              target="_blank"
            >
              <b>
                {personReport?.result?.TrackingUser
                  ? personReport?.result?.TrackingUser?.Name
                  : null}
              </b>
            </Link>
          </PageHeader>
          <BotDetection
            data={personReport?.result?.Statistic?.followersBotDetection}
            match={match}
          />
          <AllReport data={personReport} />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default DetailPersonReport
