import { useCallback, useEffect, useState } from 'react'
import { Row } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'
import Alert from '@zera-admin/alert'

import dashboardService, {
  DashboardCountryProps,
} from 'services/http/dashboard-ui/dashboard'
import GoogleMaps from 'components/google-maps'
import { COORDINATES } from 'components/google-maps/utils/constants'
import InfluentialPeopleMap from 'widgets/influential-people-map'

import * as Styled from './InteractionMap.styled'
import { InteractionMapProps, MapData } from './types'

let map: google.maps.Map
let markerLines = []
let timeouts = []
let data: Array<MapData> = []

const REFRESH_DURATION = 1000 * 30
const LINE_ANIMATION_DURATION = 2000

const InteractionMap: React.FunctionComponent<InteractionMapProps> = ({
  theme = 'silver',
  reference,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [values, setValues] = useState<DashboardCountryProps>()

  useEffect(() => {
    if (reference?.raw?.id) {
      getValues(reference?.raw?.id as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference?.raw?.id])

  const getValues = useCallback((id: string) => {
    setIsLoading(true)

    dashboardService
      .getCountryDetail(id)
      .then((res) => {
        setIsLoading(false)
        setValues(res.data)
      })
      .catch((err: Error) => {
        setIsLoading(false)
        console.log(err)
      })
  }, [])

  const initMapData = (values: DashboardCountryProps) => {
    const fromCoordinate = COORDINATES.find(
      (coordinate) => coordinate.alpha3 === values.source.country
    )

    data = values?.targets
      .filter((target) => target.countryName)
      .map((target, index) => {
        const targetCoordinate = COORDINATES.find(
          (coordinate) => coordinate.alpha3 === target.country
        )

        const from = {
          code: target.country,
          name: target.countryName,
          lat: targetCoordinate?.latitude as number,
          lng: targetCoordinate?.longitude as number,
        }

        const dest = {
          code: values.source.country,
          name: values.source.countryName,
          lat: fromCoordinate?.latitude as number,
          lng: fromCoordinate?.longitude as number,
        }

        const lines = []
        const flowDelay = Math.floor(Math.random() * REFRESH_DURATION)
        const quantity = Math.floor(target.count / 5000) + 1

        for (let i = 0; i < quantity; i++) {
          if (i === 4) break

          const lineDelay = Math.floor(Math.random() * quantity)
          const arrival = new window.google.maps.LatLng(dest.lat, dest.lng)
          const departure = new window.google.maps.LatLng(
            from.lat - quantity / 4 + i / 2,
            from.lng
          )

          lines.push({
            coordinates: [departure, arrival],
            delay: lineDelay,
            key: i,
          })
        }

        return {
          key: index,
          delay: flowDelay,
          quantity,
          lines,
          from,
          dest,
          count: target.count,
        }
      })

    drawMap()
  }

  const setPolyline = (data: any, options: any) => {
    const colors = ['#00ACEE', '#00ACEE', '#ff0000']
    const geciciSentimen = Math.random()
    const [departure, arrival] = data.coordinates
    const line = new window.google.maps.Polyline({
      geodesic: true,
      strokeColor:
        geciciSentimen > 0.6
          ? colors[0]
          : geciciSentimen < 0.6 && geciciSentimen > 0.4
          ? colors[1]
          : colors[2],
      strokeWeight: 2,
      map: map,
      ...options,
    })

    const duration = LINE_ANIMATION_DURATION
    const timePerStep = 20
    let intervalSource: NodeJS.Timeout
    let intervalTarget: NodeJS.Timeout

    const reversedraw = () => {
      let step = 0

      intervalSource = setInterval(() => {
        step += timePerStep

        if (window.google.maps.geometry) {
          if (step >= duration) {
            clearInterval(intervalSource)
            line.setMap(null)
          } else {
            const [from, to] = data.coordinates
            const end = window.google.maps.geometry.spherical.interpolate(
              from,
              to,
              step / duration
            )
            line.setPath([arrival, end])
          }
        }
      }, timePerStep)
    }

    const draw = () => {
      let step = 0

      intervalTarget = setInterval(() => {
        step += timePerStep

        if (window.google.maps.geometry) {
          if (step >= duration) {
            clearInterval(intervalTarget)
            reversedraw()
          } else {
            const end = window.google.maps.geometry.spherical.interpolate(
              departure,
              arrival,
              step / duration
            )

            line.setPath([departure, end])
          }
        }
      }, timePerStep)
    }

    markerLines.push(line)
    draw()
  }

  const setPolylines = (data: MapData) => {
    for (let i = 0; i < data.lines.length; i++) {
      const line = data.lines[i]

      timeouts.push(
        setTimeout(() => {
          setPolyline(line, {
            strokeOpacity: data.quantity / 25 + 0.3,
          })
        }, line.delay)
      )
    }
  }

  const drawMap = () => {
    data.forEach((data) => {
      timeouts.push(
        setTimeout(() => {
          setPolylines(data)
        }, data.delay)
      )
    })

    timeouts.push(
      setTimeout(() => {
        drawMap()
      }, REFRESH_DURATION + LINE_ANIMATION_DURATION)
    )
  }

  const handleLoad = (mapRef: google.maps.Map) => {
    map = mapRef

    if (values) {
      initMapData(values)
    }
  }

  return (
    <Styled.InteractionMap>
      {isLoading ? (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      ) : values ? (
        values.source?.countryName ? (
          <GoogleMaps onLoad={handleLoad} theme={theme} options={{ zoom: 3 }} />
        ) : (
          <InfluentialPeopleMap
            values={values.targets.map((target) => ({
              country: target.country,
              label: target.countryName,
              position: COORDINATES.find(
                (coordinate) => coordinate.alpha3 === target.country
              ),
            }))}
          />
        )
      ) : (
        <Alert
          appearance="warning"
          title="Etkileşim haritası oluşturulamadı"
          children="Etkileşim hartiasını oluşturacak veri bulunamadı. Lütfen sorgularınızı ve etkileşim haritası ayarlarınızı ayarlarınızı kontrol ediniz."
        />
      )}
    </Styled.InteractionMap>
  )
}

export default InteractionMap
