import styled from 'styled-components'

export const UpdateButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 2rem;
  & button {
    background-color: white;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 30px;
    font-size: 2rem;
    .fa-x {
      color: red;
      height: 20px;
    }
    .fa-check {
      color: lightgreen;
    }
  }
`
