import { LinkButton } from '@zera-admin/button'

import { CompoundButtonProps } from './types'
import * as Styled from './CompoundButton.styled'

const CompoundButton: React.FunctionComponent<CompoundButtonProps> = (
  props
) => {
  return (
    <Styled.CompoundButton>
      <LinkButton {...props} />
    </Styled.CompoundButton>
  )
}

export default CompoundButton
