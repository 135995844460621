import React, { useState } from 'react'
import Modal from '@zera-admin/modal'
import { Column, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'
import EmptyState from '@zera-admin/empty-state'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Select, { SelectOptionProps, SelectValueProps } from '@zera-admin/select'

import { useSchemaService } from 'app/library/layout-builder'
import NotFoundHeading from 'assets/images/not-found.png'

import ComponentHeader from '../ComponentHeader'
import * as Styled from './Heading.styled'
import { HEADING_TYPES } from './constants'
import { HeadingNodeType, HeadingProps } from './types'

const Heading: React.FunctionComponent<HeadingProps> = ({
  caption,
  designing,
  entry,
  id,
  nodeType = 'h1',
  title,
}) => {
  const [modal, setModal] = useState<HeadingProps | null>(null)
  const schemaService = useSchemaService()

  const handleChangeProperties = (value: object) => {
    schemaService.changeProperties(id as string, value)
  }

  const renderDesigner = () => (
    <Styled.HeadingEmpty>
      <EmptyState
        title={title || 'Başlık'}
        description="Görsellerinizi dashboard üzerinde görüntüleyebilirsiniz."
        image={{ src: NotFoundHeading }}
        actions={
          <Button
            iconBefore={<Icon name="preferences" size="small" />}
            onClick={() => {
              setModal({ nodeType, caption, title })
            }}
          >
            Başlık ayarları
          </Button>
        }
      />
    </Styled.HeadingEmpty>
  )

  const renderComponentSettings = () => {
    if (modal) {
      const actions = [
        {
          text: 'Ayarları kaydet',
          onClick: () => {
            setModal(null)
            handleChangeProperties(modal)
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]

      return (
        <Modal
          title="Başlık ayarları"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>
            Dashboard'a başlık ekleyerek ve onu özelleştirerek
            görüntüleyebilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Select
                name="type"
                label="Başlık tipi"
                placeholder="Seçim yapınız"
                onChange={(option: SelectValueProps) => {
                  setModal({
                    ...modal,
                    nodeType: (option as SelectOptionProps)
                      .value as HeadingNodeType,
                  })
                }}
                value={
                  HEADING_TYPES
                    ? HEADING_TYPES.find(
                        (type) => type.value === modal.nodeType
                      )
                    : undefined
                }
                options={HEADING_TYPES}
              />
            </Column>
            <Column>
              <Input
                name="title"
                label="Başlık"
                value={modal.title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({ ...modal, title: event.target.value })
                }
              />
            </Column>
            <Column>
              <Input
                name="caption"
                label="Alt başlık"
                value={modal.caption}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({ ...modal, caption: event.target.value })
                }
              />
            </Column>
          </Row>
          <br />
          <br />
        </Modal>
      )
    }
  }

  const renderHeading = () => {
    const Heading = nodeType

    return (
      <Styled.Heading>
        {title && <Heading>{title}</Heading>}
        {caption && <h4>{caption}</h4>}
      </Styled.Heading>
    )
  }

  return (
    <div
      data-schema-id={id}
      className={['dnd-widget', designing && 'fake-draggable']
        .filter((e) => !!e)
        .join(' ')}
    >
      {designing ? (
        <React.Fragment>
          {renderComponentSettings()}
          <ComponentHeader
            componentEntry={entry}
            componentSchemaId={id as string}
          />
          {renderDesigner()}
        </React.Fragment>
      ) : (
        renderHeading()
      )}
    </div>
  )
}

export default Heading
