import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { getCountryPerceptions } from '../../../services/http/backoffice/country-perception/endpoints'
import backofficeDefinitionService from 'services/http/backoffice/definition/'

import { WebsiteResponseValues } from './../../../services/http/backoffice/definition/types'

import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, Row, PageHeader } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Checkbox from '@zera-admin/checkbox'
import Icon from '@zera-admin/icon'
import Form, {
  FormSection,
  useForm,
  FormHookSubmitProps,
  FormFooter,
  FormHeader,
} from '@zera-admin/form'
import { cos } from '@amcharts/amcharts4/.internal/core/utils/Math'

const Website = () => {
  const [formValues, setFormValues] = useState<{
    countryPerceptions: Array<SelectOptionProps>
  }>({
    countryPerceptions: [],
  })
  const [blackListWord, setBlackListWord] = useState<string>('')
  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    getCountryPerceptions()
      .then((res) => res.data.data)
      .then((res) =>
        setFormValues((state) => ({
          ...state,
          countryPerceptions: res.map((country: any) => {
            return { label: country?.description, value: country?.key }
          }),
        }))
      )
  }, [])

  const initialValues: WebsiteResponseValues = {
    crawlingType: 'fetch',
    definitionType: 'keyword',
    countryPerception: 1,
    allowedLanguages: undefined,
    allowedDbs: undefined,
    useIncomingDefinition: undefined,
    environment: 'Alice',
    definitionBlackLists: undefined,
    periodicFetch: undefined,
    period: undefined,
    websiteGoogleRecommendationOption: {
      value: '',
    },
  }

  const form = useForm<WebsiteResponseValues>({
    initialValues,
    onSubmit: (props) => {
      handlerSubmit(props)
    },
  })

  const handlerSubmit = (props: FormHookSubmitProps<WebsiteResponseValues>) => {
    console.log(form.values)
    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createWebsite(form.values)
          .then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
            form.handleBatchUpdate(initialValues)
            form.handleReset()
          })
          .catch((error) => {
            console.log(error)

            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
            form.handleBatchUpdate(initialValues)
            form.handleReset()
          })
      }
    }
  }
  console.log(form.values)

  const handleAddBlackListWord = () => {
    if (blackListWord) {
      form.handleFieldChange('definitionBlackLists', [
        ...(form.values.definitionBlackLists || []),
        blackListWord,
      ])
      setBlackListWord('')
    }
  }

  const handleRemoveBlackListWord = (index: number) => {
    form.handleFieldChange(
      'definitionBlackLists',
      form.values.definitionBlackLists?.filter((_, i) => i !== index)
    )
  }

  const renderPageContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormHeader
            title="Website Tanımlama Formu"
            description={
              <div style={{ marginBottom: '1.5rem' }}>
                <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
                <p style={{ margin: '0', color: '#A73121' }}>
                  Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
                </p>
              </div>
            }
          />
          <FormSection>
            <Row xs={{ direction: 'column' }}>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="*Definition Value"
                  name="definitionValue"
                  placeholder="Takip edilecek kelime giriniz."
                  isRequired
                  onChange={(props) =>
                    form.handleFieldChange(
                      'definitionValue',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.definitionValue}
                />
              </Column>
            </Row>
            {/* <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Dil Seçiniz"
                  name="allowedLanguages"
                  description="*İzin verilen diller listesi"
                  placeholder="Seçim yapın"
                  isMultiple
                  options={[
                    { value: 'Turkish', label: 'Turkish' },
                    { value: 'English', label: 'English' },
                  ]}
                  onChange={(props) => {
                    const value = props as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedLanguages',
                      value.length > 0
                        ? value.map((lang) => lang.value)
                        : undefined
                    )
                  }}
                  value={form.values.allowedLanguages?.map((lang) => {
                    return { value: lang, label: lang }
                  })}
                />
              </Column> */}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Algı Seçiniz"
                  name="countryPerception"
                  options={formValues.countryPerceptions}
                  placeholder="Seçim yapın"
                  defaultValue={
                    formValues.countryPerceptions.find(
                      (country) => country.label === 'Türkiye'
                    ) || { label: 'Türkiye', value: 1 }
                  }
                  value={formValues.countryPerceptions.find(
                    (country) => country.value === form.values.countryPerception
                  )}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'countryPerception',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Database Seçimi"
                  name="allowedDbs"
                  placeholder="Seçim yapın"
                  description="*Çekilen verilerin işlendikten sonra hangi db 'lere kaydedileceğini belirtir."
                  isMultiple
                  options={[{ value: 'oracle', label: 'Oracle' }]}
                  onChange={(props) => {
                    const value = props as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedDbs',
                      value.length > 0 ? value.map((db) => db.value) : undefined
                    )
                  }}
                  value={form.values.allowedDbs?.map((db) => {
                    return { value: db.toLowerCase(), label: db }
                  })}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  name="useIncomingDefinition"
                  label="Bu definitiondan gelen postlar direkt olarak bu definition ile
                  işaretlensin mi ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      props.currentTarget.checked
                    )
                  }}
                  defaultChecked={form.values.useIncomingDefinition}
                  isChecked={form.values.useIncomingDefinition}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Ortam seçiniz"
                  name="environment"
                  options={[{ label: 'Alice', value: 'Alice' }]}
                  onChange={(selectedOption) => {
                    const selectedValue = (selectedOption as SelectOptionProps)
                      .value
                    form.handleFieldChange('environment', selectedValue)
                  }}
                  placeholder="Seçim yapın"
                  isMultiple={false}
                />
              </Column>
            </Row>
            <>
              <Row>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="blackListWord"
                    label="Tanımlama Kara Listesi"
                    placeholder="Bir kara liste kelimesi giriniz"
                    description="Bir kara liste kelimesi giriniz"
                    value={blackListWord}
                    onChange={(props) => {
                      setBlackListWord(props.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4 }}>
                  <Button
                    onClick={handleAddBlackListWord}
                    appearance="primary"
                    children="Ekle"
                    style={{ marginTop: '16px' }}
                  />
                </Column>
              </Row>
              {form.values.definitionBlackLists?.map((word, index) => (
                <Row key={index}>
                  <Column xs={{ size: 4 }}>{word}</Column>
                  <Column>
                    <Button
                      onClick={() => handleRemoveBlackListWord(index)}
                      appearance="error"
                      children="Kaldır"
                      style={{ marginTop: '16px' }}
                    />
                  </Column>
                </Row>
              ))}
            </>
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  label="Periyodik olarak çekilsin mi ?"
                  name="periodicFetch"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      props.currentTarget.checked
                    )
                  }}
                  isChecked={form.values.periodicFetch}
                />
              </Column>
            </Row>
            {form.values.periodicFetch && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Input
                    name="period"
                    placeholder="Periyot giriniz (0 * * * *)"
                    description="Geçerli bir periyot giriniz"
                    onChange={(props) =>
                      form.handleFieldChange(
                        'period',
                        props.currentTarget.value
                      )
                    }
                    value={form.values.period}
                    isRequired={form.values.periodicFetch}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Google Önerilen ayarlar"
                  name="websiteGoogleRecommendationOption"
                  placeholder="Takip edilecek kelime giriniz."
                  isRequired
                  onChange={(props) =>
                    form.handleFieldChange(
                      'websiteGoogleRecommendationOption',
                      {
                        ...form.values.websiteGoogleRecommendationOption,
                        value: props.currentTarget.value,
                      }
                    )
                  }
                  value={form.values.websiteGoogleRecommendationOption?.value}
                />
              </Column>
            </Row>
          </FormSection>
          <FormFooter align="start">
            <Button
              isDisabled={
                (form.values.periodicFetch && !form.values.period) ||
                form.values.definitionValue === ''
              }
              type="submit"
              appearance="primary"
              children="Tanımlama Oluştur"
              iconBefore={<Icon name="add-item" />}
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem text="Tanımlama" href="/definition" />
              <BreadcrumbItem
                text="Tanımlama oluştur"
                href="/definition/create"
              />
              <BreadcrumbItem text="Website" />
            </Breadcrumb>
          }
        />
        {renderPageContent()}
      </Page>
    </MainLayout>
  )
}

export default Website
