import React from 'react'
import * as Styled from './Header.styled'
import Logo from 'assets/images/tgaheader.png'

export interface GraphCard {
  date?: any
  number?: number
}
const Header: React.FunctionComponent<GraphCard> = ({ date, number }) => {
  const dates = new Date(date)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    // second: '2-digit',
    // hour: '2-digit',
    // minute: '2-digit',
  }
  const formattedDate = dates.toLocaleDateString('tr-TR', options)

  return (
    <Styled.Container>
      <div id="card-box" className="header-logo-container">
        <img className="header-logo" src={Logo} alt="" />
      </div>
      <div className="header-main-title" style={{}}></div>
      <h1 style={{}} className="header-title">
        Türkiye Stres Raporu
      </h1>
      <div className="header-pager">
        <span className="header-pager-title">{number}</span>
        <span className="header-pager-date">{formattedDate}</span>
      </div>
    </Styled.Container>
  )
}

export default Header
