import React from 'react'
import Modal from '@zera-admin/modal'
import Icon from '@zera-admin/icon'
import { Column, Row } from '@zera-admin/page'

import NotFoundImage from 'assets/images/not-found.png'

import {
  TwitterBackground,
  TwitterProfileImage,
  TwitterPersonProfileInfo,
} from './TwitterDetailModal.styled'
import { TwitterDetailModalProps } from './types'
import { getMonthAndYear } from 'app/functions'

const TwitterDetailModal: React.FunctionComponent<TwitterDetailModalProps> = ({
  onClose,
  data,
}) => {
  const actions = [
    {
      text: 'Kapat',
      onClick: () => {
        onClose()
      },
    },
    {
      text: 'Hesaba Git',
      onClick: () => {
        if (data.username) {
          window.open(`https://twitter.com/${data.username}`, '_blank')?.focus()
        }
      },
    },
  ]
  return (
    <Modal width={700} height={550} onClose={onClose} actions={actions}>
      <TwitterBackground>
        {/* <img src={data.} alt="background" /> */}
      </TwitterBackground>
      <TwitterProfileImage>
        <img
          src={data.profileImageUrl}
          alt="profile"
          onError={(event) => {
            event.currentTarget.src = NotFoundImage
          }}
        />
      </TwitterProfileImage>
      <TwitterPersonProfileInfo>
        <Column
          style={{ fontSize: '35px', paddingBottom: '0px', color: '#ffffff' }}
        >
          {data.username}
        </Column>
        <Column style={{ paddingTop: '0px' }}>@{data.username}</Column>
        <Column style={{ color: '#ffffff' }}>{data.description}</Column>
        <Row style={{ padding: '0px 5px' }}>
          <Column>
            <Icon name="location" size="small" color="blue" /> {data.location}
            &nbsp; <Icon name="link" size="small" color="blue" />
            <span style={{ color: '#00aced' }}>&nbsp;</span>
          </Column>
          <Column>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(data.createdDate)} tarihinde katıldı
          </Column>
        </Row>
        <Column>
          <span>{data.followingCount}&nbsp;</span> Takip Edilen
          <span> &nbsp;{data.followerCount}&nbsp;</span> Takipçi
        </Column>
      </TwitterPersonProfileInfo>
    </Modal>
  )
}
export default TwitterDetailModal
