import React from 'react'
import { EksiCardProps } from './types'
import defaultUserProfile from './images/default-user.png'

import * as Styled from './EksiCard.styled'
import logo from './images/logo.png'
import up from './images/up.svg'
import down from './images/down.svg'

const EksiCard: React.FC<EksiCardProps> = ({ data, editmode, onChange }) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  const toLocaleDate = (date: string) => {
    // ISO tarihini alalım
    const isoDate = date // Örnek ISO formatlı tarih

    // ISO tarihini Date nesnesine dönüştürelim
    const dateObject = new Date(isoDate)

    // Tarihi Türkçe formatında yerel tarihe çevirip yazdıralım
    const localDate = dateObject.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    return localDate
  }

  // function hasErrorImage(e: React.SyntheticEvent) {
  //   e.currentTarget.setAttribute("src", logo);
  // }

  const textLength = data?.Text && data?.Text?.length >= 400

  return (
    <Styled.EksiCardWrapper>
      <div className="post">
        <div
          contentEditable={editmode}
          onBlur={(e) => handleBlurElement(e, 'TitleText')}
          className="post-header"
        >
          {data?.TitleText}
        </div>
        <div className="post-body">
          <div
            contentEditable={editmode}
            onBlur={(e) => handleBlurElement(e, 'Text')}
            className="post-body-entry"
          >
            {textLength && !isOpen && !editmode
              ? `${data?.Text?.substring(0, 400)}...`
              : data?.Text}
          </div>
          {textLength && !editmode && (
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? 'Daha Az' : 'Daha Fazla'}
            </button>
          )}
          <div className="post-body-info">
            <div className="post-body-info-count">
              <img src={up} alt="up" />

              {data?.FavoriteCount}

              <img src={down} alt="up" />
            </div>
            <div className="post-body-info-author">
              <div className="post-body-info-author-wrap">
                <div className="post-body-info-author-name">
                  {data?.AuthorName}
                </div>
                <div className="post-body-info-author-date">
                  {toLocaleDate(data?.CreatedAt as string)}
                </div>
              </div>
              <div className="post-body-info-author-profile">
                <img src={defaultUserProfile} alt="profile" />
              </div>
            </div>
          </div>
        </div>
        <div className="post-footer">
          <div className="post-footer-logo">
            <img src={logo} alt="eksi" />
          </div>
          <div className="post-footer-url eksi-green">
            <a href={data?.Url} target="_blank" rel="noreferrer">
              Post'u görüntüle
            </a>
          </div>
        </div>
      </div>
    </Styled.EksiCardWrapper>
  )
}

export default EksiCard
