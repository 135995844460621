import { NewsList, NewsListResponse } from './types'

export const mapNewsListForResponse = (raw: any): NewsList => ({
  alexaLanguage: raw.data.alexaLanguage,
  blackListStatus: raw.data.blackListStatus,
  country: raw.data.country,
  description: raw.data.description,
  isTopNewsSource: raw.data.isTopNewsSource,
  link: raw.data.link,
  linksInCount: raw.data.linksInCount,
  name: raw.data.name,
  newsLink: raw.data.newsLink,
  onlineSince: raw.data.onlineSince,
  rank: raw.data.rank,
  turkeyRank: raw.data.turkeyRank,
  id: raw.data.id,
  type: raw.data.type,
  value: raw.data.value,
  createdAt: raw.data.createdAt,
  dataSource: raw.data.dataSource,
})

export const mapNewsListForList = (raw: any): NewsListResponse => ({
  list: raw.data.map((item: unknown) => mapNewsListForResponse({ data: item })),
  dataCount: raw.dataCount,
  pageCount: raw.pageCount,
  success: raw.statusCode,
})

export const mapNewsListForRequest = (raw: any): NewsListResponse => ({
  list: raw.data.map((item: unknown) => mapNewsListForResponse({ data: item })),
  dataCount: raw.dataCount,
  pageCount: raw.pageCount,
  success: raw.statusCode,
})
