import { string } from '@zera-admin/helpers'
import { getIntervalPeriod } from 'app/functions'

import { Field } from 'services/http/bi-tool/field'
import { QueryCriteriaInterval } from 'services/http/bi-tool/query/enums'
import { Widget } from 'services/http/bi-tool/widget'

export const getFieldOptions = (fields: Field[]) => {
  const result = fields.map((value) => ({
    label: string.textify(value.field || ''),
    value: value.field,
  }))

  return [{ label: 'Varsayılan', value: '' }, ...result]
}

export const getCriteriaInterval = (
  widget: Widget
): QueryCriteriaInterval | undefined => {
  if (widget.criteria) {
    const { interval } = widget.query
    const period = getIntervalPeriod(interval)

    if (period === 'monthly') {
      return QueryCriteriaInterval.Monthly
    } else if (period === 'weekly') {
      return QueryCriteriaInterval.Weekly
    }
    return QueryCriteriaInterval.Daily
  }
}
