import React, { useState } from 'react'
import { uid } from '@zera-admin/utils'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button from '@zera-admin/button'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { ValidationError } from 'app/shared/types'
import MainLayout from 'layouts/main'
import commonRuleService, {
  CreateParams,
} from 'services/http/backoffice/common-rule'

import { CreateCommonRuleProps } from './types'
import EntityTypeForm from './CommonRuleForm'

const CreateCommonRule: React.FunctionComponent<CreateCommonRuleProps> = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()

  const handleSubmit = (values: CreateParams, handleReset: () => void) => {
    if (values) {
      setIsLoading(true)

      commonRuleService
        .create(values)
        .then(() => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: 'Kayıt işlemi başarılı',
            children:
              'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
          })

          handleReset()
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleError = (error?: ValidationError) => {
    console.log(error)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Alarmlar" href="/alarms" />
      <BreadcrumbItem
        text="Telegram Kanala Kullanıcı Listesi"
        href="/alarms/common-rule"
      />
      <BreadcrumbItem text="Telegram Kanala Kullanıcı Oluştur" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Container width="medium">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Aşağıdaki bilgileri doldurarak yeni bir telegram kanala
                kullanıcı oluşturabilirsiniz..
              </p>
            }
          >
            Telegram Kanala Kullanıcı oluştur
          </PageHeader>
          <EntityTypeForm
            actions={
              <Button
                appearance="primary"
                children="Telegram Kanala Kullanıcı oluştur"
                isLoading={isLoading}
                type="submit"
              />
            }
            operation="create"
            onError={handleError}
            onSubmit={handleSubmit}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default CreateCommonRule
