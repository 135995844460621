import { SentimentControlSet, SentimentControlSetResponse } from './types'

export const mapSentimentControlSetForRequest = (
  raw: SentimentControlSet
): unknown => ({
  sentiment_control_set_id: raw.id,
  conclusion: raw.conclusion,
  language_code: raw.languageCode,
  text: raw.text,
})

export const mapSentimentControlSetForResponse = (
  raw: any
): SentimentControlSet => ({
  id: raw.data.id,
  conclusion: raw.data.conclusion,
  currentVersion: raw.data.current_version,
  languageCode: raw.data.language_code,
  text: raw.data.text,
  userId: raw.data.user_id,
})

export const mapSentimentControlSetForList = (
  raw: any
): SentimentControlSetResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapSentimentControlSetForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
