import * as React from 'react'
import SortableJS from 'sortablejs'

interface SortableProps {
  sortableOptions: SortableJS.Options
  className?: string
  isInitialized?: boolean
  children: React.ReactNode
}

export default class Sortable extends React.Component<SortableProps> {
  isInitialized = false
  divRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    if (this.divRef.current) {
      SortableJS.create(this.divRef.current, this.props.sortableOptions)
      this.isInitialized = true
    }
  }

  render() {
    return (
      <div className={this.props.className} ref={this.divRef}>
        {this.props.children}
      </div>
    )
  }
}
