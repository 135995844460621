import styled from 'styled-components'

export const Hotel = styled.div<{ isLoaded?: boolean; isRecent?: boolean }>`
  width: 100%;
  position: relative;
  transform-origin: center;
  opacity: 1;
  background-color: transparent;
  top: 0px;

  &.is-loaded {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
    transition: all, 0.75s;
    -moz-transition: all, 0.75s;
    -webkit-transition: all, 0.75s;
    top: 0;
    width: 100%;
  }
`

export const HotelLozenge = styled.span`
  position: absolute;
  right: 60px;
  top: 16px;
`

export const HotelList = styled.div`
  max-height: calc(50vh);
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 12px;
  transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -webkit-transition: all, 0.5s;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 20px;
  }
`
export const Container = styled.div`
  border: 1px solid black;
  border-radius: 6px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  @media only screen and (width <= 728px) {
    display: block;
  }
  .hotel-card-img-container {
    width: 45%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    @media only screen and (width <= 728px) {
      width: 100%;
    }
    a {
      width: 100%;
      border-radius: 6px 0 0 6px;
      height: 100%;
      @media only screen and (width <= 728px) {
        height: 170px;
        border-radius: 6px 6px 0 0;
      }
    }
    img {
      width: 100%;
      border-radius: 6px;
      height: 100%;
      @media only screen and (width <= 728px) {
        height: 170px;
        border-radius: 6px;
      }
    }
  }
  .hotel-card-content-container {
    width: 100%;
    padding: 12px;
    display: flex;
    @media only screen and (width >= 2880px) {
      padding: 1rem;
    }
    flex-direction: column;
    .hotel-card-content-container-title {
      width: 100%;
      text-shadow: 2px 5px 3px rgba(0, 0, 0, 0.2);
      @media only screen and (width >= 1600px) {
        font-size: 1.1rem;
      }
      @media only screen and (width >= 1800px) {
        font-size: 1.25rem;
      }
      @media only screen and (width >= 1920px) {
        font-size: 1.35rem;
      }
      @media only screen and (width >= 2160px) {
        font-size: 1.55rem;
      }
      @media only screen and (width >= 2880px) {
        font-size: 2.2rem;
      }
    }
    .hotel-card-content-container-location {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      margin-top: 0.4rem;
      i {
        @media only screen and (width >= 1600px) {
          font-size: 1.1rem;
        }
        @media only screen and (width >= 1800px) {
          font-size: 1.2rem;
        }
        @media only screen and (width >= 1920px) {
          font-size: 1.3rem;
        }
        @media only screen and (width >= 2160px) {
          font-size: 1.4rem;
        }
        @media only screen and (width >= 2880px) {
          font-size: 1.7rem;
        }
        font-size: 1rem;
        opacity: 1 !important;
      }
      .hotel-card-location-name {
        @media only screen and (width >= 1600px) {
          font-size: 0.9rem;
        }
        @media only screen and (width >= 1800px) {
          font-size: 1rem;
        }
        @media only screen and (width >= 1920px) {
          font-size: 1.1rem;
        }
        @media only screen and (width >= 2160px) {
          font-size: 1.2rem;
        }
        @media only screen and (width >= 2880px) {
          font-size: 1.5rem;
        }
        font-size: 0.8rem;
        opacity: 0.6;
      }
    }
    .hotel-card-content-container-desc {
      width: 100%;
      font-size: 0.8rem;
      margin-top: 0.3rem;
      @media only screen and (width >= 1600px) {
        font-size: 0.92rem;
      }
      @media only screen and (width >= 1800px) {
        font-size: 1.05rem;
      }
      @media only screen and (width >= 1920px) {
        font-size: 1.15rem;
      }
      @media only screen and (width >= 2160px) {
        font-size: 1.3rem;
      }
      @media only screen and (width >= 2880px) {
        font-size: 1.8rem;
        margin-top: 0.8rem;
      }
    }
    .hotel-card-content-container-rating {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;

      span {
        font-size: 0.8rem;
        @media only screen and (width >= 1600px) {
          font-size: 0.9rem;
        }
        @media only screen and (width >= 1800px) {
          font-size: 1rem;
        }
        @media only screen and (width >= 1920px) {
          font-size: 1.1rem;
        }
        @media only screen and (width >= 2160px) {
          font-size: 1.2rem;
        }
        @media only screen and (width >= 2880px) {
          font-size: 1.6rem;
        }
      }
    }
  }
  .hotel-card-arrangement {
    margin-top: 0.4rem;
    span {
      color: #1aa3ff;
    }
  }
  .hotel-card-bold {
    font-weight: bold;
  }
`
