import styled from 'styled-components'

export const Table = styled.div`
  box-sizing: border-box;
  width: 100%;
`

export const TableSummary = styled.div`
  margin-top: 10px;
  width: 100%;
  text-align: right;
  font-weight: 500;

  strong {
    font-weight: 700;
  }
`
