import styled from 'styled-components'

export const WidgetScreenEmptyContent = styled.div`
  max-width: 450px;
  margin: 0px auto;
  padding-bottom: 100px;
`

export const WidgetScreenFooter = styled.div`
  background: #fff;
  border-top: 1px solid #ececec;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  z-index: 99;

  button.success {
    margin-left: 20px;
    background: #11875f;

    &:hover {
      background: #0d7754;
    }
  }

  button.primary-outline {
    background: rgba(179, 212, 255, 0.4);

    * {
      color: #0052cc;
    }

    &:hover {
      background: rgba(179, 212, 255, 0.6);
    }
  }
`

export const WidgetScreenTimePicker = styled.div`
  min-width: 350px;
`
