import React from 'react'
import * as Styled from './Analysis.styled'
import { IExecutiveSummaryAnalysisProps } from './types'

const ExecutiveSummaryAnalysis: React.FC<IExecutiveSummaryAnalysisProps> = ({
  editMode,
  data,
  onChange,
}) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  return (
    <Styled.Analysis>
      <h1
        contentEditable={editMode}
        onBlur={(e) => handleBlurElement(e, 'SummaryTitle')}
        className="analysis-title"
      >
        {data?.SummaryTitle}
      </h1>

      <p
        contentEditable={editMode}
        onBlur={(e) => handleBlurElement(e, 'Summary')}
        className="analysis-text"
      >
        {data?.Summary}
      </p>
    </Styled.Analysis>
  )
}

export default ExecutiveSummaryAnalysis
