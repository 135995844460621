import styled from 'styled-components'

export const RoleFormClaims = styled.div`
  div[role='tablist'] {
    width: 100%;
    margin: 0px -8px;
  }

  div[role='tabpanel'] {
    width: 100%;
    margin: 0px -8px;

    & > div {
      width: 100%;
    }

    table {
      width: 100%;
    }
  }
`

export const RoleFormTableButton = styled.div`
  font-weight: 500;
  cursor: pointer;
  margin-top: 8px;
  color: #0052cf;
  font-size: 0.85rem;
  width: 60%;
  margin-bottom: -45px;
`
