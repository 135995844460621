import Accordion from '@zera-admin/accordion'

import TwitterProfile from 'components/twitter-profile'
import WordCloud from 'components/word-cloud'
import MostFollowedFollowers from 'widgets/person-report/most-followed-followers'

import {
  ActiveHours,
  Aspect,
  AverageSentiments,
  Big5,
  CategoriesByTime,
  CountOfPostTypes,
  Email,
  Facebook,
  FollowersBotDetection,
  FollowersByCountry,
  // FollowersByGender,
  GeneralCategories,
  Google,
  Hashtag,
  LanguagesUsedByUser,
  Linkedin,
  Mention,
  MetricsOfOriginalTweets,
  MostCommonEntities,
  MostImpressedBy,
  TweetImpressions,
  Tweets,
  TweetsByTimeAfter,
  TweetsCountBefore,
  Wikipedia,
} from '.'
import { CollapsesProps } from '../types'

const AllReport: React.FunctionComponent<{ data: any }> = ({ data }) => {
  const collapses: CollapsesProps[] = [
    {
      children: <TwitterProfile data={data?.result?.TrackingUser} />,
      name: 'activeHours',
      title: 'X kullanıcı bilgileri',
      defaultExpanded: true,
    },
    {
      children: <Big5 data={data?.result?.Statistic?.bigFive} />,
      name: 'bigFive',
      title: 'Big 5 verisi',
      defaultExpanded: true,
    },
    {
      children: <ActiveHours data={data?.result?.Statistic?.activeHours} />,
      name: 'activeHours',
      title: 'Kullanıcının aktif saatleri',
      defaultExpanded: true,
    },
    {
      children: data?.result?.Statistic?.aspectAnalysis?.aspect?.map(
        (item: any) => <Aspect data={item} />
      ),
      name: 'aspectAnalysis',
      title: "Post'larının ABS analizi",
      defaultExpanded: true,
    },
    {
      children: (
        <MostCommonEntities
          data={data?.result?.Statistic?.aspectAnalysis?.mostCommonEntities}
        />
      ),
      name: 'aspectAnalysis',
      title: 'ABS Analizine göre en çok kullandığı kelimeler',
      defaultExpanded: true,
    },
    {
      children: (
        <AverageSentiments data={data?.result?.Statistic?.averageSentiments} />
      ),
      name: 'averageSentiments',
      title: "Post'larının Ortalama Duygu Analizi",
      defaultExpanded: true,
    },
    {
      children: data?.result?.Statistic?.classifications?.categoriesByTime?.map(
        (classification: any) =>
          classification.classifications.length > 0 ? (
            <CategoriesByTime data={classification} />
          ) : null
      ),
      name: 'categoriesByTime',
      title: 'Zamana göre sınıflandırmalar (Aylık)',
      defaultExpanded: true,
    },
    {
      children: (
        <CountOfPostTypes data={data?.result?.Statistic?.countOfPostTypes} />
      ),
      name: 'countOfPostTypes',
      title: 'Tweet Tipine göre Tweet sayıları',
      defaultExpanded: true,
    },
    {
      children: (
        <FollowersBotDetection
          data={data?.result?.Statistic?.followersBotDetection}
        />
      ),
      name: 'botDetection',
      title: 'Takipçilerinin potansiyel bot dağılımı',
      defaultExpanded: true,
    },
    {
      children: (
        <FollowersByCountry
          data={data?.result?.Statistic?.followersByCountry}
        />
      ),
      name: 'followersByCountry',
      title: 'Takipçilerinin ülke dağılımı',
      defaultExpanded: true,
    },
    // {
    //   children: (
    //     <FollowersByGender data={data?.result?.Statistic.followersByGender} />
    //   ),
    //   name: 'followersByGender',
    //   title: 'Takipçilerinin cinsiyet dağılımı',
    //   defaultExpanded: true,
    // },
    {
      children: (
        <GeneralCategories
          data={data?.result?.Statistic?.classifications?.generalCategories}
        />
      ),
      name: 'generalCategories',
      title: 'Genel kategoriler',
      defaultExpanded: true,
    },
    {
      children: <Email data={data?.result?.Statistic?.email} />,
      name: 'emailSearch',
      title: 'Email',
      defaultExpanded: true,
    },
    {
      children: <Facebook data={data?.result?.Statistic?.facebook} />,
      name: 'facebookSearch',
      title: 'Facebook',
      defaultExpanded: true,
    },
    {
      children: <Google data={data?.result?.Statistic?.google?.Data?.items} />,
      name: 'googleSearch',
      title: 'Google',
      defaultExpanded: true,
    },
    {
      children: (
        <LanguagesUsedByUser
          data={data?.result?.Statistic?.languagesUsedByUser}
        />
      ),
      name: 'languagesUsedByUser',
      title: 'Kullanıcının kullandığı dillerin dağılımı',
      defaultExpanded: true,
    },
    {
      children: <Linkedin data={data?.result?.Statistic?.linkedin?.Data} />,
      name: 'linkedinSearch',
      title: 'Linkedin',
      defaultExpanded: true,
    },
    {
      children: (
        <MetricsOfOriginalTweets
          data={data?.result?.Statistic?.metricsOfOriginalTweets}
        />
      ),
      name: 'metricsOfOriginalTweets',
      title: "Orijinal Post'larının Metrikleri",
      defaultExpanded: true,
    },
    {
      children: (
        <MostFollowedFollowers
          data={data?.result?.Statistic?.mostFollowedFollowers}
        />
      ),
      name: 'mostFollowedFollowers',
      title: 'En çok takipçisi olan takipçileri',
      defaultExpanded: true,
    },
    {
      children: (
        <MostImpressedBy data={data?.result?.Statistic?.mostImpressedBy} />
      ),
      name: 'mostImpressedBy',
      title: 'En çok etkilendiği kişiler',
      defaultExpanded: true,
    },
    {
      children: <Mention data={data?.result?.Statistic?.mostMentions} />,
      name: 'mostMentions',
      title: "En çok Mention'ladığı kişiler",
      defaultExpanded: true,
    },
    {
      children: (
        <Tweets
          data={data?.result?.Statistic?.mostRecentTweets}
          profile={data?.result?.TrackingUser}
        />
      ),
      name: 'mostRecentTweets',
      title: "En son Post'ları",
      defaultExpanded: true,
    },
    {
      children: (
        <Tweets
          data={data?.result?.Statistic?.mostRetweetedTweets}
          profile={data?.result?.TrackingUser}
        />
      ),
      name: 'mostRetweeted20Tweets',
      title: 'En çok Repost yapılan postları (20)',
      defaultExpanded: true,
    },
    {
      children: (
        <MostFollowedFollowers
          data={data?.result?.Statistic?.mostTweetedFollowers}
        />
      ),
      name: 'mostTweetedFollowers',
      title: 'En çok Post atan takipçileri',
      defaultExpanded: true,
    },
    {
      children: <Hashtag data={data?.result?.Statistic?.mostUsedHashtags} />,
      name: 'mostUsedHashtags',
      title: "En çok kullandığı Hashtag'ler",
      defaultExpanded: true,
    },
    {
      children: (
        <TweetImpressions data={data?.result?.Statistic?.tweetImpressions} />
      ),
      name: 'tweetImpressions',
      title: "Post'ların ulaştığı kişi sayısı",
      defaultExpanded: true,
    },
    {
      children: (
        <TweetsByTimeAfter
          data={data?.result?.Statistic?.tweetsByTimeAfter2021}
        />
      ),
      name: 'tweetsByTimeAfter2021',
      title: '2021 Sonrası Post Dağılımı (Aylık)',
      defaultExpanded: true,
    },
    {
      children: (
        <TweetsCountBefore
          data={data?.result?.Statistic?.tweetsCountBefore2021}
        />
      ),
      name: 'tweetsByTimeBefore2021',
      title: '2021 Öncesi Post Dağılımı (Yıllık)',
      defaultExpanded: true,
    },
    {
      children: (
        <Wikipedia data={data?.result?.Statistic?.wikipedia?.Data?.data} />
      ),
      name: 'wikipediaSearch',
      title: 'Wikipedia',
      defaultExpanded: true,
    },
    {
      children: <WordCloud data={data?.result?.Statistic?.wordCloud} />,
      name: 'wordCloud',
      title: 'Word Cloud',
      defaultExpanded: true,
    },
  ]

  const renderAccordion = () => {
    const content = collapses.filter((item: CollapsesProps) =>
      data?.requestField?.includes(item.name)
    )

    if (content) {
      return <Accordion data={content} isToggle />
    }

    return null
  }

  return renderAccordion()
}

export default AllReport
