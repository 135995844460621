import { request } from 'services/http/backoffice/instance'
import { mapActivePersonForList, mapFilterResponse } from './mappers'

import type {
  ActivePersonListResponse,
  FilterResponse,
  GetActivesByCategoryCountryParams,
  PostMailParams,
  PutActivesPeopleStatusParams,
} from './types'

export const get = (params: GetActivesByCategoryCountryParams) =>
  request<ActivePersonListResponse>({
    method: 'get',
    url: 'back-office/active-account/get-actives-by-category-country',
    params: params,
    mappers: {
      res: mapActivePersonForList,
    },
  })

export const getFilters = () =>
  request<FilterResponse>({
    method: 'get',
    url: 'back-office/active-account/get-country-and-classification-filters',
    mappers: {
      res: mapFilterResponse,
    },
  })

export const put = (datas: PutActivesPeopleStatusParams) => {
  return request({
    method: 'put',
    url: 'back-office/active-account/update-actives-by-category-country-publish-status',
    data: datas,
  })
}

export const postMail = (datas: PostMailParams) => {
  return request({
    method: 'post',
    url: 'back-office/active-account/past-influential-users',
    data: datas,
  })
}
