import React, { useState } from 'react'
import Tweet, { MenuButtonProps, TweetProps } from '@zera-admin/alice-tweet'

import TrainingGroupModal, {
  TrainingGroupModalEnum,
} from '../training-group-modal'

const TweetTrainingControls: React.FunctionComponent<TweetProps> = (props) => {
  const [trainingModalValues, setTrainingModalValues] = useState<any>()
  const [trainingModal, setTrainingModal] = useState<
    TrainingGroupModalEnum | undefined
  >(undefined)

  const menuButtonsHandleClick = (
    values: any,
    modal: TrainingGroupModalEnum
  ) => {
    setTrainingModalValues(values)
    setTrainingModal(modal)
  }

  const menuButtons: MenuButtonProps[] = [
    {
      text: 'Sınıflandırma eğit',
      icon: 'app-access',
      onClick: (e, context) => {
        menuButtonsHandleClick(
          {
            text: context.tweet,
            entityKey: context.entityKey,
            sourceId: 1,
          },
          TrainingGroupModalEnum.Classification
        )
      },
    },
    {
      text: 'Cinsiyet eğit',
      icon: 'app-access',
      onClick: (e, context) =>
        menuButtonsHandleClick(
          {
            text: context.tweet,
            entityKey: context.entityKey,
            sourceId: 1,
          },
          TrainingGroupModalEnum.Gender
        ),
    },
    {
      text: 'Duygu eğit',
      icon: 'app-access',
      onClick: (e, context) =>
        menuButtonsHandleClick(
          {
            text: context.tweet,
            entityKey: context.entityKey,
            sourceId: 1,
          },
          TrainingGroupModalEnum.Sentiment
        ),
    },
    {
      text: 'İsimlendirilmiş varlık eğit',
      icon: 'app-access',
      onClick: (e, context) =>
        menuButtonsHandleClick(
          {
            text: context.tweet,
            entityKey: context.entityKey,
            sourceId: 1,
          },
          TrainingGroupModalEnum.Named
        ),
    },
  ]

  const renderTrainingGroupModal = trainingModal ? (
    <TrainingGroupModal
      onClose={() => setTrainingModal(undefined)}
      trainingModal={trainingModal}
      values={trainingModalValues}
    />
  ) : null

  return (
    <React.Fragment>
      {renderTrainingGroupModal}
      <Tweet menuButtons={menuButtons} {...props} />
    </React.Fragment>
  )
}

export default TweetTrainingControls
