import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import MainLayout from 'layouts/main'
import crawlTaskService from 'services/http/report/crawl-task'
import {
  AgendaReportStatus,
  AgendaReportType,
} from 'services/http/report/agenda-report/enums'
import agendaReportsService from 'services/http/report/agenda-report-4'

import AgendaReportWidgets from './widgets'

import DetailAgendaReportProcess from './DetailAgendaReportProcess'
import DetailAgendaReportCalculating from './DetailAgendaReportCalculating'
import AgendaCalculationForm from '../form/AgendaCalculationForm'
import AgendaReportWizard from '../wizard'
import { useLocation } from 'react-router-dom'
import { DetailAgendaReportProps, DetailAgendaReportTask } from './types'

import * as Styled from './DetailAgendaReport.styled'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Spinner from '@zera-admin/spinner'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import { usePrintDataParam } from 'app/functions/navigation'
import { Root } from './widgets/types'
import { getVideoService } from 'services/http/video/url/endpoint'

const DetailAgendaReport: React.FunctionComponent<DetailAgendaReportProps> = ({
  match,
}) => {
  const location = useLocation()
  const status = new URLSearchParams(location.search).get('status')
  const isPrint = usePrintDataParam(location)

  const [type, setType] = useState<AgendaReportType | null>()
  const [values, setValues] = useState<any>()
  const [task, setTask] = useState<DetailAgendaReportTask>()
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false)

  const [videoUrl, setVideoUrl] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getTasks()
    getVideoService({ key: 'alice-agenda-report-v4-modification' }).then(
      (res) => {
        setVideoUrl(res?.data?.data?.url || '')
      }
    )
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (task?.main.status !== AgendaReportStatus.Completed) {
        getTasks()
      }
    }, 10000)

    return () => clearInterval(interval)
  })

  const pdfContainerRef = useRef(null)

  const getTasks = () => {
    crawlTaskService
      .event(match.params.id)
      .then((taskResponse) => {
        const result = taskResponse?.data
        const main = result?.data?.find((task) => !task.path)

        if (main) {
          setType(AgendaReportType.Independent)

          setTask({ main, definitions: result?.data })

          if (main?.status === AgendaReportStatus.Completed) {
            getValues()
          }
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }
  const pdfDownload = (url: string, fileName: string) => {
    setIsPdfLoading(true)
    crawlTaskService
      .specificPdf(url, fileName)
      .then((res: any) => {
        var blob = new Blob([res.data], {
          type: 'application/pdf',
        })
        const downloadUrl = URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = downloadUrl
        anchor.download = fileName // Dosyanın adını burada belirleyebilirsiniz
        document.body.appendChild(anchor)
        anchor.click()
        URL.revokeObjectURL(downloadUrl)
        setIsPdfLoading(false)

        // saveAs(blob, "asd.pdf");
      })
      .catch((err: Error) => {
        console.log(err)
        setIsPdfLoading(false)
      })
  }

  const getValues = () => {
    setIsLoading(true)
    agendaReportsService
      .agendaReportV4(match?.params?.id, status)
      .then((res) => {
        setIsLoading(false)
        setValues(res?.data)
      })
      .catch((err: any) => {
        if (err?.reason?.includes('404')) {
          agendaReportsService
            .agendaReportV4(match?.params?.id, 1)
            .then((res) => {
              setIsLoading(false)

              setValues(res?.data)
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
  }

  // document.body.style.overflowX = 'hidden'

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem('scrollPosition')) {
        window.scrollTo({
          top: parseInt(sessionStorage.getItem('scrollPosition') || '0'),
          behavior: 'smooth',
        })
      }
    }, 1000)
  })

  const renderBody = () => {
    if (task?.main.status === AgendaReportStatus.Completed) {
      return (
        <>
          {(isPdfLoading && (
            <Styled.SpinnerContainer className="spinner-container">
              <p>
                İşlem biraz uzun sürebilir, aksi bir durumda lütfen iletişime
                geçin.
              </p>
              <div>
                <Spinner size="medium" />
              </div>
            </Styled.SpinnerContainer>
          )) ||
            null}
          <AgendaReportWidgets
            isLoading={isLoading}
            data={values as Root}
            startDate={task.main.crawlProperties.StartDate}
            endDate={task.main.crawlProperties.EndDate}
          />
        </>
      )
    } else {
      if (type === AgendaReportType.Dependent) {
        return (
          <AgendaReportWizard
            status={
              task?.main.status === AgendaReportStatus.Pending
                ? AgendaReportStatus.Ready
                : (task?.main.status as AgendaReportStatus)
            }
            steps={[
              {
                label: 'Genel Bilgiler',
                value: AgendaReportStatus.Create,
                description:
                  'Aşağıdan Gündem Raporu ile ilgili genel bilgileri girebilirsiniz. Ardından diğer adımlara geçilecektir.',
              },
              {
                label: 'Hazırlanıyor',
                value: AgendaReportStatus.Ready,
                description:
                  'Veriler toplanmaya hazırlanıyor. Bu işlem, diğer veri toplama işlemlerinin yoğunluğuna göre zaman alabilmektedir.',
              },
              {
                label: 'Veriler Toplanıyor',
                value: AgendaReportStatus.Processing,
                description:
                  'Veriler toplanıyor. İşlemin tamamını dilediğiniz zaman durdurabilirsiniz. Fetch (çekilen verileri) ve streamleri (canlı veri akışları) durdurabilir veya silebilirsiniz.',
                children: (
                  <DetailAgendaReportProcess
                    onFetch={() => getTasks()}
                    task={task as DetailAgendaReportTask}
                    groupId={match.params.id}
                  />
                ),
              },
              {
                label: 'Hesaplama Ayarları',
                value: AgendaReportStatus.Stopped,
                description:
                  'Aşağıdan toplanan veriler ile ilgili hesaplanacak alanları belirleyebilirsiniz.',
                children: (
                  <AgendaCalculationForm
                    onFetch={() => getTasks()}
                    groupId={match.params.id}
                  />
                ),
              },
              {
                label: 'Hesaplama Yapılıyor',
                value: AgendaReportStatus.Calculating,
                description:
                  'Hesaplama yapılıyor. Bu işlem biraz zaman alabilir. İşlem tamamlandıktan sonra, raporunuzu görüntüleyebilirsiniz.',
                children: (
                  <DetailAgendaReportCalculating
                    task={task as DetailAgendaReportTask}
                  />
                ),
              },
              {
                label: 'Tamamlandı',
                value: AgendaReportStatus.Completed,
              },
            ]}
          />
        )
      } else if (type === AgendaReportType.Independent) {
        return (
          <AgendaReportWizard
            status={
              task?.main.status === AgendaReportStatus.Pending
                ? AgendaReportStatus.Ready
                : (task?.main.status as AgendaReportStatus)
            }
            steps={[
              {
                label: 'Genel Bilgiler',
                value: AgendaReportStatus.Create,
                description:
                  'Aşağıdan Gündem Raporu ile ilgili genel bilgileri girebilirsiniz. Ardından diğer adımlara geçilecektir.',
              },
              {
                label: 'Hazırlanıyor',
                value: AgendaReportStatus.Ready,
                description:
                  'Veriler işlenmeye hazırlanıyor. Bu işlem, diğer veri işlemelerinin yoğunluğuna göre zaman alabilmektedir.',
              },
              {
                label: 'Kuyrukta Bekliyor',
                value: AgendaReportStatus.Pending,
                description:
                  'Veriler işlenmek üzere kuyruğa alındı. Bu işlem, diğer veri işlemelerinin yoğunluğuna göre zaman alabilmektedir.',
              },
              {
                label: 'Veriler İşleniyor',
                value: AgendaReportStatus.Processing,
                description:
                  'Veriler işleniyor. Bu işlem biraz zaman alabilir. İşlem tamamlandıktan sonra, raporunuzu görüntüleyebilirsiniz.',
              },
              {
                label: 'Tamamlandı',
                value: AgendaReportStatus.Completed,
              },
            ]}
          />
        )
      }
    }
  }

  const reportType = values?.data?.reportType
  const renderContent = () => (
    <div ref={pdfContainerRef}>
      <Page id="printThis" className="fa-Page">
        {!isPrint && (
          <PageHeader
            breadcrumbs={
              <Breadcrumb>
                <BreadcrumbItem text="Anasayfa" href="/" />
                <BreadcrumbItem
                  text="Gündem Raporu V4 Listesi"
                  href="/agenda-reportv4"
                />
                <BreadcrumbItem text="Gündem raporu detayı" />
              </Breadcrumb>
            }
            bottomBar={
              <p>
                Oluşturmuş olduğunuz raporun detaylarını aşağıdan
                görüntüleyebilirsiniz.
              </p>
            }
            actions={
              <Styled.HeaderButtonsContainer>
                <Button
                  id="pdf-button"
                  iconBefore={<Icon name="download" size="small" />}
                  onClick={() =>
                    pdfDownload(
                      `https://${window.location.host}/agenda-reportv4/detail/${
                        match?.params?.id
                      }?status=${
                        window.location.href.split('status=')[1]
                      }&print=1`,
                      `${task?.main?.description} Raporu`
                    )
                  }
                >
                  PDF olarak indir
                </Button>
                <Button
                  iconBefore={<Icon name="vid-camera-on" />}
                  appearance="button"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  Yardım al
                </Button>
                {reportType && (
                  <div
                    style={{
                      background: reportType === 'Edited' ? 'orange' : 'green',
                      color: 'white',
                      padding: 6,
                      borderRadius: 3,
                    }}
                  >
                    {reportType}
                  </div>
                )}
              </Styled.HeaderButtonsContainer>
            }
          >
            {task?.main.description || 'Gündem Raporu V4'}
          </PageHeader>
        )}

        {renderBody()}
      </Page>
    </div>
  )
  const renderModal = () => (
    <Styled.ModalContainer>
      <div id="modal-overlay" className="modal-overlay" />
      <div className="modal-content">
        <div className="modal-content-button">
          <button onClick={() => setModalOpen(false)}>Kapat</button>
        </div>
        <div className="modal-content-video">
          {videoUrl && (
            <iframe
              src={videoUrl}
              width="640"
              height="480"
              title="Video Player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          )}
        </div>
      </div>
    </Styled.ModalContainer>
  )

  return isLoading || isPdfLoading ? (
    <LoadingView>
      <Spinner size="large" />
      <h5>{isPdfLoading ? 'PDF Hazırlanıyor...' : 'Rapor yükleniyor...'}</h5>
    </LoadingView>
  ) : isPrint ? (
    <div style={{ padding: '5px 10px' }} className="print-container">
      {renderContent()}
    </div>
  ) : (
    <>
      <MainLayout>{renderContent()}</MainLayout>
      {modalOpen && renderModal()}
    </>
  )
}

export default DetailAgendaReport
