import { useHistory } from 'react-router-dom'
import Spinner from '@zera-admin/spinner'
import PageLayout, {
  NavProps,
  NotificationsProps,
  ProductHomeProps,
  ProfileProps,
  SearchProps,
} from '@zera-admin/page-layout'

import { BIToolLayoutProps } from './types'
import * as Styled from './BIToolLayout.styled'

import AliceLogo from 'assets/images/logo.png'
import KtbIcon from 'assets/images/ktb-icon.png'

const BIToolLayout: React.FunctionComponent<BIToolLayoutProps> = ({
  children,
  isLoading,
}) => {
  const history = useHistory()

  const product: ProductHomeProps = {
    icon: () => (
      <img
        style={{ minHeight: 40, filter: 'brightness(0)' }}
        src={KtbIcon}
        alt="Kültür ve Turizm Bakanlığı"
      />
    ),
    logo: () => (
      <img
        style={{ filter: 'brightness(0)' }}
        src={AliceLogo}
        alt="Alice Project"
      />
    ),
    onClick: () => {
      history.push('/')
    },
    title: 'BI Tool',
  }

  const navigation: NavProps = {
    menu: [
      {
        text: 'Anasayfa',
        href: '/',
      },
      {
        text: 'Dashboard',
        menugroup: [
          {
            text: 'Dashboardları görüntüle',
            href: '/bi-tool/dashboards',
          },
          {
            text: 'Yeni dashboard oluştur',
            href: '/bi-tool/dashboard/create',
          },
        ],
      },
      {
        text: 'Widget',
        menugroup: [
          {
            text: 'Widgetları görüntüle',
            href: '/bi-tool/widgets',
          },
          {
            text: 'Yeni widget oluştur',
            href: '/bi-tool/widget/create',
          },
        ],
      },
      {
        text: 'Kullanıcı yönetimi',
        menugroup: [
          {
            text: 'Kullanıcıları görüntüle',
            href: '/users',
          },
          {
            text: 'Rolleri görüntüle',
            href: '/roles',
          },
          {
            text: 'İzinleri görüntüle',
            href: '/claims',
          },
          {
            text: 'Uygulamaları görüntüle',
            href: '/apps',
          },
        ],
      },
    ],
  }

  const search: SearchProps = {
    placeholder: 'Arama yap...',
    children: <p>Arama sonuçları aşağıda listelenir</p>,
    onChange: (value) => console.log('Search value changed:', value),
    onClose: () => console.log('Search drawer closed'),
    onOpen: () => console.log('Search drawer opened'),
    onSubmit: () => console.log('Search submitted'),
  }

  const notifications: NotificationsProps = {
    contents: [
      { title: 'Direkt', children: <p>Bildirimler</p> },
      { title: 'İzleme', children: <p>Bildirim takibi</p> },
    ],
    onChange: (index) => console.log('Notification tab changed', index + 1),
    onClose: () => console.log('Notification drawer closed'),
    onOpen: () => console.log('Notification drawer opened'),
  }

  const profile: ProfileProps = {
    menugroup: [
      {
        type: 'section',
        menugroup: [
          {
            description: 'Kişisel bilgilerinizi güncelleyin',
            href: '/account/profile',
            text: 'Profil',
          },
          {
            description: 'Şifrenizi güncelleyin',
            href: '/account/password',
            text: 'Şifre güncelle',
          },
        ],
      },
      {
        type: 'group',
        menugroup: [
          {
            description: 'Uygulamadan çıkış yapın.',
            href: '/signout',
            text: 'Çıkış yap',
          },
        ],
      },
    ],
    onClose: () => console.log('Profile popup closed'),
    onOpen: () => console.log('Profile popup opened'),
  }

  return (
    <PageLayout
      product={product}
      nav={navigation}
      search={search}
      notifications={notifications}
      profile={profile}
    >
      <Styled.LayoutSafeArae>
        {isLoading ? (
          <Styled.LayoutSpinner>
            <Spinner />
          </Styled.LayoutSpinner>
        ) : (
          children
        )}
      </Styled.LayoutSafeArae>
    </PageLayout>
  )
}

export default BIToolLayout
