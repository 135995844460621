import { request } from 'services/http/backoffice/instance'

import {
  CreateDefinitionTagRequest,
  DefinitionTag,
  DefinitionTagResponse,
  UpdateDefinitionTagRequest,
} from './types'

import {
  mapDefinitionTagForList,
  mapDefinitionTagForRequest,
  mapGetDefinitionTagForResponse,
} from './mappers'

export const create = (data: CreateDefinitionTagRequest) =>
  request<DefinitionTag>({
    method: 'post',
    url: 'api/DefinitionTag/Create',
    data,
    mappers: {
      req: mapDefinitionTagForRequest,
    },
  })

export const get = (pageSize?: number, pageIndex?: number) =>
  request<DefinitionTagResponse>({
    method: 'get',
    url: 'api/DefinitionTag',
    params: { pageSize: pageSize || 20, pageIndex },
    mappers: {
      res: mapDefinitionTagForList,
    },
  })

export const update = (data: UpdateDefinitionTagRequest) =>
  request<DefinitionTag>({
    method: 'put',
    url: 'api/DefinitionTag/Update',
    data,
    mappers: {
      req: mapDefinitionTagForRequest,
    },
  })

export const getById = (id: string) =>
  request<DefinitionTag>({
    method: 'get',
    url: `api/DefinitionTag/${id}`,
    mappers: {
      res: mapGetDefinitionTagForResponse,
    },
  })

export const remove = (id: string) =>
  request<DefinitionTag>({
    method: 'delete',
    url: `api/DefinitionTag/Delete`,
    params: { id: id },
  })
