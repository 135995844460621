import React, { useState } from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'
import { Column, Row } from '@zera-admin/page'

import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'

import * as Styled from './BarDefinitions.styled'
import { BarDefinitionsProps } from './types'

const BarDefinitions: React.FunctionComponent<BarDefinitionsProps> = ({
  aggregations = [],
  fields = [],
  groups = [],
  options,
  onChange,
}) => {
  const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
    getFieldOptions(fields)
  )

  const handleChangeFieldInput = (value: string) => {
    const option = {
      label: value,
      value,
    }
    const index = fieldOptions.findIndex(
      (reference) => reference.value === parseInt(option.value)
    )
    let optionsInstance = [...fieldOptions]

    if (value && index === -1) {
      optionsInstance[0] = option
    } else {
      optionsInstance = getFieldOptions(fields)
    }

    setFieldOptions(optionsInstance)
  }

  const renderTabs = () => {
    return (
      <Tabs id="tabs">
        <TabTriggerGroup>
          <TabTrigger>Veri</TabTrigger>
        </TabTriggerGroup>
        <TabPanel>{renderDataTab()}</TabPanel>
      </Tabs>
    )
  }

  const renderDataTab = () => {
    return (
      <Row xs={{ direction: 'column' }}>
        <Column>
          <Select
            name="column"
            options={fieldOptions}
            onChange={(props) => {
              onChange({
                ...options,
                label: (props as SelectOptionProps).value as string,
              })
            }}
            onInputChange={handleChangeFieldInput}
            value={
              options.label
                ? {
                    label: string.textify(options.label),
                    value: options.label,
                  }
                : { label: '', value: '' }
            }
            label="Metin"
            placeholder="Seçim yapınız"
            description="Bar üzerinde gösterilecek metni seçin"
            isDisabled={aggregations.length || groups.length ? true : false}
          />
        </Column>
        <Column>
          <Styled.BarDefinitionField>
            <Row>
              <Column>
                <Select
                  name="column"
                  options={fieldOptions}
                  onChange={(props) => {
                    onChange({
                      ...options,
                      value: (props as SelectOptionProps).value as string,
                    })
                  }}
                  onInputChange={handleChangeFieldInput}
                  value={
                    options.value
                      ? {
                          label: string.textify(options.value),
                          value: options.value,
                        }
                      : { label: '', value: '' }
                  }
                  label="Değer"
                  placeholder="Seçim yapınız"
                  description="Bar üzerinde gösterilecek değeri seçin"
                  isDisabled={
                    aggregations.length || groups.length ? true : false
                  }
                />
              </Column>
            </Row>
          </Styled.BarDefinitionField>
        </Column>
      </Row>
    )
  }

  return <Styled.BarDefinitions>{renderTabs()}</Styled.BarDefinitions>
}

export default BarDefinitions
