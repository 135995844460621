import React, { useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import Form, { FormFooter } from '@zera-admin/form'
import Button from '@zera-admin/button'
import Checkbox from '@zera-admin/checkbox'
import { uid } from '@zera-admin/utils'

import { CreateCalculatingRequest } from 'services/http/report/agenda-report/types'
import agendaReportService from 'services/http/report/agenda-report'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'

import * as Styled from './AgendaReportForm.styled'
import { AgendaCalculationFormProps } from './types'

const AgendaCalculationForm: React.FunctionComponent<AgendaCalculationFormProps> =
  ({ groupId, onFetch }) => {
    const [popup] = usePopupContext()
    const [flagcard] = useFlagcardContext()
    const [values, setValues] = useState<CreateCalculatingRequest>({
      groupId,
      requestField: [],
      selectedEvents: [],
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = () => {
      const popupId = uid()

      const onClick = () => {
        if (groupId) {
          setIsLoading(true)
          popup.remove(popupId)

          agendaReportService
            .createCalculating({ ...values, groupId })
            .then(() => {
              flagcard.add({
                title: 'Hesaplama işlemi başlatıldı',
                children: `Gündem raporu üzerinde hesaplamalar yapılmaya başlandı. Diğer adıma yönlendiriliyorsunuz.`,
              })

              onFetch()
            })
            .catch(() => {
              flagcard.add({
                appearance: 'error',
                title: 'Hesaplama başlatılamadı',
                children:
                  'Hesaplama başlatılması sırasında bir hata oluştu. Lütfen tekrar deneyiniz.',
              })
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      }

      const onClose = () => {
        popup.remove(popupId)
      }

      popup.add({
        id: popupId,
        appearance: 'warning',
        title: 'Hesaplama başlatılsın mı?',
        children:
          'İlgili gündem raporu üzerinde, seçtiğiniz değerler ile birlikte hesaplama başlatılsın mı?',
        actions: [
          {
            isLoading: isLoading,
            onClick,
            text: 'Hesaplamayı başlat',
          },
          {
            onClick: onClose,
            text: 'Vazgeç',
          },
        ],
        onClose,
      })
    }

    const handleToggleEvent = (name: string) => {
      const events = [...values.selectedEvents]
      const index = events.findIndex((event) => event === name)

      if (index !== -1) {
        events.splice(index, 1)

        if (name === 'twitter-influenced-people-friends') {
          const child = events.findIndex(
            (event) => event === 'twitter-influenced-people-friends-detail'
          )

          if (child !== -1) {
            events.splice(child, 1)
          }
        }
      } else {
        events.push(name)

        if (name === 'twitter-influenced-people-friends') {
          events.push('twitter-influenced-people-friends-detail')
        }
      }

      setValues({
        ...values,
        selectedEvents: events,
      })
    }

    return (
      <Styled.AgendaReportForm>
        <Form>
          <Row>
            <Column xs={{ size: 12 }}>
              <Checkbox
                name="replies"
                label="En etkili kullanıcıların / tweet'lerin yorumları çekilsin mi?"
                onChange={() => handleToggleEvent('twitter-replies')}
                isChecked={
                  values.selectedEvents.find(
                    (event) => event === 'twitter-replies'
                  )
                    ? true
                    : false
                }
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Checkbox
                name="friends"
                label="En etkili kullanıcıların arkadaş çevresi (takip ettikleri) çekilsin mi?"
                onChange={() =>
                  handleToggleEvent('twitter-influenced-people-friends')
                }
                isChecked={
                  values.selectedEvents.find(
                    (event) => event === 'twitter-influenced-people-friends'
                  )
                    ? true
                    : false
                }
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Checkbox
                name="friends-detail"
                label="En etkili kullanıcıların arkadaş çevrelerinin detayları çekilsin mi?"
                isDisabled
                isChecked={
                  values.selectedEvents.find(
                    (event) =>
                      event === 'twitter-influenced-people-friends-detail'
                  )
                    ? true
                    : false
                }
              />
            </Column>
          </Row>
          <FormFooter>
            <Button
              children="Hesaplamayı başlat ve raporu oluştur"
              appearance="primary"
              onClick={() => handleSubmit()}
              isLoading={isLoading}
            />
          </FormFooter>
        </Form>
      </Styled.AgendaReportForm>
    )
  }

export default AgendaCalculationForm
