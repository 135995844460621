import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, PageHeader, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Checkbox from '@zera-admin/checkbox'
import Input from '@zera-admin/input'
import { useFlagcardContext } from 'app/contexts/flagcard'

import definitionService from 'services/http/backoffice/definition'
import { DefinitionFormProps, SocialMediaEnum } from '../../types'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import { TwitterQueryRequest } from 'services/http/backoffice/definition/types'
import DateTimePicker from '@zera-admin/datetime-picker'
import { DateTimePickerHours } from 'app/shared/enums'

const Twitter: React.FunctionComponent<DefinitionFormProps> = ({
  actions = [],
  onError,
  values,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>()

  const [countData, setCountData] = useState<any>()

  const [dateError, setDateError] = useState<boolean>(false)

  const [dateFilter, setDateFilter] = useState<any>({
    dateSince: '',
    dateUntil: '',
  })

  const [isSubmit, setIsSubmit] = useState<boolean>()

  const [flagcard] = useFlagcardContext()

  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  const changeCrawlingSource = (source: SocialMediaEnum) => {
    form.handleFieldChange('crawlingSource', source)
  }

  const handleTwitter = () => {
    changeCrawlingSource(SocialMediaEnum.Twitter)
    form.handleBatchUpdate({
      definitionValue: '',
      definitionType: 'fetch',
      fetchKeywordSince: undefined,
      fetchKeywordUntil: undefined,
    })
    // resetform()
  }

  const form = useForm<TwitterQueryRequest>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
  })

  const handleSubmit = (props: FormHookSubmitProps<TwitterQueryRequest>) => {
    if (!props.errors) {
      if (form.values) {
        definitionService
          .twitterFetch(form.values)
          .then((res: any) => {
            setCountData(res.data.data)
            setIsSubmit(true)
            flagcard.add({
              appearance: 'success',
              title: 'Sorgulama işlemi başarılı',
              children: 'Sorgu sonucu aşağıdaki gibidir.',
            })
            form.handleReset()
            form.handleFieldChange('definitionValue', '')
            form.handleFieldChange('fetchKeywordSince', '')
            form.handleFieldChange('fetchKeywordUntil', '')
          })
          .catch((error) => {
            console.log(error, 'error')
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  const renderPageHeader = () => {
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Tanımlama" href="/definition" />
        <BreadcrumbItem
          text="Tanımlama Veri Sayısı Sorgulama"
          href="/definition/query"
        />
        <BreadcrumbItem text="X" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>X ile alakalı veri sayılarını buradan sorgulayabilirsiniz.</p>
    )

    return (
      <PageHeader
        // actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        X
      </PageHeader>
    )
  }

  function isOneMonthBefore(selectedDate: any) {
    var currentDate = new Date()
    var oneMonthBefore = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate()
    ).toISOString()

    return selectedDate <= oneMonthBefore
  }

  useEffect(() => {
    handleTwitter()
  }, [])

  const renderContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormSection
            title="Genel bilgiler"
            description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
          >
            <Row>
              <>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="keyword"
                    placeholder="Keyword adı giriniz"
                    description="Veri toplamak için keyword giriniz (hashtag için # sembolü kullanınız)"
                    onChange={(props) =>
                      form.handleFieldChange(
                        'definitionValue',
                        props.currentTarget.value
                      )
                    }
                  />
                </Column>
              </>
            </Row>
            <Row>
              <Column xs={{ size: 4 }}>
                <DateTimePicker
                  description="İşlenecek veri aralığının bitiş tarihini seçiniz"
                  label="Secili tarihten"
                  locale="tr"
                  name="fetchKeywordSince"
                  onChange={(props) => {
                    if (isOneMonthBefore(props)) {
                      setDateError(true)
                    } else {
                      setDateError(false)
                    }
                    form.handleFieldChange('fetchKeywordSince', props)
                    setDateFilter({ ...dateFilter, dateSince: props })
                  }}
                  type="default"
                  value={
                    dateFilter.dateSince === ''
                      ? new Date().toISOString()
                      : dateFilter.dateSince
                  }
                  times={hoursArray}
                />
              </Column>
              <Column xs={{ size: 4 }}>
                <DateTimePicker
                  description="İşlenecek veri aralığının bitiş tarihini seçiniz"
                  label="Secili tarihe"
                  locale="tr"
                  name="fetchKeywordUntil"
                  onChange={(props) => {
                    setDateError(false)
                    form.handleFieldChange('fetchKeywordUntil', props)
                    setDateFilter({ ...dateFilter, dateUntil: props })
                  }}
                  type="default"
                  value={
                    dateFilter.dateUntil === ''
                      ? new Date().toISOString()
                      : dateFilter.dateUntil
                  }
                  times={hoursArray}
                />
              </Column>
            </Row>
            {dateError === true && (
              <Row style={{ paddingBottom: '2vh', opacity: 0.7 }}>
                <Column>
                  <span style={{ color: 'red' }}>
                    1 Ay oncesine bir tarih sectiginiz icin diger tarih alaninda
                    tarih secip aralik belirlemeniz gerekmektedir.
                  </span>
                </Column>
              </Row>
            )}

            {countData && countData?.resultByDates && (
              <div style={{ marginTop: '2vh' }}>
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <Column xs={{ size: 4 }}>
                    <span style={{ fontSize: '1rem', fontWeight: 600 }}>
                      Zaman Aralığı ve Değerleri:
                    </span>
                  </Column>
                  <Column xs={{ size: 12 }}>
                    <span style={{ fontSize: '0.9rem', fontWeight: 600 }}>
                      Toplam Sayı:
                    </span>
                    <span>&nbsp;{countData.totalCount}</span>
                  </Column>
                  {countData.resultByDates
                    ?.filter((item: any) => item.count > 0)
                    .map((item: any) => (
                      <div
                        style={{
                          borderBottom: '1px solid black',
                          width: '30%',
                          marginTop: '2vh',
                        }}
                      >
                        <Row>
                          <Column xs={{ size: 6 }}>
                            <span
                              style={{ fontSize: '0.85rem', fontWeight: 600 }}
                            >
                              Değer:
                            </span>
                            &nbsp;{item.count}
                          </Column>
                        </Row>
                        <Row>
                          <Column xs={{ size: 9 }}>
                            <span
                              style={{ fontSize: '0.85rem', fontWeight: 600 }}
                            >
                              Zaman Aralığı:
                            </span>
                            &nbsp;{item.timePeriod}
                          </Column>
                        </Row>
                      </div>
                    ))}
                </Row>
              </div>
            )}
          </FormSection>
          <FormFooter>
            <Button
              appearance="primary"
              children="Sorgula"
              isLoading={isLoading}
              type="submit"
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderContent()}
      </Page>
    </MainLayout>
  )
}

export default Twitter
