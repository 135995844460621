import React, { useCallback, useEffect, useState } from 'react'
import Page, { Container, PageHeader, Row } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import MainLayout from 'layouts/main'
import mobileReportService from 'services/http/report/agenda-report'
import { Data, HashtagDetail, MobileReportProps } from './types'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import * as Styled from './Stres.styled'
import { PdfContainer } from './components/twitter-card'
import Overlay from './components/overlay/Overlay'
import TwitterTweetCard from './components/tweet'
import Header from './components/header'
import Users from './components/users'
import StresGraph from './components/graph'
import Hashtags from './components/hashtags'
import SocialCard from './socialcard/SocialCard'
import Table from './components/table'
import NewsCard from './components/news'
import TwitterLogo from '../../../assets/images/twitter-new-logo-light.png'
const MobileReport: React.FunctionComponent<MobileReportProps> = ({
  match,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false)
  const [pdfInputValue, setPdfInputValue] = useState('')
  const [stressReport, setStressReport] = useState<Data>()
  const [showFullTextList, setShowFullTextList] = useState<boolean[]>([])

  const updateShowFullTextList = (index: number, showFullText: boolean) => {
    const updatedList = [...showFullTextList]
    updatedList[index] = showFullText
    setShowFullTextList(updatedList)
  }

  const colors = [
    '#E69E15',
    '#D13FD6',
    '#20D496',
    '#269FFB',
    '#E69E15',
    '#D13FD6',
    '#20D496',
    '#269FFB',
  ]

  const Dteparse = (date: string) => {
    const dates = new Date(date)
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',

      hour: '2-digit',
      minute: '2-digit',
    }
    return dates.toLocaleDateString('tr-TR', options)
  }

  let liste: any = []
  let categories: any = []
  let timesize = 0
  let timeserie: HashtagDetail = {
    hashtag: '',
    count: 0,
    timeSeries: [],
    tweets: [],
    news: [],
    influentialPeople: [],
    postCountByUserBotDetection: [],
  }

  stressReport?.hashtagDetails.forEach(function (time: HashtagDetail) {
    if (time.timeSeries.length > timesize) {
      timesize = time.timeSeries.length
      timeserie = time
    }
    timeserie?.timeSeries?.map((itm) =>
      categories?.push(`${Dteparse(itm.asDateTime)}`)
    )
  })

  stressReport?.hashtagDetails.map((item) =>
    liste.push({
      name: item.hashtag,
      data: item.timeSeries.map((item) => item.count),
    })
  )
  const DailyReport = useCallback(() => {
    mobileReportService
      .StressReport(1)
      .then((res) => {
        // setMobileReport(res?.data?.data)
        setStressReport(res.data.data)
        setIsLoading(false)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    DailyReport()
  }, [DailyReport])

  const handleDownloadPdf = () => {
    setIsPdfLoading(true)
    let input = document.getElementById('printThis')!

    html2canvas(input, {
      scale: 1,
      useCORS: true,
      ignoreElements: (element: any) => {
        if (element.id === 'card-box') {
          element.style.boxShadow = 'none'
          element.style.border = '1px solid lightgray'
        } else if (element.id === 'profileShadow') {
          element.style.boxShadow = 'none'
          element.style.padding = '0px'
          element.style.border = '1px solid gray'
          element.style.height = '70px'
        }
        return false
      },
    })
      .then((canvas) => {
        var imgData = canvas.toDataURL('image/jpeg')

        var pdf = new jsPDF('p', 'in', [6, 100], true)

        // if (mobileReport?.negativePersonalitiesAndInteractions.length) {
        //   pdf = new jsPDF('p', 'in', [6, 100], true)
        // }
        var imgProps = pdf.getImageProperties(imgData)
        var pdfWidth = pdf.internal.pageSize.getWidth()
        var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

        const date = new Date()
          .toLocaleDateString('tr-TR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
          .replaceAll(':', '_')

        pdf.save(`StresRaporu-${date}.pdf`.replaceAll(' ', '_'))

        const fileName = `StresRaporu-${date}`.replaceAll(' ', '_')
        setPdfInputValue(fileName)
      })
      .catch((e) => console.log('error', e))
      .finally(() => {
        setIsPdfLoading(false)
      })
  }

  return isLoading || isPdfLoading ? (
    <LoadingView>
      <Spinner size="large" />
      <h5>{isPdfLoading ? 'PDF Hazırlanıyor...' : 'Rapor yükleniyor...'}</h5>
    </LoadingView>
  ) : (
    <MainLayout isLoading={isLoading}>
      <Container width="wide">
        <Page style={{ width: '100%' }}>
          <PageHeader
            actions={
              <>
                <input id="pdf-date" type="hidden" value={pdfInputValue} />
                <Button
                  id="pdf-button"
                  iconBefore={<Icon name="download" size="small" />}
                  onClick={() => handleDownloadPdf()}
                >
                  PDF olarak indir
                </Button>
              </>
            }
          >
            STRES Raporu
          </PageHeader>

          <Row xs={{ direction: 'column', align: 'center' }}>
            <PdfContainer
              style={{ width: '100%', boxShadow: 'none' }}
              id="printThis"
              className="fa-Page"
            >
              <div
                style={{ width: '95%', borderWidth: 0.6, borderColor: 'gray' }}
              >
                <Header
                  date={stressReport?.date}
                  number={stressReport?.stressReportNumber}
                />
                <Styled.Container>
                  <div className="header-container">
                    <div className="chart-container">
                      {(liste.length && categories.length && (
                        <StresGraph series={liste} categories={categories} />
                      )) || <h1>No Data</h1>}
                    </div>
                    <div className="socialcards-container">
                      {stressReport?.postCountByType?.map((itm, index) => (
                        <SocialCard
                          styles={{
                            borderTopLeftRadius: index === 0 ? 10 : 0,
                            borderTopRightRadius: index === 2 ? 10 : 0,
                          }}
                          tweetTitle={itm.type}
                          tweetCount={itm.count.toLocaleString()}
                          img={TwitterLogo}
                          color={'#50aaf5'}
                        />
                      ))}
                      <SocialCard
                        styles={{ borderBottomLeftRadius: 10 }}
                        tweetTitle={'Tahmini Etki'}
                        tweetCount={stressReport?.totalImpressionCount.toLocaleString()}
                        img={TwitterLogo}
                        color={'#50aaf5'}
                      />
                      <SocialCard
                        tweetTitle={'Tekil Kullanıcı'}
                        tweetCount={stressReport?.uniqueUserCount.toLocaleString()}
                        img={TwitterLogo}
                        color={'#50aaf5'}
                      />
                      <SocialCard
                        styles={{ borderBottomRightRadius: 10 }}
                        tweetTitle={
                          'Post Atan Hesapların Toplam Takipçi Sayısı'
                        }
                        tweetCount={stressReport?.totalFollowersCount.toLocaleString()}
                        img={TwitterLogo}
                        color={'#50aaf5'}
                      />
                    </div>
                  </div>

                  <Styled.HashtagsCardContainer>
                    <Styled.HashtagsContainer>
                      <Hashtags data={stressReport?.hashtagDetails} />
                    </Styled.HashtagsContainer>
                    <div style={{ alignItems: 'center' }}>
                      <div style={{ display: 'flex', gap: '0.3vw' }}>
                        <SocialCard
                          styles={{ borderBottomLeftRadius: 10 }}
                          tweetTitle={'Haberler'}
                          tweetCount={stressReport?.newsCount.toLocaleString()}
                          icon={'fa-solid fa-newspaper'}
                          color={'#FF9914'}
                        />
                        <SocialCard
                          styles={{ borderBottomRightRadius: 10 }}
                          tweetTitle={'Reddit'}
                          tweetCount={stressReport?.redditCount.toLocaleString()}
                          icon={'fa-brands fa-reddit'}
                          color={'#FF4500'}
                        />
                      </div>
                    </div>
                  </Styled.HashtagsCardContainer>
                </Styled.Container>

                {stressReport?.hashtagDetails?.map(
                  (item: HashtagDetail, index) => (
                    <Styled.LineComp
                      style={{ borderColor: colors[index], marginTop: '6%' }}
                    >
                      <Styled.TitleWrapper
                        style={{ backgroundColor: colors[index] }}
                      >
                        <Styled.Title>&#9679; {item?.hashtag}</Styled.Title>
                        <Styled.Line
                          style={{ backgroundColor: colors[index] }}
                        />
                      </Styled.TitleWrapper>
                      <Styled.Row id="tweet">
                        <Styled.Line
                          style={{ backgroundColor: colors[index] }}
                        />
                        <Overlay
                          label={'X'}
                          topic={item.hashtag}
                          color={'#50aaf5'}
                          img={TwitterLogo}
                          titleColor={colors[index]}
                        >
                          {item?.tweets?.map((tweet: any) => (
                            <TwitterTweetCard tweet={tweet} />
                          ))}
                        </Overlay>
                      </Styled.Row>
                      {item?.news.length > 0 && (
                        <Styled.Row id="news">
                          <Styled.Line
                            style={{ backgroundColor: colors[index] }}
                          />
                          <Overlay
                            label={'Haberler'}
                            topic={item.hashtag}
                            color={'#FF9914'}
                            icon={'fa-solid fa-newspaper'}
                            titleColor={colors[index]}
                          >
                            {item?.news.map((news, index) => (
                              <NewsCard
                                key={index}
                                news={news}
                                index={index}
                                updateShowFullTextList={updateShowFullTextList}
                                showFullTextList={showFullTextList}
                              />
                            ))}
                          </Overlay>
                        </Styled.Row>
                      )}

                      <Styled.StyledDiv id="user">
                        <div
                          style={{
                            position: 'absolute',
                            left: '0px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            height: '2px',
                            width: '20px',
                            backgroundColor: colors[index],
                          }}
                        ></div>
                        <Overlay
                          label={'Kişiler'}
                          topic={item.hashtag}
                          color={'#3658A5'}
                          titleColor={colors[index]}
                        >
                          {item?.influentialPeople?.map((itm) => (
                            <Users people={itm} />
                          ))}
                        </Overlay>
                      </Styled.StyledDiv>
                    </Styled.LineComp>
                  )
                )}
                <Styled.Row id="tweet">
                  <Overlay
                    color={'#815ABC'}
                    background={'#815ABC'}
                    label={'Ortak Kullanıcılar'}
                  >
                    <Table table={stressReport?.intersectingPeopleCount} />

                    {stressReport?.intersectingPeople
                      ?.slice(0, 6)
                      .map((itm) => (
                        <Users people={itm} />
                      ))}
                  </Overlay>
                </Styled.Row>
              </div>
            </PdfContainer>
          </Row>
        </Page>
      </Container>
    </MainLayout>
  )
}
export default MobileReport
