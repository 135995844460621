import { request } from '../instance'

import {
  mapAppForResponse,
  mapAppForCreateRequest,
  mapAppForUpdateRequest,
  mapAppListForResponse,
  mapAppListForRequest,
} from './mappers'
import {
  AppListRequest,
  AppUpdateRequest,
  AppCreateRequest,
  AppListResponse,
  AppResponse,
} from './types'

export const create = (data: AppCreateRequest) =>
  request<AppResponse>({
    method: 'post',
    url: 'app',
    data,
    mappers: {
      req: mapAppForCreateRequest,
      res: mapAppForResponse,
    },
  })

export const get = (params?: AppListRequest) =>
  request<AppListResponse>({
    method: 'get',
    params,
    url: 'app',
    mappers: {
      req: mapAppListForRequest,
      res: mapAppListForResponse,
    },
  })

export const getById = (id: number) =>
  request<AppResponse>({
    method: 'get',
    url: `app/${id}`,
    mappers: {
      res: mapAppForResponse,
    },
  })

export const update = (data: AppUpdateRequest) =>
  request<AppResponse>({
    method: 'put',
    url: 'app',
    data,
    mappers: {
      req: mapAppForUpdateRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `app/${id}`,
  })
