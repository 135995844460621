import {
  mapAuditForResponse,
  mapMetaForResponse,
} from 'services/http/identity-server/mappers'

import {
  AppCreateRequest,
  AppUpdateRequest,
  AppListRequest,
  AppListResponse,
  AppResponse,
} from './types'

export const mapAppForResponse = (raw: any): AppResponse => ({
  id: raw.data.id,
  name: raw.data.name,
  audit: mapAuditForResponse(raw.data),
})

export const mapAppForCreateRequest = (raw: AppCreateRequest): any => ({
  name: raw.name,
})

export const mapAppForUpdateRequest = (raw: AppUpdateRequest): any => ({
  id: raw.id,
  name: raw.name,
})

export const mapAppListForRequest = (raw: AppListRequest) => ({
  PageIndex: raw.pageIndex,
  PageSize: raw.pageSize,
})

export const mapAppListForResponse = (raw: any): AppListResponse => ({
  data: raw.data.map((app: any) => mapAppForResponse({ data: app })),
  meta: mapMetaForResponse(raw),
})
