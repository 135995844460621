import { useState, useEffect } from 'react'
import { SearchboxOptionProps } from '@zera-admin/searchbox'

import sourceService from 'services/http/dataset/source'

const useSources = () => {
  const [sources, setSources] = useState<SearchboxOptionProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getSources()
  }, [])

  const getSource = (id?: number, name?: string) => {
    if (id !== null && id !== undefined) {
      return {
        label:
          (sources.find((source) => source.value === id)?.label as string) ||
          name ||
          '',
        value: id,
      }
    }
    return null
  }

  const getSources = async (value?: string) => {
    setIsLoading(true)

    const response = await sourceService.get(value)
    const mapped = response.data?.list.map((source) => ({
      label: source.name,
      value: source.id as number,
    }))

    setSources(mapped)
    setIsLoading(false)

    return mapped
  }

  return { sources, getSources, getSource, isLoading }
}

export default useSources
