import styled from 'styled-components'

export const Designer = styled.div`
  .designer {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .designer .sortable-ghost:after {
    opacity: 1;
  }

  .designer .sortable-ghost * {
    opacity: 0 !important;
    pointer-events: none !important;
  }

  .designer .designer-content {
    border: none !important;
  }

  .designer .designer-content > .droppable-area {
    overflow: hidden;
  }

  .designer > .droppable-area {
    background: #fbfbfb !important;
    position: relative;
  }

  .designer .fa-Grid {
    background: transparent;
    margin: 0px !important;
    border: none;

    &.designing {
      background: #f7f7f7;
      border: 1px solid #ececec;
    }
  }

  .designer .fa-Grid.is-default > .droppable-area {
    min-height: 300px;
  }

  .designer .fa-Grid.is-default > .component-header {
    left: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 6.5px 15px;
    background: #ececec;
  }

  .designer .fa-Container {
    background: #fff;
    margin: 0px !important;
  }

  .designer .fa-Layout-Container {
    margin: 0px !important;
    background: #fff;
  }

  .designer .fa-Layout-Container > .blank-wrapper {
    position: absolute;
    background: transparent !important;
    min-height: 300px !important;
  }

  .designer .fa-Layout-Container > .blank-wrapper .blank-box {
    position: absolute !important;
  }

  .designer .fa-Slideshow > .droppable-area {
    display: flex;
  }

  .designer .fa-Slideshow > .droppable-area > * {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    z-index: 1;
  }

  .designer .fa-Section,
  .designer .fa-Animation,
  .designer .fa-Container,
  .designer .fa-Grid {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }

  .designer .fa-Section:hover > .fa-droppable-act,
  .designer .fa-Animation:hover > .fa-droppable-act,
  .designer .fa-Container:hover > .fa-droppable-act,
  .designer .fa-Grid:hover > .fa-droppable-act {
    border: 1px dotted #88aeda !important;
  }

  .designer .fa-Section > .blank-wrapper,
  .designer .fa-Slideshow > .blank-wrapper,
  .designer .fa-Scene > .blank-wrapper {
    position: absolute !important;
    background: transparent !important;
    min-height: 300px !important;
  }

  .designer .fa-Section > .blank-wrapper .blank-box,
  .designer .fa-Slideshow > .blank-wrapper .blank-box,
  .designer .fa-Scene > .blank-wrapper .blank-box {
    position: absolute !important;
  }

  .designer .fa-Section > .droppable-area,
  .designer .fa-Slideshow > .droppable-area,
  .designer .fa-Scene > .droppable-area {
    min-height: 300px !important;
  }

  .designer .fa-Col {
    margin: 5px;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    border: none;
    background: #fff;

    &.designing {
      margin: 0px;
      background: rgba(0, 120, 212, 0.05);
    }
  }

  .designer .fa-Col.is-default {
    background: #f7f7f7;
  }

  .designer .fa-Col.is-default > .blank-wrapper {
    position: absolute;
    background: transparent !important;
    min-height: 300px;
  }

  .designer .fa-Col.is-default > .blank-wrapper .blank-box {
    position: absolute;
  }

  .designer .fa-Col.is-default > .droppable-area {
    min-height: 300px !important;
  }

  .designer .fa-Col.is-default > .component-header {
    width: auto;
    left: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 6.5px 15px;
    background: #ececec;
    opacity: 1 !important;
  }

  .designer .fa-Col.is-default:hover {
    background: #f7f7f7;
  }

  .designer .fa-Col.is-default:hover > .fa-droppable-act {
    border: 1px dotted rgba(187, 187, 187, 0.7) !important;
  }

  .designer .fa-Col.designing:hover {
    background: rgba(0, 120, 212, 0.05);
  }

  .designer .fa-Col:hover > .fa-droppable-act {
    border: 1px dotted #88aeda !important;
  }

  .designer .fa-Page {
    width: 100%;
    background: #ffbcbc;
    padding: 1rem;
    position: relative;

    &.designing {
      padding: 0rem;

      & > .droppable-area {
        background: #f3f8f5;
      }
    }

    &-lozenge {
      margin: 5px 0px;
      width: 100%;
    }
  }

  .designer .fa-Page:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 135vh;
    background: #e3fcef;
    z-index: 0;
  }

  .designer .fa-Page.designing:before {
    height: 100%;
  }

  .designer > div[role='tabpanel'] {
    background: #f9f9f9;
    border: 1px solid #e0e0e0;
    margin-top: -1px;
  }

  .designer .designer-header {
      z-index: 10;
      margin: 24px 8px;
      position: -webkit-sticky;
      position: sticky;
      top: 56px;
      background: #fbfbfb;
      padding: 8px;
      margin: 10px 0;
    }
  }

  .designer .designer-header .designer-mode-indicator {
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    background: #f9f9f9;
    padding: 10px;
    font-size: 10.5px;
    font-weight: 500;
    cursor: pointer;
  }

  .designer .designer-header .designer-mode-indicator i {
    padding-right: 5px;
  }

  .designer .designer-header .ms-Button {
    margin-right: 0px;
    font-size: 12.3px;
    font-weight: 600;
    border: 1px solid transparent;
    border-bottom: none;
    color: #0078d4;
    height: 37px;
  }

  .designer .designer-header .ms-Button.is-selected {
    border: 1px solid #e0e0e0;
    border-bottom: none;
    color: #000;
    background: #f9f9f9;
  }

  .designer .designer-header .ms-Button::before {
    display: none;
  }

  .designer .designer-components {
    margin: 0px;
  }

  .designer .designer-components .item {
    cursor: pointer;
    text-align: center;
    cursor: move;
    margin-right: 10px;
    min-width: 100px;
    text-align: center;

    a {
      pointer-events: none;
      background: #fff;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: .75rem;
    }
  }

  .designer .designer-components .item:last-child {
    border-right: none;
  }

  .designer .designer-components .item::selection {
    background: transparent;
  }

  .designer .designer-components .item .icon {
    text-align: center;
  }

  .designer .designer-components .item .icon::selection {
    background: transparent;
  }

  .designer .designer-components .item .icon img {
    width: 42.5px;
    filter: brightness(0.6);
  }

  .designer .designer-components .item .icon img::selection {
    background: transparent;
  }

  .designer .designer-components .item .text {
    font-size: 10px;
    font-weight: 600;
    margin-top: 5px;
    min-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #505050;
    font-weight: 700;
  }

  .designer .designer-components .item .text::selection {
    background: transparent;
  }

  .droppable-area {
    padding: 5px !important;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    border: 1px dotted rgba(187, 187, 187, 0.7);
    display: block;
    margin: 0px;
    box-sizing: border-box;

    .row.component-header {
      margin: 0px;
    }
  }

  .droppable-area:first-child {
    margin-top: 0px;
  }

  .droppable-area .fa-sortable-item {
    width: 100% !important;
    height: 75px;
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
    border: 1px dashed #d1d1d1;
    background: #f5fcf8;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }

  .droppable-area .fa-sortable-item.size-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-6 {
    -ms-flex: 0 0 49%;
    flex: 0 0 49%;
    max-width: 49%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
    height: 120px;
  }

  .droppable-area .fa-sortable-item.size-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    height: 120px;
  }

  .fake-draggable {
    position: relative;
    border-top: none;
    padding: 35px 0px 0px 0px !important;
    display: block;
    margin: 5px !important;
    background: #fff;
  }

  .fa-draggable {
    position: relative;
    border: 1px solid #ececec;
    border-top: none;
  }

  .fa-draggable.designing:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed #d1d1d1;
    background: #f5fcf8;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    opacity: 0;
  }

  .fa-draggable.designing {
    padding: 35px 0px 0px 0px !important;
    display: block;
    margin: 5px !important;

    &.is-default {
      padding: 0px !important;
    }
  }

  .fa-draggable.designing:hover > .component-header {
    opacity: 1;
    pointer-events: all;
  }

  .fa-draggable.designing .blank-wrapper {
    background: #ececec;
    position: relative;
    z-index: 1;
    background: transparent;
    min-height: 100px;
  }

  .fa-draggable.designing .blank-wrapper .blank-box {
    position: relative;
    padding: 12px 10px;
  }

  .fa-draggable.designing .blank-wrapper .blank-box > i {
    font-size: 25px;
  }

  .blank-wrapper {
    min-height: 288px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    cursor: move;
  }

  .blank-wrapper::selection {
    background: transparent;
  }

  .blank-wrapper::selection * {
    background: transparent;
  }

  .blank-box {
    position: absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    width: 100%;
    opacity: 0.4;
    left: 0;
    pointer-events: none;
    top: 0;
    z-index: -1;
    text-align: center;
  }

  .blank-box > i {
    font-size: 30px;
    color: #a3a3a3;
    font-weight: 300;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.55);
    margin-bottom: 10px;
  }

  .blank-box > i::selection {
    background: transparent;
  }

  .blank-box > img {
    width: 30px;
    margin-bottom: 10px;
  }

  .blank-box .title {
    font-size: 14px;
    font-weight: 600;
    color: #444444;
  }

  .blank-box .title::selection {
    background: transparent;
  }

  .blank-box .sub-text {
    font-size: 10px;
    font-weight: 400;
    color: #656565;
    margin-top: 1.5px;
  }

  .blank-box .sub-text::selection {
    background: transparent;
  }

  .fa-sortable-item.sortable-ghost {
    width: 25px;
  }

  .component-header {
    padding: 5px;
    background: #f5f5f5;
    border: 1px solid #d7d5d5;
    margin-bottom: -1px;
    top: 0;
    z-index: 9;
    width: 100%;
    position: absolute;
    align-items: center;
    cursor: move;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    box-sizing: border-box;
  }

  .component-header span.schema-copy {
    background: #569c8c;
    color: #fff;
    font-size: 9px;
    padding: 4px 7px;
  }

  .component-header span.schema-paste {
    font-size: 9px;
    padding: 4px 0px;
    border-right: 1px solid #d4d4d4;
    padding-right: 10px;
  }

  .component-header .title {
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    text-align: right;
  }

  .dnd-widget {
    position: relative;
  }

  .dnd-widget-content {
    padding: 10px;
  }

  .fa-Col-1 {
    width: calc(8.33% - 10px);
  }

  .fa-Col-2 {
    width: calc(16.66% - 10px);
  }

  .fa-Col-3 {
    width: calc(25% - 10px);
  }

  .fa-Col-4 {
    width: calc(33.33% - 10px);
  }

  .fa-Col-5 {
    width: calc(41.66% - 10px);
  }

  .fa-Col-6 {
    width: calc(50% - 10px);
  }

  .fa-Col-7 {
    width: calc(58.33% - 10px);
  }

  .fa-Col-8 {
    width: calc(66.66% - 10px);
  }

  .fa-Col-9 {
    width: calc(75% - 10px);
  }

  .fa-Col-10 {
    width: calc(83.33% - 10px);
  }

  .fa-Col-11 {
    width: calc(91.66% - 10px);
  }

  .fa-Col-12 {
    width: calc(100% - 10px);
  }
`
