import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'
import EmptyState from '@zera-admin/empty-state'

import { MostImpressedByProps } from './types'

const MostImpressedBy: React.FunctionComponent<MostImpressedByProps> = ({
  data,
}) => {
  const mostImpressedBy: Props = {
    options: {
      chart: {
        id: 'mostImpressedBy',
      },
      colors: [colors.B400],
      xaxis: {
        categories: data?.map(
          (item: any) => '@' + item?.RetweetedUserScreenName
        ),
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true,
        },
      },
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: 'sayısı',
        data: data?.map((item: any) => item?.TotalRetweetCount),
      },
    ],
  }

  return data && data.length > 0 ? (
    <Chart
      options={mostImpressedBy.options}
      series={mostImpressedBy.series}
      type="bar"
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default MostImpressedBy
