import React from 'react'
import { Column, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import Textarea from '@zera-admin/textarea'

import * as Styled from '../FilterSettings.styled'

import { FilterTabProps } from '../types'

const InQueriesTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
}) => {
  const inqueries = [...(values.raw?.inqueries || [])]

  const renderQueries = () => {
    return inqueries.map((value, index) => (
      <Styled.ListField key={index}>
        <Row xs={{ direction: 'row' }}>
          <Column>
            <Input
              name="name"
              label="Parametreler"
              placeholder="inquery-1"
              value={value.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                inqueries[index].name = event.target.value
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')

                onChange({
                  ...values,
                  raw: { ...values.raw, inqueries },
                })
              }}
            />
          </Column>
          <Column>
            <Textarea
              name="value"
              label="JSON Nesnesi"
              placeholder="{ source: ['etkin-kisiler'], country: 'TR' }"
              value={JSON.stringify(value.params, null, 4)}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                try {
                  inqueries[index].params = JSON.parse(
                    event.currentTarget.value
                  )
                  onChange({ ...values, raw: { ...values.raw, inqueries } })
                } catch (err) {
                  console.log(err)
                }
              }}
            />
          </Column>
          <Button
            appearance="error"
            size="medium"
            iconBefore={<Icon name="cross" size="small" />}
            onClick={() => {
              inqueries.splice(index, 1)
              onChange({ ...values, raw: { ...values.raw, inqueries } })
            }}
          />
        </Row>
      </Styled.ListField>
    ))
  }

  return (
    <React.Fragment>
      <Row xs={{ direction: 'row' }}>
        <Column xs={{ size: 12 }}>
          <p>Aşağıdan özel bir in sorgusu ekleyebilirsiniz.</p>
        </Column>
      </Row>
      {renderQueries()}
      <Button
        iconBefore={<Icon name="add" size="small" />}
        onClick={() => {
          inqueries.push({
            name: '',
            params: {},
          })

          onChange({ ...values, raw: { ...values.raw, inqueries } })
        }}
        style={{ marginTop: 12 }}
      >
        In Sorgusu ekle
      </Button>
    </React.Fragment>
  )
}

export default InQueriesTab
