import { CheckboxProps } from '@zera-admin/checkbox'
import { SelectOptionProps } from '@zera-admin/select'
import {
  AgendaReportStatus,
  AgendaReportType,
} from 'services/http/report/agenda-report/enums'
import { SwitchProps } from './types'

export const AGENDA_REPORT_STATUSES = [
  {
    label: 'Hazır',
    value: AgendaReportStatus.Create,
  },
  {
    label: 'Hazırlanıyor',
    value: AgendaReportStatus.Ready,
  },
  {
    label: 'Kuyrukta Bekliyor',
    value: AgendaReportStatus.Pending,
  },
  {
    label: 'Veriler Toplanıyor',
    value: AgendaReportStatus.Processing,
  },
  {
    label: 'Hesaplama Bekleniyor',
    value: AgendaReportStatus.Stopped,
  },
  {
    label: 'Hesaplama Yapılıyor',
    value: AgendaReportStatus.Calculating,
  },
  {
    label: 'Tamamlandı',
    value: AgendaReportStatus.Completed,
  },
]

export const AGENDA_REPORT_INDEPENDENT_STATUSES = [
  {
    label: 'Hazır',
    value: AgendaReportStatus.Create,
  },
  {
    label: 'Hazırlanıyor',
    value: AgendaReportStatus.Ready,
  },
  {
    label: 'Kuyrukta Bekliyor',
    value: AgendaReportStatus.Pending,
  },
  {
    label: 'Veriler İşleniyor',
    value: AgendaReportStatus.Processing,
  },
  {
    label: 'Tamamlandı',
    value: AgendaReportStatus.Completed,
  },
]

export const switches: SwitchProps[] = [
  {
    id: 'fetch-profile-details',
    disabled: true,
    label: 'Bu kullanıcının profil bilgileri çekilsin',
    name: 'fetch-profile-details',
  },
  {
    id: 'fetch-user-tweets',
    disabled: true,
    label: 'Bu kullanıcının tweetleri çekilsin',
    name: 'fetch-tweet',
  },
  {
    id: 'create-word-count',
    label: 'Tweetleri word count oluşturulsun',
    name: 'create-word-count',
  },
  {
    id: 'fetch-user-followers',
    label:
      'Bu kullanıcının follower id listesi çekilerek bu idlerin user lookup servisinden profil bilgileri çekilsin',
    name: 'follower-id',
  },
  {
    id: 'use-ai',
    label: 'Bu kullanıcının tüm tweetleri yapay zeka süreçlerinden geçsin',
    name: 'all-ai',
  },
  {
    id: 'use-big5',
    label: 'Bu kullanıcının big5 analizi yapılsın',
    name: 'big-five',
  },
  {
    id: 'generate-statistics',
    label: 'Kullanıcı ile alakalı istatistik raporları çıkartılsın',
    name: 'statistic-report',
  },
]

export const checks: CheckboxProps[] = [
  {
    isChecked: true,
    label: 'En çok takipçisi olan takipçileri',
    name: 'mostFollowedFollowers',
  },
  {
    isChecked: true,
    label: 'En çok tweet atan takipçileri',
    name: 'mostTweetedFollowers',
  },
  {
    isChecked: true,
    label: 'Attığı tweetlerin zamana göre dağılımı (2021 sonrası)',
    name: 'tweetsByTimeAfter2021',
  },
  {
    isChecked: true,
    label: 'Attığı tweetlerin zamana göre dağılımı (2021 öncesi)',
    name: 'tweetsByTimeBefore2021',
  },
  {
    isChecked: true,
    label: 'Konuştuğu konuların aylara göre dağılımları',
    name: 'categoriesByTime',
  },
  {
    isChecked: true,
    label: 'Takipçilerinin kadın erkek dağılımı',
    name: 'followersByGender',
  },
  {
    isChecked: true,
    label: 'Tweetlerindeki ABS sonuçlarının raporu',
    name: 'aspectAnalysis',
  },
  {
    isChecked: true,
    label: 'Konuştuğu konuların sayıları',
    name: 'mostCommonEntities',
  },
  {
    isChecked: true,
    label: 'Takipçi listesinin ülkelere göre dağılımı',
    name: 'followersByCountry',
  },
  {
    isChecked: true,
    label: 'En çok retweet alan 20 tweeti',
    name: 'mostRetweeted20Tweets',
  },
  {
    isChecked: true,
    label: 'En çok kullandığı hastagler',
    name: 'mostUsedHashtags',
  },
  {
    isChecked: true,
    label: 'En çok mentionlamalar',
    name: 'mostMentions',
  },
]

export const status: SelectOptionProps[] = [
  {
    label: 'Tümü',
    value: '',
  },
  {
    label: 'Devam ediyor',
    value: 'Processing',
  },
  {
    label: 'Tamamlandı',
    value: 'Completed',
  },
]

export const reportTypes: SelectOptionProps[] = [
  {
    label: 'Tümü',
    value: AgendaReportType.All,
  },
  {
    label: 'Standart Rapor',
    value: AgendaReportType.Dependent,
  },
  {
    label: 'Hızlı Rapor',
    value: AgendaReportType.Independent,
  },
]
