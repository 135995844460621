import styled from 'styled-components'

export const Map = styled.div<{ height?: number }>`
  position: relative;
  z-index: 1;
  height: ${(props) => `${props.height}px`};
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 16px;
`
