import styled from 'styled-components'

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  &.print {
    align-items: center;
    align-self: center;
    align-content: center;
  }
  .edit-btn-container {
    display: flex;
    justify-content: end;
    align-items: center;
    .edit-btn {
      display: flex;
    }
  }
`

export const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  display: flex;
  justify-content: center;

  table {
    border-radius: 8px;
    border: 1px solid #dadada;
    width: 90%;
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 1px solid transparent;
        th {
          text-align: start;
          padding: 0.5rem;
        }
      }
    }
  }

  th,
  td {
    border: 0.5px solid #dadada;
    padding: 0.3rem;
  }

  thead {
    font-size: 0.8rem;
    font-weight: 500;
  }

  tbody {
    td {
      font-size: 12px;
    }
  }
`
