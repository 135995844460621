import { NameValueProps } from './types'
import * as Styled from './NameValues.styled'

const NameValue: React.FunctionComponent<NameValueProps> = ({
  name,
  onClick = () => {},
  value,
}) => {
  return (
    <Styled.MainView onClick={() => onClick()}>
      <Styled.Row>
        <p>{name}</p>
        {value && <b>{value.toLocaleString()}</b>}
      </Styled.Row>
    </Styled.MainView>
  )
}

export default NameValue
