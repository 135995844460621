import React, { useState } from 'react'
import * as Styled from './TiktokCard.styled'

import defaultUserProfile from './images/default-user.png'
import defaultPostImage from './images/default-post.png'

import EyeIcon from './images/eye-icon.svg'
import LikeIcon from './images/like-icon.png'
import comment from './images/comment.svg'
import share from './images/share.png'
import { ITiktokAnalysisProps } from './types'
import { numberFormat } from 'app/functions/number'

const TiktokCard: React.FC<ITiktokAnalysisProps> = ({
  data,
  editMode,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  const timeAgo = (input: Date | string | number) => {
    const date = input instanceof Date ? input : new Date(input)
    const formatter = new Intl.RelativeTimeFormat('tr')
    const ranges = [
      ['years', 3600 * 24 * 365],
      ['months', 3600 * 24 * 30],
      ['weeks', 3600 * 24 * 7],
      ['days', 3600 * 24],
      ['hours', 3600],
      ['minutes', 60],
      ['seconds', 1],
    ] as const
    const secondsElapsed = (date.getTime() - Date.now()) / 1000

    for (const [rangeType, rangeVal] of ranges) {
      if (rangeVal < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / rangeVal
        return formatter.format(Math.round(delta), rangeType)
      }
    }
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultPostImage)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfile)
  }

  const textLength = data?.Text && data?.Text?.length >= 52

  return (
    <Styled.AgendaTiktokCard
      $isOpen={editMode ? true : isOpen}
      $isLength={textLength || false}
    >
      {data?.MediaUrl ? (
        <img
          className="post-image"
          src={data?.MediaUrl}
          onError={hasErrorImage}
          alt="Postimage"
        />
      ) : (
        <img className="post-image" src={defaultPostImage} alt="Postimage" />
      )}

      <div className="agenda-tiktok">
        <div className="agenda-tiktok-header">
          <div className="agenda-tiktok-header-profile-image">
            {data?.ChannelProfilePictureUrl ? (
              <img
                src={data?.ChannelProfilePictureUrl}
                onError={hasErrorImageProfil}
                alt="Profilepp"
              />
            ) : (
              <img src={defaultUserProfile} alt="Profilepp" />
            )}
          </div>
          <div className="agenda-tiktok-header-profile-des">
            <span className="agenda-tiktok-header-profile-des-name">
              {data?.ChannelName}
            </span>
            <span className="agenda-tiktok-header-profile-des-link">
              {numberFormat(data?.ChannelFollowerCount)} Abone
            </span>
          </div>
        </div>
        <div className="agenda-tiktok-video">
          <div
            contentEditable={editMode}
            onBlur={(e) => handleBlurElement(e, 'Text')}
            className="agenda-tiktok-video-desc"
          >
            {data?.Text}
          </div>
          {textLength && !editMode && (
            <button
              className="agenda-tiktok-video-button"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? 'Daha Az' : 'Daha Fazla'}
            </button>
          )}
          <div className="agenda-tiktok-video-date">
            <span>{timeAgo(data?.CreatedAt ? data?.CreatedAt : '')}</span>
          </div>
        </div>
        <div className="agenda-tiktok-post-infos">
          <div className="agenda-tiktok-post-infos-item">
            <img src={EyeIcon} alt="Eye Icon" />
            <strong>{numberFormat(data?.ViewCount)}</strong>
            <span>Görüntülenme</span>
          </div>
          <div className="agenda-tiktok-post-infos-item">
            <img src={LikeIcon} alt="Like Icon" />
            <strong>{numberFormat(data?.FavoriteCount)}</strong>
            <span>Beğeni</span>
          </div>
          <div className="agenda-tiktok-post-infos-item">
            <img src={comment} alt="commentIcon" />
            <strong>{numberFormat(data?.CommentCount)}</strong>
            <span>Yorum</span>
          </div>
          <div className="agenda-tiktok-post-infos-item">
            <img src={share} alt="shareIcon" />
            <strong>{numberFormat(data?.ShareCount)}</strong>
            <span>Paylaşım</span>
          </div>
        </div>
      </div>
    </Styled.AgendaTiktokCard>
  )
}

export default TiktokCard
