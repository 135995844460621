import React from 'react'
import Page from '@zera-admin/page'

import MainLayout from 'layouts/main'
import TrainingWizard from 'widgets/training-wizard'

import { TrainingGroupListProps } from './types'
import { renderPageHeader } from './constants'

const TrainingGroupList: React.FunctionComponent<TrainingGroupListProps> =
  () => {
    return (
      <MainLayout>
        <Page>
          {renderPageHeader()}
          <TrainingWizard />
        </Page>
      </MainLayout>
    )
  }

export default TrainingGroupList
