import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { SentimentType } from 'services/http/report/person-report/type'

import { AverageSentimentsProps } from './types'

const AverageSentiments: React.FunctionComponent<AverageSentimentsProps> = ({
  data,
}) => {
  const getTitle = (sentiment: SentimentType) => {
    switch (sentiment) {
      case 'negative':
        return 'Negatif'
      case 'neutral':
        return 'Nötr'
      case 'positive':
        return 'Pozitif'
      default:
        return ''
    }
  }

  const sentimentColor = (label: string) => {
    switch (label) {
      case 'positive':
        return '#1CD797'
      case 'negative':
        return '#E3000F'
      case 'neutral':
        return 'gray'
      default:
        return 'gray'
    }
  }

  const averageSentiments: Props = {
    series: data?.map((item: any) => item.TotalCount),
    options: {
      chart: {
        id: 'averageSentiments',
        width: '100%',
      },
      legend: {
        show: true,
        position: 'top',
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      colors: data?.map((item: any) => item.Sentiment).map(sentimentColor),
      fill: {
        colors: data?.map((item: any) => item.Sentiment).map(sentimentColor),
      },
      labels: data?.map((item: any) => getTitle(item.Sentiment)),
    },
  }

  return data && data.length > 0 ? (
    <Chart
      options={averageSentiments.options}
      series={averageSentiments.series}
      type="pie"
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default AverageSentiments
