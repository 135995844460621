import styled from 'styled-components'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
export const ModalContent = styled.div`
  margin-top: 4px;
`

export const Person = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  width: 100%;
  .img {
    margin-right: 8px;
    height: fit-content;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .username {
    width: 100%;
  }
`
