import React from 'react'
import * as Styled from './SocialCard.styled'

export interface Overlay {
  text?: string
  icon?: string
  color?: string
  value?: string
  tweetCount?: number | string
  tweetTitle?: string
  styles?: any
}

const SocialCard: React.FunctionComponent<Overlay> = ({
  icon,
  color,
  tweetCount,
  tweetTitle,
  styles,
}) => {
  return (
    <Styled.SocialCardContainer style={{ backgroundColor: color, ...styles }}>
      <div className="tweet-box-icon">
        <i className={icon}></i>
      </div>
      <div className="tweet-box-count">
        <span>{tweetCount}</span>
      </div>
      <div className="tweet-box-title">
        <span>{tweetTitle}</span>
      </div>
    </Styled.SocialCardContainer>
  )
}

export default SocialCard
