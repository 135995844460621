import React, { useEffect, useState } from 'react'
import { uid } from '@zera-admin/utils'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { ValidationError } from 'app/shared/types'
import MainLayout from 'layouts/main'
import blackListService, {
  BlackList,
  UpdateteBlackListRequest,
} from 'services/http/backoffice/black-list'

import { UpdateBlackListProps } from './types'
import BlackListForm from './BlackListForm'

const UpdateBlackList: React.FunctionComponent<UpdateBlackListProps> = ({
  match,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(true)
  const [blackList, setBlackList] = useState<BlackList>()
  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    const id = match.params.id

    blackListService
      .getById(id)
      .then((res) => {
        setBlackList(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsReady(false)
      })
  }, [match.params.id, history])

  const handleSubmit = (data: UpdateteBlackListRequest) => {
    if (data) {
      setIsLoading(true)

      blackListService
        .update(data, match.params.id)
        .then(() => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: 'İşlem Başarılı',
            children:
              'Geçerli veri, sistem üzerinde başarıyla güncellenmiştir.',
          })
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Hata Oluştu',
            children:
              'Geçerli veri güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleDelete = () => {
    blackListService
      .remove(match.params.id)
      .then(() => {
        flagcard.add({
          id: uid(),
          appearance: 'success',
          title: 'İşlem Başarılı',
          children: 'Geçerli veri, sistem üzerinde başarıyla güncellenmiştir.',
        })
        history.push('/black-list')
      })
      .catch((error) => {
        console.log(error)

        flagcard.add({
          id: uid(),
          appearance: 'error',
          title: 'Hata Oluştu',
          children:
            'Geçerli veri güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleError = (error?: ValidationError) => {
    console.log(error)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Black List" href="/black-list" />
      <BreadcrumbItem text="Black List değeri güncelle" />
    </Breadcrumb>
  )

  return (
    <MainLayout isLoading={isReady}>
      <Container width="medium">
        <Page>
          <PageHeader
            actions={
              <Button onClick={() => handleDelete()} appearance="error">
                Black List değeri sil
              </Button>
            }
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Aşağıdaki bilgileri doldurarak geçerli Black List değerini
                güncelleyebilirsiniz.
              </p>
            }
          >
            Veri güncelleniyor: {blackList ? blackList.value : null}
          </PageHeader>
          <BlackListForm
            actions={
              <ButtonGroup>
                <Button
                  appearance="primary"
                  children="Güncelle"
                  isLoading={isLoading}
                  type="submit"
                />
                <LinkButton href="/black-list">İptal</LinkButton>
              </ButtonGroup>
            }
            operation="update"
            onSubmit={handleSubmit}
            onError={handleError}
            values={blackList}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default UpdateBlackList
