import React, { useEffect, useState } from 'react'
import { Row, Container } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'
import Alert from '@zera-admin/alert'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected'

import accountService from 'services/http/dashboard-ui/account'

import * as Styled from './RelationChart.styled'
import { RelationChartProps } from './types'

const RelationChart: React.FunctionComponent<RelationChartProps> = ({
  reference,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [graphData, setGraphData] = useState<any[]>([])

  const handleCreateGraph = () => {
    const chart = am4core.create(
      'chartdiv',
      am4plugins_forceDirected.ForceDirectedTree
    )
    const networkSeries = chart.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    )

    chart.data = graphData

    networkSeries.dataFields.value = 'value'
    networkSeries.dataFields.name = 'name'
    networkSeries.dataFields.children = 'children'
    networkSeries.dataFields.id = 'name'
    networkSeries.dataFields.linkWith = 'otherParents'
    networkSeries.nodes.template.tooltipText = '{name} | Takipçi sayısı:{value}'
    networkSeries.nodes.template.fillOpacity = 1

    networkSeries.nodes.template.label.text = '{name}'
    networkSeries.fontSize = 10
    networkSeries.minRadius = 40

    networkSeries.links.template.strokeWidth = 1

    let hoverState = networkSeries.links.template.states.create('hover')
    hoverState.properties.strokeWidth = 3
    hoverState.properties.strokeOpacity = 1

    networkSeries.nodes.template.events.on('over', function (event) {
      event.target.dataItem.childLinks.each(function (link) {
        link.isHover = true
      })
      if (event.target.dataItem.parentLink) {
        event.target.dataItem.parentLink.isHover = true
      }
    })

    networkSeries.nodes.template.events.on('out', function (event) {
      event.target.dataItem.childLinks.each(function (link) {
        link.isHover = false
      })
      if (event.target.dataItem.parentLink) {
        event.target.dataItem.parentLink.isHover = false
      }
    })
  }

  useEffect(() => {
    const username = reference?.raw?.username
    let graphDataObj: any = []

    if (username) {
      accountService
        .getRelationships(username as string)
        .then((res) => {
          res.data.forEach((relation: any) => {
            graphDataObj.push({
              name: relation.name,
              value: relation.value,
              children: relation.children,
            })
          })
          //TODO: common node içeren responselar için recursive tarama yaparak ilişki ağı nesnesi reduce edilecek
          setGraphData(graphDataObj)
          handleCreateGraph()

          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference?.raw?.username])

  const renderRelationChart = () => {
    return (
      <Container width="narrow">
        {isLoading ? (
          <Row xs={{ align: 'center', justify: 'center' }}>
            <Spinner className="spinner" size="medium" />
          </Row>
        ) : graphData?.length ? (
          <Styled.RelationChart>
            <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
          </Styled.RelationChart>
        ) : (
          <Alert
            appearance="warning"
            title="İlişki ağı oluşturulamadı"
            children="İlişki ağunu oluşturacak veri bulunamadı. Lütfen sorgularınızı ve ilişki ağı ayarlarınızı kontrol ediniz."
          />
        )}
      </Container>
    )
  }

  return renderRelationChart()
}

export default RelationChart
