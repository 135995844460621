import React, { useState } from 'react'
import Form from '@zera-admin/form'
import MainLayout from 'layouts/main/Main'

import { Column, PageHeader, Row } from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'

import { ManualAlarmCreateProps } from './types'

import { manualCreate } from 'services/http/backoffice/alarm-keyword/endpoints'

import Input from '@zera-admin/input'
import Textarea from '@zera-admin/textarea'
import Switch from '@zera-admin/switch'
import Button from '@zera-admin/button'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { uid } from '@zera-admin/utils'
import { ManualCreateParams } from 'services/http/backoffice/alarm-keyword/types'

const ManualAlarmCreate: React.FunctionComponent<ManualAlarmCreateProps> =
  ({}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [flagcard] = useFlagcardContext()

    const [formData, setFormData] = useState<ManualCreateParams>({
      alarmName: '',
      alarmMessage: '',
      isTr: false,
      translateText: '',
    })

    const handleFormData = (key: string, value: any) => {
      setFormData({
        ...formData,
        [key]: value,
      })
    }

    const renderPageHeader = () => {
      const breadcrumbs = (
        <Breadcrumb>
          <BreadcrumbItem text="Anasayfa" href="/" />
          <BreadcrumbItem text="Alarmlar" href="/alarms" />
          <BreadcrumbItem text={'Manual Alarm Oluştur'} />
        </Breadcrumb>
      )

      return (
        <PageHeader
          // actions={actions}
          breadcrumbs={breadcrumbs}
        >
          Manual Alarm Oluştur
        </PageHeader>
      )
    }

    const handleManualCreateAlarm = () => {
      if (formData?.alarmMessage && formData?.alarmName) {
        manualCreate({ ...formData, isTr: !formData?.isTr })
          .then(() => {
            flagcard.add({
              id: uid(),
              appearance: 'success',
              title: `Manual alarm oluşturuldu`,
              children: `Girmiş olduğunuz bilgiler ile alarm gönderimi yapılmaya başlandı.`,
            })
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
          .catch(() => {
            flagcard.add({
              id: uid(),
              appearance: 'error',
              title: 'Bir hata oluştu',
              children: `Manual alarm eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.`,
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        flagcard.add({
          id: uid(),
          appearance: 'error',
          title: 'Bütün alanları doldurunuz!!',
          children: `Alarm Adı veya Alarm Mesaj İçeriği boş olamaz.`,
        })
      }
    }

    const renderContent = () => {
      return (
        <Form onSubmit={() => handleManualCreateAlarm()}>
          <Row>
            <Column>
              <Input
                name="alarmName"
                type="text"
                label="Alarm Adı"
                placeholder="Alarm Adı"
                value={formData?.alarmName}
                onChange={(event) => {
                  const value = event.currentTarget.value
                  handleFormData('alarmName', value)
                }}
                isRequired
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Textarea
                name="alarmMessage"
                label="Alarm Mesaj İçeriği"
                placeholder="Alarm Mesaj İçeriği"
                value={formData?.alarmMessage}
                onChange={(event) => {
                  const value = event.currentTarget.value
                  handleFormData('alarmMessage', value)
                }}
                isRequired
              />
            </Column>
          </Row>
          <Row
            xs={{
              direction: 'column',
            }}
          >
            <Column>
              <Switch
                name="isTr"
                label="Çeviri var mı ?"
                isChecked={formData?.isTr}
                onChange={(event) =>
                  handleFormData('isTr', event.target.checked)
                }
              />
            </Column>
            {formData?.isTr && (
              <Column>
                <Textarea
                  name="translateText"
                  label="Çeviri İçeriği"
                  placeholder="Çeviri İçeriği"
                  value={formData?.translateText}
                  onChange={(event) => {
                    const value = event.currentTarget.value
                    handleFormData('translateText', value)
                  }}
                />
              </Column>
            )}
          </Row>
          <Row>
            <Column>
              <Button type="submit" appearance="primary" isDisabled={isLoading}>
                Manual Alarm Oluştur
              </Button>
            </Column>
          </Row>
        </Form>
      )
    }

    return (
      <MainLayout>
        {renderPageHeader()}
        {renderContent()}
      </MainLayout>
    )
  }

export default ManualAlarmCreate
