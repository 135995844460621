import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 696px;
  gap: 0 1vw;
  @font face {
    font-family: 'EuClid';
    src: url('../../../../assets/fonts/EuClidBold');
  }
  font-family: 'EuClid', sans-serif !important;
`

export const AsideBar = styled.div`
  position: relative;
  background: linear-gradient(to bottom, #08a0e5, #045073);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  min-width: 240px;
  min-height: 696px;

  .aside-bar-header {
    width: 100%;
    &-edit {
      display: flex;
      justify-content: end;
      gap: 4px;
      padding-top: 1rem;
      padding-right: 1rem;
      & button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        & i {
          color: lightgreen;
          font-size: 1.4rem;
          &:hover {
            color: #1bdb1b;
            transition: 0.3s;
          }
        }
      }
    }
    .aside-bar-input {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: transparent;
      font-size: 28px;
      line-height: 34px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 5rem;
      text-align: center;
    }

    &-title {
      font-size: 28px;
      line-height: 34px;
      font-weight: bold;
      color: #ffffff;
      margin: 5rem 1rem 0 1rem;
      text-align: center;
    }

    &-date {
      display: block;
      font-size: 16px;
      line-height: 20px;
      font-weight: medium;
      color: #ffffff;
      margin: 0.5rem 1rem 0 1rem;
      text-align: center;
    }
  }

  .aside-bar-footer {
    &-flag {
      text-align: center;
      img {
        width: 104px;
        border-radius: 4px;
      }

      span {
        display: block;
        font-size: 16px;
        line-height: 20px;
        font-weight: medium;
        color: #ffffff;
      }
    }

    &-logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 2rem 3rem 3.5rem 3rem;

      img {
        height: 40px;
      }

      & > img:first-child {
        /* width: 98px;
        padding-right: 1rem;
        margin-right: 1rem;
        border-right: 2px solid #89b4c8; */
      }

      & > img:last-child {
        /* width: 85px; */
      }
    }
  }

  .page-count {
    position: absolute;
    bottom: 1rem;
    right: 1.4rem;
    font-size: 18px;
    line-height: 20px;
    font-weight: medium;
    color: #89afc1;
  }
`
