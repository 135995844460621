import { Column } from '@zera-admin/page'
import PdfPageLayout from './PdfPageLayout'
import {
  ActiveHours,
  AverageSentiments,
  Big5,
  GeneralCategories,
  MostCommonEntities,
} from '../report-detail'

import { PdfPageProps } from './types'
import Icon from '@zera-admin/icon'
import { getMonthAndYear } from 'app/functions'

const PageOne: React.FunctionComponent<PdfPageProps> = ({ personReport }) => {
  return (
    <PdfPageLayout personReport={personReport}>
      <div className="half">
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 6 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Big5 Verileri
            </h2>

            <Big5 data={personReport.data?.result?.Statistic?.bigFive} />
          </Column>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 6 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Kullanıcının Aktif Saatleri
            </h2>
            <ActiveHours
              data={personReport.data?.result?.Statistic?.activeHours}
            />
          </Column>
        </div>
        <Column>
          <div></div>
        </Column>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 6 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Tweetlerin Ortalama Duygu Analizi
            </h2>
            <AverageSentiments
              data={personReport.data?.result?.Statistic?.averageSentiments}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 10 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Genel Kategoriler
            </h2>
            <GeneralCategories
              data={
                personReport.data?.result?.Statistic?.classifications
                  ?.generalCategories
              }
            />
          </Column>
        </div>
        <Column>
          <div></div>
        </Column>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 9 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              ABS Analizine Göre En Çok Kullandığı Kelimeler
            </h2>
            <MostCommonEntities
              data={
                personReport.data?.result?.Statistic?.aspectAnalysis
                  ?.mostCommonEntities
              }
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
    </PdfPageLayout>
  )
}
export default PageOne
