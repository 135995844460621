import React from 'react'
import Modal from '@zera-admin/modal'
import Button from '@zera-admin/button'

import { ModalContent, Person } from './SelectedPersonModal.styled'
import { SelectedPersonModalProps } from './types'

const SelectedPersonModal: React.FunctionComponent<SelectedPersonModalProps> =
  ({ onClose, selectedPerson, onDeletePerson, putStatus }) => {
    const updateStatus = () => {
      const entityKeys = selectedPerson.map((person) => person.entityKey)
      putStatus(entityKeys)
    }
    const actions = [
      {
        text: 'Statüs Güncelle',
        onClick: () => {
          onClose()
          updateStatus()
        },
      },
      {
        text: 'Kapat',
        onClick: () => {
          onClose()
        },
      },
    ]

    const persons = selectedPerson.map((person) => (
      <Person>
        <div className="img">
          <img src={person.profileImageUrl} alt="profile" />
        </div>
        <div className="username">{person.name}</div>
        <Button
          onClick={() => {
            onDeletePerson(person)
            if (selectedPerson.length === 1) {
              onClose()
            }
          }}
        >
          Sil
        </Button>
      </Person>
    ))
    return (
      <Modal width={500} height={600} actions={actions}>
        <ModalContent>{persons}</ModalContent>
      </Modal>
    )
  }
export default SelectedPersonModal
