import { Provider as ReduxProvider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { LinkProvider } from '@zera-admin/link'

import { store } from 'app/redux'
import { FlagcardProvider } from 'app/contexts/flagcard'
import { PopupProvider } from 'app/contexts/popup'
import { DashboardProvider } from 'app/contexts/dashboard'
import CustomLink from 'components/custom-link'
import { RootProvider } from 'app/contexts/reports'

const Provider = ({ children }: { children: React.ReactElement }) => {
  const theme = {}

  return (
    <ThemeProvider theme={theme}>
      <LinkProvider component={CustomLink}>
        <ReduxProvider store={store}>
          <DashboardProvider>
            <FlagcardProvider>
              <RootProvider>
                <PopupProvider>{children}</PopupProvider>
              </RootProvider>
            </FlagcardProvider>
          </DashboardProvider>
        </ReduxProvider>
      </LinkProvider>
    </ThemeProvider>
  )
}

export default Provider
