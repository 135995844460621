import {
  DefinitionTag,
  UpdateDefinitionTagRequest,
  DefinitionTagResponse,
} from './types'

export const mapDefinitionTagForRequest = (
  raw: UpdateDefinitionTagRequest
): UpdateDefinitionTagRequest => ({
  id: raw.id,
  definitionIds: raw.definitionIds.map((item: any) => item.value) || [],
  name: raw.name,
})

export const mapGetDefinitionTagForResponse = (raw: any): DefinitionTag => ({
  definitionIds: raw.definitions.map((item: any) => ({
    label: item.value,
    value: item.id,
  })),
  id: raw.definitionTag.id,
  name: raw.definitionTag.name,
  insertedDate: raw.definitionTag.insertedDate,
})

export const mapDefinitionTagForResponse = (
  raw: any
): Omit<DefinitionTag, 'definitionIds'> => ({
  id: raw.data.id,
  insertedDate: raw.data.insertedDate,
  name: raw.data.name,
})

export const mapDefinitionTagForList = (raw: any): DefinitionTagResponse => {
  return {
    list: raw.data.map((item: unknown) =>
      mapDefinitionTagForResponse({ data: item })
    ),
    dataCount: raw.dataCount,
    pageCount: raw.pageCount,
  }
}

// definitions: raw.data.definitions.map(
//   (definition: Definitions): Definitions => ({
//     accountType: definition.accountType,
//     crawlingType: definition.crawlingType,
//     currentCursor: definition.currentCursor,
//     definitionType: definition.definitionType,
//     fetchCrawledEndDate: definition.fetchCrawledEndDate,
//     fetchCrawledStartDate: definition.fetchCrawledStartDate,
//     fetchNextRefreshDate: definition.fetchNextRefreshDate,
//     fetchStatus: definition.fetchStatus,
//     id: definition.id,
//     insertedDate: definition.insertedDate,
//     isCalculateWordCount: definition.isCalculateWordCount,
//     mediaIndicatorId: definition.mediaIndicatorId,
//     paused: definition.paused,
//     readHistorical: definition.readHistorical,
//     refreshInterval: definition.refreshInterval,
//     streamStatus: definition.streamStatus,
//     streamStatusLastPingDate: definition.streamStatusLastPingDate,
//     value: definition.value,
//   })
// ),
