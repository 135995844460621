import React, { useEffect, useState } from 'react'
import * as Styled from './MostSharedWebSiteList.styled'
import { MostSharedWebSiteListProps } from './types'
import { MostSharedWebSiteList } from 'pages/agenda-report-v4/detail/widgets/types'
import { numberFormat } from 'app/functions/number'
import WidgetLayout from 'pages/agenda-report-v4/detail/widgets/widget-layout'
import UpdateButton from 'pages/agenda-report-v4/detail/components/update-button/UpdateButton'

const MostSharedWebSiteTable: React.FC<MostSharedWebSiteListProps> = ({
  date,
  data,
  title,
  isEditValue,
  setIsEditValue,
  pageNumber,
  metaData,
}) => {
  const [tableData, setTableData] = useState<
    MostSharedWebSiteList[] | undefined
  >(data)
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setTableData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChange = (key: string, value: string, source: string) => {
    setTableData(
      tableData?.map((item) => {
        if (item.Name === source) {
          return {
            ...item,
            [key]: value,
          }
        }
        return item
      })
    )
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      {data && data?.length > 0 ? (
        <Styled.TableContent>
          <div className="edit-btn-container">
            <UpdateButton
              {...editProps}
              contentData={{ MostSharedWebSiteList: tableData }}
              title={pageData?.title}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </div>

          <Styled.TableContainer>
            <table>
              <thead>
                <tr>
                  <th>Site dağılımı</th>
                  <th>Hacim</th>
                  <th>Paylaşım</th>
                  <th>Görüntülenme</th>
                  <th>Yorum</th>
                  <th>Beğeni</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((row, index) => (
                  <tr key={index}>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange('Name', e.target.innerText, row.Name)
                      }
                    >
                      {row.Name}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange('PostCount', e.target.innerText, row.Name)
                      }
                    >
                      {row.PostCount ? numberFormat(row.PostCount) : '-'}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange('ShareCount', e.target.innerText, row.Name)
                      }
                    >
                      {row.ShareCount ? numberFormat(row.ShareCount) : '-'}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange(
                          'ImpressionCount',
                          e.target.innerText,
                          row.Name
                        )
                      }
                    >
                      {row.ImpressionCount
                        ? numberFormat(row.ImpressionCount)
                        : '-'}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange('CommentCount', e.target.innerText, row.Name)
                      }
                    >
                      {row.CommentCount ? numberFormat(row.CommentCount) : '-'}
                    </td>
                    <td
                      contentEditable={editMode}
                      onBlur={(e) =>
                        onChange('FavoriteCount', e.target.innerText, row.Name)
                      }
                    >
                      {row.FavoriteCount
                        ? numberFormat(row.FavoriteCount)
                        : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Styled.TableContainer>
        </Styled.TableContent>
      ) : (
        <h3>Veri Bulunamadi</h3>
      )}
    </WidgetLayout>
  )
}

export default MostSharedWebSiteTable
