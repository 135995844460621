import React from 'react'
import { crypto } from '@zera-admin/helpers'

import { useFlagcardContext } from 'app/contexts/flagcard'
import BIToolLayout from 'layouts/bi-tool'

import WidgetForm from '../widget-form'

import { UpdateWidgetProps } from './types'

const UpdateWidget: React.FunctionComponent<UpdateWidgetProps> = ({
  match,
  history,
}) => {
  const [flagcard] = useFlagcardContext()

  const handleSave = () => {
    flagcard.add({
      id: crypto.id(),
      appearance: 'success',
      title: 'Widget güncellendi',
      children:
        'Girmiş olduğunuz bilgiler ile birlikte widget sistem üzerinde başarıyla güncellenmiştir.',
    })
  }

  const handleDelete = () => {
    history.push('/bi-tool/widgets')
  }

  return (
    <BIToolLayout>
      <WidgetForm
        id={match.params.id}
        operation="update"
        onSave={handleSave}
        onDelete={handleDelete}
        type="page"
      />
    </BIToolLayout>
  )
}

export default UpdateWidget
