import { useState, useEffect } from 'react'
import * as Styled from './SelectIcon.styled'
import { PropsForMap } from './types'

interface SelectIconProps {
  list: []
  placeholder?: string
  title?: string
  desc?: string
  onChange?: (value: string) => void
}

function SelectIcon({
  list,
  placeholder,
  title,
  onChange,
  desc,
}: SelectIconProps) {
  const [isSelected, setIsSelected] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!event.target.closest('.select-menu')) {
        setIsSelected(false)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleOptionClick = (item: any) => {
    setValue(item.label)
    setIsSelected(false)

    if (onChange) {
      onChange(item.value) // onChange fonksiyonunu değişen değerle çağır
    }
  }

  const colorPicker = (color: string): string => {
    switch (color) {
      case 'Twitter':
        return '#1DA1F1'
      case 'Reddit':
        return '#F74500'
      case 'YouTube':
        return '#FF0000'
      case 'VK':
        return '#4C75A3'
      case 'Haberler':
        return '#86592d'
      case 'Starngage':
        return '#red'
      case 'Tiktok':
        return '#86592d'
      case 'TripAdvisor':
        return '#86592d'
      case 'Uludağ Sözlük':
        return '#86592d'
      case 'Website':
        return '#86592d'
      default:
        return 'black'
    }
  }

  return (
    <>
      <Styled.BarChart>
        <div className="select-menu">
          <label className="select-title">{title}</label>
          <button
            onClick={() => setIsSelected(!isSelected)}
            className="select-btn"
          >
            <span className="sBtn-text">
              {(value.length > 0 && value) || <span>{placeholder}</span>}
            </span>
            <span className="sort-container">
              {isSelected ? (
                <i className="fa-solid fa-sort-down"></i>
              ) : (
                <i className="fa-solid fa-sort-up"></i>
              )}
            </span>
          </button>
          {isSelected === true ? (
            <ul className="options">
              {list.map((item: PropsForMap) => (
                <button
                  key={item.label}
                  onClick={() => handleOptionClick(item)}
                  className="option"
                >
                  {item.icon && (
                    <div className="img-container">
                      <i
                        className={`${item.icon}`}
                        style={{ color: colorPicker(item.label) }}
                      />
                    </div>
                  )}
                  {item.img && (
                    <div className="img-container">
                      <img src={item.img} alt={item.label} />
                    </div>
                  )}
                  <button className="option-text">{item.label}</button>
                </button>
              ))}
            </ul>
          ) : null}
          <div className="select-desc">{desc}</div>
        </div>
      </Styled.BarChart>
    </>
  )
}

export default SelectIcon
