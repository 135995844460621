/* eslint-disable no-eval */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment'

import { oracleOperator } from 'app/functions/parser/helpers'
import { QueryRequest } from 'services/http/bi-tool/query'
import { uid } from '@zera-admin/utils'
import { getIntervalAsISO, getIntervalPeriod } from './date'
import { QueryCriteriaInterval } from 'services/http/bi-tool/query/enums'
import { getUserScreenNameList } from 'services/http/bi-tool/query/endpoints'

export const oracle = async (query: QueryRequest, params: any) => {
  let sql = ''
  let inqueries: [id: string, params: object, data: string[]][] = []
  if (query && query.raw) {
    const rules = query.where && query.where.rules
    const intervals = query.raw.intervals

    //bunlar  eval fonksiyonu içinde kullanılıyor
    const filter = (
      key: string,
      value = '',
      operator = '=',
      seperator = 'AND'
    ) => {
      let result = ''

      if (rules) {
        value = value || key

        if (key.toLowerCase() === 'all') {
          let where = ''

          if (query.where) {
            where = query.where.rules
              .map((rule) => {
                return `${rule.field} ${oracleOperator(rule.operator || '')} '${
                  rule.value
                }';
                `
              })
              .join('')
          }

          result = where
        } else {
          const ruleref = rules.find((rule) => rule.field === value)

          if (ruleref) {
            result = `${key}${operator}'${ruleref.value}';`
          }
        }
      }

      return result
    }

    const time = (start: string, end: string, ref = '') => {
      let formatted = getIntervalAsISO(query.interval)
      const period = getIntervalPeriod(query.interval)
      if (!start || !end) return ''

      if (intervals && ref) {
        const timeref = intervals.find((interval) => interval.name === ref)

        if (timeref) {
          formatted = getIntervalAsISO(timeref.interval)
        }
      }
      if (period === 'hourly') {
        return `${start} := '${moment(formatted.start).format(
          'yyyy-MM-DD HH:00:00'
        )}';${end} := '${moment(formatted.end).format('yyyy-MM-DD HH:00:00')}';`
      } else {
        return `${start} := '${moment(formatted.start).format(
          'yyyy-MM-DD 00:00:00'
        )}';${end} := '${moment(formatted.end).format('yyyy-MM-DD 00:00:00')}';`
      }
    }

    const newtime = (key: string) => {
      if (key) {
        const formatted = getIntervalAsISO(query.interval)

        return `${key}>=to_timestamp('${moment(formatted.start).format(
          'yyyy-MM-DD HH:mm:00'
        )}','yyyy-mm-dd HH24:MI:SS') AND ${key}<=to_timestamp('${moment(
          formatted.end
        ).format('yyyy-MM-DD HH:mm:00')}','yyyy-mm-dd HH24:MI:SS') `
      }
      return ''
    }

    const word = (first: string, second: string) => {
      if (params.searchKey && params.secondValue) {
        return `${first} := ${params.searchKey};${second} := ${params.secondValue};`
      } else {
        return ''
      }
    }

    const inquery = (key: string, params: object, seperator = 'AND') => {
      if (params) {
        const id = uid()

        inqueries.push([id, params, []])

        return `${seperator} ${key} IN(${id})`
      }

      return ''
    }

    const inprocedurequery = (
      key: string,
      params: object,
      seperator = ':='
    ) => {
      if (params) {
        const id = uid()

        inqueries.push([id, params, []])

        return ` ${key}${seperator}'(${id})';`
      }

      return ''
    }

    try {
      sql = eval('`' + query.raw.sql + '`')
      if (inqueries.length) {
        const category =
          rules && rules.find((rule) => rule.field === 'classification1')
        const sentiment =
          rules && rules.find((rule) => rule.field === 'sentiment')
        const country =
          rules && rules.find((rule) => rule.field === 'countryDetection')
        const period = getIntervalPeriod(query.interval)

        let dateType = QueryCriteriaInterval.Daily

        if (period === 'monthly') {
          dateType = QueryCriteriaInterval.Monthly
        } else if (period === 'weekly') {
          dateType = QueryCriteriaInterval.Weekly
        }

        const values = await Promise.all(
          inqueries.map(([id, params]) =>
            getUserScreenNameList({
              ...params,
              sentiment: sentiment ? sentiment.value : null,
              country: country ? country.value : null,
              userCategory: category ? category.value : null,
              dateType,
            })
          )
        )

        inqueries.forEach(([id, params, data], index) => {
          const value = values[index]

          if (value) {
            sql = sql.replace(
              id,
              value.data.data.map((value) => `''${value}''`).join(',')
            )
          }

          inqueries[index][2] = values[index].data.data || []
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return sql
}
