import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Lozenge from '@zera-admin/lozenge'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import botClassificationServices, {
  BotClassification,
  BotClassificationResponse,
} from 'services/http/dataset/bot-classification'
import { BotConclusionEnum } from 'services/http/dataset'

import { BotClassificationListProps } from './types'

const BotClassificationList: React.FunctionComponent<
  BotClassificationListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [botClassification, setBotClassification] =
    useState<BotClassificationResponse>()

  useEffect(() => {
    getBotClassifications()
  }, [])

  const getBotClassifications = async () => {
    try {
      const response = await botClassificationServices.get()

      setBotClassification(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/bot-classification/create">
        Bot sınıflandırması oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Bot sınıflandırmaları" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm bot sınıflandırmalarını görüntüleyebilir, yeni oluşturabilir,
        varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Bot sınıflandırmaları
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'twitterUserName',
        text: 'Kullanıcı adı',
      },
      {
        name: 'followersCount',
        text: 'Takipçi',
      },
      {
        name: 'followedCount',
        text: 'Takip edilen',
      },
      {
        name: 'likeTweetCount',
        text: 'Beğenilen tweet',
      },
      {
        name: 'sourceName',
        text: 'Kaynak',
      },
      {
        name: 'trainingGroupName',
        text: 'Eğitim grubu',
      },
      {
        name: 'maxSendingTweetTime',
        text: 'Max tweet gönderme süresi',
      },
      {
        name: 'maxTweetLength',
        text: 'Max tweet uzunluğu',
      },
      {
        name: 'minTweetLength',
        text: 'Min tweet uzunluğu',
      },
      {
        name: 'isBot',
        text: 'Bot durumu',
        render: (props: BotClassification) => {
          if (props.isBot === BotConclusionEnum.Bot) {
            return <Lozenge appearance="error">Bot</Lozenge>
          }
          return <Lozenge appearance="success">Bot Değil</Lozenge>
        },
      },
      {
        name: 'accountCreatedDate',
        text: 'Hesap oluşturulma tarihi',
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: BotClassification) => (
          <LinkButton
            appearance="subtle-link"
            href={`/bot-classification/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (botClassification) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={botClassification.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={botClassification.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default BotClassificationList
