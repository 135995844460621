import React, { useState, useEffect } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container, Row } from '@zera-admin/page'
import EmptyState from '@zera-admin/empty-state'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import { crypto } from '@zera-admin/helpers'

import countryService, {
  CountryPerceptionsResponse,
} from 'services/http/backoffice/country-perception'
import { useFlagcardContext } from 'app/contexts/flagcard'
import {
  AgendaReportStatus,
  AgendaReportType,
} from 'services/http/report/agenda-report/enums'
import agendaReportService from 'services/http/report/agenda-report-4'
import {
  Countries,
  CreateAgendaReportV4Req,
} from 'services/http/report/agenda-report-4/types'
import MainLayout from 'layouts/main'
import NotFoundImage from 'assets/images/not-found.png'
import Help from 'assets/images/help.svg'
import * as Styled from './CreateAgendaReport.styled'
import AgendaReportWizard from '../wizard'
import { CreateAgendaReportProps } from './types'
import AgendeSecondForm from '../secondform/AgendeSecondForm'
import { conditionBuilderInsert } from 'app/functions/parser/condition-builder/insert'
import { current30DaysBeforeString, currentDateString } from 'app/functions'
import { getVideoService } from 'services/http/video/url/endpoint'

const CreateAgendaReport: React.FunctionComponent<CreateAgendaReportProps> = ({
  history,
}) => {
  const [type, setType] = useState<AgendaReportType | null>()

  const [source, setSource] = useState<string[]>([])

  const [countries, setCountries] = useState<Countries[]>([])
  const [criteriaFields, setCriteriaFields] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [countryPerceptions, setCountryPerceptions] =
    useState<CountryPerceptionsResponse>()
  const [modalOpen, setModalOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string>('')

  useEffect(() => {
    agendaReportService.availableDataSourcesV4().then((res) => {
      setSource(res.data.data)
    })
    agendaReportService.availableCountriesV4().then((res) => {
      setCountries(res.data.data.countries)
    })
    agendaReportService.criteriaFieldSettingsV4().then((res) => {
      setCriteriaFields(res.data.data)
    })

    getVideoService({ key: 'alice-agenda-report-v4-creation' }).then((res) => {
      setVideoUrl(res.data.data.url)
    })

    handleGetCountryPerceptions()
  }, [])

  document.onkeydown = function (evt) {
    if (evt.keyCode === 27) {
      // Escape key pressed
      setModalOpen(false)
    }
  }

  document.onclick = function (evt) {
    if (evt.target === document.getElementById('modal-overlay')) {
      setModalOpen(false)
    }
  }

  const handleGetCountryPerceptions = (pageIndex: number = 0) => {
    setIsLoading(true)
    countryService
      .getCountryPerceptions(1000, pageIndex)
      .then((res) => {
        setCountryPerceptions(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSubmitIndependent = (values: CreateAgendaReportV4Req) => {
    const criteriaGroup =
      conditionBuilderInsert(values?.criteriaGroup as any) || undefined

    setIsLoading(true)
    agendaReportService
      .createAgendaReportV4({ ...values, criteriaGroup })
      .then((res) => {
        flagcard.add({
          id: crypto.id(),
          appearance: 'success',
          title: 'Gündem raporu hazırlanıyor',
          children:
            'Girmiş olduğunuz bilgiler ile birlikte gündem raporu, oluşturulmak üzere işlem sırası alınmıştır. İlgili adımları takip etmek üzere yönlendiriliyorsunuz.',
        })

        setTimeout(() => {
          history.push(`/agenda-reportv4/detail/${res.data}`)
        }, 300)
      })
      .catch((error: string) => {
        flagcard.add({
          id: crypto.id(),
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Gündem raporu oluşturulurken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
        })

        setIsLoading(false)
      })
  }
  const renderIndependentForm = () => (
    <AgendeSecondForm
      operation="create"
      source={source}
      countries={countries}
      criteriaFields={criteriaFields?.criteriaFields || []}
      countryPerception={countryPerceptions?.data}
      data={{
        reportName: 'Turkey 2023-02-01 & 02 Report',
        startDate: current30DaysBeforeString(),
        endDate: currentDateString(),
        criteriaGroup: {} as any,
        countryPerceptions: ['TUR'],
        dataSources: [],
        table: 3,
        environment: 'Alice',
        countryList: [],
      }}
      isLoading={isLoading}
      onSubmit={handleSubmitIndependent}
    />
  )
  const renderModal = () => (
    <Styled.ModalContainer>
      <div id="modal-overlay" className="modal-overlay" />
      <div className="modal-content">
        <div className="modal-content-button">
          <button onClick={() => setModalOpen(false)}>Kapat</button>
        </div>
        <div className="modal-content-video">
          {videoUrl && (
            <iframe
              src={videoUrl}
              width="640"
              height="480"
              title="Video Player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          )}
        </div>
      </div>
    </Styled.ModalContainer>
  )

  const renderEmpty = () => (
    <Styled.CreateAgendaReport>
      {modalOpen && renderModal()}
      <div className="content-body">
        <EmptyState
          title="Gündem raporu tipi seçiniz"
          description="Gündem raporunu oluşturacak bir tip seçiniz. Dilerseniz standart akışta bir gündem raporu oluşturabilir, dilersenizde mevcut tanımlamalar ile birlikte hızlı gündem raporu oluşturabilirsiniz."
          image={{
            src: NotFoundImage,
          }}
        />
        <EmptyState
          title="Yardıma mı ihtiyacınız var?"
          description="Nasıl gündem raporu oluşturacağınızı bilmiyorsanız, yardım almak için aşağıdaki butona tıklayabilirsiniz."
          image={{
            src: Help,
          }}
          actions={
            <Button
              iconBefore={<Icon name="vid-camera-on" />}
              appearance="button"
              onClick={() => setModalOpen(!modalOpen)}
            >
              Yardım al
            </Button>
          }
        />
      </div>

      <Row className="source-container" xs={{ direction: 'column' }}>
        <div className="source-container-btn">
          <Button
            iconBefore={<Icon name="documents" />}
            appearance="button"
            onClick={() => setType(AgendaReportType.Independent)}
          >
            Gündem Raporu V4 Oluştur
          </Button>
        </div>
      </Row>
    </Styled.CreateAgendaReport>
  )
  const renderWizard = () => {
    if (type === AgendaReportType.Independent) {
      return (
        <AgendaReportWizard
          status={AgendaReportStatus.Create}
          steps={[
            {
              label: 'Genel Bilgiler',
              value: AgendaReportStatus.Create,
              children: renderIndependentForm(),
              description:
                'Aşağıdan Gündem Raporu ile ilgili genel bilgileri girebilirsiniz. Ardından diğer adımlara geçilecektir.',
            },
            {
              label: 'Hazırlanıyor',
              value: AgendaReportStatus.Pending,
            },
            {
              label: 'Kuyrukta Bekliyor',
              value: AgendaReportStatus.Pending,
              description:
                'Veriler işlenmek üzere kuyruğa alındı. Bu işlem, diğer veri işlemelerinin yoğunluğuna göre zaman alabilmektedir.',
            },
            {
              label: 'Veriler İşleniyor',
              value: AgendaReportStatus.Processing,
            },
            {
              label: 'Tamamlandı',
              value: AgendaReportStatus.Completed,
            },
          ]}
        />
      )
    } else {
      return renderEmpty()
    }
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Gündem raporları" href="/agenda-reportv4" />
      <BreadcrumbItem
        text="Gündem raporu oluştur"
        onClick={() => setType(null)}
      />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Container width="medium">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={
              type ? (
                <p>
                  Gündem raporu oluşturmak için "Genel Bilgiler" adımındaki
                  ayarlamaları yapmanız yeterlidir. Bu adım tamamlandıktan
                  sonra, diğer adımlara yönlendirileceksiniz.
                </p>
              ) : undefined
            }
          >
            {type === AgendaReportType.Independent
              ? 'Hızlı gündem raporu oluşturuluyor'
              : 'GÜNDEM RAPORU'}
          </PageHeader>
          {renderWizard()}
        </Page>
      </Container>
    </MainLayout>
  )
}

export default CreateAgendaReport
