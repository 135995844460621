import Lozenge from '@zera-admin/lozenge'
import React from 'react'
import PageDesigner from './PageDesigner'

import { PageProps } from './types'

const Page: React.FunctionComponent<PageProps> = (props) => {
  const index = (props.index || 0) + 1

  return (
    <React.Fragment>
      <div className="fa-Page-lozenge">
        <Lozenge appearance="success" children={`${index}. Sayfa başı`} />
      </div>
      <div
        data-schema-id={props.id}
        className={`fa-draggable fa-Grid fa-Page row ${
          props.designing ? 'designing' : ''
        }`}
        style={{
          minHeight: props.designing ? '200px' : '135vh',
          position: 'relative',
        }}
      >
        {props.designing ? <PageDesigner {...props} /> : props.children}
      </div>
      <div className="fa-Page-lozenge">
        <Lozenge appearance="success" children={`${index}. Sayfa sonu`} />
      </div>
    </React.Fragment>
  )
}

export default Page
