import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Lozenge from '@zera-admin/lozenge'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import { BotConclusionEnum } from 'services/http/dataset'
import namedEntityControlSetsService, {
  NamedEntityControlSet,
  NamedEntityControlSetResponse,
} from 'services/http/dataset/named-entity-control-set'

import { NamedEntityControlSetListProps } from './types'

const NamedEntityControlSetList: React.FunctionComponent<
  NamedEntityControlSetListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [namedEntityControlSet, setNamedEntityControlSet] =
    useState<NamedEntityControlSetResponse>()

  useEffect(() => {
    getNamedEntityControlSets()
  }, [])

  const getNamedEntityControlSets = async () => {
    try {
      const response = await namedEntityControlSetsService.get()

      setNamedEntityControlSet(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/named-entity-control-set/create">
        İsimlendirilmiş varlık kontrol seti oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="İsimlendirilmiş varlık kontrol setleri" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm isimlendirilmiş varlık kontrol setlerini görüntüleyebilir, yeni
        oluşturabilir, varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        İsimlendirilmiş varlık kontrol setleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'text',
        text: 'İsimlendirilmiş varlık kontrol seti metni',
      },
      {
        name: 'languageCode',
        text: 'Dil kodu',
      },
      {
        name: 'conclusion',
        text: 'Bot Durumu',
        render: (props: NamedEntityControlSet) => {
          if (props.conclusion === BotConclusionEnum.Bot) {
            return <Lozenge appearance="error">Bot</Lozenge>
          }

          return <Lozenge appearance="success">Bot Değil</Lozenge>
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: NamedEntityControlSet) => (
          <LinkButton
            appearance="subtle-link"
            href={`/named-entity-control-set/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (namedEntityControlSet) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={namedEntityControlSet.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={namedEntityControlSet.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default NamedEntityControlSetList
