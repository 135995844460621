import React, { useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'
import MainLayout from 'layouts/main'
import userService, { User } from 'services/http/identity-server/user'

import { CreateUserProps } from './types'
import UserForm from './components/UserForm'

const CreateUser: React.FunctionComponent<CreateUserProps> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()

  const handleCreate = async (data: User) => {
    setIsLoading(true)

    try {
      const response = await userService.create(data)

      flagcard.add({
        title: 'Kullanıcı oluşturuldu',
        children:
          'Geçerli kullanıcı başarıyla oluşturuldu. Detay sayfasına yönlendiriliyorsunuz.',
      })

      if (response.data.id) {
        history.push(`/user/update/${response.data.id}`)
      }
    } catch {
      popup.add({
        title: 'Kullanıcı oluşturulamadı',
        children:
          'Kullanıcı oluşturulurken bir sorun oluştu lütfen tekrar deneyin.',
      })
    }

    setIsLoading(false)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Kullanıcılar" href="/users" />
      <BreadcrumbItem text="Kullanıcı oluştur" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Container width="narrow">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Sistem üzerine yeni bir kullanıcı oluştrunuz. Seçeceğiniz rol ve
                uygulamalar ile birlikte geçerli kullanıcının yeteneklerini
                belirleyebilirsiniz.
              </p>
            }
          >
            Kullanıcı oluştur
          </PageHeader>
          <UserForm
            loading={isLoading}
            values={{
              name: '',
              surname: '',
              phoneNumber: '',
              country: '',
              gender: '',
              birthDate: new Date().toISOString(),
              password: '',
              email: '',
              userName: '',
              roles: [],
              apps: [],
            }}
            operation="create"
            onSubmit={handleCreate}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default CreateUser
