import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, PageHeader, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Checkbox from '@zera-admin/checkbox'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { useFlagcardContext } from 'app/contexts/flagcard'

import countryService, {
  CountryPerceptionsResponse,
} from 'services/http/backoffice/country-perception'
import fieldServices from 'services/http/backoffice/field'

import { FieldLanguagesResponse } from 'services/http/backoffice/field/types'
import { DefinitionFormProps, SelectType, SocialMediaEnum } from '../types'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import backofficeDefinitionService from 'services/http/backoffice/definition'
import { CreateNewsResponse } from 'services/http/backoffice/definition/types'
import DateTimePicker from '@zera-admin/datetime-picker'
import { DateTimePickerHours } from 'app/shared/enums'

const News: React.FunctionComponent<DefinitionFormProps> = ({
  actions = [],
  onError,
  values,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>()

  const [languages, setLanguages] = useState<FieldLanguagesResponse>()
  const [countryPerceptions, setCountryPerceptions] =
    useState<CountryPerceptionsResponse>()
  const [periodicFetch, setPeriodicFetch] = useState<boolean>()
  const [languageValue, setLanguageValue] = useState<[]>()
  const [handleCount, setHandleCount] = useState<boolean>(false)
  const [environment, setEnvironment] = useState<any>()
  const [countData, setCountData] = useState<any>()
  const [dateError, setDateError] = useState<boolean>(false)

  const [dateFilter, setDateFilter] = useState<any>({
    dateSince: '',
    dateUntil: '',
  })
  const [database, setDatabase] = useState([
    { label: 'Neo4j', value: 'neo4j' },
    { label: 'Oracle', value: 'oracle' },
  ])
  const [sourceList, setSourceList] = useState([
    { label: 'NewsAPI', value: 'newsapi' },
    { label: 'NewsCatcher', value: 'newscatcher' },
    { label: 'GoogleNews', value: 'googlenews' },
    { label: 'BingNews', value: 'bingnews' },
  ])
  const [databaseValue, setDatabaseValue] = useState([])
  const [sourceValue, setSourceValue] = useState([])
  const [isSubmit, setIsSubmit] = useState<boolean>()
  const [blackListWord, setBlackListWord] = useState<string>('')

  const [flagcard] = useFlagcardContext()

  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  const resetform = () => {
    form.handleBatchUpdate({
      definitionValue: '',
      allowedLanguages: undefined,
      allowedDbs: undefined,
      useIncomingDefinition: false,
      definitionBlackLists: '',
      periodicFetch: false,
      period: undefined,
      startTime: undefined,
      endTime: undefined,
      newsSourceList: [],
      alarmStatus: undefined,
      environment: '',
    })
  }

  const changeCrawlingSource = (source: SocialMediaEnum) => {
    form.handleFieldChange('crawlingSource', source)
  }

  const handleNews = () => {
    changeCrawlingSource(SocialMediaEnum.News)
    form.handleBatchUpdate({
      crawlingType: 'fetch',
      definitionType: 'keyword',
      definitionValue: undefined,
      countryPerception: 1,
      allowedLanguages: undefined,
      allowedDbs: undefined,
      useIncomingDefinition: undefined,
      environment: 'Alice',
      definitionBlackLists: undefined,
      periodicFetch: undefined,
      period: undefined,
      startTime: undefined,
      endTime: undefined,
      newsSourceList: undefined,
      alarmStatus: 1,
    })
    // resetform()
  }

  const form = useForm<CreateNewsResponse>({
    initialValues: values,
    onSubmit: (props) => {
      handleSubmit(props)
      resetform()
    },
  })

  const handleSubmit = (props: FormHookSubmitProps<CreateNewsResponse>) => {
    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createNews(form.values)
          .then(() => {
            setCountData({})
            setIsSubmit(true)
            setLanguageValue([])
            setDatabaseValue([])
            setPeriodicFetch(false)
            setSourceValue([])
            setEnvironment('')
            resetform()

            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
          })
          .catch((error) => {
            console.log(error)
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  const renderPageHeader = () => {
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Tanımlama" href="/definition" />
        <BreadcrumbItem text="Tanımlama oluştur" href="/definition/create" />
        <BreadcrumbItem text="Haberler" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Haberler ile alakali tanımlama oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        // actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Haberler
      </PageHeader>
    )
  }
  function isOneMonthBefore(selectedDate: any) {
    var currentDate = new Date()
    var oneMonthBefore = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      currentDate.getDate()
    ).toISOString()

    return selectedDate <= oneMonthBefore
  }

  const handleGetCountryPerceptions = (
    pageSize: number = 0,
    pageIndex: number = 0,
    selectedItem?: any
  ) => {
    setIsLoading(true)
    countryService
      .getCountryPerceptions(1000, pageIndex)
      .then((res) => {
        setCountryPerceptions(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    handleGetCountryPerceptions()
    handleNews()
    setPeriodicFetch(false)
    setHandleCount(false)
  }, [])

  useEffect(() => {
    console.log(dateError)
  }, [dateError])

  const getFieldLanguages = () => {
    fieldServices
      .getFieldLanguages()
      .then((res) => {
        setLanguages(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {})
  }

  useEffect(() => {
    getFieldLanguages()
  }, [])

  const handleAddBlackListWord = () => {
    if (blackListWord) {
      form.handleFieldChange('definitionBlackLists', [
        ...(form.values.definitionBlackLists || []),
        blackListWord,
      ])
      setBlackListWord('')
    }
  }

  const handleRemoveBlackListWord = (index: number) => {
    form.handleFieldChange(
      'definitionBlackLists',
      form.values.definitionBlackLists?.filter((_, i) => i !== index)
    )
  }

  const renderContent = () => {
    const getCountryPerceptionList = (data: any): SelectType[] => {
      const countryPerceptionList: SelectType[] = []
      data?.data.forEach((per: any) =>
        countryPerceptionList.push({ label: per.description, value: per.key })
      )
      return countryPerceptionList
    }

    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormSection
            title="Genel bilgiler"
            description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
          >
            <Row>
              <Column xs={{ size: 4 }}>
                <Input
                  name="definitionValue"
                  placeholder="Kullanıcı adı giriniz"
                  description="Geçerli bir twitter kullanıcı adı giriniz"
                  onChange={(props) =>
                    form.handleFieldChange(
                      'definitionValue',
                      props.currentTarget.value
                    )
                  }
                  value={form?.values?.definitionValue}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 4 }}>
                <Select
                  label="Algı Seçiniz"
                  name="denemed"
                  options={getCountryPerceptionList(countryPerceptions)}
                  value={getCountryPerceptionList(countryPerceptions).filter(
                    (dataSetType: any) =>
                      dataSetType.value === form.values.countryPerception
                  )}
                  onChange={(props) => {
                    const selectedValue =
                      (props as SelectOptionProps).value || 1
                    form.handleFieldChange('countryPerception', selectedValue)
                  }}
                  placeholder="Seçim yapın"
                />
              </Column>
            </Row>

            <Row>
              <Column xs={{ size: 4 }}>
                <Select
                  label="Kaynak Listesi seçiniz"
                  name="newsSourceList"
                  options={sourceList}
                  onChange={(props: any) => {
                    setSourceValue(props)
                    form.handleFieldChange(
                      'newsSourceList',
                      (props as SelectOptionProps[]).map(
                        (source) => source.value
                      )
                    )
                  }}
                  placeholder="Seçim yapın"
                  isMultiple={true}
                  value={sourceValue}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 6 }}>
                <Input
                  name="definitionBlackLists"
                  placeholder="Anahtar kelime giriniz."
                  description="Blackliste eklemek istediginiz kelimeleri giriniz(Her kelimeden sonra , eklemeniz gerekmektedir)"
                  onChange={(props) => {
                    const inputString = props.currentTarget.value
                    const stringToArray = inputString.split(',')

                    form.handleFieldChange(
                      'definitionBlackLists',
                      stringToArray
                    )
                  }}
                  value={form?.values?.definitionBlackLists}
                />
              </Column>
            </Row>
            <>
              <Row>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="blackListWord"
                    label="Tanımlama Kara Listesi"
                    placeholder="Bir kara liste kelimesi giriniz"
                    description="Bir kara liste kelimesi giriniz"
                    value={blackListWord}
                    onChange={(props) => {
                      setBlackListWord(props.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4 }}>
                  <Button
                    onClick={handleAddBlackListWord}
                    appearance="primary"
                    children="Ekle"
                    style={{ marginTop: '16px' }}
                  />
                </Column>
              </Row>
              {form.values.definitionBlackLists?.map((word, index) => (
                <Row key={index}>
                  <Column xs={{ size: 4 }}>{word}</Column>
                  <Column>
                    <Button
                      onClick={() => handleRemoveBlackListWord(index)}
                      appearance="error"
                      children="Kaldır"
                      style={{ marginTop: '16px' }}
                    />
                  </Column>
                </Row>
              ))}
            </>
            <Row>
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="useIncomingDefinition"
                  label="Tanımlamadan gelen postlar direkt olarak bu tanımlama ile işaretlensin mi ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      props.currentTarget.checked
                    )
                  }}
                  isChecked={form?.values?.useIncomingDefinition}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="alarmStatus"
                  label="Çekilen verinin alarm olarak telegram, mail, sms vb. yardımıyla gönderilmesini istermisiniz?  "
                  onChange={(props) => {
                    form.handleFieldChange(
                      'alarmStatus',
                      props.currentTarget.checked === true ? 1 : 0
                    )
                  }}
                  isChecked={form?.values?.alarmStatus === 1 ? true : false}
                />
              </Column>
            </Row>

            <Row>
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="periodicFetch"
                  label="Periyodik olarak çekilsin mi?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      props.currentTarget.checked
                    )

                    setPeriodicFetch(props.currentTarget.checked)
                  }}
                  isChecked={form?.values?.periodicFetch}
                />
              </Column>
            </Row>

            {form.values.definitionType &&
              form.values.crawlingType === 'fetch' &&
              periodicFetch && (
                <Row>
                  <Column xs={{ size: 6 }}>
                    <Input
                      name="period"
                      placeholder="Periyot giriniz"
                      description="Geçerli bir periyot giriniz"
                      onChange={(props) =>
                        form.handleFieldChange(
                          'period',
                          props.currentTarget.value
                        )
                      }
                    />
                  </Column>
                </Row>
              )}
            <Row>
              <Column xs={{ size: 4 }}>
                <DateTimePicker
                  description="İşlenecek veri aralığının başlangıç tarihini seçiniz"
                  label="Seçili tarihten"
                  locale="tr"
                  name="startTime"
                  onChange={(props) => {
                    if (isOneMonthBefore(props)) {
                      setDateError(true)
                    } else {
                      setDateError(false)
                    }
                    form.handleFieldChange('startTime', props)
                    setDateFilter({ ...dateFilter, dateSince: props })
                  }}
                  type="default"
                  value={
                    dateFilter.dateSince === ''
                      ? new Date().toISOString()
                      : dateFilter.dateSince
                  }
                  times={hoursArray}
                />
              </Column>

              <Column xs={{ size: 4 }}>
                <DateTimePicker
                  description="İşlenecek veri aralığının bitiş tarihini seçiniz"
                  label="Seçili tarihe"
                  locale="tr"
                  name="endDate"
                  onChange={(props) => {
                    setDateError(false)
                    // setHandleCount(true)
                    form.handleFieldChange('endDate', props)
                    setDateFilter({ ...dateFilter, dateUntil: props })
                  }}
                  type="default"
                  value={
                    dateFilter.dateUntil === ''
                      ? new Date().toISOString()
                      : dateFilter.dateUntil
                  }
                  times={hoursArray}
                />
              </Column>
            </Row>
          </FormSection>
          <FormFooter>
            {form?.values?.startTime && (
              <Button
                appearance="primary"
                children="Tanımlama oluştur"
                isLoading={isLoading}
                type="submit"
              />
            )}
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderContent()}
      </Page>
    </MainLayout>
  )
}

export default News
