export const druidOperator = (type: string) => {
  switch (type) {
    case 'equal':
      return '='
    case 'notEqual':
      return '!='
    case 'greaterThan':
      return '>'
    case 'lessThan':
      return '<'
    case 'greaterThanOrEqual':
      return '>='
    case 'lessThanOrEqual':
      return '<='
    case 'in':
      return ' IN'
    default:
      return ''
  }
}

export const oracleOperator = (type: string) => {
  switch (type) {
    case 'equal':
      return ':='
    case 'notEqual':
      return '!='
    case 'greaterThan':
      return '>'
    case 'lessThan':
      return '<'
    case 'greaterThanOrEqual':
      return '>='
    case 'lessThanOrEqual':
      return '<='
    default:
      return ''
  }
}

export const value = (value: unknown, operator: string) => {
  switch (typeof value) {
    case 'string': {
      if (operator === 'notEmpty') {
        return ' IS NOT NULL'
      } else if (operator === 'isEmpty') {
        return ' IS NULL'
      } else {
        return `'${value}'`
      }
    }
    case 'object': {
      if (Array.isArray(value) && value.length) {
        if (operator === 'in') {
          return ` (${value.map((name: string) => `'${name}'`).join(',')})`
        }
        return `'${value[0]}'`
      }

      return value
    }
    default:
      return value
  }
}
