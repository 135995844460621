import React from 'react'
import { HashtagDetail } from '../../types'
import './hashtags.css'

export interface INews {
  data?: HashtagDetail[]
}

const News: React.FunctionComponent<INews> = ({ data }) => {
  const [width, setWidth] = React.useState(40)
  return (
    <div className="mainContainer">
      <div className="head">
        <div className="container">
          <div>
            <h2>Hashtagler</h2>
          </div>
          <div className="headContainer">
            <div className="headContent">
              <div className="circle deep"></div>
              <div className="negative">
                <p>Bot olmayan oranı</p>
              </div>
              <div className="border"></div>
            </div>

            <div className="headContent">
              <div className="circle blue"></div>
              <div className="positive">
                <p>Bot oranı</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cards">
        {data?.map((item, index) => {
          const count = item.postCountByUserBotDetection.filter(
            (x) => x.userBotDetection === 'NormalCount'
          )[0]?.count
          const botCount = item.postCountByUserBotDetection.filter(
            (x) => x.userBotDetection === 'BotCount'
          )[0]?.count
          return (
            <div className="card">
              <div className="cardContainer">
                <div>
                  <p style={{ marginTop: '2%' }}>
                    {' '}
                    {index + 1}#{item.hashtag}
                  </p>
                </div>
                <div className="chartLine">
                  <div
                    className="chartSection1"
                    style={{
                      width: (150 * count) / (count + botCount),
                    }}
                  >
                    % {((100 * count) / (count + botCount)).toFixed()}
                  </div>
                  <div className="chartSection2">
                    %{(100 - (100 * count) / (count + botCount)).toFixed()}
                  </div>
                </div>
              </div>
              <div className="row">
                <div>
                  <p className="circle deep"></p>
                </div>
                <div>
                  <p className="negative">{count}</p>
                </div>
              </div>
              <div className="row">
                <div>
                  <p className="circle blue"></p>
                </div>
                <div>
                  <p className="positive"> {botCount}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default News
