import styled from 'styled-components'

export const Key = styled.p`
  margin: 0;
  margin-left: 10px;
  font-weight: 600;
  & > span {
    font-weight: 100;
  }
`

export const ProfilImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  & * h4 {
    margin: 0;
  }
`
