import { Container, ContainerEntry } from 'app/library/layout-builder'

import Column from './Column'
import CustomComponent from './CustomComponent'
import Heading from './Heading/'
import Image from './Image'
import Page from './Page'
import Paragraph from './Paragraph/'
import Row from './Row'
import Section from './Section'
import WidgetComponent from './WidgetComponent'

import { ColumnPropertyDefinitions } from './definitions'

export const container = new Container([
  ContainerEntry.buildFromComponent(Column, {
    name: 'column',
    properties: ColumnPropertyDefinitions,
  }),
  ContainerEntry.buildFromComponent(Section, {
    name: 'section',
    properties: [],
  }),
  ContainerEntry.buildFromComponent(Page, {
    name: 'page',
    properties: [],
  }),
  ContainerEntry.buildFromComponent(Row, { name: 'row', properties: [] }),
  ContainerEntry.buildFromComponent(WidgetComponent, {
    name: 'widget',
    properties: [],
  }),
  ContainerEntry.buildFromComponent(Image, {
    name: 'image',
    properties: [],
  }),
  ContainerEntry.buildFromComponent(Heading, {
    name: 'heading',
    properties: [],
  }),
  ContainerEntry.buildFromComponent(Paragraph, {
    name: 'paragraph',
    properties: [],
  }),
  ContainerEntry.buildFromComponent(CustomComponent, {
    name: 'custom-component',
    properties: [],
  }),
])
