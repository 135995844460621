import styled from 'styled-components'

export const Loader = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  padding-top: calc(50vh - 100px);

  span {
    margin-top: 8px;
    font-weight: 500;
    font-size: 1.1rem;
  }
`
