import React, { useEffect, useState } from 'react'

import MainLayout from 'layouts/main'
import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, PageHeader, Row } from '@zera-admin/page'
import { RadioGroup } from '@zera-admin/radio'
import Button from '@zera-admin/button'
import Checkbox from '@zera-admin/checkbox'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { useFlagcardContext } from 'app/contexts/flagcard'

import countryService, {
  CountryPerceptionsResponse,
} from 'services/http/backoffice/country-perception'
import fieldServices from 'services/http/backoffice/field'
import definitionService from 'services/http/backoffice/definition'
import { FieldLanguagesResponse } from 'services/http/backoffice/field/types'
import TwitterProfile from 'components/twitter-profile'
import { crawlingType, definitionType } from './constant'
import { DefinitionFormProps, SelectType } from '../types'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import backofficeDefinitionService from 'services/http/backoffice/definition'
import { CreateTwitterResponse } from 'services/http/backoffice/definition/types'
import DateTimePicker from '@zera-admin/datetime-picker'
import { DateTimePickerHours } from 'app/shared/enums'
import { current30DaysBeforeString, currentDateString } from 'app/functions'
import { databaseOptions, streamLanguageOptions } from './constant'
import Icon from '@zera-admin/icon'

const Twitter: React.FunctionComponent<DefinitionFormProps> = ({
  onError,
  values,
}) => {
  const [newPerceptionChecked, setNewPerceptionChecked] =
    useState<boolean>(false)
  const [newPerception, setNewPerception] = useState<string>()
  const [definitionValue, setDefinitionValue] = useState<string>()
  const [blackListWord, setBlackListWord] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>()

  const [isLoadingTwitterProfile, setIsLoadingTwitterProfile] =
    useState<boolean>(false)
  const [languages, setLanguages] = useState<FieldLanguagesResponse>()
  const [account, setAccount] = useState<any[]>([])
  const [countryPerceptions, setCountryPerceptions] =
    useState<CountryPerceptionsResponse>()
  const [languageValue, setLanguageValue] = useState<[]>()
  const [countData, setCountData] = useState<any>()
  const [flagcard] = useFlagcardContext()
  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  const form = useForm<CreateTwitterResponse>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
  })

  const formatDateInput = (value: string): string => {
    const cleaned = value.replace(/\D/g, '') // Sadece rakamları al
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})$/) // GGAAYYYY eşleşmesini kontrol et

    if (match) {
      return `${match[1]}/${match[2]}/${match[3]}` // GG/AA/YYYY formatında döndür
    }
    return value // Eşleşme yoksa olduğu gibi döndür
  }

  const formatDateInputWithDots = (value: string): string => {
    const cleaned = value.replace(/\D/g, '') // Sadece rakamları al
    const match = cleaned.match(/^(\d{1,2})(\d{1,2})?(\d{1,4})?$/) // GGAAYYYY eşleşmesini kontrol et

    if (match) {
      let formattedValue = match[1]
      if (match[2]) {
        formattedValue += `.${match[2]}`
      }
      if (match[3]) {
        formattedValue += `.${match[3]}`
      }
      return formattedValue // GG.AA.YYYY formatında döndür
    }
    return value // Eşleşme yoksa olduğu gibi döndür
  }

  // Giriş değişikliğini işleme
  const handleInputChange = (field: string, e: any): void => {
    const formattedValue = formatDateInputWithDots(e.target.value)
    form.handleFieldChange(field, formattedValue)
  }

  const handleTwitter = () => {
    form.handleBatchUpdate({
      crawlingType: form?.values.crawlingType,
      definitionValues: undefined,
      definitionType: undefined,
      historicalFetch: undefined,
      fetchComment: undefined,
      streamAllowReplies: undefined,
      period: undefined,
      periodicFetch: false,
      newPerception: undefined,
      timeIntervalInHours: undefined,
      updateTimeParametersByTimeInterval: undefined,
      countryPerception: 1,
      allowedDbs: undefined,
      allowedLanguages: undefined,
      environment: 'Alice',
      definitionBlackLists: [],
      useIncomingDefinition: false,
      fetchSince: undefined,
      fetchUntil: undefined,
      changeTimeIntervalByRefreshInterval: undefined,
      conversationId: undefined,
      streamLanguage: undefined,
    })
  }

  useEffect(() => {
    form.handleBatchUpdate({
      crawlingType: form?.values.crawlingType,
      definitionValues: undefined,
      definitionType: undefined,
      historicalFetch: undefined,
      fetchComment: undefined,
      streamAllowReplies: undefined,
      period: undefined,
      periodicFetch: false,
      newPerception: undefined,
      timeIntervalInHours: undefined,
      updateTimeParametersByTimeInterval: undefined,
      countryPerception: 1,
      allowedDbs: undefined,
      allowedLanguages: undefined,
      environment: 'Alice',
      definitionBlackLists: [],
      useIncomingDefinition: false,
      fetchSince: undefined,
      fetchUntil: undefined,
      changeTimeIntervalByRefreshInterval: undefined,
      conversationId: undefined,
      streamLanguage: undefined,
    })
  }, [form.values.crawlingType])

  useEffect(() => {
    form.handleBatchUpdate({
      crawlingType: form?.values.crawlingType,
      definitionValues: undefined,
      definitionType: form?.values.definitionType,
      historicalFetch: undefined,
      fetchComment: undefined,
      streamAllowReplies: undefined,
      period: undefined,
      periodicFetch: false,
      newPerception: undefined,
      timeIntervalInHours: undefined,
      updateTimeParametersByTimeInterval: undefined,
      countryPerception: 1,
      allowedDbs: undefined,
      allowedLanguages: undefined,
      environment: 'Alice',
      definitionBlackLists: [],
      useIncomingDefinition: false,
      fetchSince: undefined,
      fetchUntil: undefined,
      changeTimeIntervalByRefreshInterval: undefined,
      conversationId: undefined,
      streamLanguage: undefined,
    })
  }, [form.values.definitionType])

  const handleSubmit = (props: FormHookSubmitProps<CreateTwitterResponse>) => {
    if (!props.errors) {
      if (form.values) {
        if (
          form?.values?.crawlingType === 'stream' &&
          !form?.values?.streamLanguage
        ) {
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'Akış dili seçiniz',
          })

          return
        }
        if (form?.values?.definitionValues?.length > 0) {
          backofficeDefinitionService
            .createTwitter(form.values)
            .then(() => {
              setCountData({})
              setLanguageValue([])

              flagcard.add({
                appearance: 'success',
                title: 'Kayıt işlemi başarılı',
                children:
                  'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
              })
              form.handleReset()
              setTimeout(() => {
                window.location.reload()
              }, 400)
            })
            .catch((error) => {
              console.log(error)
              flagcard.add({
                appearance: 'error',
                title: 'Bir hata oluştu',
                children:
                  'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
              })
            })
        } else {
          console.log(form?.values?.definitionValues, 'definitionValues')
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'En az bir tanımlama değeri giriniz',
          })
        }
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  const countryPerceptionData = {
    customValue: '',
    fromCountry: '',
    toCountry: '',
    description: '',
    sendAsAlarm: true,
  }

  const handleCreateCountryPerception = (data: string) => {
    countryPerceptionData.customValue = data

    countryService
      .createCountryPerception(countryPerceptionData)
      .then((res) => {
        setIsLoading(false)
        handleGetCountryPerceptions(0, 0, res.data.data)
        setNewPerceptionChecked(false)
      })
      .catch((err) => {
        console.log(err)
        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children: 'Yeni algı eklenirken hata oluştu.',
        })
      })
      .finally(() => {
        setIsLoading(false)
        flagcard.add({
          appearance: 'success',
          title: 'Başarılı',
          children: 'Yeni algı başarıyla eklendi.',
        })
      })
  }

  const mapResponseToTwitterProfile = (res: any) => {
    const response = res?.accountInfo
    return {
      CreatedAt: response.created_at,
      Description: response.description,
      EntityKey: response.id,
      FavoritesCount: response.public_metrics.like_count,
      FollowersCount: response.public_metrics.followers_count,
      FriendsCount: response.public_metrics.following_count,
      Name: response.name,
      ProfileImageUrl: response.profile_image_url,
      Protected: response.protected,
      Location: response.location,
      Verified: response.verified,
      ScreenName: response.username,
      StatusesCount: response.public_metrics.tweet_count,
    }
  }

  const handleCheckDefinition = () => {
    setIsLoadingTwitterProfile(true)
    definitionService
      .checkDefinition(definitionValue || '')
      .then((res) => {
        if (res?.code === 200) {
          console.log(res, 'res checkDefinition')

          setAccount([...account, mapResponseToTwitterProfile(res.data)])

          form.handleFieldChange('definitionValues', [
            ...(form.values.definitionValues || []),
            definitionValue,
          ])

          console.log(form.values.definitionValues, 'definitionValues')

          flagcard.add({
            appearance: 'success',
            title: 'Başarılı',
            children: 'Tanımlama başarıyla doğrulandı.',
          })
          onKeywordToDefinitionValue()
        } else {
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'Tanımlama doğrulanamadı.',
          })
        }
        setIsLoadingTwitterProfile(false)
      })
      .catch((err) => {
        console.log(err)
        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children: 'Tanımlama doğrulanırken hata oluştu.',
        })
      })
      .finally(() => {
        setIsLoadingTwitterProfile(false)
      })
  }

  const onKeywordToDefinitionValue = () => {
    const currentValue: string[] = []
    const currentDefinitionValue = form.values.definitionValues
    if (currentDefinitionValue && currentDefinitionValue?.length > 0) {
      currentValue.push(...currentDefinitionValue)
    }
    if (definitionValue) {
      currentValue.push(definitionValue)
      setDefinitionValue('')
    }
    form.handleFieldChange('definitionValues', currentValue)
  }

  const onKeywordToDefinitionValueRemove = (index: number) => {
    const currentDefinitionValue = [...form.values.definitionValues]

    if (currentDefinitionValue && currentDefinitionValue?.[index]) {
      currentDefinitionValue.splice(index, 1)
    }
    form.handleFieldChange('definitionValues', currentDefinitionValue)
  }

  const handleRemoveAccount = (index: number) => {
    const currentDefinitionValue = [...form.values.definitionValues]
    const currentAccount = [...account]

    if (currentDefinitionValue && currentDefinitionValue?.[index]) {
      currentDefinitionValue.splice(index, 1)
      currentAccount.splice(index, 1)
    }
    form.handleFieldChange('definitionValues', currentDefinitionValue)
    setAccount(currentAccount)
  }

  const handleGetCountryPerceptions = (
    pageSize: number = 0,
    pageIndex: number = 0,
    selectedItem?: any
  ) => {
    setIsLoading(true)
    countryService
      .getCountryPerceptions(1000, pageIndex)
      .then((res) => {
        setCountryPerceptions(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children: 'Algılar yüklenirken hata oluştu.',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    handleGetCountryPerceptions()
    handleTwitter()
    form.handleFieldChange('periodicFetch', false)
  }, [])

  const getFieldLanguages = () => {
    fieldServices
      .getFieldLanguages()
      .then((res) => {
        setLanguages(res.data)
      })
      .catch((err) => {
        console.log(err)
        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children: 'Diller yüklenirken hata oluştu.',
        })
      })
      .finally(() => {})
  }

  useEffect(() => {
    getFieldLanguages()
  }, [])

  const getCountryPerceptionList = (
    countryPerceptions: CountryPerceptionsResponse | undefined
  ) => {
    return (
      countryPerceptions?.data.map((perception: any) => ({
        label: perception.description,
        value: perception.key,
      })) || []
    )
  }

  const handleAddBlackListWord = () => {
    if (blackListWord) {
      const currentBlackLists = form.values.definitionBlackLists || []
      form.handleFieldChange('definitionBlackLists', [
        ...currentBlackLists,
        blackListWord,
      ])
      setBlackListWord('')
    }
  }

  const handleRemoveBlackListWord = (index: number) => {
    const currentBlackLists = form?.values?.definitionBlackLists
      ? [...form.values.definitionBlackLists]
      : []
    if (currentBlackLists && currentBlackLists?.[index]) {
      currentBlackLists.splice(index, 1)
    }
    form.handleFieldChange('definitionBlackLists', currentBlackLists)
  }

  const renderFormFields = () => {
    return (
      <>
        <Row>
          <Column>
            <RadioGroup
              label="Crawling Tipi"
              isRequired={true}
              name="crawlingType"
              onChange={(props) => {
                setCountData({})
                form.handleFieldChange(
                  'crawlingType',
                  props.currentTarget.value
                )
              }}
              options={crawlingType}
            />
          </Column>
        </Row>
        {form?.values?.crawlingType && (
          <Row>
            <Column xs={{ size: 12 }}>
              <RadioGroup
                label="Tanımlama Tipi"
                name="definitionType"
                isRequired={true}
                onChange={(props) => {
                  setCountData({})
                  form.handleFieldChange(
                    'definitionType',
                    props.currentTarget.value
                  )
                }}
                options={
                  form?.values?.crawlingType === 'fetch'
                    ? definitionType
                    : definitionType
                        .filter((item) => item.value !== 'reply')
                        .filter((item) => item.value !== 'favorite')
                        .filter((item) => item.value !== 'user-favorite')
                        .filter((item) => item.value !== 'follower')
                        .filter((item) => item.value !== 'friend')
                }
              />
            </Column>
          </Row>
        )}
        {form?.values?.definitionType && (
          <>
            <Row xs={{ justify: 'center', align: 'center' }}>
              <Column xs={{ size: 4 }}>
                <Input
                  name="definitionValue"
                  label="Tanımlama Değeri"
                  placeholder={
                    form.values.definitionType === 'account'
                      ? 'Kullanıcı adı giriniz'
                      : 'Bir tanımlama değeri giriniz'
                  }
                  description={
                    form.values.definitionType === 'account'
                      ? 'Geçerli bir X kullanıcı adı giriniz'
                      : 'Bir tanımlama değeri giriniz'
                  }
                  value={definitionValue}
                  onChange={(props) => {
                    setDefinitionValue(props.currentTarget.value)
                  }}
                />
              </Column>
              <Column>
                <Button
                  onClick={handleCheckDefinition}
                  appearance="primary"
                  children="Doğrula"
                  isLoading={isLoadingTwitterProfile}
                  style={{ marginTop: '16px' }}
                />
              </Column>
            </Row>
            {form?.values?.definitionValues?.map((d: any, index: number) =>
              form?.values?.definitionType === 'account' ? null : (
                <Row key={index}>
                  <Column xs={{ size: 4 }}>{d}</Column>
                  <Column>
                    <Button
                      onClick={() => onKeywordToDefinitionValueRemove(index)}
                      appearance="error"
                      children="Kaldır"
                      style={{ marginTop: '16px' }}
                    />
                  </Column>
                </Row>
              )
            )}

            {form?.values?.definitionValues?.length > 0 &&
              form?.values?.definitionType === 'account' &&
              account.map((item, index) => (
                <Row key={index}>
                  <Column xs={{ size: 12 }}>
                    <TwitterProfile data={item} />
                    <Button
                      onClick={() => handleRemoveAccount(index)}
                      appearance="error"
                      children="Kaldır"
                      style={{ marginTop: '16px' }}
                    />
                  </Column>
                </Row>
              ))}

            <Row>
              <Column xs={{ size: 4 }}>
                <Select
                  label="Algı Seçiniz(*)"
                  name="countryPerception"
                  options={getCountryPerceptionList(countryPerceptions)}
                  value={getCountryPerceptionList(countryPerceptions).filter(
                    (dataSetType: any) =>
                      dataSetType.value === form.values.countryPerception
                  )}
                  onChange={(props) => {
                    const selectedValue =
                      (props as SelectOptionProps).value || 1
                    form.handleFieldChange('countryPerception', selectedValue)
                  }}
                  placeholder="Seçim yapın"
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="newPerception"
                  label="Yeni Algı eklemek ister misiniz?"
                  onChange={(props) => {
                    setNewPerceptionChecked(props.currentTarget.checked)
                  }}
                />
              </Column>
            </Row>
            {newPerceptionChecked && (
              <Row>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="newPerception"
                    placeholder="Bir algı giriniz"
                    description="Bir algı giriniz"
                    onChange={(props) => {
                      setNewPerception(props.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4 }}>
                  <Button
                    onClick={() =>
                      handleCreateCountryPerception(newPerception!)
                    }
                    appearance="primary"
                    children="Ekle"
                    isLoading={isLoading}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 4 }}>
                <Select
                  label="Dil Seçiniz"
                  name="allowedLanguages"
                  value={languageValue}
                  options={
                    languages?.data
                      ? languages.data.map((lang: string) => {
                          return { label: lang, value: lang }
                        })
                      : []
                  }
                  onChange={(props: any) => {
                    form.handleFieldChange(
                      'allowedLanguages',
                      (props as SelectOptionProps[]).map(
                        (language) => language.value
                      )
                    )
                  }}
                  placeholder="Seçim yapın"
                  isMultiple={true}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 4 }}>
                <Select
                  label="Veritabanı seçiniz(*)"
                  name="dataset"
                  options={[
                    { label: 'Neo4j', value: 'neo4j' },
                    { label: 'Oracle', value: 'oracle' },
                  ]}
                  onChange={(props: any) => {
                    form.handleFieldChange(
                      'allowedDbs',
                      (props as SelectOptionProps[]).map(
                        (database) => database.value
                      )
                    )
                  }}
                  placeholder="Seçim yapın"
                  isMultiple={true}
                  value={databaseOptions.filter((item) =>
                    form?.values?.allowedDbs?.includes(item.value)
                  )}
                />
              </Column>
            </Row>

            {form.values.crawlingType === 'fetch' &&
              form.values.definitionType === 'reply' && (
                <Row>
                  <Column xs={{ size: 6 }}>
                    <Input
                      name="conversationId"
                      placeholder="conversationId giriniz"
                      description="Geçerli bir conversationId giriniz"
                      onChange={(props) => {
                        form.handleFieldChange(
                          'conversationId',
                          props.currentTarget.value
                        )
                      }}
                    />
                  </Column>
                </Row>
              )}

            {((form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'account') &&
              form.values.crawlingType === 'fetch') ||
            (form?.values?.crawlingType === 'stream' &&
              form?.values?.historicalFetch) ? (
              <>
                <Row>
                  <Column xs={{ size: 4 }}>
                    <DateTimePicker
                      description="İşlenecek veri aralığının bitiş tarihini seçiniz"
                      label="Seçili tarihten"
                      locale="tr"
                      name="fetchSince"
                      onChange={(props) => {
                        const formattedProps =
                          typeof props === 'string'
                            ? formatDateInputWithDots(props)
                            : props

                        form.handleFieldChange('fetchSince', formattedProps)
                      }}
                      type="default"
                      datePickerProps={{
                        maxDate:
                          form?.values?.fetchUntil && form?.values?.fetchUntil,
                        minDate: '2006-03-21 03:00',

                        parseInputValue: (
                          inputValue: string,
                          dateFormat: string
                        ) => {
                          // Sadece rakamları al
                          const cleaned = inputValue.replace(/\D/g, '')
                          let formattedDate = cleaned

                          if (cleaned.length > 2) {
                            formattedDate = `${cleaned.slice(
                              0,
                              2
                            )}.${cleaned.slice(2)}` // GG.
                          }
                          if (cleaned.length > 4) {
                            formattedDate = `${cleaned.slice(
                              0,
                              2
                            )}.${cleaned.slice(2, 4)}.${cleaned.slice(4)}` // GG.AA.YYYY
                          }

                          // Tarih nesnesine dönüştür
                          const [day, month, year] = formattedDate.split('.')
                          if (day && month && year) {
                            return new Date(
                              Number(year),
                              Number(month) - 1,
                              Number(day)
                            ) // Date nesnesi oluştur
                          }

                          // Eğer tarih geçerli değilse, bugünün tarihini döndür
                          return new Date()
                        },
                      }}
                      value={
                        form?.values?.fetchSince
                          ? form?.values?.fetchSince
                          : current30DaysBeforeString()
                      }
                      times={hoursArray}
                    />
                  </Column>
                  <Column xs={{ size: 4 }}>
                    <DateTimePicker
                      description="İşlenecek veri aralığının bitiş tarihini seçiniz"
                      label="Seçili tarihe"
                      locale="tr"
                      name="fetchUntil"
                      parseInputValue={(value: string, format: string) =>
                        new Date(formatDateInput(value))
                      }
                      onChange={(props) => {
                        const formattedProps =
                          typeof props === 'string'
                            ? formatDateInputWithDots(props)
                            : props

                        form.handleFieldChange('fetchUntil', formattedProps)
                      }}
                      type="default"
                      value={
                        form?.values?.fetchUntil
                          ? form?.values?.fetchUntil
                          : currentDateString()
                      }
                      datePickerProps={{
                        minDate: form?.values?.fetchSince
                          ? form?.values?.fetchSince
                          : '2006-03-21 03:00',
                        maxDate: currentDateString(),

                        parseInputValue: (
                          inputValue: string,
                          dateFormat: string
                        ) => {
                          // Sadece rakamları al
                          const cleaned = inputValue.replace(/\D/g, '')
                          let formattedDate = cleaned

                          if (cleaned.length > 2) {
                            formattedDate = `${cleaned.slice(
                              0,
                              2
                            )}.${cleaned.slice(2)}` // GG.
                          }
                          if (cleaned.length > 4) {
                            formattedDate = `${cleaned.slice(
                              0,
                              2
                            )}.${cleaned.slice(2, 4)}.${cleaned.slice(4)}` // GG.AA.YYYY
                          }

                          // Tarih nesnesine dönüştür
                          const [day, month, year] = formattedDate.split('.')
                          if (day && month && year) {
                            return new Date(
                              Number(year),
                              Number(month) - 1,
                              Number(day)
                            ) // Date nesnesi oluştur
                          }

                          // Eğer tarih geçerli değilse, bugünün tarihini döndür
                          return new Date()
                        },
                      }}
                      times={hoursArray}
                    />
                  </Column>
                </Row>
              </>
            ) : null}

            {form?.values?.crawlingType === 'stream' && (
              <Row>
                <Column xs={{ size: 4 }}>
                  <Select
                    label="Akış dili"
                    name="streamLanguage"
                    options={streamLanguageOptions}
                    value={streamLanguageOptions?.filter(
                      (item: any) => item.value === form.values.streamLanguage
                    )}
                    onChange={(props) => {
                      const selectedValue =
                        (props as SelectOptionProps).value || 1
                      form.handleFieldChange('streamLanguage', selectedValue)
                    }}
                    placeholder="Seçim yapın"
                  />
                </Column>
              </Row>
            )}

            <>
              <Row>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="blackListWord"
                    label="Tanımlama Kara Listesi"
                    placeholder="Bir kara liste kelimesi giriniz"
                    description="Bir kara liste kelimesi giriniz"
                    value={blackListWord}
                    onChange={(props) => {
                      setBlackListWord(props.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4 }}>
                  <Button
                    onClick={handleAddBlackListWord}
                    appearance="primary"
                    children="Ekle"
                    style={{ marginTop: '16px' }}
                  />
                </Column>
              </Row>
              {form.values.definitionBlackLists?.map((word, index) => (
                <Row key={index}>
                  <Column xs={{ size: 4 }}>{word}</Column>
                  <Column>
                    <Button
                      onClick={() => handleRemoveBlackListWord(index)}
                      appearance="error"
                      children="Kaldır"
                      style={{ marginTop: '16px' }}
                    />
                  </Column>
                </Row>
              ))}
            </>

            {form?.values?.definitionType === 'friend' ||
            form?.values?.definitionType === 'follower' ||
            form?.values?.definitionType === 'user-favorite' ||
            form?.values?.definitionType === 'favorite' ? (
              <Row>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="limit"
                    type="number"
                    placeholder="Limit giriniz"
                    description="Limit giriniz"
                    value={form.values.limit}
                    onChange={(props) => {
                      form.handleFieldChange('limit', props.currentTarget.value)
                    }}
                  />
                </Column>
              </Row>
            ) : null}

            {form?.values?.definitionType === 'account' &&
            form.values.crawlingType === 'fetch' ? (
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="updateTimeParametersByTimeInterval"
                  label="Zaman parametreleri zaman aralığına göre güncellensin mi?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'updateTimeParametersByTimeInterval',
                      props.currentTarget.checked
                    )
                  }}
                />
              </Column>
            ) : null}

            {form.values.crawlingType === 'fetch' &&
              form?.values?.updateTimeParametersByTimeInterval && (
                <Row>
                  <Column xs={{ size: 6 }}>
                    <Input
                      name="timeIntervalInHours"
                      placeholder="Aralık giriniz"
                      description="Geçerli bir aralık giriniz"
                      onChange={(props) => {
                        form.handleFieldChange(
                          'timeIntervalInHours',
                          props.currentTarget.value
                        )
                      }}
                    />
                  </Column>
                </Row>
              )}

            {(form?.values?.definitionType === 'account' ||
              form?.values?.definitionType === 'keyword' ||
              form?.values?.definitionType === 'reply') &&
            form.values.crawlingType === 'fetch' ? (
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="periodicFetch"
                  label="Periyodik olarak çekilsin mi ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      props.currentTarget.checked
                    )
                  }}
                />
              </Column>
            ) : null}

            {form.values.definitionType &&
              form.values.crawlingType === 'fetch' &&
              form?.values?.periodicFetch && (
                <Row>
                  <Column xs={{ size: 6 }}>
                    <Input
                      name="period"
                      placeholder="Periyot giriniz"
                      description="Geçerli bir periyot giriniz"
                      onChange={(props) => {
                        form.handleFieldChange(
                          'period',
                          props.currentTarget.value
                        )
                      }}
                    />
                  </Column>
                </Row>
              )}

            {form.values.crawlingType === 'stream' &&
              form.values.definitionType && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="historicalFetch"
                    label="Stream taskının bir de fetch taskı oluşsun mu?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'historicalFetch',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}
            {form.values.definitionType !== 'friend' &&
              form.values.definitionType !== 'follower' &&
              form.values.definitionType !== 'user-favorite' &&
              form.values.definitionType !== 'favorite' && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="useIncomingDefinition"
                    label="Tanımlamadan gelen postlar direkt olarak bu tanımlama ile işaretlensin mi?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'useIncomingDefinition',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}

            {form.values.definitionType === 'account' &&
              form.values.crawlingType === 'stream' && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="streamAllowReplies"
                    label="Tanımlamadan gelen yanıtlara izin verilsin mi?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'streamAllowReplies',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}

            {form.values.definitionType === 'account' && (
              <Column xs={{ size: 12 }}>
                <Checkbox
                  name="fetchComment"
                  label="Tanımlamadan gelen postların bir de reply taskları oluşturulsun mu?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'fetchComment',
                      props.currentTarget.checked
                    )
                  }}
                />
              </Column>
            )}
            {form.values.crawlingType === 'fetch' &&
              form.values.definitionType === 'keyword' && (
                <Column xs={{ size: 12 }}>
                  <Checkbox
                    name="changeTimeIntervalByRefreshInterval"
                    label="Yenileme aralığına göre zaman aralığını değiştirmek ister misiniz?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'changeTimeIntervalByRefreshInterval',
                        props.currentTarget.checked
                      )
                    }}
                  />
                </Column>
              )}

            {countData && countData?.resultByDates && (
              <div style={{ marginTop: '2vh' }}>
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <Column xs={{ size: 4 }}>
                    <span style={{ fontSize: '1rem', fontWeight: 600 }}>
                      Zaman Aralığı ve Değerleri:
                    </span>
                  </Column>
                  <Column xs={{ size: 12 }}>
                    <span style={{ fontSize: '0.9rem', fontWeight: 600 }}>
                      Toplam Sayı:
                    </span>
                    <span>&nbsp;{countData.totalCount}</span>
                  </Column>
                  {countData.resultByDates
                    ?.filter((item: any) => item.count > 0)
                    .map((item: any) => (
                      <div
                        style={{
                          borderBottom: '1px solid black',
                          width: '30%',
                          marginTop: '2vh',
                        }}
                      >
                        <Row>
                          <Column xs={{ size: 6 }}>
                            <span
                              style={{ fontSize: '0.85rem', fontWeight: 600 }}
                            >
                              Değer:
                            </span>
                            &nbsp;{item.count}
                          </Column>
                        </Row>
                        <Row>
                          <Column xs={{ size: 9 }}>
                            <span
                              style={{ fontSize: '0.85rem', fontWeight: 600 }}
                            >
                              Zaman Aralığı:
                            </span>
                            &nbsp;{item.timePeriod}
                          </Column>
                        </Row>
                      </div>
                    ))}
                </Row>
              </div>
            )}
          </>
        )}
      </>
    )
  }

  const renderContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormSection
            title="Genel bilgiler"
            description="Form üzerinden aşağıdaki genel bilgileri doldurunuz."
          >
            {renderFormFields()}
          </FormSection>
          <FormFooter align="start">
            <Button
              appearance="primary"
              children="Tanımlama oluştur"
              isDisabled={!form.values.definitionType}
              isLoading={isLoading}
              iconBefore={<Icon name="add-item" />}
              type="submit"
              style={{ marginTop: '16px' }}
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  const renderPageHeader = () => {
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Tanımlama" href="/definition" />
        <BreadcrumbItem text="Tanımlama oluştur" href="/definition/create" />
        <BreadcrumbItem text="X" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        X ile alakali tanımlama oluşturabilir, varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader bottomBar={bottomBar} breadcrumbs={breadcrumbs}>
        X
      </PageHeader>
    )
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderContent()}
      </Page>
    </MainLayout>
  )
}

export default Twitter
