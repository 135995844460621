import styled from 'styled-components'

export const SocialCountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  align-self: center;
  &.print {
    align-items: center;
    align-self: center;
    align-content: center;
  }
  .update-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`

export const SocialCountsContentContainer = styled.div`
  width: 90%;
  display: flex;
  padding-bottom: 4rem;
  padding-left: 2rem;
  gap: 2rem;

  .twitter-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .reddit-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .quora-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .mastodon-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .instagram-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .hidden {
    display: none;
  }

  .tiktok-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .youtube-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .telegram-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  @media screen and (min-width: 1920px) {
    gap: 6rem;
    justify-content: center;
  }
`
