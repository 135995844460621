import { request } from 'services/http/backoffice/instance'

import type { ManualAlarm, CreateManualAlarmRequest } from './types'

export const create = (data: CreateManualAlarmRequest) =>
  request<ManualAlarm>({
    method: 'post',
    url: 'back-office/alarm-keyword/manual-alarm',
    data: { ...data, sendWithAlarmName: true },
  })
