export const britishActiveUser = [
  {
    F1: [
      'CarlosLoret',
      'ScuderiaFerrari',
      'tribundergi',
      'ValtteriBottas',
      'SchumacherMick',
      'zeekay15',
      'HulisaniRavele',
      'panchosagredo',
      'SophieASlut',
      'davidcoverdale',
      'PabloCarrilloL',
      'memobarba',
      'okay_karacan',
      'ssporttr',
      'arr1910',
      'sebvettelnews',
      'VerstappenCOM',
    ],
  },
  {
    ValtteriBottas: [
      'MercedesAMGF1',
      'PET_Motorsports',
      'pirellisport',
      'yeniacikcom',
      'Alihanna_Liadon',
      'Fatouu_S',
      'rnaudmeunier',
      'vividcloudofwat',
      'mjnonk663',
      'Formula1circus',
      'Formula1arg1',
      'tiffanycromwell',
      'Corpo_Insider',
      'Canduh_',
      'owenkk7',
      'xabatis',
      'TinotendaMachi6',
      'f1miami',
      'fortius0',
    ],
  },
  {
    tompeck: [
      'Independent',
      'mrjamesob',
      'campbellclaret',
      'sidlowe',
      'IanDunt',
      'RalfLittle',
      'doctor_oxford',
      'adamboultonSKY',
      'paulwaugh',
      'domjoly',
      'PickardJE',
      'amateuradam',
      'DPJHodges',
      'StevePeers',
      'chrischirp',
      'SimonCalder',
      'JohnRentoul',
      'NickCohen4',
      'JMPSimor',
      'newschambers',
    ],
  },
  {
    IFazilaBaloch: [
      'MeghanMcCain',
      'FForotan',
      'jihadwatchRS',
      'corinnamilborn',
      'calxandr',
      'VictoriousNamo',
      'FaysalSaryldz',
      'olum_gulumse',
      'ryanmauro',
      'IFazilaBaloch',
      'Gasteci_Eskin',
      'PeldaEhmed',
      'A_ProudCivilian',
      'littlebytesnews',
      'KavehGhoreishi',
      'basuri_welat33E',
      'juhasaarinen',
      'Sapna_3',
      'KahkashanHaide3',
      'ActusPays',
    ],
  },
  {
    lowkidowki: [
      'Rumay_Chian',
      'YUNGYlFF',
      'ManiaCousin',
      'triplebrownmeow',
      'cjafterdark',
      'GDAutoArt',
      'WeegeepieYT',
      'agent_mcgrath',
      'AeroArtwork',
      'Trickster____',
      'BerserkerVEVO',
      'hansamalospayas',
      'JenukiDa',
      'CutieMonica',
      'DumbCerb',
      'pichi08_',
      'reshirii',
      'Fiddledo',
      'Spooky_Sal',
      'SonicBasedSizi',
      'jeeble_s',
    ],
  },
  {
    amjadt25: [
      'drr_alklaam',
      'mayssamaghrebi',
      'amjadt25',
      'Gg5k0kHD2MnQBi5',
      'yhya_jaber',
      'omsalem70',
      'AbdullahMMugrin',
      'drgalalhatem',
      'irinatsukerman_',
      's_tw_s',
      '3_i_n',
      'hanisaadi2011',
      'Binaliuae',
      'zahrh_alwfa',
      'HilaliUae',
      'bo_mohsin_',
      'Thani75',
      'kaabi_202',
      'Azo0z__aj',
      'PhExlNBVdgTcQjd',
      'AbdullaAldhahri',
    ],
  },
  {
    victorabadf1: [
      'victorabadf1',
      'Teacher1Rosy',
      'GaboLC',
      'itsaalee22',
      'GAYAVACA',
      'David2095_Sport',
      'crls8995',
      'XtianPortu',
      'ad_Morato',
      'PedroMarekiao',
      'konaitor99',
      'lopez9346',
      'poldiazz',
      'dapifretes',
      'tessaff_99',
      'AngelicaCorzo_',
      '17Formulero',
      'HidalgoJoni',
      'karina_zeballos',
      'Fitness_vid',
    ],
  },
  {
    McLarenF1: [
      'ZBrownCEO',
      'caglayankahveic',
      'yeniacikcom',
      'Motorsport_LAT',
      'farouq_yahaya',
      'bitcicom',
      'localman254',
      'es_Motorsport',
      'hakana',
      'playsporcom',
      'rnaudmeunier',
      'fantasticoF1',
      'Thee_mavERIC',
      'McLarenFamily',
      'BATplc',
      'moncet',
      'Canduh_',
      'TinotendaMachi6',
      'castrojr76',
      'fortius0',
    ],
  },
  {
    RMCunliffe: [
      'piersmorgan',
      'michaelbd',
      'DuncanWeldon',
      'lastpositivist',
      'ruth_wishart',
      'dr_shibley',
      'RMCunliffe',
      '_Rian_Stone',
      'BDStanley',
      'ImIncorrigible',
      'cjayanetti',
      'Zoah_HS',
      'sidcelery',
      'robkroese',
      'DarrenJohnson66',
      'DJSkelton',
      'sammurraytory',
      'SP_Duckworth',
      'nuwandiss',
      'TomHulme79',
    ],
  },
  {
    IsabelOakeshott: [
      'CatharineHoey',
      'MichelleDewbs',
      'MalloryOnTravel',
      'BernieSpofforth',
      'TheHirstComment',
      'Unnamedinsider',
      'LouiseRawAuthor',
      'ExquisiteLadys',
      'Stop_The_EU',
      'darcybbc',
      'Coconutinbahama',
      'MikeSwadling',
      'dr_shibley',
      'PatWilliams1944',
      'CromwellStuff',
      'DailyESSEX',
      'Life_Disrupted',
      'SpanishDan1',
      'ThomasEvansAdur',
      'miffythegamer',
    ],
  },
  {
    TheBishF1: [
      'wbuxtonofficial',
      'Sathnam',
      'RespectIsVital',
      'TheBishF1',
      'thepainterflynn',
      'rnaudmeunier',
      'JohnGiddings01',
      'Racingnews2015',
      'GoCharlieM',
      'tkcproductions',
      'F1Tarihi',
      'McBiryaani',
      'MsportXtra',
      'KiaAbdullah',
      'BiaRosenburg',
      'ErnestAkor',
      'nuria_sm',
      'JoshFG',
      'Cocolh03',
      'PassionateF1',
    ],
  },
  {
    BylineTV: [
      'mikegalsworthy',
      'BylineTimes',
      'BernardKeane',
      'tashecon',
      'BylineTV',
      'DrPhillipLee',
      'LizWebsterLD',
      'GRITCULT',
      'PeteBrownBeer',
      'GlenMatlock',
      'HelenTBracken',
      'gr8musicvenues',
      'cosmiclandmine',
      'EH54_Lainey',
      'RoseSpitznogle',
      'PureChes',
      'Artifactorficti',
      'jonesr2310',
      'DavidPriceOBE',
      'SueScarrott',
    ],
  },
  {
    AyoCaesar: [
      'piersmorgan',
      'shailjapatel',
      'Co_Op_Trucker',
      'JamesEFoster',
      'JackDunc1',
      'Africa4Pal',
      'TheProleStar',
      'WarwickHunt4',
      'simonmaginn',
      'NAJ562',
      'BabaGlocal',
      'mattlodder',
      'ArtCrunchy',
      'damienmulley',
      'rk70534',
      'davecameroon',
      'RATR2016',
      'Saffiya_Khan1',
      'Damian0706',
      'PatsyGroomer',
    ],
  },
  {
    mikegalsworthy: [
      'ProfBrianCox',
      'mikegalsworthy',
      'reid6peter',
      'peterjukes',
      'BylineTimes',
      'AlastairMorgan',
      'BylineTV',
      'Byline_Media',
      'LizWebsterLD',
      'andycorneys',
      'HughQuarshie',
      'rdanielkelemen',
      'Speed_Peete',
      'garygilligan',
      'unojen_wood',
      'JoRichardsKent',
      'RATR2016',
      'DavidHeadViews',
      'nulasuchet',
      'Borgle1',
    ],
  },
  {
    AdoptionsUk: [
      'alhanda',
      'AdoptionsUk',
      'BreakingKind',
      'sarrahh011',
      'artytrace',
      'YorkshireLady3',
      'DiJohnson15',
      'zsarrina',
      'lesgreen66',
      'RedworldToys',
      'KateMiller57',
      'julie_dutto',
      'N_T784',
      'Fran09637399',
      'alisdairmacleo3',
      'Fuksoks',
      'coralangs',
      'Mariebe10098426',
      'suegray834',
      'oakes_caroline',
    ],
  },
  {
    rebecca_vincent: [
      'candundaradasi',
      'banuguven',
      'alykhansatchu',
      'mariaressa',
      'RSF_inter',
      'AWGoraya',
      'P24Punto24',
      'nazihasaeed',
      'IFEX',
      'TahirImran',
      'RSF_en',
      'rebecca_vincent',
      'steveswerdlow',
      'CaligirlLeftie',
      'ChristineJameis',
      'HughAWilliamson',
      'jaraparilla',
      'FreeTurkeyMedia',
      'MilenaBuyum',
      'BirgitMSchwarz',
    ],
  },
  {
    andyhampson: [
      'FootballlForAll',
      'Chykere',
      'richyrich1971',
      'arturart',
      'HigginsAdam95',
      'saintruddy',
      'GokyRifat',
    ],
  },
  {
    TheCryptoSniper: [
      'bgzarik',
      'TheCryptoSniper',
      'iamRobONeill',
      'AxisAlive',
      'ScaryChildren',
      'AntonyWelfare',
      'RealAllinCrypto',
      'street_bandito',
      'RobertHartley77',
      'will_moriarty',
      'AchieveLeague',
      'equalligy',
      'Theresa95387093',
      'btc_handsfree',
      'senatus_btc',
      'mildblur',
      'mrdmckenna',
      'HodlCultivator',
      'ooophoenixooo13',
      'TamTam53120220',
    ],
  },
  {
    Sibylle_Mohr: [
      'tompeck',
      'ks_1968',
      'jonfr500',
      'marydejevsky',
      'SarahRosemary3',
      'abholymoly',
      'StaceyL49955745',
      'ashariqbal',
      'care4derby',
      'matrix100jaguar',
      'rosederbyshire',
      'rouge2507',
      'Sibylle_Mohr',
      '3179Arshavin',
      'JohnBrynt',
    ],
  },
  {
    afshinrattansi: [
      'georgegalloway',
      'dejene_2011',
      'skwawkbox',
      'citizentommy',
      'medialens',
      'Tom_Fowdy',
      'CameronMcNeish',
      's_m_marandi',
      'redsarah99',
      'JasonReidUK',
      'RenieriArts',
      'SocialistAct',
      'tesfanews',
      '55krissi55',
      'mirabarhillel',
      'KeesvdPijl1',
      'Normanjam671',
      'positiveimagep',
      'PhillipsBarrie',
      'Lashesxx',
    ],
  },
  {
    kenancruz: [
      'srkninci',
      'umit_k',
      'Seda_Ozen',
      'obk',
      'MazMHussain',
      'borzou',
      'karelvalansi',
      'Istanbultelaviv',
      'fgeerdink',
      'elcinarabaci',
      'SonerCagaptay',
      'terraincognitae',
      'muradcobanoglu',
      'timurkuran',
      'Paul_Osterlund',
      'TheTurkishLife',
      'arash_tehran',
      'enginbas',
      'DianaKristinne',
      'Ziya_Meral',
    ],
  },
  {
    _Neeses_Pieces_: ['fraudbameyang'],
  },
  {
    Minalami: [
      'MalcolmNance',
      'husainhaqqani',
      'Chahali',
      'vvanwilgenburg',
      'hashimspeaks',
      'NYTBen',
      'SecKermani',
      'OwaisTohid',
      'IftikharFirdous',
      'KyleWOrton',
      'priyaakulkarni2',
      'kashmirosint',
      'MichaelSSmithII',
      'JJohnson2u',
      'MalikAchkJourno',
      'ColinPClarke',
      'jchaltiwanger',
      'NataliaSancha',
      'mattizcoop',
      'MiraqaPopal',
    ],
  },
  {
    mrmarksteel: [
      'OwenJones84',
      'KuperSimon',
      'sampilger',
      'TalebUK',
      'TheFarm_Peter',
      'RobertMackey',
      'brucel',
      'DanKennett',
      'EuroFute',
      'HeydonProwse',
      'garygilligan',
      'shaunjlawson',
      'AllyFogg',
      'johnmerro1',
      'mconway70',
      'Saffiya_Khan1',
      'mirabarhillel',
      'sidcelery',
      'NeonLuvBar',
      'TommyMartinVM',
    ],
  },
  {
    SteveH103: [
      'IsabelOakeshott',
      'catseyesjohnson',
      'OwenLake',
      'BillyKi88394399',
      'MrLChristmas',
      'Brexitletsgo',
      'EyesRight6',
      'JustAGuyTrying2',
      'UKislost',
      'exebee88',
      'AspinwallJohn',
      'captinles3020',
    ],
  },
  {
    MarinaPurkiss: [
      'campbellclaret',
      'MalloryOnTravel',
      'AlastairMorgan',
      'jwahjwah',
      '45albannach',
      'sportmarketing1',
      'PaulSchleifer',
      'mcguigandanny',
      'mckinlay_liz',
      'wrightonthenail',
      'ChrisWChrysalis',
      'JohnTonks',
      'IanBarwell1',
      'JKLDNMAD',
      'WestNitaFBPE',
      'gehapeters',
      'DbAshby',
      'GailGailtranter',
      'catherinemginn',
      'zeno001',
    ],
  },
  {
    AstonMartinF1: [
      'sebvettelnews',
      'serhanacar',
      'Motorsport_LAT',
      'localman254',
      'Vetteleclerc',
      'playsporcom',
      'Tolbert_Ke',
      'rnaudmeunier',
      'adoreurueI',
      'BroderickAliwa',
      'TinotendaMachi6',
      'maktejah_',
      'Racingnews2015',
      'starlhamilton',
      'xargrp',
      'sennastroll',
      'RosbergOpressao',
      'SteveStevens47',
      'yldrmtayfun',
      'rmad2021',
    ],
  },
  {
    HandsomeMENAMan: [
      'bulgespotter',
      'HandsomeMENAMen',
      'AuraAakash',
      'HandsomeMENAMan',
      'pakicock86',
      'aldrin_gaa',
      'RonDashing',
      'Magicalfingers5',
      'brenosexlog',
      'meatmylollipop',
      'Jakeer41412987',
      'yuyu_grumoso',
      'Velocir95506627',
      'stagfoxxx',
      'alex11196005884',
      'fan2slibards',
      'SissyBelgium',
      'SarkarMoneeka',
      'sissystoner90',
      'BigDonStevens',
    ],
  },
  {
    grantshapps: [
      'lydiaforson',
      'Abramjee',
      'KirstieMAllsopp',
      'GabbyDarko',
      'allcapetown',
      'hakanchelik',
      'GurkaynakGonenc',
      'BenopaOnyx1',
      'denetlecomtr',
      'cuneytbasaran',
      'trussliz',
      'Sophie_Mokoena',
      'africaupdates',
      'Bruceps',
      'SharmaKadambini',
      'JaneMarriottUK',
      'bigstraps',
      'sidhant',
      'A_Boynukalin',
      'CatrionaLaing1',
    ],
  },
  {
    PeterStefanovi2: [
      'piersmorgan',
      'BylineTimes',
      'MitchBenn',
      'AlastairMorgan',
      'mojos55',
      '_DanielRyan',
      'simonmaginn',
      'EdzardErnst',
      'DerekMeers',
      'MikeHolden42',
      'peterjohncox',
      'Pingachoo',
      'rod1evans',
      'JoRichardsKent',
      'ciabaudo',
      'KarenJu62258050',
      'NinaPinabudhabi',
      'Saffiya_Khan1',
      'Gaynorann46',
      'MarcusFitzsimon',
    ],
  },
  {
    wrightonthenail: [
      'MarinaPurkiss',
      'paulwrblanchard',
      'Right_Angles',
      'podcastpartnrs',
      'henson40',
      'MarkyB_62',
      'thinkthisthru',
      'minijew',
      'brentrobbo',
      'ToriesCostLives',
      'wowcasanova',
      'sianjames1',
      'joanblanewater',
      'PickernellDavid',
      'KaesiF',
      'CheekyChi',
      'PGroenhuysen',
    ],
  },
  {
    fyzisbsrn: [
      'candundaradasi',
      'barisyarkadas',
      'baskomsernevzat',
      'ecapa_aklinizi',
      'zekikayahan',
      'eacarer',
      'barbarosansalfn',
      'SunaVarol_',
      'tokcem',
      'izmirdesanat',
      'OzlemGurses',
      'NataliAVAZYAN',
      'edipyuksel',
      'GLBLTV',
      'ErhanErkut',
      'ay_moon_ay',
      'ATAKIZI__',
      'VeysiCe',
      'bayiyimser',
      'caliskanerh',
    ],
  },
  {
    lloydhardy: [
      'badwebsites',
      'lloydhardy',
      'washdcbhl',
      'SandraDunn1955',
      'evertonfc2',
      'SpanishDan1',
      'Jackcdawes',
      'MrYesWeCan',
      'tonyrawdin9',
      'SueSuezep',
      'marie_kate2',
      'AnneLai31821380',
      'JoRichardsKent',
      'VintiquesMark',
      'lorraine_teuten',
      'gibbsy66',
      '45albannach',
      'nighthawkgeoff',
      'MarcusFitzsimon',
      'EHijacked',
    ],
  },
  {
    mrjamesob: [
      'neilhimself',
      'KirstieMAllsopp',
      'ManMadeMoon',
      'terrychristian',
      'Otto_English',
      'theskibeagle',
      'DaveMilbo',
      'TheBookofGinko',
      'EUflagmafia',
      'Steve_Hooker',
      'jwahjwah',
      'TheAeronut',
      'HelenTBracken',
      'SpanishDan1',
      'MaldenSaboteur',
      'DavidWCummins',
      'guse_guse',
      'ahddrv',
      'PeterDilworth1',
      'Daisybelll',
    ],
  },
  {
    SteveStuWill: [
      'kaansezyum',
      'JasonSilva',
      'IKucukparlak',
      'SteveStuWill',
      'ScotMcKay',
      'allanschoenberg',
      'talbotlabTSL',
      'pallaron12',
      'Lewis_GS_',
      'andrewfenton',
      'nicotrajtenberg',
      'terraluxe',
      'Gynocentrism',
      'kpatton',
      'JylkkaJussi',
      'fabiopss',
      'KamranBeg3',
      'dashywiz',
      'DoubleCurvature',
      'Adam_Asmus',
    ],
  },
  {
    hilltopgina: [
      'RobRobbEdwards',
      '_AnimalAdvocate',
      'herbivore_club',
      'Veganella_',
      'YarosisNancy',
      'XposeTrophyHunt',
      'DJZJCZ',
      'amusejanetmason',
      'wisebuyspain',
      'Freddy6518227',
      'WgarNews',
      'ALF_269',
      'awkward_1110',
      'rappublicist',
      'redmayne_robert',
      'hilltopgina',
      'joelleannabella',
      'weaniejeanie53',
      '_Pehicc',
      'gemandmoll',
    ],
  },
  {
    GoodwinMJ: [
      'corinnamilborn',
      'AllisonPearson',
      'Fox_Claire',
      'Rob_Kimbell',
      'boblister_poole',
      'laurapitel',
      'LukeJohnsonRCP',
      'HHepplewhite',
      'DCBMEP',
      'darcybbc',
      'kvoneggert',
      'MikeSwadling',
      'henryolsenEPPC',
      'BDStanley',
      'CMY1952',
      'MrRBourne',
      'RichardvReeves',
      'TimScottUK',
      'TegoArcanaDei',
    ],
  },
  {
    BordersAgainst: [
      'CathyCathyFox',
      'mammiebocockiq',
      'SharpieDj',
      'Ireland2020',
      'BordersAgainst',
      'chicchan',
      'DrBruceScott',
      'yeshualucifer',
      'SusanBe36094276',
      'EnglishLionhea1',
      'manyhopes',
      'Ella4Dogs',
      'Titithebest4',
      'ealeorah',
      'gumptionology',
      'greensecretauz',
      'militantme1',
      'sydney9651',
      'RossGrant17',
      'billybee9991',
    ],
  },
  {
    bosssbunny: [
      'TamashiAl',
      'sandstormdev',
      'Minecraft_SSYT',
      'IntrinsicWorld',
      'ImRainbowGirlYT',
      'IntrinsicGames',
      'retrozynth',
      'OxygenStudiosXD',
      'Zapp3rr',
      'packretweeter',
      'mynamesmarlol',
      'bosssbunny',
      'Rand0mAlchemist',
      'niwlzin',
      'ignawemoon',
      'IsturtiliruC',
      'Diguxeee',
      'legolemur',
      'PackMakerLegion',
      'MaffStreams',
    ],
  },
  {
    AndyTruman4: [
      'tompeck',
      'dascritch',
      'SpoonyBart',
      'sunildvr',
      'abholymoly',
      'celestialweasel',
      'JohnBrynt',
    ],
  },
  {
    DrSTheissen: [
      'tompeck',
      'ezekiel',
      '1_Lovelife',
      'worgztheowl',
      'Yeah_ThatBloke',
      'acsoave',
      'mark_r_asser',
      'Ayohugh',
      'cageybaby',
      'stevexe42',
      'thatjameswest',
      'jonpsp',
      'JohnBrynt',
    ],
  },
  {
    miketaylor71: [
      'tompeck',
      'sz_duras',
      'budgie',
      'iancundell',
      'iaintucker',
      'rhysdgriffiths',
      'ch1ckenwhispers',
    ],
  },
  {
    rinseandspin: ['tompeck', 'ashariqbal', 'SuseRichards'],
  },
  {
    alexanderfletch: ['tompeck', 'LCWF_TBK', '100glitterstars', 'JohnBrynt'],
  },
  {
    patrickjreddy: ['tompeck', 'abholymoly', 'skhowden'],
  },
  {
    circusofhorrors: ['tompeck'],
  },
  {
    jokers_tailor: ['tompeck'],
  },
  {
    DavidMLindsay: ['tompeck'],
  },
  {
    airwars: [
      'ButterflyLove_8',
      'airwars',
      'DanielLarison',
      'AOAV',
      'Bivi_17',
      '1957_Tintin_',
      'ramahkudaimi',
      'BillLaw49',
      '___adn',
      'HI_Advocacy',
      'KohNagao',
      'gsmarshall64',
      'Adal_empire_',
      'wagepeaceau',
      'aditimadhura',
      'alraven3',
      'd6TobAVk70NhwPl',
      'willemstaes',
      'markkukis',
      'MacrobianNomad',
    ],
  },
  {
    MellyUTD: [
      'TrujistaSancho',
      'SBT33J',
      'CFCJeremy1905',
      'grandadmcfc',
      'yerimaworld',
      'SackHimFFS',
      'rishitshit',
    ],
  },
  {
    SamRamani2: [
      'javerias',
      'Zeinobia',
      'mosharrafzaidi',
      'SRI_org',
      'autorMACosta',
      'sumrkhan1',
      'Natsecjeff',
      'BCSBART',
      'BudGothmog44',
      'MaxAbrahms',
      'Malbrunot',
      'AliBakeer',
      'Nervana_1',
      'miqazi',
      'chutoislam',
      'PYAntika',
      'PTI_Lion26',
      'abaabood',
      'SamRamani2',
      'MrInfluencerUg1',
    ],
  },
  {
    JamesMelville: [
      'KirstieMAllsopp',
      'JamesMelville',
      'Awarding_Women',
      'AilsaForshaw',
      'almaemily2',
      'z_chrissie',
      'craigjtodd',
      'BishirDeborah',
      'gursmehmet',
      'ZedTrafficker',
      'DeniseMembery',
      'Sunpoches',
      'pet031960',
      'sanaayesha__',
      'hereandnowthen',
      'cilergecici',
      'AliBushell',
      'paulaaprilmunn',
      'genevieve1st',
      'Rodrigo80842128',
    ],
  },
  {
    Underground_RT: [
      'RT_com',
      'afshinrattansi',
      'johnjoechad',
      'rogersurfacings',
      'Hans_Besemans',
      'IamSamAbraham',
      'L_Gale517',
      'caciquecaonao',
      'TapityB',
      'HarvesterCrypto',
      'MarcelloBotzo',
      'Freefreedoms1',
      'Samjoy95251167',
      'Reza_Gasant',
      'NaeemIhsan5',
    ],
  },
  {
    PaulEmbery: [
      'thecoastguy',
      'Rob_Kimbell',
      'ianpuddick',
      'Stop_The_EU',
      'paulafr68963440',
      'MikeSwadling',
      'PatWilliams1944',
      'KingBobIIV',
      'sir_frankuk',
      'ATHE1STP0WER',
      'futureking57',
      'WalkerMarcus',
      'GoodmanJonathan',
      'johndmtb',
      'YvetteHenson',
      'maturefinancier',
      'pauldbowen',
      'JamesEarl2112',
      'yogamanic',
      'Ash_Hirani',
    ],
  },
  {
    FrasierHarry: [
      'RobRobbEdwards',
      'YarosisNancy',
      'jacqui703',
      'awkward_1110',
      '_Pehicc',
      'FrasierHarry',
      'Calendula2016',
      'gruntvegan',
      'NervenaJolt',
      'sonneyjo',
      'ForeverChestnut',
      'Robert0Guiscard',
      'DERVIEMOO',
      'vegan_raven',
      'AlexCoppocBunce',
      'GMACVHHH',
      'kwaidan',
      'bozzo1931',
      'buddyholly1111',
      'sandrathegreate',
    ],
  },
  {
    stevedabitcoin: [
      'KriptoLevent',
      'CoinJess',
      'VolgaTamoz',
      'MeschainMES',
      'baharlanya',
      'Papatyaabla',
      'PirloTaylan',
      'FerdiSerttas',
      'kriptortak',
      'busratemurcin',
      'ismailtsdln',
      'emperor_677',
      'DakkaDukkaa',
      'EdwardEnglannd',
      'Fibotrading48',
      'muratbaspinar',
      'NecatColak',
      'chzhodlerr',
      'trader_boga',
    ],
  },
  {
    FutbolBible: [
      'piersmorgan',
      'harun_tekin',
      'JamesMiller988',
      'davnch',
      'JayFromVenda',
      'aro__base',
      'tauhenare',
      'NgudoFisk',
      'iamalexfinch',
      'Enioxare',
      'OliB_RFC',
      'eyez_ow',
      'ManUnitedXtra_',
      'efe38795173',
      'ejindu_chibizy',
      'Pr3koNam',
      'iMhartyz',
      'AganMandla',
      'shivster28',
      'matekihlabisa',
    ],
  },
  {
    bauglobal: [
      'AlatonLeyla',
      'UgurOkullari',
      'sirin_karadeniz',
      'nilcicek',
      'serdarsenel',
      'adilkurt9',
      'ardaergez',
      'hasankose_hk',
      'bornovakampusu',
      'ugurlucografya',
      'refikziya',
      'metinakcaa',
      'Bakirkoy_Ugur',
      'DurdanOsman',
      'gamzeelifturkay',
      'sayarulucay',
      'sunalkarapostal',
      'yavuz_muftuoglu',
      'mkuvanci',
      'GaziemirKampus',
    ],
  },
  {
    hafsi_ahmed1: [
      'hafsi_ahmed1',
      'basma_yassmin_a',
      'dzairrrrr',
      'NanouSheraz',
      'IssamBENCHEIKH1',
      'SafouSafou3',
      'salrit',
      'luky60646',
      'faramawione',
      'tifa802',
      'h_idayat',
      'Nurse22si',
      'BachirSid',
      'Amira96Mirou',
      'gffhhghffhgfd',
      'SmailAyoube',
      'MU7Q5eYrR1LBeCB',
      'dihanimed',
      'Algerienne_21',
      'Algerianlilia27',
    ],
  },
  {
    CiaranRH2: [
      'MonthlyPuroresu',
      'IrishHammerMMA',
      'thesuperjcast',
      'AngelInvestorOG',
      'P1Ange1',
      'CiaranRH2',
      'AnnetteReid247',
      'TrapBobe',
      'stevenamoney',
      'Cosmictranquilo',
      'Eldivo23',
      'Pablo____Chacon',
      'bigboymiguel12',
      'Catch_Krush',
      'shiniestpoison',
      'dirkmalloy32',
      'Jay_Lee101',
      'Timmy_Ryan02',
      'FaM_First52',
      'switchblade_gt',
    ],
  },
  {
    LBB_Research: [
      'arkmedic',
      'real_GGoswami',
      'good_is_real',
      'DSuprean',
      'Mark39496798',
      'Chareen98452288',
    ],
  },
  {
    englishpen: [
      'candundaradasi',
      'camanpour',
      'ETemelkuran',
      'LivaneliZulfu',
      'fispahani',
      'umit_k',
      'ebabahan',
      'baysal_nurcan',
      'ezop2011',
      'fact_mirrorr',
      'drgurbuzcapan1',
      'Soc_of_Authors',
      'P24Punto24',
      'IFEX',
      'pen_int',
      'aesrayalazan',
      'YaseminCongar_',
      'yunusunbabasi',
      'ayayciog',
      'TheTurkishLife',
    ],
  },
  {
    Rob_Kimbell: [
      'africaupdates',
      'Rob_Kimbell',
      'HHepplewhite',
      'MikeSwadling',
      'powerglobalus',
      'tlloydjones',
      'TegoArcanaDei',
      'PoliticsSense',
      'EarlswoodWarw',
      'PFr1end',
      'astro_trader',
      'robertsessford1',
      'YvetteHenson',
      'shiremoorpotter',
      'haniefhaider',
      'Ash_Hirani',
      'objectsmith',
      'MSANNCOOPER',
      'AndRealisation',
      'MartinTwigs68',
    ],
  },
  {
    skzchartdata: [
      'BINCHANLOVER',
      'binnietattoo',
      'vfelixfairy',
      'bangtaer',
      'Mollolina',
      'chanlvntr',
      'shineminiverse_',
      'skijzee',
      'starknow98',
      'waysfic',
      'sungshinesss',
      'mijin913',
      'shiningmoonztar',
      'ChanSeung_1900',
      'jonginjulie',
      'skzboy',
      'feIixknows',
      'sbinflower',
      'yassnara57',
      'jisoominn',
    ],
  },
  {
    smallcappick: [
      'smallcappick',
      'Ste_Cha_FEG',
      'amazingfeg',
      'feg_steph',
      'FEGRizz',
      'Amirfar47983211',
      'Molecul66251121',
      'Elfaggio1',
      'Soli20222',
      'rayancoin',
    ],
  },
  {
    shashj: [
      'MariosKaratzias',
      'GerardAraud',
      'edwardlucas',
      'AydnSelcen',
      'GreekAnalyst',
      'tashecon',
      'shashj',
      'TomMcTague',
      'LawDavF',
      'SonerCagaptay',
      'TiagoDF',
      'KyleWOrton',
      '_JakubJanda',
      'ghoshworld',
      'RikeFranke',
      'DSORennie',
      'e_amyna',
      'Ziya_Meral',
      'StevenErlanger',
      'telloglou',
    ],
  },
  {
    Femi_Sorry: [
      'piersmorgan',
      'Femi_Sorry',
      'RalfLittle',
      'MalloryOnTravel',
      'slightlylate',
      'Iloveautumn2',
      'MattLittler',
      'steviweavi',
      'ScarcityStudios',
      'huge2ya',
      'RileyXav',
      'SamYoung841',
      'Nealb2010',
      'Youth4ia',
      'dwinle',
      'McKennaSusie',
      'York4EU',
      'J_G_Hughes',
      'catherinemginn',
      'MarkAyresRWS',
    ],
  },
  {
    eyupkaanyoksu: [
      'ezop2011',
      'AhmetGunestekin',
      'AlatonLeyla',
      'eyupkaanyoksu',
      'vbozkurt55',
      'mstfsvn',
      'DuyguToplan',
      'cilergecici',
      'tilkigozu',
      'CananSekerr',
      'hedonicus',
      'BizimTekke',
      'kursatbeyoglum',
      'kiiiibele',
      'Besteisildak',
      'huysuzpluton',
      'aogorgun',
      'tekelee',
      'KlDolunay',
      'GokhanErtosun',
    ],
  },
  {
    Ray_Uddin: [
      'MiddleEastEye',
      'Pete__Panda',
      'NickNorbrook',
      'daniaakkad',
      'amyharlib',
      'HelloPoli',
      'frankandrews__',
      'hatimny',
      '_KhaledShalaby',
      'hailants',
      'Fatman003',
      '5hongo',
      'victoriaelms',
      'repeatwatch',
      'ghostofharar',
      'anitarantada',
      'MominhK',
      'chicosagorro',
      'FckScoundrel',
      'thehiddensinner',
    ],
  },
  {
    RightDisplay: [
      'RightDisplay',
      'BerneLarry',
      'Jo_Barbarella',
      'Tai_japh',
      'dcodedboii',
      '__jce_',
      'harasimiuk33',
      'Moha_ky93',
      'chattymandan',
      'Lestercream45',
      'keekee_boy',
      'dos_jordina',
      'mcmc_twt',
      'arnaudmoricel',
      'Diane_Dee',
      'gir_104',
      'NAhmadAmina',
      'DIANA5566',
      'PancakeAngel88',
      'marshallj523',
    ],
  },
  {
    jches16: [
      'CoreDesign_com',
      'stellalune',
      'coder_487',
      'RaidercastPod',
      'harvest57688777',
      'JRMilward',
      'jches16',
      'ulthimas',
      'CoreyOCroft',
      'TombRaiderFr',
      'thecrofttomb',
      'LodairJr',
      '_nastiaershova_',
      'KopriDean',
      'AnEntireAmazon',
      'classicTRfan',
      'CalypsisCroft',
      'NightwingRaider',
      'TheWorldlyGamer',
      'micheleCR0FT',
    ],
  },
  {
    _AnimalAdvocate: [
      'ItsMadhuriJain',
      'RobRobbEdwards',
      '_AnimalAdvocate',
      'Veganella_',
      'Ivy_MiddletonUK',
      'DJZJCZ',
      'LiftForever67',
      'DidiFrench',
      'WgarNews',
      'hilltopgina',
      'joelleannabella',
      'PetraBentele',
      'Artudacamelot5',
      'julie_dutto',
      'TheAnimalsKnow',
      'Ramiro6668',
      'DaveCol08591776',
      'setyourmindfree',
      'minsquish',
      'armeuskadi',
    ],
  },
  {
    tariqpanja: [
      'MiguelDelaney',
      'BenMabley',
      'Knox_Harrington',
      'Mobyhaque1',
      'AST_arsenal',
      'inabster',
      'asifkapadia',
      'meNabster',
      'parkercaruana',
      'JustWhatNowWhy',
      'fathermetro',
      'samueloakford',
      'JamesPiotr',
      'ben_rumsby',
      'johnmerro1',
      'muhammadbutt',
      'Pingachoo',
      'Nicocantor1',
      'TheFev',
      'AnnieEaves',
    ],
  },
  {
    tuhynft: [
      'AltcoinsCrypto',
      'digiknowassets',
      'D_Infiltrator',
      'whiskeylanestds',
      'CryptoBoarder',
      'r0acher',
      'catsfanatic319',
      'edwinbona27',
      'Eshlematt',
      'TonyEverythings',
      'cccnielsen',
      'MCLombardini',
      'CryptoGartus',
      'NFTShiller',
      'airdropped16',
      'sourceawareness',
      'TheChag77',
      'Cameron7Nielsen',
      'BigBoyPlaysNFT',
      'CryptoFo_XRP',
    ],
  },
  {
    Ziya_Meral: [
      'eyupcansaglik',
      'hakanchelik',
      'ragipsoylu',
      'cansucamlibel',
      'GreekAnalyst',
      'tashecon',
      'guvsak',
      'sinanulgen1',
      'IsinElicin',
      'SonerCagaptay',
      'hahellyer',
      'laurapitel',
      'ghoshworld',
      'selinnasi',
      'APHClarkson',
      'Ziya_Meral',
      'BrunoTertrais',
      'RenaNetjes',
      'NesrinOzoren',
      'emad_badi',
    ],
  },
  {
    propeertys: [
      'propeertys',
      'beatax088',
      'DankaKoz',
      'magdakodds',
      'GrzegorzFiolek',
      'Ewa33872319',
      'bbrzoska88',
      'piotr4913',
      'Elatte1',
      'PiotrWarszawski',
      'Jagoda29623120',
      'JerzyMajzer',
      'SzczepanMarek',
      'Robert06270082',
      'BZylinska',
      'MarKo_293',
      'MilosawReczek',
      'ZosiaZwarszawy',
      'RamzesShogun',
      'JoannaGerda',
    ],
  },
  {
    TajudenSoroush: [
      'GulBukhari',
      'ceydak',
      'Muonsatona',
      'guvsak',
      'NajeebNangyal',
      'shahzadShafi007',
      'NavroopSingh_',
      'CameliaFard',
      'lali_eng',
      'brianbigelow',
      'kedar_mordechai',
      'jagodagrondecka',
      'APHClarkson',
      'JJohnson2u',
      'Rezhasan',
      'YamayaT',
      'AbraxasSpa',
      'imUrB00gieman',
      'iopyne',
      'natiqmalikzada',
    ],
  },
  {
    paulybrown: [
      'Chahali',
      'Minalami',
      'garybunt',
      'CReTheIll',
      'Danaiolos',
      'Juli1shadows',
    ],
  },
  {
    Alanduncan2016: [
      'Neurophysik',
      'Ezidi2',
      'KerenRidgway',
      'MrSugden2',
      'GillJames54',
      'SuperbikeAl',
      'netminnow',
      'rubycooper007',
      'TessaOutlook',
      'pambeertom',
      'JaffaStreet24',
      'tm014d1009',
      'Irene48',
      'AndyHammers',
      'laxman_xy',
      'JackieBear1872',
      'ClaireH37444648',
      'BromleyBeau',
      'Dojo50113598',
      'notamused666',
    ],
  },
  {
    Simon_S70: [
      'DaveAtherton20',
      'ninnyd101',
      'viesvies1',
      'heidiEC5',
      'MickGreenhough',
      'Ciolfilicious1',
      'Snyggois',
      'Rob_E_Dresser',
      'NONONOEU',
      'Deedy2201',
      'canadiancarol1',
      'joanna_starz07',
      'Bobilly10',
      'iainmcquillan',
      'dollytrollpe',
      'MJohn59655',
      'PymPurnell',
      'rubycooper007',
      'NikitaAnne77',
      'flufficat',
    ],
  },
  {
    GlobalBritain21: [
      'darcybbc',
      'JayneDWales',
      'jaycee1001',
      'YorkshireLady3',
      'andie1105',
      'ninnyd101',
      'FenrirWolf26',
      'johndmtb',
      'YvetteHenson',
      'viesvies1',
      'biffrbear',
      'tonyk2169',
      'alan_lingwood',
      'wiillsjd',
      'garyhulme1812',
      'MSANNCOOPER',
      'Minxky999',
      'GuliableMany',
      'Christi75367510',
      'Bobilly10',
    ],
  },
  {
    WilliamsRacing: [
      'NicholasLatifi',
      'F1GP_ManUtd',
      'AuRupteur',
      'Tolbert_Ke',
      'rnaudmeunier',
      'OfficialGR63',
      'maktejah_',
      'fortius0',
      'Racingnews2015',
      'barisgrckr',
      'AristideBanceVU',
      'xargrp',
      'lovesongnorris',
      'SteveStevens47',
      'guzelawjunior',
      'folknorris',
      'LirbesOlerybes',
      'Formula1chick',
      'perryizm',
      'JunaidSamodien_',
    ],
  },
  {
    NCFCareForum: [
      'NCFCareForum',
      'Sanders4Health',
      'vicrayner',
      'SarahReed_MHR',
      'DesKellyOBE',
      'nchadborn',
      'runnermandoc',
      'DeniseT01',
      'CathyAyrton1',
      'CPA_SocialCare',
      'imagine_ceo',
      'insequaltd',
      'CTalkBusiness',
      'JoThomasRN',
      'NSCSLtd',
      'maxine1966',
      'VDuskin',
      'lockwood_sian',
      'BalitAmira',
      'thesarahck',
    ],
  },
  {
    isisneutronmuon: [
      'cabotinstitute',
      'FaradayInst',
      'EdinburghChem',
      'Askgerbil',
      'essneutron',
      'JohnWomersley',
      'ProfValeskaTing',
      'BAMResearch',
      'BristolUniEng',
      'MarkJ_Neutron',
      'Scieducation1',
      'hywelowen',
      'RutherfordApLab',
      'XtalGrrl',
      'UKCatalysisHub',
      'analioj',
      'FranEmmerling',
      'AcadeNik',
      'sublimestem',
      'RSEccleston',
    ],
  },
  {
    BestForBritain: [
      'sturdyAlex',
      'KatCapps',
      'mojos55',
      'helenyg',
      'MikeHolden42',
      '111meeuwis',
      'RebetikoWalrus',
      'ciabaudo',
      'BRyvkin',
      'J_G_Hughes',
      'VolksCrapWagen',
      'SallyFereday',
      'heather63262308',
      'markrowantree',
      'gnomeicide',
      'Roger_Xanth_Day',
      'Bournemouth4EU',
      'gremalkincat',
      'NarcAware',
      'EuropeFife',
    ],
  },
  {
    b_e_n_d_y: [
      'edser_manips',
      'Sunshineayt',
      'laukinesiela8',
      'gecebursin',
      'themiyystan',
      'dizzistan',
      'XimenaPsck',
      'bealegido',
      'whatsabex10',
      '0RGINVLS',
      'Horte081995',
      'InHerDiziWorld',
      'JustSofia1009',
      'artisticIouvre',
      'KatyPastor',
      'XimenaBO2',
      'yaseera__',
      'Munnamac2',
      'maloosak77',
      'WhysoSiriusz',
    ],
  },
  {
    dgmoore75: [
      'BurnsideNotTosh',
      'shiremoorpotter',
      'iky86',
      'UhtredUhtred99',
      'Carlos59182445',
      'SIwanttogetoff',
      'NeckSturgeon',
      'RichardJonesRC',
    ],
  },
  {
    GinaAVale: [
      'fgeerdink',
      'janearraf',
      'ICSR_Centre',
      'fredabrahams',
      'Eleanor_Robson',
      'FrancescaEbel',
      'diplomattitude',
      'toumi_abdennour',
      'FaridoonDida',
      'Escritordefutu1',
      'AmericanCTRI',
      'TheBusinessNet',
      'gbvjournalism',
      'XCEPT_Research',
      'Carlopodaliri',
    ],
  },
  {
    ashraaf_alsaad: [
      'DrShdeeddAwy',
      'anaelmasri2121',
      'nadim_shimi',
      'azmosaa',
      '99xc',
      'Ezzat443',
      'FnZtgb',
      'masr_7',
      'redtiger2016',
      'AhmedAlbadry79',
      'Hamada2Prince',
      'sherifothman3',
      'gamal5901',
      'MohamedElHenaw7',
      'dgma75',
      'HESHAMH16075134',
      'n5MjMLn4W0s83GQ',
      '65Mam',
      'AlyemniaH',
      'XhiPVlrc8UlcBVy',
    ],
  },
  {
    Quadrant: [
      'prongsbrave',
      'lovesongnorris',
      'hemmselss',
      'landonorrisvids',
      'LandoNorrisBr',
      'stackztup',
      'ephemieral',
      'softnorris',
      'sucrenorriz',
      'mingyuungi',
      'bendthwrules',
      'oneofsunflowers',
      'SWEETON3D',
      'mau4414',
      'notearsivy',
      'buteraolivia',
      'landosoylago',
      'LixxieB',
      'norrisprii',
      'phantomsnoelia',
    ],
  },
  {
    LauraofLagos: [
      'deejayfaremi',
      'marrgarritah',
      'LauraofLagos',
      'yew1e',
      'H8DramaSoBad',
      'okonjithelma',
      'DuchesseGingham',
      'Beckyezinwa',
      'The_ynxx',
      'Swiiish_x',
      'Nkeem_',
      'phavourshelby',
      'ifedolarr',
      'modela__jane',
      'callme__BettyOJ',
      'CutieyLicious',
      'EmpirelLatino',
      'BBnaija2020N',
      'Ace_kemora',
      'kool2timmy',
    ],
  },
  {
    Rothna_Begum: [
      'janearraf',
      'IFEX',
      'OmarSShakir',
      'KooyJan',
      'MarkKersten',
      'ColombeCS',
      'bitteham',
      'rinachandran',
      'MikeReeseM',
      'Shanfaraa',
      'GadflyQuebec',
      'abier_i',
      'angelatimlin',
      'msaez_torres',
      'john_medalen',
      'anna_lillith',
      'JagathKey',
      'rickhaden',
      'juliefr88667164',
      'LingMeiWong',
    ],
  },
  {
    Grandelovesick2: [
      'LlLlMONS',
      'khunyingyaCeLi',
      'urisa0327',
      'namtan_0327',
      'slays_world',
      '7L4EVER',
      'jeniedior',
      'crystaIpink',
      'chaesoopalace',
      'IUfazendeira',
      'Marybel246f',
      'liliproduced',
      'AbNamtarn',
      'lisabestgirI',
      'Lilie_M327',
      'LM19_',
      'lisaxalways',
      'llIIisaIlIlI',
      'BeeelRP',
      'liliprty',
    ],
  },
  {
    CER_IanBond: [
      'edwardlucas',
      'tashecon',
      'DaveKeating',
      'SarahLudford',
      'oulosP',
      'FHeisbourg',
      'ulrichspeck',
      'laurnorman',
      'juhasaarinen',
      'marypcbuk',
      'Stefan_Laurell',
      'paulstpancras',
      'Bjoern__M',
      'defact0_',
      'lobjakas',
      'DefenceEurope',
      'christinayiotis',
      'SE25A',
      'edwebb',
      'IskanderRehman',
    ],
  },
  {
    peterjukes: [
      'jennycohn1',
      'emmyzen',
      'AlastairMorgan',
      'RespectIsVital',
      'robertjoseph',
      '0Calamity',
      'SkartveitNancy',
      'SarahLudford',
      'POCX100',
      'Jgs_x',
      'butwhatifitsall',
      'SandraDunn1955',
      'RuthvenPhilip',
      'eyemagistus',
      'Jackcdawes',
      'digitalmaverick',
      'jurylady5',
      'BillyVacant',
      'snigskitchen',
      'eyejosh',
    ],
  },
  {
    Alighazizade: [
      'afshin19999',
      'makeyourselfno1',
      'DokhtareMaardom',
      'EbHFL9CLFaaK3t8',
      'BlackOwl65',
      'Rooham61',
      'BayatMahboubeh',
      'haj_mahmoood',
      'ghasedak189',
      'FrankKh3',
      'ila0009',
      'Fataneh49460550',
      'Aghaye_khassi',
      'chapronrOuge_',
      'epenetusodyssey',
      'arrashkamangir',
      'fariba1121',
      'Nobateman1',
      'DedalusDiggle0',
      'SARA_SEPIDEH',
    ],
  },
  {
    Luiz_Fernando_J: [
      'Luiz_Fernando_J',
      'VernardoLau',
      'alanrodrigues14',
      'johnnyjl96',
      'NargleInAFez',
      'tvsinemayildizi',
      'ShauvikSaha',
      'SecretTime18',
      'witcherself',
      'SnyderVTheCW',
      'Amit_Hu_Mai25',
      'LtMenalippe',
      '_13thofmay',
      'quoteorcomedy',
      'redezrobbins',
      'Raj__1099',
      'nikoskarras1998',
      'mtastepe',
      'popcorngrabber',
      'T4ier1',
    ],
  },
  {
    ashindestad: [
      'ShogunAsh',
      'SincerelyTops',
      'proceeeding',
      'KashARTworks',
      'NSKL_',
      'coc0nut_',
      'LeoraLeone',
      'DJCallyV',
      'ybees3',
      'estrellapreto',
      'GardensofLun',
      'DrCube_',
      'Blick_Oyinbo',
      'chimsldn',
      'essexhauttie',
      'adorekem',
      'XaraChisano',
      'DJ__Ari3s',
      'karenochuwa',
      'Unlocatable1',
    ],
  },
  {
    meghamohan: [
      'scott_mills',
      'cchukudebelu',
      'harumutasa',
      'Weza_Solange',
      'BBCNawal',
      'raziakkhan',
      'KwamiAdadevoh',
      'nazaninemoshiri',
      'BlessingsDigha',
      'goulcher',
      'GissouNia',
      'jamieklingler',
      'Samwyri',
      'ninatypewriter',
      'lebo_diseko',
      'BasuAshis',
      'kateileaver',
      'OsobElmi',
      'monikaplaha',
      'kenedavies',
    ],
  },
  {
    MarieAnnUK: [
      'jacquep',
      'ottocrat',
      'BrexitBuster',
      'SpanishDan1',
      'MrYesWeCan',
      'sharonwllms48',
      'CornishSkipper',
      'MicloutT',
      'juliehinukeu',
      'TimWil',
      'mdareedwards',
      'moses_brian',
      'NeonLuvBar',
      'politicswatch15',
      'NE4EU',
      'nulasuchet',
      'BiancaCelentan1',
      'TimMorton2',
      'StuartLothian',
      'haversham_miss',
    ],
  },
  {
    observatoryihr: ['TurkeyAffairs', 'PPHRtweets', 'AhsanKhan_Esq'],
  },
  {
    whencyclopedia: [
      'HardcoreHistory',
      'cchukudebelu',
      'SophNar0747',
      'CathyYoung63',
      'TudorTweep',
      'carmen_caesaris',
      'ChrisStringer65',
      'MakeMyDayPunk_',
      'Minoss_',
      'Norb_Lawrence',
      'Robertsixto2',
      'KissesForUsAll',
      'reedcliffe83',
      'abeerallamj',
      'Dian_Supolo',
      'PrincessofWails',
      'SocialNomadRach',
      'Sternenschiff',
      'Jorgeluenda',
      'CalDisasters',
    ],
  },
  {
    SattarSaeedi: [
      'noor_quraishii',
      'mSaleemJaved',
      'OpAfghanistan',
      'kabulii',
      'harounrahimi1',
      'ziaatifi',
      'RokhaPanjsher',
      'NaveedaKhoshbo',
      'sayedfaqerzai',
      '__Dastan',
      'rahjoo1376',
      'AfghanAfghan143',
      'Khan99447063',
      'tagGcEWCp3PuMJE',
      'abdalrab003',
      'Jawaad_Jaawed',
      'Abdalian3',
      'Reyhan028423352',
      'HaleEbham',
      'ghiasee',
    ],
  },
  {
    saimelondon: [
      'gulguntp',
      'c137yo',
      'yldrmztrk8',
      'ahusimya',
      'TekinZuhal',
      'ismailTuncel17',
      '1UlkemTc',
      'FCizmecioglu',
      '_fatih_1',
      'FakePandemi1',
      'blgcdm',
      'filiz_filizce',
      'TeryAzy',
      'ondemir066',
      'kelami_kibar_',
      'krdghan',
      'saimelondon',
      'Simendam',
      'mehmetkocbas',
      'sunsea35',
    ],
  },
  {
    jucerasoli: [
      'jucerasoli',
      'HAMlL7ON',
      'mttmurdxck',
      'PaolaLaredo',
      'empresariojoao',
      'AlbertoTomasi',
      'avcnarrador',
      'rafiss44',
      'ricovmader',
      'isitmarvel',
      'Chrissantana22',
      'michaelmsb',
      'Fabiana76610123',
      'soueuamandoca',
      'RitaaAfonso',
      'WilliamsRacingB',
      'WolfTargaryen_',
      'jolllutstan',
      'AldoLuiz00',
      'famousgossip3',
    ],
  },
  {
    TheGamingNacho: [
      'PUBGMOBILEPLYER',
      'TheGamingNacho',
      'gcoops94',
      'H1TM4N_TTV',
      'WickyTv',
      'PUBGamingLounge',
      'BenWest83005712',
      '_h3rbzzz',
      'A_Timeless_Soul',
      'colonelno1',
      'BigHamish88',
      'Flaevxr',
      'e1_nut',
      'RELfps',
      'BeechBfly',
      'Tris24',
      'Gorjus__George',
      'geeluton',
      'itsretrojoe',
      'nathibrs',
    ],
  },
  {
    EBRD: [
      'Lagarde',
      'ahuozyurt',
      'RyszardPetru',
      'CIF_Action',
      'ma_ozdemir',
      'SustainTrends',
      'DrRueckauf',
      'OdileRenaud',
      'arifcemgundogan',
      'EBRDgreen',
      'olgarosca',
      'kmlyyll',
      'CasparVeldkamp',
      'uyvarh',
      'MustafaAlperen',
      'TurSEFF',
      'MoneyWizard_NYC',
      'GoksuAytug',
      'AbdoulR37230833',
      'cagataybircan',
      'EBRDgender',
    ],
  },
  {
    OxfordMedic: [
      'YarosisNancy',
      'Nex72599860',
      'awkward_1110',
      '_Pehicc',
      'FrasierHarry',
      'Lawlor224',
      'hayley_Core_V',
      'rizfan',
      'FierceCharm',
      'vegan_raven',
      'VeganShoesLon',
      '3Lowery',
      'flloydthecat',
      'AlanAlan5240751',
      'JustWhatcause',
      'BlueberryJane',
      'lilacjay2',
      'navin391',
      'RevolutionPi',
      'Lucille78631236',
    ],
  },
  {
    OTVDesk: [
      'GiftCee',
      'jaycee1001',
      'FacundoSavala',
      'InvictaRegina00',
      'honey51818443',
      'heidiEC5',
      'AndiNeglia',
      'PrincessofWails',
      'z_chrissie',
      'MSANNCOOPER',
      'ItsJustaRide',
      'LeaveHQ',
      'kath21_field',
      'KerryThomas20',
      'GillJames54',
      'Jan_S_B_459',
      'HorshamGardener',
      'PetenShirl',
      'davyjo2222',
      'Tedestrela',
    ],
  },
  {
    daliaziada: [
      'daliaziada',
      'sfrantzman',
      'eevriviades',
      'Marianhouk',
      'GagakNew',
      'SherifRAyad',
      'sorryboy321',
      'FionaMullenCY',
      'MArizanti',
      'Defense785',
      'Pucemargine',
      'Mark45dominic',
      's_total_s2',
      'ChloeEmmano',
      '1538med',
      'xfailadis',
      'ESSA_A1I',
      'MustafaSinanGl1',
      'ahmed747hassan',
      'JournalistJaff3',
    ],
  },
  {
    iranintlsport: ['IranIntl', 'miladzaman18'],
  },
  {
    FutureShockPC: [
      'ZackGibson01',
      'Lana_Austin1',
      'FutureShockWres',
      'chrisridgeway__',
      'WWEChrisSharpe',
      'alexxisfalcon',
      'johnisherwood',
      'CATCHpw',
      'visagewrestler',
      'holliebarlow_',
      'RPDFighter',
      'ShadyNattrass',
      'ItsTaonga',
      'SoapBoxRasslin',
      'robhalden',
      'tony_wright97',
      'anttheeditor',
      'GuidingLightIQ',
      'CJ_413',
      'RyanBartram',
    ],
  },
  {
    HayroKara: [
      'BigBlueWaveUSA',
      'JohnDWestbrook',
      'Marston4ca42',
      'HillBeverlyhill',
      'hilarysontag',
      'justice4all01',
      'mgoldsmith',
      'RBiakpara',
      'Jillian1008',
      'CelticNotorious',
      'lovethydog9',
      'StockScout1',
      'trishmc1958',
      'Uncle_Joe78',
      'venetianblonde',
      'bellum_omnium7',
      'Twinsfan811',
      'Allchanges',
      'Multijo1',
      'ViudaAlegre13',
    ],
  },
  {
    shanna1627take2: [
      'rdrhwke',
      'cinarte1956',
      'coco14391',
      'BookerSparticus',
      'ScottyAmerica1',
      'The_FJC',
      'blaze0497',
      'realArmandKlein',
      'x4eileen',
      'Rickster_75',
      'Winback24',
      'Elenaforever13',
      '18hariprakash18',
      'kernaghanscott5',
      'Popsunderhouse1',
      'jedi_fu',
      'SPepper1964',
      'CurtisHebert',
      'CrescentPatriot',
      'Birdie2963',
    ],
  },
  {
    davidbelle_: ['ybees3'],
  },
  {
    MrJGTodd: [
      'Botanygeek',
      'gynarchygoddess',
      'BioMickWatson',
      'rolandmcs',
      'johnandi',
      'PaulTyredagh81',
      'kmirandalondon',
      'Lynn_GT',
      'StayEuropean',
      'marccold',
      'alexbellars',
      'Guy_Stallard',
      'dwinnera',
      'davey',
      'mad4farming',
      'dylanhm',
      'AnitaBellows12',
      'CountrySkills',
      'north_joanna',
      'HawkwindsGong',
    ],
  },
  {
    MercedesAMGF1BR: [
      'adoreurueI',
      'HAMlL7ON',
      'TVolfi',
      'wickedcalore',
      'hqmarvel',
      'RosbergOpressao',
      'avengersucker',
      'Jahmilton44',
      'onelovelaham',
      'laughszarry',
      'exilecIerc',
      'Felipemmeira',
      'starsgin',
      'pascaldjarin',
      'safesbeaulewis',
      'DIAMERONMARERIS',
      'kingsmanrvel',
      'inthegoldxn',
      'powermzrvel',
      'gabs_stef',
    ],
  },
  {
    Sillyshib: [
      'SandraDunn1955',
      'SpanishDan1',
      'johnandi',
      'guse_guse',
      'PeterDilworth1',
      'ElizabethBangs',
      'DavidHeadViews',
      'KarenStopHate',
      'johnthebrexile',
      'jenjeffiner',
      'DevonianMatthew',
      'scarlettpeach',
      'moomaloom',
      'Shieldmaid8',
      'NickStevenson63',
      'BJ_Gardener',
      'Yogablackbelt',
      'York4EU',
      'Scarborough_GB',
      'IsntTony105',
    ],
  },
  {
    SheAdawg2: ['MirLagerfield', 'thats_me_Jay'],
  },
  {
    LBC: [
      'piersmorgan',
      'jonsnowC4',
      'NickFerrariLBC',
      'corinnamilborn',
      'vvanwilgenburg',
      'tashecon',
      'SpanishDan1',
      'Janice4Brexit',
      'marionste',
      'naomi_amba',
      'Andy_Gez_',
      'stroppypanda',
      'Go4gold100',
      'astrojenny',
      'arslandmr',
      'GirlyswotFbpe',
      'curiocat13',
      'tubeway',
      'solamiga',
      'Brett8240',
    ],
  },
  {
    V9va: [
      'V9va',
      'LDN_3',
      'MazajiAlThwene',
      'silver88099',
      'LDNFather',
      'fahadalsaqari',
      'aljumairym',
      'alqaidikhalaf',
      'Dooojii',
      'mo7balsafar',
      'randeeza',
      'KsaTravels',
      'BlqsCuler',
      'king_mooon',
      'amal_balushi',
      'AroosAlrrawdh',
      'Dareenwrites',
      'M_AlRubaiey',
      'umturki_55',
      'AlnajmiHussain',
    ],
  },
  {
    JuliaHB1: [
      'jonsnowC4',
      'Coconutinbahama',
      'jlj21964',
      'LSW12612672511',
      'rwaterer',
      'jonnyhodl',
      'DianewasHR',
      'garyhulme1812',
      'LouisHenwood',
      'NeilJamesWeekes',
      'catseyesjohnson',
      'HorshamGardener',
      'LewtonSerena5',
      'omminc',
      'ChristineEasda1',
      'flowesaunders68',
      'magicdmw',
      'norfolkingood65',
      'SteveG06322664',
      'amandajs11',
    ],
  },
  {
    br_nning: [
      'TheStephenRalph',
      'guse_guse',
      'gibbsy66',
      'jymbudd',
      'paddydocherty',
      'TheGreatRejoin',
      'Vicktor1111',
      'mckinlay_liz',
      'nulasuchet',
      'TymVirginia',
      'VanPeterselie',
      'Yogablackbelt',
      'JKLDNMAD',
      'WestNitaFBPE',
      'Mikemccandles',
      'catherinemginn',
      'MaruiNeko33',
      'bertdove',
      'heather63262308',
      'TaniaAlcor',
    ],
  },
  {
    DailyMailUK: [
      'piersmorgan',
      'MailOnline',
      'LaurenGoodger',
      'KirstieMAllsopp',
      'SensoCrtico1',
      'Prof_Umbongo',
      'marionste',
      'rodrigocosma',
      'shiremoorpotter',
      'Brixton_Angel',
      'peristeraRa',
      'NeilJamesWeekes',
      'canadiancarol1',
      'PoliticoAnatomy',
      'BillieO2',
      'EdwardCookson2',
      'terryelaineh1',
      'AndyHammers',
      'frLarousse2',
      'krezmien',
    ],
  },
  {
    CityTheatreRep: [
      'JCPinMerseyside',
      'mSharronSpice',
      'debbiebridge',
      'AriyaLarker',
      'schnoddelbotz',
      'ElicitTheatreCo',
      'Nadia_Fenty',
      'FinnOldfield',
      'HiwireEntertai1',
      'ThomasKleimann',
      'barcles2',
      'TSC_COMPANY',
      'AliciaABayly',
      'Tony_Hickson',
      'QuinyKDill',
      'SheepishPR',
      'LucyBellSW',
      'NFDacting',
      'MeganSwaisland',
      'vamphire_com',
    ],
  },
  {
    Iftykhan15: [
      'neelikhan',
      'SULTAN_KI_SENA',
      'BeingAarohi8',
      'human9922',
      'HannanxTweetx',
      'itz_faiz',
      'ibeing__naina',
      'beingsrbsumit07',
      'LokeshSatwani2',
      'ek_tha_tigerrr',
      'sjkamble25',
      'BeingSalwa',
      '0Tuhin0',
      'jaykajain881',
      'basavaraj_gb',
      'mystery07255',
      'BeingShivaGowli',
      'aruna_sk',
      'PatelPr10789',
      '_Being_Prem_',
    ],
  },
  {
    iBANCOIN: ['smallcappick'],
  },
  {
    afintlbrk: [
      'HNajafizada',
      'LinaRozbih',
      'JJohnson2u',
      'natiqmalikzada',
      'thoton9',
      'mujtaba_haris',
      'Khalifaevaz',
      'ziaatifi',
      'SAMRIReports',
      'EsraKarahindiba',
      'RaminMazhar',
      'JAshtarany',
      'NilofarSakhi',
      'fhzadran',
      'ClarissaVance16',
      'naotoaitai',
      'TiliakouK',
      'AmirMRamin',
      'Alireza97222885',
      'SameaShanori',
    ],
  },
  {
    LibyanIntegrity: [
      'abuhilalah',
      'MohamedHnid',
      'farukonalan',
      'AristideBanceVU',
      '58YJxw3UnFiNE0M',
      'RubioAnnabel',
      'LibyanIntegrity',
      'BMoon_bee',
      'mazlibya',
      'Desertup',
      'AbdulkadirCay',
      'Nurilhayat',
      'tarek_cher',
      'ly3122013',
      'riyazulkhaliq',
      'YvonneSnitjer',
      'kingbirds81',
      'LaylaG11825502',
      'haque_shahidul',
      'AnatolianLyceum',
    ],
  },
  {
    InvestmentTalkk: ['davidbelle_'],
  },
  {
    caiwuunion: [
      'scot_tigger',
      'annetteashley61',
      'CleanersFor',
      'worker_group',
      'ADCUnion',
      'GreenLibLeft',
      'PrettyHatMech',
      'irondela',
      'iwweastern',
      'einsteindurango',
      'mmurty6',
      'granuledadcliff',
      'paulalvareza029',
      'Jhonhenrysalaz2',
      'camoci30',
      'Laurel62636461',
      'WagnerMina2',
      'AngelaAbrego8',
      'MiriamOjedaGue1',
      'MonicaMariaArr1',
    ],
  },
  {
    _Matty723: [
      'yoniweisberg',
      '_Matty723',
      'ianyoungkop',
      'dcallydesign',
      'Dubsy_chat',
      'officialdevb',
      'TonyDJTStanding',
      'LFC_bot_v1',
      'Stu_Palmer',
      'Bonalbio24',
      'legendsoffootie',
      'marquesbuckley1',
      'FitchLFC8',
      'caitlinstevo14',
      'MarkYozzer86',
      'LintelinoLinus',
    ],
  },
  {
    wtf1official: [
      'Racingnews2015',
      'eleaannor',
      'galancantu',
      'dennismons',
      'Petalouda_',
      'RRKCofK',
      'CityzeNish_',
      'RolinhoPensante',
      'aggidilimanto',
      'russelldarocket',
      'hannamilkovich',
      'Charlie_Gaze',
      'eIpege',
      'enrico_ahlig',
      'jeicksonmndza',
      'IniKabaYan',
      'JohnMagneTrane',
      'TheTechnician44',
      'autorace_f1',
      'adoreEDM_Justin',
    ],
  },
  {
    ozgeozdemir: [
      'candundaradasi',
      'ozgurmumcu',
      'Armagan_caglaya',
      'vedatmilor',
      'yektakopan',
      'kaansezyum',
      'aksezen',
      'ahuozyurt',
      'ozanbingoll',
      'TansuYegen',
      'cigdemtoker',
      'RefetGurkaynak',
      'otisabi',
      'irfandonat',
      'AlatonLeyla',
      'Istanbultelaviv',
      'mstfsvn',
      'cilergecici',
      'GokbelCagdas',
      'GoksuAytug',
    ],
  },
  {
    utdreport: [
      'comradefyat',
      'myFavFilms',
      'max_sticks',
      'NafisaYakubu01',
      'UncoPaaTee',
      '__TIm______',
      'ismomanyi',
      'EdBateman84',
      'iam_terabyte',
      'lord_mannie',
      'shegelege_218',
      'waru254',
      'Ajayyyy___',
      'united_focus_',
      'iamWekesa',
      'Bhavya_Kaushik',
      'kwame_agyei',
      'AlexanderRudol3',
      'ojonugwa92',
      'ManUtd_BITW',
    ],
  },
  {
    AlexInAir: [
      'ShashiTharoor',
      'candundaradasi',
      'toksik08',
      'MissVVeee',
      'OVargas52',
      'MijuLee_Tz',
      'JKabuleta',
      'osasuo',
      '_TheUnbothered',
      'MissChisomo',
      'SIGMA__LADY',
      'Icon__08',
      'Bhambathah_',
      'ElidKNaz',
      'MissLesegoS',
      'EOkala',
      'Ruthie19',
      'ameliabhebhe',
      'gatmanuk1',
      'DipendraDipzo',
    ],
  },
  {
    DariusGaruolis: [
      'JuniorEbong',
      'mertevg',
      '2mambaout',
      'winchestrange',
      'rebel_crimson',
      '_Macijauskas_',
      'DariusGaruolis',
      'GuiBermejo',
      'cyril_cmch',
      'alimhdblr',
      'cemkoroglu',
      'alex_lagk',
      'okanbaytin',
      'TRA7000',
      'RH_zade',
      'YellowCapCanry2',
      'erdembuyuknalb1',
      'LucaNardo97',
      'atakangneyy',
      'kyvesely',
    ],
  },
  {
    Nft_Spacebar: [
      'tariktolunay',
      'ziontaxi',
      'IpekMorel',
      'fractalistanbul',
      'canaksupsd',
      'esraeslenillust',
      'Jeremy972i',
      'OZMediaNFT',
      'hiceku',
      'osmankbt',
      'IBozatak',
      'Ali_Molavi',
      'a_tchuca',
      'Nft_Spacebar',
      'lcrypt0saurusl',
      'sonarklang',
      'CryptoStatuette',
      'feliciolost',
      'anonimlesmek',
      '_miyorum',
    ],
  },
  {
    KyleWOrton: [
      'leventkemaI',
      'Natsecjeff',
      'KyleWOrton',
      'Rezhasan',
      'ibnkafka',
      'aj_jobs',
      'MarkABentley',
      'propornot',
      've10ve',
      'CarolineRose8',
      'EveKeneinan',
      'Mucagcebe',
      'Gus_802',
      'Firezstarter1',
      'Ayei_Eloheichem',
      'sophianictract',
      'BaileyPittipat',
      'kwasylewsky',
      'ChrisANethery',
    ],
  },
  {
    kareem_medhat: [
      'urfavadult',
      'KkgnEH7qrMtmOby',
      'Fotna__',
      'nowitsdina',
      'TheDollzNstuff',
      'AhmedFathy',
      'sunflowerlovero',
      'noragamal68',
      'Arwaaaa__',
      'Furrrrida',
      'jkbadawy',
      'autophobiaR',
      'Nohaasabeerr',
      'MaiOmara_',
      'S_Lilish',
      'Radwael_kashef',
      'hebataha402',
      'MonaNaf3',
      '_Ahm93',
      'BOoRomana',
    ],
  },
  {
    TheOrwellPrize: ['afshinrattansi'],
  },
  {
    FindShowsNearMe: [
      'mysouthend',
      'Turbowolfband',
      'iainmcquillan',
      'FindShowsNearMe',
      'ProtestMusica',
      'TheDustCoda',
      'SBPMusicUK',
      'Edinburgh_forum',
      'Bexhill_On_Sea',
      'sheffield_tf',
      '_lovemusicals',
      'NMardler',
      'reviewnortharts',
      'TeddySwimsFAN',
      'dandliondlion',
      'jw_karma',
      'tomconnors4',
      'sooz_78',
    ],
  },
  {
    annettedittert: [
      'peterjukes',
      'RikeFranke',
      'akm0803',
      'Cedar50',
      'RandomlyGeorgea',
      'guse_guse',
      'aunty_shirley',
      'NE4EU',
      'JohnPotts2',
      'Jeffrey_Black',
      'Mushroom_Elect',
      'BFujer',
      'Kristine3108',
      'NSelkie',
      'germanacanzi',
      'JohnHol32019188',
      'caz5697',
      'Radischorsch',
      'crispmp',
      'MushyMelbowHead',
    ],
  },
  {
    Steven_Swinford: [
      'piersmorgan',
      'hugorifkind',
      'steve_hawkes',
      'jacquep',
      'SpanishDan1',
      'rpOliveira',
      'aunty_shirley',
      'antic2000',
      'The_ChrisShaw',
      'LouisHenwood',
      'rudemrlang',
      'Mexico1978R',
      'respros',
      'FbpeRoberts',
      'alan_rowse',
      'CoastPaul',
      'aBitSquirrely',
      'JPLT59',
      'anghelides',
      'norock',
    ],
  },
  {
    MattyWTF1: [
      'the_travor_guy',
      'Racingnews2015',
      'logansuspence',
      'ErnestAkor',
      'GDAstory',
      'Aradise256',
      'Chiira',
      'TheBossGent',
      'swambi',
      'That_guy_moha',
      'fredbteich',
      'yorkielover2006',
      'landosoylago',
      'yana_s_37',
      'mboes73',
      'kane_sy98',
      'Williammuir01',
      'boborsky',
      'Wellzy95nufc',
      'sstanset',
    ],
  },
  {
    PeterTatchell: [
      'labourstart',
      'starrcongress',
      'PPHRtweets',
      'gursmehmet',
      'seb293',
      'Pyrmha',
      'sohoprint',
      'Kendal_Uzun',
      'Preamble_Green',
      'turracoo1',
      'erictlee',
      'byzantinologue',
      'LetsRoc137',
      'mac35red',
      'MT4tk',
      'Cegerxwin621',
      'CHPSRE',
      'LyndonRosser',
      'freecihanerdal',
      'industrialdemoc',
    ],
  },
  {
    pangalactic: [
      'digitalmaverick',
      'JamesIntrocaso',
      'KiennaS',
      'panarin_misha',
      'TrebuchetOps',
      'TheDovetailor',
      'whatdoyoudopods',
      'LapisLunaris',
      'OrdoaleaP',
      'jl_nicegirl',
      'BabblegumSam',
      'SoupGargoyle',
      'puckett101',
      'capacle',
      'whipstache',
      'caberlitz',
      'DDemonEclipse',
      'ThoughtyGames',
      'MariaMison',
      'BrubruGeek',
    ],
  },
  {
    ocalanfree: [
      'Union_NewsUK',
      'marxismocritico',
      'TSSAunion',
      'scot_tigger',
      'SweeneySteve',
      'JoeEDwyer',
      'ilhandogus',
      'workersuniting',
      'TUFAC4',
      'Evin_Jiyan_21_P',
      'TonyBurke2010',
      'sarahwoolley01',
      'bunnylookin',
      'emilyapple',
      'KurdishOrg',
      'BehnamZarei21',
      'BFAWUOfficial',
      'MedArsiv',
      'Colin43069335',
      'MattNLabour',
    ],
  },
  {
    KawaKerami: [
      'calxandr',
      'FatmaFaizi',
      'natiqmalikzada',
      'ForughTabish',
      'achandwar',
      'Olasyaar007',
      'SAMRIReports',
      've10ve',
      'AfgFactChecks',
      'KawaKerami',
      'RajaArsalanKhan',
      'FreeBeYouMe',
      'saxotrombone',
      'awkward_penyu',
      'daknews',
      'TheNihariKing',
      'Asfand_uop',
      'beyzanurapaydin',
      'nurechashm',
      'DeepaParent',
    ],
  },
  {
    dpatrikarakos: [
      'corinnamilborn',
      'BHL',
      'MatthewdAncona',
      'Nervana_1',
      'J_Bloodworth',
      'dpatrikarakos',
      'e_amyna',
      'Ziya_Meral',
      'NasimiShabnam',
      'KateMaltby',
      'DavidHirsh',
      'NedosVassilis',
      'juliaskripkaser',
      'TuckerSamuel1',
      'SamuelSokol',
      'NeilBlairTBP',
      'ellehardy',
      'yevhenfedchenko',
      'GeorgeTetradis',
      'EEmerson14',
    ],
  },
  {
    abdullahalsaleh: [
      'abdullahalsaleh',
      'boibraheem',
      '_AFd3',
      'tarind_warah',
      'ALKHAMIS__',
      '3adeeemQ8',
      'AwadAl_',
      'clickq8',
      'loveeq8',
      'al_m6eriah',
      'kuwaity_saree7',
      'Salahalkhamees',
      'alhajre791',
      'fahadq801',
      'Fistaqyo',
      'OSAQ801',
      '_alhakm',
      'boderar',
      'Laf_hamood',
      'jallawati',
    ],
  },
  {
    ophelynn: [
      'intrnetdaughter',
      'BirdRespecter',
      'coolp1np',
      'mustachetoilet',
      'stephane_ulrich',
      'majhailinexile',
      'druipendejongen',
      'kielbasagarage',
      'JDsBunker',
      'GACKTed',
      'himbodotgov',
      'VJ',
      'ophelynn',
      'kels_316',
      'judyrudin',
      'rosaslutzemburg',
      'Red_br00',
      'DMkpanam',
      'workofbourke',
      'Titi_Suru',
    ],
  },
  {
    GMB: [
      'piersmorgan',
      'camanpour',
      'British_Airways',
      'scott_mills',
      'KirstieMAllsopp',
      'Awarding_Women',
      'BillyJWells',
      'maelysdervenn',
      'JohnJamesTV',
      '599bt',
      'NeilEastell',
      'PaulMacBookPro',
      '10yearsaway',
      'AdrianCleasby',
      'trom_laighe',
      'WoolacombeMosq1',
      'MosqueTenbWales',
      '55Bluesgirl',
      'herrbartlett1',
      'kathleenmwade',
    ],
  },
  {
    KyleJGlen: [
      'candundaradasi',
      'ArminWolf',
      'Jake_Hanrahan',
      'no_itsmyturn',
      'APHClarkson',
      'Aufildubosphore',
      'COUPSURE',
      'kulturbolschewi',
      'AliTahmizian',
      'GeromanAT',
      'Whiteeagle210',
      'ArtyomTonoyan',
      'simonforco',
      'yevhenfedchenko',
      'Kostian_V',
      'nancykric',
      'ApiWenuwen',
      '_RedFrog',
      'ONC3X',
      'Elmoyan15',
    ],
  },
  {
    ufouapam: [
      'TheZignal',
      'ChaseKloetzke',
      'LuAngeles',
      'Lynda_Katerra',
      'jr_chillin550',
      'NoelmcArdle',
      'Borders750',
      'nosleinad7',
      'mlw975',
      'Lambo1966',
      'BklynUFOHunter',
      'thtonetime',
      'BFCPLUTO',
      'chrisfrench83',
      'thoreauawhelan',
      'AWaifSoul',
      'fun_uap',
      'jamesjack',
      'Adam_295',
      'timbo_staley',
    ],
  },
  {
    JamalMOsman: [
      'jonsnowC4',
      'iyoba4u',
      'althecat',
      'vitsil64',
      '2bEritrean',
      'AmennRahel',
      'hafsamohamed1',
      'digitaldjeli',
      'AbiyFrom',
      'Sadik_Mahd',
      'JonAbbink',
      'ORC35',
      'kellyogome',
      'Fraol_GS',
      'Abdi_AlSheikh',
      'Zee_Zed_Z',
      'teddyyeneniguss',
      'howlpolitically',
      'gtesfa',
      'ToyarYimam',
    ],
  },
  {
    ByrneBarry: [
      'TheHirstComment',
      'MarianneSansum',
      '2tweetaboutit',
      'paulafr68963440',
      'flossyflange',
      'jaycee1001',
      'TegoArcanaDei',
      'andie1105',
      'docuterror',
      '_TheEnquirer',
      'Bretwaldas',
      'Olgachristie',
      'FlowersEnglish',
      'Jojones2762',
      'Smileygirl2706',
      'johndmtb',
      'Para_glider69',
      'susanstanwix',
      'Phoebejoy1611',
      'MickGreenhough',
    ],
  },
  {
    TarikToros: [
      'ademyarslan',
      'akinipek01',
      'ezop2011',
      'Prof_Ozsoy',
      'nevinipekk',
      'SonnPerdem',
      '4Suvariii',
      'HAKKIYEMEZLER',
      'Profiozturk',
      'HurPasaport',
      'aysacar',
      'l_cakiroglu',
      'senceneolsunki',
      'yorukcafer',
      'tutsakavukat',
      'buraktahir34',
      'MANSURTURGUT',
      'admyklmz',
      'zerre046',
      'rukkoc',
    ],
  },
  {
    RutherfordApLab: ['isisneutronmuon', 'UKCatalysisHub', 'brightnesseu'],
  },
  {
    ilhanpolat12345: [
      'A_Fettah_Sahin',
      'TutsakBebekler',
      'ciftlers76',
      'hy_06_',
      'halit_esendir2',
      'PatrickJane02',
      'Abdulla86296315',
      'Ekrem41406612',
      'MeralinNotu',
      '35denizderin',
      'zerre046',
      'El_remember_',
      'CihanMedyaLu',
      'funda3523',
      'KhkLiTakiplesme',
      'Y42Yildizlar',
      'Azrakpinar',
      'SonSungur01',
      'alivl1717',
      'Fraumulteci',
    ],
  },
  {
    jrmygrdn: [
      'JPN_LISA',
      'jmkorhonen',
      'joshdr83',
      'AngelicaOung',
      'nuclear94',
      'MayaPosch',
      'cloudy_cl',
      'VeroWendland',
      'energybants',
      'thiesbeckers',
      'Syndroma3',
      'QContrarian',
      'AvvocatoAtomico',
      'chrispydog',
      'GaopalelweBigG',
      'HenryK_B_',
      'CommieGIR',
      'sennekuyl',
      'ConradKnauer',
      'DrewSmithee',
    ],
  },
  {
    guardianeco: [
      'GretaThunberg',
      'melisalphan',
      'ChristineMilne',
      'SaleemulHuq',
      'MrMatthewTodd',
      'redmayne_robert',
      'mwt2008',
      'mikealbeland',
      'Culldogfarms',
      'laurelworlds',
      'lange_tobias_hh',
      'Dragofix',
      'beaudyk',
      'srzjwalters',
      'Mr_Spock',
      'NsAPesarDeTodo',
      '_RedFrog',
      'JohnthetreeWeir',
      'flightbomb',
      'RocketRacer69',
    ],
  },
  {
    SueSuezep: [
      'mancunianmedic',
      'AgainstHunting',
      'ThomasEvansAdur',
      'heardbarry',
      'unojen_wood',
      'Daisybelll',
      'bloggerheads',
      'JohnEJefferson',
      'MerryMichaelW',
      'incorbynsname',
      'CarrollJackie99',
      'mckinlay_liz',
      'Mr_ReadingTown',
      'Jc4pmTomski',
      'BarbaraSutton15',
      'ernlaugh',
      'farley697',
      'fussydolphin',
      'MarisaDrucker',
      'JanmRees1',
    ],
  },
  {
    petemorristwit: [
      'YvetteHenson',
      '1NickMill',
      'El3atar62',
      'bremner1919',
      'lsrlinda',
      'alfaqfour',
      'rospay15',
      'GreatScent',
      'donaldpenman2',
      'Warwicktweets',
      'TonyWil29738019',
      'Jeanett49751382',
      'AndyWells7',
      'deejayhome',
      'chrisjackson193',
      'Christi77411315',
      'Blayney20',
      'pastoralview',
      'sallybaloo',
      'TruthVulgarians',
    ],
  },
  {
    JavierBlas: [
      'GretaThunberg',
      'gmbutts',
      'BenjaminHarvey',
      'Lohana1983',
      'TraderIrene',
      'BRyvkin',
      'ProfBShaffer',
      'BettyB____',
      'agturbobrazil',
      'Ronniemarkets',
      'riskparty63',
      'TraderPBurns',
      'mburtonmetals',
      'PepsiGro',
      'Orangeman1992',
      'justinvela',
      'WaqarEY',
      'ZeVinzJ',
      'jakluge',
      'CraigRatbag',
    ],
  },
  {
    SirJJQC: [
      'ProfBrianCox',
      'StevePeers',
      'SpanishDan1',
      'acatfromgreece',
      'Calimocho14',
      'BJ_Gardener',
      'lordbonkers',
      'connolly_lg',
      'jhazan',
      'WGAbroad',
      'ProfJeffKenner',
      'lloyd_dennis8',
      'KingKing3107',
      'Idontmind64',
      'londonyc',
      'Peradventur3',
      'TheSandlings',
      'ACarpenDigital',
      'TheAmericanMag',
      'CstlinC',
    ],
  },
  {
    BhavikaKapoor5: [
      'BhavikaKapoor5',
      'ARajesh_SP',
      'Stanalb',
      'INCian_Haris',
      'dranupmodgil',
      'nayyar76',
      'MirzaVoice',
      'Dayakar67650633',
      'Alien_India',
      'pmssit',
      'siddiq_faheem',
      'SinghTivendra',
      'samyagdrsti',
      'ypstomer',
      'pula_reddy',
      'Mansoor19302780',
      'LOC_bbk',
      'Tiger_RaGa',
      'ahmad_sayeem',
      'vkjha62',
    ],
  },
  {
    Nervana_1: [
      'umitnazliboyner',
      'BarakRavid',
      'fispahani',
      'ebabahan',
      'WashingtonPoint',
      'yavuzbaydar4',
      'MariosKaratzias',
      'ProfElvanAktas',
      'stevenacook',
      'Nervana_1',
      'ciftlers76',
      'NavroopSingh_',
      'AlsharifSKY',
      'sertac_aktan',
      'MUDDLAW',
      'heissenstat',
      'AlirezaNader',
      'oulosP',
      'irinatsukerman_',
      'aykan_erdemir',
    ],
  },
  {
    DoughtyStPublic: [
      'DoughtyStPublic',
      'Amal_Style1',
      'calibasak',
      'DoughtyStIntl',
      'FreeShingal',
      'glsurvivorsfund',
      'reginadelira',
      'NiallRobinson__',
      'jacky_jahnel',
      'angelaescobar65',
      'Butchard6',
      'perrilyons',
    ],
  },
  {
    hajegbal: [
      'nafsmotmaeneh5',
      'Fatiwh3883',
      'harandi1367',
      'ali_s1352',
      'Foroutan_ir',
      'mohammad_agha17',
      'h_heidarpoor',
      'AbolfazlBalam',
      'asieh63802702',
      'KarimHaggi',
      'hajegbal',
      'suspendovich01',
      'iraneshomali',
      'AmaniHadise',
      'Forever_Green15',
      'm_artesh',
      'Rheydari01',
      'salimi_ir',
      'Saeran_2002',
      'mhshobeiri459',
    ],
  },
  {
    vandalgil: [
      'kaziklimaria',
      'snyzbgl',
      'icarusvari',
      'vandalgil',
      'NihanAksakalli',
      'SodomluTora',
      'zeynepthekedi',
      'kcgyd',
      'hazalisthenew',
      'WhiteCatEcem',
      'Veloriel',
      'RukiyeArcan',
      'kumdankadinn',
      'OzgeKatip',
      'LeydiBuckingham',
      'clndstna',
      'cileklilipstick',
      'NarnarNp',
      'KantarUmit',
      'rukenyum',
    ],
  },
  {
    mikeginsberg98: [
      'DailyCaller',
      'GOPoversight',
      'RepJamesComer',
      'kebie19',
      'OptimisticCon',
      'RonaldBruceBar3',
      'LeeSutton4',
      'BarbieSueJ',
      'jefe_viejo',
      'PatriciaWilhoit',
      'brianjsymes',
      'JessTer62203870',
      'pretty7159',
      'cptcookswife',
      'michaeljashmore',
      'SherryC82778084',
      'yaya51517',
      'DesertReporter',
      'Harrytraylor4',
      'AmyDay25286027',
    ],
  },
  {
    misterbrilliant: [
      'Brainmage',
      'MichaelToole',
      'itsJenSim',
      'Patrick_Fenelon',
      'DarylSurat',
      'tenoq',
      'APZonerunner',
      'the_gneech',
      'DJWeedPizza',
      'mumbly_joe',
      'FrankenTan',
      'whemleh',
      'chairmanmaou',
      'RightSaidJames',
      'PrototypeCube',
      'coolt_skelett',
      'uncreativecat',
      'VicPerfecto',
      'rodakscreens',
      'HarleyPan',
    ],
  },
  {
    Rtaghizade: [
      'freeman010',
      'AzadehKhorasan',
      'Nastara99638495',
      'Nationalist101',
      'Asadi222Mehrdad',
      'Rooham61',
      'MohamadiniaAli',
      'BayatMahboubeh',
      'Reza57147956',
      'MahmoudiArsam',
      'ArashKaveh5',
      'frarandeh',
      'Hoghogh_Zanan',
      'mohsen121',
      'GlasgowTweets_',
      'ShirAfkan1968',
      'jakbar70631285',
      'aria_irani48',
      'samyar45472518',
      'joker_1398',
    ],
  },
  {
    '5by5Creativity': [
      'docrussjackson',
      'bethanyusher',
      'matilda_w_',
      'legwill',
      'highersynth52',
      'JL_998',
      'marciaj64',
      '_mrgrumps',
      'DrSophyAntrobus',
      'NadimJBaba',
      'Frasmi',
      'harphat',
      'Patinahat2',
      'JimCforEU',
      'etkwebster',
      'frazerc',
      '6d7f272b973849c',
      'kubrickslenscap',
      'juliadarwen',
      'GallahersBlues',
    ],
  },
  {
    DrJEBall: [
      'openculture',
      'marinamaral2',
      'ebruenig',
      'AndreaLamber',
      'AntheaButler',
      'otepofficial',
      'holdengraber',
      'melgua86',
      'ADavidHaleJoint',
      'ByDavidMurphy',
      'DigiVictorian',
      'SelineSigil9',
      'Templesmith',
      'KatiePuckrik',
      'TrimontiumTrust',
      'rogueclassicist',
      'yubartina',
      'folk_horror',
      'KS1729',
      'AlanLevinovitz',
    ],
  },
  {
    StevePeers: [
      'TerryReintke',
      'Frances_Coppola',
      'tashecon',
      'SarahLudford',
      'SpanishDan1',
      'JohnWest_JAWS',
      'stand_for_all',
      'alan_matts',
      'Calimocho14',
      'DevonianMatthew',
      'colmocinneide',
      'JulienHoez',
      'ronpatz',
      'TheFabledAesop',
      'Yazzy_321',
      'CountrySkills',
      'Resourceful1942',
      'PhilMorandi',
      'MarktNailham',
      'LittleGravitas',
    ],
  },
  {
    KlasfeldReports: [
      'JuddApatow',
      'ugurses',
      'KadriGursel',
      'umit_k',
      'rodrikdani',
      'cansucamlibel',
      'BenjaminHarvey',
      'TimInHonolulu',
      'GottaLaff',
      'Out5p0ken',
      'gaynycdad',
      'BaddCompani',
      'laurapitel',
      'Megawatts55',
      'heissenstat',
      'xtrixcyclex',
      'aykan_erdemir',
      '06JAnk',
      'RRalstonAgile',
      'janetcalif',
    ],
  },
  {
    talkRADIO: [
      'piersmorgan',
      'JuliaHB1',
      'whippletom',
      'LSW12612672511',
      'HuntedTeamNick',
      'FWDwholesale',
      'CopyOfOne',
      'LucyWernerPR',
      'garside_geoff',
      'UturnSarkar',
      'Track7778',
      'AbiChippindale',
      'ShaytanTruth',
      'miraforeman',
      'billybee9991',
      'ShirleyParnel11',
      'antisnpgranny',
      'auntysocialist',
      'HfromHorley',
      'Maturin50',
    ],
  },
  {
    MilenaBuyum: [
      'candundaradasi',
      'ETemelkuran',
      'banuguven',
      'asliaydintasbas',
      'KadriGursel',
      'umit_k',
      'rodrikdani',
      'ebabahan',
      'yavuzbaydar4',
      'sarahleah1',
      'P24Punto24',
      'StefSimanowitz',
      'fgeerdink',
      'Cemrebirand1',
      'LindseySnell',
      'amnestypress',
      'RenginArslan',
      'IFEX',
      'iremafsin',
      'juremawerneck',
    ],
  },
  {
    CBASS_Research: [
      'BZephaniah',
      'Bruneluni',
      'routledgebooks',
      'BrunelResearch',
      'BrunelWriter',
      'EnglishatBrunel',
      'CBASS_Research',
      'WWatkinBrunel',
      'elizakania',
      'sebjenner',
      'KaesiF',
    ],
  },
  {
    isimsizhareket: [
      'LeventUzumcu',
      'nasrullahayan',
      'Serhat_Halis',
      'janetcalif',
      'ulakMehmet06',
      'Desdina362',
      'mavisim1111',
      'Filiz4482',
      'Filinta_65',
      'HakanSan_',
      'aantifasistt',
      'Mehmet646',
      'BnProle',
      'Deniz35029494',
      'sendromsuZisci',
      'dbirinci54',
      'Salih0106',
      'SSeher4',
      'Canan2152_',
      'xatunkz',
    ],
  },
  {
    ConCotzias: ['TurkWonk', 'kaileyleinz', 'ConCotzias'],
  },
  {
    Blayney20: [
      'petemorristwit',
      'OutragedOf',
      'MarilynAshy',
      'CrathernKim',
      'peter_bucksey',
    ],
  },
  {
    Marcotti: [
      'harun_tekin',
      'Marcotti',
      'BarryTownSC',
      'Ninja_Atl',
      'jockykenny',
      '2suuuu_',
      'EarlofAbeokuta',
      'KOCricket528',
      'Spiderchoc',
      'greenwatcher17',
      'Abbi1619',
      'sebastianrcgr',
      'hamdanihussein1',
    ],
  },
  {
    docrussjackson: [
      'docrussjackson',
      'celtjules66',
      'Crewesmissile',
      'irisstylosa',
      'Normanjam671',
      'Lesleygsmith3',
      'SueJonesSays',
      'SollyKathryn',
      'BHPanimalwatch',
      'equi_noctis',
      'IrlagainstFash',
      'bilbalbag',
      'divadarlings1',
      'bane_baldy',
      'zeerlin12',
      'fbpe_h',
      'FedupMad',
      'jpjanson',
      'UNIQfurniture',
      'datchmog',
    ],
  },
  {
    OliviaAlabaster: [
      'BremainInSpain',
      'gavindjharper',
      'GraceMurphy2',
      'selzero',
      'ciabaudo',
      'jonesr2310',
      'NtNigel',
      'SallyFereday',
      'stop1984',
      'bluewendysday',
      'markrowantree',
      'FranJWilliams',
      'jurgenvontopfer',
      'CarbonisedPleb',
      'orkneywebdesign',
      'OdeToJoyEU',
      'BtaD42255016',
      'garethjllewelyn',
      'Carriecakes80',
      'bobjmsn',
    ],
  },
  {
    zooinspectors: [
      'zooinspectors',
      'juliana_monty',
      'LavaBoils',
      'Stella20203126',
      'Bazthebearuk',
      'boortzthebozo',
      'juliejonesxx',
      'ElizabethHatt12',
      'SavanaWoman',
      'war_anti',
      'Davide70405052',
      'petrapidges',
      'SChecinska',
    ],
  },
  {
    Ericdun19416783: [
      'NickPye10',
      'karengilly',
      'Keithpoker1',
      'JeSuisTinkerbel',
      'Shelo78334355',
      'Grumpy40274816',
      'SteveG06322664',
      'PollyIce2',
      'roxannerandel',
      'EnglishLionhea1',
      'Cabarfeidh59',
      'Dave0London',
      'sreynolds196777',
      'tandoreo',
      'Jillyhar2020',
      'Julie229404878',
      'Deadferrets',
      'wereallgoingdei',
      'Jackied15846926',
      'MediumDebyOuld',
    ],
  },
  {
    LiamThorpECHO: [
      'ProfBrianCox',
      'carolecadwalla',
      'thijsromer',
      'Daisybelll',
      'RichardTol',
      'AndyBounds',
      'JulieLeyGibbs',
      'ViewFromEngland',
      'EuEthnic',
      'neighbour_kx',
      'David_Batty',
      'PeterFl85166213',
      'YourTown_Oldham',
      'molieresloge',
      'SusanKHeflin',
      'Mogleeone',
      'bmwelby',
      'LawrenceConnol2',
      'EoghanMacguire',
      'quixoticgeek',
    ],
  },
  {
    SqsF7: [
      'amal198711',
      'iadam_',
      'Abdelrazek_Jr',
      'TamerELshakanky',
      'Saraturky0',
      'jihad2001',
      'Mohamma32495175',
      'obeidaj',
      'MrHassanHashem',
      'rabikarim55',
      'amerRev',
      'EngMahmoud_Nasr',
      'bashar123418',
      '2NUQswWulAFOMEP',
      'grassvalleyth',
      'Mohamme04652993',
      'AhmedMo05760473',
      'Meda_Billy',
      'Ehabahm88185350',
      'AlaaAlkit',
    ],
  },
  {
    NatashaRudraCT: [
      'TomMcIlroy',
      'reinventionfail',
      'chaim_aron',
      'irris',
      'MissLucy_2011',
      'EwokHunter1',
      'NavyMusings',
    ],
  },
  {
    grahambsi: [
      'SydesJokes',
      'MaldenSaboteur',
      'Mistywoman1',
      'steviweavi',
      'DesFbpe',
      'johnthebrexile',
      'mckinlay_liz',
      'GreatAunty1',
      'HuwpHuw',
      'Davidj916',
      'AHaglington',
      'TookeKris',
      'DNewtonC',
      'philt5116',
      'JKLDNMAD',
      'emeicen',
      'CathyHaglington',
      'vjl2',
      'barbjungr',
      'EuropeFife',
    ],
  },
  {
    Jake_Hanrahan: [
      'AlexanderNL',
      'IwriteOK',
      'ticiaverveer',
      'IKucukparlak',
      'BenjaminHarvey',
      'HasnainKazim',
      'AydnSelcen',
      'TimInHonolulu',
      'sfrantzman',
      'IsinElicin',
      'joeyayoub',
      'jkass99',
      'e_amyna',
      'mickthehack',
      'cngzkync',
      'ml_1maria',
      'AlbertoMiguelF5',
      'Leylaimret_73',
      'VivaRevolt',
      'iAmHaks',
    ],
  },
  {
    CanalplusF1: [
      'infosportplus',
      'CanalplusSport',
      'Julien_FEBREAU',
      'rnaudmeunier',
      'FranckDeAbreu',
      'PascalCostanti1',
      'nhoizey',
      'Thomas_Liard',
      'lovingleclerc2',
      'Robinho10___',
      'JeanDoobs',
      'Toden_LAviateur',
      'Rubenzf911',
      '601_14',
      'BigJayz25',
      'jbSFOM13',
      'FraMat_Inc',
      'QueFoot',
      'springinsfeldje',
      'levycoco',
    ],
  },
  {
    fersalvaterra: [
      'PrintInkling',
      'Archarzel',
      'capacle',
      'DominarChris1',
      'GardenGM2',
    ],
  },
  {
    raging545: [
      'TimInHonolulu',
      'LiuZhongjing',
      'brianbigelow',
      'JJohnson2u',
      'bernerlap',
      'BeverlyFolkers',
      'Cultiv8Hope',
      'hkb73',
      'StockScout1',
      've10ve',
      'AfgFactChecks',
      'ericserati68',
      'Bivi_17',
      'rottenbanana101',
      'saabelieves',
      'raging545',
      'TTysonToni1',
      'CyrusShares',
      'marciasgreen',
      'BenjaminT0001',
    ],
  },
  {
    GholamKaren: [
      'AlteaFerrari',
      'Drc_19',
      'Metanoia100',
      'dawnclark6',
      'AllaKrymova',
      'semrasevimli44',
      'VickieSchn_',
      'elsufuen',
      'kessam56',
      'dhinakaranrajan',
      'baykaler',
      'David77692706',
      'lrahman376',
      'bosy_tawfek',
      'el_mo_sa',
      'ele9061',
      'LavaletteAstrid',
      'vinodkumarmeht2',
      'natural000001',
      'BerneLarry',
    ],
  },
  {
    DMovies_org: [
      'DMovies_org',
      'VictorFraga4',
      'JungleDrumsmag',
      'von_kant',
      'jl_fassbinder',
      'Franzbiberkopf3',
      'ItCanOnlyBeGod',
      'dingdoorum',
    ],
  },
  {
    fuzzfoster: [
      'mirabarhillel',
      'MarcusFitzsimon',
      'Glasmerleperle',
      'DrJoeMcCarthy',
      'GloryBS',
      'Siadasha',
      'ProtectingTerra',
      'dido15',
      'mackaaij',
      'freereed59',
      'dascritch',
      'JRGStuff',
      'HolgerGelhausen',
      'Alleone',
      'longitude0',
      'dkahn400',
      'RidingtheGoblin',
      'AndrewVAD',
      'Wonkyclock007',
      'jilly_mackay',
    ],
  },
]
