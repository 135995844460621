import React, { useEffect, useState } from 'react'
import Button, { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import { CommonRuleListProps } from './types'
import commonRuleService, {
  CreateParams,
  ITelegramBotListenerMessage,
  ITelegramBotListenerMessageResponse,
} from 'services/http/backoffice/common-rule'
import Icon from '@zera-admin/icon'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { uid } from '@zera-admin/utils'

const CommonRuleList: React.FunctionComponent<CommonRuleListProps> = () => {
  const [flagcard] = useFlagcardContext()

  const [isLoading, setIsLoading] = useState(true)
  const [newLoading, setNewLoading] = useState(false)
  const [commonRule, setCommonRule] =
    useState<ITelegramBotListenerMessageResponse>()
  const [pageIndex, setPageIndex] = useState<number>(0)
  useEffect(() => {
    getCommonRules()
  }, [pageIndex])

  const getCommonRules = async () => {
    try {
      const response = await commonRuleService.get(pageIndex)

      setCommonRule(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    // const actions = (
    //   <LinkButton appearance="primary" href="/alarms/common-rule/create">
    //     Telegram kanala kullanıcı oluştur
    //   </LinkButton>
    // )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Alarmlar" href="/alarms" />
        <BreadcrumbItem text="Telegram Kanala Kullanıcı Listesi" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm telegram kanalına istekte bulunan kullanıcıların listesini
        görüntüleyebilir, yeni oluşturabilirsiniz.
      </p>
    )

    return (
      <PageHeader bottomBar={bottomBar} breadcrumbs={breadcrumbs}>
        Telegram Kanala Kullanıcı Listesi
      </PageHeader>
    )
  }

  const handleSubmit = (values: CreateParams, handleReset: () => void) => {
    if (values) {
      setNewLoading(true)
      commonRuleService
        .create(values)
        .then(() => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: 'Kayıt işlemi başarılı',
            children:
              'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
          })
          handleReset()
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setNewLoading(false)
        })
    }
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'operation',
        text: 'Ad Soyad',
        width: '100px',
        render: (item: any) => (
          <strong>{`${item?.firstName} ${item?.lastName}`}</strong>
        ),
      },
      {
        name: 'botId',
        text: 'Bot Id',
      },
      {
        name: 'botName',
        text: 'Bot Adı',
      },
      {
        name: 'chatId',
        text: 'Chat Id',
      },
      {
        name: 'message',
        text: 'Mesaj',
      },

      {
        name: 'operation',
        text: 'İşlemler ',
        width: '250px',
        render: (item: ITelegramBotListenerMessage) => (
          <Button
            appearance="primary"
            iconBefore={<Icon name="add" />}
            children={'Kanala Kullanıcı Ekle'}
            isDisabled={newLoading}
            type="button"
            onClick={() =>
              handleSubmit(
                {
                  botId: item.botId,
                  chatId: item.chatId,
                },
                () => {
                  setTimeout(() => {
                    window.location.reload()
                  }, 500)
                }
              )
            }
          />
        ),
      },
    ]

    if (commonRule) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={commonRule.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager
            pages={commonRule.pageCount}
            onChange={(index) => setPageIndex((index as number) - 1)}
          />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default CommonRuleList
