import React, { useState, useEffect } from 'react'
import { crypto } from '@zera-admin/helpers'

import widgetService, {
  Widget,
  WidgetVisualization,
} from 'services/http/bi-tool/widget'
import fieldService, { Field, FieldRequest } from 'services/http/bi-tool/field'
import datasourceService, {
  Datasource,
  Table,
} from 'services/http/bi-tool/datasource'
import { useFlagcardContext } from 'app/contexts/flagcard'
import Loader from 'components/loader/Loader'
import WidgetScreen from 'pages/bi-tool/widget/widget-screen'
import { generateTableVisualization } from 'bi-tool/visualizations/table'

import { WidgetFormProps } from './types'

const WidgetForm: React.FunctionComponent<WidgetFormProps> = ({
  id,
  onSave,
  onComplete = () => {},
  onDelete = () => {},
  operation = 'create',
  type = 'page',
}) => {
  const [isLoading, setIsLoading] = useState(
    operation === 'update' ? true : false
  )
  const [sources, setSources] = useState<Datasource[]>([])
  const [copy, setCopy] = useState(false)
  const [tables, setTables] = useState<Table[]>([])
  const [fields, setFields] = useState<Field[]>([])
  const [values, setValues] = useState<Widget>(
    operation === 'create'
      ? {
          title: 'Yeni widget',
          description: '',
          visualization: {} as WidgetVisualization,
          type: 'json',
          version: '',
          query: {
            source: '',
            table: '',
            fields: [],
            groups: [],
            orders: [],
            where: { id: crypto.id(), condition: 'and', rules: [] },
            interval: {
              start: {
                type: 'relative',
                unit: 'days',
                value: 1,
              },
              end: {
                type: 'now',
              },
            },
            limit: 100,
          },
        }
      : ({} as Widget)
  )

  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    if (id) {
      getWidget(id as string)
    } else {
      getDatasources()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const initializeVisualization = (fields: Field[] = [], reference: Widget) => {
    const visualization: WidgetVisualization =
      generateTableVisualization(fields)
    const values = { ...reference, visualization }

    handleChangeValues(values)
  }

  const getWidget = (id: string) => {
    widgetService
      .getById(id)
      .then((res) => {
        setIsLoading(false)
        setValues(res.data || {})
        getDatasources()
        getTables(res.data.query?.source)
        getFields({
          datasource: res.data.query?.source,
          table: res.data.query?.table,
        })
      })
      .catch((err: Error) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const getDatasources = () => {
    datasourceService
      .get()
      .then((res) => {
        setSources(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getTables = (datasource: string) => {
    datasourceService
      .getTables(datasource)
      .then((res) => {
        setTables(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getFields = (request: FieldRequest) => {
    setIsLoading(true)

    fieldService
      .get(request)
      .then((res) => {
        setIsLoading(false)

        if (res.data) {
          setFields(res.data)

          if (operation === 'create') {
            initializeVisualization(res.data, {
              ...values,
              query: { ...values.query, ...request },
            })
          }
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const handleSubmit = () => {
    if (operation === 'create') {
      handleCreate()
    } else if (copy) {
      handleCopy()
    } else {
      handleUpdate()
    }
  }

  const handleCreate = () => {
    if (values) {
      setIsLoading(true)

      widgetService
        .create(values)
        .then((res) => {
          const value = res.data

          if (value) {
            onSave({ ...values, id: value.data as string })
          }
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: crypto.id(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Widget eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
          onComplete()
        })
    }
  }
  const handleCopy = () => {
    if (values) {
      setIsLoading(true)
      // Remove the 'id' field from the values object
      const { id, ...newValues } = values
      // Append 'copy' to the title field
      newValues.title += ' - copy'
      widgetService
        .create(newValues)
        .then((res) => {
          const value = res.data

          if (value) {
            onSave({ ...newValues, id: value.data as string })
          }
        })
        .catch((error) => {
          console.log(error)
          flagcard.add({
            id: crypto.id(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Widget eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
          onComplete()
        })
    }
  }
  const handleUpdate = () => {
    if (values) {
      setIsLoading(true)

      widgetService
        .update(id as string, values)
        .then(() => {
          onSave(values)
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: crypto.id(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Widget eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
          onComplete()
        })
    }
  }

  const handleDelete = () => {
    setIsLoading(true)

    widgetService
      .del(id as string)
      .then(() => {
        onDelete()
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleChangeValues = (values: Widget) => {
    setValues(values)
  }

  return (
    <WidgetScreen
      copy={copy}
      breadcrumbVisibility={type === 'page' ? true : false}
      fields={fields}
      onChange={handleChangeValues}
      onGetFields={getFields}
      onGetTables={getTables}
      onSave={handleSubmit}
      onDelete={handleDelete}
      operation={operation}
      sources={sources}
      tables={tables}
      values={values}
      onCopy={(copy: boolean) => setCopy(copy)}
    >
      {isLoading && (
        <Loader show={isLoading} size="medium" label="Widget hazırlanıyor..." />
      )}
    </WidgetScreen>
  )
}

export default WidgetForm
