import styled from 'styled-components'

export const CreateAgendaReport = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;

  .content-body {
    display: flex;
    align-items: center;
    flex: content;
    justify-content: center;
    gap: 5rem;
  }
  .source-container {
    &-select {
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      & button {
        border-radius: 8px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

export const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: calc(100vh - 56px);
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;

  button {
    padding: 8px 16px;
    width: fit-content;
    background-color: #f5f5f5;
    border: none;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: end;
    border-radius: 5px;
  }
  .modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .modal-content {
    background-color: #fff;
    padding: 20px;

    min-width: 50%;
    width: 60%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    z-index: 2;

    &-video {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      height: 100%;

      iframe {
        width: 100%;
      }
    }

    &-button {
      display: flex;
      justify-content: flex-end;
    }
  }
`
