import { ButtonAppearance } from '@zera-admin/button'

import { PageProps, FormProps } from 'app/shared/types'
import { Definition } from 'services/http/backoffice/definition'

export interface DefinitionParams {
  id: number
}
export interface SelectType {
  label: string
  value: string
}
export interface SocialMediaButtonProps {
  apperance: ButtonAppearance
  onClick: () => void
  src: string
  title: string
  width?: number
}

export enum SocialMediaEnum {
  EksiSozluk = 'eksisozluk',
  Reddit = 'reddit',
  Tumblr = 'tumblr',
  Twitter = 'twitter',
  Vk = 'vk',
  Youtube = 'youtube',
  News = 'news',
  InciSozluk = 'incisozluk',
}

export type DefinitionListProps = PageProps

export type DefinitionFormProps = FormProps<Definition>

export type DetailDefinitionProps = PageProps<DefinitionParams>

export type CreateDefinitionProps = PageProps
