export const ORDER_SORTS = [
  {
    label: "A-Z'ye sırala (Ascending)",
    value: 'asc',
  },
  {
    label: "Z-A'ya sırala (Descending)",
    value: 'desc',
  },
]

export const AGGREGATORS = [
  {
    label: 'Toplam kayıt sayısı',
    value: 'countOf',
  },
  {
    label: 'Değer toplamı',
    value: 'sumOf',
  },
  {
    label: 'Değer ortalaması',
    value: 'avgOf',
  },
]

export const GROUP_INTERVALS = [
  {
    label: 'Varsayılan',
    value: '',
  },
  {
    label: 'Saat',
    value: 'hour',
  },
  {
    label: 'Gün',
    value: 'day',
  },
  {
    label: 'Hafta',
    value: 'week',
  },
  {
    label: 'Ay',
    value: 'month',
  },
  {
    label: 'Yıl',
    value: 'year',
  },
]
