import styled from 'styled-components'

export const BarDefinitions = styled.div`
  margin-top: 12px;
  width: 100%;

  div[role='tabpanel'] {
    & > div {
      width: 100%;
    }
  }
`

export const BarDefinitionField = styled.div`
  width: 100%;

  .button--settings {
    margin-top: 30px;
    height: 40px;
  }
`
