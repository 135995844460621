import React from 'react'
import EmptyState from '@zera-admin/empty-state'
import Tweet from '@zera-admin/alice-tweet'

import { TweetsProps } from './types'

const Tweets: React.FunctionComponent<TweetsProps> = ({
  data,
  profile,
  cutContent,
}) => {
  return data && data.length > 0 ? (
    data?.map((item: any) => (
      <Tweet
        comment={item.replyCount}
        entityKey={item.entityKey}
        favorite={item.likeCount}
        profilImage={profile.ProfileImageUrl}
        name={profile.Name}
        retweet={item.rtCount}
        tweet={!cutContent ? item.tweet : item.tweet.slice(0, 100) + '...'}
        username={profile.ScreenName}
        date={item.tweetDate}
      />
    ))
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Tweets
