import { request } from 'services/http/dataset/instance'

import type {
  CreateTrainingGroupRequest,
  UpdateTrainingGroupRequest,
  TrainingGroup,
  TrainingGroupResponse,
  UpdateTrainingGroupRunningStatusRequest,
} from './types'

import {
  mapTrainingGroupForList,
  mapTrainingGroupForRequest,
  mapTrainingGroupForResponse,
} from './mappers'

export const create = (data: CreateTrainingGroupRequest) =>
  request<TrainingGroup>({
    method: 'post',
    url: 'training-group',
    data,
    mappers: {
      req: mapTrainingGroupForRequest,
    },
  })

export const get = () =>
  request<TrainingGroupResponse>({
    method: 'get',
    url: 'training-group',
    mappers: {
      res: mapTrainingGroupForList,
    },
  })

export const getById = (id: number) =>
  request<TrainingGroup>({
    method: 'get',
    url: `training-group/${id}`,
    mappers: {
      res: mapTrainingGroupForResponse,
    },
  })

export const update = (data: UpdateTrainingGroupRequest) =>
  request<TrainingGroup>({
    method: 'put',
    url: `training-group/${data.id}`,
    data,
    mappers: {
      req: mapTrainingGroupForRequest,
    },
  })

export const updateRunningStatus = (
  data: UpdateTrainingGroupRunningStatusRequest
) =>
  request<TrainingGroup>({
    method: 'put',
    url: `training-group/running-status`,
    data,
    mappers: {
      req: mapTrainingGroupForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `training-group/${id}`,
  })

export const readyToTrain = (id?: number) =>
  request<TrainingGroup>({
    method: 'put',
    url: `training-group/ready-to-train/${id}`,
  })
