import React, { useCallback, useEffect, useState } from 'react'
import { Row } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'

import dashboardService, {
  DashboardAccountProps,
} from 'services/http/dashboard-ui/dashboard'
import TwitterProfile from 'components/twitter-profile'

import { ProfileProps } from './types'

const Profile: React.FunctionComponent<ProfileProps> = ({ reference }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [values, setValues] = useState<DashboardAccountProps>()

  const getValues = useCallback(() => {
    setIsLoading(true)

    dashboardService
      .getProfileDetail(reference?.raw?.id as string)
      .then((res) => {
        setIsLoading(false)
        setValues(res.data)
      })
      .catch((err: Error) => {
        setIsLoading(false)
        console.log(err)
      })
  }, [reference?.raw?.id])

  useEffect(() => {
    if (reference?.raw?.id) {
      getValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference?.raw?.id])

  if (isLoading) {
    return (
      <Row xs={{ align: 'center', justify: 'center' }}>
        <Spinner className="spinner" size="medium" />
      </Row>
    )
  } else {
    return <TwitterProfile data={values} />
  }
}

export default Profile
