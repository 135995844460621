import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import {
  GraphChartVisualizationFields,
  GraphChartVisualizationOptions,
} from '../types'

export const generateGraphChartOptions = (
  options: GraphChartVisualizationOptions,
  query: Query,
  fields: Field[]
): GraphChartVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      fields: {
        ...result.fields,
        [group.field]: group.field as string,
      },
    }
  })

  return result
}

export const generateGraphChartVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as GraphChartVisualizationFields)

  return {
    type: 'graph-chart',
    options: {
      stream: {},
      fields: {
        totalTweetCount: options['totalTweetCount'],
        totalOriginTweetCount: options['totalOriginalCount'],
        totalRetweetCount: options['totalRetweetCount'],
        totalFollowersCountOfTweetedAccounts:
          options['totalFollowersCountOfTweetedAccounts'],
        totalTweetAccountsCount: options['uniqueAccountCount'],
        totalImpressionCount: options['totalImpressionCount'],
        youtubecount: options['youtubecount'],
        newspapercount: options['newspapercount'],
        instagramcount: options['instagramcount'],
        redditcount: options['redditcount'],
      },
    },
  }
}
