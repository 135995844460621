import React, { useState } from 'react'

function Tooltip({
  children,
  content,
}: {
  children: React.ReactElement
  content: React.ReactElement | string
}) {
  const [visible, setVisible] = useState(false)

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      {visible && (
        <div
          style={{
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'black',
            color: 'white',
            padding: '5px',
            borderRadius: '3px',
            whiteSpace: 'nowrap',
            zIndex: 1,
          }}
        >
          {content}
        </div>
      )}
    </div>
  )
}

export default Tooltip
