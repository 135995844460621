import { request } from 'services/http/dataset/instance'

import type {
  ClassificationControlSet,
  ClassificationControlSetResponse,
  CreateClassificationControlSetRequest,
  UpdateClassificationControlSetRequest,
} from './types'

import {
  mapClassificationControlSetForList,
  mapClassificationControlSetForRequest,
  mapClassificationControlSetForResponse,
} from './mappers'

export const create = (data: CreateClassificationControlSetRequest) =>
  request<ClassificationControlSet>({
    method: 'post',
    url: 'classification-control-set',
    data,
    mappers: {
      req: mapClassificationControlSetForRequest,
    },
  })

export const get = () =>
  request<ClassificationControlSetResponse>({
    method: 'get',
    url: 'classification-control-set',
    mappers: {
      res: mapClassificationControlSetForList,
    },
  })

export const getById = (id: number) =>
  request<ClassificationControlSet>({
    method: 'get',
    url: `classification-control-set/${id}`,
    mappers: {
      res: mapClassificationControlSetForResponse,
    },
  })

export const update = (data: UpdateClassificationControlSetRequest) =>
  request<ClassificationControlSet>({
    method: 'put',
    url: `classification-control-set/${data.id}`,
    data,
    mappers: {
      req: mapClassificationControlSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `classification-control-set/${id}`,
  })
