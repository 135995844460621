import React, { useEffect, useState } from 'react'
import Alert from '@zera-admin/alert'

import { AgendaReportStatus } from 'services/http/report/agenda-report-4/enums'

import * as Styled from './AgendaReportWizard.styled'
import { AgendaReportWizardProps, AgendaReportWizardStep } from './types'

const AgendaReportWizard: React.FunctionComponent<AgendaReportWizardProps> = ({
  steps = [],
  status = AgendaReportStatus.Create,
}) => {
  const [activeStep, setActiveStep] = useState<AgendaReportStatus>(status)

  useEffect(() => {
    if (status && status !== activeStep) {
      setActiveStep(status)
    }
  }, [status, activeStep])

  const renderHeader = () => {
    const activeIndex: number | undefined = steps.findIndex(
      (step) => step.value === activeStep
    )

    return (
      <Styled.AgendaReportWizardHeader>
        {steps.map((step, index) => (
          <Styled.AgendaReportWizardHeaderStep
            key={index}
            isActive={step.value === status}
            isCompleted={index < activeIndex ? true : false}
          >
            <span>{step.label}</span>
          </Styled.AgendaReportWizardHeaderStep>
        ))}
      </Styled.AgendaReportWizardHeader>
    )
  }

  const renderBody = () => {
    const active: AgendaReportWizardStep | undefined = steps.find(
      (step) => step.value === activeStep
    )

    if (!active) return null

    return (
      <Styled.AgendaReportWizardBody>
        {active.description && (
          <Alert className="agenda-report-wizard-alert" appearance="info">
            {active.description}
          </Alert>
        )}
        {active.children}
      </Styled.AgendaReportWizardBody>
    )
  }

  return (
    <Styled.AgendaReportWizard>
      {renderHeader()}
      {renderBody()}
    </Styled.AgendaReportWizard>
  )
}

export default AgendaReportWizard
