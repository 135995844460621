import React from 'react'
import ReactWordcloud from 'react-wordcloud'

import { WordCloudMain } from './WordCloud.styled'
import { WordCloudProps } from './types'

const WordCloud: React.FunctionComponent<WordCloudProps> = (props) => {
  const { data, isRotate } = props

  return data ? (
    <WordCloudMain>
      <ReactWordcloud
        options={{
          rotations: 2,
          rotationAngles: (isRotate && [0, 90]) || [0, 0],
          fontSizes: [15, 40],
        }}
        words={data?.map((item: any) => ({
          text: item.Word || item.word,
          value: item.Count || item.totalCount,
        }))}
      />
    </WordCloudMain>
  ) : null
}

export default WordCloud
