export const HEADING_TYPES = [
  {
    label: 'Heading 1',
    value: 'h1',
  },
  {
    label: 'Heading 2',
    value: 'h2',
  },
  {
    label: 'Heading 3',
    value: 'h3',
  },
  {
    label: 'Heading 4',
    value: 'h4',
  },
  {
    label: 'Heading 5',
    value: 'h5',
  },
  {
    label: 'Heading 6',
    value: 'h6',
  },
]
