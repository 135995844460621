import React from 'react'
import * as Styled from './Overlay.styled'

export interface IOverlay {
  children: any
  label?: string
  topic?: string
  icon?: string
  color?: string
  background?: string
  style?: string
  titleColor?: string
}

const Overlay: React.FunctionComponent<IOverlay> = ({
  children,
  label,
  topic,
  icon,
  color,
  background,
  style,
  titleColor,
}) => {
  return (
    <Styled.OverlayContainer
      style={{ backgroundColor: color, marginTop: '2.5%', width: '100%' }}
    >
      <div className="overlay-header">
        <div className="overlay-ls">
          <h3 style={{ color: 'white' }}>{label}</h3>
        </div>
        <div></div>
      </div>
      <div
        style={{ backgroundColor: background }}
        className="overlay-content-container"
      >
        {children}
      </div>
    </Styled.OverlayContainer>
  )
}

export default Overlay
