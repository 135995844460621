export const NEWS_FIELDS = [
  {
    label: 'Başlık',
    value: 'title',
  },
  {
    label: 'Açıklama',
    value: 'text',
  },
  {
    label: 'Görsel',
    value: 'image',
  },
  {
    label: 'Oluşturma tarihi',
    value: 'createdAt',
  },
  {
    label: 'Haber mecrası',
    value: 'source',
  },
  {
    label: 'Haber sırası',
    value: 'rank',
  },
  {
    label: 'Etkileşim sayısı',
    value: 'interactionCount',
  },
  {
    label: 'Etki sayısı',
    value: 'impressionCount',
  },
  {
    label: 'URL',
    value: 'url',
  },
]
