import React, { useEffect, useState } from 'react'

import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import categoryTypesService, {
  ClassificationCategoryResponse,
} from 'services/http/dataset/classification-category'

import { ClassificationCategoryListProps } from './types'

const ClassificationCategoryList: React.FunctionComponent<
  ClassificationCategoryListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [categoryType, setCategoryType] =
    useState<ClassificationCategoryResponse>()

  useEffect(() => {
    getCategoryTypes()
  }, [])

  const getCategoryTypes = async () => {
    try {
      const response = await categoryTypesService.get()

      setCategoryType(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    // const actions = (
    //   <LinkButton appearance="primary" href="/classification-category/create">
    //     Kategori tipi oluştur
    //   </LinkButton>
    // )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Kategori tipi" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm kategori tiplerini görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={undefined}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Kategori tipleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'name',
        text: 'Kategori tipi metni',
      },
      {
        name: 'parentName',
        text: 'Üst Kategori',
      },
      // {
      //   name: 'operation',
      //   text: 'İşlemler ',
      //   width: '100px',
      //   render: (item: ClassificationCategory) => (
      //     <LinkButton
      //       appearance="subtle-link"
      //       href={`/classification-category/update/${item.id}`}
      //     >
      //       Görüntüle
      //     </LinkButton>
      //   ),
      // },
    ]

    if (categoryType) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={categoryType.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={categoryType.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default ClassificationCategoryList
