import { Column } from '@zera-admin/page'
import PdfPageLayout from './PdfPageLayout'
import { PdfPageProps } from './types'

import TurkeyPerceptionSentimentCounts from '../report-detail/turkey-perception-sentiment-counts'
import { Tweets } from '../report-detail'
import TurkeyPerceptionTweetMetric from '../report-detail/turkey-perception-tweet-metric'
import Icon from '@zera-admin/icon'
import { getMonthAndYear } from 'app/functions'

const PageTwelve: React.FunctionComponent<PdfPageProps> = ({
  personReport,
}) => {
  return (
    <PdfPageLayout personReport={personReport}>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Türkiye ile ilgili postların verileri
            </h2>
          </Column>
        </div>
        {
          <TurkeyPerceptionTweetMetric
            data={
              personReport?.data?.result.Statistic?.turkeyPerceptionTweetMetrics
            }
          />
        }

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '50px',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Türkiye hakkında atılmış postların duygu dağılımı
            </h2>

            <TurkeyPerceptionSentimentCounts
              data={
                personReport?.data.result.Statistic
                  ?.turkeyPerceptionSentimentCounts
              }
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Kişinin Türkiye hakkında postları
            </h2>
            <Tweets
              data={personReport?.data.result.Statistic.turkeyPerceptionTweets.slice(
                0,
                5
              )}
              profile={personReport.data?.result?.TrackingUser}
              cutContent={true}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
    </PdfPageLayout>
  )
}
export default PageTwelve
