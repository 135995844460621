import React from 'react'
import { IntersectingPeopleCount } from '../../types'
import * as Styled from './Table.styled'

export interface PeopleCard {
  table?: IntersectingPeopleCount
}

const Tablo: React.FunctionComponent<PeopleCard> = ({ table }) => {
  const data = [
    [
      '',
      '#AçOlanlarıSizDoyurun',
      '#750MilyonDolaraNeOldu',
      '#MilleteYenileceksiniz',
      '#Maske',
    ],
    ['#AçOlanlarıSizDoyurun', null, 4.599, 3.235, 6.833],
    ['#750MilyonDolaraNeOldu', 4.599, null, 2.44, 5.154],
    ['#MilleteYenileceksiniz', 3.235, 2.44, null, 3.217],
    ['#Maske', 6.833, 5.154, 3.217, null],
  ]
  //   const handleTDClick = (event: any) => {
  //     console.log(event.target.textContent)
  //   }

  //   const emptyRows = data
  //     .map((row, i) => (row.every((cell) => cell === null) ? i : null))
  //     .filter((value) => value !== null)

  //   const emptyCols = data[0]
  //     .map((cell, j) => (data.every((row) => row[j] === null) ? j : null))
  //     .filter((value) => value !== null)

  return (
    // <div
    //   style={{
    //     width: '100%',
    //     backgroundColor: 'white',
    //     padding: 10,
    //     borderTopLeftRadius: 10,
    //     borderTopRightRadius: 10,
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //   }}
    // >
    <Styled.TableContainer style={{ fontSize: 10 }}>
      <table className="table-container" style={{}}>
        <tbody>
          {table?.data.map((row, i) => (
            <tr key={i}>
              {row.map((cell, j) => (
                <td
                  key={j}
                  style={{
                    backgroundColor: cell === null ? '#C7BDD6' : '',
                    padding: '10px',
                    textAlign: 'center',
                    border: '1px solid black',
                    borderBottom:
                      i === table?.data.length - 1 ? 'none' : '1px solid gray',
                    borderRight:
                      j === row.length - 1 ? 'none' : '1px solid gray',
                    borderLeft: 'none',
                    borderTop: 'none',
                    color: 'black',
                  }}
                  // onClick={handleTDClick}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Styled.TableContainer>
    // </div>
  )
}

export default Tablo
