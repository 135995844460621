import { useRef } from 'react'

import { GeoChart } from 'components/google-maps'

import { GeoChartMapProps, GeoChartValues } from './types'

const GeoChartMap: React.FunctionComponent<GeoChartMapProps> = ({
  height,
  label,
  theme = 'standard',
  values,
}) => {
  const chartRef = useRef<google.visualization.GeoChart>()

  const handleLoad = (chart: google.visualization.GeoChart) => {
    chartRef.current = chart

    const result: GeoChartValues = [['Country', label]]

    values?.forEach((value) => {
      result.push([value.country, value.count])
    })

    const data = google.visualization.arrayToDataTable(result)

    for (var i = 0; i < data.getNumberOfRows(); i++) {
      const countryValue = data.getValue(i, 1)
      const description = values?.[i].description
      if (description) {
        data.setFormattedValue(
          i,
          1,
          `${description}: ${countryValue?.toString()}`
        )
      } else data.setFormattedValue(i, 1, countryValue?.toString())
    }

    chart.draw(data, {
      height,
      colorAxis: {
        // colors: ['#fc5a3e', '#fce63e', '#aae878', '#81e878', '#28bd70']
        colors: ['#bbe1e6', '#84d3dd', '#55acb8', '#23707a', '#175e68'],
      },
    })
  }

  return <GeoChart onLoad={handleLoad} height={height} />
}

export default GeoChartMap
