import {
  ITelegramBotListenerMessage,
  ITelegramBotListenerMessageResponse,
} from './types'

export const mapCommonRuleListForResponse = (
  raw: any
): ITelegramBotListenerMessage => ({
  botId: raw.data.botId,
  chatId: raw.data.chatId,
  firstName: raw.data.firstName,
  lastName: raw.data.lastName,
  message: raw.data.message,
  botName: raw.data.botName,
})

export const mapCommonRuleListForList = (
  raw: any
): ITelegramBotListenerMessageResponse => ({
  list: raw.data.map((item: unknown) =>
    mapCommonRuleListForResponse({ data: item })
  ),
  dataCount: raw.datacount,
  pageCount: raw.pageCount,
  message: raw.message,
  success: raw.success,
})
// export const mapBlackListForRequest = (raw: BlackList): unknown => ({
//   id: raw.id,
//   type: raw.type,
//   value: raw.value,
//   dataSource: raw.dataSource,
// })

// export const mapBlackListForResponse = (raw: any): BlackList => ({
//   id: raw.data.id,
//   type: raw.data.type,
//   value: raw.data.value,
//   createdAt: raw.data.createdAt,
//   dataSource: raw.data.dataSource,
// })

// export const mapBlackListForList = (raw: any): BlackListResponse => ({
//   list: raw.data.map((item: unknown) =>
//     mapBlackListForResponse({ data: item })
//   ),
//   dataCount: raw.dataCount,
//   pageCount: raw.pageCount,
//   message: raw.message,
//   success: raw.success,
// })
