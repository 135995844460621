import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { UserVisualizationFields, UserVisualizationOptions } from '../types'

export const generateUserOptions = (
  options: UserVisualizationOptions,
  query: Query,
  fields: Field[]
): UserVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      [group.field]: group.field as string,
    }
  })

  return result
}

export const generateUserVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as UserVisualizationFields)

  return {
    type: 'user',
    options: {
      stream: {},
      fields: {
        entityKey: options['userEntityKey'] || options['entityKey'],
        countryDetection:
          options['userCountryDetection'] || options['countryDetection'],
        userCreatedAt: options['userCreatedAt'] || options['createdAt'],
        userFollowers:
          options['userFollowersCount'] || options['followersCount'],
        userTweetCount:
          options['userStatusesCount'] || options['statusesCount'],
        userFriends: options['userFriendsCount'] || options['friendsCount'],
        userProfileImage:
          options['userProfileImageUrl'] || options['profileImageUrl'],
        userProtected: options['userProtected'] || options['protected'],
        userVerified: options['userVerified'] || options['verified'],
        userVisibleText: options['userScreenName'] || options['screenName'],
        retweetCount: options['retweetCount'],
        favoriteCount: options['favoriteCount'],
        replyCount: options['replyCount'],
        negativeStatusCount: options['negativeStatusCount'],
        positiveStatusCount: options['positiveStatusCount'],
        neutralStatusCount: options['neutralStatusCount'],
      },
    },
  }
}
