import React, { useState } from 'react'
import { Column, Row } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import EmptyState from '@zera-admin/empty-state'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { pdf } from 'app/functions'
import { Designer } from 'components/designer'
import DashboardSettings from 'bi-tool/drawers/dashboard-settings'
import DashboardJobs from 'bi-tool/drawers/dasboard-jobs'
import FilterSettings from 'bi-tool/drawers/filter-settings'

import NotFoundImage from 'assets/images/not-found.png'

import * as Styled from './DashboardDesigner.styled'
import { DashboardDesignerProps } from './types'
import Loader from 'components/loader'
import Input from '@zera-admin/input'

const DashboardDesigner: React.FunctionComponent<DashboardDesignerProps> = ({
  children,
  fields,
  filterVisibility,
  isPreview,
  onChange,
  onDelete,
  onGetFields,
  onGetTables,
  onValue,
  onSave,
  copy,
  operation,
  setFilterVisibility,
  sources,
  tables,
  values,
  bottomBarHide,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [settingsDrawerVisibility, setSettingsDrawerVisibility] =
    useState(false)
  const [jobsDrawerVisibility, setJobsDrawerVisibility] = useState(false)
  const [pdfInputValue, setPdfInputValue] = useState('')
  const handleDownloadPdf = () => {
    setIsLoading(true)
    window.scrollTo({ top: 0, behavior: 'smooth' })

    pdf('.fa-Page', values.title).then((fileName: string) => {
      setPdfInputValue(fileName)
      setIsLoading(false)
    })
  }

  const getSourceOption = () => {
    if (!values.query?.source) return undefined

    const source = sources.find((source) => source.id === values.query?.source)

    return {
      value: values.query?.source,
      label: source?.name as string,
    }
  }

  const getTableOption = () => {
    if (!values.query?.table) return undefined

    const table = tables.find((table) => table.id === values.query?.table)

    return {
      value: values.query?.table,
      label: table?.name as string,
    }
  }

  const handleChangeTable = (table: string) => {
    if (values.query?.source && table) {
      onGetFields({ datasource: values.query?.source, table })
    }
  }

  const renderEmpty = () => (
    <Styled.DashboardScreenEmptyContent>
      <EmptyState
        title="Veri kaynağınızı belirleyin"
        description="Verilerinizi görselleştirmek ve panonuzu oluşturmak için öncelikle bir veri kaynağı ve bir tablo belirlemelisiniz. "
        image={{
          src: NotFoundImage,
        }}
      />
      <Row xs={{ direction: 'column' }}>
        <Column>
          <Select
            name="source"
            label="Veri kaynağı"
            placeholder="Veri kaynağı seçiniz"
            options={sources.map((source) => ({
              label: source.name,
              value: source.id,
            }))}
            isDisabled={!sources || sources.length === 0}
            value={getSourceOption()}
            onChange={(props) => {
              const value = (props as SelectOptionProps).value as string

              onChange({
                ...values,
                query: {
                  ...values.query,
                  source: value,
                },
              })
              onGetTables(value)
            }}
          />
        </Column>
        {values.query?.source ? (
          <>
            {onValue && (
              <Column>
                <Input
                  name="xs"
                  label="Widget Sayısı"
                  // value={2}
                  onChange={(event: any) => onValue(event.target.value)}
                  type="number"
                />
              </Column>
            )}

            <Column>
              <Select
                name="table"
                label="Veri tablosu"
                placeholder="Tablo seçiniz"
                options={tables.map((table) => ({
                  label: table.name,
                  value: table.id,
                }))}
                value={getTableOption()}
                onChange={(props) => {
                  const value = (props as SelectOptionProps).value as string

                  onChange({
                    ...values,
                    query: {
                      ...values.query,
                      table: value,
                    },
                  })

                  handleChangeTable(value)
                }}
              />
            </Column>
          </>
        ) : null}
      </Row>
    </Styled.DashboardScreenEmptyContent>
  )

  const renderPageFooter = () => {
    const settings = (
      <ButtonGroup>
        <Button
          className="primary-outline"
          iconBefore={<Icon name="preferences" size="small" />}
          onClick={() => setSettingsDrawerVisibility(true)}
        >
          Dashboard ayarları
        </Button>
        <Button
          iconBefore={<Icon name="schedule" size="small" />}
          onClick={() => setJobsDrawerVisibility(true)}
        >
          Zamanlanmış görevler
        </Button>
        {isPreview && (
          <React.Fragment>
            <input id="pdf-date" type="hidden" value={pdfInputValue} />
            <Button
              id="pdf-button"
              iconBefore={<Icon name="download" size="small" />}
              onClick={() => handleDownloadPdf()}
            >
              PDF olarak indir
            </Button>
          </React.Fragment>
        )}
      </ButtonGroup>
    )

    const actions = (
      <Row xs={{ justify: 'end', gutter: 'none', align: 'center' }}>
        <Button
          appearance="primary"
          iconBefore={<Icon name="tray" size="small" />}
          onClick={onSave}
          style={{ marginLeft: 10 }}
        >
          {copy
            ? 'Kopya Oluştur'
            : operation === 'create'
            ? 'Değişiklikleri kaydet ve oluştur'
            : 'Değişiklikleri kaydet'}
        </Button>
      </Row>
    )

    if (!values?.query?.table) return null

    return (
      <Styled.DashboardDesignerFooter>
        <Row>
          <Column>{settings}</Column>
          <Column>{actions}</Column>
        </Row>
      </Styled.DashboardDesignerFooter>
    )
  }

  const renderPageDrawers = () => (
    <React.Fragment>
      <DashboardSettings
        show={settingsDrawerVisibility}
        onDismiss={() => setSettingsDrawerVisibility(false)}
        onSave={(data) => onChange(data)}
        onDelete={onDelete}
        values={values}
        sources={sources}
        tables={tables}
      />
      <DashboardJobs
        show={jobsDrawerVisibility}
        onDismiss={() => setJobsDrawerVisibility(false)}
        onSave={(data) => onChange(data)}
      />
      <FilterSettings
        fields={fields}
        onDismiss={() => setFilterVisibility(false)}
        onSave={(query) => onChange({ ...values, query })}
        operation="dashboard"
        show={filterVisibility}
        values={values.query}
        title="Dashboard - Filtre ve Hesaplamalar"
        type="json"
      />
    </React.Fragment>
  )

  const renderPageContent = () => {
    return values.query?.table ? (
      <Designer preview={isPreview} query={values.query} fields={fields} />
    ) : (
      renderEmpty()
    )
  }

  return (
    <Styled.DashboardDesigner>
      <Loader
        show={isLoading}
        size="medium"
        label="Dashboard PDF olarak hazırlanıyor..."
      />
      {renderPageDrawers()}
      {renderPageContent()}
      {!bottomBarHide && renderPageFooter()}
      {children}
    </Styled.DashboardDesigner>
  )
}

export default DashboardDesigner
