import React, { useEffect, useState } from 'react'
import { uid } from '@zera-admin/utils'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button from '@zera-admin/button'
import Page, { PageHeader, Container, Column } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import MainLayout from 'layouts/main'
import newsListService, { NewsList } from 'services/http/backoffice/news-list'

import { UpdateNewsListProps } from './types'
import DataBox from '@zera-admin/alice-data-box'
const UpdateNewsList: React.FunctionComponent<UpdateNewsListProps> = ({
  match,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isReady, setIsReady] = useState(true)
  const [newsList, setNewsList] = useState<NewsList>()
  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    const id = match.params.id
    newsListService
      .getById(id)
      .then((res) => {
        setNewsList(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsReady(false)
      })
    setIsLoading(false)
  }, [match.params.id, history])

  const handleStatus = () => {
    setIsLoading(true)
    newsListService
      .update(match.params.id)
      .then(() => {
        flagcard.add({
          id: uid(),
          appearance: 'success',
          title: 'İşlem Başarılı',
          children: 'Geçerli veri, sistem üzerinde başarıyla güncellenmiştir.',
        })
        setTimeout(() => {
          history.push('/news-list')
        }, 500)
      })
      .catch((error) => {
        console.log(error)

        flagcard.add({
          id: uid(),
          appearance: 'error',
          title: 'Hata Oluştu',
          children:
            'Geçerli veri, güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Haber Listesi" href="/news-list" />
      <BreadcrumbItem text="Haber Listesi değeri güncelle" />
    </Breadcrumb>
  )

  return (
    <MainLayout isLoading={isReady}>
      <Container width="medium">
        <Page>
          <PageHeader
            actions={
              <Button onClick={() => handleStatus()} appearance="primary">
                Status Güncelle
              </Button>
            }
            breadcrumbs={breadcrumbs}
            bottomBar={<p>Bilgiler aşağıdadır.</p>}
          >
            Veri güncelleniyor : {newsList ? newsList.name : null}
          </PageHeader>
          <Column xs={{ size: 12 }}>
            {newsList && (
              <DataBox
                appearance="default"
                span={3}
                values={[
                  ...[
                    ...Object.entries(newsList).map((item) => ({
                      text: `${item[1]}`,
                      title: `${item[0]}`,
                    })),
                  ],
                ]}
              />
            )}
          </Column>
        </Page>
      </Container>
    </MainLayout>
  )
}

export default UpdateNewsList
