import React, { useState } from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'
import { Column, Row } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import Input from '@zera-admin/input'
import Icon from '@zera-admin/icon'

import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'

import * as Styled from './PieChartDefinitions.styled'
import { PieChartDefinitionsProps } from './types'

import { NUMBER_FORMATS } from './constants'
import {
  PieChartVisualizationNumberFormat,
  PieChartVisualizationValue,
} from '../types'

const PieChartDefinitions: React.FunctionComponent<PieChartDefinitionsProps> =
  ({ aggregations = [], fields = [], groups = [], options, onChange }) => {
    const [activeField, setActiveField] =
      useState<PieChartVisualizationValue | null>()
    const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
      getFieldOptions(fields)
    )

    const handleChangeFieldInput = (value: string) => {
      const option = {
        label: value,
        value,
      }
      const index = fieldOptions.findIndex(
        (reference) => reference.value === parseInt(option.value)
      )
      let optionsInstance = [...fieldOptions]

      if (value && index === -1) {
        optionsInstance[0] = option
      } else {
        optionsInstance = getFieldOptions(fields)
      }

      setFieldOptions(optionsInstance)
    }

    const handleUpdateField = (key: string, value: string | object) => {
      const result = { ...activeField, [key]: value }

      setActiveField(result as PieChartVisualizationValue)
    }

    const handleSaveFieldSettings = () => {
      const result = { ...options }

      if (activeField) {
        result.value = activeField

        onChange(result)
        setActiveField(null)
      }
    }

    const renderFieldNumberOptions = () => {
      const options =
        (activeField?.format as PieChartVisualizationNumberFormat) || {}

      return (
        <Row
          xs={{ direction: 'column' }}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <Column>
            <Select
              name="style"
              label="Numaralandırma formatı"
              placeholder="Bir format seçiniz"
              options={NUMBER_FORMATS}
              value={
                options.style
                  ? {
                      value: options.style,
                      label: NUMBER_FORMATS.find(
                        (format) => format.value === options.style
                      )?.label as string,
                    }
                  : { label: 'Varsayılan', value: 'default' }
              }
              onChange={(props) =>
                handleUpdateField('format', {
                  ...options,
                  style: (props as SelectOptionProps).value as string,
                })
              }
            />
          </Column>
          <Column>
            <Input
              name="prefix"
              label="Ön ek"
              placeholder="Fiyat"
              value={options.prefix}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleUpdateField('format', {
                  ...options,
                  prefix: event.target.value,
                })
              }
            />
          </Column>
          <Column>
            <Input
              name="suffix"
              label="Son ek"
              placeholder="Adet"
              value={options.suffix}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleUpdateField('format', {
                  ...options,
                  suffix: event.target.value,
                })
              }
            />
          </Column>
        </Row>
      )
    }

    const renderFieldsDrawer = () => {
      if (!activeField) return null

      const actions = (
        <ButtonGroup>
          <Button appearance="primary" onClick={handleSaveFieldSettings}>
            Ayarları uygula
          </Button>
          <Button onClick={() => setActiveField(null)}>Kapat</Button>
        </ButtonGroup>
      )

      return (
        <Drawer
          actions={actions}
          isOpen={activeField ? true : false}
          onClose={() => setActiveField(null)}
          title={`${string.textify(activeField.field)} ayarları`}
          width="narrow"
          description="Aşağıdan geçerli alana ait özellikleri güncelleyebilirsiniz."
        >
          {renderFieldNumberOptions()}
        </Drawer>
      )
    }

    const renderTabs = () => {
      return (
        <Tabs id="tabs">
          <TabTriggerGroup>
            <TabTrigger>Veri</TabTrigger>
          </TabTriggerGroup>
          <TabPanel>{renderDataTab()}</TabPanel>
        </Tabs>
      )
    }

    const renderDataTab = () => {
      return (
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Select
              name="column"
              options={fieldOptions}
              onInputChange={handleChangeFieldInput}
              onChange={(props) => {
                onChange({
                  ...options,
                  label: {
                    field: (props as SelectOptionProps).value as string,
                  },
                })
              }}
              value={
                options.label
                  ? {
                      label: string.textify(options.label?.field || ''),
                      value: options.label.field,
                    }
                  : { label: '', value: '' }
              }
              label="Kırılım"
              placeholder="Seçim yapınız"
              description="Chart üzerinde gösterilecek kırılımı seçin"
              isDisabled={aggregations.length || groups.length ? true : false}
            />
          </Column>
          <Column>
            <Styled.PieChartDefinitionField>
              <Row>
                <Column>
                  <Select
                    name="column"
                    options={fieldOptions}
                    onInputChange={handleChangeFieldInput}
                    onChange={(props) => {
                      const field = (props as SelectOptionProps).value as string
                      const reference = fields.find(
                        (value) => value.field === field
                      )

                      onChange({
                        ...options,
                        value: {
                          field: reference?.field || field,
                          type: reference?.type || 'string',
                        },
                      })
                    }}
                    value={
                      options.value
                        ? {
                            label: string.textify(options.value?.field || ''),
                            value: options.value.field,
                          }
                        : { label: '', value: '' }
                    }
                    label="Değer"
                    placeholder="Seçim yapınız"
                    description="Chart üzerinde gösterilecek değeri seçin"
                    isDisabled={
                      aggregations.length || groups.length ? true : false
                    }
                  />
                </Column>
                <Button
                  className="button--settings"
                  iconBefore={<Icon name="settings" size="small" />}
                  onClick={() => setActiveField(options.value)}
                  isDisabled={!options.value}
                />

                {renderFieldsDrawer()}
              </Row>
            </Styled.PieChartDefinitionField>
          </Column>
        </Row>
      )
    }

    return (
      <Styled.PieChartDefinitions>{renderTabs()}</Styled.PieChartDefinitions>
    )
  }

export default PieChartDefinitions
