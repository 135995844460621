import { colors } from '@atlaskit/theme'
import { ZeraLogo } from '@zera-admin/logo'

import { Flex, FlexContent, FlexFooter } from './Empty.styled'

const EmptyLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex>
      <FlexContent>{children}</FlexContent>
      <FlexFooter>
        <ZeraLogo iconColor={colors.N400} size="small" />
      </FlexFooter>
    </Flex>
  )
}

export default EmptyLayout
