import React, { useState } from 'react'
import Button from '@zera-admin/button'
import EmptyState from '@zera-admin/empty-state'
import Icon from '@zera-admin/icon'
import Modal from '@zera-admin/modal'
import { Column, Row } from '@zera-admin/page'
import Select, { SelectOptionProps, SelectValueProps } from '@zera-admin/select'
import Input from '@zera-admin/input'
import Textarea from '@zera-admin/textarea'

import { useSchemaService } from 'app/library/layout-builder'
import InteractionMap from 'widgets/interaction-map'
import Profile from 'widgets/profile'
import RelationChart from 'widgets/relation-chart'

import ComponentHeader from '../ComponentHeader'
import * as Styled from './CustomComponent.styled'
import { CUSTOM_COMPONENTS } from './constants'

import NotFoundImage from 'assets/images/not-found.png'
import WidgetHeroid from 'assets/images/widget-heroid.png'

import { CustomComponentName, CustomComponentProps } from './types'

const CustomComponent: React.FunctionComponent<CustomComponentProps> = ({
  description,
  designing,
  entry,
  id,
  name,
  reference,
  title,
}) => {
  const [modal, setModal] = useState<CustomComponentProps | null>(null)

  const schemaService = useSchemaService()

  const handleChangeProperties = (value: object) => {
    schemaService.changeProperties(id as string, value)
  }

  const renderDesigner = () => (
    <Styled.CustomComponentEmpty>
      <EmptyState
        title={title || 'Özel Bileşen'}
        description="Dashboard'ınızı zenginleştirecek bir özel bileşen seçebilirsiniz."
        image={{ src: name ? WidgetHeroid : NotFoundImage }}
        actions={
          <Button
            iconBefore={<Icon name="preferences" size="small" />}
            onClick={() => {
              setModal({ name, title, description })
            }}
          >
            Özel bileşen ayarları
          </Button>
        }
      />
    </Styled.CustomComponentEmpty>
  )

  const renderComponentSettings = () => {
    if (modal) {
      const actions = [
        {
          text: 'Ayarları kaydet',
          onClick: () => {
            setModal(null)
            handleChangeProperties(modal)
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]

      return (
        <Modal
          title="Özel bileşen ayarları"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>
            Dashboard'ınızı zenginleştirecek bir özel bileşen seçebilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Select
                name="name"
                label="Özel bileşen"
                placeholder="Seçim yapınız"
                onChange={(option: SelectValueProps) => {
                  setModal({
                    ...modal,
                    name: (option as SelectOptionProps)
                      .value as CustomComponentName,
                  })
                }}
                value={
                  CUSTOM_COMPONENTS
                    ? CUSTOM_COMPONENTS.find(
                        (component) => component.value === modal.name
                      )
                    : undefined
                }
                options={CUSTOM_COMPONENTS}
              />
            </Column>
            <Column>
              <Input
                name="title"
                label="Özel bileşen başlığı"
                value={modal.title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({ ...modal, title: event.target.value })
                }
              />
            </Column>
            <Column>
              <Textarea
                name="description"
                label="Özel bileşen açıklaması"
                value={modal.description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setModal({ ...modal, description: event.target.value })
                }
              />
            </Column>
          </Row>
          <br />
        </Modal>
      )
    }
  }

  const renderCustomComponent = () => {
    switch (name) {
      case 'interaction-map':
        return <InteractionMap reference={reference} />
      case 'profile':
        return <Profile reference={reference} />
      case 'relation-chart':
        return <RelationChart reference={reference} />
      default:
        return null
    }
  }

  return (
    <div
      data-schema-id={id}
      className={['dnd-widget', designing && 'fake-draggable']
        .filter((e) => !!e)
        .join(' ')}
    >
      {designing ? (
        <React.Fragment>
          {renderComponentSettings()}
          <ComponentHeader
            componentEntry={entry}
            componentSchemaId={id as string}
          />
          {renderDesigner()}
        </React.Fragment>
      ) : (
        <Styled.CustomComponent>
          <Styled.CustomComponentHeader>
            <Row xs={{ axis: 'x', align: 'center', justify: 'around' }}>
              <Column>
                <h4>{title || 'Özel Bileşen'}</h4>
                <p>
                  {description ||
                    'Aşağıdan seçmiş olduğunuz özel bileşeni görüntülüyorsunuz'}
                </p>
              </Column>
            </Row>
          </Styled.CustomComponentHeader>
          <Styled.CustomComponentContent>
            {renderCustomComponent()}
          </Styled.CustomComponentContent>
        </Styled.CustomComponent>
      )}
    </div>
  )
}

export default CustomComponent
