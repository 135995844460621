import React from 'react'

import InfoCard from '../report-card'
import { reportInformationProps } from './types'
import InfoIcon from './info_icon.svg'

const ReportsInformation: React.FunctionComponent<reportInformationProps> = ({
  data,
  title,
}) => {
  return (
    <InfoCard title={title} icon={InfoIcon} fontSize="16px">
      <div className="general-info">
        <div className="general-info-title">Hashtag</div>
        <div className="general-info-content">{data?.hashtagCountInfo}</div>
      </div>
      <div className="general-info">
        <div className="general-info-title">Lokasyon</div>
        <div className="general-info-content">
          {data?.locationCountInfo?.includes('tweet')
            ? data.locationCountInfo.replace('tweet', 'post')
            : data?.locationCountInfo}
        </div>
      </div>
      <div className="general-info">
        <div className="general-info-title">Bahsedilenler</div>
        <div className="general-info-content">
          {data?.numberOfAccountsMentionedInfo}
        </div>
      </div>
      <div className="general-info">
        <div className="general-info-title">Post</div>
        <div className="general-info-content">
          {data?.tweetCountInfo.includes('tweet')
            ? data?.tweetCountInfo.replace('tweet', 'post')
            : data?.tweetCountInfo}
        </div>
      </div>
      <div className="general-info">
        <div className="general-info-title">Kullanıcı</div>
        <div className="general-info-content">{data?.userCountInfo}</div>
      </div>
    </InfoCard>
  )
}
export default ReportsInformation
