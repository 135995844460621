import React, { useEffect, useState } from 'react'
import { uid } from '@zera-admin/utils'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { ValidationError } from 'app/shared/types'
import MainLayout from 'layouts/main'
import sourcesService, {
  Source,
  UpdateSourceRequest,
} from 'services/http/dataset/source'

import { UpdateSourceProps } from './types'
import SourceForm from './SourceForm'

const UpdateSource: React.FunctionComponent<UpdateSourceProps> = ({
  match,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(true)
  const [source, setSource] = useState<Source>()
  const [flagcard] = useFlagcardContext()

  useEffect(() => {
    const id = parseInt(match.params.id)

    sourcesService
      .getById(id)
      .then((res) => {
        setSource(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsReady(false)
      })
  }, [match.params.id, history])

  const handleSubmit = (data: UpdateSourceRequest) => {
    if (data) {
      setIsLoading(true)

      sourcesService
        .update(data)
        .then(() => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: 'İşlem Başarılı',
            children:
              'Geçerli veri, sistem üzerinde başarıyla güncellenmiştir.',
          })
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Hata Oluştu',
            children:
              'Geçerli veri güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleError = (error?: ValidationError) => {
    console.log(error)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Kaynak" href="/source" />
      <BreadcrumbItem text="Kaynak güncelle" />
    </Breadcrumb>
  )

  return (
    <MainLayout isLoading={isReady}>
      <Container width="medium">
        <Page>
          <PageHeader
            // actions={<Button appearance="error">Delete user</Button>}
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Aşağıdaki bilgileri doldurarak geçerli kaynak
                güncelleyebilirsiniz.
              </p>
            }
          >
            Veri güncelleniyor: {source ? source.name : null}
          </PageHeader>
          <SourceForm
            actions={
              <ButtonGroup>
                <Button
                  appearance="primary"
                  children="Güncelle"
                  isLoading={isLoading}
                  type="submit"
                />
                <LinkButton href="/source">İptal</LinkButton>
              </ButtonGroup>
            }
            operation="update"
            onSubmit={handleSubmit}
            onError={handleError}
            values={source}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default UpdateSource
