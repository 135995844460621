import TwitterProfileCard, {
  MenuButtonProps,
  TwitterProfileCardProps,
} from '@zera-admin/alice-twitter-profile-card'
import React, { useState } from 'react'
import TrainingGroupModal, {
  TrainingGroupModalEnum,
} from '../training-group-modal'

const TwitterProfileTrainingControls: React.FunctionComponent<TwitterProfileCardProps> =
  (props) => {
    const [trainingModalValues, setTrainingModalValues] = useState<any>()
    const [trainingModal, setTrainingModal] = useState<
      TrainingGroupModalEnum | undefined
    >(undefined)

    const menuButtonsHandleClick = (
      values: any,
      modal: TrainingGroupModalEnum
    ) => {
      setTrainingModalValues(values)
      setTrainingModal(modal)
    }

    const menuButtons: MenuButtonProps[] = [
      {
        text: 'Bot sınıflandırması eğit',
        icon: 'app-access',
        onClick: (e, context) => {
          menuButtonsHandleClick(
            {
              isCertainUpdate: true,
              accountEntityKey: context.account.userId,
              sourceId: 1,
            },
            TrainingGroupModalEnum.Bot
          )
        },
      },
      {
        text: 'Cinsiyet eğit',
        icon: 'app-access',
        onClick: (e, context) =>
          menuButtonsHandleClick(
            {
              accountEntityKey: context.account.userId,
              sourceId: 1,
            },
            TrainingGroupModalEnum.GenderByUser
          ),
      },
    ]

    const renderTrainingGroupModal = trainingModal ? (
      <TrainingGroupModal
        onClose={() => setTrainingModal(undefined)}
        trainingModal={trainingModal}
        values={trainingModalValues}
      />
    ) : null
    return (
      <React.Fragment>
        {renderTrainingGroupModal}
        <TwitterProfileCard menuButtons={menuButtons} {...props} />
      </React.Fragment>
    )
  }

export default TwitterProfileTrainingControls
