import styled from 'styled-components'

export const Report = styled.div`
  padding: 0 200px;
`

export const Avatar = styled.div`
  padding: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div:first-child {
    border: 3px solid #f0c73b;
    border-radius: 50%;

    img {
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      width: 250px;
      height: 250px;
    }
  }
`

export const LoadingView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
