import React from 'react'
import { Link } from 'react-router-dom'
import EmptyState from '@zera-admin/empty-state'

import { GoogleProps } from './types'
import * as Styled from './Google.styled'

const Google: React.FunctionComponent<GoogleProps> = ({ data }) => {
  return data && data.length > 0 ? (
    data.map((item: any, index: number) => (
      <Styled.Google key={index}>
        <Link to={{ pathname: `https://${item.displayLink}` }} target="_blank">
          <p
            dangerouslySetInnerHTML={{
              __html: item.displayLink,
            }}
          />
        </Link>
        <Link to={{ pathname: item.link }} target="_blank">
          <p dangerouslySetInnerHTML={{ __html: item.htmlTitle }} />
        </Link>
        <p dangerouslySetInnerHTML={{ __html: item.htmlSnippet }} />
      </Styled.Google>
    ))
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Google
