import React, { useEffect, useRef } from 'react'
import Sortable from 'sortablejs'
import { container } from 'bi-tool/drag-drop/container'
import { useSchemaService } from 'app/library/layout-builder'

interface DroppableProps {
  elementId: string | null
  elementType?: string
  style?: any
  className?: string
  groupName?: string
  disableSort?: boolean
  children: React.ReactNode
}

const Droppable: React.FunctionComponent<DroppableProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const schemaService = useSchemaService()

  useEffect(() => {
    createSortable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createSortable = () => {
    Sortable.create(containerRef.current as HTMLDivElement, {
      group: {
        name: 'droppable',
        pull: (toElement: Sortable, fromElement: Sortable) => {
          const from = fromElement.el.getAttribute('data-schema-type')
          const to = toElement.el.getAttribute('data-schema-type')

          if (from === 'column' && to === 'column') return true
          else if (from !== 'page' && to === 'page') return true
          else if (from === 'page' && to === 'grid') return true

          return false
        },
      },
      sort: true,
      animation: 150,
      swapThreshold: 0.5,
      fallbackOnBody: true,
      delay: 50,
      onSort: (event: any) => {
        if (event.to.getAttribute('data-schema-id') === props.elementId) {
          const fromId = event.from.getAttribute('data-schema-id')
          const type = event.clone.getAttribute('data-schema-type')
          const elementId = event.clone.getAttribute('data-schema-id')
          const toId = event.to.getAttribute('data-schema-id')
          const size = event.clone.getAttribute('data-schema-size')

          if ((!type || type.length === 0) && !fromId) {
            return event.item.parentNode
              ? event.item.parentNode.removeChild(event.item)
              : ''
          }

          if (!fromId || fromId.length === 0) {
            if (event.item.parentNode) {
              event.item.parentNode.removeChild(event.item)
            }

            const containerEntry = container.resolve(type as string)
            let defaultProps: { [key: string]: string | object | number } = {}

            schemaService.insertAt(
              toId as string,
              type as string,
              {
                ...defaultProps,
                sizes: size
                  ? {
                      lg: Number(size),
                      xs: Number(size),
                      sm: Number(size),
                      md: Number(size),
                      xl: Number(size),
                    }
                  : defaultProps.sizes,
              },
              event.newIndex as number,
              undefined,
              containerEntry.options,
              undefined
            )
            return
          }

          schemaService.moveElement(
            fromId,
            toId as string,
            elementId as string,
            event.oldIndex as number,
            event.newIndex as number
          )
        }
      },
    })
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100px',
        ...(props.style || {}),
      }}
      className={['droppable-area', ...[props.className || null]]
        .filter((x) => x)
        .join(' ')}
      data-schema-id={props.elementId}
      data-schema-type={props.elementType || ''}
      ref={containerRef}
    >
      {props.children}
    </div>
  )
}

export default Droppable
