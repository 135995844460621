import React from 'react'
import * as Styled from './News.styled'
import { News } from '../../types'
import WorkersImage from 'assets/images/newspaper.jpg'

export interface NewCard {
  news: News
  index: number
  showFullTextList: boolean[]
  updateShowFullTextList: (index: number, showFullText: boolean) => void
}

const NewsCrd: React.FunctionComponent<NewCard> = ({
  news,
  index,
  showFullTextList,
  updateShowFullTextList,
}) => {
  const showFullText = showFullTextList[index]

  const dates = new Date(news?.createdAt)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    second: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  const addEllipsis = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + '...'
    }
    return text
  }

  const formattedDate = dates.toLocaleDateString('tr-TR', options)

  return (
    <Styled.NewsCards>
      <div>
        <Styled.CardHead>
          <div>
            <img src={news.imageUrl} alt="" width={50} height={50} />
          </div>
          <div>
            <h3>{news?.newsSourceName}</h3>
          </div>
        </Styled.CardHead>
        <Styled.CardMain>
          <img
            src={
              news.newsSourceImageUrl ? news.newsSourceImageUrl : WorkersImage
            }
            alt=""
          />
        </Styled.CardMain>
        <Styled.CardText>
          <h3>{news?.title}</h3>
          <a href={news?.url}>
            {showFullText ? (
              <p className="news-none-h">{news?.text}</p>
            ) : (
              <p className="news-h">{addEllipsis(news?.text, 300)}</p>
            )}
            <div className="createdat">
              <span>{formattedDate}</span>
            </div>
          </a>
          {(news?.text.length > 300 && (
            <button
              onClick={() => updateShowFullTextList(index, !showFullText)}
            >
              {showFullText ? 'Daha az' : 'Daha fazlasını göster'}
            </button>
          )) ||
            null}
        </Styled.CardText>
      </div>
    </Styled.NewsCards>
  )
}

export default NewsCrd
