import React, { useEffect, useState } from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'

import Pager from 'components/pager'

import { useFlagcardContext } from 'app/contexts/flagcard'
import activeNewsServices, {
  NewsAlarms,
  NewsAlarmsListResponse,
} from 'services/http/backoffice/news-alarms'
import MainLayout from 'layouts/main'

import { NewsAlarmsFormProps } from './types'

import TwitterNewsCard from 'components/twitter-news-card/TwitterNewsCard'
import Icon from '@zera-admin/icon'
import newsAlarms from 'services/http/backoffice/news-alarms'
import Form from '@zera-admin/form'
import Input from '@zera-admin/input'

const NewsAlarmsList: React.FunctionComponent<NewsAlarmsFormProps> = () => {
  const [flagcard] = useFlagcardContext()
  const [influentialPersons, setInfluentialPersons] =
    useState<NewsAlarmsListResponse | null>()
  const [selectedNewsList, setSelectedNewsList] = useState<Array<NewsAlarms>>(
    []
  )
  const [pageIndex, setPageIndex] = useState<number>(0)

  const [loader, setLoader] = useState<boolean>(false)

  useEffect(() => {
    getActivePeople(24, 10, ' ')
  }, [])

  const getActivePeople = async (
    pageIndex: number,
    pageSize: number,
    channelName: string | null
  ) => {
    setLoader(true)
    try {
      const response = await activeNewsServices.get(
        pageSize,
        pageIndex,
        channelName
      )
      setInfluentialPersons(response.data)
    } catch (err) {
      const ex: any = err
      console.log(ex)
      setInfluentialPersons(null)
    }
    setLoader(false)
  }

  const putStatus = async (id: any) => {
    try {
      const response = await activeNewsServices.put(id).then((res: any) => {
        flagcard.add({
          appearance: 'success',
          title: 'Durum güncelleme başarılı',
          children: 'Seçmiş olduğunuz haberlerin durumu başarıyla güncellendi.',
        })
      })
    } catch (err) {
      console.log(err)
    }
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Haber Alarmları" />
    </Breadcrumb>
  )

  const addNewsToList = (news: any) => {
    setSelectedNewsList([...selectedNewsList, news])
  }

  const removeNewsFromList = (news: any) => {
    setSelectedNewsList(
      selectedNewsList.filter((per) => {
        return news.id !== per.id
      })
    )
  }

  const newsIsSelected = (id: string) => {
    let isThere = selectedNewsList.find((el) => el.id === id)
    if (isThere) return true
    return false
  }

  const twitterCard = influentialPersons?.list?.map((data: NewsAlarms) => (
    <TwitterNewsCard
      key={data.id}
      addListNews={addNewsToList}
      removeListNews={removeNewsFromList}
      data={data}
      isSelectedNews={newsIsSelected(data.id)}
    />
  ))

  const renderPageTable = () => {
    if (influentialPersons) {
      return twitterCard
    }
  }
  const handleSearch = (values: any) => {
    getActivePeople(pageIndex, 10, values['channelName'])
    setLoader(true)
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          bottomBar={
            <p>
              Tüm haberleri görüntüleyebilir ve yayınlama durumlarını
              güncelleyebilirsiniz.
            </p>
          }
          breadcrumbs={breadcrumbs}
          actions={
            selectedNewsList.length ? (
              <ButtonGroup>
                <Button
                  onClick={() => {
                    selectedNewsList.forEach((news) => {
                      newsAlarms.put(news)
                    })
                  }}
                >
                  Statü Güncelle
                </Button>
              </ButtonGroup>
            ) : (
              <></>
            )
          }
        >
          Haber Alarmları
        </PageHeader>
        <Form onSubmit={handleSearch} style={{ marginBottom: '10px' }}>
          <Row xs={{ align: 'center' }}>
            <Column xs={{ size: 4 }}>
              <Input
                description="Aramak istediğiniz haber kanalının adını yazınız.."
                label="Kanal Adı"
                name="channelName"
              />
            </Column>

            <Column>
              <Button
                appearance="primary"
                children="Arama yap"
                iconBefore={<Icon size="small" name="search" />}
                type="submit"
                onClick={() => {
                  selectedNewsList.forEach((values) => {
                    newsAlarms.put(values)
                  })
                }}
              />
            </Column>
          </Row>
        </Form>

        {renderPageTable()}
        <Pager
          pages={
            influentialPersons?.pageCount ? influentialPersons?.pageCount : 10
          }
          onChange={(index) => setPageIndex(index - 1)}
        />
      </Page>
    </MainLayout>
  )
}
export default NewsAlarmsList
