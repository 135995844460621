import { Widget } from 'services/http/bi-tool/widget'

export type SchemeSourceType = 'type' | 'ref'

export type SchemaNodePredicate = (
  node: SchemaNode,
  index: number,
  nodes: SchemaNode[]
) => boolean

export interface BindingObject {
  object: string
  property: string
}

export interface SchemaObject {
  name: string | undefined
  type?: string | undefined
  props: object | undefined
  source: SchemaSourceOptions | undefined
  children: SchemaObject[] | undefined
  definitions?: object | undefined
  options?: Widget | undefined
}

export interface PureSchemaObject {
  name: string | undefined
  type: string | undefined
  props: object | undefined
  children: PureSchemaObject[] | undefined
  source?: SchemaSourceOptions | undefined
}

export type SearchPredicate = (node: SchemaNode) => boolean

export interface SchemaSourceOptions {
  type?: SchemaSourceTypes
  ref?: string | object // If type: local-data, global-data => queryId (string), If type: meta => metaName (string)
  criteria?: boolean
  relation?: string
}

export enum SchemaSourceTypes {
  Static = 'static',
  Dynamc = 'dynamic',
}

export class SchemaNode {
  name: string | undefined
  type: string | undefined = undefined
  source: SchemaSourceOptions = {}
  props: any = {}
  children: SchemaNode[] = []
  definitions: any = {
    id: 'undefined',
    name: 'undefined',
    description: 'undefined',
  }
  parent: SchemaNode | null = null
  options?: Widget = {} as Widget

  constructor(parent: SchemaNode | null) {
    this.parent = parent
  }

  setType(name: string): SchemaNode {
    this.name = name
    return this
  }

  setSchemaType(type: string): SchemaNode {
    this.type = type
    return this
  }

  setSource(key: SchemeSourceType, value: any): SchemaNode {
    if (!this.source) {
      this.source = {}
    }
    this.source[key] = value
    return this
  }

  setSourceObject(value: any): SchemaNode {
    this.source = value
    return this
  }

  getType(): string {
    return this.name || ''
  }

  getSource(): SchemaSourceOptions {
    return this.source || ({} as SchemaSourceOptions)
  }

  search(id: string): SchemaNode | null {
    if (this.definitions.id === id) return this

    let found = null

    for (let index = 0; index < this.children.length; index++) {
      let child = this.children[index]
      found = child?.search(id)

      if (found !== null) break
    }

    return found
  }

  searchBy(predicate: SearchPredicate): SchemaNode[] {
    let resultArray: SchemaNode[] = []
    const firstResult = predicate(this)

    if (firstResult) {
      resultArray.push(this)
    }

    for (let index = 0; index < this.children.length; index++) {
      let child = this.children[index]
      let searchResult = child.searchBy(predicate)
      resultArray = [...resultArray, ...searchResult]
    }

    return resultArray
  }

  insertChildren(at: number, node: SchemaNode): SchemaNode {
    if (
      this.children.findIndex(
        (x) => x.getDefinition('id') === node.getDefinition('id')
      ) === -1
    ) {
      this.children.splice(at, 0, node)
      node.setParentNode(this)
    }

    return this
  }

  appendChildren(node: SchemaNode): SchemaNode {
    this.children.push(node)
    node.setParentNode(this)
    return this
  }

  appendChildrens(nodes: SchemaNode[]): SchemaNode {
    this.children = [...this.children, ...nodes]
    nodes.forEach((x) => x.setParentNode(this))
    return this
  }

  removeChildren(at: number): SchemaNode {
    this.children.splice(at, 1)
    return this
  }

  removeChildrenPredicate(predicate: SchemaNodePredicate): SchemaNode {
    const childIndex = this.children.findIndex(predicate)

    if (childIndex > -1) {
      return this.removeChildren(childIndex)
    }

    return this
  }

  setParentNode(parentNode: SchemaNode): SchemaNode {
    this.parent = parentNode
    return this
  }

  setProp(key: any, value: any): SchemaNode {
    this.props[key] = value
    return this
  }

  getProp(key: any): any {
    return this.props[key]
  }

  getProps(): object {
    return this.props
  }

  getOption(key: string): any {
    if (this.options) {
      // @ts-ignore
      return this.options[key]
    }
    return null
  }

  getOptions(): Widget {
    return this.options || ({} as Widget)
  }

  setDefinition(key: any, value: any): SchemaNode {
    this.definitions[key] = value
    return this
  }

  getDefinition(key: any): any {
    return this.definitions[key]
  }

  setName(name: string): SchemaNode {
    this.definitions.name = name
    return this
  }

  getName(): string {
    return typeof this.definitions.name === 'string'
      ? this.definitions.name
      : this.definitions.id
  }

  setProps(value: object): SchemaNode {
    this.props = value
    return this
  }

  setOption(key: string, value: any): SchemaNode {
    if (this.options) {
      // @ts-ignore
      this.options[key] = value
    }
    return this
  }

  setOptions(options: Widget): SchemaNode {
    this.options = options
    return this
  }

  setOptionValue(option: string, key: string, value: any): SchemaNode {
    // @ts-ignore
    this.options[option] = {
      [key]: value,
    }
    return this
  }

  setDefinitions(value: object): SchemaNode {
    this.definitions = value
    return this
  }

  toObject(): SchemaObject {
    const { name, type, props, definitions, source, options } = this

    let object: SchemaObject = {
      name,
      type,
      props,
      source,
      definitions,
      children: this.children.map((node) => node.toObject()),
      options,
    }

    return object
  }

  toPureObject(): PureSchemaObject {
    const { name, props, type, source } = this

    let object: PureSchemaObject = {
      name,
      props,
      type,
      children: this.children
        .filter((node) => !node.getProp('hideRuntime'))
        .map((node) => node.toPureObject()),
    }

    if (source) {
      object.source = source
    }

    return object
  }

  getDistinctNodes(): SchemaNode[] {
    return this.children.reduce(
      (prev: SchemaNode[], node: SchemaNode) => {
        return [...prev, ...node.getDistinctNodes()]
      },
      [this]
    )
  }

  static parse(obj: SchemaObject, parent: SchemaNode | null = null) {
    const node = new SchemaNode(parent)
    node.name = obj.name
    node.type = obj.type
    node.props = obj.props
    node.source = obj.source || {}
    node.definitions = obj.definitions
    node.children = (obj.children || []).map((childObject) =>
      this.parse(childObject, node)
    )
    node.options = obj.options || ({} as Widget)
    return node
  }
}
