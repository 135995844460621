import styled from 'styled-components'

export const FilterSettings = styled.div`
  /** It's will be refactor */
  & > div > div:first-child {
    z-index: 601;
  }

  & > div > div:last-child {
    z-index: 600;
  }
`

export const FilterSettingsTabs = styled.div`
  div[role='tabpanel'] {
    & > div {
      width: 100%;
    }
  }

  code {
    display: block;
    margin-top: 10px;
    font-size: 13px;
    border-left: 4px solid #ececec;
    padding-left: 8px;
    color: #217141;
  }

  .CodeMirror {
    outline: none;
    border: 1px solid #efefef;
    height: 600px;
  }

  textarea {
    padding: 0px !important;
  }
`
export const FilterRaw = styled.div`
  p {
    margin-bottom: 24px;
  }
`

export const ListField = styled.div`
  background: #fff;
  border: 1px solid #efefef;
  padding: 1rem;
  margin-top: 10px;

  button {
    margin-top: 30px;
    padding: 0px 5px;
    height: 2.75em;
    margin-left: 8px;
  }
`

export const WidgetScreenTimePicker = styled.div`
  min-width: 350px;
  margin-top: 10px;
`
