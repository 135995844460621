import React, { useEffect, useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import DataBox from '@zera-admin/alice-data-box'
import Page, { PageHeader, Container, Column } from '@zera-admin/page'

import { localeDate } from 'app/functions'
import MainLayout from 'layouts/main'
import definitionService, {
  Definition,
} from 'services/http/backoffice/definition'

import { DetailDefinitionProps } from './types'

const DetailDefinition: React.FunctionComponent<DetailDefinitionProps> = ({
  match,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [definition, setDefinition] = useState<Definition>()

  useEffect(() => {
    getDefinition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDefinition = () => {
    definitionService
      .getById(String(match.params.id))
      .then((res) => {
        setDefinition(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => setIsLoading(false))
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Tanımlama" href="/definition" />
      <BreadcrumbItem text="Tanımlama detayı" />
    </Breadcrumb>
  )

  return (
    <MainLayout isLoading={isLoading}>
      <Container width="medium">
        <Page>
          <PageHeader
            // actions={<Button appearance="error">Delete user</Button>}
            breadcrumbs={breadcrumbs}
            bottomBar={<p>Tanımlamanın detayını görüntülüyorsunuz</p>}
          >
            Tanımlama listeleniyor:
          </PageHeader>
          {definition && (
            <Column xs={{ size: 12 }}>
              <DataBox
                appearance="default"
                title={definition.eventName}
                span={3}
                values={[
                  {
                    text: definition.pathId,
                    title: 'Path Id',
                  },
                  {
                    text: definition?.crawlingType || '',
                    title: 'Veri Çekme Tipi',
                  },
                  {
                    text: definition.dataSource
                      .toLowerCase()
                      .includes('twitter')
                      ? 'X'
                      : definition.dataSource,
                    title: 'Veri Kaynağı',
                  },
                  {
                    text: localeDate(definition.insertedInQueueAt),
                    title: 'Kuyruk atılma',
                  },
                  {
                    text: localeDate(definition.crawlingStartedAt),
                    title: 'İşlem başlangıç',
                  },
                  {
                    text: definition.crawlingEndedAt
                      ? localeDate(definition.crawlingEndedAt)
                      : '-',
                    title: 'İşlem tamamlanma',
                  },
                  ...[
                    ...Object.entries(definition.crawlProperties).map(
                      (item) => ({
                        text: `${item[1]}`,
                        title: `${item[0]}`,
                      })
                    ),
                  ],
                ]}
              />
            </Column>
          )}
        </Page>
      </Container>
    </MainLayout>
  )
}

export default DetailDefinition
