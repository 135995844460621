import { Http, HttpConfig } from '@zera-admin/classes'
import { storage } from '@zera-admin/helpers'

import { refresh } from 'services/http/interceptors'

const http = new Http({
  origin: process.env.REACT_APP_BACKOFFICE,
  interceptors: {
    refresh,
  },
})

export function request<T>(config: HttpConfig) {
  config.headers = {
    Authorization: `Bearer ${storage.get('token')}`,
    ...config.headers,
  }

  return http.request<T>(config)
}

export default http
