import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'

import { StackedChartVisualizationOptions } from '../types'

export const generateStackedChartOptions = (
  options: StackedChartVisualizationOptions,
  query: Query,
  fields: Field[]
): StackedChartVisualizationOptions => {
  const result = options

  const aggregations = query?.aggregations || []
  const group = query.groups?.length
    ? query.groups[query.groups.length - 1]
    : null

  if (aggregations.length) {
    aggregations.forEach((aggregation) => {
      if (aggregation.field) {
        const reference = fields.find(
          (value) => value.field === aggregation.field
        )

        if (reference) {
          if (!result.series) {
            result.series = []
          }

          result.series.push({
            field: reference.field,
            type: reference.type,
            alias: reference.alias || reference.field,
          })
        }
      }
    })
  }

  if (group) {
    result.label = {
      field: group.field as string,
    }
  }

  return result
}
