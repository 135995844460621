import React, { useEffect, useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import * as yup from 'yup'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Form, { FormFooter, FormSection } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps, SelectValueProps } from '@zera-admin/select'
import Icon from '@zera-admin/icon'

import { usePopupContext } from 'app/contexts/popup'
import { LOGIN_TYPES } from 'app/shared/constants'
import { User, UserApp } from 'services/http/identity-server/user'
import appService from 'services/http/identity-server/app'
import roleService from 'services/http/identity-server/role'
import { LoginType } from 'services/http/identity-server/enums'

import { UserFormProps } from '../types'

const UserForm: React.FunctionComponent<UserFormProps> = ({
  values,
  onSubmit,
  operation,
  loading,
}) => {
  const [form, setForm] = useState<User>(values)
  const [apps, setApps] = useState<SelectOptionProps[]>([])
  const [roles, setRoles] = useState<SelectOptionProps[]>([])
  const [popup] = usePopupContext()
  const validations = {
    name: yup.string().required('Kullanıcı ismi girmelisiniz.'),
    surname: yup.string().required('Kullanıcı soyismi girmelisiniz.'),
    userName: yup
      .string()
      .required('Benzersiz bir kullanıcı adı girmelisiniz.'),
    email: yup.string().required('E-posta adresi girmelisiniz.'),
    phoneNumber: yup
      .string()
      .required('Telefon numarası bilgisi girmelisiniz.'),
    appIds: yup.array().min(1, 'En az bir ilişkili uygulama seçmelisiniz.'),
    roleIds: yup.array().min(1, 'En az bir rol seçmelisiniz.'),
    password: yup.string().required('Parola bilgisi girmelisiniz.'),
  }

  useEffect(() => {
    getApps()
    getRoles()
  }, [])

  const getApps = async () => {
    const response = await appService.get({ pageIndex: 0, pageSize: 100 })
    const mapped = (response.data.data || []).map((app) => ({
      label: app.name,
      value: app.id,
    }))

    setApps(mapped)
  }

  const getRoles = async () => {
    const response = await roleService.get({ pageIndex: 0, pageSize: 100 })
    const mapped = (response.data.data || []).map((role) => ({
      label: role.title,
      value: role.id,
    }))

    setRoles(mapped)
  }

  const handleSubmit = () => {
    const schema = yup.object().shape(validations)

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        onSubmit(form)
      })
      .catch((err) => {
        popup.add({
          appearance: 'error',
          title: 'Doğrulama hatası',
          children: (
            <div>
              <p>
                Aşağıdaki kriterlere dikkate alarak, formu gözden geçiriniz.
              </p>
              <ul className="errors">
                {(err.errors || []).map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
        })
      })
  }

  const renderApp = (app: UserApp, index: number) => {
    if (!app) return null

    return (
      <Row>
        <Column>
          <Select
            name="appIds"
            label="Uygulama"
            placeholder="Seçim yapınız"
            description="Kullanıcıların aktif olacağı ilişkili uygulamayı seçiniz."
            onChange={(props: SelectValueProps) => {
              const apps = [...form.apps]

              apps[index] = {
                id: (props as SelectOptionProps).value as number,
                name: (props as SelectOptionProps).label,
                loginType: LoginType.OnlyPassword,
              }

              setForm({
                ...form,
                apps,
              })
            }}
            value={app.id ? { label: app.name, value: app.id } : undefined}
            options={apps}
          />
        </Column>
        <Column>
          <Select
            name="type"
            label="Sisteme giriş tipi"
            placeholder="Seçim yapınız"
            description="Kullanıcının seçtiğiniz uygulama üzerinde sisteme giriş yapacağı tipi seçiniz."
            onChange={(options: SelectValueProps) => {
              const apps = [...form.apps]

              apps[index] = {
                ...apps[index],
                loginType: (options as SelectOptionProps).value as LoginType,
              }

              setForm({
                ...form,
                apps,
              })
            }}
            value={LOGIN_TYPES.find(
              (loginType) => loginType.value === app.loginType
            )}
            options={LOGIN_TYPES}
          />
        </Column>
        <Column xs={{ size: 1, align: 'center' }}>
          <Button
            onClick={() => {
              const apps = [...form.apps]

              apps.splice(index, 1)

              setForm({
                ...form,
                apps,
              })
            }}
          >
            <Icon size="small" name="cross" />
          </Button>
        </Column>
      </Row>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Column xs={{ size: 6 }}>
          <Input
            label="Kulanıcı ismi"
            description="Kullanıcı ismini giriniz."
            name="name"
            value={form.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, name: event.target.value })
            }
          />
        </Column>
        <Column xs={{ size: 6 }}>
          <Input
            label="Kulanıcı soyismi"
            description="Kullanıcı soyismini giriniz."
            name="surname"
            value={form.surname}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, surname: event.target.value })
            }
          />
        </Column>
        <Column xs={{ size: 6 }}>
          <Input
            label="Kulanıcı adı"
            description="Geçerli bir kullanıcı adı giriniz."
            name="userName"
            value={form.userName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, userName: event.target.value })
            }
            autoComplete="new-userName"
          />
        </Column>
        <Column xs={{ size: 6 }}>
          <Input
            label="Kulanıcı e-posta"
            description="Geçerli bir e-posta adresini giriniz."
            name="email"
            value={form.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, email: event.target.value })
            }
          />
        </Column>
        {operation === 'create' && (
          <Column xs={{ size: 12 }}>
            <Input
              type="password"
              label="Kulanıcı parolası"
              description="Kullanıcının şifre bilgisini giriniz. Şifre bilgisi yalnızca ekleyeceğiniz kullanıcı tarafından değiştirilebilir."
              name="password"
              value={form.password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setForm({ ...form, password: event.target.value })
              }
              autoComplete="new-password"
            />
          </Column>
        )}
        <Column xs={{ size: 6 }}>
          <Input
            label="Kulanıcı telefon numarası"
            description="Geçerli bir telefon numarası giriniz."
            name="phoneNumber"
            placeholder="(555) 555-5555"
            value={form.phoneNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, phoneNumber: event.target.value })
            }
          />
        </Column>
        <Column xs={{ size: 6 }}>
          <Input
            label="Kulanıcı ülkesi"
            description="Kullanıcının ülke bilgisini giriniz."
            name="country"
            value={form.country}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, country: event.target.value })
            }
          />
        </Column>

        <Column xs={{ size: 12 }}>
          <Select
            name="roleIds"
            label="İlişkili roller"
            placeholder="Seçim yapınız"
            description="Kullanıcıların sahip olacağı rolleri seçiniz. Kullanıcıların sistem üzerindeki yetenekleri rollerine göre belirlenir."
            isMultiple
            onChange={(props) => {
              const options = props as SelectOptionProps[]

              setForm({
                ...form,
                roles: options.map((option) => ({
                  id: option.value as number,
                  title: option.label,
                })),
              })
            }}
            value={
              form.roles?.length
                ? form.roles.map((role) => ({
                    label: role.title,
                    value: role.id,
                  }))
                : undefined
            }
            options={roles}
          />
        </Column>
      </Row>
      <FormSection
        title="Uygulamalar"
        description="Kullanıcının ilişkili olduğu uygulamaları aşağıdan tanımlayabilir veye düzenleyebilirsiniz."
      >
        <Row>
          {form.apps.length ? (
            <Column xs={{ size: 12 }}>
              {form.apps.map((app, index) => renderApp(app, index))}
            </Column>
          ) : null}
          <Column>
            <Button
              onClick={() => {
                const apps = [...form.apps]

                apps.push({
                  id: 0,
                  loginType: LoginType.OnlyPassword,
                  name: '',
                })

                setForm({ ...form, apps })
              }}
            >
              Yeni uygulama tanımla
            </Button>
          </Column>
        </Row>
      </FormSection>
      <FormFooter>
        <ButtonGroup>
          <Button appearance="primary" isLoading={loading} type="submit">
            {operation === 'create'
              ? 'Kullanıcı oluştur'
              : 'Kullanıcıyı güncelle'}
          </Button>
          <LinkButton href="/users">Vazgeç</LinkButton>
        </ButtonGroup>
      </FormFooter>
    </Form>
  )
}

export default UserForm
