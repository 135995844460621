import { QueryRunResponse } from 'services/http/bi-tool/query'

import { HotelValueProps, HotelVisualizationOptions } from '../types'

export const mapQueryForNews = (
  raw: QueryRunResponse,
  options: HotelVisualizationOptions
): HotelValueProps[] =>
  (raw.rows || []).map((row) => ({
    hotelName: row[options?.fields?.hotelName as string],
    hotelLink: row[options?.fields?.hotelLink as string],
    hotelDescription: row[options?.fields?.hotelDescription as string],
    mediaList: row[options?.fields?.mediaList as any],
    arrangement: row[options?.fields?.arrangement as string],
    adress: row[options?.fields?.adress as string],
    rating: row[options?.fields?.rating as string],
  }))
