import { TrainingGroup, TrainingGroupResponse } from './types'

export const mapTrainingGroupForRequest = (raw: TrainingGroup): unknown => ({
  training_group_id: raw.id,
  code_version: 0,
  data_set_type: raw.dataSetType,
  language_code: raw.languageCode,
  name: raw.name,
  planning_start_date: raw.planningStartDate,
  process_end_date: raw.processEndDate,
  process_started_date: raw.processStartedDate,
  running_status: raw.runningStatus,
  status: raw.status,
  success_rate: raw.successRate,
})

export const mapTrainingGroupForResponse = (raw: any): TrainingGroup => ({
  id: raw.data.id,
  codeVersion: raw.data.code_version,
  createdDate: raw.data.created_date,
  currentVersion: raw.data.current_version,
  dataSetType: raw.data.type,
  languageCode: raw.data.language_code,
  name: raw.data.name,
  planningStartDate: raw.data.planning_start_date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('.'),
  processEndDate: raw.data.process_end_date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('.'),
  processStartedDate: raw.data.process_started_date
    .split('T')[0]
    .split('-')
    .reverse()
    .join('.'),
  runningStatus: raw.data.running_status,
  status: raw.data.status,
  successRate: raw.data.success_rate,
  userId: raw.data.user_id,
})

export const mapTrainingGroupForList = (raw: any): TrainingGroupResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapTrainingGroupForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
