import React from 'react'

import { AreaChartDefinitions } from '../area-chart'
import { BarChartDefinitions } from '../bar-chart'
import { BarDefinitions } from '../bar'
import { ColoredMapDefinitions } from '../colored-map'
import { CounterDefinitions } from '../counter'
import { InfluentialMapDefinitions } from '../influential-map'
import { NameValueDefinitions } from '../name-value'
import { NewsDefinitions } from '../news'
import { PieChartDefinitions } from '../pie-chart'
import { RadarChartDefinitions } from '../radar-chart'
import { RedditDefinitions } from '../reddit'
import { StackedChartDefinitions } from '../stacked-chart'
import { TableDefinitions } from '../table'
import { TimeSeriesDefinitions } from '../time-series'
import { TweetDefinitions } from '../tweet'
import { UserDefinitions } from '../user'
import { WordCloudDefinitions } from '../word-cloud'
import { GraphChartDefinitions } from '../graph-chart/definitions'
import { SocialNumbersDefinitions } from '../social-numbers'
import { VisualizationDefinitionProps } from './types'
import { HotelDefinitions } from '../hotel/definitions'

const VisualizationDefinitions: React.FunctionComponent<VisualizationDefinitionProps> =
  ({ aggregations, fields, groups, onChange, visualization, options }) => {
    const renderVisualizationDefinitions = () => {
      const props = {
        aggregations,
        fields,
        groups,
        options,
      }
      switch (visualization?.type) {
        case 'table':
          return (
            <TableDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'table', options })}
            />
          )
        case 'pie-chart':
          return (
            <PieChartDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'pie-chart', options })}
            />
          )
        case 'bar-chart':
          return (
            <BarChartDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'bar-chart', options })}
            />
          )
        case 'stacked-chart':
          return (
            <StackedChartDefinitions
              {...props}
              onChange={(options) =>
                onChange({ type: 'stacked-chart', options })
              }
            />
          )
        case 'area-chart':
          return (
            <AreaChartDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'area-chart', options })}
            />
          )
        case 'radar-chart':
          return (
            <RadarChartDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'radar-chart', options })}
            />
          )
        case 'counter':
          return (
            <CounterDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'counter', options })}
            />
          )
        case 'colored-map':
          return (
            <ColoredMapDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'colored-map', options })}
            />
          )
        case 'influential-map':
          return (
            <InfluentialMapDefinitions
              {...props}
              onChange={(options) =>
                onChange({ type: 'influential-map', options })
              }
            />
          )
        case 'name-value':
          return (
            <NameValueDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'name-value', options })}
            />
          )
        case 'news':
          return (
            <NewsDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'news', options })}
            />
          )
        case 'bar':
          return (
            <BarDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'bar', options })}
            />
          )
        case 'tweet':
          return (
            <TweetDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'tweet', options })}
            />
          )
        case 'time-series':
          return (
            <TimeSeriesDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'time-series', options })}
            />
          )
        case 'user':
          return (
            <UserDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'user', options })}
            />
          )
        case 'reddit':
          return (
            <RedditDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'reddit', options })}
            />
          )
        case 'word-cloud':
          return (
            <WordCloudDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'word-cloud', options })}
            />
          )
        case 'graph-chart':
          return (
            <GraphChartDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'graph-chart', options })}
            />
          )
        case 'hotel':
          return (
            <HotelDefinitions
              {...props}
              onChange={(options) => onChange({ type: 'hotel', options })}
            />
          )
        case 'social-numbers':
          return (
            <SocialNumbersDefinitions
              {...props}
              onChange={(options) =>
                onChange({ type: 'social-numbers', options })
              }
            />
          )
        default:
          return null
      }
    }

    return renderVisualizationDefinitions()
  }

export default VisualizationDefinitions
