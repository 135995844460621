import { SchemaObject } from 'app/library/layout-builder'

export const DEFAULT_SCHEMA: SchemaObject[] = [
  {
    definitions: {
      id: 'row-default',
      name: 'Row title',
    },
    name: 'row',
    props: {
      defaultElement: true,
    },
    source: {},
    children: [
      {
        definitions: {
          id: 'column',
          name: 'Column title',
        },
        name: 'column',
        props: {
          sizes: {
            md: 6,
            lg: 6,
            sm: 6,
            xs: 6,
          },
        },
        source: {},
        children: [
          {
            definitions: {
              id: 'section',
              name: 'Widget title',
            },
            name: 'section',
            props: { height: 400 },
            source: {},
            children: [
              {
                definitions: {
                  id: 'widget',
                  name: 'Widget title',
                },
                name: 'widget',
                props: {},
                source: {},
                children: [],
              },
            ],
          },
        ],
      },
      {
        definitions: {
          id: 'column2',
          name: 'Column title',
        },
        name: 'column',
        props: {
          sizes: {
            md: 6,
            lg: 6,
            sm: 6,
            xs: 6,
          },
        },
        source: {},
        children: [
          {
            definitions: {
              id: 'section2',
              name: 'Widget title',
            },
            name: 'section',
            props: { height: 400 },
            source: {},
            children: [
              {
                definitions: {
                  id: 'widget2',
                  name: 'Widget title',
                },
                name: 'widget',
                props: {},
                source: {},
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
]
