import React, { useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'
import MainLayout from 'layouts/main'
import roleService, { Role } from 'services/http/identity-server/role'

import { CreateRoleProps } from './types'
import RoleForm from './components/RoleForm'

const CreateRole: React.FunctionComponent<CreateRoleProps> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()

  const handleCreate = async (data: Role) => {
    setIsLoading(true)

    try {
      const response = await roleService.create(data)

      flagcard.add({
        title: 'Rol oluşturuldu',
        children:
          'Geçerli rol başarıyla oluşturuldu. Detay sayfasına yönlendiriliyorsunuz.',
      })

      if (response.data.id) {
        history.push(`/role/update/${response.data.id}`)
      }
    } catch {
      popup.add({
        title: 'Rol oluşturulamadı',
        children: 'Rol oluşturulurken bir sorun oluştu lütfen tekrar deneyin.',
      })
    }

    setIsLoading(false)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Roller" href="/roles" />
      <BreadcrumbItem text="Rol oluştur" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Container width="medium">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Kullanıcılar üzerine tanımlamak adına bir rol oluşturunuz.
                Tanımlanan roller, seçtiğiniz izinlere göre, geçerli role sahip
                olunan kullanıcıların yeteneklerini belirler.
              </p>
            }
          >
            Rol oluştur
          </PageHeader>
          <RoleForm
            loading={isLoading}
            values={{
              title: '',
              name: '',
              claimIds: [],
            }}
            operation="create"
            onSubmit={handleCreate}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default CreateRole
