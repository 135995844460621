import React, { useCallback, useEffect, useState } from 'react'
import personReportsService from 'services/http/report/person-report'
import { PdfPersonReportProps } from './types'
import { PersonReportResponse } from 'services/http/report/person-report/type'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import Loader from 'components/loader'
import PageOne from './report-pages/PageOne'
import PageTwo from './report-pages/PageTwo'
import PageThree from './report-pages/PageThree'
import PageFour from './report-pages/PageFour'
import PageFive from './report-pages/PageFive'
import PageSix from './report-pages/PageSix'
import PageSeven from './report-pages/PageSeven'
import PageEight from './report-pages/PageEight'
import PageNine from './report-pages/PageNine'
import PageTen from './report-pages/PageTen'
import PageCategories from './report-pages/PageCategories'
import PageTwelve from './report-pages/PageTwelve'
import Page13 from './report-pages/Paget13'

const PdfReport: React.FunctionComponent<PdfPersonReportProps> = ({
  match,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [personReport, setPersonReport] = useState<PersonReportResponse>()
  const getByGroupId = useCallback(() => {
    const groupId = match.params.id
    personReportsService
      .getByGroupId(groupId)
      .then((res) => {
        const dataMapped = res?.data
        setPersonReport(dataMapped)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }, [match.params.id])

  useEffect(() => {
    getByGroupId()
  }, [getByGroupId])

  function pdf(selector: string, title?: string) {
    window.scrollTo(0, 0)
    setIsLoading(true)
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve) => {
      const elements = document.querySelectorAll<HTMLElement>(selector)
      const heightSize = elements[0]!.clientHeight
      const widthSize = elements[1]!.clientWidth

      if (elements.length) {
        const images = await canvas(elements)
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'mm',
          format: [heightSize, widthSize],
        })

        images.forEach((image) => {
          const options = pdf.getImageProperties(image)
          const width = pdf.internal.pageSize.getWidth()
          const height = (options.height * width) / options.width
          pdf.addImage(image, 'PNG', 0, 0, width, height)
          pdf.addPage('p', 'landscape')
        })

        pdf.deletePage(images.length + 1)

        const date = new Date()
          .toLocaleDateString('tr-TR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
          .replaceAll(':', '_')
        const fileName = `${title}-${date}`.replaceAll(' ', '_')

        pdf.save(`${fileName}.pdf`)

        setIsLoading(false)
        resolve(fileName)
      }
    })
  }

  async function canvas(elements: NodeListOf<HTMLElement>) {
    const canvases: string[] = []

    await asyncForEach(elements, async (element) => {
      await html2canvas(element, {
        scale: 1.5,
        useCORS: true,
      }).then((canvas) => {
        canvases.push(canvas.toDataURL('image/jpeg'))
      })
    })

    return canvases
  }

  async function asyncForEach(
    array: NodeListOf<HTMLElement>,
    callback: (data: HTMLElement, index: number) => void
  ) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index)
    }
  }
  const lengthTurkey =
    Math.floor(
      personReport?.data?.result?.Statistic?.turkeyPerceptionTweets.length
    ) / 10

  const pagesPDF = [
    <PageOne personReport={personReport} />,
    <PageTwo personReport={personReport} />,
    <PageThree personReport={personReport} />,
    <PageFour personReport={personReport} />,
    <PageFive personReport={personReport} />,
    <PageSix personReport={personReport} />,
    <PageSeven personReport={personReport} />,
    <PageEight personReport={personReport} />,
    <PageNine personReport={personReport} />,
    <PageTen personReport={personReport} />,
    personReport?.data?.result?.Statistic?.classifications?.categoriesByTime?.map(
      (item: any, index: any) => (
        <PageCategories item={item} index={index} personReport={personReport} />
      )
    ),
    <PageTwelve personReport={personReport} />,
    Array.from({ length: lengthTurkey }, (_, i) => i + 1).map((index) => {
      return <Page13 personReport={personReport} index={index} />
    }),
  ]
  return personReport ? (
    <div
      style={{
        backgroundColor: '#f5f5f5',
      }}
    >
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100vh',
            zIndex: 4,
          }}
        >
          <Loader
            show={isLoading}
            size="medium"
            label="Dashboard PDF olarak hazırlanıyor..."
          />
        </div>
      )}

      <button
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '145px',
          height: '29px',
          backgroundColor: '#4c9aff',
          color: 'white',
          borderRadius: '0 0 10px 10px',
          fontWeight: 400,
          border: 'none',
          zIndex: 99,
          cursor: 'pointer',
        }}
        onClick={() => {
          pdf('.printit', 'person-report')
        }}
      >
        PDF'e Dönüştür
      </button>

      {pagesPDF}
    </div>
  ) : (
    <Loader show={true} size="medium" label="Kişi raporu oluşturuluyor..." />
  )
}
export default PdfReport
