export const GRAPH_FIELDS = [
  {
    label: 'Edges',
    value: 'edges',
  },
  {
    label: 'Nodes',
    value: 'nodes',
  },
  {
    label: 'Tweet',
    value: 'tweet',
  },
]
