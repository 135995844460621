import { RadioProps } from '@zera-admin/radio'

export const alarmTypes: RadioProps[] = [
  {
    label: 'Twitter Alarmı',
    value: '32',
  },
  {
    label: 'Haber Alarmı',
    value: '34',
  },
  {
    label: 'Telegram Kanal Alarmı',
    value: '35',
  },
]

export const renderAlarmType = (selectedAlarmType: number) => {
  const selectedAlarm = alarmTypes.filter(
    (x) => x.value === String(selectedAlarmType)
  )

  console.log(selectedAlarm, 'selectedAlarm', selectedAlarmType)
  if (selectedAlarm?.length > 0) {
    return selectedAlarm?.[0]?.label || ''
  }

  return ''
}
