import { languageCode } from 'app/shared/constants'
import countries from './countryFlags.json'

export const pageTypeOptions = [
  {
    label: 'TikTok Gönderileri',
    value: 'tikTokPosts',
  },
  {
    label: 'Youtube Gönderileri',
    value: 'youtubePosts',
  },
  {
    label: 'Instagram Gönderileri',
    value: 'InstagramPosts',
  },
  {
    label: 'Reddit Gönderileri',
    value: 'RedditPosts',
  },
  {
    label: 'Quora Gönderileri',
    value: 'QuoraPosts',
  },
  {
    label: 'Telegram Gönderileri',
    value: 'TelegramPosts',
  },
  {
    label: 'Ekşi Sözlük Gönderileri',
    value: 'EksiSozlukPosts',
  },
  {
    label: 'Mastodon Gönderileri',
    value: 'MastodonPosts',
  },
  {
    label: 'Ülkelere Göre Tweet Sayfaları',
    value: 'tweetsPagesByCountry',
  },
  {
    label: 'Ülkelere Göre Etkili Hesap Tweet Sayfaları',
    value: 'influentialAccountTweetPagesByCountry',
  },
  {
    label: 'Ülkelere Göre Etkili Hesap Sayfaları',
    value: 'influentialAccountPagesByCountry',
  },
  {
    label: 'Ülkelere Göre Haber Sayfaları',
    value: 'newsPagesByCountry',
  },
]

export const countryOptions = countries.map((country) => ({
  label: country.country_name,
  value: country.country_code_3,
  flagUrl: country.flag_url,
}))
