import React, { useImperativeHandle, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Row } from '@zera-admin/page'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'
import WidgetGeoChart from 'widgets/geo-chart-map'

import { getCriteriaInterval } from '../visualization/getters'
import * as Styled from './ColoredMap.styled'
import { mapColoredMapProps, mapQueryForColoredMap } from './utils/mappers'
import {
  ColoredMapProps,
  ColoredMapValueProps,
  ColoredMapVisualizationOptions,
} from './types'

const ColoredMap: React.ForwardRefRenderFunction<
  WidgetHandlerRef<ColoredMapVisualizationOptions>,
  ColoredMapProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<ColoredMapValueProps[]>([])
  const [error, setError] = useState<string>()

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const runQuery = (widget: Widget<ColoredMapVisualizationOptions>) => {
    setIsLoading(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
      })
      .then((res) => {
        setValues(
          mapQueryForColoredMap(
            res.data as QueryRunResponse,
            widget.visualization?.options
          )
        )
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderColoredMap = () => {
    return isLoading ? (
      <Row xs={{ align: 'center', justify: 'center' }}>
        <Spinner className="spinner" size="medium" />
      </Row>
    ) : values?.length ? (
      <Styled.ColoredMap>
        <WidgetGeoChart
          height={500}
          values={mapColoredMapProps(values)}
          label={
            props.widget.visualization.options?.description
              ? ''
              : props.widget.visualization.options?.label || 'Yoğunluk'
          }
        />
      </Styled.ColoredMap>
    ) : (
      <Alert
        appearance={error ? 'error' : 'warning'}
        title="Renklendirilmiş harita oluşturulamadı"
      >
        {error ||
          'Renklendirilmiş harita oluşturacak veri bulunamadı. Lütfen sorgularınızı ve harita ayarlarınızı kontrol ediniz.'}
      </Alert>
    )
  }

  return renderColoredMap()
}

export default React.forwardRef(ColoredMap)
