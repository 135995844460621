import React from 'react'
import { useRouteMatch } from 'react-router'
import Drawer from '@zera-admin/drawer'
import MulticronBuilder from './../../../widgets/multicron-builder/src/index'
import * as Styled from './DashboardJobs.styled'
import { DashboardJobsProps } from './types'
import { useFlagcardContext } from 'app/contexts/flagcard'
import backofficeScheduler from 'services/http/backoffice/scheduler-report'

const DashboardJobs: React.FunctionComponent<DashboardJobsProps> = ({
  onDismiss,
  onSave,
  show,
}) => {
  const [flagcard] = useFlagcardContext()
  const match = useRouteMatch<{ id: string }>()
  const id = match.params.id
  const findPeriod = (period: string) => {
    switch (period) {
      case 'minutely':
        return 1
      case 'hourly':
        return 2
      case 'daily':
        return 3
      case 'weekly':
        return 4
      case 'monthly':
        return 5
      default:
        return 1
    }
  }
  const renderDrawerBody = () => (
    <Styled.DashboardJobsField>
      <MulticronBuilder
        onCancel={onDismiss}
        onSave={async (values) => {
          Promise.all(
            values.map((value) => {
              const request = {
                dashboardId: id,
                emails: value.emails,
                period: findPeriod(value.frequency.type || ''),
                ...(value.trigger.dayInMonth
                  ? {
                      day:
                        value.trigger.dayInMonth === 'end'
                          ? 29
                          : parseInt(value.trigger.dayInMonth),
                    }
                  : {}),
                ...(value.trigger.hour ? { hour: value.trigger.hour } : {}),
                ...(value.trigger.minuteOnly
                  ? { minute: parseInt(value.trigger.minuteOnly) }
                  : {}),
              }
              return backofficeScheduler.create(request)
            })
          ).then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir.',
            })
            onDismiss()
          })
        }}
      />
    </Styled.DashboardJobsField>
  )

  const renderDrawer = () => {
    return (
      <Drawer
        isOpen={show}
        onClose={onDismiss}
        width="wide"
        title="Zamanlanmış görevler"
        description="Aşağıdan seçtiğiniz dashboard'a zamanlanmış görev belirleyerek, belirlediğiniz zamanlarda Dashboard snapshot'unun alınması ve iletilmesini sağlayabilirsiniz."
      >
        {renderDrawerBody()}
      </Drawer>
    )
  }

  return renderDrawer()
}

export default DashboardJobs
