import { SelectOptionProps } from '@zera-admin/select'

import { request } from '../instance'
import {
  AgendaReport,
  AgendaReportListResponse,
  CreateCalculatingRequest,
  CreateAgendaIndependentRequest,
  CreateAgendaReportRequest,
  AgendaReportSearchUsernameResponse,
  CreateAgendaReportResponse,
  DailyReportRequest,
  CreateAgendaIndependentSecondRequest,
  CreateAgendaReportSecondResponse,
  AgendaReportV2,
  UpdateAgendaRequest,
  CreateAgendaReportV3Req,
  ICriteriaFieldSettings,
  IV3RootResponse,
  IV3InfluentialPerson,
} from './types'

export const create = (data: CreateAgendaReportRequest) =>
  request<CreateAgendaReportResponse>({
    method: 'post',
    url: 'agenda-report',
    data,
    mappers: {
      res: (values) => values.data,
    },
  })

export const createCalculating = (data: CreateCalculatingRequest) =>
  request<any>({
    method: 'post',
    url: 'agenda-report/create-agenda-report-calculating',
    data,
  })

export const createIndependent = (data: CreateAgendaIndependentRequest) =>
  request<CreateAgendaReportResponse>({
    method: 'post',
    url: 'agenda-report/independent-agenda-report',
    data,
    mappers: {
      res: (values) => values.data,
    },
  })
export const createsecondIndependent = (
  data: CreateAgendaIndependentSecondRequest
) =>
  request<CreateAgendaReportSecondResponse>({
    method: 'post',
    url: 'agenda-report/2/create/independent',
    data,
    mappers: {
      res: (values) => values.data,
    },
  })
export const addDefinitions = (data: CreateAgendaReportRequest) =>
  request<any>({
    method: 'post',
    url: 'agenda-report/add-definitions',
    data,
  })

export const get = (groupId: string) =>
  request<AgendaReport>({
    method: 'get',
    url: 'agenda-report',
    params: { GroupId: groupId },
  })

export const getV2 = (groupId: string, status: any, withBots: boolean) =>
  request<AgendaReportV2>({
    method: 'get',
    url: `agenda-report/2/report`,
    params: { GroupId: groupId, ReportType: status || 1, WithBots: withBots },
  })
export const updateV2 = (data: UpdateAgendaRequest) =>
  request<any>({
    method: 'put',
    url: 'agenda-report/2/update/report',
    data,
  })
export const getReportTweet = (
  groupId: string | undefined,
  entityKey: string
) =>
  request<any>({
    method: 'get',
    url: `agenda-report/2/tweet`,
    params: { GroupId: groupId, EntityKey: entityKey },
  })
export const getAllAgendaReport = (
  description?: string,
  status?: SelectOptionProps['label'],
  reportType?: string,
  pageSize?: number,
  pageIndex?: number
) =>
  request<AgendaReportListResponse>({
    method: 'get',
    url: 'agenda-report/all-agenda-reports',
    params: {
      Description: description,
      ReportType: reportType,
      Status: status,
      PageSize: pageSize,
      PageIndex: pageIndex,
    },
  })
export const getAllV2Report = (
  reportName?: string,
  status?: SelectOptionProps['label'],
  reportType?: string,
  pageSize?: number,
  pageIndex?: number
) =>
  request<AgendaReportListResponse>({
    method: 'get',
    url: 'agenda-report/2/all',
    params: {
      ReportName: reportName,
      ReportType: reportType,
      Status: status,
      PageSize: pageSize,
      PageIndex: pageIndex,
    },
  })

export const checkStarted = () =>
  request<any>({
    method: 'get',
    url: `agenda-report/ai-calculations/check-started-process`,
  })

export const search = (username: string) =>
  request<AgendaReportSearchUsernameResponse>({
    method: 'get',
    url: 'agenda-report/search-username',
    params: { username },
    mappers: {
      res: (values) => values.data,
    },
  })

export const stopEvents = (groupId: string) =>
  request<any>({
    method: 'put',
    url: `agenda-report/stop-events/${groupId}`,
  })

export const stopDefinition = (crawlTaskId: string) =>
  request<any>({
    method: 'put',
    url: `agenda-report/stop-definition/${crawlTaskId}`,
  })

export const removeDefinition = (crawlTaskId: string) =>
  request<any>({
    method: 'delete',
    url: `agenda-report/remove-definition/${crawlTaskId}`,
  })

export const DailyReport = (dateType: number, Override: boolean = false) =>
  request<DailyReportRequest>({
    method: 'get',
    url: 'daily-report/oracle/daily-report',
    params: {
      DateType: dateType,
      Override,
    },
  })
export const StressReport = (dateType?: number) =>
  request<any>({
    method: 'get',
    url: 'stress-report',
    params: {
      DateType: dateType,
      Override: true,
    },
  })
export const SecondReport = (dateType?: number) =>
  request<any>({
    method: 'get',
    url: 'stress-report/mne-stress-report',
    params: {
      DateType: dateType,
      Override: true,
    },
  })
export const dailyReportSource = () =>
  request<any>({
    method: 'get',
    url: 'agenda-report/2/available-data-sources',
  })

export const getLanguageService = () =>
  request<any>({
    method: 'get',
    url: 'api/definition/languages',
  })

export const createAgendaReportV3 = (data: CreateAgendaReportV3Req) =>
  request<any>({
    method: 'post',
    url: 'agenda-report/3/create/independent',
    data,
    mappers: {
      res: (values) => values.data,
    },
  })

export const availableCountriesV3 = () =>
  request<any>({
    method: 'get',
    url: 'agenda-report/3/available-countries',
  })
export const availableDataSourcesV3 = () =>
  request<any>({
    method: 'get',
    url: 'agenda-report/3/available-data-sources',
  })
export const listAgendaReportV3 = ({
  ReportName,
  Status,
  Environment,
  PageSize,
  PageIndex,
}: {
  ReportName?: string
  Status?: string
  Environment?: string
  PageSize?: number
  PageIndex?: number
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/3/all',
    params: {
      ReportName,
      Status,
      Environment,
      PageSize,
      PageIndex,
    },
  })

export const criteriaFieldSettingsV3 = () =>
  request<ICriteriaFieldSettings>({
    method: 'get',
    url: 'agenda-report/3/criteria-field-settings',
  })

export const agendaReportV3 = (GroupId: string, ReportType: any) =>
  request<IV3RootResponse>({
    method: 'get',
    url: 'agenda-report/3/report',
    params: {
      GroupId,
      ReportType,
    },
  })

export const agendaUpdateReportV3 = (data: any) =>
  request<any>({
    method: 'put',
    url: 'agenda-report/3/update/report',
    data,
  })
export const agendaUpdateReportV3Title = (data: {
  groupId: string
  pageNumber: number
  title: string
}) =>
  request<any>({
    method: 'put',
    url: 'agenda-report/3/update/title',
    data,
  })

export const getInfluentialUser = (GroupId: string, UserScreenName: string) =>
  request<IV3InfluentialPerson>({
    method: 'get',
    url: 'agenda-report/3/twitter/get-account',
    params: {
      GroupId,
      UserScreenName,
    },
  })
