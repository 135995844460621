import React from 'react'
import { crypto } from '@zera-admin/helpers'

import { useFlagcardContext } from 'app/contexts/flagcard'
import BIToolLayout from 'layouts/bi-tool'
import { Widget } from 'services/http/bi-tool/widget'

import WidgetForm from '../widget-form'

import { CreateWidgetProps } from './types'

const CreateWidget: React.FunctionComponent<CreateWidgetProps> = ({
  history,
}) => {
  const [flagcard] = useFlagcardContext()

  const handleSave = (values: Widget) => {
    history.push(`/bi-tool/widget/update/${values.id}`)

    flagcard.add({
      id: crypto.id(),
      appearance: 'success',
      title: 'Widget oluşturuldu',
      children:
        'Girmiş olduğunuz bilgiler ile birlikte widget sisteme başarıyla kayıt edilmiştir. Oluşturduğunuz widgeti düzenleyebilirsiniz.',
    })
  }
  return (
    <BIToolLayout>
      <WidgetForm onSave={handleSave} operation="create" type="page" />
    </BIToolLayout>
  )
}

export default CreateWidget
