import { request } from 'services/http/backoffice/instance'

import type {
  CountryPerceptionsResponse,
  CreateCountryPerceptionParm,
  CountryPerceptionResponse,
} from './types'

import {
  mapCountryPerceptions,
  mapCreateCountryPerception,
  mapCountryPerception,
} from './mappers'

export const getCountryPerceptions = (pageSize?: number, pageIndex?: number) =>
  request<CountryPerceptionsResponse>({
    method: 'get',
    url: 'country-perception/country-perceptions',
    params: { pageSize, pageIndex },
    mappers: {
      res: mapCountryPerceptions,
    },
  })

export const createCountryPerception = (data: CreateCountryPerceptionParm) =>
  request<CountryPerceptionsResponse>({
    method: 'post',
    url: 'country-perception/create',
    data,
    mappers: {
      req: mapCreateCountryPerception,
    },
  })

export const getCountryPerception = (
  id: number,
  description: string,
  sendAsAlarm: boolean
) =>
  request<CountryPerceptionResponse>({
    method: 'get',
    url: 'country-perception/country-perceptions',
    params: { id, description, sendAsAlarm },
    mappers: {
      res: mapCountryPerception,
    },
  })
