import { COORDINATES } from 'components/google-maps/utils/constants'
import { QueryRunResponse } from 'services/http/bi-tool/query'

import { ColoredMapValueProps, ColoredMapVisualizationOptions } from '../types'

export const mapQueryForColoredMap = (
  raw: QueryRunResponse,
  options: ColoredMapVisualizationOptions
): ColoredMapValueProps[] =>
  (raw.rows || []).map((row) => ({
    country: row[options?.country as string],
    count: row[options?.count as string],
    description: row[options?.description as string],
  }))

export const mapColoredMapProps = (values: ColoredMapValueProps[]) =>
  values
    .filter((value) => value.country !== 'UNKNOWN')
    .map((value) => {
      const coordinate = COORDINATES.find(
        (coordinate) => coordinate.alpha3 === value.country
      )

      return {
        country: coordinate?.country || value.country,
        count: value.count,
        description: value.description,
      }
    })
