import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import partOfSpeechsService, {
  PartOfSpeech,
  PartOfSpeechResponse,
} from 'services/http/dataset/part-of-speech'

import { PartOfSpeechListProps } from './types'

const PartOfSpeechList: React.FunctionComponent<PartOfSpeechListProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [partOfSpeech, setPartOfSpeech] = useState<PartOfSpeechResponse>()

  useEffect(() => {
    getPartOfSpeechs()
  }, [])

  const getPartOfSpeechs = async () => {
    try {
      const response = await partOfSpeechsService.get()

      setPartOfSpeech(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/part-of-speech/create">
        Kelime parçaları oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Kelime parçaları" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm Kelime parçalarını görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Kelime parçaları
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'name',
        text: 'Kelime parçası metni',
      },
      {
        name: 'longName',
        text: 'Uzun kelime parçası metni',
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: PartOfSpeech) => (
          <LinkButton
            appearance="subtle-link"
            href={`/part-of-speech/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (partOfSpeech) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={partOfSpeech.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={partOfSpeech.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default PartOfSpeechList
