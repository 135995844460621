import * as Styled from './TweetsPagesByCountry.styled'

import { TweetsPagesByCountryProps } from './types'
import Analysis from '../../components/analysis'

import { Row, Column } from '@zera-admin/page'
import { useEffect, useState } from 'react'
import UpdateButton from '../../components/update-button/UpdateButton'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { TweetCardSingle } from '../../components/tweet-card'
import { Tweet2 } from '../../widgets/types'
import WidgetLayout from '../../widgets/widget-layout'
import { AnalysisData } from '../../components/analysis/types'
import { getAgendaReportTweet } from 'services/http/report/agenda-report-4/endpoint'

const TweetsPagesByCountry = ({
  date,
  isEditValue,
  setIsEditValue,
  pageNumber,
  title,
  data,
  metaData,
}: TweetsPagesByCountryProps) => {
  const [tweetDatas, setTweetDatas] = useState<Tweet2 | undefined>(data)
  const [tweetId, setTweetId] = useState<string | undefined>(data?.EntityKey)
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  const [flagCard] = useFlagcardContext()

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setTweetDatas(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const handleDeleteTweet = () => {
    setTweetDatas(undefined)
  }

  const addTweet = () => {
    getAgendaReportTweet({
      GroupId: metaData?.GroupId,
      TweetId: tweetId,
    })
      .then((res) => {
        setTweetDatas(res.data.data)
      })
      .catch((err) => {
        console.log(err)
        flagCard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Veritabanında tweet bulunamadı. Lütfen tweet ID bilgisini kontrol ediniz.',
        })
      })
  }

  const onChange = (key: string, value: string) => {
    tweetDatas && setTweetDatas({ ...tweetDatas, [key]: value })
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      <Styled.TwitterAnalysisWrapper>
        <UpdateButton
          {...editProps}
          contentData={{
            Tweet: tweetDatas,
          }}
          title={pageData.title}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <Styled.TwitterAnalysisContainer>
          <Row xs={{ justify: 'center' }}>
            {tweetDatas ? (
              <Column xs={{ size: tweetDatas?.Analysis ? 7 : 8 }}>
                <div className="tweet">
                  <TweetCardSingle
                    data={tweetDatas}
                    editMode={editMode}
                    multiple={false}
                    onChange={onChange}
                    setData={setTweetDatas}
                    custom={true}
                  />
                  {editMode && (
                    <button
                      className="delete-tweet"
                      onClick={() => handleDeleteTweet()}
                    >
                      X
                    </button>
                  )}
                </div>
              </Column>
            ) : (
              <Column xs={{ size: 8 }}>
                <div className="add-tweet">
                  <input
                    type="text"
                    placeholder="Enter Tweet ID"
                    value={tweetId}
                    onChange={(e) => setTweetId(e.target.value)}
                  />
                  <button onClick={addTweet}>Add Tweet</button>
                </div>
              </Column>
            )}

            {tweetDatas?.Analysis && (
              <Column xs={{ size: 5 }}>
                <Analysis
                  editMode={editMode}
                  onChange={onChange}
                  data={tweetDatas && (tweetDatas as AnalysisData)}
                />
              </Column>
            )}
          </Row>
        </Styled.TwitterAnalysisContainer>
      </Styled.TwitterAnalysisWrapper>
    </WidgetLayout>
  )
}

export default TweetsPagesByCountry
