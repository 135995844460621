import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { ColoredMapVisualizationOptions } from '../types'

export const generateColoredMapOptions = (
  options: ColoredMapVisualizationOptions,
  query: Query,
  fields: Field[]
): ColoredMapVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      country: group.field as string,
    }
  })

  query.aggregations?.forEach((aggregation) => {
    result = {
      ...result,
      count: aggregation.field as string,
    }
  })

  return result
}

export const generateColoredMapVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as ColoredMapVisualizationOptions)

  return {
    type: 'colored-map',
    options: {
      country: options['userCountryDetection'] || options['countryDetection'],
      count: options['rowCount'],
      label: 'Yoğunluk',
    },
  }
}
