import React from 'react'
import { Column, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import Label from '@zera-admin/label'
import TimeRangePicker from '@zera-admin/alice-time-range-picker'

import * as Styled from '../FilterSettings.styled'

import { FilterTabProps } from '../types'

const IntervalsTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
}) => {
  const renderIntervals = () => {
    const intervals = [...(values.raw?.intervals || [])]

    return intervals.map((value, index) => (
      <Styled.ListField key={index}>
        <Row xs={{ direction: 'row' }}>
          <Column>
            <Input
              name="name"
              label="Aralık adı"
              placeholder="time-1"
              value={value.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                intervals[index].name = event.target.value
                  .toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')

                onChange({ ...values, raw: { ...values.raw, intervals } })
              }}
            />
          </Column>
          <Column>
            <Label>Tarih aralığı</Label>
            <Styled.WidgetScreenTimePicker>
              <TimeRangePicker
                defaultValues={value.interval}
                onChange={(interval) => {
                  intervals[index].interval = interval

                  onChange({ ...values, raw: { ...values.raw, intervals } })
                }}
              />
            </Styled.WidgetScreenTimePicker>
          </Column>
          <Button
            appearance="error"
            size="medium"
            iconBefore={<Icon name="cross" size="small" />}
            onClick={() => {
              intervals.splice(index, 1)
              onChange({ ...values, raw: { ...values.raw, intervals } })
            }}
          />
        </Row>
      </Styled.ListField>
    ))
  }

  const renderOrderTab = () => {
    const intervals = [...(values.raw?.intervals || [])]

    return (
      <React.Fragment>
        <Row xs={{ direction: 'row' }}>
          <Column xs={{ size: 12 }}>
            <p>
              Aşağıdan seçtiğiniz alanları öncelikli olarak sıralayabilirsiniz.
            </p>
          </Column>
        </Row>
        {renderIntervals()}
        <Button
          iconBefore={<Icon name="add" size="small" />}
          onClick={() => {
            intervals.push({
              name: '',
              interval: {
                start: {
                  type: 'relative',
                  unit: 'days',
                  value: 1,
                },
                end: {
                  type: 'now',
                },
              },
            })

            onChange({ ...values, raw: { ...values.raw, intervals } })
          }}
          style={{ marginTop: 12 }}
        >
          Yeni tarih aralığı ekle
        </Button>
      </React.Fragment>
    )
  }

  return renderOrderTab()
}

export default IntervalsTab
