import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;

  .name-value-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8vh;
    .name-value-content-container {
      width: 47%;
    }
  }

  .chart-content {
    background-color: #f1f8ff;
    padding: 20px;
    border-radius: 10px;
    * {
      padding: unset !important;
    }
  }
  .header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 520px) {
      flex-direction: column;
    }
  }
  .chart-container {
    height: 100%;
    width: 47%;
  }

  .socialcards-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 0.3rem;
    justify-content: center;
    margin-top: 6vh;
    @media only screen and (max-width: 520px) {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
  .top-container {
    display: flex;
    justify-content: space-around;
    @media (width >= 2440px) {
      gap: 24.7rem;
    }
    @media (width >= 2000px) {
      gap: 15.7rem;
    }
  }
  .graph-container {
    width: 100%;
  }
  .tweet-box-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: flex-end;
    @media (width >= 2160px) {
      width: 50% !important;
    }
    @media (width >= 1920px) {
      width: 80% !important;
    }
    @media (width >= 1600px) {
      width: 70% !important;
    }

    @media (width >= 1800px) {
      width: 60% !important;
    }
    @media (width >= 2880px) {
      width: 100% !important;
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .line {
    width: 2px;
    height: 100%;
    background-color: gray;
    margin-right: 16px;
  }
`
export const NameCont = styled.div`
  .name-value-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 8vh;
    .name-value-content-container {
      width: 47%;
    }
  }
`
export const HashtagsContainer = styled.div`
  width: 100%;
`

export const HashtagsCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1vw;
  @media (width >= 2440px) {
    margin-top: 5vh;
  }
`
export const StyledDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  margin-left: -2px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: calc(50% - 1px); /* Div'in yarısının yarısı kadar bir yükseklik */
    width: 4px;
    background-color: white;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50%;
  }
`
export const LineComp = styled.div`
  border-left: 2px solid orange;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-color: orange;
  position: relative;
`
export const TitleWrapper = styled.div`
  background-color: orange;
  padding: 5px;
  width: fit-content;
  position: relative;
  left: 20px;
  top: -14px;

  border-radius: 6px;
`

export const Title = styled.span`
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  color: white;
  font-weight: 800;
  @media (width >= 2440px) {
    font-size: 1.4rem;
  }
`

export const Line = styled.div`
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  width: 20px;
  background-color: orange;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 20px;
`
export const NameValue = styled.div<{ theme: boolean }>`
  max-height: calc(50vh);
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 20px;
  }
`
export const TimeSeries = styled.div<{ theme: boolean }>`
  width: 100%;
  margin: 0px auto;
  background: ${(props) =>
    props.theme === 'dark' ? 'rgb(66, 66, 66)' : 'transparent'};
  padding: ${(props) => (props.theme === 'dark' ? '2rem' : 'inherit')};
  box-sizing: border-box;
`
