export const CUSTOM_COMPONENTS = [
  {
    label: 'Etkileşim haritası',
    value: 'interaction-map',
  },
  {
    label: 'Profil kartı',
    value: 'profile',
  },
  {
    label: 'İlişki ağı',
    value: 'relation-chart',
  },
  {
    label: 'Zaman serisi',
    value: 'time-series',
  },
]
