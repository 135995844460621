import React from 'react'
import Alert from '@zera-admin/alert'
import EmptyState from '@zera-admin/empty-state'

import { FollowersBotDetectionProps } from './types'

const FollowersBotDetection: React.FunctionComponent<FollowersBotDetectionProps> =
  ({ data }) => {
    return data && data.length > 0 ? (
      <Alert
        title="Takipçilerinin bot olma olasılığı"
        appearance={data[0].botDetection === 0 ? 'success' : 'error'}
      >
        {data[0].percentage.toFixed(2)}%{' '}
        {data[0].botDetection === 0 ? 'Bot değil' : 'Bot'}
      </Alert>
    ) : (
      <EmptyState
        title="Veri bulunamadı"
        description="Kullanıcı verisi bulunamadı"
      />
    )
  }

export default FollowersBotDetection
