import { request } from 'services/http/dataset/instance'

import type {
  ClassificationCategory,
  ClassificationCategoryResponse,
  CreateClassificationCategoryRequest,
  UpdateClassificationCategoryRequest,
} from './types'

import {
  mapCategoryTypeForList,
  mapCategoryTypeForRequest,
  mapCategoryTypeForResponse,
} from './mappers'

export const create = (data: CreateClassificationCategoryRequest) =>
  request<ClassificationCategory>({
    method: 'post',
    url: 'classification-category',
    data,
    mappers: {
      req: mapCategoryTypeForRequest,
    },
  })

export const get = (value?: string) =>
  request<ClassificationCategoryResponse>({
    method: 'get',
    url: 'classification-category',
    params: { name: value, PageSize: 20 },
    mappers: {
      res: mapCategoryTypeForList,
    },
  })

export const getById = (id: number) =>
  request<ClassificationCategory>({
    method: 'get',
    url: `classification-category/${id}`,
    mappers: {
      res: mapCategoryTypeForResponse,
    },
  })

export const update = (data: UpdateClassificationCategoryRequest) =>
  request<ClassificationCategory>({
    method: 'put',
    url: `classification-category/${data.id}`,
    data,
    mappers: {
      req: mapCategoryTypeForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `classification-category/${id}`,
  })
