interface Criteria {
  fieldName: string
  value: any
  clause: string
  fieldType: any | null
}

interface Group {
  operation: number
  criteria: Criteria[]
  groups: Group[]
}

interface Rule {
  id: string
  type: string
  field: string
  value: any
  operator: string
}

interface InputGroup {
  field: string
  dataType: any | null
  operator: string
  value: any
  id: string
  type: string
  condition: string
  rules: (Rule | InputGroup)[]
}

export const conditionBuilderInsert = (output: InputGroup): Group => {
  const transformRuleToCriteria = (rule: Rule): Criteria => ({
    fieldName: rule.field,
    value: rule.value,
    clause: rule.operator,
    fieldType: null,
  })

  const transformGroupToInputGroup = (group: InputGroup): Group => ({
    operation: group.condition === 'and' ? 1 : 2,
    criteria: group.rules
      .filter((rule) => rule.type === 'rule')
      .map((rule) => transformRuleToCriteria(rule as Rule)),
    groups: group.rules
      .filter((rule) => rule.type === 'group')
      .map((rule) => transformGroupToInputGroup(rule as InputGroup)),
  })

  const input: Group = {
    operation: output.condition === 'and' ? 1 : 2,
    criteria: [
      //   {
      //     fieldName: output.field,
      //     value: output.value,
      //     clause: output.operator,
      //     fieldType: output.dataType,
      //   },
      ...output.rules
        .filter((rule) => rule.type === 'rule')
        .map((rule) => transformRuleToCriteria(rule as Rule)),
    ],
    groups: output.rules
      .filter((rule) => rule.type === 'group')
      .map((rule) => transformGroupToInputGroup(rule as InputGroup)),
  }

  return input
}
