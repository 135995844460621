import { useHistory } from 'react-router-dom'
import Spinner from '@zera-admin/spinner'
import PageLayout, {
  NavProps,
  NotificationsProps,
  ProductHomeProps,
  ProfileProps,
  SearchProps,
  SidebarProps,
} from '@zera-admin/page-layout'

import AliceLogo from 'assets/images/logo.png'
import KtbIcon from 'assets/images/ktb-icon.png'

import { MainLayoutProps } from './types'
import * as Styled from './MainLayout.styled'
import { jvt } from 'app/functions'

const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  children,
  isLoading,
}) => {
  const history = useHistory()
  const token = jvt()

  const product: ProductHomeProps = {
    icon: () => (
      <img
        style={{ minHeight: 40, filter: 'brightness(0)' }}
        src={KtbIcon}
        alt="Kültür ve Turizm Bakanlığı"
      />
    ),
    logo: () => (
      <img
        style={{ filter: 'brightness(0)' }}
        src={AliceLogo}
        alt="Alice Project"
      />
    ),
    onClick: () => {
      history.push('/')
    },
  }

  const navigation: NavProps = {
    menu: [
      {
        text: 'Anasayfa',
        href: '/',
      },
      {
        text: 'Kullanıcı yönetimi',
        menugroup: [
          {
            text: 'Kullanıcıları görüntüle',
            href: '/users',
          },
          {
            text: 'Rolleri görüntüle',
            href: '/roles',
          },
          {
            text: 'İzinleri görüntüle',
            href: '/claims',
          },
          {
            text: 'Uygulamaları görüntüle',
            href: '/apps',
          },
        ],
      },
      {
        text: 'BI Tool',
        menugroup: [
          {
            text: 'Dashboardları görüntüle',
            href: '/bi-tool/dashboards',
          },
          {
            text: 'Widgetları görüntüle',
            href: '/bi-tool/widgets',
          },
        ],
      },
    ],
  }

  const search: SearchProps = {
    placeholder: 'Arama yap...',
    children: <p>Arama sonuçları aşağıda listelenir</p>,
    onChange: (value) => console.log('Search value changed:', value),
    onClose: () => console.log('Search drawer closed'),
    onOpen: () => console.log('Search drawer opened'),
    onSubmit: () => console.log('Search submitted'),
  }

  const notifications: NotificationsProps = {
    contents: [
      { title: 'Direkt', children: <p>Bildirimler</p> },
      { title: 'İzleme', children: <p>Bildirim takibi</p> },
    ],
    onChange: (index) => console.log('Notification tab changed', index + 1),
    onClose: () => console.log('Notification drawer closed'),
    onOpen: () => console.log('Notification drawer opened'),
  }

  const profile: ProfileProps = {
    menugroup: [
      {
        type: 'section',
        menugroup: [
          {
            description: 'Kişisel bilgilerinizi güncelleyin',
            href: '/account/profile',
            text: 'Profil',
          },
          {
            description: 'Şifrenizi güncelleyin',
            href: '/account/password',
            text: 'Şifre güncelle',
          },
        ],
      },
      {
        type: 'group',
        menugroup: [
          {
            description: 'Uygulamadan çıkış yapın.',
            href: '/signout',
            text: 'Çıkış yap',
          },
        ],
      },
    ],
    onClose: () => console.log('Profile popup closed'),
    onOpen: () => console.log('Profile popup opened'),
  }

  const alarmSubMenus = token?.views?.includes('manual-alarm')
    ? [
        {
          text: 'Twitter Ana Alarm',
          href: '/alarms',
        },
        {
          text: 'Telegram Kanala Kullanıcı Ekleme',
          href: '/alarms/common-rule',
        },
        {
          text: 'Manuel Alarm Oluştur',
          href: '/manual-alarm-create',
        },
      ]
    : [
        {
          text: 'Twitter Ana Alarm',
          href: '/alarms',
        },
        {
          text: 'Telegram Kanala Kullanıcı Ekleme',
          href: '/alarms/common-rule',
        },
      ]
  const sidebar: SidebarProps = {
    header: {
      description: 'Gelişmiş yapay zeka platformu',
      children: 'Alice',
    },
    nav: [
      {
        text: 'Raporlar',
        menugroup: [
          {
            text: 'Kişi raporları',
            href: '/person-report',
          },
          {
            text: 'Kişi raporu oluştur',
            href: '/person-report/create',
          },
          {
            text: 'Profil raporu oluştur',
            href: '/profile-report/create',
          },
          {
            text: 'Profil raporları',
            href: '/profile-report',
          },
          {
            text: 'Gündem Raporu Listesi',
            href: '/agenda-reportv4',
          },

          {
            text: 'Gündem Raporu Oluştur',
            href: '/agenda-reportv4/create',
          },
          {
            text: 'Gündem mobil raporu',
            href: '/agenda-report/mobile',
          },
          {
            text: 'Stres raporu',
            href: '/agenda-report/stres',
          },
          {
            text: 'MNE raporu',
            href: '/agenda-report/secondreport',
          },
        ],
      },
      {
        text: 'Veri toplama',
        menugroup: [
          {
            text: 'Tanımlamalar',
            href: '/definition',
          },
          {
            text: 'Tanımlama oluştur',
            href: '/definition/create',
          },
          {
            text: 'Tanımlama etiketleri',
            href: '/definition-tag',
          },
          {
            text: 'Tanımlama etiketleri oluştur',
            href: '/definition-tag/create',
          },
          {
            text: 'Tanımlama Veri Sayısı Sorgulama',
            href: '/definition/query',
          },
        ],
      },
      {
        text: 'Etkili Kişiler',
        href: '/influential-people',
      },
      {
        text: 'Black list',
        href: '/black-list',
      },
      {
        text: 'Haber Listesi',
        href: '/news-list',
      },
      // {
      //   text: 'Haber Alarmları',
      //   href: '/news-alarms',
      // },
      {
        text: 'Alarmlar',
        menugroup: alarmSubMenus,
      },
    ],
  }

  const env = String(process.env.REACT_APP_ENV)

  return (
    <Styled.Container>
      <PageLayout
        product={product}
        nav={navigation}
        search={search}
        notifications={notifications}
        profile={profile}
        sidebar={sidebar}
      >
        <Styled.LayoutSafeArae>
          {isLoading ? (
            <Styled.LayoutSpinner>
              <Spinner />
            </Styled.LayoutSpinner>
          ) : (
            children
          )}
        </Styled.LayoutSafeArae>
        <div
          style={{
            display: env === 'production' ? 'none' : 'block',
            margin: 10,
            position: 'fixed',
            bottom: 0,
            right: 0,
            background: 'rgba(9, 30, 66, 0.04)',
            color: '#42526E',
            padding: 10,
            zIndex: 9999,
            borderRadius: 5,
          }}
        >
          {env === 'development' ? 'Development' : env === 'test' ? 'Test' : ''}
        </div>
      </PageLayout>
    </Styled.Container>
  )
}

export default MainLayout
