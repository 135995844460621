import { string } from '@zera-admin/helpers'
import { TableColumnProps } from '@zera-admin/table/dist/types/types'

import { QueryRunResponse } from 'services/http/bi-tool/query'
import { WidgetQueryType } from 'services/http/bi-tool/widget'

import { numberFormat, dateFormat } from './helpers'
import {
  TableVisualizationColumn,
  TableVisualizationDateFormat,
  TableVisualizationNumberFormat,
} from '../types'

export const mapQueryForTable = (
  raw: QueryRunResponse,
  columns: TableVisualizationColumn[],
  type: WidgetQueryType
) => {
  const headers = mapTableColumns(columns, raw.headers, type)

  return {
    headers,
    rows: (raw.rows || []).map((row) => {
      let result = {}

      Object.keys(row).forEach((key) => {
        const reference = headers.find((column) => column.name === key)

        if (reference) {
          result = {
            ...result,
            [key]: typeof row[key] !== 'object' ? row[key] : '-',
          }
        }
      })

      return result
    }),
  }
}

export const mapTableColumns = (
  columns: TableVisualizationColumn[],
  headers: any[],
  type: WidgetQueryType
): any[] => {
  let result: any[] = []

  if (type === 'raw' || type === 'http') {
    headers?.forEach((header) => {
      result.push({
        name: header.name,
        text: string.textify(header.name),
      })
    })
  } else {
    if (columns.length) {
      columns.forEach((column) => {
        const props: any = {
          name: column.name,
          text: column.alias || string.textify(column.name),
        }

        if (column.format) {
          if (column.type === 'number') {
            props.render = (row: any) =>
              numberFormat(
                row[column.name],
                column.format as TableVisualizationNumberFormat
              )
          } else if (column.type === 'date') {
            props.render = (row: any) =>
              dateFormat(
                row[column.name],
                column.format as TableVisualizationDateFormat
              )
          }
        }

        result.push(props)
      })
    } else {
      result = headers
    }
  }

  return result
}
