import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { genderType } from 'app/shared/constants'
import { validation } from 'app/functions'
import useSources from 'app/hooks/sources'
import { GenderDataSet } from 'services/http/dataset/gender-data-set'

import { GenderDataSetFormProps } from './types'
import useTrainingGroup from 'app/hooks/training-group'

const validations = {
  genderType: yup.string().required('Cinsiyet seçmelisiniz'),
  sourceId: yup.string().required('Kaynak seçmelisiniz'),
  text: yup.string().required('Cinsiyet veri seti metni girmelisiniz'),
}

const GenderDataSetForm: React.FunctionComponent<GenderDataSetFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const form = useForm<GenderDataSet>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const { sources, getSources, getSource, isLoading } = useSources()

  const {
    trainingGroups,
    getTraningGroup,
    isLoading: isLoadingTraining,
  } = useTrainingGroup()

  const handleSubmit = (props: FormHookSubmitProps<GenderDataSet>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Geçerli bir cinsiyet veri seti metni giriniz"
              errorMessage={form.errors.text}
              label="Cinsiyet veri seti metni"
              name="text"
              placeholder="Örnek metin"
              value={form.values.text}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Select
              description="Geçerli bir cinsiyet seçimi yapınız"
              errorMessage={form.errors.genderType}
              label="Cinsiyet"
              name="genderType"
              options={genderType}
              value={genderType.find(
                (genderType) => genderType.value === form.values.genderType
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'genderType',
                  (props as SelectOptionProps).value
                )
              }
              placeholder="Seçim yapın"
              isLoading={isLoading}
            />
          </Column>
          <Column>
            <SearchBox
              description="Geçerli bir kaynak seçimi yapınız"
              errorMessage={form.errors.sourceId}
              label="Kaynak"
              name="sourceId"
              options={sources}
              value={getSource(form.values.sourceId)}
              onFetchOptions={(value) => getSources(value)}
              onChange={(props) =>
                form.handleFieldChange(
                  'sourceId',
                  (props as SearchboxOptionProps).value
                )
              }
              placeholder="Seçim yapın"
              isLoading={isLoading}
            />
          </Column>
          <Column>
            <Select
              description="Geçerli bir eğitim grubu seçin"
              label="Eğitim grubu seçiniz"
              name="trainingGroupId"
              value={getTraningGroup(
                form.values.trainingGroupId,
                form.values.trainingGroupName
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'trainingGroupId',
                  (props as SelectOptionProps).value
                )
              }
              options={trainingGroups}
              isLoading={isLoadingTraining}
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default GenderDataSetForm
