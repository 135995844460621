import React from 'react'

import * as Styled from './InfoCard.styled'
import { InfoCardProps } from './types'

const InfoCard: React.FunctionComponent<InfoCardProps> = ({
  title,
  icon,
  children,
  fontSize = '20px',
  contentMarginTop,
}) => {
  const titleClasss = icon ? 'info-card-title' : 'info-card-title--passive'
  return (
    <Styled.InfoCard>
      <Styled.InfoCardHeader fontSize={fontSize}>
        {icon && (
          <span className="info-card-icon">
            <img src={icon} alt="icon" width="30" height="30" />
          </span>
        )}
        <span className={titleClasss}>{title}</span>
      </Styled.InfoCardHeader>
      <Styled.InfoCardContent contentMarginTop={contentMarginTop}>
        {children}
      </Styled.InfoCardContent>
    </Styled.InfoCard>
  )
}
export default InfoCard
