import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  .update-btn {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    width: 96%;
  }
  .chart {
    width: 96%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
`
