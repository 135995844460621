import { request } from 'services/http/backoffice/instance'

import type {
  BlackList,
  BlackListResponse,
  CreateBlackListRequest,
  UpdateteBlackListRequest,
} from './types'

import {
  mapBlackListForList,
  mapBlackListForRequest,
  mapBlackListForResponse,
} from './mappers'

export const get = (pageIndex: number) =>
  request<BlackListResponse>({
    method: 'get',
    url: 'black-list',
    params: { PageIndex: pageIndex },
    mappers: {
      res: mapBlackListForList,
    },
  })

export const create = (data: CreateBlackListRequest) =>
  request<BlackList>({
    method: 'post',
    url: 'black-list',
    data,
    mappers: {
      req: mapBlackListForRequest,
    },
  })

export const update = (data: UpdateteBlackListRequest, id?: string) =>
  request<BlackList>({
    method: 'put',
    url: `black-list/${id || data.id}`,
    data,
    mappers: {
      req: mapBlackListForRequest,
    },
  })

export const getById = (id: string) =>
  request<BlackList>({
    method: 'get',
    url: `black-list/${id}`,
    mappers: {
      res: mapBlackListForResponse,
    },
  })

export const remove = (id: string) =>
  request<BlackList>({
    method: 'delete',
    url: `black-list/${id}`,
    mappers: {
      res: mapBlackListForRequest,
    },
  })
