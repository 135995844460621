import React, { useImperativeHandle, useState } from 'react'
import ReactWordcloud from 'react-wordcloud'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Container, Row } from '@zera-admin/page'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import queryService from 'services/http/bi-tool/query'
import {
  Widget,
  WidgetHandlerRef,
  WidgetVisualization,
} from 'services/http/bi-tool/widget'

import { getCriteriaInterval } from '../visualization/getters'
import { mapQueryForWordCloud } from './utils/mappers'
import * as Styled from './WordCloud.styled'
import {
  WordCloudProps,
  WordCloudValueProps,
  WordCloudVisualizationOptions,
} from './types'

const WordCloud: React.ForwardRefRenderFunction<
  WidgetHandlerRef<WordCloudVisualizationOptions>,
  WordCloudProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<WordCloudValueProps[]>([])
  const [error, setError] = useState<string>()
  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const runQuery = (widget: Widget) => {
    setIsLoading(true)

    queryService
      .run(
        widget?.query,
        widget?.visualization as WidgetVisualization,
        widget.type,
        {
          criteriaType: props.widget?.criteria,
          dateType: getCriteriaInterval(props.widget),
        }
      )
      .then((res) => {
        setValues(
          mapQueryForWordCloud(
            res.data,
            widget.visualization.options as WordCloudVisualizationOptions
          )
        )
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderChart = () => {
    if (isLoading) {
      return (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      )
    } else {
      if (values.length) {
        return (
          <Styled.WordCloud theme={props.widget.visualization?.theme}>
            <ReactWordcloud
              callbacks={{
                onWordClick: (values) => {
                  if (props.onClickRow) {
                    props.onClickRow(
                      props.widget.visualization,
                      props.widget.query,
                      { label: values.text, value: values.value }
                    )
                  }
                },
              }}
              options={{
                colors: [
                  '#1f77b4',
                  '#ff7f0e',
                  '#2ca02c',
                  '#d62728',
                  '#9467bd',
                  '#8c564b',
                ],
                enableTooltip: true,
                deterministic: false,
                fontFamily: 'impact',
                fontSizes: [14, 100],
                fontStyle: 'normal',
                fontWeight: 'normal',
                padding: 1,
                rotations: 2,
                rotationAngles: [0, 0],
                scale: 'linear',
                spiral: 'archimedean',
                transitionDuration: 1000,
              }}
              size={[800, 450]}
              words={values}
            />
          </Styled.WordCloud>
        )
      } else {
        return (
          <Container width="narrow">
            <Alert
              appearance={error ? 'error' : 'warning'}
              title="Kelime bulutu verisi oluşturulamadı"
            >
              {error ||
                'Kelime bulutunu oluşturacak veri bulunamadı. Lütfen sorgularınızı ve ayarlarınızı kontrol ediniz.'}
            </Alert>
          </Container>
        )
      }
    }
  }

  return renderChart()
}

export default React.forwardRef(WordCloud)
