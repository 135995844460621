import React from 'react'
import Spinner from '@zera-admin/spinner'

import * as Styled from './Loader.styled'
import { LoaderProps } from './types'

const Loader: React.FunctionComponent<LoaderProps> = ({
  label,
  show,
  size = 'small',
}) => {
  const renderLoader = () => {
    if (!show) return null

    return (
      <Styled.Loader>
        <Spinner size={size} />
        {label && <span>{label}</span>}
      </Styled.Loader>
    )
  }

  return renderLoader()
}

export default Loader
