import React, { useState, useRef, useEffect } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Select, { SelectOptionProps } from '@zera-admin/select'
import EmptyState from '@zera-admin/empty-state'
import TimeRangePicker from '@zera-admin/alice-time-range-picker'

import {
  VisualizationOptions,
  Widget,
  WidgetHandlerRef,
  WidgetVisualization,
  WidgetVisualizationType,
} from 'services/http/bi-tool/widget'
import { Query } from 'services/http/bi-tool/query'
import WidgetVisualTypes from 'bi-tool/drawers/widget-visual-types'
import WidgetSettings, {
  WidgetSettingsValues,
} from 'bi-tool/drawers/widget-settings'
import FilterSettings from 'bi-tool/drawers/filter-settings'
import Visualization, {
  mapVisualization,
  mapVisualizationOptions,
} from 'bi-tool/visualizations'

import NotFoundImage from 'assets/images/not-found.png'

import * as Styled from './WidgetScreen.styled'
import { WidgetScreenProps } from './types'

const WidgetScreen: React.FunctionComponent<WidgetScreenProps> = ({
  breadcrumbVisibility = true,
  children,
  fields,
  onChange,
  onDelete,
  onSave,
  onCopy,
  onGetFields,
  onGetTables,
  operation,
  sources,
  tables,
  values,
  copy,
}) => {
  const [visualizationType, setVisualizationType] =
    useState<WidgetVisualizationType>('table')
  const [settingsDrawerVisibility, setSettingsDrawerVisibility] =
    useState(false)
  const [visualTypesDrawerVisibility, setVisualTypesDrawerVisibility] =
    useState(false)
  const [filterDrawerVisibility, setFilterDrawerVisibility] = useState(false)
  const [tableVisibility, setTableVisibility] = useState(false)
  const [interval, setInterval] = useState(values.query?.interval)

  const visualizationRef = useRef<WidgetHandlerRef>(null)

  useEffect(() => {
    if (values.visualization?.type) {
      setVisualizationType(values.visualization.type)
    }
  }, [values.visualization?.type])

  useEffect(() => {
    if (values.query?.interval) {
      setInterval(values.query?.interval)
    }
  }, [values.query?.interval])

  const getSourceOption = () => {
    if (!values.query?.source) return undefined

    const source = sources.find((source) => source.id === values.query?.source)

    return {
      value: values.query?.source,
      label: source?.name as string,
    }
  }

  const getTableOption = () => {
    if (!values.query?.table) return undefined

    const table = tables.find((table) => table.id === values.query?.table)

    return {
      value: values.query?.table,
      label: table?.name as string,
    }
  }

  const handleChangeTable = (table: string) => {
    if (values.query?.source && table) {
      onGetFields({ datasource: values.query?.source, table })
    }
  }

  const handleChangeTableVisibility = (visibility: boolean) => {
    if (visibility) {
      setTableVisibility(true)
    } else {
      setTableVisibility(false)
    }
  }

  const handleRefresh = () => {
    const widget = { ...values, query: { ...values.query, interval } }

    Promise.resolve()
      .then(() => setTableVisibility(false))
      .then(() => visualizationRef.current?.runQuery(widget))
      .then(() => onChange(widget))
  }

  const handleSaveWidgetChanges = (values: Widget<VisualizationOptions>) => {
    Promise.resolve()
      .then(() => setTableVisibility(false))
      .then(() => setVisualizationType(values.visualization?.type))
      .then(() => onChange(values))
  }

  const handleSaveWidgetSettings = (settings: WidgetSettingsValues) => {
    const result = {
      ...values,
      ...settings,
      visualization: {
        ...values.visualization,
        field: settings.field,
        version: settings.version,
      },
      query: { ...values.query, ...settings.query },
    }

    if (values.query?.table !== result.query.table) {
      handleChangeTable(result.query.table)
    }

    onChange(result)
  }

  const handleSaveVisualOptions = (visualization: WidgetVisualization) => {
    let result = { ...values, visualization }

    if (visualization.type !== values.visualization.type) {
      result = getVisualizationSettings(values.query, true, visualization)
    }

    result.visualization.version = values.version

    handleSaveWidgetChanges(result)
  }

  const getVisualizationSettings = (
    query: Query,
    summarize?: boolean,
    visualization?: WidgetVisualization
  ) => {
    let result = {
      ...values,
      visualization: visualization || values.visualization,
      query,
    }

    if (summarize) {
      if (
        query.aggregations?.length ||
        query.groups?.length ||
        values.type !== 'json'
      ) {
        result.visualization.options = mapVisualizationOptions(
          result.visualization,
          query,
          fields
        )
      } else {
        result.visualization = mapVisualization(result.visualization, fields)
      }
    }

    result.visualization.version = values.version

    return result
  }

  const handleSaveFilterSettings = (
    query: Query,
    summarize?: boolean,
    visualization?: WidgetVisualization
  ) => {
    const result = getVisualizationSettings(query, summarize, visualization)

    handleSaveWidgetChanges(result)
  }

  const renderEmpty = () => (
    <Styled.WidgetScreenEmptyContent>
      <EmptyState
        title="Veri kaynağınızı belirleyin"
        description="Verilerinizi görselleştirmek için öncelikle bir veri kaynağı ve bir tablo belirlemelisiniz. "
        image={{
          src: NotFoundImage,
        }}
      />
      <Row xs={{ direction: 'column' }}>
        <Column>
          <Select
            name="source"
            label="Veri kaynağı"
            placeholder="Veri kaynağı seçiniz"
            options={sources.map((source) => ({
              label: source.name,
              value: source.id,
            }))}
            isDisabled={!sources || sources.length === 0}
            value={getSourceOption()}
            onChange={(props) => {
              const value = (props as SelectOptionProps).value as string

              onChange({
                ...values,
                query: {
                  ...values.query,
                  source: value,
                },
              })
              onGetTables(value)
            }}
          />
        </Column>
        {values.query?.source ? (
          <Column>
            <Select
              name="table"
              label="Veri tablosu"
              placeholder="Tablo seçiniz"
              options={tables.map((table) => ({
                label: table.name,
                value: table.id,
              }))}
              value={getTableOption()}
              onChange={(props) => {
                const value = (props as SelectOptionProps).value as string

                onChange({
                  ...values,
                  query: {
                    ...values.query,
                    table: value,
                  },
                })

                handleChangeTable(value)
              }}
            />
          </Column>
        ) : null}
      </Row>
    </Styled.WidgetScreenEmptyContent>
  )

  const renderVisualize = () => {
    if (tableVisibility) {
      return (
        <Visualization
          widget={{
            ...values,
            visualization: { type: 'table', options: { columns: [] } },
          }}
          ref={visualizationRef}
        />
      )
    } else {
      return <Visualization widget={values} ref={visualizationRef} />
    }
  }

  const renderPageContent = () => {
    return values.visualization?.type ? renderVisualize() : renderEmpty()
  }

  const renderPageHeader = () => {
    const actions = (
      <Row xs={{ direction: 'row' }}>
        {interval && (
          <Column>
            <Styled.WidgetScreenTimePicker>
              <TimeRangePicker
                defaultValues={interval}
                onChange={setInterval}
              />
            </Styled.WidgetScreenTimePicker>
          </Column>
        )}
        <Column>
          <Button
            appearance="primary"
            iconBefore={<Icon name="refresh" size="small" />}
            onClick={() => handleRefresh()}
          >
            Yenile
          </Button>
        </Column>
        <Column>
          <Button
            iconBefore={<Icon name="filter" size="small" />}
            onClick={() => setFilterDrawerVisibility(true)}
          >
            Filtre ve Hesaplamalar
          </Button>
        </Column>
        <Column>
          <Button
            iconBefore={<Icon name="filter" size="small" />}
            onClick={() => onCopy(!copy)}
          >
            {copy ? 'Kopyalamayı geri al' : 'Widget Kopyala'}
          </Button>
        </Column>
      </Row>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Widgetlar" href="/bi-tool/widgets" />
        <BreadcrumbItem
          text={
            copy === true
              ? 'Kopya Oluştur'
              : operation === 'create'
              ? 'Widget oluştur'
              : 'Widget güncelle'
          }
        />
      </Breadcrumb>
    )

    return (
      <PageHeader
        actions={
          operation === 'update' || fields.length > 0 ? actions : undefined
        }
        breadcrumbs={breadcrumbVisibility ? breadcrumbs : undefined}
      >
        {values.title || 'Widget'}
      </PageHeader>
    )
  }

  const renderPageFooter = () => {
    const customs = [
      'table',
      'tweet',
      'reddit',
      'news',
      'user',
      'relation-chart',
      'interaction-map',
      'time-series',
    ]

    const settings = (
      <ButtonGroup>
        <Button
          className="primary-outline"
          iconBefore={<Icon name="preferences" size="small" />}
          onClick={() => setSettingsDrawerVisibility(true)}
        >
          Widget ayarları
        </Button>
        <Button
          className="primary-outline"
          iconBefore={<Icon name="issues" size="small" />}
          onClick={() => setVisualTypesDrawerVisibility(true)}
        >
          Görünüm tipleri
        </Button>
        <Button
          iconBefore={<Icon name="copy" size="small" />}
          onClick={() =>
            navigator.clipboard.writeText(JSON.stringify(values.query))
          }
        >
          Şemayı kopyala
        </Button>
      </ButtonGroup>
    )

    const actions = (
      <Row xs={{ justify: 'end', gutter: 'none', align: 'center' }}>
        <Button
          className="success"
          appearance="primary"
          iconBefore={<Icon name="tray" size="small" />}
          onClick={onSave}
        >
          {copy
            ? 'Kopyayı Oluştur'
            : operation === 'create'
            ? 'Değişiklikleri kaydet ve oluştur'
            : 'Değişiklikleri kaydet'}
        </Button>
      </Row>
    )

    const appearances = (
      <Row xs={{ justify: 'center', gutter: 'none' }}>
        {!customs.includes(visualizationType) && (
          <ButtonGroup>
            <Button
              appearance={!tableVisibility ? 'subtle' : 'primary'}
              iconBefore={<Icon name="table" size="small" />}
              onClick={() => handleChangeTableVisibility(true)}
            />

            <Button
              appearance={tableVisibility ? 'subtle' : 'primary'}
              iconBefore={<Icon name="lightbulb" size="small" />}
              onClick={() => handleChangeTableVisibility(false)}
            />
          </ButtonGroup>
        )}
      </Row>
    )

    if (!fields.length && operation === 'create') return null

    return (
      <Styled.WidgetScreenFooter>
        <Row>
          <Column>{settings}</Column>
          <Column>{appearances}</Column>
          <Column>{actions}</Column>
        </Row>
      </Styled.WidgetScreenFooter>
    )
  }

  const renderPageDrawers = () => (
    <React.Fragment>
      <WidgetSettings
        show={settingsDrawerVisibility}
        onChangeTable={handleChangeTable}
        onDismiss={() => setSettingsDrawerVisibility(false)}
        onDelete={() => (onDelete ? onDelete() : {})}
        onSave={handleSaveWidgetSettings}
        values={{
          id: values.id,
          title: values.title as string,
          description: values.description,
          type: values.type,
          criteria: values.criteria,
          version: values.version,
          dashboardKeys: values?.dashboardKeys,
          field: values?.visualization?.field,
          query: {
            source: values.query?.source,
            table: values.query?.table,
          },
        }}
        fields={fields}
        sources={sources}
        tables={tables}
      />
      <WidgetVisualTypes
        show={visualTypesDrawerVisibility}
        onDismiss={() => setVisualTypesDrawerVisibility(false)}
        onSave={handleSaveVisualOptions}
        fields={fields}
        groups={values.query?.groups || []}
        aggregations={values.query?.aggregations || []}
        values={values.visualization || ({} as WidgetVisualization)}
      />
      <FilterSettings
        show={filterDrawerVisibility}
        onDismiss={() => setFilterDrawerVisibility(false)}
        onSave={handleSaveFilterSettings}
        values={values.query}
        fields={fields}
        type={values.type}
      />
    </React.Fragment>
  )

  return (
    <Page>
      {renderPageHeader()}
      {renderPageContent()}
      {renderPageFooter()}
      {renderPageDrawers()}
      {children}
    </Page>
  )
}

export default WidgetScreen
