import React from 'react'
import { Widget } from 'services/http/bi-tool/widget'

import { SchemaObject, SchemaNode } from '../schema'

/** Element flow descriptor */
export interface EventDescriptor {
  type: string
  name: string
}

/** Element flow events */
export interface EventDefinition {
  name: string
  descriptor?: EventDescriptor[]
}

/** Element properties's visibility types */
export enum PropertyMappingType {
  String = 1, // String type with input
  Number = 2, // Number type with input
  Boolean = 3, // True / False type with checkbox
  Array = 4, // List type with each item type
  Object = 5, // Object type with dropdown
  Color = 6, // Color type with color picker
  Icon = 7, // Icon type with icon picker
  Image = 8, // Image type with image picker
  Video = 9, // Video type with video picker
  Textarea = 10, // Textarea type with input
  Alignment = 11, // Alignment type with alignment picker
  LinkWizard = 12, // Url type with input
  ArrayData = 13, // List type with dynamic list item type
  ParentObject = 14, // Parent objects its has sub-property, used with children.
  Html = 15, // HTML type with html editor
  Menu = 16, // Menu items field
  Website = 17, // Web site picker field
  SrcSet = 18,
  Contributor = 19,
  Pricing = 20,
  DateTime = 21,
  ContentType = 22,
  Brand = 23,
  Css = 24,
  ProductData = 25,
  Product = 26,
  ContentTypes = 27,
  DynamicFilter = 28,
  Custom = 98, // Custom rendering
  Any = 99, // Any
}

/** Set schema modal data */
export type SetData = (schema: SchemaObject[]) => any

/** Object type (e.g. { key: "formapps", text: "Formapps" }) */
export interface PropertyDefinitionOneOf {
  key: string | number
  text: string
}

/** Entry Options Definitions */
export type DefinitionKeys =
  | 'advanceMode'
  | 'bindingProperty'
  | 'customMapping'
  | 'mappingProperty'
  | 'externalDesign'

/** Fill options (only uses single property on element) */
export interface OptionsDefinitions {
  bindingProperty?: string // which field (array) will fill?
  mappingProperty?: string // which on field (array) will mapping?
  advanceMode?: boolean // advance component mode
  onChangeMode?: (schemaNode: SchemaNode) => SchemaNode // advance mode changed
  onDragged?: (schemaNode: SchemaNode) => SchemaNode[] // on component dragged
  customMapping?: PropertyDefinition[] // custom flow mapping
  externalDesign?: boolean // component displays another design page
}

/** Element mapping type definitions */
export interface PropertyDefinitonMapping {
  type: PropertyMappingType // mapping type
  keys?: PropertyDefinition[] // array keys
  children?: PropertyDefinition[] // property childrens with only parent object type
  oneOf?: PropertyDefinitionOneOf[] // object keys
  showPropertyText?: boolean // show or hide property text if custom field renderer active
}

/** Element group property object */
export interface PropertyGroupObject {
  key: string
  title: string
  withObject?: boolean
}

/** Element property definitions */
export interface PropertyDefinition {
  name: string
  text?: string
  description?: string
  default?: any
  group?: PropertyGroupObject
  initialFlowValue?: boolean
  mapping: PropertyDefinitonMapping
}

/** Element entry render type on container */
export enum ContainerEntryEnum {
  Component = 1,
  Schema = 2,
}

export interface ComponentDefinitions {
  name: string
  properties: PropertyDefinition[]
}

/** Element container entry operations */
export class ContainerEntry {
  component: React.FunctionComponent
  name: string | undefined
  entryType: ContainerEntryEnum
  properties: PropertyDefinition[] = []
  events: EventDefinition[] = []
  options: Widget | undefined = undefined
  children: SchemaObject[] = []

  constructor(
    component: React.FunctionComponent,
    name: string,
    entryType: ContainerEntryEnum,
    properties: PropertyDefinition[] = [],
    children: SchemaObject[] = []
  ) {
    this.component = component
    this.name = name
    this.entryType = entryType
    this.properties = [
      ...properties,
      {
        name: 'hideRuntime',
        text: 'Çalışırken Gizle',
        mapping: { type: PropertyMappingType.Boolean },
      },
      {
        name: 'className',
        text: 'Özel Sınıf (className)',
        mapping: { type: PropertyMappingType.String },
      },
    ]
    this.children = children
  }

  /** Create a node instance on container with current type and properties */
  static buildFromComponent(
    component: React.FunctionComponent,
    definitions: ComponentDefinitions
  ) {
    let entry = new ContainerEntry(
      component,
      definitions.name,
      ContainerEntryEnum.Component,
      definitions.properties,
      (component as any)?.children
    )
    return entry
  }
}
