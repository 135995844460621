export const NUMBER_FORMATS = [
  {
    label: 'Varsayılan',
    value: 'default',
  },
  {
    label: 'Sayı',
    value: 'number',
  },
  {
    label: 'Yüzde',
    value: 'percent',
  },
  {
    label: 'Fiyat / Döviz',
    value: 'currency',
  },
]
