import styled from 'styled-components'

export const InstagramCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  height: fit-content !important;

  .gradient {
    border-radius: 0 0 8px 8px;
    background: #833ab4;
    background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
  }

  .post {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      padding: 16px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;

      &-profile {
        display: flex;
        align-items: center;

        &-image {
          margin-right: 16px;

          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
          }
        }

        &-info {
          display: flex;
          flex-direction: column;
          align-items: start;

          span {
            font-weight: 500;
            font-size: 14px;
            color: #666;
          }

          &-link {
            display: flex;
            align-items: center;
            a {
              font-size: 16px;
              font-weight: 600;
              color: #333;
              text-decoration: none;
            }

            img {
              width: 16px;
              height: 16px;
              margin-left: 4px;
            }
          }
        }

        &-data {
          margin-left: 8px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: end;
          span {
            font-weight: 600;
            font-size: 16px;
            color: #333;
          }
        }
      }
    }

    &-body {
      &-image {
        img {
          height: 200px;
          width: 100%;
          object-fit: cover;
        }
      }
      &-image-error {
        height: 200px;
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
          opacity: 0.4;
        }
      }

      &-content {
        display: flex;

        justify-content: start;
        text-align: start;
        padding: 8px 16px;

        &-text {
          &-user {
            font-weight: 600;
            color: #333;
          }
        }

        button {
          background: none;
          border: none;
          color: #333;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        .user {
          font-size: 16px;
          color: #333;
          font-weight: 400;
          line-height: 1.5;

          word-break: break-word;
          span {
            font-weight: 600;
            color: #333;
          }
        }
      }
    }

    &-footer {
      position: relative;
      border-top: 1px solid #f0f0f0;

      &-url {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        padding: 12px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: #fff;
        }
        a:hover {
          color: #fff;
          text-decoration: underline;
        }
      }

      &-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        padding: 12px;

        color: #333;
        font-size: 14px;
        font-weight: 600;

        &-like {
        }
      }

      &-logo {
        position: absolute;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;

        &-warpper {
          padding: 4px;
          border-radius: 100%;
          background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
          border: 2px solid #fff;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 40px;
          }
        }
      }
    }
  }
`
