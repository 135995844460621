import React from 'react'

import BotDataSet from './bot-classification'
import ClassificationDataSet from './classification-data-set'
import GenderByUser from './gender-by-user'
import GenderDataSet from './gender-data-set'
import NamedDataSet from './named-entity-recognition'
import SentimentDataSet from './sentiment-data-set'

import { TrainingGroupModalEnum, TrainingGroupModalProps } from './types'

const TrainingGroupModal: React.FunctionComponent<TrainingGroupModalProps> = ({
  trainingModal,
  onClose,
  values,
}) => {
  const renderModal = () => {
    switch (trainingModal) {
      case TrainingGroupModalEnum.Bot:
        return <BotDataSet onClose={onClose} values={values} />

      case TrainingGroupModalEnum.Classification:
        return <ClassificationDataSet onClose={onClose} values={values} />

      case TrainingGroupModalEnum.Gender:
        return <GenderDataSet onClose={onClose} values={values} />

      case TrainingGroupModalEnum.GenderByUser:
        return <GenderByUser onClose={onClose} values={values} />

      case TrainingGroupModalEnum.Named:
        return <NamedDataSet onClose={onClose} values={values} />

      case TrainingGroupModalEnum.Sentiment:
        return <SentimentDataSet onClose={onClose} values={values} />

      default:
        return <React.Fragment></React.Fragment>
    }
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {renderModal()}
    </div>
  )
}

export default TrainingGroupModal
