import { useRef } from 'react'

import GoogleMaps from 'components/google-maps'

import { InfluentialPeopleMapProps } from './types'

const InfluentialPeopleMap: React.FunctionComponent<InfluentialPeopleMapProps> =
  ({ theme = 'standard', values, height, onClick }) => {
    const mapRef = useRef<google.maps.Map>()

    const handleLoad = (map: google.maps.Map) => {
      mapRef.current = map

      values?.forEach((value) => {
        const marker = new google.maps.Marker({
          position: value.position,
          map,
          title: `${value.country?.toUpperCase()}: ${value.label}`,
          label: {
            text: value.label,
            color: '#fff',
            fontSize: '11px',
            fontWeight: 'bold',
          },
          optimized: false,
          icon: {
            path: 'M 0, 0 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
            fillColor: 'green',
            fillOpacity: 0.65,
            strokeWeight: 0,
            rotation: -45,
            scale: 0.3,
          },
        })

        if (onClick) {
          google.maps.event.addDomListener(marker, 'click', () =>
            onClick(value)
          )
        }
      })
    }

    return (
      <GoogleMaps
        options={{
          zoom: 2.5,
        }}
        onLoad={handleLoad}
        theme={theme}
        height={height}
      />
    )
  }

export default InfluentialPeopleMap
