import React, { useState, useCallback } from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import EmptyState from '@zera-admin/empty-state'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import Modal from '@zera-admin/modal'
import Select, { SelectOptionProps, SelectValueProps } from '@zera-admin/select'
import Switch from '@zera-admin/switch'
import Textarea from '@zera-admin/textarea'
import { Row, Column } from '@zera-admin/page'

import { query } from 'app/functions'
import {
  SchemaSourceOptions,
  SchemaSourceTypes,
  useSchemaService,
} from 'app/library/layout-builder'
import widgetService, { Widget } from 'services/http/bi-tool/widget'
import dashboardService, { Dashboard } from 'services/http/bi-tool/dashboard'
import { Query } from 'services/http/bi-tool/query'
import ComponentHeader from 'bi-tool/drag-drop/ComponentHeader'
import Visualization from 'bi-tool/visualizations'
import FilterSettings from 'bi-tool/drawers/filter-settings'
import WidgetDrawer from 'pages/bi-tool/widget/widget-drawer'

import * as Styled from './WidgetComponent.styled'

import NotFoundImage from 'assets/images/not-found.png'
import WidgetHeroid from 'assets/images/widget-heroid.png'

import { WidgetComponentModalProps, WidgetComponentProps } from './types'
import { WIDGET_GRID_TYPES } from './constants'

const WidgetComponent: React.FunctionComponent<WidgetComponentProps> = (
  props
) => {
  const {
    className,
    dashboard,
    description,
    designing,
    entry,
    fields = [],
    filter,
    grid,
    id,
    reference,
    schemaNode,
    title,
    type = 'local',
    dashboardKey,
    dashboardId,
    dashboardTitle,
  } = props
  const [modal, setModal] = useState<WidgetComponentModalProps | null>(null)
  const [cloneModal, setCloneModal] =
    useState<WidgetComponentModalProps | null>(null)
  const [cloneLoading, setCloneLoading] = useState<boolean>(false)
  const [filterDrawerVisibility, setFilterDrawerVisibility] =
    useState<boolean>(false)
  const [widgetDrawerVisibility, setWidgetVisibility] = useState<boolean>(false)
  const [widgets, setWidgets] = useState<Widget[]>([])
  const [activeWidget, setactiveWidget] = useState<Widget>()
  const [dashboards, setDashboards] = useState<Dashboard[]>([])
  const schemaService = useSchemaService()
  const handleChangeProperties = (
    value: object,
    source?: SchemaSourceOptions
  ) => {
    schemaService.changeProperties(id as string, value, source)
  }

  const handleFilterDrawerVisibility = useCallback(() => {
    setFilterDrawerVisibility(!filterDrawerVisibility)
  }, [filterDrawerVisibility])

  const handleWidgetDrawerVisibility = useCallback(() => {
    setWidgetVisibility(!widgetDrawerVisibility)
  }, [widgetDrawerVisibility])

  const handleWidgetSaveChanges = (widget: Widget) => {
    const source = schemaNode?.getSource()
    const options = schemaNode?.getOptions() as Widget

    if (source?.ref) {
      schemaService.changeSource(id as string, {
        type: SchemaSourceTypes.Static,
        ref: widget.id,
      })
    } else {
      handleChangeProperties(
        {
          title: widget.title || options?.title,
          description: widget.description || options?.title,
          type: 'local',
        },
        { type: SchemaSourceTypes.Static, ref: widget.id }
      )
    }
  }

  const handleClone = (
    value: WidgetComponentModalProps,
    source: SchemaSourceOptions
  ) => {
    setCloneLoading(true)

    widgetService
      .getById(source.ref as string)
      .then((res) => {
        if (res.data) {
          widgetService
            .create({
              ...res.data,
              title: value?.title as string,
              description: value?.description,
            })
            .then((created) => {
              const result = created.data

              if (result) {
                schemaService.cloneWidget(id as string, value, {
                  type: SchemaSourceTypes.Static,
                  ref: result.data,
                })
              }

              setCloneLoading(false)
              setCloneModal(null)
            })
        }
      })
      .catch((err: Error) => {
        setCloneLoading(false)
        console.log(err)
      })
  }

  const getWidgets = async (title?: string) => {
    try {
      const response = await widgetService.get(undefined, undefined, 5000)
      title &&
        setactiveWidget(
          response.data.list?.find((widget: Widget) => widget.id === title)
        )
      setWidgets(response.data.list)
    } catch (err) {
      console.log(err)
    }
  }
  const getDashboards = async () => {
    try {
      const response = await dashboardService.get()

      setDashboards(response.data.list)
    } catch (err) {
      console.log(err)
    }
  }

  const renderWidgetWrapper = () => {
    const options = schemaNode?.getOptions()

    return (
      <Styled.WidgetComponent theme={options?.visualization?.theme}>
        <Styled.WidgetComponentHeader>
          <Row xs={{ axis: 'x', align: 'center', justify: 'around' }}>
            <Column>
              <h4>{title || 'Widget (Veri Görselleştirme)'}</h4>
              <p>
                {description ||
                  'Aşağıdan seçmiş olduğunuz widget verilerini görüntülüyorsunuz'}
              </p>
            </Column>
            {/* <Column>
              <Button
                iconBefore={<Icon name="filter" size="medium" />}
                onClick={handleFilterDrawerVisibility}
              />
            </Column> */}
          </Row>
        </Styled.WidgetComponentHeader>
        <Styled.WidgetVisualization>
          <Visualization
            widget={{
              ...(options as Widget),
              query: query(options as Widget, type, dashboard, filter),
              grid,
            }}
            onClickRow={reference?.onClick}
          />
        </Styled.WidgetVisualization>
      </Styled.WidgetComponent>
    )
  }

  const renderWidgetSettings = () => {
    if (modal) {
      const options = schemaNode?.getOptions()
      const actions = [
        {
          text: 'Ayarları kaydet',
          isDisabled: !modal.dashboardTitle && !modal.widget,
          onClick: () => {
            setModal(null)
            handleChangeProperties(
              {
                title: modal.title || options?.title,
                description: modal.description || options?.description,
                grid: modal.grid,
                type: modal.type || 'local',
                dashboardKey: modal.dashboardKey,
                dashboardId: modal.dashboardId,
                dashboardTitle: modal.dashboardTitle,
              },
              { type: SchemaSourceTypes.Static, ref: modal.widget }
            )
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]
      const widget = {
        value: modal?.widget as string,
        label: widgets?.find(
          (widget: Widget) => widget.id === (modal?.widget as string)
        )?.title as string,
      }

      return (
        <Modal
          title="Widget ayarları"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>
            Aşağıdan widget seçebilirsiniz ve seçtiğiniz widget ayarlarını
            güncelleyebilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Select
                name="type"
                label="Widget seçiniz"
                placeholder="Seçim yapınız"
                description="Sistem üzerinde oluşturulmuş widget'lar üzerinden seçip yapabilirsiniz."
                onChange={(options: SelectValueProps) => {
                  const reference = widgets?.find(
                    (item) => item.id === (options as SelectOptionProps).value
                  )

                  if (reference) {
                    setModal({
                      ...modal,
                      widget: reference.id,
                      title: reference.title,
                      description: reference.description,
                    })
                  }
                  setactiveWidget(reference)
                }}
                value={modal.widget ? widget : undefined}
                options={widgets.map((widget: Widget) => ({
                  label: widget.title as string,
                  value: widget.id as string,
                }))}
              />
            </Column>
            <Column>
              <Select
                name="type"
                label="Dashboard Seçiniz"
                placeholder="Seçim yapınız"
                description="Sistem üzerinde oluşturulmuş dashboard'lar üzerinden seçip yapabilirsiniz."
                onChange={(options: SelectValueProps) => {
                  const reference = dashboards?.find(
                    (item) => item.id === (options as SelectOptionProps)?.value
                  )
                  if (reference) {
                    setModal({
                      ...modal,
                      dashboardKey: reference.key,
                      dashboardId: reference.id,
                      dashboardTitle: reference.title,
                    })
                  } else {
                    setModal({
                      ...modal,
                      dashboardKey: '',
                      dashboardId: '',
                      dashboardTitle: '',
                    })
                  }
                }}
                value={{
                  label: modal.dashboardTitle,
                  value: modal.dashboardId,
                }}
                options={dashboards.map((dashboard: Dashboard) => ({
                  label: dashboard.title as string,
                  value: dashboard.id as string,
                }))}
                isClearable
              />
            </Column>
            <Column>
              <Switch
                name="type"
                label="Filtreler dashboard'dan yönetilsin mi?"
                isChecked={modal.type === 'dashboard' ? true : false}
                onChange={(event) =>
                  setModal({
                    ...modal,
                    type: event.target.checked ? 'dashboard' : 'local',
                  })
                }
                description="Widget'iniz üzerinde özel filtreler bulunmuyorsa, filtreleri dashboard üzerinden yönetebilirsiniz."
              />
            </Column>
            <Column>
              <Input
                name="title"
                label="Widget başlığı"
                value={modal.title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({ ...modal, title: event.target.value })
                }
              />
            </Column>
            <Column>
              <Textarea
                name="description"
                label="Widget açıklaması"
                value={modal.description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setModal({ ...modal, description: event.target.value })
                }
              />
            </Column>
            <Column>
              <Select
                name="grid"
                label="Widget verileri sütunlara bölünsün mü?"
                onChange={(options: SelectValueProps) => {
                  setModal({
                    ...modal,
                    grid: (options as SelectOptionProps).value as number,
                  })
                }}
                value={
                  WIDGET_GRID_TYPES.find((grid) => grid.value === modal.grid) ||
                  WIDGET_GRID_TYPES[0]
                }
                options={WIDGET_GRID_TYPES}
                position="fixed"
              />
            </Column>
          </Row>
          <br />
        </Modal>
      )
    }
  }

  const renderWidgetClone = () => {
    if (cloneModal) {
      const options = schemaNode?.getOptions()
      const actions = [
        {
          text: 'Kaydet ve klonla',
          isDisabled: !cloneModal.widget,
          isLoading: cloneLoading,
          onClick: () => {
            handleClone(
              {
                title: cloneModal.title || options?.title,
                description: cloneModal.description || options?.description,
                type: cloneModal.type || 'local',
              },
              { type: SchemaSourceTypes.Static, ref: cloneModal.widget }
            )
          },
        },
        {
          text: 'Kapat',
          onClick: () => setCloneModal(null),
        },
      ]
      const widget = {
        value: cloneModal?.widget as string,
        label: widgets?.find(
          (widget: Widget) => widget.id === (cloneModal?.widget as string)
        )?.title as string,
      }

      return (
        <Modal
          title="Widget klonla"
          actions={actions}
          onClose={() => setCloneModal(null)}
        >
          <p>
            Aşağıdan geçerli widget üzerinden yeni bir widget
            klonlayabilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Select
                name="type"
                label="Klonlanacak widget seçiniz"
                placeholder="Seçim yapınız"
                description="Sistem üzerinde oluşturulmuş widget'lar üzerinden seçip yapabilirsiniz."
                onChange={(options: SelectValueProps) => {
                  const reference = widgets?.find(
                    (item) => item.id === (options as SelectOptionProps).value
                  )

                  if (reference) {
                    setCloneModal({
                      ...cloneModal,
                      widget: reference.id,
                      title: reference.title,
                      description: reference.description,
                    })
                  }
                }}
                value={cloneModal.widget ? widget : undefined}
                options={widgets.map((widget: Widget) => ({
                  label: widget.title as string,
                  value: widget.id as string,
                }))}
              />
            </Column>
            <Column>
              <Switch
                name="type"
                label="Filtreler dashboard'dan yönetilsin mi?"
                isChecked={cloneModal.type === 'dashboard' ? true : false}
                onChange={(event) =>
                  setCloneModal({
                    ...cloneModal,
                    type: event.target.checked ? 'dashboard' : 'local',
                  })
                }
                description="Widget'iniz üzerinde özel filtreler bulunmuyorsa, filtreleri dashboard üzerinden yönetebilirsiniz."
              />
            </Column>
            <Column>
              <Input
                name="title"
                label="Klonlanacak widget başlığı"
                value={cloneModal.title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCloneModal({ ...cloneModal, title: event.target.value })
                }
              />
            </Column>
            <Column>
              <Textarea
                name="description"
                label="Klonlanacak widget açıklaması"
                value={cloneModal.description}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setCloneModal({
                    ...cloneModal,
                    description: event.target.value,
                  })
                }
              />
            </Column>
          </Row>
          <br />
        </Modal>
      )
    }
  }

  const renderDesigner = () => {
    const widget = schemaNode?.getSource()?.ref as string

    return (
      <Styled.WidgetComponentEmpty>
        <EmptyState
          title={title || 'Widget (Veri Görselleştirme)'}
          description="Verilerinizi görselleştirmek için oluşturduğunuz bir widgetı seçin ya da yeni oluşturun."
          image={{ src: widget ? WidgetHeroid : NotFoundImage }}
          actions={
            widget ? (
              <ButtonGroup>
                <Button
                  iconBefore={<Icon name="preferences" size="small" />}
                  onClick={() => {
                    getWidgets(widget)
                    getDashboards()
                    setModal({
                      title,
                      description,
                      widget,
                      type,
                      grid,
                      dashboardKey,
                      dashboardId,
                      dashboardTitle,
                    })
                  }}
                >
                  Widget ayarları
                </Button>
                <Button
                  appearance="primary"
                  iconBefore={<Icon name="edit" size="small" />}
                  onClick={handleWidgetDrawerVisibility}
                >
                  Hızlı düzenle
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Button
                  iconBefore={<Icon name="preferences" size="small" />}
                  onClick={() => {
                    getWidgets()
                    getDashboards()
                    setModal({ title, description, widget, type })
                  }}
                >
                  Widget seç
                </Button>
                <Button
                  appearance="primary"
                  iconBefore={<Icon name="add" size="small" />}
                  onClick={handleWidgetDrawerVisibility}
                >
                  Hızlı oluştur
                </Button>
              </ButtonGroup>
            )
          }
        />
      </Styled.WidgetComponentEmpty>
    )
  }

  return (
    <React.Fragment>
      <div
        data-schema-id={id}
        className={['dnd-widget', designing && 'fake-draggable', className]
          .filter((e) => !!e)
          .join(' ')}
      >
        {designing ? (
          <React.Fragment>
            {renderWidgetSettings()}
            {renderWidgetClone()}
            <ComponentHeader
              componentEntry={entry}
              componentSchemaId={id as string}
              componentType={
                schemaNode?.getSource()?.ref ? schemaNode?.getType() : undefined
              }
              onClone={() => {
                getWidgets()
                setCloneModal({
                  title: `${title} Kopya`,
                  description,
                  widget: schemaNode?.getSource()?.ref as string,
                  type,
                })
              }}
            />
            {renderDesigner()}
          </React.Fragment>
        ) : (
          renderWidgetWrapper()
        )}
      </div>
      <FilterSettings
        show={filterDrawerVisibility}
        onDismiss={handleFilterDrawerVisibility}
        onSave={(query) =>
          handleChangeProperties({
            filter: query,
          })
        }
        values={filter || (schemaNode?.getOptions()?.query as Query)}
        fields={fields}
        title={`${
          title || 'Widget (Veri Görselleştirme)'
        } - Filtre ve Hesaplamalar`}
        type={schemaNode?.getOptions().type || 'json'}
      />
      {widgetDrawerVisibility && (
        <WidgetDrawer
          id={schemaNode?.getSource()?.ref as string}
          isOpen={widgetDrawerVisibility}
          onDismiss={handleWidgetDrawerVisibility}
          onSave={handleWidgetSaveChanges}
          operation={schemaNode?.getSource()?.ref ? 'update' : 'create'}
        />
      )}
    </React.Fragment>
  )
}

export default WidgetComponent
