import React from 'react'
import { InstagramCardProps } from './types'
import defaultUserProfile from './images/default-user.png'
import logo from './images/instagram-logo.png'
import logoWhite from './images/logo-white.png'
import * as Styled from './InstagramCard.styled'
import verifiedIcon from './images/verified.svg'

const InstagramCard: React.FC<InstagramCardProps> = ({
  data,
  editmode,
  onChange,
}) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  const timeAgo = (input: Date | string | number) => {
    const date = input instanceof Date ? input : new Date(input)
    const formatter = new Intl.RelativeTimeFormat('tr')
    const ranges = [
      ['years', 3600 * 24 * 365],
      ['months', 3600 * 24 * 30],
      ['weeks', 3600 * 24 * 7],
      ['days', 3600 * 24],
      ['hours', 3600],
      ['minutes', 60],
      ['seconds', 1],
    ] as const
    const secondsElapsed = (date.getTime() - Date.now()) / 1000

    for (const [rangeType, rangeVal] of ranges) {
      if (rangeVal < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / rangeVal
        return formatter.format(Math.round(delta), rangeType)
      }
    }
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', logo)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfile)
  }

  const textLength = data?.Text && data?.Text?.length >= 400

  return (
    <Styled.InstagramCardWrapper>
      <div className="post">
        <div className="post-header">
          <div className="post-header-profile">
            <div className="post-header-profile-image">
              <img
                src={data?.UserProfilePictureUrl || defaultUserProfile}
                onError={hasErrorImageProfil}
                alt="Instagram Profile"
              />
            </div>
            <div className="post-header-profile-info">
              <div className="post-header-profile-info-link">
                <a href={`https://instagram.com/${data?.UserName}`}>
                  @{data?.UserName}
                </a>
                {data?.IsUserVerified && (
                  <img width={16} height={16} src={verifiedIcon} alt="" />
                )}
              </div>
              <div className="post-header-profile-info-name">
                <span>{data?.UserFullName}</span>
              </div>
            </div>
          </div>
          <div className="post-header-profile-data">
            <span>{data?.UserFollowersCount}</span>
            <span>Takipçi</span>
          </div>
        </div>
        <div className="post-body">
          <div className="post-body-image">
            {data?.MediaUrl ? (
              <img
                className="post-body-image"
                src={data?.MediaUrl}
                onError={hasErrorImage}
                alt="Instagram Post"
              />
            ) : (
              <img
                className="post-body-image-"
                src={logo}
                onError={hasErrorImage}
                alt="Instagram Post"
              />
            )}
          </div>

          <div className="post-body-content">
            <div className="post-body-content-text">
              <span className="post-body-content-text-user">
                {data?.UserName}{' '}
              </span>

              <span
                contentEditable={editmode}
                onBlur={(e) => handleBlurElement(e, 'Text')}
              >
                {textLength && !isOpen && !editmode
                  ? data?.Text?.slice(0, 400) + '...'
                  : data?.Text}
              </span>

              {textLength && !editmode && (
                <button onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? 'Daha Az' : 'Daha Fazla'}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="post-footer">
          <div className="post-footer-logo">
            <div className="post-footer-logo-warpper">
              <img src={logoWhite} alt="Instagram Logo" />
            </div>
          </div>
          <div className="post-footer-info">
            <div className="post-footer-info-like">
              <span>{data?.LikeCount} Beğeni</span>
            </div>
            <div className="post-footer-info-comment">
              <span>{data?.CommentCount} Yorum</span>
            </div>
          </div>
          <div className="post-footer-url gradient">
            <a href={data?.Url} target="_blank" rel="noreferrer">
              Post'u görüntüle
            </a>
          </div>
        </div>
      </div>
    </Styled.InstagramCardWrapper>
  )
}

export default InstagramCard
