export const REDDIT_FIELDS = [
  {
    label: 'ID',
    value: 'postFullId',
  },
  {
    label: 'Skor',
    value: 'score',
  },
  {
    label: 'Başlık',
    value: 'title',
  },
  {
    label: 'Profil Görseli',
    value: 'userIconImg',
  },
  {
    label: 'Kullanıcı Adı',
    value: 'userName',
  },
  {
    label: 'Bağlantı Adresi',
    value: 'permaLink',
  },
  {
    label: 'Oluşturma tarihi',
    value: 'createdAt',
  },
]
