import styled from 'styled-components'

export const RelationChart = styled.div`
  width: 100%;
  margin: 0.8rem 0;

  button {
    margin: 0;
    width: 100%;
  }
`

export const RelationChartList = styled.div`
  max-height: calc(50vh);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 20px;
  }
`
