import { Column, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import DateTimePicker from '@zera-admin/datetime-picker'
import Form from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select from '@zera-admin/select'
import { DateTimePickerHours } from 'app/shared/enums'

export const datasets = [
  { label: 'Bot Veri Setleri', value: 1 },
  { label: 'Cinsiyet Veri Setleri', value: 2 },
  { label: 'Duygu Veri Setleri', value: 3 },
  { label: 'İsimlendirme Veri Setleri', value: 4 },
  { label: 'Sınıflandırma Veri Setleri', value: 5 },
]

export const renderFilter = (form: any) => {
  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <Row>
        <Column xs={{ size: 4 }}>
          <Select
            label="Veri seti türü seçiniz"
            name="dataset"
            placeholder="Seçim yapın"
            options={datasets}
          />
        </Column>
        <Column xs={{ size: 8 }}>
          <Column xs={{ size: 12 }}>
            <Button
              style={{ marginLeft: 'auto' }}
              appearance="warning"
              children="Eğitime başlamak için kalan : 150"
            />
          </Column>
          <Column xs={{ size: 12 }}>
            <Button
              appearance="primary"
              children="Daha sonra kümeyi büyütmek üzere kapat"
            />
          </Column>
        </Column>
        <Column xs={{ size: 2 }}>
          <Input
            onChange={form.handleChange}
            name="UserName"
            placeholder="Kullanıcı"
            label="Kullanıcı"
            description="Twitter kullanıcı adı giriniz"
          />
        </Column>
        <Column xs={{ size: 1 }}>
          <Input
            description="Min Rt sayısı giriniz"
            onChange={form.handleChange}
            name="RetweetCount.Min"
            placeholder="1"
            label="Min Rt sayısı"
          />
        </Column>
        <Column xs={{ size: 1 }}>
          <Input
            description="Max Rt sayısı giriniz"
            onChange={form.handleChange}
            name="RetweetCount.Max"
            placeholder="100"
            label="Max Rt sayısı"
          />
        </Column>
        <Column xs={{ size: 1 }}>
          <Input
            description="Min Beğeni sayısı giriniz"
            onChange={form.handleChange}
            name="LikeCount.Min"
            placeholder="1"
            label="Min Like sayısı"
          />
        </Column>
        <Column xs={{ size: 1 }}>
          <Input
            description="Max Beğeni sayısı giriniz"
            onChange={form.handleChange}
            name="LikeCount.Max"
            placeholder="100"
            label="Max Like sayısı"
          />
        </Column>
        <Column xs={{ size: 2 }}>
          <DateTimePicker
            description="Başlangıç tarihi seçiniz"
            label="Başlangıç tarihi"
            locale="tr"
            name="StartDate"
            type="default"
            onChange={(props) =>
              form.handleFieldChange('StartDate', new Date(props).toISOString())
            }
            times={hoursArray}
          />
        </Column>
        <Column xs={{ size: 2 }}>
          <DateTimePicker
            description="Bitiş tarihi seçiniz"
            label="Bitiş tarihi"
            locale="tr"
            name="EndDate"
            type="default"
            onChange={(props) =>
              form.handleFieldChange('EndDate', new Date(props).toISOString())
            }
            times={hoursArray}
          />
        </Column>
        <Column xs={{ size: 2, align: 'center' }}>
          <Button appearance="primary" children="Filtrele" type="submit" />
        </Column>
      </Row>
    </Form>
  )
}
