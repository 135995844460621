import { useState } from 'react'

import Accordion from '@zera-admin/accordion'

import { AgendaReportWidgetsProps, Page } from './types'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import Spinner from '@zera-admin/spinner'

import Intro from '../pages/intro'
import MostSharedWebSiteTable from '../pages/mostSharedWebSiteList/MostSharedWebSiteList'
import ExecutiveSummary from '../pages/executiveSummary/ExecutiveSummary'
import SocialCounts from '../pages/socialCounts/SocialCounts'
import TwitterCountryDistribution from '../pages/twitterCountryDistribution/TwitterCountryDistribution'
import { IAnalysisData } from '../pages/executiveSummary/types'
import NewsCountryDistribution from '../pages/newsCountryDistribution/NewsCountryDistribution'
import WordCountPageByCountry from '../pages/wordCountPageByCountry/WordCountPageByCountry'
import TimeSeries from '../pages/timeSeries/TimeSeries'
import MostSharedCountriesTable from '../pages/mostSharedCountries/MostSharedCountries'
import HighestFollowedAccounts from '../pages/highestFollowedAccounts/HighestFollowedAccounts'
import InfluentialAccountPagesByCountry from '../pages/influentialAccountPagesByCountry/InfluentialAccountPagesByCountry'
import YoutubePosts from '../pages/youtubePosts/YoutubePosts'
import TiktokPosts from '../pages/tiktokPosts/TiktokPosts'
import NewsPagesByCountry from '../pages/newsPagesByCountry/NewsPagesByCountry'
import TweetsPagesByCountry from '../pages/tweetsPagesByCountry/TweetsPagesByCountry'
import InfluentialAccountTweetPagesByCountry from '../pages/influentialAccountTweetPagesByCountry/InfluentialAccountTweetPagesByCountry'
import GraphAnalysis from '../pages/graphAnalysis/GraphAnalysis'
import FCUsersInfluencingSpread from '../pages/usersInfluencingSpread/UsersInfluencingSpread'
import InstagramPosts from '../pages/instagramPosts/InstagramPosts'
import RedditPosts from '../pages/redditPosts/RedditPosts'
import TelegramPosts from '../pages/telegramPosts/TelegramPosts'
import EksiPost from '../pages/eksiPosts/EksiPosts'
import QuoraPosts from '../pages/quoraPosts/QuoraPosts'
import CountryReflections from '../pages/countryReflections/CountryReflections'
import MastodonPosts from '../pages/mastodonPosts/MastodonPosts'

const Widgets: React.FunctionComponent<AgendaReportWidgetsProps> = ({
  data,
  startDate,
  endDate,
  isLoading,
}) => {
  const Dteparse = (date: string) => {
    const dates = new Date(date)
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }
    return dates.toLocaleDateString('tr-TR', options)
  }
  const resultBaseData = data?.data || {}
  const result = data?.data?.pages || []

  const [isEditValue, setIsEditValue] = useState(-1)

  const renderPage = (page: Page) => {
    switch (page.pageType) {
      case 'firstPage':
        return (
          <div className="printed">
            <Intro
              title={page?.title}
              date={{
                startDate,
                endDate,
              }}
            />
          </div>
        )
      case 'executiveSummary':
        return (
          <div className="printed">
            <ExecutiveSummary
              data={page?.data as IAnalysisData}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              title={page?.title}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )
      case 'numbers':
        return (
          <div className="printed">
            <SocialCounts
              data={page?.data?.Counts}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              title={page?.title}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'mostSharedWebSites':
        return (
          <div className="printed">
            <MostSharedWebSiteTable
              data={page?.data?.MostSharedWebSiteList}
              setIsEditValue={setIsEditValue}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              title={page?.title}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )
      case 'mostSharedCountries':
        return (
          <div className="printed">
            <MostSharedCountriesTable
              data={page?.data?.MostSharedCountriesList}
              setIsEditValue={setIsEditValue}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              title={page?.title}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'twitterCountryDistribution':
        return (
          <div className="printed">
            <TwitterCountryDistribution
              data={page?.data?.CountryDistribution}
              title={page?.title}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'newsCountryDistribution':
        return (
          <div className="printed">
            <NewsCountryDistribution
              data={page?.data?.CountryDistribution}
              title={page?.title}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'wordCountPageByCountry':
        return (
          <div className="printed">
            <WordCountPageByCountry
              data={page?.data?.WordCounts}
              title={page?.title}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'highestFollowedAccounts':
        return (
          <div className="printed">
            <HighestFollowedAccounts
              data={page?.data?.Accounts}
              title={page?.title}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )
      case 'timeSeries':
        return (
          <div className="printed">
            <TimeSeries
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.TimeSeries || []}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'influentialAccountPagesByCountry':
        return (
          <div className="printed">
            <InfluentialAccountPagesByCountry
              data={page?.data?.Accounts}
              title={page?.title}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
              setIsEditValue={setIsEditValue}
            />
          </div>
        )
      case 'youtubePosts':
        return (
          <div className="printed">
            <YoutubePosts
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Post}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )
      case 'tikTokPosts':
        return (
          <div className="printed">
            <TiktokPosts
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              setIsEditValue={setIsEditValue}
              pageNumber={page?.pageNumber}
              title={page?.title}
              data={page?.data?.Post}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )
      case 'influentialAccountTweetPagesByCountry':
        return (
          <div className="printed">
            <InfluentialAccountTweetPagesByCountry
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Tweets}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'newsPagesByCountry':
        return (
          <div className="printed">
            <NewsPagesByCountry
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              setIsEditValue={setIsEditValue}
              pageNumber={page?.pageNumber}
              title={page?.title}
              data={page?.data?.News}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'tweetsPagesByCountry':
        return (
          <div className="printed">
            <TweetsPagesByCountry
              data={page?.data?.Tweet}
              title={page?.title}
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'UsersInfluencingSpread':
        return (
          <div className="printed">
            <FCUsersInfluencingSpread
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Groups}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
                Text: page?.data?.Text,
              }}
            />
          </div>
        )
      case 'GraphAnalysis':
        return (
          <div className="printed">
            <GraphAnalysis
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={(page?.data as string) || ''}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'InstagramPosts':
        return (
          <div className="printed">
            <InstagramPosts
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Post}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )
      case 'RedditPosts':
        return (
          <div className="printed">
            <RedditPosts
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Post}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'TelegramPosts':
        return (
          <div className="printed">
            <TelegramPosts
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Post}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'EksiSozlukPosts':
        return (
          <div className="printed">
            <EksiPost
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Post}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'QuoraPosts':
        return (
          <div className="printed">
            <QuoraPosts
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Post}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'CountryReflections':
        return (
          <div className="printed">
            <CountryReflections
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Regions || []}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      case 'MastodonPosts':
        return (
          <div className="printed">
            <MastodonPosts
              date={`${Dteparse(startDate)} - ${Dteparse(endDate)}`}
              data={page?.data?.Post}
              title={page?.title}
              isEditValue={isEditValue}
              pageNumber={page?.pageNumber}
              setIsEditValue={setIsEditValue}
              metaData={{
                GroupId: resultBaseData.groupId,
                PageId: page?.pageId,
                ReportType: resultBaseData?.reportType,
                PageType: page?.pageType,
                pageNumber: page?.pageNumber,
                flagShortCode: page?.data?.Country,
              }}
            />
          </div>
        )

      default:
        return null
    }
  }

  return (
    (isLoading && (
      <LoadingView>
        <Spinner size="large" />
        <h5>Rapor yükleniyor...</h5>
      </LoadingView>
    )) || (
      <Accordion
        data={result?.map((page) => ({
          name: page?.title,
          title: page?.title,
          defaultExpanded: true,
          children: renderPage(page),
        }))}
        isToggle
      />
    )
  )
}

export default Widgets
