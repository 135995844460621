import React, { useState } from 'react'
import * as yup from 'yup'
import { Column, Row } from '@zera-admin/page'
import Form, { useForm } from '@zera-admin/form'
import Modal from '@zera-admin/modal'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { validation } from 'app/functions'
import useClassificationCategory from 'app/hooks/classification-category'
import { languageCode } from 'app/shared/constants'
import classificationDataSetsService, {
  CreateClassificationDataSetRequest,
} from 'services/http/dataset/classification-data-set'

import { ClassificationDataSetProps } from '../types'

const validations = {
  languageCode: yup.string().required('Dil kodu girmelisiniz'),
  classificationCategoryId: yup
    .string()
    .required('Sınıflandırma kategorisi seçmelisiniz'),
}

const ClassificationDataSet: React.FunctionComponent<ClassificationDataSetProps> =
  ({ onClose, values }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [flagcard] = useFlagcardContext()

    const form = useForm<CreateClassificationDataSetRequest>({
      initialValues: values,
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })

    const {
      classificationCategories,
      getClassificationCategory,
      isLoading: isLoadingClassificationCategory,
    } = useClassificationCategory()

    const handleSubmit = () => {
      setIsLoading(true)
      classificationDataSetsService
        .create(form.values)
        .then(() => {
          flagcard.add({
            appearance: 'success',
            title: 'Kayıt işlemi başarılı',
            children:
              'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
          })
          onClose?.()
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    return (
      <Modal
        actions={[
          {
            appearance: 'primary',
            text: 'Kaydet',
            onClick: () => handleSubmit(),
            isLoading: isLoading,
          },
          {
            appearance: 'subtle',
            onClick: onClose,
            text: 'Vazgeç',
          },
        ]}
        width="medium"
        onClose={onClose}
        title="Sınıflandırma veri seti eğitme"
      >
        <Form onSubmit={handleSubmit}>
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Select
                errorMessage={form.errors.languageCode}
                label="Dil kodu"
                name="languageCode"
                value={languageCode.find(
                  (item) => item.value === form.values.languageCode
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'languageCode',
                    (props as SelectOptionProps).value
                  )
                }
                options={languageCode}
                position="fixed"
              />
            </Column>
            <Column>
              <Select
                errorMessage={form.errors.classificationCategoryId?.toString()}
                placeholder="Kategori"
                label="Kategori seçiniz"
                name="classificationCategoryId"
                value={getClassificationCategory(
                  form.values.classificationCategoryId,
                  form.values.classificationCategoryName
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'classificationCategoryId',
                    (props as SelectOptionProps).value
                  )
                }
                options={classificationCategories}
                isLoading={isLoadingClassificationCategory}
                position="fixed"
              />
            </Column>
          </Row>
        </Form>
      </Modal>
    )
  }

export default ClassificationDataSet
