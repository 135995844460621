import React, { useState } from 'react'
import * as Styled from './GAnalysis.styled'
import { GraphAnalysisProps } from './types'
import WidgetLayout from 'pages/agenda-report-v4/detail/widgets/widget-layout'

const GraphAnalysis: React.FC<GraphAnalysisProps> = ({
  date,
  data,
  title,
  pageNumber,
  metaData,
}) => {
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
    text: string
  }>({ title: title, pageNumber: pageNumber, text: metaData?.Text || '' })

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={false}
      flagShortCode={metaData?.flagShortCode}
    >
      {data ? (
        <Styled.GraphAnalysisContainer>
          <iframe
            src={`https://network-graph-ui.alice.glomil.com/?jsonUrl=${data}`}
            width={'100%'}
            title={pageData.title}
            height={700}
            frameBorder="0"
            style={{
              border: 'none',
            }}
          ></iframe>
        </Styled.GraphAnalysisContainer>
      ) : (
        <h3>Veri Bulunamadi</h3>
      )}
    </WidgetLayout>
  )
}

export default GraphAnalysis
