import { Source, SourceResponse } from './types'

export const mapSourceForRequest = (raw: Source): unknown => ({
  name: raw.name,
  source_id: raw.sourceId,
})

export const mapSourceForResponse = (raw: any): Source => ({
  id: raw.data.id,
  name: raw.data.name,
  sourceId: raw.data.source_id,
})

export const mapSourceForList = (raw: any): SourceResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapSourceForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
