import styled from 'styled-components'

export const FormContainer = styled.div`
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  .form-group {
    margin-bottom: 20px;
    label {
      display: block;
      margin-bottom: 5px;
    }
    input {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #e5e5e5;
    }
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      background-color: #007bff;
      color: #fff;
      cursor: pointer;
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .data {
    .tweets {
      display: flex;
      gap: 0.5rem;
    }

    .post {
      position: relative;
      width: 6rem;
      height: 8rem;
      background-color: #e5e5e5;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      position: relative;
    }

    .news {
      position: relative;
      width: 6rem;
      height: 8rem;
      background-color: #e5e5e5;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      position: relative;
    }
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      a {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tweet {
      background-color: #e5e5e5;
      display: flex;
      width: 6rem;
      height: 8rem;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      padding: 10px;
      border-radius: 5px;
      position: relative;
    }

    .close {
      background-color: #bf0a13;
      padding: 2px 5px;
      border-radius: 5px;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      color: #fff;
      border: 0;
    }
    .error {
      background-color: #f8d7da;
      padding: 2px 5px;
      border-radius: 5px;
    }
  }
`
