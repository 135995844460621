import React from 'react'
import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'

import { validation } from 'app/functions'
import { ManualAlarm } from 'services/http/backoffice/manual-alarm'

import { ManualAlarmFormProps } from './types'
import Textarea from '@zera-admin/textarea'

const validations = {
  alarmName: yup.string().required('Tip seçmelisiniz'),
  message: yup.string().required('Değer girmelisiniz'),
}

const ManualAlarmForm: React.FunctionComponent<ManualAlarmFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const form = useForm<ManualAlarm>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),

    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = (props: FormHookSubmitProps<ManualAlarm>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection>
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Textarea
              description="Geçerli bir mesaj metni giriniz"
              errorMessage={form.errors.message}
              label="Mesaj"
              name="message"
              placeholder="Örnek mesaj"
              value={form.values.message}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Input
              description="Geçerli bir isim giriniz"
              errorMessage={form.errors.alarmName}
              label="Alarm İsmi"
              name="alarmName"
              placeholder="Örnek isim"
              value={form.values.alarmName}
              onChange={form.handleChange}
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default ManualAlarmForm
