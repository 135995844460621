import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Lozenge from '@zera-admin/lozenge'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import { BotConclusionEnum } from 'services/http/dataset'
import botControlSetsService, {
  BotControlSet,
  BotControlSetResponse,
} from 'services/http/dataset/bot-control-set'

import { BotControlSetListProps } from './types'

const BotControlSetList: React.FunctionComponent<
  BotControlSetListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [botControlSet, setBotControlSet] = useState<BotControlSetResponse>()

  useEffect(() => {
    getBotControlSets()
  }, [])

  const getBotControlSets = async () => {
    try {
      const response = await botControlSetsService.get()

      setBotControlSet(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/bot-control-set/create">
        Bot kontrol seti oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Bot kontrol setleri" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm bot kontrol setlerini görüntüleyebilir, yeni oluşturabilir,
        varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Bot kontrol setleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'text',
        text: 'Bot kontrol seti metni',
      },
      {
        name: 'languageCode',
        text: 'Dil kodu',
      },
      {
        name: 'conclusion',
        text: 'Bot durumu',
        render: (props: BotControlSet) => {
          if (props.conclusion === BotConclusionEnum.Bot) {
            return <Lozenge appearance="error">Bot</Lozenge>
          }

          return <Lozenge appearance="success">Bot Değil</Lozenge>
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: BotControlSet) => (
          <LinkButton
            appearance="subtle-link"
            href={`/bot-control-set/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (botControlSet) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={botControlSet.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={botControlSet.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default BotControlSetList
