import styled from 'styled-components'

import { ReportTitleProps } from './types'

export const ReportTitle = styled.div<{ mainBackgroundColor?: string }>`
  background-color: ${(props) =>
    props.mainBackgroundColor ? props.mainBackgroundColor : '#fff'};
`

export const Title = styled.div<Omit<ReportTitleProps, 'title' | 'children'>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ebebeb'};
  height: 50px;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  height: ${(props) => (props.height ? props.height : '50px')};
  background-image: ${(props) =>
    props.linearGradient
      ? 'linear-gradient(to right, #dfc8f5,#fafafa)'
      : 'none'};
  & > div {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : 'f0cc73b'};
    width: 15px;
    height: 100%;
  }

  p {
    margin: 0;
    margin-left: 15px;
    font-weight: bold;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
    color: ${(props) => (props.color ? props.color : '#373737')};
  }
`

export const Children = styled.div`
  padding: 10px 30px;
`
