import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'

import { NameValueVisualizationOptions } from '../types'

export const generateNameValueOptions = (
  options: NameValueVisualizationOptions,
  query: Query,
  fields: Field[]
): NameValueVisualizationOptions => {
  const result = options

  const aggregation = query.aggregations?.length
    ? query.aggregations[query.aggregations.length - 1]
    : null
  const group = query.groups?.length
    ? query.groups[query.groups.length - 1]
    : null

  if (aggregation) {
    result.value = aggregation.field as string
  }

  if (group) {
    result.label = group.field
  }

  return result
}
