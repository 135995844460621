import { colors } from '@atlaskit/theme'

export const getCategory = (category: string) => {
  switch (category) {
    case 'ARTS':
      return '#ff024e'

    case 'BUSINESS & FINANCE':
      return '#013fb5'

    case 'EDUCATION':
      return '#ffba00'

    case 'FOOD':
      return '#bf00a2'

    case 'HEALTH':
      return '#12adcc'

    case 'HISTORY':
      return '#23ffba'

    case 'LAW & HUMAN RIGHTS':
      return '#ff99e6'

    case 'MILITARY':
      return '#ccff1a'

    case 'NATURE & GEOGRAPHY':
      return '#66994d'

    case 'POLITICS':
      return '#33ffcc'

    case 'RELIGION':
      return colors.T100

    case 'SCIENCE & TECH':
      return '#b366cc'

    case 'SPORTS':
      return '#4d8000'

    case 'TERROR':
      return '#b33300'

    case 'TOURISM':
      return '#00bfca'

    default:
      return colors.DN50
  }
}

export const getCategoryName = (category: string) => {
  switch (category) {
    case 'ARTS':
      return 'Sanat'

    case 'BUSINESS & FINANCE':
      return 'İş ve Finans'

    case 'EDUCATION':
      return 'Eğitim'

    case 'FOOD':
      return 'Yemek'

    case 'HEALTH':
      return 'Sağlık'

    case 'HISTORY':
      return 'Tarih'

    case 'LAW & HUMAN RIGHTS':
      return 'Hukuk ve İnsan Hakları'

    case 'MILITARY':
      return 'Askeri'

    case 'NATURE & GEOGRAPHY':
      return 'Doğa ve Coğrafya'

    case 'POLITICS':
      return 'Politik'

    case 'RELIGION':
      return 'Din'

    case 'SCIENCE & TECH':
      return 'Bilim ve Teknoloji'

    case 'SPORTS':
      return 'Spor'

    case 'TERROR':
      return 'Terör'

    case 'MEDIA':
      return 'Medya'

    case 'TOURISM':
      return 'Turizm'

    default:
      return 'Sınıflandırma'
  }
}
