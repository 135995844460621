import React, { useState } from 'react'
import { TwitterCard, TwitterCardTop } from './TwitterNewsCard.style'
import Button, { ButtonGroup } from '@zera-admin/button'
import { Column, Row } from '@zera-admin/page'
import Icon from '@zera-admin/icon'

import { TwitterNewsCardProps } from './types'
import Select from '@zera-admin/select'

const TwitterNewsCard: React.FunctionComponent<TwitterNewsCardProps> = ({
  data,
  addListNews,
  removeListNews,
  isSelectedNews,
}) => {
  const [requestObj, setRequestObj] = useState<any>({
    id: data.id,
    sentiment: data.sentiment,
    sentimentScore: data.sentimentScore,
    channelName: '',
  })
  return (
    <React.Fragment>
      <TwitterCard className="test">
        <TwitterCardTop>
          <ButtonGroup>
            {!isSelectedNews ? (
              <Button
                isDisabled={requestObj.sentiment === ''}
                appearance="subtle"
                iconBefore={<Icon name="add" size="medium" />}
                onClick={() => {
                  addListNews(requestObj)
                }}
              />
            ) : (
              <Button
                appearance="subtle"
                iconBefore={<Icon name="check-circle" size="medium" />}
                onClick={() => {
                  removeListNews(requestObj)
                }}
              />
            )}
          </ButtonGroup>
        </TwitterCardTop>
        <Row xs={{ direction: 'column' }}>
          <Column
            xs={{ align: 'start' }}
            style={{ padding: '4px' }}
            onClick={() => window.open(`${data.url}`)}
          >
            <span>Haber Sitesi : </span>
            {data.newsSourceName}
          </Column>
          <Column
            xs={{ align: 'start' }}
            style={{ padding: '4px' }}
            onClick={() => window.open(`${data.url}`)}
          >
            <span>Başlık: </span>
            {data.title}
          </Column>
          <Column
            xs={{ align: 'start' }}
            style={{ padding: '4px' }}
            onClick={() => window.open(`${data.url}`)}
          >
            <span>Haber : </span>
            {data.description}
          </Column>
          <Column xs={{ align: 'start' }} style={{ padding: '4px' }}>
            <Column
              xs={{ align: 'start' }}
              style={{ padding: '4px' }}
              onClick={() => window.open(`${data.url}`)}
            >
              <span>Sentiment: </span>
              {data.sentiment}
            </Column>
          </Column>
          <Column xs={{ align: 'start' }} style={{ padding: '4px' }}>
            <Select
              isDisabled={isSelectedNews}
              onChange={(e: any) =>
                setRequestObj({ ...requestObj, sentiment: e.value })
              }
              value={{
                value: requestObj.sentiment,
                label: requestObj.sentiment,
              }}
              label="Sentiment Seçiniz"
              name="sentiment"
              options={[
                { label: 'Pozitif', value: 'positive' },
                { label: 'Negatif', value: 'negative' },
                { label: 'Nötr', value: 'neutral' },
              ]}
              placeholder="Seçim yapın"
              isMultiple={false}
            />
          </Column>
        </Row>
      </TwitterCard>
    </React.Fragment>
  )
}

export default TwitterNewsCard
