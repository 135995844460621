import Icon from '@zera-admin/icon'
import { Column } from '@zera-admin/page'
import PdfPageLayout from './PdfPageLayout'
import { PdfPageProps } from './types'
import { getMonthAndYear } from 'app/functions'
const PageTen: React.FunctionComponent<PdfPageProps> = ({ personReport }) => {
  return (
    <PdfPageLayout personReport={personReport}>
      <div style={{ width: '80vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Wikipedia
            </h2>
            {personReport.data?.result?.Statistic?.wikipedia?.Data?.data
              ?.sort((a: any, b: any) =>
                a[Object.keys(a)[0]].split('').length >
                b[Object.keys(b)[0]].split('').length
                  ? 1
                  : -1
              )
              ?.map((wikiItem: any) => (
                <div
                  className="wiki-item"
                  style={{
                    float: 'left',
                    padding: '5px',
                    backgroundColor: 'rgb(228 228 228)',
                    marginRight: '5px',
                    marginBottom: '5px',
                  }}
                >
                  <span style={{ float: 'left', fontWeight: 'bold' }}>
                    {Object.keys(wikiItem)[0].charAt(0).toUpperCase() +
                      Object.keys(wikiItem)[0]
                        .slice(1)
                        .toLowerCase()
                        .replaceAll('_', ' ')}
                  </span>
                  <label style={{ float: 'left', clear: 'both' }}>
                    {wikiItem[Object.keys(wikiItem)[0]]}
                  </label>
                </div>
              ))}
            {/* {personReport?.data?.result?.Statistic?.classifications?.categoriesByTime?.map(
                (classification: any) =>
                  classification.classifications.length > 0 ? (
                    <CategoriesByTime data={classification} />
                  ) : null
              )} */}
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
    </PdfPageLayout>
  )
}
export default PageTen
