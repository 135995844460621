import styled from 'styled-components'

export const AgendaTweetCard = styled.div<{ multiple: boolean }>`
  width: 100%;

  .agenda-tweet {
    &-header {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 1.2rem;
      position: relative;
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 20px 20px 0px 0px;
      padding: 1.2rem 0 0.8rem 1.2rem;
      z-index: -2;

      &-profile-image {
        position: relative;
        img {
          width: 50px;
          border-radius: 50%;
        }
        &::after {
          content: '';
          position: absolute;
          width: 62px;
          height: 62px;
          left: calc(((62px - 50px) / 2) * -1);
          top: calc(((62px - 50px) / 2) * -1);
          background: #ffffff 0 0 no-repeat padding-box;
          border-radius: 50%;
          z-index: -1;
        }
      }

      &-profile-des {
        &-name {
          display: block;
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          color: #ffffff;
        }
        &-link {
          display: block;
          font-size: 16px;
          font-weight: normal;
          line-height: 20px;
          color: #707b93;
        }
      }
    }

    &-body {
      padding: 1.4rem 0.5rem;
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #e8e8e8;
      /* overflow: hidden; */
      font: normal normal normal 18px/23px Euclid Circular A;
      ${({ multiple }) => multiple && 'border-top: none;'};

      &-post-image {
        ${({ multiple }) => (multiple ? 'min-width: 238px;' : ' width: 98%;')}
        @media screen and (max-width: 1660px) {
          ${({ multiple }) => multiple === true && 'min-width: 150px;'}
        }
        ${({ multiple }) => multiple === false && 'overflow: hidden;'}
        border-radius: 20px;
        img {
          width: 100%;
          height: 100%;
          max-height: 194px;
          object-fit: cover;
          object-position: center;
        }
      }

      &-content {
        height: 100%;
        @media screen and (max-width: 1660px) {
          ${({ multiple }) => multiple === true && 'height: 100%; '}
          ${({ multiple }) => multiple === true && 'display: flex;'}
          ${({ multiple }) => multiple === true && 'flex-direction: column;'}
          ${({ multiple }) =>
            multiple === true && 'justify-content: space-evenly;'}
        }
      }

      &-post-text {
        margin: 0;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 9;
        box-orient: vertical;
        -webkit-line-clamp: 9;
        -webkit-box-orient: vertical;
      }

      &-post-infos {
        color: #707b93;
        margin-top: 1rem;
        span:not(:first-child) {
          margin-left: 8px;
          &::before {
            content: '|';
            color: #707b93;
            margin-right: 8px;
          }
          strong {
            color: #000000;
          }
        }
      }

      &.multiple-body {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        min-height: 200px;
        max-height: 200px;
        align-items: center;
        @media screen and (max-width: 1660px) {
          gap: 0.5rem;
        }
      }
    }

    &-footer {
      position: relative;
      &-statistics {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 0.6rem 0;
        /* ${({ multiple }) =>
          multiple
            ? 'justify-content: flex-end; padding-right: 1.6rem;'
            : ' justify-content: center;'} */
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background: #f4f4f4 0 0 no-repeat padding-box;
        z-index: -1111;
        padding-left: 10%;

        span {
          font: normal normal 16px/20px Euclid Circular A;
          font-weight: 600;
          display: flex;

          p {
            margin-right: 0.4rem;
            font-weight: 700;
          }
        }
      }
      &-link {
        background: #000000 0 0 no-repeat padding-box;
        border-radius: 0 0 20px 20px;
        width: 100%;
        padding: 0.5rem 0;

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;
          font: normal normal 16px/20px Euclid Circular A;
          margin: auto;
          color: #959698;
          padding-left: 8%;
          &:hover {
            color: #ffffff;
          }
        }

        img {
          width: 18px;
          color: #959698;
        }
      }
      &-logo {
        position: absolute;
        left: 14px;
        bottom: 0.6rem;
        img {
          position: relative;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          border: 1px solid #ffffff;
          padding: 4px;
        }
        &::after {
          content: '';
          position: absolute;
          width: 64px;
          height: 64px;
          left: calc(((64px - 48px) / 2) * -1);
          top: calc(((64px - 48px) / 2) * -1);
          background: #000000 0 0 no-repeat padding-box;
          border-radius: 50%;
          z-index: -111;
        }
      }
    }
  }
  .btn {
    background: none;
    border: none;
    cursor: pointer;
    transition-duration: 200ms;
    padding: 0;
  }
  .btn.btn-primary {
    color: #1ea4ea !important;
  }
`
