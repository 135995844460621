import { Audit, Meta } from './types'

export const mapAuditForResponse = (audit: any): Audit => ({
  createdAt: audit.creation_date,
  createdBy: audit.created_by,
  updatedAt: audit.update_date,
  updatedBy: audit.updated_by,
})

export const mapMetaForResponse = (meta: any): Meta => ({
  totalData: meta.dataCount,
  totalPage: meta.pageCount,
})
