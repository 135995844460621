import React, { useImperativeHandle, useRef, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Column, Container, Row } from '@zera-admin/page'
import TweetActivities from '@zera-admin/tweet-activities'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'

import * as Styled from './SocialNumbers.styled'
import { mapQueryForSocialNumbers } from './utils/mappers'
import {
  SocialNumbersProps,
  SocialNumbersValueProps,
  SocialNumbersVisualizationOptions,
} from './types'
import Select, { SelectOptionProps } from '@zera-admin/select'
import useDefinitions from 'app/hooks/definitions'

const SocialNumbers: React.ForwardRefRenderFunction<
  WidgetHandlerRef<SocialNumbersVisualizationOptions>,
  SocialNumbersProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<SocialNumbersValueProps[]>([])
  const [error, setError] = useState<string>()
  const tweetRefs = useRef<HTMLDivElement[]>([])
  const [list, setList] = useState<SelectOptionProps[]>()
  const { definitions } = useDefinitions()
  const [event, setEvent] = useState({
    searchValue: '',
    secondValue: '',
  })
  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const getColumn = () => {
    const { grid } = props.widget

    if (grid) {
      if (grid === 2) return 6
      else if (grid === 3) return 4
      else if (grid === 4) return 3
      else if (grid === 6) return 2
    }

    return 12
  }

  const replaceQuotes = (label: string) => {
    const position = label.search(',')
    if (position > -1) {
      return label.split(',').join(' ')
    }
    return label
  }
  const runQuery = (widget: Widget<SocialNumbersVisualizationOptions>) => {
    setIsLoading(true)
    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        searchKey: `${event.searchValue}`,
        secondValue: `${event.secondValue}`,
      })
      .then((res) => {
        setValues(
          mapQueryForSocialNumbers(
            res.data as QueryRunResponse,
            widget.visualization?.options
          )
        )
        setIsLoading(false)
        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderNewsItem = (props: SocialNumbersValueProps, index: number) => {
    return (
      <Styled.News
        key={index}
        ref={(element) =>
          (tweetRefs.current[index] = element as HTMLDivElement)
        }
      >
        <Column xs={{ size: 12 }}>
          <Select
            label="Kelime Seçiniz"
            name="words"
            value={list}
            options={[
              { label: 'istanbul', value: 'istanbul' },
              { label: 'ankara', value: 'ankara' },
              { label: 'turkey', value: 'turkey' },
            ]}
            onChange={(props) => {
              const options = props as SelectOptionProps[]
              setList(options)
              let searchList = options?.map(
                (item: SelectOptionProps) => `''${item.value}''`
              )
              let secondList = options?.map(
                (item: SelectOptionProps, index: number) =>
                  index === 0
                    ? `definition_values like ''%${item.value}%''`
                    : `or definition_values like ''%${item.value}%''`
              )
              setEvent({
                ...event,
                searchValue: `'(${searchList.toString() || ''})'`,
                secondValue: `'(${secondList
                  .toString()
                  .split(',')
                  .join(' ')})'`,
              })
            }}
            placeholder="Seçim yapın"
            isMultiple={true}
          />
        </Column>
        <TweetActivities
          totalTweetCount={props?.totalTweetCount || '0'}
          totalOriginTweetCount={props?.totalOriginTweetCount || '0'}
          totalReTweetCount={props?.totalRetweetCount || '0'}
          numberOfEffectsCount={
            props?.totalFollowersCountOfTweetedAccounts || '0'
          }
          totalTweetAccountsCount={props?.uniqueAccountCount || '0'}
          totalSingularUserCount={props?.totalImpressionCount || '0'}
          youTubeCount={props?.youtubecount || '0'}
          newsPaperCount={props?.newspapercount || '0'}
          instagramCount={props?.instagramcount || '0'}
          redditCount={props?.redditcount || '0'}
        />
      </Styled.News>
    )
  }

  const renderContent = () => {
    const { grid } = props.widget

    if (grid) {
      const column = Math.round(values.length / grid)
      const group: { [key: string]: SocialNumbersValueProps[] } = {}

      for (var i = 0; i < values.length; i += column) {
        group[i] = values.slice(i, i + column)
      }

      return (
        <Row>
          {Object.keys(group).map((key) => {
            const value = group[key]

            return (
              <Column xs={{ size: getColumn() }}>
                {value.map((row, index) => renderNewsItem(row, index))}
              </Column>
            )
          })}
        </Row>
      )
    } else {
      return (
        <Container width="narrow">
          <Styled.NewsList>
            {values.map((row, index) => renderNewsItem(row, index))}
          </Styled.NewsList>
        </Container>
      )
    }
  }

  const renderNews = () => {
    if (isLoading) {
      return (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      )
    } else {
      if (values.length) {
        return renderContent()
      } else {
        return (
          <Alert
            appearance={error ? 'error' : 'warning'}
            title="Haberler oluşturulamadı"
          >
            {error ||
              'Haberler listesini oluşturacak veri bulunamadı. Lütfen sorgularınızı ve haberler listesi ayarlarınızı kontrol ediniz.'}
          </Alert>
        )
      }
    }
  }

  return renderNews()
}

export default React.forwardRef(SocialNumbers)
