import React from 'react'

import InfoCard from '../report-card'
import { MostTweetedLocationProps } from './types'
import LocationIcon from './location_icon.svg'

const MostTweetedLocation: React.FunctionComponent<MostTweetedLocationProps> =
  ({ data }) => {
    return (
      <InfoCard
        icon={LocationIcon}
        title="En Çok Post Atılan Toplam Lokasyonu Tespit Edilmiş Kullanıcı Sayısı "
      >
        <table className="info-card-table">
          <thead>
            <tr>
              <th>Post Count</th>
              <th>User Country Detection</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((location: any) => (
              <tr>
                <td>{location.rowCount.toLocaleString()}</td>
                <td>{location.userCountryDetection}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfoCard>
    )
  }
export default MostTweetedLocation
