import { Column } from '@zera-admin/page'
import PdfPageLayout from './PdfPageLayout'
import { Aspect, CategoriesByTime } from '../report-detail'

import { PdfPageProps } from './types'
import Icon from '@zera-admin/icon'
import { getMonthAndYear } from 'app/functions'

const PageCategories: React.FunctionComponent<PdfPageProps> = ({
  item,
  index,
  personReport,
}) => {
  return (
    <PdfPageLayout personReport={personReport}>
      <div style={{ width: '80vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 6 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Zamana göre sınıflandırmalar (Aylık)
            </h2>
            <CategoriesByTime data={item} chartHeight={600} />
          </Column>
          <Column xs={{ size: 6 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Tweet'lerinin ABS analizi
            </h2>

            <Aspect
              data={
                personReport.data?.result?.Statistic?.aspectAnalysis?.aspect[
                  index
                ]
              }
              chartHeight={600}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data?.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
    </PdfPageLayout>
  )
}
export default PageCategories
