import React, { useEffect, useState } from 'react'
import { uid } from '@zera-admin/utils'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { ValidationError } from 'app/shared/types'
import MainLayout from 'layouts/main'
import categoryTypeService, {
  ClassificationCategory,
  UpdateClassificationCategoryRequest,
} from 'services/http/dataset/classification-category'

import { UpdateClassificationCategoryProps } from './types'
import CategoryTypeForm from './ClassificationCategoryForm'

const UpdateClassificationCategory: React.FunctionComponent<UpdateClassificationCategoryProps> =
  ({ match, history }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isReady, setIsReady] = useState(true)
    const [categoryType, setCategoryType] = useState<ClassificationCategory>()
    const [flagcard] = useFlagcardContext()

    useEffect(() => {
      const id = parseInt(match.params.id)

      categoryTypeService
        .getById(id)
        .then((res) => {
          setCategoryType(res.data)
        })
        .catch((err: Error) => {
          console.log(err)
        })
        .finally(() => {
          setIsReady(false)
        })
    }, [match.params.id, history])

    const handleSubmit = (data: UpdateClassificationCategoryRequest) => {
      if (data) {
        setIsLoading(true)

        categoryTypeService
          .update(data)
          .then(() => {
            flagcard.add({
              id: uid(),
              appearance: 'success',
              title: 'İşlem Başarılı',
              children:
                'Geçerli veri, sistem üzerinde başarıyla güncellenmiştir.',
            })
          })
          .catch((error) => {
            console.log(error)

            flagcard.add({
              id: uid(),
              appearance: 'error',
              title: 'Hata Oluştu',
              children:
                'Geçerli veri güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    const handleError = (error?: ValidationError) => {
      console.log(error)
    }

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Kategori tipi" href="/classification-category" />
        <BreadcrumbItem text="Kategori tipi güncelle" />
      </Breadcrumb>
    )

    return (
      <MainLayout isLoading={isReady}>
        <Container width="medium">
          <Page>
            <PageHeader
              // actions={<Button appearance="error">Delete user</Button>}
              breadcrumbs={breadcrumbs}
              bottomBar={
                <p>
                  Aşağıdaki bilgileri doldurarak geçerli kategori tipi
                  güncelleyebilirsiniz.
                </p>
              }
            >
              Veri güncelleniyor: {categoryType ? categoryType.name : null}
            </PageHeader>
            <CategoryTypeForm
              actions={
                <ButtonGroup>
                  <Button
                    appearance="primary"
                    children="Güncelle"
                    isLoading={isLoading}
                    type="submit"
                  />
                  <LinkButton href="/classification-category">İptal</LinkButton>
                </ButtonGroup>
              }
              operation="update"
              onSubmit={handleSubmit}
              onError={handleError}
              values={categoryType}
            />
          </Page>
        </Container>
      </MainLayout>
    )
  }

export default UpdateClassificationCategory
