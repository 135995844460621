import { string } from '@zera-admin/helpers'

import { Query } from 'services/http/bi-tool/query'

import { CounterVisualizationOptions } from '../types'

export const generateCounterOptions = (
  options: CounterVisualizationOptions,
  query: Query
): CounterVisualizationOptions => {
  let result = options

  const aggregation = query.aggregations?.length
    ? query.aggregations[query.aggregations.length - 1]
    : null

  if (aggregation) {
    result = {
      ...result,
      value: {
        field: aggregation.field as string,
        type: 'number',
      },
      label: string.textify(aggregation.field as string),
    }
  }

  return result
}
