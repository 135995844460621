import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

interface DashboardListProps {
  customProp: boolean
}

export const DashboardList = styled.div.attrs((props: DashboardListProps) => ({
  customProp: props.customProp,
}))<DashboardListProps>`
  position: absolute;
  left: 87%;
  top: 0;

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent !important;
    color: ${(props) => (props.customProp ? 'green' : 'red')};

    &:hover {
      color: ${(props) => (props.customProp ? 'green' : 'red')};
    }
  }
`

export const HandleDeleteContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 12px;
  position: fixed;
  width: 40vh;
  padding: 10px;
  height: 10vh;
  top: 80%;
  left: 72%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: ${fadeIn} 0.5s ease-in-out;
  .handle-delete-row {
    @media only screen and (min-width: 2880px) {
      flex-direction: column;
    }
  }

  h3 {
    text-align: center;
    color: black;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Error = styled.div`
  border-radius: 3px;
  font-size: 1rem;
  z-index: 9999;
  color: red;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-in; /* Apply fade-in animation */
  &:before {
    transition: 0.6s;
  }

  .error-text {
    font-size: 0.8rem;
    text-align: center;
    color: black;
  }
`

export const Success = styled.div`
  border-radius: 3px;
  font-size: 1rem;
  z-index: 9999;
  color: green;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  animation: ${fadeIn} 0.6s ease-in; /* Apply fade-in animation */
  &:before {
    transition: 0.6s;
  }

  .success-text {
    font-size: 0.8rem;
    text-align: center;
    color: black !important;
  }
`
