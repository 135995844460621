import { PropertyDefinitionOneOf } from 'app/library/layout-builder'

export const ColumnSizesOneOf: PropertyDefinitionOneOf[] = [
  {
    key: '',
    text: 'Varsayılan',
  },
  {
    key: 1,
    text: '1/12',
  },
  {
    key: 2,
    text: '2/12',
  },
  {
    key: 3,
    text: '3/12',
  },
  {
    key: 4,
    text: '4/12',
  },
  {
    key: 5,
    text: '5/12',
  },
  {
    key: 6,
    text: '6/12',
  },
  {
    key: 7,
    text: '7/12',
  },
  {
    key: 8,
    text: '8/12',
  },
  {
    key: 9,
    text: '9/12',
  },
  {
    key: 10,
    text: '10/12',
  },
  {
    key: 11,
    text: '11/12',
  },
  {
    key: 12,
    text: '12/12',
  },
]

export const ReorderingOneOf: PropertyDefinitionOneOf[] = [
  {
    key: '',
    text: 'Varsayılan',
  },
  {
    key: 'first',
    text: 'first',
  },
  {
    key: 'last',
    text: 'last',
  },
]

export const AlignmentOneOf: PropertyDefinitionOneOf[] = [
  {
    key: 'start',
    text: 'Başta Göster',
  },
  {
    key: 'end',
    text: 'Sonda Göster',
  },
  {
    key: 'middle',
    text: 'Ortada Göster',
  },
  {
    key: 'bottom',
    text: 'Altta Göster',
  },
  {
    key: 'center',
    text: 'Dikey ve Yatayda Ortada Göster',
  },
]

export const ButtonAppearanceOneOf: PropertyDefinitionOneOf[] = [
  {
    key: '',
    text: 'Varsayılan',
  },
  {
    key: 'primary',
    text: 'Primary',
  },
  {
    key: 'default',
    text: 'Default',
  },
  {
    key: 'danger',
    text: 'Danger',
  },
  {
    key: 'subtle',
    text: 'Subtle',
  },
  {
    key: 'help',
    text: 'Help',
  },
  {
    key: 'info',
    text: 'Info',
  },
  {
    key: 'success',
    text: 'Success',
  },
  {
    key: 'warning',
    text: 'Warning',
  },
]

export const ButtonSpacingOneOf: PropertyDefinitionOneOf[] = [
  {
    key: 'default',
    text: 'Varsayılan',
  },
  {
    key: 'compact',
    text: 'Default',
  },
  {
    key: 'none',
    text: 'none',
  },
]

export const ContainerSizesOneOf: PropertyDefinitionOneOf[] = [
  {
    key: '',
    text: 'Varsayılan',
  },
  {
    key: 'fluid',
    text: 'Full',
  },
  {
    key: 'extended',
    text: 'Ekstra Geniş',
  },
  {
    key: 'wide',
    text: 'Geniş',
  },
  {
    key: 'medium',
    text: 'Standart',
  },
  {
    key: 'narrow',
    text: 'Okuma Modu',
  },
]

export const ImageSizesOneOf: PropertyDefinitionOneOf[] = [
  {
    key: 'sm',
    text: 'Küçük',
  },
  {
    key: 'md',
    text: 'Orta',
  },
  {
    key: 'lg',
    text: 'Büyük',
  },
]

export const CardListSizesDefault = { lg: 3, xs: 12, sm: 6, md: 4, xl: 3 }

export const SlideshowArrows: PropertyDefinitionOneOf[] = [
  {
    key: 'ontop',
    text: 'Üstte',
  },
  {
    key: 'centered',
    text: 'Ortada',
  },
]

export const SlideshowLazyload: PropertyDefinitionOneOf[] = [
  {
    key: 'ondemand',
    text: 'ondemand',
  },
  {
    key: 'progressive',
    text: 'progressive',
  },
]
