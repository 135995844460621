import React from 'react'
import { Column, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'

import { QueryOrderSort } from 'services/http/bi-tool/query/types'

import { ORDER_SORTS } from '../utils/constants'
import * as Styled from '../FilterSettings.styled'

import { FilterTabProps } from '../types'

const OrderTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
  fields,
}) => {
  const renderOrders = () => {
    const orders = [...(values.orders || [])]
    const resultFields = values.aggregations?.length
      ? fields.filter((value) =>
          values.aggregations?.find(
            (aggregation) => aggregation?.field === value.field
          )
        )
      : fields

    return orders.map((order, index) => (
      <Styled.ListField key={index}>
        <Row xs={{ direction: 'row' }}>
          <Column>
            <Select
              name="sort"
              label="Sıralama alanı"
              placeholder="Sıralama alanı seçiniz"
              options={resultFields.map((value) => ({
                label: string.textify(value.field),
                value: value.field,
              }))}
              value={
                order.field
                  ? { label: string.textify(order.field), value: order.field }
                  : undefined
              }
              onChange={(props) => {
                orders[index].field = (props as SelectOptionProps)
                  .value as QueryOrderSort

                onChange({ ...values, orders })
              }}
            />
          </Column>
          <Column>
            <Select
              name="sort"
              label="Sıralama tipi"
              placeholder="Sıralama tipi seçiniz"
              options={ORDER_SORTS}
              value={
                order.sort
                  ? ORDER_SORTS.find((sort) => sort.value === order.sort)
                  : undefined
              }
              onChange={(props) => {
                orders[index].sort = (props as SelectOptionProps)
                  .value as QueryOrderSort

                onChange({ ...values, orders })
              }}
            />
          </Column>
          <Button
            appearance="error"
            size="medium"
            iconBefore={<Icon name="cross" size="small" />}
            onClick={() => {
              orders.splice(index, 1)
              onChange({ ...values, orders })
            }}
          />
        </Row>
      </Styled.ListField>
    ))
  }

  const renderOrderTab = () => {
    const orders = [...(values.orders || [])]

    return (
      <React.Fragment>
        <Row xs={{ direction: 'row' }}>
          <Column xs={{ size: 12 }}>
            <p>
              Aşağıdan seçtiğiniz alanları öncelikli olarak sıralayabilirsiniz.
            </p>
          </Column>
        </Row>
        {renderOrders()}
        <Button
          iconBefore={<Icon name="add" size="small" />}
          onClick={() => {
            orders.push({ field: '', sort: 'asc' })

            onChange({ ...values, orders })
          }}
          style={{ marginTop: 12 }}
        >
          Yeni sıralama ekle
        </Button>
      </React.Fragment>
    )
  }

  return renderOrderTab()
}

export default OrderTab
