import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button, { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Icon from '@zera-admin/icon'
import Lozenge from '@zera-admin/lozenge'
import Form, { useForm } from '@zera-admin/form'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Table from '@zera-admin/table'
import DateTimePicker from '@zera-admin/datetime-picker'

import {
  currentDateString,
  currentWeekBeforeString,
  turkishDateFormat,
} from 'app/functions'
import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import definitionResponseService, {
  Definition,
  DefinitionResponse,
} from 'services/http/backoffice/definition'

import { DefinitionListProps } from './types'
import { StatusEnum } from 'app/shared/enums'
import { IDefinitionListRequest } from 'services/http/backoffice/definition/types'
import { useFlagcardContext } from 'app/contexts/flagcard'

const DefinitionList: React.FunctionComponent<DefinitionListProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [definitions, setDefinitions] = useState<DefinitionResponse>()
  const selectSources = [
    { label: 'Genel', value: 0 },
    { label: 'Twitter', value: 1 },
    { label: 'Reddit', value: 2 },
    { label: 'Tumblr', value: 3 },
    { label: 'EksiSozluk', value: 4 },
    { label: 'News', value: 5 },
    { label: 'Youtube', value: 6 },
    { label: 'Vk', value: 7 },
    { label: 'İnci Sozluk', value: 8 },
    { label: 'Instagram', value: 9 },
    { label: 'Linkedin', value: 10 },
    { label: 'Mastodon', value: 11 },
    { label: 'Quora', value: 12 },
    { label: 'Memurlar', value: 13 },
    { label: 'Booking', value: 14 },
    { label: 'AjansPress', value: 15 },
    { label: 'Airbnb', value: 16 },
    { label: 'GoogleTrends', value: 17 },
    { label: 'StarnGage', value: 18 },
  ]

  const history = useHistory()

  useEffect(() => {
    getDefinitions(intitalFormValues, 0)
  }, [])

  const clearFilter = () => {
    form.handleBatchUpdate(intitalFormValues)
    history.push({
      search: 'pageIndex=1',
    })
    setTimeout(() => getDefinitions(intitalFormValues, 0), 500)
  }

  const intitalFormValues: IDefinitionListRequest = {
    definitionValue: '',
    PageIndex: 0,
    PageSize: 10,
    dataSource: '',
    createdAtSince: '',
    createdAtUntil: '',
  }
  const form = useForm<IDefinitionListRequest>({
    initialValues: intitalFormValues,
    onSubmit: () => handleFilter(),
  })

  const handleFilter = () => {
    history.push({
      search: 'pageIndex=1',
    })
    getDefinitions(form?.values, 0)
  }

  const getDefinitions = async (
    formData: IDefinitionListRequest,
    pageIndex: number
  ) => {
    const index = pageIndex ? pageIndex - 1 : 0
    try {
      setIsLoading(true)
      const dataSource = selectSources.find(
        (sourceKey: SelectOptionProps) =>
          sourceKey.value === form?.values?.dataSource
      )?.label
      const response = await definitionResponseService.getList({
        ...formData,
        PageIndex: index,
        dataSource: formData?.dataSource ? dataSource : '',
      })
      setDefinitions(response.data)
      setIsLoading(false)
    } catch (err) {
      const ex = err
      setIsLoading(false)
      setDefinitions({
        list: [],
        dataCount: 0,
        message: '',
        pageCount: 0,
        success: false,
      })
    }
  }
  const [flagcard] = useFlagcardContext()

  const handlePaused = async (id: string) => {
    if (id) {
      try {
        const response = await definitionResponseService.putById(id)
        if (response.code === 200) {
          setDefinitions((prevDefinitions) => {
            if (prevDefinitions) {
              const updatedList = prevDefinitions.list.map((definition) => {
                if (definition.id === id) {
                  return {
                    ...definition,
                    paused: !definition.paused,
                  }
                }
                return definition
              })

              return {
                ...prevDefinitions,
                list: updatedList,
              }
            }
            return prevDefinitions
          })
        } else {
          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: 'Süreç değiştirilirken bir hata oluştu',
          })
        }
      } catch {
        flagcard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children: 'Süreç değiştirilirken bir hata oluştu',
        })
      }
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/definition/create">
        Tanımlama oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Tanımlama" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm tanımlamaları görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )
    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Tanımlama
      </PageHeader>
    )
  }

  const dataSourceValue = selectSources?.find(
    (sourceKey: SelectOptionProps) =>
      sourceKey?.value === form?.values?.dataSource
  )

  const renderFilter = () => {
    return (
      <Form onSubmit={form.handleSubmit}>
        <Row>
          <Column xs={{ size: 3 }}>
            <Input
              description="Geçerli bir Keyword giriniz"
              label="Keyword Adı"
              name="definitionValue"
              placeholder="Örnek metin"
              value={form?.values?.definitionValue}
              onChange={form.handleChange}
            />
          </Column>

          <Column xs={{ size: 2 }} className="tweet-date-rs-f">
            <Select
              name="dataSource"
              label="Kaynak"
              options={selectSources}
              onChange={(props: any) =>
                form.handleFieldChange('dataSource', props.value)
              }
              value={dataSourceValue || selectSources[0]}
            />
          </Column>

          <Column xs={{ size: 2 }}>
            <DateTimePicker
              label="Başlangıç Tarihi"
              locale="tr"
              name="createdAtSince"
              onChange={(props) => {
                form.handleFieldChange('createdAtSince', props)
              }}
              type="date"
              datePickerProps={{
                maxDate: form?.values?.createdAtUntil
                  ? form?.values?.createdAtUntil
                  : currentWeekBeforeString(),
              }}
              value={
                form?.values?.createdAtSince
                  ? form?.values?.createdAtSince
                  : currentWeekBeforeString()
              }
            />
          </Column>
          <Column xs={{ size: 2 }}>
            <DateTimePicker
              label="Bitiş Tarihi"
              locale="tr"
              name="createdAtUntil"
              onChange={(props) => {
                form.handleFieldChange('createdAtUntil', props)
              }}
              type="date"
              value={
                form?.values?.createdAtUntil
                  ? form?.values?.createdAtUntil
                  : currentDateString()
              }
              datePickerProps={{
                minDate: form?.values?.createdAtSince,
                maxDate: currentDateString(),
              }}
            />
          </Column>

          <Column xl={{ align: 'center' }}>
            <Button
              appearance="primary"
              children="Arama yap"
              iconBefore={<Icon name="search" />}
              type="submit"
            />
            <Button
              appearance="error"
              children="Filtreyi temizle"
              iconBefore={<Icon name="trash" />}
              style={{ marginLeft: 20 }}
              type="button"
              onClick={() => {
                form.handleReset()
                clearFilter()
              }}
            />
          </Column>
        </Row>
      </Form>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'dataSource',
        text: 'Veri kaynağı',
      },
      {
        name: 'eventName',
        text: 'Aranma Tipi',
        render: (props: Definition) => {
          if (props.eventName.includes('UserName')) {
            return <i className="fa-solid fa-user"></i>
          } else if (props.eventName.includes('Keyword')) {
            return <i className="fa-solid fa-hashtag"></i>
          }
        },
      },
      {
        name: 'value',
        text: 'Keyword',
        render: (props: Definition) => {
          return props.crawlProperties.Value
        },
      },
      {
        name: 'crawlingStartedAt',
        text: 'Başlama tarihi',
        render: (props: Definition) => {
          return turkishDateFormat(props.crawlingStartedAt)
        },
      },
      {
        name: 'crawlingEndedAt',
        text: 'Bitiş tarihi',
        render: (props: Definition) => {
          return turkishDateFormat(props.crawlingEndedAt)
        },
      },
      {
        name: 'insertedInQueueAt',
        text: 'Rapor başlangıç tarihi',
        render: (props: Definition) => {
          return turkishDateFormat(props.insertedInQueueAt)
        },
      },
      {
        name: 'progressPercentage',
        text: 'İlerleme yüzdesi',
        render: (props: Definition) => {
          return (
            <Lozenge
              appearance={
                props.progressPercentage === 100 ? 'success' : 'warning'
              }
            >
              {props.progressPercentage.toFixed(2)}%
            </Lozenge>
          )
        },
      },
      {
        name: 'status',
        text: 'Durumu',
        render: (props: Definition) => {
          if (props.paused === true) {
            return <Lozenge appearance="warning">Durduruldu</Lozenge>
          } else if (props.status === StatusEnum.Completed) {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {props.status !== StatusEnum.Completed ? (
                  props.paused === false ? (
                    <Icon name="vid-play" />
                  ) : (
                    <Icon name="vid-pause" />
                  )
                ) : null}
                <Lozenge appearance="success">Tamamlandı</Lozenge>
              </div>
            )
          } else if (props.status === StatusEnum.Pending) {
            return <Lozenge appearance="warning">Bekliyor</Lozenge>
          } else if (props.status === StatusEnum.Error) {
            return <Lozenge appearance="error">Hatalı</Lozenge>
          } else if (props.status === StatusEnum.Processing) {
            return <Lozenge appearance="info">İşleniyor</Lozenge>
          } else if (props.status === StatusEnum.Disabled) {
            return <Lozenge appearance="default">Devre Dışı</Lozenge>
          } else if (props.status === StatusEnum.Ready) {
            return <Lozenge appearance="success">Hazır</Lozenge>
          } else if (props.status === StatusEnum.Stopped) {
            return <Lozenge appearance="error">Durduruldu</Lozenge>
          } else if (props.status === StatusEnum.Calculating) {
            return <Lozenge appearance="info">Hesaplanıyor</Lozenge>
          } else if (props.status === StatusEnum.Exists) {
            return <Lozenge appearance="info">Var</Lozenge>
          } else if (props.status === StatusEnum.Updated) {
            return <Lozenge appearance="info">Güncellendi</Lozenge>
          }
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: Definition) => (
          <LinkButton
            appearance="subtle-link"
            href={`/definition/detail/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
      {
        name: 'period',
        text: 'Süreç ',
        width: '100px',
        render: (item: Definition) => (
          <Button
            appearance="subtle"
            iconBefore={
              item.paused === true ? (
                <Icon name="vid-play" />
              ) : (
                <Icon name="vid-pause" />
              )
            }
            style={{ marginLeft: 20 }}
            children={item.paused === true ? 'Sürdür' : 'Durdur'}
            type="button"
            onClick={() => handlePaused(item.id)}
          />
        ),
      },
    ]
    return (
      <React.Fragment>
        <Table
          columns={columns as any}
          rows={definitions?.list || []}
          isLoading={isLoading}
          helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
        />
        <Pager
          pages={definitions?.pageCount || 0}
          onChange={(index) => getDefinitions(form.values, index)}
        />
      </React.Fragment>
    )
  }
  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderFilter()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default DefinitionList
