import React, { useRef, useEffect, useImperativeHandle } from 'react'

import {
  VisualizationOptions,
  WidgetHandlerRef,
} from 'services/http/bi-tool/widget'

import AreaChartVisualization from '../area-chart'
import BarChartVisualization from '../bar-chart'
import BarVisualization from '../bar'
import ColoredMapVisualization from '../colored-map'
import CounterVisualization from '../counter'
import InfluentialMapVisualization from '../influential-map'
import NameValueVisualization from '../name-value'
import NewsVisualization from '../news'
import PieChartVisualization from '../pie-chart'
import RadarChartVisualization from '../radar-chart'
import RedditVisualization from '../reddit'
import StackedChartVisualization from '../stacked-chart'
import TableVisualization from '../table'
import TimeSeriesVisualization from '../time-series'
import TweetVisualization from '../tweet'
import UserVisualization from '../user'
import WordCloudVisualization from '../word-cloud'
import GraphChartVisualization from '../graph-chart'
import SocialNumbersVisualization from '../social-numbers'
import { VisualizationProps } from './types'
import Hotel from '../hotel'

const Visualization: React.ForwardRefRenderFunction<
  WidgetHandlerRef<VisualizationOptions>,
  VisualizationProps
> = ({ widget, theme = 'light', onClickRow }, ref) => {
  const visualizationRef = useRef<WidgetHandlerRef>(null)

  useImperativeHandle(ref, () => ({
    runQuery: (widget) => visualizationRef.current?.runQuery(widget),
  }))

  useEffect(() => {
    if (widget) {
      visualizationRef.current?.runQuery(widget)
    }
  }, [widget])

  const renderVisualization = () => {
    if (widget && widget.visualization) {
      const props = {
        onClickRow,
        ref: visualizationRef,
        theme,
        widget,
      }

      if (props.widget.visualization) {
        switch (props.widget.visualization.type) {
          case 'area-chart':
            return <AreaChartVisualization {...props} />
          case 'pie-chart':
            return <PieChartVisualization {...props} />
          case 'bar-chart':
            return <BarChartVisualization {...props} />
          case 'bar':
            return <BarVisualization {...props} />
          case 'radar-chart':
            return <RadarChartVisualization {...props} />
          case 'counter':
            return <CounterVisualization {...props} />
          case 'colored-map':
            return <ColoredMapVisualization {...props} />
          case 'tweet':
            return <TweetVisualization {...props} />
          case 'user':
            return <UserVisualization {...props} />
          case 'reddit':
            return <RedditVisualization {...props} />
          case 'stacked-chart':
            return <StackedChartVisualization {...props} />
          case 'influential-map':
            return <InfluentialMapVisualization {...props} />
          case 'news':
            return <NewsVisualization {...props} />
          case 'hotel':
            return <Hotel {...props} />
          case 'name-value':
            return <NameValueVisualization {...props} />
          case 'time-series':
            return <TimeSeriesVisualization {...props} />
          case 'word-cloud':
            return <WordCloudVisualization {...props} />
          case 'graph-chart':
            return <GraphChartVisualization {...props} />
          case 'social-numbers':
            return <SocialNumbersVisualization {...props} />
          default:
            return <TableVisualization {...props} />
        }
      }
    }

    return null
  }

  return renderVisualization()
}

const propsAreEqual = (
  prevProps: VisualizationProps,
  nextProps: VisualizationProps
): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

export default React.memo(React.forwardRef(Visualization), propsAreEqual)
