import { CheckboxProps } from '@zera-admin/checkbox'

import { SwitchProps } from './types'

export const initialValues = {
  googleSearchValue: '',
  wikipediaValue: '',
  facebookValue: '',
  linkedinValue: '',
  emailValue: '',
  userName: '',
  requestField: [
    'mostFollowedFollowers',
    'mostTweetedFollowers',
    'tweetsByTimeAfter2021',
    'tweetsByTimeBefore2021',
    'categoriesByTime',
    'mostRetweeted20Tweets',
    'followersByCountry',
    'totalRetweetCount',
    'totalReplyCount',
    'userInfo',
    'mostUsedHashtags',
    'mostMentions',
    'followersByGender',
    'aspectAnalysis',
    'bigFive',
    'botDetection',
    'wordCloud',
    'mostRecentTweets',
    'mostImpressedBy',
    'activeHours',
    'languagesUsedByUser',
    'averageSentiments',
    'tweetImpressions',
    'googleSearch',
    'wikipediaSearch',
    'facebookSearch',
    'linkedinSearch',
    'emailSearch',
    'mostCommonEntities',
    'generalCategories',
    'lastTweet',
    'firstTweet',
  ],
  groupId: '',
  selectedEvents: [
    'fetch-profile-details',
    'fetch-user-tweets',
    'create-word-count',
    'fetch-user-followers',
    'use-ai',
    'use-big5',
    'generate-statistics',
  ],
  turkeyPerceptionQueryKeywords: [
    'Turkiye',
    'Türkiye',
    'Turkey',
    'Istanbul',
    'istanbul',
    'erdoğan',
    'Erdogan',
    'Ankara',
    'İstanbul',
  ],
}

export const switches: SwitchProps[] = [
  {
    id: 'fetch-profile-details',
    disabled: true,
    label: 'Bu kullanıcının profil bilgileri çekilsin',
    name: 'fetch-profile-details',
  },
  {
    id: 'fetch-user-tweets',
    disabled: true,
    label: 'Bu kullanıcının tweetleri çekilsin',
    name: 'fetch-tweet',
  },
  {
    id: 'create-word-count',
    label: 'Tweetleri word count oluşturulsun',
    name: 'create-word-count',
  },
  {
    id: 'fetch-user-followers',
    label:
      'Bu kullanıcının follower id listesi çekilerek bu idlerin user lookup servisinden profil bilgileri çekilsin',
    name: 'follower-id',
  },
  {
    id: 'use-ai',
    label: 'Bu kullanıcının tüm tweetleri yapay zeka süreçlerinden geçsin',
    name: 'all-ai',
  },
  {
    id: 'use-big5',
    label: 'Bu kullanıcının big5 analizi yapılsın',
    name: 'big-five',
  },
  {
    id: 'generate-statistics',
    label: 'Kullanıcı ile alakalı istatistik raporları çıkartılsın',
    name: 'statistic-report',
  },
]
export const checksPerceptions: CheckboxProps[] = [
  {
    isChecked: true,
    label: 'Kişinin Türkiye algı skoru',
    name: 'turkeyPerceptionTweets',
  },
  {
    isChecked: true,
    label: 'Türkiye ile ilgili tweetlerinin ayrıca gösterilmesi',
    name: 'turkeyPerceptionSentimentCounts',
  },
  {
    isChecked: true,
    label: 'Türkiye hakkında paylaştığı tweetlerin genel bilgileri',
    name: 'turkeyPerceptionTweetMetrics',
  },
]
export const checks: CheckboxProps[] = [
  {
    isChecked: true,
    label: 'Big5',
    name: 'bigFive',
  },
  {
    isChecked: true,
    label: 'En çok takipçisi olan takipçileri',
    name: 'mostFollowedFollowers',
  },
  {
    isChecked: true,
    label: 'En çok post atan takipçileri',
    name: 'mostTweetedFollowers',
  },
  {
    isChecked: true,
    label: 'Attığı tweetlerin zamana göre dağılımı (2021 sonrası)',
    name: 'tweetsByTimeAfter2021',
  },
  {
    isChecked: true,
    label: 'Attığı tweetlerin zamana göre dağılımı (2021 öncesi)',
    name: 'tweetsByTimeBefore2021',
  },
  {
    isChecked: true,
    label: 'Konuştuğu konuların özet raporu',
    name: 'generalCategories',
  },
  {
    isChecked: true,
    label: 'Konuştuğu konuların aylara göre dağılımları',
    name: 'categoriesByTime',
  },
  {
    isChecked: true,
    label: 'Takipçilerinin kadın erkek dağılımı',
    name: 'followersByGender',
  },
  {
    isChecked: true,
    label: 'Konuştuğu konuların sayıları',
    name: 'mostCommonEntities',
  },
  {
    isChecked: true,
    label: 'Takipçi listesinin ülkelere göre dağılımı',
    name: 'followersByCountry',
  },
  {
    isChecked: true,
    label: 'En çok retweet alan 20 tweeti',
    name: 'mostRetweeted20Tweets',
  },
  {
    isChecked: true,
    label: 'En çok kullandığı hastagler',
    name: 'mostUsedHashtags',
  },
  {
    isChecked: true,
    label: 'En çok mentionlamalar',
    name: 'mostMentions',
  },
  {
    isChecked: true,
    label: 'Most Recent Tweets',
    name: 'mostRecentTweets',
  },
  {
    isChecked: true,
    label: 'Most Impressed By',
    name: 'mostImpressedBy',
  },
  {
    isChecked: true,
    label: 'Active Hours',
    name: 'activeHours',
  },
  {
    isChecked: true,
    label: 'Languages Used By User',
    name: 'languagesUsedByUser',
  },
  {
    isChecked: true,
    label: 'Average Sentiments',
    name: 'averageSentiments',
  },
  {
    isChecked: true,
    label: 'Tweet Impressions',
    name: 'tweetImpressions',
  },
  {
    isChecked: true,
    label: 'Bot denetlemesi',
    name: 'botDetection',
  },
  {
    isChecked: true,
    label: 'Kelime bulutu',
    name: 'wordCloud',
  },
  {
    isChecked: true,
    label: 'Google',
    name: 'googleSearch',
  },
  {
    isChecked: true,
    label: 'Wikipedia',
    name: 'wikipediaSearch',
  },
  {
    isChecked: true,
    label: 'Facebook',
    name: 'facebookSearch',
  },
  {
    isChecked: true,
    label: 'Linkedin',
    name: 'linkedinSearch',
  },
  {
    isChecked: true,
    label: 'Email',
    name: 'emailSearch',
  },
]
