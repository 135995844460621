import type { BarProps } from './types'
import * as Styled from './Bar.styled'

const Bar: React.FunctionComponent<BarProps> = (props) => {
  const { title, count, width, onClick } = props

  return (
    <Styled.Bar onClick={onClick}>
      <div>
        <p>{title}</p>
        <b>{count?.toLocaleString()}</b>
      </div>
      <div>
        <div style={{ width: `${width}%` }} />
      </div>
    </Styled.Bar>
  )
}

export default Bar
