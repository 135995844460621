import { request } from 'services/http/dataset/instance'

import type {
  CreateEntityTypeRequest,
  EntityType,
  EntityTypeResponse,
  UpdateEntityTypeRequest,
} from './types'

import {
  mapEntityTypeForList,
  mapEntityTypeForRequest,
  mapEntityTypeForResponse,
} from './mappers'

export const create = (data: CreateEntityTypeRequest) =>
  request<EntityType>({
    method: 'post',
    url: 'entity-type',
    data,
    mappers: {
      req: mapEntityTypeForRequest,
    },
  })

export const get = (value?: string) =>
  request<EntityTypeResponse>({
    method: 'get',
    url: 'entity-type',
    // params: { name: value },
    mappers: {
      res: mapEntityTypeForList,
    },
  })

export const getById = (id: number) =>
  request<EntityType>({
    method: 'get',
    url: `entity-type/${id}`,
    mappers: {
      res: mapEntityTypeForResponse,
    },
  })

export const update = (data: UpdateEntityTypeRequest) =>
  request<EntityType>({
    method: 'put',
    url: `entity-type/${data.id}`,
    data,
    mappers: {
      req: mapEntityTypeForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `entity-type/${id}`,
  })
