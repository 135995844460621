import { request } from 'services/http/linkedin-dev/instance'

import { Linkedin, Email, Facebook } from './types'

export const get = (value: string) =>
  request<Linkedin>({
    method: 'get',
    url: 'search/linkedin',
    params: { value },
  })

export const getAutoComplete = (value: string) =>
  request<any>({
    method: 'get',
    url: 'search/linkedin/auto-complete',
    params: { value },
  })

export const getFacebook = (value: string) =>
  request<Facebook>({
    method: 'get',
    url: 'search/facebook',
    params: { value },
  })

export const getEmail = (value: string) =>
  request<Email>({
    method: 'get',
    url: 'search/email',
    params: { value },
  })
