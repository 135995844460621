import React, { useState, useEffect } from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'

import GeneralTab from './tabs/GeneralTab'
import ConditionTab from './tabs/ConditionTab'
import OrderTab from './tabs/OrderTab'
import SummarizeTab from './tabs/SummarizeTab'
import RawTab from './tabs/RawTab'
import IntervalsTab from './tabs/IntervalsTab'
import AddressTab from './tabs/AddressTab'
import InQueriesTab from './tabs/InQueriesTab'
import FunctionsTab from './tabs/FunctionsTab'

import * as Styled from './FilterSettings.styled'
import { FilterSettingsProps } from './types'

const FilterSettings: React.FunctionComponent<FilterSettingsProps> = ({
  fields = [],
  onDismiss,
  onSave,
  operation = 'widget',
  show,
  values,
  type = 'json',
  title,
}) => {
  const [settings, setSettings] = useState(values || {})
  const [editorValue, setEditorValue] = useState(values?.raw?.sql || '')
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if (values) {
      setSettings(values)
      setEditorValue(values.raw?.sql || '')
    }
  }, [values])

  const handleSave = () => {
    const currentAggregations = JSON.stringify(values?.aggregations)
    const nextAggregations = JSON.stringify(settings.aggregations)

    const currentGroups = JSON.stringify(values?.groups)
    const nextGroups = JSON.stringify(settings.groups)

    if (
      currentAggregations !== nextAggregations ||
      currentGroups !== nextGroups
    ) {
      onSave({ ...settings, raw: { ...settings.raw, sql: editorValue } }, true)
    } else {
      onSave({ ...settings, raw: { ...settings.raw, sql: editorValue } })
    }

    onDismiss()
  }

  const renderDrawerBody = () => {
    if (type === 'raw') {
      return (
        <Styled.FilterSettingsTabs>
          <Tabs id="tabs" selected={activeTab} onChange={setActiveTab}>
            <TabTriggerGroup>
              <TabTrigger>SQL Sorgu</TabTrigger>
              <TabTrigger>Özel Tarih Aralıkları</TabTrigger>
              <TabTrigger>Özel IN Sorguları</TabTrigger>
              <TabTrigger>Özel Sorgu Fonksiyonları</TabTrigger>
            </TabTriggerGroup>
            <TabPanel>
              <RawTab
                onChange={setEditorValue}
                value={editorValue}
                intervals={settings.raw?.intervals || []}
              />
            </TabPanel>
            <TabPanel>
              <IntervalsTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
            <TabPanel>
              <InQueriesTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
            <TabPanel>
              <FunctionsTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
          </Tabs>
        </Styled.FilterSettingsTabs>
      )
    } else if (type === 'http') {
      return (
        <Styled.FilterSettingsTabs>
          <Tabs id="tabs" selected={activeTab} onChange={setActiveTab}>
            <TabTriggerGroup>
              <TabTrigger>Adres Bilgileri</TabTrigger>
              <TabTrigger>Sorgu Sihirbazı</TabTrigger>
            </TabTriggerGroup>
            <TabPanel>
              <AddressTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
            <TabPanel>
              <ConditionTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
          </Tabs>
        </Styled.FilterSettingsTabs>
      )
    }
    return (
      <Styled.FilterSettingsTabs>
        <Tabs id="tabs" selected={activeTab} onChange={setActiveTab}>
          <TabTriggerGroup>
            <TabTrigger>Genel</TabTrigger>
            <TabTrigger>Sorgu Sihirbazı</TabTrigger>
            {operation === 'widget' && (
              <TabTrigger>Birleştirmeler ve Gruplamalar</TabTrigger>
            )}
            {operation === 'widget' && <TabTrigger>Sıralamalar</TabTrigger>}
            {operation === 'dashboard' && (
              <TabTrigger>Özel Sorgu Fonksiyonları</TabTrigger>
            )}
          </TabTriggerGroup>
          <TabPanel>
            <GeneralTab
              onChange={setSettings}
              values={settings}
              fields={fields}
              operation={operation}
              type={type}
            />
          </TabPanel>
          <TabPanel>
            <ConditionTab
              onChange={setSettings}
              values={settings}
              fields={fields}
              operation={operation}
              type={type}
            />
          </TabPanel>
          {operation === 'dashboard' && (
            <TabPanel>
              <FunctionsTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
          )}
          {operation === 'widget' && (
            <TabPanel>
              <SummarizeTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
          )}
          {operation === 'widget' && (
            <TabPanel>
              <OrderTab
                onChange={setSettings}
                values={settings}
                fields={fields}
                operation={operation}
                type={type}
              />
            </TabPanel>
          )}
        </Tabs>
      </Styled.FilterSettingsTabs>
    )
  }

  const renderDrawer = () => {
    const actions = (
      <ButtonGroup>
        <Button appearance="primary" onClick={handleSave}>
          Filtreyi uygula
        </Button>
        <Button onClick={onDismiss}>Kapat</Button>
      </ButtonGroup>
    )

    return (
      <Styled.FilterSettings>
        <Drawer
          isOpen={show}
          onClose={onDismiss}
          width="extended"
          actions={actions}
          title={title || 'Filtre ve hesaplamalar'}
          description="Aşağıdan widget'ınıza özel sorgularınızı ve ayarlandırmalarınızı yapabilirsiniz."
          position="right"
          // className="drawer--filter"
          isBlock={true}
        >
          {renderDrawerBody()}
        </Drawer>
      </Styled.FilterSettings>
    )
  }

  return renderDrawer()
}

export default FilterSettings
