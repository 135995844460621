import {
  PropertyMappingType,
  PropertyDefinition,
} from 'app/library/layout-builder'
import {
  AlignmentOneOf,
  CardListSizesDefault,
  ColumnSizesOneOf,
  ImageSizesOneOf,
  ReorderingOneOf,
  SlideshowArrows,
  SlideshowLazyload,
} from './defaults'

export function getMenuDefinitions(key: string, text: string) {
  return [
    {
      name: key,
      text,
      description: "İlgili Menü'yü Seçiniz",
      mapping: {
        type: PropertyMappingType.Menu,
      },
      group: {
        key,
        title: `${text} Ayarları`,
        withObject: false,
      },
    },
    {
      name: 'level',
      text: `${text} Derinliği`,
      description: "Menü'nün Gözükecek Alt Menü Derinliği",
      mapping: {
        type: PropertyMappingType.Object,
        oneOf: [
          {
            key: 0,
            text: 'Alt Menüleri Gizle',
          },
          {
            key: 1,
            text: '1. Derece',
          },
          {
            key: 2,
            text: '2. Derece',
          },
          {
            key: 3,
            text: '3. Derece',
          },
          {
            key: 4,
            text: '4. Derece',
          },
          {
            key: 5,
            text: '5. Derece',
          },
        ],
      },
      group: {
        key,
        title: `${text} Ayarları`,
        withObject: true,
      },
    },
  ]
}

export const PureMenuDefinitions: PropertyDefinition[] = [
  {
    name: 'id',
    text: 'Menü',
    description: "İlgili Menü'yü Seçiniz",
    mapping: {
      type: PropertyMappingType.Menu,
    },
  },
  {
    name: 'level',
    text: 'Menü Derinliği',
    description: "Menü'nün Gözükecek Alt Menü Derinliği",
    default: 2,
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: [
        {
          key: 0,
          text: 'Alt Menüleri Gizle',
        },
        {
          key: 1,
          text: '1. Derece',
        },
        {
          key: 2,
          text: '2. Derece',
        },
        {
          key: 3,
          text: '3. Derece',
        },
        {
          key: 4,
          text: '4. Derece',
        },
        {
          key: 5,
          text: '5. Derece',
        },
      ],
    },
  },
]

export const ImageChildrenDefinitions: PropertyDefinition[] = [
  {
    name: 'src',
    mapping: {
      type: PropertyMappingType.Image,
    },
    text: 'Görsel Seçin',
  },
  {
    name: 'alt',
    mapping: {
      type: PropertyMappingType.String,
    },
    text: 'Görsel Alt Etiketi',
  },
  {
    name: 'width',
    mapping: {
      type: PropertyMappingType.String,
    },
    text: 'Görsel Genişlik',
  },
  {
    name: 'height',
    mapping: {
      type: PropertyMappingType.String,
    },
    text: 'Görsel Yükseklik',
  },
  {
    name: 'srcset',
    mapping: {
      type: PropertyMappingType.SrcSet,
    },
    text: 'Responsive Görseller',
  },
  {
    name: 'lazyload',
    mapping: {
      type: PropertyMappingType.Boolean,
    },
    text: 'Görseli Görüntülendiğinde Yükle (Lazy Load)',
    default: true,
  },
]

export const ColumnReordersChildrenDefinition: PropertyDefinition[] = [
  {
    name: 'lg',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ReorderingOneOf,
    },
    text: 'Masaüstü Görünümü',
  },
  {
    name: 'md',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ReorderingOneOf,
    },
    text: 'Tablet Görünümü',
  },
  {
    name: 'sm',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ReorderingOneOf,
    },
    text: 'Küçük Tablet Görünümü',
  },
  {
    name: 'xs',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ReorderingOneOf,
    },
    text: 'Mobil Yan Görünüm',
  },
  {
    name: 'xl',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ReorderingOneOf,
    },
    text: 'Mobil Görünümü',
  },
]

export const ColumnReordersDefinition: PropertyDefinition[] = [
  {
    name: 'reorders',
    text: 'Sıralamalar',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ColumnReordersChildrenDefinition,
    },
    description: 'Sutünların cihaz boyutlarına göre sıralamasını ayarla',
    group: {
      key: 'column',
      title: 'Sutün Ayarları',
      withObject: true,
    },
  },
]

export const VideoDefinition: PropertyDefinition[] = [
  {
    name: 'title',
    mapping: {
      type: PropertyMappingType.String,
    },
    text: 'Başlık',
  },
  {
    name: 'subtitle',
    mapping: {
      type: PropertyMappingType.String,
    },
    text: 'Alt Başlık',
  },
  {
    name: 'category',
    mapping: {
      type: PropertyMappingType.String,
    },
    text: 'Video Kategorisi',
  },
  {
    name: 'description',
    mapping: {
      type: PropertyMappingType.Textarea,
    },
    text: 'Video Açıklaması',
  },
  {
    name: 'poster',
    mapping: {
      type: PropertyMappingType.Image,
    },
    initialFlowValue: true,
    text: 'Video Poster Görseli Seçin',
  },
  {
    name: 'formats',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: [
        {
          name: 'mp4',
          mapping: {
            type: PropertyMappingType.Video,
          },
        },
        {
          name: 'ogg',
          mapping: {
            type: PropertyMappingType.Video,
          },
        },
        {
          name: 'webm',
          mapping: {
            type: PropertyMappingType.Video,
          },
        },
      ],
    },
    description: 'Video Kaynakları',
    default: { mp4: '', ogg: '', webm: '' },
  },
  {
    name: 'height',
    mapping: {
      type: PropertyMappingType.Number,
    },
    text: 'Video Yüksekliği',
  },
  {
    name: 'controls',
    mapping: {
      type: PropertyMappingType.Boolean,
    },
    text: 'Oynat Düğmesini Gizle',
    default: true,
  },
  {
    name: 'playsInline',
    mapping: {
      type: PropertyMappingType.Boolean,
    },
    text: 'Satır İçi Oynat',
  },
  {
    name: 'autoPlay',
    mapping: {
      type: PropertyMappingType.Boolean,
    },
    text: 'Otomatik Oynat',
  },
  {
    name: 'muted',
    mapping: {
      type: PropertyMappingType.Boolean,
    },
    text: 'Sessiz Oynat',
  },
  {
    name: 'loop',
    mapping: {
      type: PropertyMappingType.Boolean,
    },
    text: 'Bitince Tekrar Et',
  },
  {
    name: 'filter',
    mapping: {
      type: PropertyMappingType.String,
    },
    text: 'Özel Filtre',
  },
]

export const ImageSizeDefinition: PropertyDefinition[] = [
  {
    name: 'imageSize',
    text: 'Kırpma Maske Boyutu',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ImageSizesOneOf,
    },
    default: 'md',
    description: 'Görsellerin liste üzerinde kırpılacağı boyutu ayarlayın.',
  },
]

export const ColumnSizes: PropertyDefinition[] = [
  {
    name: 'xl',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ColumnSizesOneOf,
    },
    text: 'Masaüstü Görünümü',
  },
  {
    name: 'lg',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ColumnSizesOneOf,
    },
    text: 'Büyük Tablet Görünümü',
  },
  {
    name: 'md',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ColumnSizesOneOf,
    },
    text: 'Küçük Tablet Görünümü',
  },
  {
    name: 'sm',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ColumnSizesOneOf,
    },
    text: 'Mobil Yan Görünüm',
  },
  {
    name: 'xs',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: ColumnSizesOneOf,
    },
    text: 'Mobil Görünümü',
  },
]

export const ColumnAlignments: PropertyDefinition[] = [
  {
    name: 'xl',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: AlignmentOneOf,
    },
    text: 'Masaüstü Görünümü',
  },
  {
    name: 'lg',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: AlignmentOneOf,
    },
    text: 'Büyük Tablet Görünümü',
  },
  {
    name: 'md',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: AlignmentOneOf,
    },
    text: 'Küçük Tablet Görünümü',
  },
  {
    name: 'sm',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: AlignmentOneOf,
    },
    text: 'Mobil Yan Görünüm',
  },
  {
    name: 'xs',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: AlignmentOneOf,
    },
    text: 'Mobil Görünümü',
  },
]
export const ColumnPropertyDefinitions: PropertyDefinition[] = [
  {
    name: 'sizes',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ColumnSizes,
    },
    text: 'Boyutlar',
    description: 'Sutün boyutlarını tüm cihaz boyutlarına göre yapılandırır.',
    default: { lg: '', xs: '', sm: '', md: '', xl: '' },
  },
  {
    name: 'reorders',
    text: 'Sıralamalar',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ColumnReordersChildrenDefinition,
    },
    description: 'Sutünların cihaz boyutlarına göre sıralamasını ayarlar.',
  },
  {
    name: 'offsets',
    text: 'Boşluklar',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ColumnSizes,
    },
    description:
      'Sutünların cihaz boyutlarına alacağı boşlukları yapılandırır.',
  },
  {
    name: 'alignments',
    text: 'Hizalandırmalar',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ColumnAlignments,
    },
    description: 'Sutünları belirli değerlere göre hizalandırır.',
  },
]

export const ProductPropertyDefinitions: PropertyDefinition[] = [
  {
    mapping: {
      type: PropertyMappingType.String,
    },
    name: 'id',
    text: 'Ürün ID',
  },
  {
    mapping: {
      type: PropertyMappingType.String,
    },
    name: 'title',
    text: 'Başlık',
  },
  {
    mapping: {
      type: PropertyMappingType.Textarea,
    },
    name: 'summary',
    text: 'Kısa Açıklama',
  },
  {
    name: 'image',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ImageChildrenDefinitions,
    },
    text: 'Ürün Görseli',
  },
  {
    name: 'contributor',
    mapping: {
      type: PropertyMappingType.Contributor,
    },
    text: 'Eser Katılımcı / Katılımcıları',
  },
  {
    name: 'pricing',
    mapping: {
      type: PropertyMappingType.Pricing,
    },
    text: 'Ürün Fiyatlandırması',
  },
  {
    name: 'label',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: [
        {
          key: '',
          text: 'Tanım Yok',
        },
        {
          key: 'new',
          text: 'Yeni Ürün',
        },
        {
          key: 'best-selling',
          text: 'Çok Satan',
        },
      ],
    },
    text: 'Ürün Tanımı',
  },
  {
    name: 'anchor',
    mapping: {
      type: PropertyMappingType.LinkWizard,
    },
    text: 'Ürün URL',
    default: null,
  },
]

export const ThemePropertyDefinitions: PropertyDefinition[] = [
  {
    mapping: {
      type: PropertyMappingType.String,
    },
    name: 'title',
    text: 'Başlık',
  },
  {
    name: 'anchor',
    mapping: {
      type: PropertyMappingType.LinkWizard,
    },
    text: 'Ürün URL',
    default: null,
  },
  {
    name: 'image',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ImageChildrenDefinitions,
    },
    text: 'Ürün Görseli',
  },
]

export const CardPropertyDefinitions: PropertyDefinition[] = [
  {
    mapping: {
      type: PropertyMappingType.String,
    },
    name: 'title',
    text: 'Başlık',
  },
  {
    mapping: {
      type: PropertyMappingType.Textarea,
    },
    name: 'description',
    text: 'Açıklama',
  },
  {
    name: 'anchor',
    mapping: {
      type: PropertyMappingType.LinkWizard,
    },
    description: 'Kart Url ',
    default: null,
  },
  {
    name: 'image',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ImageChildrenDefinitions,
    },
    description: 'Kart Resimleri',
  },
  {
    mapping: {
      type: PropertyMappingType.String,
    },
    name: 'reviews',
    text: 'İncelemeler',
  },
  {
    mapping: {
      type: PropertyMappingType.String,
    },
    name: 'score',
    text: 'Puan',
  },
  {
    name: 'substances',
    description: 'Maddeleri',
    mapping: {
      type: PropertyMappingType.ArrayData,
      keys: [
        {
          mapping: {
            type: PropertyMappingType.String,
          },
          name: 'caption',
          text: 'Başlık',
        },
        {
          mapping: {
            type: PropertyMappingType.String,
          },
          name: 'value',
          text: 'Değer',
        },
      ],
    },
  },
]

export const ColumnOffsetsDefinition: PropertyDefinition[] = [
  {
    name: 'offsets',
    text: 'Boşluklar',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ColumnSizes,
    },
    description: 'Sutünların cihaz boyutlarına alacağı boşlukları yapılandırır',
    group: {
      key: 'column',
      title: 'Sutün Ayarları',
      withObject: true,
    },
  },
]

export const ColumnSettingsDefinition: PropertyDefinition[] = [
  {
    name: 'sizes',
    mapping: {
      type: PropertyMappingType.ParentObject,
      children: ColumnSizes,
    },
    text: 'Boyutlar',
    description: 'Sutün boyutlarını tüm cihaz boyutlarına göre yapılandırır.',
    group: {
      key: 'column',
      title: 'Sutün Ayarları',
      withObject: true,
    },
    default: CardListSizesDefault,
  },
  ...ColumnOffsetsDefinition,
  ...ColumnReordersDefinition,
]

export const SlideshowDefinition: PropertyDefinition[] = [
  {
    name: 'arrows',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: SlideshowArrows,
    },
    default: 'ontop',
    text: 'Slayt Okları',
  },
  {
    name: 'lazyload',
    mapping: {
      type: PropertyMappingType.Object,
      oneOf: SlideshowLazyload,
    },
    default: 'ondemand',
    text: 'Lazyload',
  },
]

export const ImageCardDefinition: PropertyDefinition[] = [
  {
    name: 'title',
    mapping: {
      type: PropertyMappingType.String,
    },
    default: '',
    text: 'Başlık',
  },
  {
    name: 'image',
    mapping: {
      type: PropertyMappingType.Image,
    },
    default: '',
    text: 'Resim',
  },
  {
    name: 'anchor',
    mapping: {
      type: PropertyMappingType.LinkWizard,
    },
    default: '',
    text: 'Resim Link',
  },
]
