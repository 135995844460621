import React from 'react'
import Drawer from '@zera-admin/drawer'
import WidgetForm from '../widget-form'

import { WidgetDrawerProps } from './types'

const WidgetDrawer: React.FunctionComponent<WidgetDrawerProps> = ({
  id,
  isOpen,
  onDismiss,
  onSave,
  operation = 'create',
}) => {
  return (
    <Drawer
      isBlock={true}
      isOpen={isOpen}
      width="fullscreen"
      onClose={onDismiss}
      title={
        operation === 'create' ? 'Hızlı widget oluştur' : 'Hızlı widget düzenle'
      }
      description="Aşağıdan hızlı bir widget oluşturabilirsiniz veya düzenleyebilirsiniz."
    >
      <WidgetForm
        id={id}
        operation={operation}
        onSave={onSave}
        onComplete={onDismiss}
        type="other"
      />
    </Drawer>
  )
}

export default WidgetDrawer
