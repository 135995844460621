import React, { useEffect, useState } from 'react'
import { Column, Row } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'

import { DefinitionTag } from 'services/http/backoffice/definition-tag'
import definitionService from 'services/http/backoffice/definition'

import { DefinitionTagFormProps } from './types'

const DefinitionTagForm: React.FunctionComponent<DefinitionTagFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [definitions, setDefinitions] = useState<SearchboxOptionProps[]>([])

  const form = useForm<DefinitionTag>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
  })

  const getDefinitions = async (value?: string) => {
    setIsLoading(true)

    const response = await definitionService.getDefinition(value)
    const mapped = response.data?.map((definition) => ({
      label: definition.definitionValue || '',
      value: definition.definitionId,
    }))

    setDefinitions(mapped)
    setIsLoading(false)

    return mapped
  }

  const handleSubmit = (props: FormHookSubmitProps<DefinitionTag>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  useEffect(() => {
    getDefinitions()
  }, [])

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column xs={{ size: 6 }}>
            <Input
              name="name"
              label="Tanımlama etiketi"
              value={form.values.name}
              onChange={form.handleChange}
              placeholder="ÖrnekMetin"
            />
          </Column>
          <Column xs={{ size: 8 }}>
            <SearchBox
              description="Geçerli bir tanımlama listesi seçin"
              isMultiple
              label="Tanımlama etiketi listesi seçiniz"
              name="definitionIds"
              onFetchOptions={(value) => {
                return getDefinitions(value)
              }}
              value={form.values.definitionIds}
              onChange={(props) =>
                form.handleFieldChange(
                  'definitionIds',
                  props as SearchboxOptionProps
                )
              }
              options={definitions}
              isLoading={isLoading}
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default DefinitionTagForm
