import React, { useEffect, useState } from 'react'
import Button, { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Form, { useForm } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Icon from '@zera-admin/icon'
import Lozenge from '@zera-admin/lozenge'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import crawlTaskService from 'services/http/report/crawl-task'
import { FilterProps } from 'services/http/report/crawl-task/types'

import { PersonReportQueListProps } from './types'

const PersonReportQueList: React.FunctionComponent<
  PersonReportQueListProps
> = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [crawlTask, setCrawlTask] = useState<any>()
  const urlSearchParams = new URLSearchParams(location.search)

  const form = useForm<FilterProps>({
    onSubmit: (props) => handleFilter(props),
  })

  const handleFilter = ({ values }: any) => {
    getPersonReports(0, values.userName, values.status)
  }

  useEffect(() => {
    getPersonReports(parseInt(urlSearchParams.get('pageIndex') || '0'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPersonReports = async (
    pageIndex: number,
    userName?: string,
    status?: string
  ) => {
    setIsLoading(true)
    try {
      const response = await crawlTaskService.getAllGroup(
        pageIndex - 1,
        userName,
        status
      )
      setCrawlTask(response.data)
    } catch (err) {
      const ex = err
      console.log(ex)
    } finally {
      setIsLoading(false)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/person-report/create">
        Kişi raporu oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Kişi raporu" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm kişi raporlarını görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Kişi raporu
      </PageHeader>
    )
  }

  const status: SelectOptionProps[] = [
    {
      label: 'Kuyruğa alındı',
      value: 'Pending',
    },
    {
      label: 'Rapor işleniyor',
      value: 'Processing',
    },
    {
      label: 'Tamamlandı',
      value: 'Completed',
    },
  ]

  const renderPageFilter = () => {
    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <Row xs={{ align: 'center' }}>
            <Column>
              <Input
                description="Geçerli bir kullanıcı adı giriniz"
                label="Kullanıcı adı"
                name="userName"
                placeholder="Örnek metin"
                value={form.values.userName}
                onChange={form.handleChange}
              />
            </Column>
            <Column>
              <Select
                description="Geçerli bir durum seçimi yapınız"
                label="Durumu"
                name="status"
                options={status}
                placeholder="Seçim yapın"
                value={status?.find(
                  (status) => status?.value === form.values.status?.value
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'status',
                    (props as SelectOptionProps).value
                  )
                }
              />
            </Column>
            <Column>
              <Button
                appearance="primary"
                children="Arama yap"
                iconBefore={<Icon name="search" />}
                type="submit"
              />
              <Button
                appearance="error"
                children="Filtreyi temizle"
                iconBefore={<Icon name="trash" />}
                style={{ marginLeft: 20 }}
                type="button"
              />
            </Column>
          </Row>
        </Form>
      </React.Fragment>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'userName',
        text: 'Kullanıcı adı',
      },
      {
        name: 'createdAtStr',
        text: 'Rapor istek tarihi',
        render: (props: any) => {
          return new Date(props.createdAt).toLocaleString()
        },
      },
      {
        name: 'crawlingEndedAt',
        text: 'Bitiş tarihi',
        render: (props: any) => {
          if (!props.crawlingEndedAt) {
            return '-'
          } else {
            return new Date(props.crawlingEndedAt).toLocaleString()
          }
        },
      },
      {
        name: 'status',
        text: 'Durumu',
        render: (props: any) => {
          if (props.status === 'Completed') {
            return (
              <Lozenge appearance="success">
                Tamamlandı {props.progressPercentage}%
              </Lozenge>
            )
          } else if (props.status === 'Pending') {
            return <Lozenge appearance="warning">Kuyruğa alındı</Lozenge>
          }
          return (
            <Lozenge appearance="info">
              Rapor işleniyor {props.progressPercentage.toFixed(2)}%
            </Lozenge>
          )
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        render: (item: any) => {
          return (
            <React.Fragment>
              {item.status !== 'Pending' && (
                <React.Fragment>
                  <LinkButton
                    appearance="subtle-link"
                    href={
                      item.status === 'Completed'
                        ? `/person-report/detail/${item.groupId}`
                        : `/person-report/process/detail/${item.groupId}`
                    }
                  >
                    Görüntüle
                  </LinkButton>
                  <LinkButton
                    appearance="subtle-link"
                    href={`/report/${item.groupId}`}
                  >
                    Raporla
                  </LinkButton>
                  <LinkButton
                    appearance="subtle-link"
                    href={`/person-report/pdf-report/${item.groupId}`}
                  >
                    Pdf
                  </LinkButton>
                </React.Fragment>
              )}
            </React.Fragment>
          )
        },
      },
    ]

    if (crawlTask) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={crawlTask.data}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={crawlTask.pageCount} onChange={getPersonReports} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageFilter()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default PersonReportQueList
