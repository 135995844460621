import {
  CountryPerceptionResponse,
  CountryPerceptionsResponse,
  CreateCountryPerceptionResponse,
} from './types'

export const mapCountryPerceptions = (
  raw: any
): CountryPerceptionsResponse => ({
  data: raw.data,
  pageCount: raw.pageCount,
  dataCount: raw.dataCount,
})

export const mapCreateCountryPerception = (
  raw: any
): CreateCountryPerceptionResponse => ({
  customValue: raw.customValue,
  fromCountry: raw.fromCountry,
  toCountry: raw.toCountry,
  description: raw.description,
  sendAsAlarm: raw.sendAsAlarm,
})
export const mapCountryPerception = (raw: any): CountryPerceptionResponse => ({
  statusCode: raw.statusCode,
  data: raw.data,
  pageCount: raw.pageCount,
  dataCount: raw.dataCount,
})
