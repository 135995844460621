import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Page, { Container, PageHeader, Row } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import MainLayout from 'layouts/main'
import mobileReportService from 'services/http/report/agenda-report'
import { MobileReportProps, ReadOnlyURLSearchParams } from './types'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import { PdfContainer } from './components/report-card'
import ReportTitle from 'components/report-title'
import ReportCardGeneralData from './components/report-card-general-data'
import MostTweetedLocation from './components/most-tweeted-location'
import PositiveCountriesReport from './components/report-positive-countries'
import NegativeCountriesReport from './components/report-negative-countries'
import WordCloudReport from './components/word-cloud'
import TalkListReport from './components/report-talk-list'
import SentimentAnalysis from './components/report-sentiment-analysis'
import ReportCardTwitter from './components/report-card-twitter'
import AliceLogo from 'assets/images/alice-project.svg'
import DailyReportLogo from 'assets/images/daily-report.svg'
import ReportsInformation from './components/reports-information'
import ReportInfo from './components/report-info'
import ReportHashtags from './components/report-hashtags'
import ReportSubjects from './components/report-subjects'

const MobileReport: React.FunctionComponent<MobileReportProps> = ({
  location,
}) => {
  const [mobileReport, setMobileReport] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false)
  const [pdfInputValue, setPdfInputValue] = useState('')
  const searchParams = new URLSearchParams(
    location?.search
  ) as ReadOnlyURLSearchParams

  const DailyReport = useCallback(() => {
    mobileReportService
      .DailyReport(2, searchParams.get('Override') === '1')
      .then((res) => {
        setMobileReport(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    DailyReport()
  }, [DailyReport])

  const handleDownloadPdf = () => {
    setIsPdfLoading(true)

    let input = document.getElementById('printThis')!

    html2canvas(input, {
      scale: 1.5,
      useCORS: true,
    })
      .then((canvas) => {
        var imgData = canvas.toDataURL('image/jpeg')

        var pdf = new jsPDF('p', 'in', [6, 100], true)

        if (mobileReport?.negativePersonalitiesAndInteractions.length) {
          pdf = new jsPDF('p', 'in', [6, 100], true)
        }
        var imgProps = pdf.getImageProperties(imgData)
        var pdfWidth = pdf.internal.pageSize.getWidth()
        var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

        const date = new Date()
          .toLocaleDateString('tr-TR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
          .replaceAll(':', '_')

        pdf.save(`Yonetici_raporu-${date}.pdf`.replaceAll(' ', '_'))

        const fileName = `Yonetici_raporu-${date}`.replaceAll(' ', '_')
        setPdfInputValue(fileName)
      })
      .catch((e) => console.log('error', e))
      .finally(() => {
        setIsPdfLoading(false)
      })
  }

  const mobileReportData = [
    {
      title: 'RAPORLAR',
      children: [
        {
          title: 'Bilgilendirme',
          component: (
            <ReportsInformation
              data={mobileReport?.generalInfo}
              title="Genel Veriler İle İgili Bilgilendirme "
            />
          ),
        },
        {
          title: 'Genel Veriler İle İlgili Bilgilendirme',
          component: <ReportCardGeneralData data={mobileReport?.generalInfo} />,
        },
        {
          title: 'Bilgilendirme',
          component: (
            <ReportInfo
              data={mobileReport?.mostTweetedLocationsInfo}
              title="En Çok Post Atılan Toplam Lokasyonu Tespit Edilmiş Kullanıcı Sayısı İle İlgili Bilgilendirme "
            />
          ),
        },
        {
          title:
            'En Çok Post Atılan Toplam Lokasyonu Tespit Edilmiş Kullanıcı Sayısı',
          component: (
            <MostTweetedLocation data={mobileReport?.mostTweetedLocations} />
          ),
        },
      ],
    },
    {
      title: 'KONULAR/OLAYLAR',
      children: [
        {
          title: 'Konular/Olaylar İle İlgili Bilgilendirme',
          component: (
            <ReportInfo
              data={
                mobileReport?.topicsInfo.includes('tweet')
                  ? mobileReport?.topicsInfo.replace('tweet', 'post')
                  : mobileReport?.topicsInfo
              }
              title="Konular/Olaylar İle İlgili Bilgilendirme"
            />
          ),
        },
        {
          title: 'Konular',
          component: <ReportSubjects data={mobileReport?.topics} />,
        },
        {
          title: 'Hashtagler',
          component: <ReportHashtags data={mobileReport?.hashtags} />,
        },
      ],
    },
    {
      title: 'OLUMLU/OLUMSUZ İÇERİK VE ETKİLEŞİM',
      children: [
        {
          title: 'Olumlu İçerikler ve Etkileşim',
          component: mobileReport?.positivePersonalitiesAndInteractions
            .length ? (
            <ReportCardTwitter
              data={mobileReport?.positivePersonalitiesAndInteractions}
              title="Olumlu İçerikler ve Etkileşim"
            />
          ) : null,
        },
        {
          title: 'Olumsuz İçerikler ve Etkileşim',
          component: mobileReport?.negativePersonalitiesAndInteractions
            .length ? (
            <ReportCardTwitter
              data={mobileReport?.negativePersonalitiesAndInteractions}
              title="Olumsuz İçerikler ve Etkileşim"
            />
          ) : null,
        },
        {
          title: 'Kelime Bulutu',
          component: mobileReport?.wordClouds.length ? (
            <WordCloudReport data={mobileReport?.wordClouds} />
          ) : null,
        },
      ],
    },
    {
      title: 'SENTİMENT ANALİZİ',
      children: [
        {
          title: 'Sentiment Analizi İle İlgili Bilgilendirme',
          component: (
            <ReportInfo
              data={mobileReport?.sentimentAnalysisInfo}
              title="Sentiment Analizi İle İlgili Bilgilendirme"
            />
          ),
        },
        {
          title: 'Sentiment Analizi',
          component: mobileReport?.sentimentAnalysis.length ? (
            <SentimentAnalysis
              data={mobileReport?.sentimentAnalysis}
              chartHeight="400px"
            />
          ) : null,
        },
      ],
    },
    {
      title: 'GÜNLÜK EN ÇOK KONUŞULANLAR LİSTESİ',
      children: [
        {
          title: 'X En Çok Konuşulanlar Listesi',
          component: <TalkListReport data={mobileReport?.mostTalkedAbouts} />,
        },
      ],
    },
    {
      title: 'ÜLKELER',
      children: [
        {
          title: 'Bilgilendirme',
          component: (
            <ReportInfo
              data={mobileReport?.positiveCountriesInfo}
              title="Olumlu Ülkeler İle İlgili  Bilgilendirme"
            />
          ),
        },
        {
          title: 'Olumlu Ülkeler',
          component: (
            <PositiveCountriesReport data={mobileReport?.positiveCountries} />
          ),
        },
        {
          title: 'Bilgilendirme',
          component: mobileReport?.negativeCountriesInfo.length ? (
            <ReportInfo
              data={mobileReport?.negativeCountriesInfo}
              title="Olumsuz Ülkeler İle İlgili Bilgilendirme"
            />
          ) : null,
        },
        {
          title: 'Olumsuz Ülkeler',
          component: mobileReport?.negativeCountries.length ? (
            <NegativeCountriesReport data={mobileReport?.negativeCountries} />
          ) : null,
        },
      ],
    },
  ]

  const reports = mobileReportData.map((report) => {
    return (
      <>
        <ReportTitle
          children=""
          title={report.title}
          linearGradient={true}
          backgroundColor="#a26bd4"
          mainBackgroundColor="#fafafa"
          color="#202020"
          width="500px"
          height="80px"
          fontSize="26px"
          key={Math.random()}
        />
        {report.children.map((asd) => (
          <div key={Math.random()}>{asd.component}</div>
        ))}
      </>
    )
  })

  return isLoading || isPdfLoading ? (
    <LoadingView>
      <Spinner size="large" />
      <h5>{isPdfLoading ? 'PDF Hazırlanıyor...' : 'Rapor yükleniyor...'}</h5>
    </LoadingView>
  ) : (
    <MainLayout isLoading={isLoading}>
      <Container width="medium">
        <Page>
          <PageHeader
            actions={
              <>
                <input id="pdf-date" type="hidden" value={pdfInputValue} />
                <Button
                  id="pdf-button"
                  iconBefore={<Icon name="download" size="small" />}
                  onClick={() => handleDownloadPdf()}
                >
                  PDF olarak indir
                </Button>
              </>
            }
          >
            Gündem Mobil Raporu
          </PageHeader>

          <Row xs={{ direction: 'column', align: 'center' }}>
            <PdfContainer id="printThis" className="fa-Page">
              <div
                style={{ height: '150%', width: '100%', textAlign: 'right' }}
              >
                <b>{new Date().toLocaleString()} </b>
              </div>
              <div className="pdf-title">
                <img src={AliceLogo} alt="Alice Project" />
                <img src={DailyReportLogo} alt="Alice Project" />
              </div>
              {reports}
            </PdfContainer>
          </Row>
        </Page>
      </Container>
    </MainLayout>
  )
}
export default MobileReport
