import { ConditionBuilderValue } from '@zera-admin/condition-builder'

import { Widget } from 'services/http/bi-tool/widget'
import { Query } from 'services/http/bi-tool/query'
import { QueryInterval } from 'services/http/bi-tool/query/types'

export const query = (
  widget: Widget,
  type: 'dashboard' | 'local',
  dashboard?: Query,
  filter?: Query
) => {
  const query = filter ? filter : widget.query
  const where = {
    type: 'group',
    condition: 'and',
    ...widget?.query?.where,
  } as ConditionBuilderValue
  let interval = widget?.query?.interval as QueryInterval
  let raw = query?.raw
  let functions = raw?.functions || []

  if (widget.type === 'raw') {
    if (type === 'dashboard') {
      where.rules = dashboard?.where?.rules || []
    }

    if (raw && dashboard?.raw?.functions) {
      functions = [...functions, ...dashboard.raw.functions]
    }
  } else {
    if (where.rules?.length || query?.where?.rules?.length) {
      where.rules = [...(where.rules || []), ...(query?.where?.rules || [])]
    }

    if (type === 'dashboard') {
      where.rules = [
        ...(where?.rules || []),
        ...(dashboard?.where?.rules || []),
      ]
    }
  }

  return {
    ...query,
    raw: {
      ...raw,
      functions,
    },
    criteria: query?.criteria,
    where,
    interval,
  }
}
