import styled from 'styled-components'

export const HotelDefinitions = styled.div`
  margin-top: 12px;
  .css-5a7vsu-container {
    width: 100%;
  }

  div[role='tabpanel'] {
    & > div {
      width: 100%;
    }
  }
`

export const HotelDefinitionField = styled.div`
  width: 100%;

  .button--settings {
    margin-top: 30px;
    height: 40px;
  }
`
