import React from 'react'
import { QuoraCardProps } from './types'
import defaultUserProfile from './images/default-user.png'
import verified from './images/verified.png'
import * as Styled from './QuoraCard.styled'
import logo from './images/logo.png'
import up from './images/up.svg'
import down from './images/down.svg'
import comment from './images/comment.svg'
import share from './images/share.svg'
import defaultImage from './images/default.png'

const QuoraCard: React.FC<QuoraCardProps> = ({ data, editmode, onChange }) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  const toLocaleDate = (date: string) => {
    // ISO tarihini alalım
    const isoDate = date // Örnek ISO formatlı tarih

    // ISO tarihini Date nesnesine dönüştürelim
    const dateObject = new Date(isoDate)

    // Tarihi Türkçe formatında yerel tarihe çevirip yazdıralım
    const localDate = dateObject.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    return localDate
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultImage)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfile)
  }

  const textLength = data?.Text && data?.Text?.length >= 150

  return (
    <Styled.QuoraCardWrapper>
      <div className="post">
        <div className="post-header">
          <div className="post-header-profile-picture">
            <img
              src={defaultUserProfile}
              onError={hasErrorImageProfil}
              alt="Quora Profile"
            />
          </div>
          <div className="post-header-info">
            <div className="post-header-info-name">
              {data?.PageName ? (
                <a href={data?.PageProfileUrl} target="_blank" rel="noreferrer">
                  {data?.PageName}
                </a>
              ) : (
                <a href={data?.UserProfileUrl} target="_blank" rel="noreferrer">
                  {data?.UserFullName}
                </a>
              )}
            </div>
            <div className="post-header-info-details">
              {data?.ViewsCount && (
                <>
                  <span>{data?.ViewsCount} görüntülenme</span>
                  <span>·</span>
                </>
              )}
              <span>{toLocaleDate(data?.CreatedAt as string)}</span>
            </div>
          </div>
        </div>
        <div className="post-body">
          <div className="post-body-description">
            <div
              contentEditable={editmode}
              onBlur={(e) => handleBlurElement(e, 'Text')}
            >
              {textLength && !isOpen && !editmode
                ? `${data?.Text?.substring(0, 150)}...`
                : data?.Text}
            </div>
            {textLength && !editmode && (
              <button onClick={() => setIsOpen(!isOpen)} className="read-more">
                {isOpen ? 'Daha Az' : 'Daha Fazla'}
              </button>
            )}
          </div>
          {data?.MediaUrl && (
            <div className="post-body-image">
              <img src={data?.MediaUrl} alt="media" onError={hasErrorImage} />
            </div>
          )}

          <div className="post-body-stats">
            <span>
              <img src={up} alt="Up" />
              {data?.UpvotesCount}
              <img src={down} alt="" />
            </span>
            <span>
              <img src={comment} alt="Comment" />
              {data?.CommentCount}
            </span>
            <span>
              <img src={share} alt="Share" />
              {data?.ShareCount}
            </span>
          </div>
        </div>

        <div className="post-footer">
          <div className="post-footer-info">
            <img src={logo} alt="Quora Logo" />
          </div>
          <div className="post-footer-url quora-red">
            <a href={data?.PostUrl} target="_blank" rel="noreferrer">
              Post'u görüntüle
            </a>
          </div>
        </div>
      </div>
    </Styled.QuoraCardWrapper>
  )
}

export default QuoraCard
