import NameValue from './NameValue'
import * as Styled from './NameValues.styled'
import { NameValuesProps } from './types'

const NameValues: React.FunctionComponent<NameValuesProps> = ({
  data = [],
}) => {
  return (
    <Styled.NameValues>
      {data.map((value, index) => (
        <NameValue key={index} {...value} />
      ))}
    </Styled.NameValues>
  )
}

export default NameValues
