import ReactCodeMirror from '@uiw/react-codemirror'
import { autocompletion } from '@codemirror/autocomplete'
import { syntaxTree } from '@codemirror/language'
import { AlarmCodeEditorProps } from './types'

const AlarmCodeEditor: React.FunctionComponent<AlarmCodeEditorProps> = ({
  value,
  onChange,
  fncAutoCompletionData,
  msgAutoCompletionData,
}) => {
  function myCompletions(context: any) {
    let before = context.matchBefore(/\w+/)

    const text = context?.state?.sliceDoc()
    const searchMsg = text.match(/\$/g)
    const searchFnc = text.match(/\$\@/g)
    const searchFnc2 = text.match(/\@\$/g)
    const searchFnc2Length = searchFnc2?.length || 0
    if (searchFnc?.length > searchFnc2Length) {
      return {
        from: before ? before.from : context.pos,
        options: fncAutoCompletionData,
        validFor: /^($\@\w*)?$/,
      }
    } else if (searchMsg?.length % 2 === 1 && !context.explicit) {
      return {
        from: before ? before.from : context.pos,
        options: msgAutoCompletionData,
        validFor: /^($\w*)?$/,
      }
    }

    return null
  }

  return (
    <ReactCodeMirror
      value={value}
      height="300px"
      theme="light"
      extensions={[autocompletion({ override: [myCompletions] })]}
      onChange={(data) => {
        onChange(data?.replaceAll('$$', '$').replaceAll('$@$@', '$@'))
      }}
    />
  )
}
export default AlarmCodeEditor
