import React from 'react'
import { Column, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'

import { FilterTabProps } from '../types'

const AddressTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
}) => {
  const renderAddressTab = () => {
    return (
      <Row xs={{ direction: 'column' }}>
        <Column xs={{ size: 12 }}>
          <p>
            Aşağıdan özel HTTP servis URL'inizi girebilir ve
            ayarlandırmalarınızı yapabilirsiniz.
          </p>
        </Column>
        <Column>
          <Input
            name="url"
            label="HTTP Servis Adresi"
            placeholder="http://test-api.test.com/custom/service"
            value={values.http?.url || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChange({
                ...values,
                http: {
                  ...values.http,
                  url: event.target.value,
                },
              })
            }
          />
        </Column>
      </Row>
    )
  }

  return renderAddressTab()
}

export default AddressTab
