import React from 'react'
import * as Styled from './WidgetLayout.styled'
import { IWidgetLayoutProps, ICountryFlag } from './types'
import aliceProjectLogo from './logo/alice-project-logo.png'
import countryFlags from '../countryFlags.json'

const WidgetLayout: React.FC<IWidgetLayoutProps> = ({
  asideTitle,
  asideDate,
  children,
  flagShortCode,
  pageNumber,
  onChange,
  editMode,
}) => {
  let selectFlag: ICountryFlag | undefined | null = {}

  if (flagShortCode) {
    selectFlag = countryFlags.find(
      (country) => country.country_code_3 === flagShortCode
    )
  }

  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  return (
    <React.Fragment>
      <Styled.MainContainer>
        <Styled.AsideBar>
          <div className="aside-bar-header">
            <h1
              className="aside-bar-input"
              contentEditable={editMode}
              onBlur={(e) => handleBlurElement(e, 'title')}
            >
              {asideTitle}
            </h1>

            <span className="aside-bar-header-date">{asideDate}</span>
          </div>

          <div className="aside-bar-footer">
            <div className="aside-bar-footer-flag">
              {flagShortCode && selectFlag && (
                <img src={selectFlag.flag_url} alt="CountryFlag" />
              )}
              {selectFlag?.country_name !== '' &&
                selectFlag?.country_name !== null && (
                  <span>{selectFlag?.country_name}</span>
                )}
            </div>
            <div className="aside-bar-footer-logos">
              <img src={aliceProjectLogo} alt="Alice Project Logo" />
            </div>
          </div>
          {editMode && (
            <div
              contentEditable={editMode}
              onBlur={(e) => handleBlurElement(e, 'pageNumber')}
              className="page-count"
            >
              {pageNumber}
            </div>
          )}
        </Styled.AsideBar>
        {children}
      </Styled.MainContainer>
    </React.Fragment>
  )
}

export default WidgetLayout
