import React from 'react'
import * as Styled from './MastodonCard.styled'
import { MastodonCardProps } from './types'
import logo from './images/logo2.svg'
import boost from './images/boost.svg'
import fav from './images/fav.svg'
import reply from './images/reply.svg'
import defaultUserProfile from './images/default-user.png'
import defaultImage from './images/default.jpg'

const MastodonCard: React.FC<MastodonCardProps> = ({
  data,
  editmode,
  onChange,
}) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  const toLocaleDate = (date: string) => {
    // ISO tarihini alalım
    const isoDate = date // Örnek ISO formatlı tarih

    // ISO tarihini Date nesnesine dönüştürelim
    const dateObject = new Date(isoDate)

    // Tarihi Türkçe formatında yerel tarihe çevirip yazdıralım
    const localDate = dateObject.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    return localDate
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultImage)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfile)
  }

  const textLength = data?.Text && data?.Text?.length >= 400

  return (
    <Styled.MastodonCardWrapper>
      <div className="post">
        <div className="post-header">
          <div className="post-header-profile">
            <div className="post-header-profile-picture">
              <img
                src={data?.UserProfileImageUrl || defaultUserProfile}
                onError={hasErrorImageProfil}
                alt="profile"
              />
            </div>
            <div className="post-header-profile-info">
              <div className="post-header-profile-info-name">
                <span>{data?.UserInstance}</span>
              </div>
              <div className="post-header-profile-info-username">
                <span>@{data?.UserScreenName}</span>
              </div>
            </div>
          </div>
          <div className="post-header-profile-info-date">
            <span>{toLocaleDate(data?.CreatedAt as string)}</span>
          </div>
        </div>
        <div className="post-body">
          <div className="post-body-text">
            <div
              contentEditable={editmode}
              onBlur={(e) => handleBlurElement(e, 'Text')}
            >
              {textLength && !isOpen && !editmode ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${data?.Text?.substring(0, 150)}...`,
                  }}
                />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: data?.Text || '' }} />
              )}
            </div>
            {textLength && !editmode && (
              <button onClick={() => setIsOpen(!isOpen)} className="read-more">
                {isOpen ? 'Daha Az' : 'Daha Fazla'}
              </button>
            )}
          </div>
          <div className="post-body-image">
            <img
              src={data?.ImageUrl || defaultImage}
              onError={hasErrorImage}
              alt="post"
            />
          </div>

          <div className="post-body-actions">
            <div className="post-body-actions-reply">
              <img src={reply} alt="reply" />
              <span>{data?.RepliesCount}</span>
            </div>
            <div className="post-body-actions-boost">
              <img src={boost} alt="boost" />
              <span>{data?.ReblogsCount}</span>
            </div>
            <div className="post-body-actions-fav">
              <img src={fav} alt="fav" />
              <span>{data?.FavoritesCount}</span>
            </div>
          </div>
        </div>

        <div className="post-footer">
          <div className="post-footer-logo ">
            <img src={logo} alt="logo" />
          </div>
          <div className="post-footer-url mastodon-purple">
            <a href={data?.Uri} target="_blank" rel="noreferrer">
              Post'u görüntüle
            </a>
          </div>
        </div>
      </div>
    </Styled.MastodonCardWrapper>
  )
}

export default MastodonCard
