import React, { useState } from 'react'
import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Spinner from '@zera-admin/spinner'
import { validation } from 'app/functions'
import TwitterProfile from 'components/twitter-profile'
import { ProfileReport } from 'services/http/report/person-report/types'
import crawlTask from 'services/http/report/crawl-task'
import { ProfileReportRequestFormProps } from './types'
import Select, { SelectOptionProps } from '@zera-admin/select'

const validations = {
  userScreenName: yup
    .string()
    .required('Raporu oluşturulacak kişinin adını giriniz'),
}

const PersonReportRequestForm: React.FunctionComponent<ProfileReportRequestFormProps> =
  ({ actions = [], onError, onSubmit, values }) => {
    const form = useForm<ProfileReport>({
      initialValues: values,
      onSubmit: (props) => handleSubmit(props),
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })
    const [verifiedUser, setVerifiedUser] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = (props: FormHookSubmitProps<ProfileReport>) => {
      if (!props.errors) {
        if (onSubmit) {
          onSubmit(props.values, () => {
            if (props.handleReset) {
              props.handleReset()
            }
          })
        }
      } else {
        if (onError) {
          onError(props.errors)
        }
      }
    }

    const searchUsername = (
      userScreenName?: string,
      userEntityKey?: string
    ) => {
      setIsLoading(true)
      crawlTask
        .searchProfileName(userScreenName, userEntityKey)
        .then((response) => {
          setIsLoading(false)
          setVerifiedUser(true)
        })
    }

    // const linkedinValue = () => {
    //   if (form.values.selectedEvents) {
    //     return {
    //       label:
    //         ([
    //           {
    //             label: 'Kişinin takip ettiği hesaplar',
    //             value: 'fetch-friend-ids',
    //           },
    //         ].find(
    //           (event) => event.value === form.values.selectedEvents
    //         )?.label as string) || form.values.selectedEvents,
    //       value: form.values.selectedEvents,
    //     }
    //   }
    //   return null
    // }
    const subDetails = () => {
      const details = []

      if (verifiedUser === true) {
        details.push(<h3 style={{ color: 'green' }}>Kullanıcı Bulundu</h3>)
      }

      return details
    }

    return (
      <Form onSubmit={form.handleSubmit}>
        <FormSection
          title="Genel bilgiler"
          description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
        >
          <Row>
            <Column xs={{ size: 9 }}>
              <Input
                description="Geçerli bir X kullanıcı adı giriniz"
                errorMessage={form.errors.userScreenName}
                label="X kullanıcı adı giriniz"
                name="userScreenName"
                placeholder="GLOMIL"
                value={form.values.userScreenName}
                onChange={(ctx) => {
                  setVerifiedUser(false)
                  form.handleChange(ctx)
                }}
              />
            </Column>
            <Column xs={{ size: 9 }}>
              <Input
                description="Geçerli bir X kullanıcı keyi giriniz"
                errorMessage={form.errors.userEntityKey}
                label="X kullanıcı keyi giriniz"
                name="userEntityKey"
                placeholder="GLOMIL"
                value={form.values.userEntityKey}
                onChange={(ctx) => {
                  setVerifiedUser(false)
                  form.handleChange(ctx)
                }}
              />
            </Column>
            <TwitterProfile data={null} />
            <Column xs={{ size: 3, align: 'center' }}>
              <Button
                onClick={() =>
                  searchUsername(
                    form.values.userScreenName || '',
                    form.values.userEntityKey || ''
                  )
                }
                isLoading={isLoading}
              >
                Profil Doğrula
              </Button>
            </Column>
            <Column xs={{ size: 12 }}>{subDetails()}</Column>

            {!isLoading ? (
              verifiedUser && (
                <>
                  <hr style={{ width: '100%' }} color="#cecece" />

                  <Column xs={{ size: 6 }}>
                    <Input
                      label="Rapor Adı Giriniz"
                      name="reportName"
                      placeholder="GLOMIL"
                      value={form.values.reportName}
                      onChange={form.handleChange}
                    />
                  </Column>

                  <Column xs={{ size: 6 }}>
                    <Select
                      isMultiple
                      label="Eklenecek event seçiniz"
                      name="selectedEvents"
                      options={[
                        {
                          label: 'Kişinin takip ettiği hesaplar',
                          value: 'fetch-friend-ids',
                        },
                      ]}
                      // value={linkedinValue()}
                      onChange={(props) => {
                        form.handleFieldChange(
                          'selectedEvents',
                          (props as SelectOptionProps[]).map(
                            (event) => event.value
                          )
                        )
                      }}
                      placeholder="Arama yapın"
                      isLoading={isLoading}
                    />
                  </Column>
                  <Column xs={{ size: 6 }}>
                    <Input
                      label="Toplam Arkadaş Sayısı"
                      name="totalFriendsCount"
                      placeholder="GLOMIL"
                      value={form.values.totalFriendsCount}
                      onChange={form.handleChange}
                    />
                  </Column>
                </>
              )
            ) : (
              <Spinner />
            )}
          </Row>
        </FormSection>
        {!isLoading && <FormFooter>{verifiedUser && actions}</FormFooter>}
      </Form>
    )
  }

export default PersonReportRequestForm
