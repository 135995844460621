import * as Styled from './TweetCard.style'
import defaultUserProfil from './images/default-user.png'
import defaultPostImage from './images/logo-black.png'
import xLogo from './images/x-logo.svg'
import openLink from './images/open-link.svg'
import { ITweetCard } from './types'
import { numberFormat } from 'app/functions/number'
export default function TweetCard({
  multiple,
  data,
  custom,
  editMode,
  onChange,
}: ITweetCard) {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData, data?.EntityKey || '')
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultPostImage)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfil)
  }

  return (
    <Styled.AgendaTweetCard multiple={multiple}>
      <div className="agenda-tweet-header">
        <div className="agenda-tweet-header-profile-image">
          {data?.UserProfilePictureUrl ? (
            <img
              src={data?.UserProfilePictureUrl || ''}
              onError={hasErrorImageProfil}
              alt={data?.UserName}
            />
          ) : (
            <img src={defaultUserProfil} alt="" />
          )}
        </div>
        <div className="agenda-tweet-header-profile-des">
          <span className="agenda-tweet-header-profile-des-name">
            {data?.UserName}
          </span>
          <span className="agenda-tweet-header-profile-des-link">
            @{data?.UserScreenName}
          </span>
        </div>
      </div>
      <div className={`agenda-tweet-body ${multiple && 'multiple-body'}`}>
        {multiple && data?.MediaUrl && (
          <div className="agenda-tweet-body-post-image">
            <img
              src={data?.MediaUrl || ''}
              onError={hasErrorImage}
              alt="Post Logo"
            />
          </div>
        )}
        <div className="agenda-tweet-body-content">
          <p
            contentEditable={editMode}
            onBlur={(e) => handleBlurElement(e, 'Text')}
            className="agenda-tweet-body-post-text"
          >
            {data?.Text}
          </p>
          {(multiple === false && data?.MediaUrl && (
            <div className="agenda-tweet-body-post-image">
              <img
                src={data?.MediaUrl || ''}
                onError={hasErrorImage}
                alt="Tweet Resmi"
              />
            </div>
          )) ||
            (custom && (
              <div className="agenda-tweet-body-post-image">
                <img src={defaultPostImage} alt="Tweet Resmi" />
              </div>
            ))}
          <div className="agenda-tweet-body-post-infos">
            <span>
              {new Date(data?.CreatedAt || 0).toLocaleString('tr-TR', {
                dateStyle: 'long',
                timeStyle: 'short',
              })}
            </span>
            <span>
              <strong>{numberFormat(data?.ImpressionCount) || '0'}</strong>{' '}
              Görüntülenme
            </span>
          </div>
        </div>
      </div>
      <div className="agenda-tweet-footer">
        <div className="agenda-tweet-footer-statistics">
          <span>
            <p>{numberFormat(data?.RetweetCount)}</p> Repost
          </span>
          <span>
            <p>{numberFormat(data?.QuoteCount)}</p> Alıntı
          </span>
          <span>
            <p>{numberFormat(data?.FavoriteCount)}</p> Beğeni
          </span>
        </div>
        <div className="agenda-tweet-footer-link">
          <a
            href={`https://twitter.com/${data?.UserName}/status/${data?.EntityKey}`}
            target="_blank"
            rel="noreferrer"
          >
            Post &apos;u görüntülemek için tıklayınız{' '}
            <img src={openLink} alt="Open link logo" />
          </a>
        </div>
        <div className="agenda-tweet-footer-logo">
          <img src={xLogo} alt="X Logo" />
        </div>
      </div>
    </Styled.AgendaTweetCard>
  )
}
