import React, { useState } from 'react'
import * as Styled from './UpdateButton.styled'
import { UpdateButtonProps } from './types'
import { usePrintDataParam } from 'app/functions/navigation'
import { useLocation } from 'react-router-dom'
import { usePopupContext } from 'app/contexts/popup'
import { uid } from '@zera-admin/utils'
import { useFlagcardContext } from 'app/contexts/flagcard'
import agendaReportsService from 'services/http/report/agenda-report-4'
import Icon from '@zera-admin/icon'
import { IAgendaReportInsertUpdate } from 'services/http/report/agenda-report-4/types'
import ReportAddModal from '../report-add-modal'

const UpdateButton: React.FC<UpdateButtonProps> = ({
  isEditValue,
  setIsEditValue,
  editMode,
  setEditMode,
  pageNumber,
  contentData,
  title,
  metaData,
}) => {
  const location = useLocation()
  const isPrint = usePrintDataParam(location)
  const [popup] = usePopupContext()
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [addModal, setAddModal] = useState<boolean>(false)

  const editClick = () => {
    setIsEditValue(-1)
    setEditMode(!editMode)

    sessionStorage.setItem('scrollPosition', window.scrollY.toString())
  }

  const handleUpdateValues = () => {
    // buraya servis isteği atılacak
    sessionStorage.setItem('scrollPosition', window.scrollY.toString())
    const updataParams: IAgendaReportInsertUpdate = {
      groupId: metaData?.GroupId || '',
      pageType: metaData?.PageType || '',
      reportType: metaData?.ReportType || '',
      pageId: metaData?.PageId,
      data: contentData,
      title,
      pageNumber: Number(pageNumber),
    }
    editClick()
    setIsLoading(true)
    agendaReportsService
      .updateAgendaReportPage(updataParams)
      .then(() => {
        flagcard.add({
          title: 'Widget güncellendi',
          children: 'Geçerli widget başarıyla güncellendi.',
        })

        setTimeout(() => {
          window.location.reload()
        }, 750)
      })
      .catch(() => {
        popup.add({
          title: 'Widget güncellenemedi',
          children:
            'Widget güncellenirken bir sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (isPrint) {
    return null
  }

  const handleDelete = () => {
    const popupId = uid()

    const onClick = () => {
      setIsLoading(true)

      agendaReportsService
        .deleteAgendaReportPage(metaData)
        .then(() => {
          flagcard.add({
            title: 'Widget silindi',
            children: 'Geçerli widget başarıyla silindi.',
          })

          setTimeout(() => {
            window.location.reload()
          }, 500)
        })
        .catch(() => {
          popup.add({
            title: 'Widget silinemedi',
            children:
              'Widget silinirken bir sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
          })
        })
        .finally(() => {
          setIsLoading(false)
          popup.remove(popupId)
        })
    }

    const onClose = () => {
      popup.remove(popupId)
    }

    popup.add({
      id: popupId,
      appearance: 'warning',
      title: 'Widget silinsin mi?',
      children:
        'Bu widget silmek istediğinize emin misiniz? Bu işlem geri alınamaz.',
      actions: [
        {
          isLoading,
          onClick: onClick,
          text: 'Sil',
        },
        {
          onClick: onClose,
          text: 'Vazgeç',
        },
      ],
      onClose,
    })
  }

  return (
    <>
      <ReportAddModal
        metaData={metaData}
        visible={addModal}
        onClose={() => setAddModal(false)}
      />

      <div className="update-btn">
        <Styled.UpdateButtonContainer>
          {isEditValue === -1 ? (
            <>
              <button
                onClick={() => {
                  setAddModal(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                <i className="fa-solid fa-plus" />
              </button>
              <button
                onClick={() => {
                  setIsEditValue(pageNumber)
                  sessionStorage.setItem(
                    'scrollPosition',
                    window.scrollY.toString()
                  )
                }}
                disabled={isLoading}
              >
                <i className="fa-solid fa-edit" />
              </button>
              <button onClick={() => handleDelete()} disabled={isLoading}>
                <Icon name="trash" size={'large'} />
              </button>
            </>
          ) : isEditValue === metaData?.pageNumber ||
            isEditValue === pageNumber ? (
            <div>
              <button onClick={editClick}>
                <i className="fa-solid fa-x" />
              </button>
              <button onClick={handleUpdateValues} disabled={isLoading}>
                <i className="fa-solid fa-check" />
              </button>
            </div>
          ) : null}
        </Styled.UpdateButtonContainer>
      </div>
    </>
  )
}

export default UpdateButton
