import { createContext, useContext, useReducer } from 'react'
import { uid } from '@zera-admin/utils'

import { State, DashboardContextProps } from './types'
import * as actions from './actions'
import reducer from './reducer'

const initialState: State = {
  schema: [],
  query: {
    source: '',
    table: '',
    where: {
      id: uid(),
      condition: 'and',
      rules: [],
    },
    interval: {},
    limit: 100,
  },
}

export const DashboardContext = createContext<DashboardContextProps>({
  set: () => null,
  query: () => null,
  state: initialState,
})

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <DashboardContext.Provider
      value={{
        set: (data) => dispatch(actions.set(data)),
        query: (data) => dispatch(actions.query(data)),
        state,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const DashboardConsumer = DashboardContext.Consumer

export const useDashboardContext = (): DashboardContextProps => {
  return useContext(DashboardContext)
}
