import React from 'react'
import EmptyState from '@zera-admin/empty-state'

import Bar from 'components/bar'

import { HashtagProps } from './types'

const Hashtag: React.FunctionComponent<HashtagProps> = ({ data }) => {
  return data && data.length > 0 ? (
    data?.map((item: any, index: number) => (
      <Bar
        key={index}
        title={`#${item.hashtags.replace('#', '')}`}
        width={(item.hashtagsCount * 100) / data[0]?.hashtagsCount}
        count={item.hashtagsCount}
      />
    ))
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Hashtag
