import { utils } from '@zera-admin/alice-time-range-picker'
import moment from 'moment'
import { QueryInterval } from 'services/http/bi-tool/query/types'

export const GMT3 = (value: any) => {
  let date = new Date(value)
  return date.toISOString()
}

export const localeDate = (value: string) => {
  return new Date(value).toLocaleString()
}

export const getMonths = (month: number) => {
  switch (month) {
    case 1:
      return 'Ocak'
    case 2:
      return 'Şubat'
    case 3:
      return 'Mart'
    case 4:
      return 'Nisan'
    case 5:
      return 'Mayıs'
    case 6:
      return 'Haziran'
    case 7:
      return 'Temmuz'
    case 8:
      return 'Ağustos'
    case 9:
      return 'Eylül'
    case 10:
      return 'Ekim'
    case 11:
      return 'Kasım'
    case 12:
      return 'Aralık'
    default:
      return ''
  }
}

export const getIntervalAsISO = (value: QueryInterval) => {
  let start = generateISO()
  let end = generateISO()

  if (value.start && value.start?.type !== 'now') {
    start = dateToISO(utils.getIntervalDate(value.start), true)
  }

  if (value.end && value.end?.type !== 'now') {
    end = dateToISO(utils.getIntervalDate(value.end), true)
  }

  return { start, end }
}

export const getIntervalPeriod = (
  interval: QueryInterval
): 'hourly' | 'daily' | 'weekly' | 'monthly' => {
  const values = getIntervalAsISO(interval)
  const start = new Date(values.start)
  const end = new Date(values.end)
  const result = Math.round((end.getTime() - start.getTime()) / 60000)

  if (result <= 60) {
    return 'hourly'
  } else if (result > 1440 && result <= 10080) {
    return 'weekly'
  } else if (result > 10080) return 'monthly'

  return 'daily'
}

export const generateISO = (): string => {
  return dateToISO(generateUTC(new Date()))
}

export const generateUTC = (date: Date): Date => {
  // to do server time'a çekilecek
  const offset = date.getTimezoneOffset()
  const result = date.getTime() + offset * 60 * 1000

  return new Date(result)
}

export const dateToISO = (date: Date, utc?: boolean): string => {
  const result = utc ? generateUTC(date) : date

  return `${result.getFullYear()}-${digit(result.getMonth() + 1)}-${digit(
    result.getDate()
  )}T${digit(result.getHours())}:${digit(result.getMinutes())}:${digit(
    result.getSeconds()
  )}.${result.getMilliseconds()}Z`
}

const digit = (value: number) => {
  return (value < 10 ? '0' : '') + value
}

export const dateFormat = (date: Date) => moment(date).format('YYYY-MM-D')
export const turkishDateFormat = (date: string) => {
  const dateString = moment(date).format('DD-MM-YYYY h:mm')
  if (moment(date, moment.ISO_8601, true).isValid()) {
    return dateString
  } else {
    return '-'
  }
}

export const currentDateString = () => moment(new Date()).format('YYYY-MM-DD')
export const current30DaysBeforeString = () =>
  moment().clone().subtract(30, 'days').format('YYYY-MM-DD')

export const currentWeekBeforeString = () =>
  moment().clone().subtract(7, 'days').format('YYYY-MM-DD')

export const getMonthAndYear = (dateData: string) => {
  if (dateData) {
    const months = [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık',
    ]
    const date = new Date(dateData)
    const d = new Date()
    let month = months[d.getMonth()]
    return `${month} ${date.getFullYear()}`
  }
  return '-'
}
