import React from 'react'
import { Column, Row } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'

import {
  QueryAggregation,
  QueryAggregationType,
  QueryGroup,
  QueryGroupInterval,
} from 'services/http/bi-tool/query/types'

import { AGGREGATORS, GROUP_INTERVALS } from '../utils/constants'
import * as Styled from '../FilterSettings.styled'

import { FilterTabProps } from '../types'

const SummarizeTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
  fields,
}) => {
  const renderAggregates = () => {
    const aggregations = JSON.parse(
      JSON.stringify([...(values.aggregations || [])])
    ) as QueryAggregation[]

    return (
      <React.Fragment>
        <h4>Birleştirmeler</h4>
        {aggregations.map((aggregation, index) => (
          <Styled.ListField key={index}>
            <Row xs={{ direction: 'row' }}>
              <Column>
                <Select
                  name="sort"
                  label="Birleştirme tipi"
                  placeholder="Birleştirme tipi seçiniz"
                  options={AGGREGATORS}
                  value={
                    aggregation.type
                      ? AGGREGATORS.find(
                          (aggregator) => aggregator.value === aggregation.type
                        )
                      : undefined
                  }
                  onChange={(props) => {
                    const type = (props as SelectOptionProps)
                      .value as QueryAggregationType

                    aggregations[index] = {
                      type,
                      field: type === 'countOf' ? 'rowCount' : '',
                    }

                    onChange({ ...values, aggregations })
                  }}
                />
              </Column>
              {aggregation.type && aggregation.type !== 'countOf' && (
                <Column>
                  <Select
                    name="sort"
                    label="Birleştirilecek alan"
                    placeholder="Bir tablo alanı seçiniz"
                    options={fields
                      .filter(
                        (value) =>
                          value.type === 'number' ||
                          value.type === 'number-array'
                      )
                      .map((value) => ({
                        label: string.textify(value.field),
                        value: value.field,
                      }))}
                    value={
                      aggregation.field
                        ? {
                            label: string.textify(aggregation.field),
                            value: aggregation.field,
                          }
                        : undefined
                    }
                    onChange={(props) => {
                      const value = (props as SelectOptionProps).value as string

                      aggregations[index].field = value

                      onChange({
                        ...values,
                        aggregations,
                        orders: values.orders?.filter(
                          (order) => order.field === value
                        ),
                      })
                    }}
                  />
                </Column>
              )}

              <Button
                appearance="error"
                size="medium"
                iconBefore={<Icon name="cross" size="small" />}
                onClick={() => {
                  aggregations.splice(index, 1)

                  onChange({ ...values, aggregations })
                }}
              />
            </Row>
          </Styled.ListField>
        ))}

        <Button
          iconBefore={<Icon name="add" size="small" />}
          onClick={() => {
            aggregations.push({ field: 'rowCount', type: 'countOf' })

            onChange({ ...values, aggregations })
          }}
          style={{ marginTop: 12 }}
        >
          Yeni birleşim ekle
        </Button>
      </React.Fragment>
    )
  }

  const renderGroups = () => {
    const groups = JSON.parse(
      JSON.stringify([...(values.groups || [])])
    ) as QueryGroup[]

    return (
      <React.Fragment>
        <h4>Gruplar</h4>
        {groups.map((group, index) => (
          <Styled.ListField key={index}>
            <Row xs={{ direction: 'row' }}>
              <Column>
                <Select
                  name="sort"
                  label="Gruplanacak alan"
                  placeholder="Bir tablo alanı seçiniz"
                  options={fields
                    .filter(
                      (value) =>
                        value.type === 'string' ||
                        value.type === 'string-array' ||
                        value.type === 'date'
                    )
                    .map((value) => ({
                      label: string.textify(value.field),
                      value: value.field,
                    }))}
                  value={
                    group.field
                      ? {
                          label: string.textify(group.field),
                          value: group.field,
                        }
                      : undefined
                  }
                  onChange={(props) => {
                    groups[index].field = (props as SelectOptionProps)
                      .value as string

                    onChange({ ...values, groups })
                  }}
                />
              </Column>
              {fields.find((value) => value.field === group.field)?.type ===
                'date' && (
                <Column>
                  <Select
                    name="sort"
                    label="Zaman periyodu"
                    placeholder="Bir periyod seçiniz"
                    options={GROUP_INTERVALS}
                    value={
                      group.interval
                        ? {
                            label:
                              GROUP_INTERVALS.find(
                                (interval) => interval.value === group.interval
                              )?.label || '',
                            value: group.interval,
                          }
                        : GROUP_INTERVALS[0]
                    }
                    onChange={(props) => {
                      groups[index].interval = (props as SelectOptionProps)
                        .value as QueryGroupInterval

                      onChange({ ...values, groups })
                    }}
                  />
                </Column>
              )}

              <Button
                appearance="error"
                size="medium"
                iconBefore={<Icon name="cross" size="small" />}
                onClick={() => {
                  groups.splice(index, 1)

                  onChange({ ...values, groups })
                }}
              />
            </Row>
          </Styled.ListField>
        ))}

        <Button
          iconBefore={<Icon name="add" size="small" />}
          onClick={() => {
            groups.push({ field: '' })

            onChange({ ...values, groups })
          }}
          style={{ marginTop: 12 }}
        >
          Yeni grup ekle
        </Button>
      </React.Fragment>
    )
  }

  const renderSummarizeTab = () => {
    return (
      <React.Fragment>
        <Row xs={{ direction: 'row' }}>
          <Column xs={{ size: 12 }}>
            <p>
              Aşağıdan seçtiğiniz alanları birleştirebilir, ortalamalarını
              hesaplayabilir ya da toplayabilirsiniz. Aynı zamanda alanları
              birbirleri ile gruplayabilirsiniz.
            </p>
          </Column>
          <Column xs={{ size: 6 }}>{renderAggregates()}</Column>
          <Column xs={{ size: 6 }}>{renderGroups()}</Column>
        </Row>
      </React.Fragment>
    )
  }

  return renderSummarizeTab()
}

export default SummarizeTab
