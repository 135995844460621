import React, { useState, useEffect } from 'react'
import Page, { PageHeader } from '@zera-admin/page'
import { uid } from '@zera-admin/utils'

import { useDashboardContext } from 'app/contexts/dashboard'
import { useFlagcardContext } from 'app/contexts/flagcard'
import datasourceService, {
  Datasource,
  Table,
} from 'services/http/bi-tool/datasource'
import fieldService, { Field, FieldRequest } from 'services/http/bi-tool/field'
import dashboardService, { Dashboard } from 'services/http/bi-tool/dashboard'
import Loader from 'components/loader'
import DashboardDesigner from 'pages/bi-tool/dashboard/dashboard-designer'

import * as Styled from './UpdateDashboard.styled'
import { UpdateDashboardProps } from './types'

const UpdateDashboardForSS: React.FunctionComponent<UpdateDashboardProps> = ({
  history,
  match,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isPreviewLoading, setIsPreviewLoading] = useState(true)
  const [filterVisibility, setFilterVisibility] = useState(false)
  const [sources, setSources] = useState<Datasource[]>([])
  const [tables, setTables] = useState<Table[]>([])
  const [fields, setFields] = useState<Field[]>([])
  const [values, setValues] = useState<Dashboard>({} as Dashboard)

  const [flagcard] = useFlagcardContext()
  const dashboardContext = useDashboardContext()

  useEffect(() => {
    const id = match.params.id

    dashboardService
      .getById(id)
      .then((res) => {
        setIsLoading(false)
        setValues(res.data || {})
        getPreview(res.data.schema)

        getDatasources()
        getTables(res.data.query?.source)
        getFields({
          datasource: res.data.query?.source,
          table: res.data.query?.table,
        })
      })
      .catch((err: Error) => {
        setIsLoading(false)
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, history])

  const getDatasources = () => {
    datasourceService
      .get()
      .then((res) => {
        setSources(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getTables = (datasource: string) => {
    datasourceService
      .getTables(datasource)
      .then((res) => {
        setTables(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getFields = (request: FieldRequest) => {
    fieldService
      .get(request)
      .then((res) => {
        if (res.data) {
          setFields(res.data)
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getPreview = (schema: unknown[]) => {
    dashboardService
      .preview({ schema })
      .then((response) => {
        dashboardContext.set(response.data)
      })
      .finally(() => {
        setIsPreviewLoading(false)
      })
  }

  const handleSubmit = () => {
    const id = match.params.id

    if (values) {
      setIsLoading(true)

      dashboardService
        .update(id, { ...values, schema: dashboardContext.state.schema })
        .then((res) => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: 'Dashboard güncellendi',
            children:
              'Girmiş olduğunuz bilgiler ile birlikte dashboard sistem üzerinde başarıyla güncellenmiştir.',
          })
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Dashboard eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleDelete = () => {
    setIsLoading(true)

    dashboardService
      .del(match.params.id)
      .then(() => {
        history.push('/bi-tool/dashboards')
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleChangeValues = (values: Dashboard) => {
    setValues(values)
  }

  const renderPageHeader = () => {
    return <PageHeader>{values?.title || 'Dashboard'}</PageHeader>
  }

  return (
    <Page className="sreen-shot-container">
      <Styled.UpdateDashboard>
        {renderPageHeader()}

        {isLoading || isPreviewLoading ? (
          <Loader
            show={isLoading || isPreviewLoading}
            size="medium"
            label={
              values.id && !isPreviewLoading
                ? 'Dashboard güncelleniyor...'
                : 'Dashboard yükleniyor...'
            }
          />
        ) : (
          <DashboardDesigner
            fields={fields}
            filterVisibility={filterVisibility}
            isPreview={true}
            onChange={handleChangeValues}
            onDelete={handleDelete}
            onGetFields={getFields}
            onGetTables={getTables}
            onSave={handleSubmit}
            operation="update"
            setFilterVisibility={setFilterVisibility}
            sources={sources}
            tables={tables}
            values={values}
            bottomBarHide={true}
          />
        )}
      </Styled.UpdateDashboard>
    </Page>
  )
}

export default UpdateDashboardForSS
