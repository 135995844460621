import React, { useState } from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import { Column, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'
import Switch from '@zera-admin/switch'
import { string } from '@zera-admin/helpers'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'

import * as Styled from './UserDefinitions.styled'
import { UserDefinitionsProps } from './types'
import { USER_FIELDS } from './constants'

const UserDefinitions: React.FunctionComponent<UserDefinitionsProps> = ({
  aggregations,
  fields,
  groups,
  onChange,
  options,
}) => {
  const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
    getFieldOptions(fields)
  )

  const handleChangeFieldInput = (value: string) => {
    const option = {
      label: value,
      value,
    }
    const index = fieldOptions.findIndex(
      (reference) => reference.value === parseInt(option.value)
    )
    let optionsInstance = [...fieldOptions]

    if (value && index === -1) {
      optionsInstance[0] = option
    } else {
      optionsInstance = getFieldOptions(fields)
    }

    setFieldOptions(optionsInstance)
  }

  const renderTabs = () => {
    return (
      <Tabs id="tabs">
        <TabTriggerGroup>
          <TabTrigger>Veri</TabTrigger>
          <TabTrigger>Parametreler</TabTrigger>
        </TabTriggerGroup>
        <TabPanel>{renderDataTab()}</TabPanel>
        <TabPanel>{renderParametersTab()}</TabPanel>
      </Tabs>
    )
  }

  const renderParametersTab = () => {
    return (
      <Row xs={{ direction: 'column' }}>
        <Column>
          <Switch
            name="live"
            label="Anlık güncelleme"
            isChecked={options?.stream?.live}
            onChange={(event) =>
              onChange({
                ...options,
                stream: {
                  live: event.target.checked,
                  limit: 100,
                  interval: 60000,
                  timeout: 5000,
                },
              })
            }
          />
        </Column>
        {options?.stream?.live && (
          <React.Fragment>
            <Column>
              <Input
                name="interval"
                label="İstek aralığı (MS)"
                placeholder="60000"
                type="number"
                value={options?.stream?.interval}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange({
                    ...options,
                    stream: {
                      ...options.stream,
                      interval: Number(event.target.value),
                    },
                  })
                }
              />
            </Column>
            <Column>
              <Input
                name="timeout"
                label="Yeni kayıt ekleme aralığı (MS)"
                placeholder="5000"
                type="number"
                value={options?.stream?.timeout}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange({
                    ...options,
                    stream: {
                      ...options.stream,
                      timeout: Number(event.target.value),
                    },
                  })
                }
              />
            </Column>
            <Column>
              <Input
                name="limit"
                label="Gösterilecek kayıt sayısı"
                placeholder="100"
                type="number"
                value={options?.stream?.limit}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChange({
                    ...options,
                    stream: {
                      ...options.stream,
                      limit: Number(event.target.value),
                    },
                  })
                }
              />
            </Column>
          </React.Fragment>
        )}
      </Row>
    )
  }

  const renderDataTab = () => {
    const values = options?.fields || {}

    return USER_FIELDS.map((userField, index) => (
      <Row key={index} xs={{ direction: 'column' }}>
        <Column>
          <Select
            name={userField.value}
            options={fieldOptions}
            onChange={(props) => {
              onChange({
                ...options,
                fields: {
                  ...options.fields,
                  [userField.value]: (props as SelectOptionProps)
                    .value as string,
                },
              })
            }}
            onInputChange={handleChangeFieldInput}
            value={
              values[userField.value]
                ? {
                    label: string.textify(values[userField.value]),
                    value: values[userField.value],
                  }
                : { label: '', value: '' }
            }
            label={userField.label}
            placeholder="Seçim yapınız"
            isDisabled={aggregations.length || groups.length ? true : false}
          />
        </Column>
      </Row>
    ))
  }

  return <Styled.UserDefinitions>{renderTabs()}</Styled.UserDefinitions>
}

export default UserDefinitions
