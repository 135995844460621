import moment from 'moment'

import {
  TableVisualizationDateFormat,
  TableVisualizationNumberFormat,
} from '../types'

export const numberFormat = (
  value: number,
  format: TableVisualizationNumberFormat
) => {
  if (!value) return '0'

  let result = value.toString()

  if (format.style === 'currency') {
    result = value.toLocaleString('tr-TR', {
      style: 'currency',
      currency: 'TRL',
    })
  } else if (format.style === 'percent') {
    result = `%${value}`
  } else if (format.style === 'number') {
    result = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  if (format.prefix) {
    result = `${format.prefix} ${result}`
  }

  if (format.suffix) {
    result = `${result} ${format.suffix}`
  }

  return result
}

export const dateFormat = (
  value: string,
  format: TableVisualizationDateFormat
) => {
  if (!value) return '-'

  return moment(new Date(value)).format(format.style)
}
