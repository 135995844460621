import React, { useCallback, useEffect, useState } from 'react'
import Page, { Container, PageHeader, Row } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import html2canvas from 'html2canvas'
import Chart, { Props } from 'react-apexcharts'
import jsPDF from 'jspdf'
import MainLayout from 'layouts/main'
import mobileReportService from 'services/http/report/agenda-report'
import {
  Content,
  Data,
  HashTagsTimeSery,
  MobileReportProps,
  TimeSeriesBySentiment,
} from './types'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import * as Styled from './Stres.styled'
import { PdfContainer } from './components/twitter-card'
import Overlay from './components/overlay/Overlay'
import TwitterTweetCard from './components/tweet'
import Header from './components/header'
import Users from './components/users'
import StresGraph from './components/graph'
import Hashtags from './components/hashtags'
import SocialCard from './socialcard/SocialCard'
import NewsCard from './components/news'
import NameValues from 'widgets/names-values/NameValues'
import { numberFormat } from 'app/functions/number'
const MobileReport: React.FunctionComponent<MobileReportProps> = ({
  match,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isPdfLoading, setIsPdfLoading] = useState<boolean>(false)
  const [pdfInputValue, setPdfInputValue] = useState('')
  const [stressReport, setStressReport] = useState<Data>()
  const DailyReport = useCallback(() => {
    mobileReportService
      .SecondReport(3)
      .then((res) => {
        // setMobileReport(res?.data?.data)
        setStressReport(res.data.data)
        setIsLoading(false)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    DailyReport()
  }, [DailyReport])

  const handleDownloadPdf = () => {
    setIsPdfLoading(true)
    let input = document.getElementById('printThis')!

    html2canvas(input, {
      scale: 1,
      useCORS: true,
      ignoreElements: (element: any) => {
        if (element.id === 'card-box') {
          element.style.boxShadow = 'none'
          element.style.border = '1px solid lightgray'
        } else if (element.id === 'profileShadow') {
          element.style.boxShadow = 'none'
          element.style.padding = '0px'
          element.style.border = '1px solid gray'
          element.style.height = '70px'
        }
        return false
      },
    })
      .then((canvas) => {
        var imgData = canvas.toDataURL('image/jpeg')

        var pdf = new jsPDF('p', 'in', [6, 100], true)

        // if (mobileReport?.negativePersonalitiesAndInteractions.length) {
        //   pdf = new jsPDF('p', 'in', [6, 100], true)
        // }
        var imgProps = pdf.getImageProperties(imgData)
        var pdfWidth = pdf.internal.pageSize.getWidth()
        var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)

        const date = new Date()
          .toLocaleDateString('tr-TR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
          .replaceAll(':', '_')

        pdf.save(`MneRaporu-${date}.pdf`.replaceAll(' ', '_'))

        const fileName = `MneRaporu-${date}`.replaceAll(' ', '_')
        setPdfInputValue(fileName)
      })
      .catch((e) => console.log('error', e))
      .finally(() => {
        setIsPdfLoading(false)
      })
  }
  const colors = [' #00ff00', ' #ff0000', '#808080']

  const Dteparse = (date: string) => {
    const dates = new Date(date)
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',

      hour: '2-digit',
      minute: '2-digit',
    }
    return dates.toLocaleDateString('tr-TR', options)
  }
  let first: number[] = [],
    firstCategory: string[] = []

  let colorserie: { name: string; data: number[] }[] = []
  let categories: any = []
  let timesize = 0
  let timeserie: TimeSeriesBySentiment = {
    sentiment: '',
    timeSeries: [],
  }
  let secondsize = 0
  let graph: HashTagsTimeSery = {
    hashTag: '',
    timeSeries: [],
  }
  let liste: { name: string; data: number[] }[] = []
  let secondCategories: any = []
  stressReport?.hashTagsTimeSeries?.map((itm) =>
    liste.push({
      name: itm.hashTag,
      data: itm.timeSeries?.map((item) => item.count),
    })
  )

  stressReport?.timeSeriesBySentiment?.map((item) =>
    colorserie?.push({
      name: item.sentiment,
      data: item.timeSeries?.map((item) => item.counts),
    })
  )
  stressReport?.timeSeriesBySentiment?.forEach(function (
    time: TimeSeriesBySentiment
  ) {
    if (time.timeSeries?.length > timesize) {
      timesize = time.timeSeries?.length
      timeserie = time
    }
    timeserie?.timeSeries?.map((itm) =>
      categories?.push(`${Dteparse(itm.asDateTime)}`)
    )
  })

  stressReport?.hashTagsTimeSeries?.forEach(function (time: HashTagsTimeSery) {
    if (time.timeSeries?.length > secondsize) {
      secondsize = time.timeSeries?.length
      graph = time
    }
    graph?.timeSeries?.map((itm) =>
      secondCategories?.push(`${Dteparse(itm.asDateTime)}`)
    )
  })

  stressReport?.timeSeries?.map((item) => first.push(item.counts))
  stressReport?.timeSeries?.map((item) =>
    firstCategory.push(`${Dteparse(item.asDateTime)}`)
  )
  stressReport?.timeSeries?.map((item) =>
    firstCategory.push(`${Dteparse(item.asDateTime)}`)
  )
  const horizontalBarChart: Props = {
    series: colorserie,
    options: {
      chart: {
        height: 500,
        type: 'line',
        zoom: {
          enabled: false,
        },
        background: '#fff',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Zaman Bazlı Duygu Dağılımı',
        align: 'left',
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            ' - ' +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ''
          )
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: categories,
      },
      colors: ['#fc0303', '#7f8582', '#03fc8c'],
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ' (mins)'
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + ' per session'
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
    },
  }
  return isLoading || isPdfLoading ? (
    <LoadingView>
      <Spinner size="large" />
      <h5>{isPdfLoading ? 'PDF Hazırlanıyor...' : 'Rapor yükleniyor...'}</h5>
    </LoadingView>
  ) : (
    <MainLayout isLoading={isLoading}>
      <Container width="wide">
        <Page>
          <PageHeader
            actions={
              <>
                <input id="pdf-date" type="hidden" value={pdfInputValue} />
                <Button
                  id="pdf-button"
                  iconBefore={<Icon name="download" size="small" />}
                  onClick={() => handleDownloadPdf()}
                >
                  PDF olarak indir
                </Button>
              </>
            }
          >
            Mehmet Nuri Ersoy Raporu
          </PageHeader>

          <Row xs={{ direction: 'column', align: 'center' }}>
            <PdfContainer
              style={{ width: '100%' }}
              id="printThis"
              className="fa-Page"
            >
              <div
                style={{ width: '95%', borderWidth: 0.6, borderColor: 'gray' }}
              >
                <Header
                  date={stressReport?.date}
                  isDate={true}
                  title="Mehmet Nuri Ersoy Raporu"
                />
                <Styled.Container>
                  <div className="header-container">
                    <div className="chart-container">
                      {(first.length && categories.length && (
                        <StresGraph
                          series={liste}
                          categories={secondCategories}
                        />
                      )) || <h1>No Data</h1>}
                    </div>

                    <div className="chart-container">
                      <div className="chart-content">
                        <Styled.TimeSeries>
                          <Chart
                            series={horizontalBarChart.series}
                            options={horizontalBarChart.options}
                            type="area"
                          />
                        </Styled.TimeSeries>
                      </div>
                    </div>
                  </div>
                  <Styled.HashtagsCardContainer>
                    <Styled.HashtagsContainer>
                      <Hashtags
                        data={stressReport?.hashTagsByBotDetectionV2?.slice(
                          0,
                          8
                        )}
                      />
                    </Styled.HashtagsContainer>
                  </Styled.HashtagsCardContainer>
                  <Styled.HashtagsCardContainer>
                    <div className="socialcards-container">
                      {stressReport?.totalCountsByPostType?.map(
                        (itm, index) => (
                          <SocialCard
                            styles={{
                              borderTopLeftRadius: index === 0 ? 10 : 0,
                            }}
                            tweetTitle={itm.postType}
                            tweetCount={itm.counts.toLocaleString()}
                            icon={'fa-brands fa-twitter'}
                            color={'#50aaf5'}
                          />
                        )
                      )}
                      <SocialCard
                        styles={{ borderBottomLeftRadius: 10 }}
                        tweetTitle={'Tahmini Etki'}
                        tweetCount={stressReport?.totalImpressionCount?.toLocaleString()}
                        icon={'fa-brands fa-twitter'}
                        color={'#50aaf5'}
                      />
                      <SocialCard
                        tweetTitle={'Tekil Kullanıcı'}
                        tweetCount={stressReport?.uniqueUserCount?.toLocaleString()}
                        icon={'fa-brands fa-twitter'}
                        color={'#50aaf5'}
                      />
                      <SocialCard
                        tweetTitle={
                          'Tweet Atan Hesapların Toplam Takipçi Sayısı'
                        }
                        tweetCount={numberFormat(
                          stressReport?.totalFollowersCount.toLocaleString() ||
                            0
                        )}
                        icon={'fa-brands fa-twitter'}
                        color={'#50aaf5'}
                      />
                      <SocialCard
                        tweetTitle={'Haberler'}
                        tweetCount={stressReport?.newsCount.toLocaleString()}
                        icon={'fa-solid fa-newspaper'}
                        color={'#FF9914'}
                      />
                      <SocialCard
                        styles={{ borderBottomRightRadius: 10 }}
                        tweetTitle={'Reddit'}
                        tweetCount={stressReport?.redditCount.toLocaleString()}
                        icon={'fa-brands fa-reddit'}
                        color={'#FF4500'}
                      />
                    </div>
                  </Styled.HashtagsCardContainer>
                </Styled.Container>

                {stressReport?.content?.map((item: Content, index) => (
                  <Styled.LineComp
                    style={{ borderColor: colors[index], marginTop: '6%' }}
                  >
                    <Styled.TitleWrapper
                      style={{ backgroundColor: colors[index] }}
                    >
                      <Styled.Title>&#9679; {item?.sentiment}</Styled.Title>
                      <Styled.Line style={{ backgroundColor: colors[index] }} />
                    </Styled.TitleWrapper>
                    <Styled.Row id="tweet">
                      <Styled.Line style={{ backgroundColor: colors[index] }} />
                      <Overlay
                        label={'Twitter'}
                        topic={item.sentiment}
                        color={'#50aaf5'}
                        icon={'fa-brands fa-twitter'}
                        titleColor={colors[index]}
                      >
                        {item?.tweets?.slice(0, 3).map((tweet: any) => (
                          <TwitterTweetCard tweet={tweet} />
                        ))}
                      </Overlay>
                    </Styled.Row>
                    {item?.newsList.length > 0 && (
                      <Styled.Row id="news">
                        <Styled.Line
                          style={{ backgroundColor: colors[index] }}
                        />
                        <Overlay
                          label={'Haberler'}
                          topic={item.sentiment}
                          color={'#FF9914'}
                          icon={'fa-solid fa-newspaper'}
                          titleColor={colors[index]}
                        >
                          {item?.newsList?.slice(0, 3).map((itm) => (
                            <NewsCard news={itm} />
                          ))}
                        </Overlay>
                      </Styled.Row>
                    )}

                    <Styled.StyledDiv id="user">
                      <div
                        style={{
                          position: 'absolute',
                          left: '0px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          height: '2px',
                          width: '20px',
                          backgroundColor: colors[index],
                        }}
                      ></div>
                      <Overlay
                        label={'Kişiler'}
                        topic={item.sentiment}
                        color={'#3658A5'}
                        titleColor={colors[index]}
                      >
                        {item?.influentialPeople?.slice(0, 6).map((itm) => (
                          <Users people={itm} />
                        ))}
                      </Overlay>
                    </Styled.StyledDiv>
                  </Styled.LineComp>
                ))}
                <Styled.NameCont
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '4%',
                  }}
                >
                  <div className="name-value-container">
                    <div className="name-value-content-container">
                      <Styled.NameValue>
                        <h3>Pozitif ABS</h3>

                        <NameValues
                          data={stressReport?.positiveAbsKeywords
                            ?.slice(0, 5)
                            .map((item) => ({
                              name: item.value,
                              value: item.counts,
                            }))}
                        />
                      </Styled.NameValue>
                    </div>

                    <div className="name-value-content-container">
                      <Styled.NameValue>
                        <h3>Negatif ABS</h3>

                        <NameValues
                          data={stressReport?.negativeAbsKeywords
                            ?.slice(0, 5)
                            .map((item) => ({
                              name: item.value,
                              value: item.counts,
                            }))}
                        />
                      </Styled.NameValue>
                    </div>
                  </div>
                </Styled.NameCont>
              </div>
            </PdfContainer>
          </Row>
        </Page>
      </Container>
    </MainLayout>
  )
}
export default MobileReport
