import React, { useState } from 'react'
import {
  TwitterCard,
  TwitterCardTop,
  TwitterCardHeader,
} from './TwitterProfileCard.style'
import Button, { ButtonGroup } from '@zera-admin/button'
import { Column, Row } from '@zera-admin/page'
import Icon from '@zera-admin/icon'

import NotFoundImage from 'assets/images/default_profile_400x400.png'

import { TwitterProfileCardProps } from './types'
import TwitterDetailModal from './twitter-detail-modal/TwitterDetailModal'

const TwitterProfileCard: React.FunctionComponent<TwitterProfileCardProps> = ({
  data,
  addList,
  removeList,
  isSelected,
}) => {
  const [modalDetail, setModalDetail] = useState<boolean>(false)

  return (
    <React.Fragment>
      <TwitterCard>
        <TwitterCardTop>
          <ButtonGroup>
            <Button
              onClick={() => {
                setModalDetail(true)
              }}
            >
              İncele
            </Button>
            <Button
              appearance="subtle"
              iconBefore={
                <Icon
                  name={`${isSelected ? 'check-circle' : 'add'}`}
                  size="medium"
                />
              }
              onClick={() => {
                if (!isSelected) {
                  addList(data)
                } else {
                  removeList(data)
                }
              }}
            />
          </ButtonGroup>
        </TwitterCardTop>
        <TwitterCardHeader>
          <img
            src={data.profileImageUrl}
            alt="profil resmi"
            onError={(event) => {
              event.currentTarget.src = NotFoundImage
            }}
          ></img>
        </TwitterCardHeader>
        <Row xs={{ direction: 'column' }}>
          <Column xs={{ align: 'center' }} style={{ padding: '4px' }}>
            {data.name}
          </Column>
          <Column xs={{ align: 'center' }} style={{ padding: '4px' }}>
            {`@${data.username} `}
          </Column>
          <Column xs={{ align: 'center' }} style={{ padding: '4px' }}>
            {`Ülke: ${data.country} `}
          </Column>
          <Column xs={{ align: 'center' }} style={{ padding: '4px' }}>
            {` Takipçi: ${data.followerCount} Takip: ${data.followingCount}`}
          </Column>
        </Row>
      </TwitterCard>

      {modalDetail && (
        <TwitterDetailModal
          onClose={() => {
            setModalDetail(false)
          }}
          data={data}
        />
      )}
    </React.Fragment>
  )
}

export default TwitterProfileCard
