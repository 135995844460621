import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected'

import { BritishProps } from './types'
import { britishActiveUser } from './constants'

const British: React.FunctionComponent<BritishProps> = () => {
  const handleCreateGraph = () => {
    const chart = am4core.create(
      'chartdiv',
      am4plugins_forceDirected.ForceDirectedTree
    )
    const networkSeries = chart.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    )

    const dataArray = [
      {
        name: 'British',
        children: britishActiveUser.map((item) => ({
          name: Object.keys(item)[0],
          children: Object.values(item)[0].map((c: string[]) => ({
            name: c,
          })),
          /*  children: [], */
        })),
      },
    ]
    chart.data = dataArray
    networkSeries.nodes.template.expandAll = false
    networkSeries.dataFields.value = 'followingCount'
    networkSeries.dataFields.name = 'name'
    networkSeries.dataFields.children = 'children'
    networkSeries.dataFields.id = 'name'
    networkSeries.dataFields.linkWith = 'otherParents'
    networkSeries.nodes.template.fillOpacity = 1

    networkSeries.colors.list = [am4core.color('#397aaf')]

    networkSeries.nodes.template.label.text = '{name}'
    networkSeries.fontSize = 10
    networkSeries.minRadius = 35
    networkSeries.maxRadius = 90
    networkSeries.maxLevels = 2
    networkSeries.manyBodyStrength = -20
    networkSeries.links.template.distance = 0
    networkSeries.links.template.strength = 0.15

    networkSeries.links.template.strokeWidth = 1

    networkSeries.showOnInit = false

    let hoverState = networkSeries.links.template.states.create('hover')
    hoverState.properties.strokeWidth = 3
    hoverState.properties.strokeOpacity = 1
  }

  useEffect(() => {
    handleCreateGraph()
  }, [])

  return <div id="chartdiv" style={{ width: '100%', height: '100vh' }} />
}

export default British
