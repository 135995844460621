import { useState } from 'react'
import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Button from '@zera-admin/button'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'
import Switch from '@zera-admin/switch'

import { validation } from 'app/functions'
import useSources from 'app/hooks/sources'
import { BotClassification } from 'services/http/dataset/bot-classification'
import tweetService from 'services/http/dataset/tweet'

import { BotClassificationFormProps } from './types'
import { BotConclusionEnum } from 'services/http/dataset'

const validations = {
  // isBot: yup.string().required('Bot durumu seçmelisiniz'),
  // personUserName: yup.string().required('Twitter kullanıcı adı girmelisiniz'),
  // personUserId:
  //   !activeUser && yup.string().required('Twitter kullanıcısı bulunamadı'),
  sourceId: yup.string().required('Kaynak seçmelisiniz'),
}

const BotClassificationForm: React.FunctionComponent<BotClassificationFormProps> =
  ({ actions = [], onError, onSubmit, operation, values }) => {
    const [userName] = useState<string>('')
    const [activeUser, setActiveUser] = useState<boolean>(false)

    const form = useForm<BotClassification>({
      initialValues: values,
      onSubmit: (props) => handleSubmit(props),
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })

    const { sources, getSources, getSource, isLoading } = useSources()

    const handleSubmit = (props: FormHookSubmitProps<BotClassification>) => {
      if (!props.errors) {
        if (onSubmit) {
          onSubmit(props.values, () => {
            if (props.handleReset) {
              props.handleReset()
            }
          })
        }
      } else {
        if (onError) {
          onError(props.errors)
        }
      }
    }

    const verifyUserName = (userName?: string) => {
      if (userName) {
        tweetService
          .get({ UserName: userName, PageSize: 1 })
          .then((res) => {
            form.handleFieldChange('personUserId', res.data.list[0].personId)
            setActiveUser(true)
          })
          .catch((err: Error) => {
            console.log(err)
            setActiveUser(false)
          })
      }
    }

    return (
      <Form onSubmit={form.handleSubmit}>
        <FormSection
          title="Genel bilgiler"
          description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
        >
          <Row xs={{ direction: 'column' }}>
            <Column>
              <SearchBox
                description="Geçerli bir kaynak seçimi yapınız"
                errorMessage={form.errors.sourceId}
                label="Kaynak"
                name="sourceId"
                options={sources}
                value={getSource(form.values.sourceId)}
                onFetchOptions={(value) => getSources(value)}
                onChange={(props) =>
                  form.handleFieldChange(
                    'sourceId',
                    (props as SearchboxOptionProps).value
                  )
                }
                placeholder="Seçim yapın"
                isLoading={isLoading}
              />
            </Column>
            <Column>
              <Switch
                isChecked={
                  form.values.isBot === BotConclusionEnum.Bot ? true : false
                }
                name="isBot"
                label="Bot durumu"
                size="large"
                onChange={(event) =>
                  form.handleFieldChange('isBot', event.target.checked)
                }
              />
            </Column>
          </Row>
          <Row xs={{ align: 'center' }}>
            <Column xs={{ size: 6 }}>
              <Input
                description="Geçerli bir twitter kullanıcı adı giriniz"
                isDisabled={operation === 'update' && true}
                errorMessage={form.errors.twitterUserName}
                label="Twitter kullanıcı adı"
                name="twitterUserName"
                placeholder="Glomil"
                value={form.values.twitterUserName}
                onChange={(event) =>
                  form.handleFieldChange(
                    'twitterUserName',
                    event.currentTarget.value
                  )
                }
              />
            </Column>
            {operation === 'create' && (
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="@ Doğrula"
                  onClick={() => verifyUserName(form.values.twitterUserName)}
                />
              </Column>
            )}
          </Row>
          <p style={{ color: activeUser ? 'green' : 'red' }}>
            {userName.length > 0
              ? activeUser
                ? 'Doğrulandı'
                : 'Doğrulanmadı'
              : null}
          </p>
        </FormSection>
        <FormFooter>{actions}</FormFooter>
      </Form>
    )
  }

export default BotClassificationForm
