import { storage } from '@zera-admin/helpers'

import { JvtTokenResponse } from 'app/shared/types'

export const jvt = (): JvtTokenResponse | null => {
  const token = storage.get('token')

  if (!token) return null

  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (error) {
    return null
  }
}
