import { Http, HttpConfig } from '@zera-admin/classes'
import { storage } from '@zera-admin/helpers'
import { requestEnvironmentAtmaca } from 'app/functions/request-parse'

import { refresh } from 'services/http/interceptors'

const http = new Http({
  origin: process.env.REACT_APP_BACKOFFICE,
  interceptors: {
    refresh,
  },
})

export function request<T>(config: HttpConfig) {
  config.headers = {
    Authorization: `Bearer ${storage.get('token')}`,
    ...config.headers,
  }

  if (config.method === 'get' || config.method === 'delete') {
    config.params = requestEnvironmentAtmaca(config.params)
  } else if (config.method === 'post' || config.method === 'put') {
    config.data = requestEnvironmentAtmaca(config.data)
  }

  return http.request<T>(config)
}

export default http
