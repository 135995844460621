import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  &.print {
    align-items: center;
    align-self: center;
    align-content: center;
  }
  .edit-btn-container {
    display: flex;
    justify-content: end;
    align-items: center;
    .edit-btn {
      display: flex;
    }
  }
`
