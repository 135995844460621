import { PartOfSpeech, PartOfSpeechResponse } from './types'

export const mapPartOfSpeechForRequest = (raw: PartOfSpeech): unknown => ({
  part_of_speech_id: raw.id,
  long_name: raw.longName,
  name: raw.name,
})

export const mapPartOfSpeechForResponse = (raw: any): PartOfSpeech => ({
  id: raw.data.id,
  longName: raw.data.long_name,
  name: raw.data.name,
})

export const mapPartOfSpeechForList = (raw: any): PartOfSpeechResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapPartOfSpeechForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
