import { QueryRunResponse } from 'services/http/bi-tool/query'

import { UserValueProps, UserVisualizationOptions } from '../types'

export const mapQueryForUser = (
  raw: QueryRunResponse,
  options: UserVisualizationOptions
): UserValueProps[] =>
  (raw.rows || []).map((row) => ({
    entityKey: row[options?.fields?.entityKey as string],
    countryDetection: row[options?.fields?.countryDetection as string],
    createdAt: row[options?.fields?.userCreatedAt as string],
    followUp: row[options?.fields?.userFriends as string],
    follower: row[options?.fields?.userFollowers as string],
    name: row[options?.fields?.userName as string],
    profilImage: row[options?.fields?.userProfileImage as string],
    protected: row[options?.fields?.userProtected as string],
    tweetCount: row[options?.fields?.userTweetCount as string],
    username: row[options?.fields?.userVisibleText as string],
    verified: row[options?.fields?.userVerified as any],
    retweetCount: row[options?.fields?.retweetCount as any],
    favoriteCount: row[options?.fields?.favoriteCount as any],
    replyCount: row[options?.fields?.replyCount as any],
    negativeStatusCount: row[options?.fields?.negativeStatusCount as any],
    positiveStatusCount: row[options?.fields?.positiveStatusCount as any],
    neutralStatusCount: row[options?.fields?.neutralStatusCount as any],
  }))

export const mapUserDifferences = (
  previous: UserValueProps[],
  next: UserValueProps[]
) => {
  const differences: UserValueProps[] = next

  previous.forEach((prev) => {
    const index = differences.findIndex(
      (difference) => difference?.entityKey === prev.entityKey
    )

    if (index !== -1) {
      differences.splice(index, 1)
    }
  })

  return differences
}
