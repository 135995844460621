import React from 'react'
import WordCloud from 'components/word-cloud'

import InfoCard from '../report-card'
import { WordCloudReportProps } from './types'

const WordCloudReport: React.FunctionComponent<WordCloudReportProps> = ({
  data,
}) => {
  data = data.map((word: any) => {
    return {
      Word: word.words,
      Count: word.rowCount,
    }
  })
  return (
    <InfoCard title="Kelime Bulutu">
      <WordCloud data={data} />
    </InfoCard>
  )
}
export default WordCloudReport
