import { request } from 'services/http/bi-tool/instance'

import type { Datasource, Table } from './types'

import { mapDatasourceForResponse, mapTableForResponse } from './mappers'

export const get = () =>
  request<Datasource[]>({
    method: 'get',
    url: 'field/data-sources',
    mappers: {
      res: mapDatasourceForResponse,
    },
  })

export const getTables = (datasource: string) =>
  request<Table[]>({
    method: 'get',
    url: 'field/tables',
    params: {
      datasource,
    },
    mappers: {
      res: mapTableForResponse,
    },
  })
