import { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'

import { dateToISO, generateUTC } from 'app/functions'
import { QueryRunResponse } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import {
  TimeSeriesVisualizationNumberFormat,
  TimeSeriesVisualizationOptions,
} from '../types'
import { numberFormat } from './helpers'

export const mapTimeSeriesQuery = (
  values: QueryRunResponse,
  visualization: WidgetVisualization<TimeSeriesVisualizationOptions>
) => {
  const label = visualization.options.label
  const value = visualization.options.value
  const result = { ...values }

  result.rows = result.rows?.map((row) => {
    const minutes = row.minutes || '00'
    const hours = row.hours || '00'
    const days = row.days || '0'
    const months = row.months || '0'
    const years = row.years || '0'
    const date = new Date(years, months - 1, days, hours, minutes)

    return {
      [label.field]: dateToISO(date, true),
      [value.field]: row[value.field],
    }
  })

  result.rows = result.rows.sort((a, b) => {
    const dateBefore = new Date(a[label.field]).getTime()
    const dateAfter = new Date(b[label.field]).getTime()

    return dateBefore - dateAfter
  })

  return result
}

export const mapTimeSeriesProps = (
  values: QueryRunResponse,
  visualization: WidgetVisualization<TimeSeriesVisualizationOptions>
): Props => {
  let result: Props = {}
  const label = visualization.options.label
  const value = visualization.options.value
  const length = values.rows.filter(
    (row) => row[label?.field] && (row[value?.field] as number) > 0
  )?.length

  if (length) {
    result = {
      options: {
        chart: {
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: length <= 60 ? length - 1 : 60,
          labels: {
            rotateAlways: true,
            rotate: -75,
            minHeight: 100,
            style: {
              fontSize: '9px',
            },
            formatter: (val) => {
              const utc = generateUTC(new Date(val))

              return utc.toLocaleDateString('tr-TR', {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            },
          },
          categories: values?.rows
            .filter(
              (row) => row[label?.field] && (row[value?.field] as number) > 0
            )
            .map((row) => row[label?.field] as string),
        },
        stroke: {
          curve: 'straight',
        },
        dataLabels: {
          enabled: false,
          formatter: (data: number) => {
            return numberFormat(
              data,
              value?.format || ({} as TimeSeriesVisualizationNumberFormat)
            )
          },
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: [colors.N600],
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: 'top',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (data: number) => {
              return numberFormat(
                data,
                value?.format || ({} as TimeSeriesVisualizationNumberFormat)
              )
            },
          },
        },
        colors: ['#1382a3'],
        theme: {
          mode: visualization.theme,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
      },
      series: [
        {
          name: value?.alias || value?.field,
          data: values.rows
            .filter(
              (row) => row[label?.field] && (row[value?.field] as number) > 0
            )
            .map((row) => row[value?.field]),
        },
      ],
    }
  }

  return result
}
