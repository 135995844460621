import React from 'react'
import DataBox from '@zera-admin/alice-data-box'
import EmptyState from '@zera-admin/empty-state'

import { TweetImpressionsProps } from './types'

const TweetImpressions: React.FunctionComponent<TweetImpressionsProps> = ({
  data,
}) => {
  return data && Object.keys(data).length > 0 ? (
    <DataBox
      values={[
        {
          text: data.AverageImpressionCount,
          title: 'AverageImpressionCount',
        },
        {
          text: data.MaxImpressionCount,
          title: 'MaxImpressionCount',
        },
      ]}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default TweetImpressions
