import { IconName } from '@zera-admin/icon'

import { SchemaObject } from 'app/library/layout-builder'

export const DESIGNER_COMPONENTS: IDesignerComponents[] = [
  {
    name: 'Sütun',
    icon: 'board',
    schema: 'column',
    description: "Element'i dashboard üzerine sürükleyebilirsiniz.",
    size: 6,
  },
  {
    name: 'Widget',
    icon: 'graph-bar',
    schema: 'widget',
    size: 6,
    description: "Element'i dashboard üzerine sürükleyebilirsiniz.",
  },
  {
    name: 'Section',
    icon: 'overview',
    schema: 'section',
    description: "Section'ı dashboard üzerine sürükleyebilirsiniz.",
  },
  {
    name: 'Sayfa',
    icon: 'page',
    schema: 'page',
    description: 'Sayfayı dashboard üzerine sürükleyebilirsiniz.',
  },
  {
    name: 'Başlık',
    icon: 'quote',
    schema: 'heading',
    description: "Element'i dashboard üzerine sürükleyebilirsiniz.",
  },
  {
    name: 'Paragraf',
    icon: 'detail-view',
    schema: 'paragraph',
    description: "Element'i dashboard üzerine sürükleyebilirsiniz.",
  },
  {
    name: 'Görsel',
    icon: 'image',
    schema: 'image',
    description: "Element'i dashboard üzerine sürükleyebilirsiniz.",
  },
  {
    name: 'Özel Bileşen',
    icon: 'discover-filled',
    schema: 'custom-component',
    description: "Element'i dashboard üzerine sürükleyebilirsiniz.",
  },
]

export interface IDesignerComponents {
  description?: string
  disabled?: boolean
  icon: IconName
  name: string
  schema: string
  size?: number
  children?: SchemaObject
}
