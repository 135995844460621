import {
  SeparatedArray,
  SqlComparison,
  SqlExpression,
  SqlMulti,
  SqlQuery,
  SqlRef,
  SqlWhereClause,
} from 'druid-query-toolkit'
import { ConditionRuleItemProps } from '@zera-admin/condition-builder'

import { QueryInterval, QueryRaw } from 'services/http/bi-tool/query/types'

import { druidOperator, value } from './helpers'
import {
  INQUERY_SYNTAX_REGEX,
  PARANTHESES_REGEX,
  TIME_SYNTAX_REGEX,
} from './constants'
import { getIntervalAsISO } from '../date'

export const druid = (
  sql: string,
  level: number = 1,
  rules?: ConditionRuleItemProps[],
  intervals?: QueryInterval,
  main?: boolean
): string | null => {
  if (!sql) {
    return null
  }

  let result = sql

  try {
    let parsed = SqlQuery.parse(sql)

    if (level > 1) {
      const subSelect = findSubSelect(parsed)
      const subWhere = findSubWhere(parsed)

      if (subSelect && subSelect instanceof SqlQuery) {
        const parsedSubSelect = SqlQuery.parse(
          druid(subSelect.toString(), level - 1, rules, intervals) || ''
        )

        parsed = updateSubSelect(parsed, parsedSubSelect)
      }

      if (subWhere && subWhere instanceof SqlQuery) {
        const parsedSubWhere = SqlQuery.parse(
          druid(subWhere.toString(), level - 1, rules, intervals) || ''
        )

        parsed = updateSubWhere(parsed, parsedSubWhere)
      }
    }

    if (level <= 1 || main) {
      if (intervals) {
        const value = getIntervalAsISO(intervals)

        parsed = parsed.changeWhereClause(
          removeColumnFromWhere('createdAt', parsed.whereClause)
        )
        parsed = parsed.addWhere(
          `TIME_PARSE(createdAt) >= '${value.start}' AND TIME_PARSE(createdAt) <= '${value.end}'`
        )
      }

      if (rules?.length && main) {
        rules.forEach((rule) => {
          parsed = parsed.addWhere(
            `${rule.field}${druidOperator(rule.operator as string)}${value(
              rule.value,
              rule.operator as string
            )}`
          )
        })
      }
    }

    result = parsed.toString()
  } catch (err) {
    console.log(err)
  }

  return result
}

export const query = (raw: QueryRaw): string | null => {
  if (!raw.sql) {
    return ''
  }

  let result = raw.sql

  try {
    result = raw.sql.replace(TIME_SYNTAX_REGEX, (match, name) => {
      const field = match.replace(PARANTHESES_REGEX, '').replace(name, '')
      const reference = raw.intervals?.find(
        (interval) => interval.name === name
      )

      if (reference) {
        const value = getIntervalAsISO(reference.interval)

        return `TIME_PARSE(${field}) >= '${value.start}' AND TIME_PARSE(${field}) <= '${value.end}'`
      } else {
        return ''
      }
    })

    result = result.replace(INQUERY_SYNTAX_REGEX, (match, name) => {
      const field = match.replace(PARANTHESES_REGEX, '').replace(name, '')
      const reference = raw.inqueries?.find((inquery) => inquery.name === name)

      if (reference) {
        return `${field} ${
          reference.values?.length
            ? `IN (${reference.values
                ?.map((value: string) => `'${value}'`)
                .join(',')})`
            : `IS NOT NULL`
        }`
      } else {
        return ''
      }
    })

    result = result.replaceAll('`', '')
  } catch (err) {
    console.log(err)
  }

  return result
}

const removeColumnFromWhere = (name: string, where?: SqlWhereClause) => {
  let result = where as SqlWhereClause

  if (result) {
    let expression = result.expression as SqlMulti
    let args = expression?.args as SeparatedArray<SqlExpression>
    let index = findArgumentIndex(name, args)

    if (index !== -1) {
      args = args.remove(index) as SeparatedArray<SqlExpression>
      expression = expression.changeArgs(args)
      result = result.changeExpression(expression)
    }

    if (args.separators.length && findArgumentIndex(name, args) !== -1) {
      result = removeColumnFromWhere(name, result)
    }
  }

  return result
}

const updateSubSelect = (query: SqlQuery, updated: SqlQuery) => {
  let result = query

  result = result.changeFromExpressions([updated])

  return result
}

const updateSubWhere = (query: SqlQuery, updated: SqlQuery) => {
  let result = query
  let expression = result?.whereClause?.expression as SqlMulti
  let args = expression?.args as SeparatedArray<SqlExpression>
  let index = findSubWhereIndex(result)

  if (index !== -1) {
    const arg = (args.get(index) as SqlComparison).changeRhs(updated)

    args = args.change(index, arg)
    expression = expression.changeArgs(args)
    result = result.changeWhereExpression(expression)
  }

  return result
}

const findArgumentIndex = (
  name: string,
  args: SeparatedArray<SqlExpression>
): number => {
  return (args.values as SqlComparison[]).findIndex(
    (value) => (value.lhs as SqlRef).columnRefName.name === name
  )
}

const findSubWhereIndex = (query: SqlQuery) => {
  const args = (query?.whereClause?.expression as SqlMulti)?.args

  return (args.values as SqlComparison[])?.findIndex(
    (value) => value.rhs instanceof SqlQuery
  )
}

const findSubWhere = (query: SqlQuery) => {
  const args = (query?.whereClause?.expression as SqlMulti)?.args

  if (args?.values?.length) {
    return (args.values as SqlComparison[]).find(
      (value) => value.rhs instanceof SqlQuery
    )?.rhs
  }
}

const findSubSelect = (query: SqlQuery) => {
  if (query.fromClause?.expressions?.values?.length) {
    return query.fromClause?.expressions?.values[0]
  }
}
