import { Column } from '@zera-admin/page'
import PdfPageLayout from './PdfPageLayout'
import { PdfPageProps } from './types'

import { Tweets } from '../report-detail'
import Icon from '@zera-admin/icon'
import { getMonthAndYear } from 'app/functions'
const Page13: React.FunctionComponent<PdfPageProps> = ({
  personReport,
  index,
}) => {
  let sliceMin
  let sliceMax
  if (index === 1) {
    sliceMin = [5, 10]
    sliceMax = [10, 15]
  } else if (index === 2) {
    sliceMin = [15, 20]
    sliceMax = [20, 25]
  } else if (index === 3) {
    sliceMin = [25, 30]
    sliceMax = [30, 35]
  } else if (index === 4) {
    sliceMin = [35, 40]
    sliceMax = [40, 45]
  } else {
    sliceMin = [45, 50]
    sliceMax = [40, 45]
  }

  return (
    <PdfPageLayout personReport={personReport}>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Kişinin Türkiye hakkında postları
            </h2>
            <Tweets
              data={personReport?.data.result.Statistic.turkeyPerceptionTweets.slice(
                sliceMin[0],
                sliceMin[1]
              )}
              profile={personReport.data?.result?.TrackingUser}
              cutContent={true}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
      {index !== 5 && (
        <div style={{ width: '40vw', border: '1px solid grey' }}>
          <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
            <p>
              {new Date(personReport.data.crawlingEndedAt).toLocaleString()}{' '}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Column xs={{ size: 12 }}>
              <h2
                style={{
                  display: 'flex',
                  margin: 2,
                  justifyContent: 'center',
                  fontWeight: 500,
                  color: '#4c5153',
                  fontSize: 14,
                }}
              >
                Kişinin Türkiye hakkında postları
              </h2>
              <Tweets
                data={personReport?.data.result.Statistic.turkeyPerceptionTweets.slice(
                  sliceMax[0],
                  sliceMax[1]
                )}
                profile={personReport.data?.result?.TrackingUser}
                cutContent={true}
              />
            </Column>
          </div>
          <Column>
            <div
              style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}
            >
              <Icon name="calendar" size="small" color="blue" />
              &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
              {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
              akışı gösterilmektedir
            </div>
          </Column>
        </div>
      )}
    </PdfPageLayout>
  )
}
export default Page13
