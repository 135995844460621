import { request } from 'services/http/bi-tool/instance'

import type { Field, FieldRequest } from './types'

import { mapFieldForResponse } from './mappers'

export const get = (raw: FieldRequest) =>
  request<Field[]>({
    method: 'get',
    url: 'field',
    params: raw,
    mappers: {
      res: mapFieldForResponse,
    },
  })
