import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { TableVisualizationOptions } from '../types'

export const generateTableOptions = (
  options: TableVisualizationOptions,
  query: Query,
  fields: Field[]
): TableVisualizationOptions => {
  const result = options
  const summarizes = [...(query.aggregations || []), ...(query.groups || [])]

  result.columns = []

  summarizes.forEach((summarize) => {
    if (summarize.field) {
      const reference = fields.find((value) => value.field === summarize.field)

      if (reference && result.columns) {
        result.columns.push({
          id: reference.field,
          name: reference.field,
          type: reference.type,
          alias: reference.alias,
        })
      }
    }
  })

  return result
}

export const generateTableVisualization = (
  fields: Field[]
): WidgetVisualization => {
  return {
    type: 'table',
    options: {
      columns: fields
        .map((value) => ({
          id: value.field,
          name: value.field,
          alias: value.alias || '',
          type: value.type,
          format: {},
        }))
        .slice(5, 15),
    },
  }
}
