import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { InfluentialMapVisualizationOptions } from '../types'

export const generateInfluentialMapOptions = (
  options: InfluentialMapVisualizationOptions,
  query: Query,
  fields: Field[]
): InfluentialMapVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      [group.field]: group.field as string,
    }
  })

  return result
}

export const generateInfluentialMapVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as InfluentialMapVisualizationOptions)

  return {
    type: 'influential-map',
    options: {
      country: options['userCountryDetection'] || options['countryDetection'],
      count: options['rowCount'],
    },
  }
}
