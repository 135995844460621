import styled from 'styled-components'

export const MastodonCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100% !important;

  .mastodon-purple {
    border-radius: 0 0 8px 8px;
    background-color: #17063b;
  }

  .post {
    display: flex;
    flex-direction: column;

    .post-header {
      display: flex;
      align-items: center;
      padding: 16px;
      justify-content: space-between;

      .post-header-profile-info-date {
        span {
          font-size: 14px;
          color: #333;
        }
      }

      .post-header-profile {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .post-header-profile-picture {
        position: relative;
        img {
          width: 48px;
          height: 48px;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      .post-header-profile-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .post-header-profile-info-name {
          span {
            font-weight: 500;
            font-size: 14px;
            color: #000;
          }
        }
        .post-header-profile-info-username {
          span {
            font-weight: inherit;
            font-size: 14px;
            color: #45455f;
          }
        }
      }
    }

    .post-body {
      padding: 0 16px;
      margin-bottom: 16px;

      .post-body-text {
        font-size: 16px;
        line-height: 24px;
        color: #333;
        margin-bottom: 16px;

        div {
          outline: none;
          white-space: break-spaces;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          hyphens: auto;
        }

        .read-more {
          background: none;
          border: none;
          color: #0078ff;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .post-body-image {
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 8px;
          margin-bottom: 16px;
        }
      }

      .post-body-actions {
        display: flex;
        align-items: center;
        gap: 16px;
        color: #606085;
        font-weight: 600;
        stroke: #606085;

        .post-body-actions-reply,
        .post-body-actions-boost,
        .post-body-actions-fav {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            width: 24px;
            height: 24px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }

    .post-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid #f0f0f0;

      .post-footer-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 8px 0;
        img {
          width: 150px;
          object-fit: cover;
        }
      }

      .post-footer-url {
        color: #fff;
        font-size: 14px;
        font-weight: 600;

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 8px 0;

        a {
          color: #fff;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
`
