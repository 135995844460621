import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { MentionProps } from './types'

const Mention: React.FunctionComponent<MentionProps> = ({ data }) => {
  const config: Props = {
    options: {
      chart: {
        id: 'mention',
      },
      colors: ['#ff6f00'],
      xaxis: {
        categories: data?.map((item: any) => '@' + item?.userMention),
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true,
        },
      },
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: 'Mention sayısı',
        data: data?.map((item: any) => item?.mentCount),
      },
    ],
  }

  return data && data.length > 0 ? (
    <Chart options={config.options} series={config.series} type="bar" />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Mention
