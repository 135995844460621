import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import { CreateQuoraResponse } from 'services/http/backoffice/definition/types'
import { getCountryPerceptions } from '../../../services/http/backoffice/country-perception/endpoints'
import { useFlagcardContext } from 'app/contexts/flagcard'

import backofficeDefinitionService from 'services/http/backoffice/definition/'
import { IFormValues } from './types'

import Page from '@zera-admin/page'
import { PageHeader, Row, Column } from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { RadioGroup } from '@zera-admin/radio'
import Button from '@zera-admin/button'
import Input from '@zera-admin/input'
import Checkbox from '@zera-admin/checkbox'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Icon from '@zera-admin/icon'
import Form, {
  FormHeader,
  FormFooter,
  FormHookSubmitProps,
  useForm,
  FormSection,
} from '@zera-admin/form'

function Quora() {
  const [formValues, setFormValues] = useState<IFormValues>({
    countryPerceptions: [],
  })
  const [flagcard] = useFlagcardContext()
  const [blackListWord, setBlackListWord] = useState<string>('')

  useEffect(() => {
    getCountryPerceptions()
      .then((res) => res.data.data)
      .then((res) =>
        setFormValues((state) => ({
          ...state,
          countryPerceptions: res.map((country: any) => {
            return { label: country?.description, value: country?.key }
          }),
        }))
      )
  }, [])

  const formOptions = {
    crawlingType: [
      {
        label: 'Fetch',
        value: 'fetch',
      },
    ],
    definitionType: {
      fetch: [
        {
          label: 'Keyword',
          value: 'keyword',
        },
        {
          label: 'Account',
          value: 'account',
        },
      ],
    },
    allowedLanguages: [
      { value: 'Turkish', label: 'Turkish' },
      { value: 'English', label: 'English' },
    ],
    allowedDbs: [{ value: 'oracle', label: 'Oracle' }],
    environment: [{ label: 'Alice', value: 'Alice' }],
    definitionBlackLists: [{ label: 'BlackListWord', value: 'BlackListWord' }],
    dateOptions: [
      { label: 'AllTime', value: 'AllTime' },
      { label: 'PastHour', value: 'PastHour' },
      { label: 'PastDay', value: 'PastDay' },
      { label: 'PastWeek', value: 'PastWeek' },
      { label: 'PastMonth', value: 'PastMonth' },
      { label: 'PastYear', value: 'PastYear' },
    ],
  }
  const handleAddBlackListWord = () => {
    if (blackListWord) {
      form.handleFieldChange('definitionBlackLists', [
        ...(form.values.definitionBlackLists || []),
        blackListWord,
      ])
      setBlackListWord('')
    }
  }

  const handleRemoveBlackListWord = (index: number) => {
    form.handleFieldChange(
      'definitionBlackLists',
      form.values.definitionBlackLists?.filter((_, i) => i !== index)
    )
  }
  const initialValues: CreateQuoraResponse = {
    crawlingType: 'fetch',
    definitionType: undefined,
    definitionValue: undefined,
    countryPerception: 1,
    allowedLanguages: undefined,
    useIncomingDefinition: false,
    environment: 'Alice',
    definitionBlackLists: undefined,
    allowedDbs: undefined,
    periodicFetch: false,
    period: undefined,
    fetchLimit: 100,
    dateType: undefined,
  }

  const form = useForm<CreateQuoraResponse>({
    initialValues,
    onSubmit: (props) => handlerSubmit(props),
  })

  const handlerSubmit = (props: FormHookSubmitProps<CreateQuoraResponse>) => {
    console.log(form.values)

    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createQuora(form.values)
          .then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
            form.handleBatchUpdate(initialValues)
          })
          .catch((error) => {
            console.log(error)
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
      }
    }
  }

  const renderFormContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={handlerSubmit}>
          <FormHeader
            title="Quora Tanımlama Formu"
            description={
              <div style={{ marginBottom: '1.5rem' }}>
                <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
                <p style={{ margin: '0', color: '#A73121' }}>
                  Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
                </p>
              </div>
            }
          />
          <FormSection>
            <Row>
              <Column xs={{ size: 4 }}>
                <RadioGroup
                  label="*Crawling Type"
                  name="crawlingType"
                  isRequired
                  onChange={(props) => {
                    form.handleFieldChange('definitionType', undefined)
                    form.handleFieldChange(
                      'crawlingType',
                      props.currentTarget.value
                    )
                  }}
                  options={formOptions.crawlingType}
                  value={form.values.crawlingType}
                />
              </Column>
            </Row>
            {form.values.crawlingType && (
              <Row>
                <Column xs={{ size: 4 }}>
                  <RadioGroup
                    label="*Definition Type"
                    name="definitionType"
                    isRequired
                    onChange={(props) => {
                      form.handleFieldChange(
                        'definitionType',
                        props.currentTarget.value
                      )
                    }}
                    options={formOptions.definitionType.fetch}
                    value={form.values.definitionType}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="*Definition Value"
                  name="definitionValue"
                  placeholder={
                    form.values.definitionType === 'keyword'
                      ? 'Takip edilecek kelime belirtin.'
                      : form.values.definitionType === 'account'
                      ? 'Takip edilecek kullanıcı adı girin.'
                      : 'Lütfen önce Definition Type seçin...'
                  }
                  isRequired
                  onChange={(props) =>
                    form.handleFieldChange(
                      'definitionValue',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.definitionValue}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Algı Seçiniz"
                  name="countryPerception"
                  placeholder="Seçim yapın"
                  options={formValues.countryPerceptions}
                  defaultValue={
                    formValues.countryPerceptions.find(
                      (option) => option.label === 'Türkiye'
                    ) || { label: 'Türkiye', value: 1 }
                  }
                  onChange={(props) => {
                    form.handleFieldChange(
                      'countryPerception',
                      (props as SelectOptionProps).value
                    )
                  }}
                  value={formValues.countryPerceptions.find(
                    (option) => option.value === form.values.countryPerception
                  )}
                />
              </Column>
            </Row>

            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Database Seçimi"
                  name="allowedDbs"
                  placeholder="Seçim yapın"
                  description="*Çekilen verilerin işlendikten sonra hangi db 'lere kaydedileceğini belirtir."
                  isMultiple
                  options={formOptions.allowedDbs}
                  onChange={(props) => {
                    const value = props as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedDbs',
                      value.length > 0 ? value.map((db) => db.value) : undefined
                    )
                  }}
                  value={form.values.allowedDbs?.map((db) => {
                    return { value: db.toLowerCase(), label: db }
                  })}
                />
              </Column>
            </Row>

            <>
              <Row>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="blackListWord"
                    label="Tanımlama Kara Listesi"
                    placeholder="Bir kara liste kelimesi giriniz"
                    description="Bir kara liste kelimesi giriniz"
                    value={blackListWord}
                    onChange={(props) => {
                      setBlackListWord(props.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4 }}>
                  <Button
                    onClick={handleAddBlackListWord}
                    appearance="primary"
                    children="Ekle"
                    style={{ marginTop: '16px' }}
                  />
                </Column>
              </Row>
              {form.values.definitionBlackLists?.map((word, index) => (
                <Row key={index}>
                  <Column xs={{ size: 4 }}>{word}</Column>
                  <Column>
                    <Button
                      onClick={() => handleRemoveBlackListWord(index)}
                      appearance="error"
                      children="Kaldır"
                      style={{ marginTop: '16px' }}
                    />
                  </Column>
                </Row>
              ))}
            </>
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  name="useIncomingDefinition"
                  label="Bu definitiondan gelen postlar direkt olarak bu definition ile
                  işaretlensin mi ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      props.currentTarget.checked
                    )
                  }}
                  defaultChecked={form.values.useIncomingDefinition}
                  isChecked={form.values.useIncomingDefinition}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  label="Periyodik olarak çekilsin mi ?"
                  name="periodicFetch"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      props.currentTarget.checked
                    )
                  }}
                  isChecked={form.values.periodicFetch}
                />
              </Column>
            </Row>
            {form.values.periodicFetch && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Input
                    name="period"
                    placeholder="Periyot giriniz (0 * * * *)"
                    description="Geçerli bir periyot giriniz"
                    onChange={(props) =>
                      form.handleFieldChange(
                        'period',
                        props.currentTarget.value
                      )
                    }
                    value={form.values.period}
                    isRequired={form.values.periodicFetch}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="*Fetch Limit"
                  name="fetchLimit"
                  min={1}
                  description="Çekilecek maksimum veri sayısı girin. En az 1 olmalı."
                  type="number"
                  isRequired
                  onChange={(props) =>
                    form.handleFieldChange(
                      'fetchLimit',
                      +props.currentTarget.value
                    )
                  }
                  value={form.values.fetchLimit}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Tarih"
                  name="dateType"
                  placeholder="Seçim yapın"
                  description="Seçim yapmazsanız null olarak kaydedilir."
                  options={formOptions.dateOptions}
                  value={
                    form.values.dateType
                      ? {
                          label: form.values?.dateType,
                          value: form.values?.dateType,
                        }
                      : null
                  }
                  onChange={(e) => {
                    form.handleFieldChange(
                      'dateType',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row>
          </FormSection>
          <FormFooter align="start">
            <Button
              appearance="primary"
              iconBefore={<Icon name="add-item" />}
              children="Tanımlama Oluştur"
              type="submit"
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem text="Tanımlama" href="/definition" />
              <BreadcrumbItem
                text="Tanımlama oluştur"
                href="/definition/create"
              />
              <BreadcrumbItem text="Quora" />
            </Breadcrumb>
          }
        />
        {renderFormContent()}
      </Page>
    </MainLayout>
  )
}

export default Quora
