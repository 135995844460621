import React, { useState } from 'react'
import Alert from '@zera-admin/alert'

import * as Styled from './MulticronBuilder.styled'
import { FrequencyType, MulticronProps, MulticronValue } from './types'
import { Column, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Button, { ButtonGroup } from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import DateTimePicker from '@zera-admin/datetime-picker'
import { DateTimePickerHours } from 'app/shared/enums'

const weekDays = [
  { value: 'sunday', label: 'Pazar' },
  { value: 'monday', label: 'Pazartesi' },
  { value: 'tuesday', label: 'Salı' },
  { value: 'wednesday', label: 'Çarşamba' },
  { value: 'thursday', label: 'Perşembe' },
  { value: 'friday', label: 'Cuma' },
  { value: 'saturday', label: 'Cumartesi' },
]

const monthDays = [
  { value: '1', label: 'İlk Günü' },
  { value: '2', label: '2.' },
  { value: '3', label: '3.' },
  { value: '4', label: '4.' },
  { value: '5', label: '5.' },
  { value: '6', label: '6.' },
  { value: '7', label: '7.' },
  { value: '8', label: '8.' },
  { value: '9', label: '9.' },
  { value: '10', label: '10.' },
  { value: '11', label: '11.' },
  { value: '12', label: '12.' },
  { value: '13', label: '13.' },
  { value: '14', label: '14.' },
  { value: '15', label: '15.' },
  { value: '16', label: '16.' },
  { value: '17', label: '17.' },
  { value: '18', label: '18.' },
  { value: '19', label: '19.' },
  { value: '20', label: '20.' },
  { value: '21', label: '21.' },
  { value: '22', label: '22.' },
  { value: '23', label: '23.' },
  { value: '24', label: '24.' },
  { value: '25', label: '25.' },
  { value: '26', label: '26.' },
  { value: '27', label: '27.' },
  { value: '28', label: '28.' },
  { value: '29', label: '29.' },
  { value: 'end', label: 'Son günü' },
]

const monthWeeks = [
  { value: '1', label: '1.' },
  { value: '2', label: '2.' },
  { value: '3', label: '3.' },
  { value: '4', label: '4.' },
]

const frequencyTypes = [
  {
    label: 'Dakikada',
    value: 'minutely',
  },
  {
    label: 'Saatte',
    value: 'hourly',
  },
  {
    label: 'Günde',
    value: 'daily',
  },
  {
    label: 'Haftada',
    value: 'weekly',
  },
  {
    label: 'Ayda',
    value: 'monthly',
  },
  /*  {
    label: 'Ayda (Haftaya Göre)',
    value: 'monthlyByWeek',
  },*/
]

const MulticronBuilder: React.FunctionComponent<MulticronProps> = ({
  defaultValue = {
    frequency: {
      type: 'monthly',
    },
    trigger: {
      dayInMonth: 'start',
      hour: '12:00',
    },
    emails: [],
  },
  values,
  onSave,
  onCancel,
}): JSX.Element => {
  const [value, setValue] = useState<MulticronValue>(defaultValue)
  const [valuesState, setValues] = useState<MulticronValue[]>(values || [])
  const [resetable, setResetable] = useState<boolean>(true)
  const [resetable2, setResetable2] = useState<boolean>(true)
  const [emailToAdd, setEmailToAdd] = useState<string>('')

  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  const stringExpression = (value: MulticronValue) => {
    switch (value.frequency.type) {
      case 'minutely':
        return <p>Dakikada bir kez</p>
      case 'hourly':
        return (
          value.trigger.minuteOnly &&
          'trigger' in value && (
            <p>Her bir saatte bir kez. {value.trigger.minuteOnly}. dakikada.</p>
          )
        )
      case 'daily':
        return (
          value.trigger.hour &&
          'trigger' in value && (
            <p>Günde bir kez. {value.trigger.hour} anında.</p>
          )
        )
      case 'weekly':
        return (
          value.trigger.hour &&
          value.trigger.dayInWeek &&
          'trigger' in value && (
            <p>
              Haftada bir kez.{' '}
              {
                weekDays.find((day) => day.value === value.trigger.dayInWeek)
                  ?.label
              }{' '}
              günü {value.trigger.hour} anında.
            </p>
          )
        )
      case 'monthly':
        return (
          value.trigger.hour &&
          value.trigger.dayInMonth &&
          'trigger' in value && (
            <p>
              Ayda bir kez. Ayın{' '}
              {
                monthDays.find((day) => day.value === value.trigger.dayInMonth)
                  ?.label
              }{' '}
              günü {value.trigger.hour} anında.
            </p>
          )
        )
      case 'monthlyByWeek':
        return (
          value.trigger.hour &&
          value.trigger.weekInMonth &&
          value.trigger.dayInWeek &&
          'trigger' in value && (
            <p>
              Ayda bir kez. Ayın{' '}
              {
                monthWeeks.find(
                  (day) => day.value === value.trigger.weekInMonth
                )?.label
              }{' '}
              haftası{' '}
              {
                weekDays.find((day) => day.value === value.trigger.dayInWeek)
                  ?.label
              }{' '}
              günü {value.trigger.hour} anında.
            </p>
          )
        )
    }
  }
  const renderTriggerFormHourly = () => {
    return (
      <Column xs={{ size: 12 }}>
        <h4>Trigger</h4>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 12 }}>
            <Input
              type="text"
              name="minuteOnly"
              label="Kaçıncı dakikada"
              value={value.trigger.minuteOnly}
              onChange={(event) =>
                setValue({
                  frequency: value.frequency,
                  trigger: {
                    minuteOnly: event.currentTarget.value,
                  },
                  emails: value.emails,
                })
              }
            />
          </Column>
        </Row>
      </Column>
    )
  }
  const renderTriggerFormDaily = () => {
    return (
      <Column xs={{ size: 12 }}>
        <h4>Trigger</h4>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 12 }}>
            <DateTimePicker
              type="time"
              name="hour"
              label="Hangi saatte"
              value={value.trigger.hour}
              onChange={(time) =>
                setValue({
                  frequency: value.frequency,
                  trigger: { hour: time },
                  emails: value.emails,
                })
              }
              times={hoursArray}
            />
          </Column>
        </Row>
      </Column>
    )
  }

  const renderTriggerFormWeekly = () => {
    return (
      <Column xs={{ size: 12 }}>
        <h4>Trigger</h4>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 6 }}>
            <Select
              name="weekday"
              label="Haftanın hangi gününde"
              options={weekDays}
              value={weekDays.find(
                (day) => day.value === value.trigger.dayInWeek
              )}
              onChange={(props) =>
                setValue({
                  frequency: value.frequency,
                  trigger: {
                    ...value.trigger,
                    dayInWeek: (props as SelectOptionProps).value,
                  },
                  emails: value.emails,
                })
              }
            />
          </Column>
          <Column xs={{ size: 6 }}>
            <DateTimePicker
              type="time"
              name="hour"
              label="Hangi saatte"
              value={value.trigger.hour}
              onChange={(time) =>
                setValue({
                  frequency: value.frequency,
                  trigger: { ...value.trigger, hour: time },
                  emails: value.emails,
                })
              }
              times={hoursArray}
            />
          </Column>
        </Row>
      </Column>
    )
  }

  const renderTriggerFormMonthlyByWeek = () => {
    return (
      <Column xs={{ size: 12 }}>
        <h4>Trigger</h4>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 4 }}>
            <Select
              label="Ayın kaçıncı haftasında"
              options={monthWeeks}
              value={monthWeeks.find(
                (week) => week.value === value.trigger.weekInMonth
              )}
              onChange={(props) =>
                setValue({
                  frequency: value.frequency,
                  trigger: {
                    ...value.trigger,
                    weekInMonth: (props as SelectOptionProps).value,
                  },
                  emails: value.emails,
                })
              }
              name="weekInMonth"
            />
          </Column>
          <Column xs={{ size: 4 }}>
            <Select
              name="weekday"
              label="Haftanın hangi gününde"
              options={weekDays}
              value={weekDays.find(
                (day) => day.value === value.trigger.dayInWeek
              )}
              onChange={(props) =>
                setValue({
                  frequency: value.frequency,
                  trigger: {
                    ...value.trigger,
                    dayInWeek: (props as SelectOptionProps).value,
                  },
                  emails: value.emails,
                })
              }
            />
          </Column>
          <Column xs={{ size: 4 }}>
            <DateTimePicker
              type="time"
              name="hour"
              label="Hangi saatte"
              value={value.trigger.hour}
              onChange={(time) =>
                setValue({
                  frequency: value.frequency,
                  trigger: { ...value.trigger, hour: time },
                  emails: value.emails,
                })
              }
              times={hoursArray}
            />
          </Column>
        </Row>
      </Column>
    )
  }

  const renderTriggerFormMonthly = () => {
    return (
      <Column xs={{ size: 12 }}>
        <h4>Trigger</h4>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 6 }}>
            <Select
              name="monthday"
              label="Ayın hangi gününde"
              options={monthDays}
              value={monthDays.find(
                (day) => day.value === value.trigger.dayInMonth
              )}
              onChange={(props) =>
                setValue({
                  frequency: value.frequency,
                  trigger: {
                    ...value.trigger,
                    dayInMonth: (props as SelectOptionProps).value,
                  },
                  emails: value.emails,
                })
              }
            />
          </Column>
          <Column xs={{ size: 6 }}>
            <DateTimePicker
              type="time"
              name="hour"
              label="Hangi saatte"
              value={value.trigger.hour}
              onChange={(time) =>
                setValue({
                  frequency: value.frequency,
                  trigger: { ...value.trigger, hour: time },
                  emails: value.emails,
                })
              }
              times={hoursArray}
            />
          </Column>
        </Row>
      </Column>
    )
  }

  const renderTriggerForm = () => {
    switch (value?.frequency.type) {
      case 'hourly':
        return renderTriggerFormHourly()
      case 'daily':
        return renderTriggerFormDaily()
      case 'weekly':
        return renderTriggerFormWeekly()
      case 'monthly':
        return renderTriggerFormMonthly()
      case 'monthlyByWeek':
        return renderTriggerFormMonthlyByWeek()
      default:
        return null
    }
  }
  const renderFrequencyForm = () => {
    return (
      <Column xs={{ size: 12 }}>
        <h4>Periyod</h4>

        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 12 }}>
            <Select
              label="Periyod türü"
              value={frequencyTypes.find(
                (frequencyType) => frequencyType.value === value?.frequency.type
              )}
              onChange={(props) => {
                setValue({
                  frequency: {
                    type: (props as SelectOptionProps)?.value as FrequencyType,
                  },
                  trigger: {},
                  emails: value.emails,
                })
              }}
              options={frequencyTypes}
              name="frequencyType"
            />
          </Column>
        </Row>
      </Column>
    )
  }
  const renderAddButton = () => {
    return (
      <Column
        xs={{ size: 12 }}
        style={{ display: 'flex', alignItems: 'flex-end' }}
      >
        <Button
          className="list-field-button"
          appearance="primary"
          size="medium"
          children="Ekle"
          onClick={() => {
            if (stringExpression(value)) {
              setValues([...valuesState, value])
              setValue({
                trigger: {},
                frequency: { type: 'monthly' },
                emails: [],
              })
              setResetable(false)
              setTimeout(() => {
                setResetable(true)
              }, 100)
            }
          }}
        />
      </Column>
    )
  }
  const renderEmails = () => {
    return (
      <Column xs={{ size: 12 }}>
        <h4>Eposta Adresleri</h4>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 10 }}>
            {resetable2 && (
              <Input
                label="Eposta Adresi"
                value={emailToAdd}
                onChange={(e) => {
                  setEmailToAdd(e.currentTarget.value)
                }}
                name="emailToAdd"
              />
            )}
          </Column>
          <Column>
            <Button
              className="list-field-button"
              appearance="primary"
              size="small"
              children="Ekle"
              onClick={() => {
                setValue({
                  frequency: value.frequency,
                  trigger: value.trigger,
                  emails: [...value.emails, emailToAdd],
                })
                setEmailToAdd('')
                setResetable2(false)
                setTimeout(() => {
                  setResetable2(true)
                }, 100)
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Row>
              <Column>
                <h4>Gönderim yapılacak adresler</h4>
              </Column>
            </Row>
            {value.emails.map((email, index) => (
              <Row>
                <Column key={index} xs={{ size: 8 }}>
                  {email}{' '}
                </Column>
                <Column>
                  <Button
                    appearance="subtle"
                    onClick={() => {
                      setValue({
                        frequency: value.frequency,
                        trigger: value.trigger,
                        emails: [
                          ...value.emails.slice(0, index),
                          ...value.emails.slice(index + 1),
                        ],
                      })
                    }}
                    children="Sil"
                  />
                </Column>
              </Row>
            ))}
          </Column>
        </Row>
      </Column>
    )
  }
  return (
    <Styled.MulticronBuilder>
      <Styled.ListField>
        <Row>
          {renderFrequencyForm()}
          {resetable && renderTriggerForm()}
          {renderEmails()}
          {renderAddButton()}
        </Row>
        <hr />
        {/*{renderStringExpression()}*/}
      </Styled.ListField>
      <hr />
      <h3>Onaylanmayı bekleyen görevler</h3>
      {valuesState.length > 0 ? (
        valuesState.map((value, index) => {
          return (
            <div key={index} style={{ marginBottom: '5px' }}>
              <Alert appearance="info">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {stringExpression(value)}
                  <Button
                    appearance="subtle-link"
                    size="none"
                    onClick={() => {
                      setValues([
                        ...valuesState.slice(0, index),
                        ...valuesState.slice(index + 1, valuesState.length),
                      ])
                    }}
                  >
                    <Icon name="cross-circle" style={{ float: 'right' }} />
                  </Button>
                </div>
              </Alert>
            </div>
          )
        })
      ) : (
        <Alert appearance="warning">
          Henüz eklediğiniz bir zamanlanmış görev yok
        </Alert>
      )}
      <hr />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonGroup>
          {onSave && (
            <Button
              appearance="primary"
              size="medium"
              onClick={() => onSave(valuesState)}
            >
              Hepsini Onayla
            </Button>
          )}
          {onCancel && (
            <Button
              size="medium"
              appearance="subtle"
              onClick={() => onCancel()}
            >
              Kapat
            </Button>
          )}
        </ButtonGroup>
      </div>
    </Styled.MulticronBuilder>
  )
}

export default MulticronBuilder
