import * as Styled from './InfluentialAccountTweetPagesByCountry.styled'
import { InfluentialAccountTweetPagesByCountryProps } from './types'
import { TweetCard } from '../../components/tweet-card'
import { Row, Column } from '@zera-admin/page'
import UpdateButton from '../../components/update-button/UpdateButton'
import Analysis from '../../components/analysis'
import { Tweet } from '../../widgets/types'
import { useEffect, useState } from 'react'
import WidgetLayout from '../../widgets/widget-layout'
import { AnalysisData } from '../../components/analysis/types'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { getAgendaReportTweet } from 'services/http/report/agenda-report-4/endpoint'

const InfluentialAccountTweetPagesByCountry = ({
  date,
  data,
  isEditValue,
  pageNumber,
  setIsEditValue,
  title,
  metaData,
}: InfluentialAccountTweetPagesByCountryProps) => {
  const [tweetDatas, setTweetDatas] = useState<Tweet[] | undefined>(data)
  const [tweetId, setTweetId] = useState<string | undefined>('')

  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  const [flagCard] = useFlagcardContext()

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setTweetDatas(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChange = (key: string, value: string, id: string) => {
    tweetDatas &&
      setTweetDatas(
        tweetDatas.map((tweet) =>
          tweet.EntityKey === id ? { ...tweet, [key]: value } : tweet
        )
      )
  }

  const handleDeleteTweet = (id: string) => {
    setTweetDatas(tweetDatas?.filter((tweet) => tweet.EntityKey !== id))
  }

  const addTweet = () => {
    getAgendaReportTweet({
      GroupId: metaData?.GroupId,
      TweetId: tweetId,
    })
      .then((res) => {
        tweetDatas &&
        tweetDatas.length > 0 &&
        tweetDatas[0].EntityKey === tweetId
          ? flagCard.add({
              appearance: 'error',
              title: 'Hata',
              children: 'Bu tweet zaten eklenmiş.',
            })
          : setTweetDatas([...(tweetDatas || []), res.data.data])
      })
      .catch((err) => {
        console.log(err)
        flagCard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Veritabanında tweet bulunamadı. Lütfen tweet ID bilgisini kontrol ediniz.',
        })
      })
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      <Styled.InfluentialPeoplesTweetContainer
        className={`influential-peoples-tweet`}
      >
        <div className="update-btn">
          <UpdateButton
            {...editProps}
            contentData={{
              Tweets: tweetDatas,
            }}
            title={pageData?.title}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </div>

        {tweetDatas && tweetDatas.length > 0 && tweetDatas[0].Analysis && (
          <Row style={{ padding: '0 1rem' }}>
            {tweetDatas &&
              tweetDatas?.length > 0 &&
              tweetDatas?.map(
                (tweet) =>
                  tweet.Analysis && (
                    <Column xs={{ size: 6 }}>
                      <Analysis
                        editMode={editMode}
                        onChange={onChange}
                        data={tweet as AnalysisData}
                      />
                    </Column>
                  )
              )}
          </Row>
        )}

        {editMode && tweetDatas && tweetDatas?.length < 2 && (
          <div className="add-tweet">
            <input
              type="text"
              placeholder="Add Tweet ID"
              value={tweetId}
              onChange={(e) => setTweetId(e.target.value)}
            />
            <button onClick={addTweet}>Add Tweet</button>
          </div>
        )}

        <Row
          style={{ padding: '0 1rem' }}
          className="influential-peoples-tweet-row"
        >
          {tweetDatas &&
            tweetDatas?.length > 0 &&
            tweetDatas?.map((tweet: Tweet) => (
              <Column
                className="tweet"
                xs={{ size: 6 }}
                style={{ marginTop: '10px' }}
              >
                <TweetCard
                  multiple={true}
                  onChange={onChange}
                  setData={setTweetDatas}
                  data={tweet}
                  editMode={editMode}
                />
                {editMode && (
                  <button
                    className="delete-tweet"
                    onClick={() => handleDeleteTweet(tweet.EntityKey)}
                  >
                    X
                  </button>
                )}
              </Column>
            ))}
        </Row>
      </Styled.InfluentialPeoplesTweetContainer>
    </WidgetLayout>
  )
}

export default InfluentialAccountTweetPagesByCountry
