import styled from 'styled-components'
import { colors } from '@atlaskit/theme'

export const DashboardSettingsFields = styled.div`
  margin-top: 12px;
  width: 100%;
`

export const DashboardSettingsField = styled.div<{ isSelected?: boolean }>`
  button {
    margin-left: auto;
    padding: 0px;

    &.button-subtle--delete {
      color: ${colors.red} !important;
      text-decoration: underline;
    }

    &:hover {
      background: transparent;
    }
  }
`
