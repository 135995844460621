import React, { useEffect, useState } from 'react'
import { Column, Row } from '@zera-admin/page'
import Form, { useForm } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Modal from '@zera-admin/modal'

import { NamedEntityRecognitionProps } from '../types'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { dependent, languageCode } from 'app/shared/constants'
import Button from '@zera-admin/button'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'
import entityTypesService from 'services/http/dataset/entity-type'
import namedEntityRecognitionsService, {
  CreateNamedEntityRecognitionRequest,
} from 'services/http/dataset/named-entity-recognition'
import { validation } from 'app/functions'
import { useFlagcardContext } from 'app/contexts/flagcard'
import Table from '@zera-admin/table'
import Icon from '@zera-admin/icon'
import Alert from '@zera-admin/alert'

const validations = {}

const NamedEntityRecognition: React.FunctionComponent<
  NamedEntityRecognitionProps
> = ({ onClose, values }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [isLoadingEnity, setIsLoadingEntity] = useState(true)
  const [entityType, setEntityType] = useState<SearchboxOptionProps[]>([])
  const [resetable, setResetable] = useState<boolean>(true)
  const [flagcard] = useFlagcardContext()

  const form = useForm<CreateNamedEntityRecognitionRequest>({
    initialValues: values,
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = () => {
    if (form?.values?.wordList?.length) {
      setIsLoading(true)
      namedEntityRecognitionsService
        .create(form.values)
        .then(() => {
          flagcard.add({
            appearance: 'success',
            title: 'Kayıt işlemi başarılı',
            children:
              'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
          })
          onClose?.()
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setError('Kelime listesi boş olduğunda kayıt yapılamaz')
      setTimeout(() => {
        setError(null)
      }, 3000)
    }
  }

  const [subform, setSubform] = useState<any>({})
  const handleSubformChange = (field: string, value: any) => {
    setSubform({ ...subform, [field]: value })
  }

  const handleSubformSubmit = () => {
    if (
      subform.text &&
      subform.entityTypeId &&
      subform.dependent &&
      subform.order
    ) {
      form.handleFieldChange('wordList', [
        ...(form.values.wordList || []),
        subform,
      ])
      setSubform({})
      setResetable(false)
      setTimeout(() => {
        setResetable(true)
      }, 25)
    }
  }

  const wordListRemove = (index: number) => {
    const newValue = form.values.wordList?.slice() || []
    newValue.splice(index, 1)
    form.handleFieldChange('wordList', newValue)
  }

  useEffect(() => {
    getEntityType()
  }, [])

  const getEntityType = async (value?: string) => {
    setIsLoadingEntity(true)

    const response = await entityTypesService.get(value)
    const mapped = response.data?.list.map((entityType) => ({
      label: entityType.name,
      value: entityType.id as number,
    }))

    setEntityType(mapped)
    setIsLoadingEntity(false)

    return mapped
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'text',
        text: 'Kelime',
      },
      {
        name: 'order',
        text: 'Sıra',
      },
      {
        name: 'entityTypeName',
        text: 'Varlık türü',
        render: (item: any) => {
          return entityType.find(
            (childrenItem: any) => childrenItem.value === item.entityTypeId
          )?.label
        },
      },
      {
        name: 'dependentStr',
        text: 'Bağımlılık',
        render: (item: any) =>
          item.dependent === 1
            ? 'Sol'
            : item.dependent === 2
            ? 'Sağ'
            : 'Seçilmedi',
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: any, index: number) => (
          <Button appearance="error" onClick={() => wordListRemove(index)}>
            Sil
          </Button>
        ),
      },
    ]

    if (form.values.wordList) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={form.values.wordList}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <Modal
      actions={[
        {
          appearance: 'primary',
          text: 'Kaydet',
          onClick: () => handleSubmit(),
          isLoading: isLoading,
        },
        {
          appearance: 'subtle',
          text: 'Vazgeç',
          onClick: onClose,
        },
      ]}
      width="large"
      onClose={onClose}
      title="İsimlendirme veri seti eğitme"
    >
      <Form>
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Select
              errorMessage={form.errors.languageCode}
              label="Dil kodu"
              name="languageCode"
              value={languageCode.find(
                (item) => item.value === form.values.languageCode
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'languageCode',
                  (props as SelectOptionProps).value
                )
              }
              options={languageCode}
              position="fixed"
            />
          </Column>
          <Column xs={{ size: 12 }}>
            {resetable && (
              <Row>
                <Column xs={{ size: 3 }}>
                  <Input
                    label="Kelime"
                    name="text"
                    value={subform.text}
                    placeholder="Örnek"
                    onChange={(props) =>
                      handleSubformChange('text', props.currentTarget.value)
                    }
                  />
                </Column>
                <Column xs={{ size: 3 }}>
                  <SearchBox
                    label="Varlık türü"
                    name="entityTypeId"
                    options={entityType}
                    onFetchOptions={(value) => getEntityType(value)}
                    value={entityType.find(
                      (item: any) => item.value === subform.entityTypeId
                    )}
                    onChange={(props) =>
                      handleSubformChange(
                        'entityTypeId',
                        (props as SearchboxOptionProps).value
                      )
                    }
                    placeholder="Seçim yapın"
                    isLoading={isLoadingEnity}
                  />
                </Column>
                <Column xs={{ size: 3 }}>
                  <Select
                    label="Bağımlılık durumu"
                    name="dependent"
                    options={dependent}
                    value={dependent.find(
                      (item: any) => item.value === subform.dependent
                    )}
                    onChange={(props) => {
                      handleSubformChange(
                        'dependent',
                        (props as SelectOptionProps).value
                      )
                    }}
                    placeholder="Seçim yapın"
                    position="fixed"
                  />
                </Column>
                <Column xs={{ size: 2 }}>
                  <Input
                    label="Order"
                    name="order"
                    placeholder="Örnek"
                    value={subform.order}
                    onChange={(props) =>
                      handleSubformChange('order', props.currentTarget.value)
                    }
                  />
                </Column>
                <Column xs={{ size: 1, align: 'end' }}>
                  <Button
                    type="button"
                    appearance="primary"
                    onClick={() => handleSubformSubmit()}
                    iconAfter={<Icon name="add" />}
                  />
                </Column>
              </Row>
            )}
          </Column>
        </Row>
        {renderPageTable()}
      </Form>
      {error && (
        <React.Fragment>
          <br />
          <Alert
            style={{ marginTop: '50px' }}
            appearance="warning"
            title="Hata"
          >
            {error}
          </Alert>
        </React.Fragment>
      )}
    </Modal>
  )
}

export default NamedEntityRecognition
