import React, { useState, useCallback, useEffect } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container } from '@zera-admin/page'
import Button from '@zera-admin/button'
import { uid } from '@zera-admin/utils'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'
import MainLayout from 'layouts/main'
import appService, { App } from 'services/http/identity-server/app'

import { UpdateAppProps } from './types'
import AppForm from './components/AppForm'

const UpdateApp: React.FunctionComponent<UpdateAppProps> = ({
  match,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()
  const [values, setValues] = useState({ id: 0, name: '' })

  const handleUpdate = async (data: App) => {
    if (values) {
      setIsLoading(true)

      try {
        await appService.update({ ...data, id: values.id })

        flagcard.add({
          title: 'Uygulama güncellendi',
          children: 'Geçerli uygulama başarıyla güncellendi.',
        })
      } catch {
        popup.add({
          title: 'Uygulama güncellenemedi',
          children:
            'Uygulama güncellenirken bir sorun oluştu, lütfen tekrar deneyin.',
        })
      }

      setIsLoading(false)
    }
  }

  const handleDelete = () => {
    const popupId = uid()

    const onClick = () => {
      if (values) {
        setIsLoading(true)

        appService
          .del(values.id)
          .then(() => {
            flagcard.add({
              title: 'Uygulama silindi',
              children: 'Geçerli uygulama başarıyla silindi.',
            })

            history.push('/apps')
          })
          .catch(() => {
            popup.add({
              title: 'Uygulama silinemedi',
              children:
                'Uygulama silinirken bir sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
            })
          })
          .finally(() => {
            setIsLoading(false)
            popup.remove(popupId)
          })
      }
    }

    const onClose = () => {
      popup.remove(popupId)
    }

    popup.add({
      id: popupId,
      appearance: 'warning',
      title: 'Uygulama silinsin mi?',
      children:
        'Bu uygulamayı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.',
      actions: [
        {
          isLoading,
          onClick: onClick,
          text: 'Sil',
        },
        {
          onClick: onClose,
          text: 'Vazgeç',
        },
      ],
      onClose,
    })
  }

  const getValues = useCallback(async () => {
    const id = match.params.id

    try {
      const response = await appService.getById(id)

      setValues(response.data)
    } catch {
      const popupId = uid()

      const onClose = () => {
        history.push('/apps')
        popup.remove(popupId)
      }

      popup.add({
        id: popupId,
        title: 'Uygulama getirilemedi',
        children:
          'Uygulama bilgisi getirilirken bi sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
        onClose,
        actions: [
          {
            appearance: 'primary',
            text: 'Geri dön',
            onClick: onClose,
          },
        ],
      })
    }

    setIsReady(true)
  }, [match.params.id, popup, history])

  useEffect(() => {
    getValues()
  }, [getValues])

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Uygulamalar" href="/apps" />
      <BreadcrumbItem text="Uygulama detayı" />
    </Breadcrumb>
  )

  return (
    <MainLayout isLoading={!isReady}>
      <Container width="narrow">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            actions={
              <Button
                appearance="error"
                isDisabled={isLoading}
                onClick={handleDelete}
              >
                Uygulamayı sil
              </Button>
            }
            bottomBar={
              <p>
                Oluşturduğunuz uygulama bilgilerini aşağıdan
                güncelleyebilirsiniz.
              </p>
            }
          >
            Uygulama görüntüleniyor: {values?.name || '-'}
          </PageHeader>
          <AppForm
            loading={isLoading}
            values={values}
            operation="update"
            onSubmit={handleUpdate}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default UpdateApp
