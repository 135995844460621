import ColumnDesigner from './ColumnDesigner'

import { ColumnProps } from './types'

const Column: React.FunctionComponent<ColumnProps> = (props) => {
  const desktopClass = 'fa-Col-' + (props.sizes?.lg || 'none')
  const tabletClass = 'fa-Col-tb-' + (props.sizes?.sm || 'none')
  const mobileClass = 'fa-Col-mb-' + (props.sizes?.xs || 'none')

  const style = props.designing
    ? {
        minHeight: '100px',
        padding: '0px',
      }
    : {
        minHeight: 'auto',
      }

  return (
    <div
      data-schema-type="column"
      data-schema-id={props.id}
      className={`${desktopClass} ${tabletClass} ${mobileClass} fa-draggable fa-Col ${
        props.designing ? 'designing' : ''
      } ${props.defaultElement ? 'is-default' : ''}`}
      style={{ ...style, position: 'relative' }}
    >
      {props.designing ? <ColumnDesigner {...props} /> : props.children}
    </div>
  )
}

export default Column
