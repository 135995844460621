import { Table, Datasource } from './types'

export const mapDatasourceForResponse = (raws: string[]): Datasource[] =>
  raws?.map((raw) => ({
    id: raw,
    name: raw,
  }))

export const mapTableForResponse = (raws: string[]): Table[] =>
  raws?.map((raw) => ({
    id: raw,
    name: raw,
  }))
