import { Definition } from 'services/http/backoffice/definition'
import { request } from 'services/http/report/instance'

export const getAllGroup = (
  pageIndex?: number,
  userName?: string,
  status?: string
) =>
  request<any>({
    method: 'get',
    url: 'crawl-task/all-group',
    params: {
      PathId: 'person-detail',
      PageIndex: pageIndex,
      UserName: userName,
      Status: status,
    },
  })

export const getAllProfiles = (
  pageIndex?: number,
  userScreenName?: string,
  status?: string,
  reportName?: string
) =>
  request<any>({
    method: 'get',
    url: 'profile-report/all',
    params: {
      PageIndex: pageIndex,
      UserScreenName: userScreenName,
      Status: status,
      ReportName: reportName,
    },
  })

export const event = (groupId: string) =>
  request<{ data: Definition[] }>({
    method: 'get',
    url: 'crawl-task/event?pageSize=50',
    params: { GroupId: groupId },
  })

export const specificPdf = (url: string, FileName: string) =>
  request<any>({
    method: 'get',
    url: 'api/pdf/specific-elements',
    params: {
      Url: url,
      AuthNeeded: true,
      WindowWidth: 1400,
      WindowHeight: 900,
      AreQuerySelectorsMultiple: true,
      AreElementsToHideQuerySelectorsMultiple: true,
      QuerySelectors: ['.printed'],
      ElementsToHideQuerySelectors: ['.btn-header', '.btn-save', '.update-btn'],
      WaitForSpinner: true,
      SpinnerTimeoutMs: 600000,
    },
    responseType: 'blob',
  })
export const searchUsername = (username: string) =>
  request<any>({
    method: 'get',
    url: 'person-report/search-username',
    params: {
      username,
    },
  })
export const searchProfileName = (
  userScreenName?: string,
  userEntityKey?: string
) =>
  request<any>({
    method: 'get',
    url: 'profile-report/check/user',
    params: {
      userScreenName,
      userEntityKey,
    },
  })
