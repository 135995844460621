import styled from 'styled-components'

export const AgendaReportForm = styled.div`
  footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 0.6rem 0;
  }

  table {
    tbody {
      td {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;

        button {
          padding: 0.2rem;
        }
      }
    }
  }
`

export const AgendaReportFormInput = styled.div`
  position: relative;

  & > span {
    position: absolute;
    right: 1rem;
    top: 2rem;
  }
`
