import { request } from 'services/http/bi-tool/instance'

import type {
  Widget,
  WidgetResponse,
  CreateWidgetRequest,
  UpdateWidgetRequest,
  CreateWidgetResponse,
} from './types'

import {
  mapWidgetForRequest,
  mapWidgetForList,
  mapWidgetForResponse,
} from './mappers'

export const create = (data: CreateWidgetRequest) =>
  request<CreateWidgetResponse>({
    method: 'post',
    url: 'widget',
    data,
    mappers: {
      req: mapWidgetForRequest,
    },
  })

export const get = (
  title?: string,
  pageIndex?: number,
  pageSize?: number,
  withDashboardKeys?: boolean
) =>
  request<WidgetResponse>({
    method: 'get',
    url: 'widget',
    params: { title, pageIndex, pageSize, withDashboardKeys },
    mappers: {
      res: mapWidgetForList,
    },
  })

export const getById = (id: string) =>
  request<Widget>({
    method: 'get',
    url: `widget/${id}`,
    mappers: {
      res: mapWidgetForResponse,
    },
  })

export const update = (id: string, data: UpdateWidgetRequest) =>
  request<Widget>({
    method: 'put',
    url: `widget/${id}`,
    data,
    mappers: {
      req: mapWidgetForRequest,
    },
  })

export const del = (id: string) =>
  request<void>({
    method: 'delete',
    url: `widget/${id}`,
  })

export const delList = (query: string) =>
  request<void>({
    method: 'delete',
    url: `widget?${query}`,
  })
