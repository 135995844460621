import styled from 'styled-components'

export const NewsCards = styled.div`
  width: 30%;
  padding: 15px;
  border-right: 1px solid lightgray;
  @media (width >= 2440px) {
    width: 30%;
  }
  @media only screen and (max-width: 520px) {
    width: 90%;
  }
  a {
    color: black;
    text-decoration: none;
  }
`

export const CardText = styled.div`
  color: black;
  margin-top: 1vh;
  font-size: 0.7rem;
  .createdat {
    margin-top: 3vh;
    @media (width >= 2440px) {
      font-size: 16px;
    }
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    color: blue;
    &:hover {
      opacity: 0.6;
      transition: 0.6s;
    }
  }
  p {
    padding-top: 5px;
    .news-h {
      height: 200px;
      overflow: auto;
    }
    .news-none-h {
    }
    @media (width >= 2440px) {
      font-size: 1.4rem;
    }
  }
`
export const CardMain = styled.div`
  width: 100%;
  height: 200px;
  padding-top: 20px;
  @media (width >= 2440px) {
    height: 400px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`

export const CardHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  h3 {
    padding-left: 10px;
  @media (width >= 2440px) {
    font-size:2rem;
  }
  }
  img{
  @media (width >= 2440px) {
    width:100px;
    height:100px;
  }
`
