import { useRootContext } from 'app/contexts/reports'
import { AgendaReportStatus } from 'services/http/report/agenda-report/enums'
import { AgendaReportList } from 'services/http/report/agenda-report'
import {
  AGENDA_REPORT_STATUSES,
  AGENDA_REPORT_INDEPENDENT_STATUSES,
} from './../../constant'
import { IColumnLinkButtonsProps, IDesignerLinkButtonsProps } from './types'

import { LinkButton } from '@zera-admin/button'
import Lozenge from '@zera-admin/lozenge'
import Tooltip from 'components/Tooltip'

const ColumnLinkButtons = ({ groupId }: IColumnLinkButtonsProps) => {
  const { setWithBots } = useRootContext()

  return (
    <>
      <LinkButton
        appearance="subtle-link"
        onClick={() => setWithBots(false)}
        href={`/agenda-reportv4/detail/${groupId}?status=${1}`}
      >
        Görüntüle
      </LinkButton>
    </>
  )
}

const DesignerLinkButtons = ({ groupId }: IDesignerLinkButtonsProps) => {
  const { setWithBots } = useRootContext()

  return (
    <>
      <LinkButton
        appearance="subtle-link"
        href={`/agenda-reportv4/detail/${groupId}?status=2`}
      >
        Düzenle
      </LinkButton>
    </>
  )
}

export const tableColumns = [
  {
    name: 'reportName',
    text: 'Rapor adı',
  },
  {
    name: 'createdAtStr',
    text: 'Rapor istek tarihi',
    render: (props: any) => {
      return new Date(props.createdAt).toLocaleString()
    },
  },
  {
    name: 'crawlingEndedAt',
    text: 'Rapor tamamlanma tarihi',
    render: (props: any) => {
      if (!props.crawlingEndedAt) {
        return '-'
      }

      return new Date(props.crawlingEndedAt).toLocaleString()
    },
  },
  {
    name: 'status',
    text: 'Durumu',
    render: (props: any) => {
      if (props.status === AgendaReportStatus.Completed) {
        return <Lozenge appearance="success">Tamamlandı</Lozenge>
      }

      if (props.status === AgendaReportStatus.Error) {
        return (
          <Tooltip content={props?.lastError || ''}>
            <Lozenge appearance="error">Hata Oluştu</Lozenge>
          </Tooltip>
        )
      }

      const status =
        props.pathId === 'agenda-report'
          ? AGENDA_REPORT_STATUSES.find(
              (status) => status.value === props.status
            )
          : AGENDA_REPORT_INDEPENDENT_STATUSES.find(
              (status) => status.value === props.status
            )

      return (
        <>
          <Lozenge appearance="warning">
            {`${props?.progressPercentage}% `}
          </Lozenge>
          <Lozenge appearance="warning">{status?.label}</Lozenge>
        </>
      )
    },
  },
  {
    name: 'operation',
    text: 'Task ',
    width: '100px',
    render: (item: AgendaReportList) => {
      if (!item.groupId) return null

      return (
        <>
          <LinkButton
            appearance="subtle-link"
            href={`/agenda-reportv4/task-detail/${item.groupId}`}
          >
            Görüntüle
          </LinkButton>
        </>
      )
    },
  },
  {
    name: 'operation',
    text: 'Rapor',
    width: '100px',
    render: (item: AgendaReportList) => {
      if (!item.groupId) return null
      if (item?.status === AgendaReportStatus.Error) {
        return null
      }
      return (
        <>
          <ColumnLinkButtons groupId={item.groupId} />
          <DesignerLinkButtons groupId={item.groupId} />
        </>
      )
    },
  },
]
