import { request } from 'services/http/dataset/instance'

import type {
  BotClassification,
  BotClassificationResponse,
  CreateBotClassificationRequest,
  UpdateBotClassificationRequest,
} from './types'

import {
  mapBotClassificationForList,
  mapBotClassificationForRequest,
  mapBotClassificationForResponse,
} from './mappers'

export const create = (data: CreateBotClassificationRequest) =>
  request<BotClassification>({
    method: 'post',
    url: 'bot-classification',
    data,
    mappers: {
      req: mapBotClassificationForRequest,
    },
  })

export const get = (value?: string) =>
  request<BotClassificationResponse>({
    method: 'get',
    url: 'bot-classification',
    params: { UserName: value },
    mappers: {
      res: mapBotClassificationForList,
    },
  })

export const getById = (id: number) =>
  request<BotClassification>({
    method: 'get',
    url: `bot-classification/${id}`,
    mappers: {
      res: mapBotClassificationForResponse,
    },
  })

export const update = (data: UpdateBotClassificationRequest) =>
  request<BotClassification>({
    method: 'put',
    url: `bot-classification/${data.id}`,
    data,
    mappers: {
      req: mapBotClassificationForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `bot-classification/${id}`,
  })
