import { request } from 'services/http/dataset/instance'

import type {
  CreateGenderDataSetRequest,
  GenderDataSet,
  GenderDataSetResponse,
  UpdateGenderDataSetRequest,
} from './types'

import {
  mapGenderByUserForRequest,
  mapGenderDataSetForList,
  mapGenderDataSetForRequest,
  mapGenderDataSetForResponse,
} from './mappers'

export const create = (data: CreateGenderDataSetRequest) =>
  request<GenderDataSet>({
    method: 'post',
    url: 'gender-data-set',
    data,
    mappers: {
      req: mapGenderDataSetForRequest,
    },
  })

export const get = () =>
  request<GenderDataSetResponse>({
    method: 'get',
    url: 'gender-data-set',
    mappers: {
      res: mapGenderDataSetForList,
    },
  })

export const getById = (id: number) =>
  request<GenderDataSet>({
    method: 'get',
    url: `gender-data-set/${id}`,
    mappers: {
      res: mapGenderDataSetForResponse,
    },
  })

export const update = (data: UpdateGenderDataSetRequest, id?: number) =>
  request<GenderDataSet>({
    method: 'put',
    url: `gender-data-set/${id || data.id}`,
    data,
    mappers: {
      req: mapGenderDataSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `gender-data-set/${id}`,
  })

export const byUser = (data: CreateGenderDataSetRequest) =>
  request<GenderDataSet>({
    method: 'post',
    url: 'gender-data-set/by-user',
    data,
    mappers: {
      req: mapGenderByUserForRequest,
    },
  })
