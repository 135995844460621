import { Column } from '@zera-admin/page'
import PdfPageLayout from './PdfPageLayout'
import { PdfPageProps } from './types'
import MostFollowedFollowers from 'widgets/person-report/most-followed-followers'
import Icon from '@zera-admin/icon'

const PageSix: React.FunctionComponent<PdfPageProps> = ({ personReport }) => {
  const getMonthAndYear = (dateData: string) => {
    const months = [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık',
    ]

    const date = new Date(dateData)
    const d = new Date()
    let month = months[d.getMonth()]
    return ` ${d} `
  }
  return (
    <PdfPageLayout personReport={personReport}>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              En çok takipçisi olan takipçileri
            </h2>
            <MostFollowedFollowers
              data={personReport.data?.result?.Statistic?.mostFollowedFollowers.slice(
                0,
                6
              )}
              columnSize={6}
              cutName={true}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              En çok takipçisi olan takipçileri
            </h2>
            <MostFollowedFollowers
              data={personReport.data?.result?.Statistic?.mostFollowedFollowers.slice(
                6,
                12
              )}
              columnSize={6}
              cutName={true}
            />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
    </PdfPageLayout>
  )
}
export default PageSix
