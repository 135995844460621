import React from 'react'
import DataBox from '@zera-admin/alice-data-box'
import EmptyState from '@zera-admin/empty-state'

import { WikipediaProps } from './types'

const Wikipedia: React.FunctionComponent<WikipediaProps> = ({ data }) => {
  const obj: any = {}

  data?.forEach((element) => {
    const entry = Object.entries(element)
    if (entry[0].length === 2 && typeof entry[0][0] === 'string') {
      obj[entry[0][0] as string] = entry[0][1]
    }
  })

  return data && data.length > 0 ? (
    <DataBox
      values={Object.entries(obj).map((item: any) => ({
        text: item[1],
        title:
          item[0].charAt(0).toUpperCase() +
          item[0].slice(1).toLowerCase().replaceAll('_', ' '),
      }))}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Wikipedia
