import React from 'react'
import * as Styled from './SocialCard.styled'

export interface Overlay {
  text?: string
  icon?: string
  color?: string
  value?: string
  tweetCount?: number | string
  tweetTitle?: string
  styles?: any
  img?: any
}

const SocialCard: React.FunctionComponent<Overlay> = ({
  icon,
  color,
  tweetCount,
  tweetTitle,
  styles,
  img,
}) => {
  const replecaXtext = (text?: string | undefined) => {
    let newText = text
    if (text?.toLowerCase().includes('tweet')) {
      newText = newText?.replace('tweet', 'post').replace('Tweet', 'Post')
    }
    if (text?.toLowerCase().includes('retweet')) {
      newText = newText
        ?.replace('retweet', 'repost')
        .replace('Retweet', 'Repost')
    }
    return newText
  }

  return (
    <Styled.SocialCardContainer style={{ backgroundColor: color, ...styles }}>
      {icon ? (
        <div className="tweet-box-icon">
          <i className={icon}></i>
        </div>
      ) : (
        <div className="tweet-box-img">
          <img src={img} alt="Twitter Logo" />
        </div>
      )}
      <div className="tweet-box-count">
        <span>{tweetCount}</span>
      </div>
      <div className="tweet-box-title">
        <span>{replecaXtext(tweetTitle)}</span>
      </div>
    </Styled.SocialCardContainer>
  )
}

export default SocialCard
