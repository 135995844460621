import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'

import { validation } from 'app/functions'
import useEntityCategoryType from 'app/hooks/entity-category-type'
import { EntityCategoryType } from 'services/http/dataset/entity-category-type'

import { EntityCategoryTypeFormProps } from './types'

const validations = {
  name: yup.string().required('Varlık kategori türü adı girmelisiniz'),
}

const CategoryTypeForm: React.FunctionComponent<EntityCategoryTypeFormProps> =
  ({ actions = [], onError, onSubmit, values }) => {
    const form = useForm<EntityCategoryType>({
      initialValues: values,
      onSubmit: (props) => handleSubmit(props),
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })

    const entityCategoryType = useEntityCategoryType({
      label: 'Üst varlık kategorisi yok',
      value: 0,
    })

    const handleSubmit = (props: FormHookSubmitProps<EntityCategoryType>) => {
      if (!props.errors) {
        if (onSubmit) {
          onSubmit(props.values, () => {
            if (props.handleReset) {
              props.handleReset()
            }
          })
        }
      } else {
        if (onError) {
          onError(props.errors)
        }
      }
    }

    return (
      <Form onSubmit={form.handleSubmit}>
        <FormSection
          title="Genel bilgiler"
          description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
        >
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                description="Geçerli bir varlık kategori türü adı giriniz"
                errorMessage={form.errors.name}
                label="Varlık kategori türü adı"
                name="name"
                placeholder="Örnek metin"
                value={form.values.name}
                onChange={form.handleChange}
              />
            </Column>
            <Column>
              <SearchBox
                description="Varsa geçerli bir üst kategori seçimi yapınız"
                errorMessage={form.errors.parentId}
                label="Üst kategori"
                name="parentId"
                options={entityCategoryType.entityCategoryTypes}
                value={entityCategoryType.getEntityCategoryType(
                  form.values.parentId
                )}
                onFetchOptions={(value) =>
                  entityCategoryType.getEntityCategoryTypes(value)
                }
                onChange={(props) => {
                  form.handleFieldChange(
                    'parentId',
                    (props as SearchboxOptionProps).value
                  )
                }}
                placeholder="Seçim yapın"
                isLoading={entityCategoryType.isLoading}
              />
            </Column>
          </Row>
        </FormSection>
        <FormFooter>{actions}</FormFooter>
      </Form>
    )
  }

export default CategoryTypeForm
