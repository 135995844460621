import React from 'react'
import EmptyState from '@zera-admin/empty-state'

import { TurkeyPerceptionTweetMetricProps } from './types'

const TurkeyPerceptionTweetMetric: React.FunctionComponent<TurkeyPerceptionTweetMetricProps> =
  ({ data }) => {
    let result = data ? Object.keys(data).map((key) => [key, data[key]]) : null
    const findDefinition = (name: string) => {
      switch (name) {
        case 'totalReplyCount':
          return 'Toplam reply sayısı'

        case 'totalFavoriteCount':
          return 'Toplam favori sayısı'
        case 'totalRetweetCount':
          return 'Toplam retweet sayısı'
        case 'totalTweetCount':
          return 'Toplam tweet sayısı'
        case 'totalRetweetTweetCount':
          return 'Toplam retweet sayısı'
        case 'averageReplyCount':
          return 'Ortalama reply sayısı'
        case 'averageFavoriteCount':
          return 'Ortalama favori sayısı'
        case 'averageRetweetCount':
          return 'Ortalama retweet sayısı'
        default:
          return '--'
      }
    }
    return result && result.length > 0 ? (
      <table style={{ width: '80%', margin: '0 auto' }}>
        <thead>
          <tr>
            <th>Özellik</th>
            <th>Sayısı</th>
          </tr>
        </thead>
        <tbody>
          {result.map((tweet, index: number) => (
            <tr key={index}>
              <td>{findDefinition(tweet[0])}</td>
              <td>{tweet[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <EmptyState
        title="Veri bulunamadı"
        description="Kullanıcı verisi bulunamadı"
      />
    )
  }

export default TurkeyPerceptionTweetMetric
