import React from 'react'
import { HashTagsByBotDetectionV2 } from '../../types'
import './hashtags.css'

export interface INews {
  data?: HashTagsByBotDetectionV2[]
}

const News: React.FunctionComponent<INews> = ({ data }) => {
  return (
    <div className="mainContainer">
      <div className="head">
        <div className="container">
          <div>
            <h2>Hashtagler</h2>
          </div>
          <div className="headContainer">
            <div className="headContent">
              <div className="circle deep"></div>
              <div className="negative">
                <p>Bot olmayan oranı</p>
              </div>
              <div className="border"></div>
            </div>

            <div className="headContent">
              <div className="circle blue"></div>
              <div className="positive">
                <p>Bot oranı</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cards">
        {data?.map((item, index) => (
          <div className="card">
            <div className="cardContainer">
              <div>
                <p style={{ marginTop: '2%' }}>
                  {' '}
                  {index + 1}#{item?.value}
                </p>
              </div>

              <div className="bar-container">
                {item.normalCount !== 0 && (
                  <div
                    className="bar-not"
                    style={{
                      width: `${
                        (150 * item?.normalCount) /
                        (item?.normalCount + item?.botCount)
                      }%`,
                    }}
                  >
                    %{' '}
                    {(
                      (100 * item?.normalCount) /
                      (item?.normalCount + item?.botCount)
                    ).toFixed()}
                  </div>
                )}

                {item.botCount !== 0 && (
                  <div className="bar-bot">
                    %
                    {(
                      100 -
                      (100 * item?.normalCount) /
                        (item?.normalCount + item?.botCount)
                    ).toFixed()}
                  </div>
                )}
              </div>
              {/* <div className="chartLine">
                {item.normalCount !== 0 && (
                  <div
                    className="chartSection1"
                    style={{
                      width:
                        (150 * item?.normalCount) /
                        (item?.normalCount + item?.botCount),
                    }}
                  >
                    %{' '}
                    {(
                      (100 * item?.normalCount) /
                      (item?.normalCount + item?.botCount)
                    ).toFixed()}
                  </div>
                )}

                <div className="chartSection2">
                  %
                  {(
                    100 -
                    (100 * item?.normalCount) /
                      (item?.normalCount + item?.botCount)
                  ).toFixed()}
                </div>
              </div> */}
            </div>
            <div className="row">
              <div>
                <p className="circle deep"></p>
              </div>
              <div>
                <p className="negative">{item?.normalCount}</p>
              </div>
            </div>
            <div className="row">
              <div>
                <p className="circle blue"></p>
              </div>
              <div>
                <p className="positive"> {item?.botCount}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default News
