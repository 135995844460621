import { BlackList, BlackListResponse } from './types'

export const mapBlackListForRequest = (raw: BlackList): unknown => ({
  id: raw.id,
  type: raw.type,
  value: raw.value,
  dataSource: raw.dataSource,
})

export const mapBlackListForResponse = (raw: any): BlackList => ({
  id: raw.data.id,
  type: raw.data.type,
  value: raw.data.value,
  createdAt: raw.data.createdAt,
  dataSource: raw.data.dataSource,
})

export const mapBlackListForList = (raw: any): BlackListResponse => ({
  list: raw.data.map((item: unknown) =>
    mapBlackListForResponse({ data: item })
  ),
  dataCount: raw.dataCount,
  pageCount: raw.pageCount,
  message: raw.message,
  success: raw.success,
})
