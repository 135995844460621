import styled from 'styled-components'

export const DetailAgendaReportProcess = styled.div`
  footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 0.6rem 0;
    text-align: right;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding-bottom: 1rem;
`
export const DetailAgendaReportHeader = styled.div`
  position: absolute;
  top: 20px;
  left: 25px;
`

export const DetailAgendaReportHeaderTools = styled.div`
  display: flex;

  button {
    padding: 0 0.2rem;
  }
`

export const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  [type='button'] {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
`

export const ModalContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;

  button {
    padding: 8px 16px;
    width: fit-content;
    background-color: #f5f5f5;
    border: none;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: end;
    border-radius: 5px;
  }
  .modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .modal-content {
    background-color: #fff;
    padding: 20px;

    min-width: 50%;
    width: 60%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    z-index: 2;

    &-video {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      height: 100%;

      iframe {
        width: 100%;
      }
    }

    &-button {
      display: flex;
      justify-content: flex-end;
    }
  }
`
