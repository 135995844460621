import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Column, Container, Row } from '@zera-admin/page'
import ZeraTweet, {
  TweetAspect,
  TweetClassification,
} from '@zera-admin/alice-tweet'
import Lozenge from '@zera-admin/lozenge'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'

import { getCriteriaInterval } from '../visualization/getters'
import * as Styled from './Tweet.styled'
import { mapQueryForTweet, mapTweetDifferences } from './utils/mappers'
import { TweetProps, TweetValueProps, TweetVisualizationOptions } from './types'

const Tweet: React.ForwardRefRenderFunction<
  WidgetHandlerRef<TweetVisualizationOptions>,
  TweetProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [values, setValues] = useState<TweetValueProps[]>([])
  const [error, setError] = useState<string>()

  const tweetRefs = useRef<HTMLDivElement[]>([])
  const timeouts = useRef<NodeJS.Timeout[]>([])

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  useEffect(() => {
    const stream = props.widget.visualization?.options?.stream
    let interval: NodeJS.Timeout | null = null

    if (stream?.live) {
      interval = setInterval(() => {
        runStream()
      }, stream.interval || 10000)
    }

    return () => {
      clearInterval(interval as NodeJS.Timeout)
      handleClearTimeouts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget.visualization?.options?.stream, isFetching, values])

  const getColumn = () => {
    const { grid } = props.widget

    if (grid) {
      if (grid === 2) return 6
      else if (grid === 3) return 4
      else if (grid === 4) return 3
      else if (grid === 6) return 2
    }

    return 12
  }

  const runStream = () => {
    if (!isFetching) {
      const stream = props.widget.visualization?.options?.stream

      queryService
        .run(
          props?.widget.query,
          props?.widget.visualization,
          props.widget.type,
          {
            criteriaType: props.widget.criteria,
            dateType: getCriteriaInterval(props.widget),
            type: 'tweet',
          }
        )
        .then((res) => {
          const differences = mapTweetDifferences(
            values,
            mapQueryForTweet(res.data, props.widget.visualization?.options)
          )

          if (differences.length) {
            handleClearTimeouts()

            differences.forEach((difference, index) => {
              timeouts.current[index] = setTimeout(() => {
                tweetRefs.current[0]?.classList.remove('is-loaded')

                setValues((values) => [
                  { ...difference, stream: true },
                  ...values.slice(0, (stream?.limit || 20) - 1),
                ])

                setTimeout(() => {
                  tweetRefs.current[0]?.classList.add('is-loaded')
                }, 250)
              }, ((stream?.timeout || 10000) / differences.length) * index + 1)
            })
          }

          setIsFetching(false)
        })
        .catch((err: Error) => {
          setIsFetching(false)
          setValues([])
          console.log(err)
        })
    }
  }

  const handleClearTimeouts = () => {
    if (timeouts.current.length) {
      timeouts.current.forEach((timeout) => clearTimeout(timeout))
    }
  }

  const runQuery = (widget: Widget<TweetVisualizationOptions>) => {
    setIsLoading(true)
    setIsFetching(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
        type: 'tweet',
      })
      .then((res) => {
        setValues(
          mapQueryForTweet(
            res.data as QueryRunResponse,
            widget.visualization?.options
          )
        )
        setIsLoading(false)
        setIsFetching(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setIsFetching(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderTweet = (props: TweetValueProps, index: number) => {
    let gallery = props.tweet.gallery
    if (gallery) {
      if (gallery.includes('[') && typeof gallery == 'string') {
        gallery = gallery.replace('[', '')
        gallery = gallery.replace(']', '')
        gallery = gallery.split(',')
        props.tweet.gallery = gallery
      }
    }

    return (
      <Styled.Tweet
        key={index}
        isLoaded={props.stream && index === 0 ? false : true}
        isRecent={props.stream && index === 0 ? true : false}
        ref={(element) =>
          (tweetRefs.current[index] = element as HTMLDivElement)
        }
      >
        {props.stream && (
          <Styled.TweetLozenge>
            <Lozenge children="Yeni" appearance="success" />
          </Styled.TweetLozenge>
        )}

        <ZeraTweet
          {...props.tweet}
          account={props.account}
          rightChildren={
            <TweetClassification
              classifications={props.tweet.classifications || []}
            />
          }
          aspectChildren={
            <TweetAspect
              aspects={props.tweet.aspects?.length ? props.tweet.aspects : []}
            />
          }
        />
      </Styled.Tweet>
    )
  }

  const renderContent = () => {
    const { grid } = props.widget
    if (grid) {
      const column = Math.round(values.length / grid)
      const group: { [key: string]: TweetValueProps[] } = {}

      for (var i = 0; i < values.length; i += column) {
        group[i] = values.slice(i, i + column)
      }

      return (
        <Row>
          {Object.keys(group).map((key) => {
            const value = group[key]

            return (
              <Column xs={{ size: getColumn() }}>
                {value.map((row, index) => renderTweet(row, index))}
              </Column>
            )
          })}
        </Row>
      )
    } else {
      return (
        <Container width="narrow">
          <Styled.TweetList>
            {values.map((row, index) => renderTweet(row, index))}
          </Styled.TweetList>
        </Container>
      )
    }
  }

  const renderTweets = () => {
    if (isLoading) {
      return (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      )
    } else {
      if (values.length) {
        return renderContent()
      } else {
        return (
          <Alert
            appearance={error ? 'error' : 'warning'}
            title="Tweet listesi oluşturulamadı"
          >
            {error ||
              'Tweet listesini oluşturacak veri bulunamadı. Lütfen sorgularınızı ve tweet listesi ayarlarınızı kontrol ediniz.'}
          </Alert>
        )
      }
    }
  }

  return renderTweets()
}

export default React.forwardRef(Tweet)
