import React, { useCallback, useEffect, useState } from 'react'
import Button, { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Table from '@zera-admin/table'
import Lozenge from '@zera-admin/lozenge'
import Form from '@zera-admin/form'
import Input from '@zera-admin/input'
import Icon from '@zera-admin/icon'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { CLAIM_FILTER_TYPES, CLAIM_TYPES } from 'app/shared/constants'
import MainLayout from 'layouts/main'
import claimService, {
  ClaimListRequest,
  ClaimListResponse,
} from 'services/http/identity-server/claim'
import Pager from 'components/pager'
import appService from 'services/http/identity-server/app'

import { ClaimListProps } from './types'

const ClaimList: React.FunctionComponent<ClaimListProps> = ({
  location,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [apps, setApps] = useState<SelectOptionProps[]>([])
  const [values, setValues] = useState<ClaimListResponse>()
  const [inputValue, setInputValue] = useState<string>('')
  const [filter, setFilter] = useState<ClaimListRequest>({
    pageSize: 15,
    pageIndex: 1,
  })

  const getValues = useCallback(async (request: ClaimListRequest) => {
    try {
      const response = await claimService.get(request, inputValue)

      setValues(response.data)
    } catch (err) {
      setValues({ data: [], meta: { totalData: 0, totalPage: 0 } })
    }

    setIsLoading(false)
  }, [])

  const getApps = async () => {
    const response = await appService.get({ pageIndex: 0, pageSize: 100 })
    const mapped = (response.data.data || []).map((app) => ({
      label: app.name,
      value: app.id,
    }))

    setApps(mapped)
  }

  useEffect(() => {
    getApps()
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    getValues({
      ...filter,
      pageSize: 15,
      pageIndex: parseInt(searchParams.get('pageIndex') || '1') - 1,
    })
  }, [getValues, location.search, filter])

  const handleSearch = (values: ClaimListRequest) => {
    const searchParams = new URLSearchParams(location.search)

    searchParams.set('pageIndex', '1')
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    })

    setIsLoading(true)
    setFilter(values)
  }

  const renderFilter = () => {
    return (
      <Form onSubmit={handleSearch} style={{ marginBottom: '10px' }}>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 3 }}>
            <Input
              description="Aramak istediğiniz izin değerini yazınız.."
              label="İzin değeri"
              name="value"
              value={inputValue}
              onChange={(props: any) => setInputValue(props.target.value)}
            />
          </Column>
          <Column xs={{ size: 3 }}>
            <Select
              name="type"
              label="İzin tipi"
              placeholder="Seçim yapınız"
              description="Aramak istediğiniz bir izin tipi seçiniz."
              options={CLAIM_FILTER_TYPES}
              defaultValue={{ label: 'Tüm tipler', value: '' }}
            />
          </Column>
          <Column xs={{ size: 3 }}>
            <Select
              name="appId"
              label="İlişkili uygulama"
              placeholder="Seçim yapınız"
              description="İznin uygulanacağını bir uygulama seçimi yapınız."
              options={[{ label: 'Tüm uygulamalar', value: '' }, ...apps]}
              defaultValue={{ label: 'Tüm uygulamalar', value: '' }}
            />
          </Column>
          <Column>
            <Button
              appearance="primary"
              children="Arama yap"
              iconBefore={<Icon size="small" name="search" />}
              type="submit"
            />
          </Column>
        </Row>
      </Form>
    )
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="İzinler" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Page>
        <PageHeader
          actions={
            <LinkButton appearance="primary" href="/claim/create">
              İzin oluştur
            </LinkButton>
          }
          bottomBar={
            <p>
              Kullanıcı yönetimi üzerinde kayıtlı tüm izinleri görüntüleyebilir,
              yeni izin oluşturabilir veya düzenleyebilirsiniz.
            </p>
          }
          breadcrumbs={breadcrumbs}
        >
          İzinler
        </PageHeader>
        {renderFilter()}
        <Table
          columns={
            [
              {
                name: 'title',
                text: 'İzin başlığı',
              },
              {
                name: 'value',
                text: 'İzin değeri',
              },
              {
                name: 'type',
                text: 'İzin tipi',
                render: (row: any) => {
                  const type = CLAIM_TYPES.find(
                    (claim) => claim.value === row.type
                  )

                  return (
                    <Lozenge appearance="success">{type?.label || '-'}</Lozenge>
                  )
                },
              },
              {
                name: 'method',
                text: 'İzin methodu',
                render: (row: any) => (
                  <Lozenge appearance={row.method ? 'info' : 'default'}>
                    {row.method ? row.method.toUpperCase() : 'Seçilmedi'}
                  </Lozenge>
                ),
              },
              {
                name: 'description',
                text: 'Açıklama',
                render: (row: any) => `${row.description || '-'}`,
              },
              {
                name: 'operation',
                text: 'İşlem',
                width: '100px',
                render: (row: any) => (
                  <LinkButton
                    appearance="subtle-link"
                    href={`/claim/update/${row.id}`}
                    size="none"
                    style={{ padding: '5px 0px' }}
                  >
                    Görüntüle
                  </LinkButton>
                ),
              },
            ] as any
          }
          rows={values ? values.data : []}
          helperMessage="Aradığınız kriterlere ait sonuç bulunamadı, lütfen tekrar deneyin."
          isLoading={isLoading}
        />
        <Pager pages={values?.meta.totalPage || 1} />
      </Page>
    </MainLayout>
  )
}

export default ClaimList
