import { request } from 'services/http/report/instance'

import { ProfileReportResponse } from './type'

export const create = (data?: any) =>
  request<any>({
    method: 'post',
    data,
    url: 'profile-report',
  })

export const getByGroupId = (groupId: string) =>
  request<ProfileReportResponse>({
    method: 'get',
    url: 'profile-report/report',
    params: { GroupId: groupId },
  })

// export const botDetection = (groupId: string, pageIndex?: number) =>
//   request<FollowersBotDetectionResponse>({
//     method: 'get',
//     url: 'profile-report/bot-detection?PageSize=10',
//     params: { GroupId: groupId, PageIndex: pageIndex },
//   })
