import React from 'react'

import InfoCard from '../report-card'
import { MostTweetedLocationProps } from './types'
import HashtagIcon from './hashtag_icon.svg'

const MostTweetedLocation: React.FunctionComponent<MostTweetedLocationProps> =
  ({ data }) => {
    return (
      <InfoCard icon={HashtagIcon} title="Hashtagler">
        <table className="info-card-table">
          <thead>
            <tr>
              <th>Hashtagler</th>
              <th>Post Sayıları</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((hashtag: any) => (
              <tr>
                <td>{hashtag.hashtags}</td>
                <td>{hashtag.rowCount.toLocaleString('tr-TR')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfoCard>
    )
  }
export default MostTweetedLocation
