import React from 'react'
import { Link } from 'react-router-dom'
import { Column, Row } from '@zera-admin/page'
import Avatar from '@zera-admin/avatar'
import Icon from '@zera-admin/icon'

import { TwitterProfileProps } from './types'

const TwitterProfile: React.FunctionComponent<TwitterProfileProps> = (
  props
) => {
  const { data, dataA } = props

  return data ? (
    <Row
      style={{
        padding: 20,
        paddingBottom: 15,
      }}
    >
      <Column
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        xs={{ size: 12 }}
      >
        <Link
          to={{ pathname: `https://twitter.com/${data?.ScreenName}` }}
          target="_blank"
        >
          <Avatar size="xlarge" src={data?.ProfileImageUrl} />
          {/* <img
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              border: '2px solid #fff',
            }}
            src={data?.ProfileImageUrl}
            alt={data?.ProfileImageUrl}
          /> */}
        </Link>
      </Column>
      <Column xs={{ size: 12 }}>
        <Row xs={{ direction: 'row', justify: 'between' }}>
          <Column xs={{ size: 12 }}>
            <Link
              style={{
                textDecorationLine: 'none',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              to={{ pathname: `https://twitter.com/${data?.ScreenName}` }}
              target="_blank"
            >
              <b>
                {data?.Name}{' '}
                <span>
                  {data?.Verified === true ? (
                    <Icon name="check-circle-outline" />
                  ) : data?.Protected === true ? (
                    <Icon name="lock" />
                  ) : null}
                </span>
              </b>
            </Link>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Kullanıcı adı</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>{data?.ScreenName}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Takipçi sayısı</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>{data?.FollowersCount?.toLocaleString()}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Konum</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>{data?.Location ? data.Location : 'Konum bulunamadı'}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Arkadaş sayısı</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>{data?.FriendsCount?.toLocaleString()}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Hesabı oluşturma tarihi</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>
              {new Date(
                data?.CreatedAt?.toLocaleString() as string
              ).toLocaleString()}
            </p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Favori sayısı</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>{data?.FavoritesCount?.toLocaleString()}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Doğrulanmış hesap</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>{data?.Verified === false ? 'Doğrulanmadı' : 'Doğrulandı'}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>Tweet sayısı</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p>{data?.StatusesCount?.toLocaleString()}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>AverageImpressionCount</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p style={{ float: 'right' }}>{dataA?.AverageImpressionCount}</p>
          </Column>
          <Column xs={{ size: 6 }}>
            <b>MaxImpressionCount</b>
          </Column>
          <Column xs={{ size: 6 }}>
            <p style={{ float: 'right' }}>{dataA?.MaxImpressionCount}</p>
          </Column>

          {data?.Description && (
            <Column xs={{ size: 12 }}>
              <b>Açıklama</b>
              <p style={{ fontWeight: 'lighter' }}> {data?.Description}</p>
            </Column>
          )}
        </Row>
      </Column>
    </Row>
  ) : null
}

export default TwitterProfile
