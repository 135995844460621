import { ModalProps } from '@zera-admin/modal'

import { FormProps } from 'app/shared/types'

import { CreateClassificationDataSetRequest } from 'services/http/dataset/classification-data-set'
import { CreateGenderDataSetRequest } from 'services/http/dataset/gender-data-set'
import { CreateNamedEntityRecognitionRequest } from 'services/http/dataset/named-entity-recognition'

export interface TrainingGroupModalProps {
  onClose: () => void
  trainingModal: TrainingGroupModalEnum | undefined
  values?: any
}

export enum TrainingGroupModalEnum {
  Bot = 'bot',
  Classification = 'classification',
  Gender = 'gender',
  GenderByUser = 'gender-by-user',
  Named = 'named',
  Sentiment = 'sentiment',
}

export interface BotClassificationProps
  extends ModalProps,
    Omit<FormProps<CreateClassificationDataSetRequest>, 'actions'> {}

export interface ClassificationDataSetProps
  extends ModalProps,
    Omit<FormProps<CreateClassificationDataSetRequest>, 'actions'> {}

export interface GenderDataSetProps
  extends ModalProps,
    Omit<FormProps<CreateGenderDataSetRequest>, 'actions'> {}

export interface GenderByUserProps
  extends ModalProps,
    Omit<FormProps<CreateGenderDataSetRequest>, 'actions'> {}

export interface NamedEntityRecognitionProps
  extends ModalProps,
    Omit<FormProps<CreateNamedEntityRecognitionRequest>, 'actions'> {}

export interface SentimentDataSetProps
  extends ModalProps,
    Omit<FormProps<CreateGenderDataSetRequest>, 'actions'> {}
