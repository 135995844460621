import { request } from 'services/http/dataset/instance'

import type {
  CreateSentimentControlSetRequest,
  SentimentControlSet,
  SentimentControlSetResponse,
  UpdateSentimentControlSetRequest,
} from './types'

import {
  mapSentimentControlSetForList,
  mapSentimentControlSetForRequest,
  mapSentimentControlSetForResponse,
} from './mappers'

export const create = (data: CreateSentimentControlSetRequest) =>
  request<SentimentControlSet>({
    method: 'post',
    url: 'sentiment-control-set',
    data,
    mappers: {
      req: mapSentimentControlSetForRequest,
    },
  })

export const get = () =>
  request<SentimentControlSetResponse>({
    method: 'get',
    url: 'sentiment-control-set',
    mappers: {
      res: mapSentimentControlSetForList,
    },
  })

export const getById = (id: number) =>
  request<SentimentControlSet>({
    method: 'get',
    url: `sentiment-control-set/${id}`,
    mappers: {
      res: mapSentimentControlSetForResponse,
    },
  })

export const update = (data: UpdateSentimentControlSetRequest) =>
  request<SentimentControlSet>({
    method: 'put',
    url: `sentiment-control-set/${data.id}`,
    data,
    mappers: {
      req: mapSentimentControlSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `sentiment-control-set/${id}`,
  })
