import styled from 'styled-components'

export const TwitterCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  background-color: #fafafa;
  margin-right: 12px;
  box-shadow: 0px 0px 5px 1px #e9e8e8;
  width: 200px;
  margin-top: 20px;
`
export const TwitterCardTop = styled.div`
  display: flex;
  justify-content: end;
  padding: 8px 5px 12px 5px;
`

export const TwitterCardHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
`
export const TwitterCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 0px;
`
