import React from 'react'
import { RedditCardProps } from './types'
import * as Styled from './RedditCard.styled'
import defaultUserProfile from './images/default-user.png'
import logo from './images/reddit-logo.webp'
import up from './images/up.svg'
import down from './images/down.svg'
import comment from './images/comment.svg'

const RedditCard: React.FC<RedditCardProps> = ({
  data,
  editmode,
  onChange,
}) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  const timeAgo = (input: Date | string | number) => {
    const date = input instanceof Date ? input : new Date(input)
    const formatter = new Intl.RelativeTimeFormat('tr')
    const ranges = [
      ['years', 3600 * 24 * 365],
      ['months', 3600 * 24 * 30],
      ['weeks', 3600 * 24 * 7],
      ['days', 3600 * 24],
      ['hours', 3600],
      ['minutes', 60],
      ['seconds', 1],
    ] as const
    const secondsElapsed = (date.getTime() - Date.now()) / 1000

    for (const [rangeType, rangeVal] of ranges) {
      if (rangeVal < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / rangeVal
        return formatter.format(Math.round(delta), rangeType)
      }
    }
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', logo)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfile)
  }

  const textLength =
    data?.Text && data.MediaUrl
      ? data?.Text?.length >= 400
      : data?.Text?.length >= 200
  return (
    <Styled.RedditCardWrapper>
      <div className="post">
        <div className="post-header">
          <div className="post-header-info">
            <div className="post-header-profile">
              <div className="post-header-profile-image">
                <img
                  src={data?.UserProfilePictureUrl || defaultUserProfile}
                  onError={hasErrorImageProfil}
                  alt=""
                />
              </div>
              <div className="post-header-profile-name">
                <span>r/{data?.UserName}</span>
              </div>
            </div>
            <div className="post-header-dot"></div>
            <div className="post-header-time">
              <span>{timeAgo(data?.CreatedAt)}</span>
            </div>
          </div>
          <div className="post-header-logo">
            <img src={logo} alt="Reddit Logo" />
          </div>
        </div>
        <div className="post-body">
          <div className="post-body-text">
            <div
              contentEditable={editmode}
              onBlur={(e) => handleBlurElement(e, 'Text')}
            >
              {textLength && !isOpen && !editmode
                ? data?.Text?.slice(0, 400) + '...'
                : data?.Text}
            </div>
            {textLength && !editmode && (
              <button onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? 'Daha Az' : 'Daha Fazla'}
              </button>
            )}
          </div>
          {data?.MediaUrl && (
            <div className="post-body-media">
              <img
                className="post-body-media-image"
                src={data?.MediaUrl}
                onError={hasErrorImage}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="post-footer">
          <div className="post-footer-info">
            <div className="post-footer-info-like">
              <img src={up} alt="Up" />
              <span>{data?.Score}</span>
              <img src={down} alt="Down" />
            </div>

            <div className="post-footer-info-comments">
              <img src={comment} alt="Comment" />
              <span>{data?.CommentCount} </span>
            </div>
          </div>
          <div className="post-footer-url reddit-orange">
            <a href={data?.Url} target="_blank" rel="noreferrer">
              Post'u görüntüle
            </a>
          </div>
        </div>
      </div>
    </Styled.RedditCardWrapper>
  )
}

export default RedditCard
