import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { storage } from '@zera-admin/helpers'
import Button from '@zera-admin/button'
import Form, { FormFooter, FormHeader, FormSection } from '@zera-admin/form'
import Page, { Row, Column } from '@zera-admin/page'
import Label from '@zera-admin/label'
import { uid } from '@zera-admin/utils'
import VerificationCode from 'react-verification-input'

import { usePopupContext } from 'app/contexts/popup'
import MembershipLayout from 'layouts/membership'
import authService, {
  AuthTwoFactorRequest,
} from 'services/http/identity-server/auth'

import { VerifyAccountProps } from './types'
import CountDown from './components/CountDown'

const links = (
  <ul>
    <li>
      <Link to="/signin">Yeniden giriş yap</Link>
    </li>
  </ul>
)

const board = (
  <ul>
    <li>
      <Link to="/#privacy-policy">Gizlilik politikası</Link>
    </li>
    <li>
      <Link to="/#user-notice">Kullanıcı sözleşmesi</Link>
    </li>
  </ul>
)

const VerifyAccount: React.FunctionComponent<VerifyAccountProps> = ({
  history,
  location,
}) => {
  const [form, setForm] = useState<AuthTwoFactorRequest>({
    email: '',
    code: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [popup] = usePopupContext()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const email = params.get('email')

    if (!email) {
      history.push('/signin')
    } else {
      setForm((form) => ({ ...form, email: atob(email) }))
    }
  }, [history, location.search])

  const onTimeout = () => {
    history.push('/signin')
  }

  const handleSubmit = async () => {
    setIsLoading(true)

    try {
      const response = await authService.twoFactor(form)

      if (response.data) {
        const { accessToken, refreshToken } = response.data

        storage.set('token', accessToken)
        storage.set('refreshToken', refreshToken)

        history.push('/')
      }
    } catch (err) {
      popup.add({
        id: uid(),
        title: 'Giriş yapılamadı',
        children:
          'Giriş yaptığınız doğrulama kodu hatalı. Lütfen tekrar deneyiniz.',
      })
    }

    setIsLoading(false)
  }

  return (
    <MembershipLayout board={board} links={links}>
      <Page>
        <Form onSubmit={handleSubmit}>
          <FormHeader
            title="Hesabınızı doğrulayın"
            description="Sisteme giriş yapmak için, e-mail adresinize gelen 6 haneli doğrulama kodunu giriniz ve sisteme giriş yapınız."
          />
          <FormSection>
            <Row>
              <Column xs={{ size: 12 }}>
                <Label>Doğrulama kodunu giriniz</Label>
                <VerificationCode
                  classNames={{ container: 'verification-code' }}
                  onChange={(code: string) =>
                    setForm((form) => ({ ...form, code }))
                  }
                  inputProps={{
                    type: 'text',
                    width: 57,
                    height: 57,
                  }}
                />
              </Column>
            </Row>
            <CountDown onTimeout={onTimeout} />
          </FormSection>
          <FormFooter>
            <Button
              appearance="primary"
              isFullWidth
              style={{ height: 38, alignItems: 'center' }}
              type="submit"
              isLoading={isLoading}
            >
              Doğrula ve Sisteme Giriş Yap
            </Button>
          </FormFooter>
        </Form>
      </Page>
    </MembershipLayout>
  )
}

export default VerifyAccount
