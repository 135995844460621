import React from 'react'

import InfoCard from '../report-card'
import { ReportInfoProps } from './types'
import InfoIcon from './info_icon.svg'

const ReportInfo: React.FunctionComponent<ReportInfoProps> = ({
  data,
  title,
}) => {
  return (
    <InfoCard
      icon={InfoIcon}
      title={title}
      fontSize="16px"
      contentMarginTop="0px"
    >
      <div className="general-info">
        <div className="general-info-content">
          {data?.includes('tweet') ? data?.replace('tweet', 'post') : data}
        </div>
      </div>
    </InfoCard>
  )
}
export default ReportInfo
