/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Button, { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Table from '@zera-admin/table'
import Form from '@zera-admin/form'
import Input from '@zera-admin/input'
import Icon from '@zera-admin/icon'
import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import newsListService, {
  NewsList as NewsListProp,
  NewsListResponse,
} from 'services/http/backoffice/news-list'
// import { BlackListEnum } from 'services/http/dataset'

import { NewsListProps } from './types'

const NewsList: React.FunctionComponent<NewsListProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [newsList, setNewsList] = useState<NewsListResponse>()
  const [pageIndex, setPageIndex] = useState<number>(0)

  useEffect(() => {
    getNewsLists(pageIndex, '')
  }, [pageIndex])

  const getNewsLists = async (pageIndex: number, url: string) => {
    try {
      const response = await newsListService.get(pageIndex, 10, url)
      setNewsList(response.data)
      setIsLoading(false)
    } catch (err) {
      const ex: any = err
      console.log(ex)
    }
  }
  const handleSearch = (values: any) => {
    getNewsLists(0, values['news_url'])
    setIsLoading(true)
  }

  const renderFilter = () => {
    return (
      <Form onSubmit={handleSearch} style={{ marginBottom: '10px' }}>
        <Row xs={{ align: 'center' }}>
          <Column xs={{ size: 4 }}>
            <Input
              description="Aramak istediğiniz haber sitesinin url'sini yazınız.."
              label="URL"
              name="news_url"
            />
          </Column>

          <Column>
            <Button
              appearance="primary"
              children="Arama yap"
              iconBefore={<Icon size="small" name="search" />}
              type="submit"
            />
          </Column>
        </Row>
      </Form>
    )
  }

  const renderPageHeader = () => {
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Haber Listeleri" />
      </Breadcrumb>
    )

    const bottomBar = <p>Tüm Haber Listelerini görüntüleyebilirsiniz.</p>

    return (
      <PageHeader bottomBar={bottomBar} breadcrumbs={breadcrumbs}>
        Haber Listeleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns: any = [
      {
        name: 'name',
        text: 'Haber Sitesi',
      },
      {
        name: 'link',
        text: 'Link',
      },
      {
        name: 'rank',
        text: 'Sıralama',
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: NewsListProp) => (
          <LinkButton
            appearance="subtle-link"
            href={`/news-list/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (newsList) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={newsList?.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager
            pages={newsList.pageCount}
            onChange={(index) => setPageIndex((index as number) - 1)}
          />
        </React.Fragment>
      )
    }

    return null
  }
  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderFilter()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default NewsList
