import { request } from 'services/http/dataset/instance'

import type {
  CreateSourceRequest,
  Source,
  SourceResponse,
  UpdateSourceRequest,
} from './types'

import {
  mapSourceForList,
  mapSourceForRequest,
  mapSourceForResponse,
} from './mappers'

export const create = (data: CreateSourceRequest) =>
  request<Source>({
    method: 'post',
    url: 'source',
    data,
    mappers: {
      req: mapSourceForRequest,
    },
  })

export const get = (value?: string) =>
  request<SourceResponse>({
    method: 'get',
    url: 'source',
    params: { name: value },
    mappers: {
      res: mapSourceForList,
    },
  })

export const getById = (id: number) =>
  request<Source>({
    method: 'get',
    url: `source/${id}`,
    mappers: {
      res: mapSourceForResponse,
    },
  })

export const update = (data: UpdateSourceRequest) =>
  request<Source>({
    method: 'put',
    url: `source/${data.id}`,
    data,
    mappers: {
      req: mapSourceForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `source/${id}`,
  })
