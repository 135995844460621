import styled from 'styled-components'

export const LayoutSafeArae = styled.div`
  padding: 6px 30px 30px 30px;
`

export const LayoutSpinner = styled.div`
  text-align: center;
  margin-top: 24px;
`
