export function numberFormat(number?: string | number) {
  return number ? new Intl.NumberFormat('tr-TR').format(Number(number)) : 0
}

export function formatNumber(value: string | number) {
  const number = Number(value)
  if (number >= 1e9) {
    return (
      new Intl.NumberFormat('tr-TR', { maximumFractionDigits: 2 }).format(
        Number(number) / 1e9
      ) + 'M'
    )
  } else if (number >= 1e6) {
    return (
      new Intl.NumberFormat('tr-TR', { maximumFractionDigits: 2 }).format(
        number / 1e6
      ) + ' M'
    )
  } else if (number >= 1e3) {
    return (
      new Intl.NumberFormat('tr-TR', { maximumFractionDigits: 2 }).format(
        number / 1e3
      ) + ' B'
    )
  } else {
    return value.toString()
  }
}
