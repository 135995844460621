import React, { useEffect, useState } from 'react'
import Alert from '@zera-admin/alert'
import Button from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import { Column, Row } from '@zera-admin/page'
import Spinner from '@zera-admin/spinner'

import Pager from 'components/pager'
import TwitterProfile from 'components/twitter-profile'
import personReportsService, {
  FollowersBotDetectionResponse,
} from 'services/http/report/person-report'

import { BotDetectionProps } from './types'

const BotDetection: React.FunctionComponent<BotDetectionProps> = ({
  data,
  match,
}) => {
  const [botDetection, setBotDetection] =
    useState<FollowersBotDetectionResponse>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>()

  const handleBotStatusShow = () => {
    setDrawerVisible(true)
  }

  useEffect(() => {
    setIsLoading(true)
    const groupId = match.params.id
    personReportsService
      .botDetection(groupId, pageIndex || 0)
      .then((res) => {
        setBotDetection(res.data)
        setIsLoading(false)
      })
      .catch((err: Error) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [match.params.id, pageIndex])

  return (
    <React.Fragment>
      <Alert appearance="warning" title="Takipçilerinin potansiyel bot tespit">
        <p>
          Takipçilerinin %
          {data
            ?.find((item: any) => item.botDetection === 1)
            ?.percentage.toFixed(2)}
          'i potansiyel bot olarak işaretlenmiştir.
        </p>
        <Button
          style={{ marginTop: 15 }}
          appearance="warning"
          onClick={() => handleBotStatusShow()}
        >
          Takipçi bot durumlarını göster
        </Button>
      </Alert>
      <Drawer
        isOpen={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width="extended"
        title="Takipçilerinin bot durumu"
      >
        {isLoading ? (
          <Spinner />
        ) : botDetection && botDetection?.data?.length > 0 ? (
          <div style={{ paddingBottom: '40px' }}>
            <Row>
              {botDetection.data.map((item: any, index) => (
                <Column key={index} xs={{ size: 12 }}>
                  <TwitterProfile
                    data={{
                      CountryDetection: item.countryDetection,
                      CreatedAt: item.createdAt,
                      Description: item.description,
                      EntityKey: item.entityKey,
                      FavoritesCount: item.favoritesCount,
                      FollowersCount: item.followersCount,
                      FriendsCount: item.friendsCount,
                      Gender: item.gender,
                      Name: item.name,
                      ProfileImageUrl: item.profileImageUrl,
                      Protected: item.protected,
                      ScreenName: item.screenName,
                    }}
                  />
                </Column>
              ))}
            </Row>
            <Pager
              pages={botDetection?.pageCount}
              onChange={(index) => setPageIndex(index)}
            />
          </div>
        ) : null}
      </Drawer>
    </React.Fragment>
  )
}

export default BotDetection
