import styled from 'styled-components'

export const TwitterCard = styled.div`
  border-bottom: 1px #cccccc solid;
  padding: 10px 15px;
  .twitter-header {
    display: flex;
    .twitter-image img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }
    .twitter-profil {
      padding: 0px 10px;
      span {
        display: block;
      }
    }
    .twitter-profil span:nth-child(1) {
      font-weight: bold;
      font-size: 19px;
    }
    .twitter-profil span:nth-child(2) {
      font-size: 15px;
    }
  }
  .twitter-content {
    padding: 10px 0px;
  }
  .twitter-footer {
    display: flex;
    justify-content: space-between;
    span {
      color: #1da1f2;
      font-weight: bold;
    }
  }
`
