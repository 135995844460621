import styled from 'styled-components'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .msg-warning {
    span {
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
    margin-top: 4px;
    padding: 4px;
    font-size: 13px;
    border-radius: 4px;
  }
`
export const MailList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
`
export const MailCreat = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  & > button {
    width: 150px;
    margin-left: 20px;
  }
`

export const Mail = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  margin-top: 4px;
  border-bottom: 1px solid #eeebeb;
  & > span:nth-child(1) {
    margin-right: 10px;
    font-weight: bold;
  }
  & > span:nth-child(2) {
    width: 100%;
  }
`
