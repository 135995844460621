import React from 'react'
import DataBox from '@zera-admin/alice-data-box'
import EmptyState from '@zera-admin/empty-state'

import { MetricsOfOriginalTweetsProps } from './types'

const MetricsOfOriginalTweets: React.FunctionComponent<
  MetricsOfOriginalTweetsProps
> = ({ data }) => {
  return data && Object.keys(data).length > 0 ? (
    <DataBox
      values={[
        {
          text:
            data.averageFavoriteCount === 0 || data.averageFavoriteCount > 0
              ? data.averageFavoriteCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Ortalama Favori sayısı',
        },
        {
          text:
            data.averageReplyCount === 0 || data.averageReplyCount > 0
              ? data.averageReplyCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Ortalama Yanıt sayısı',
        },
        {
          text:
            data.averageRetweetCount === 0 || data.averageRetweetCount > 0
              ? data.averageRetweetCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Ortalama Retweet sayısı',
        },
        {
          text:
            data.totalFavoriteCount === 0 || data.totalFavoriteCount > 0
              ? data.totalFavoriteCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Toplam Favori sayısı',
        },
        {
          text:
            data.totalReplyCount === 0 || data.totalRetweetCount > 0
              ? data.totalReplyCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Toplam Yanıt sayısı',
        },
        {
          text:
            data.totalRetweetCount === 0 || data.totalRetweetCount > 0
              ? data.totalRetweetCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Toplam Retweet sayısı',
        },
        {
          text:
            data.totalRetweetTweetCount === 0 || data.totalRetweetCount > 0
              ? data.totalRetweetTweetCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Toplam Retweet ve Post sayısı',
        },
        {
          text:
            data.totalTweetCount === 0 || data.totalTweetCount > 0
              ? data.totalTweetCount.toLocaleString()
              : 'Bulunamadı',
          title: 'Toplam Post sayısı',
        },
      ]}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default MetricsOfOriginalTweets
