import {
  PureSchemaObject,
  SchemaNode,
  SchemaObject,
  SearchPredicate,
} from './node'

export class SchemaTree {
  rootNodes: SchemaNode[]

  constructor(nodes: SchemaNode[] | null) {
    this.rootNodes = nodes || []
  }

  search(id: string): SchemaNode | null {
    let found = null

    for (let index = 0; index < this.rootNodes.length; index++) {
      let node = this.rootNodes[index]
      found = node.search(id)

      if (found !== null && found !== undefined) break
    }

    return found
  }

  searchBy(predicate: SearchPredicate): SchemaNode[] {
    let result: SchemaNode[] = []

    for (let index = 0; index < this.rootNodes.length; index++) {
      let node = this.rootNodes[index]
      let searchResult = node.searchBy(predicate)

      result = [...result, ...searchResult]
    }

    return result
  }

  appendChildren(node: SchemaNode): SchemaTree {
    this.rootNodes.push(node)
    return this
  }

  prependChildren(node: SchemaNode): SchemaTree {
    this.rootNodes.unshift(node)
    return this
  }

  toArray(): SchemaObject[] {
    return this.rootNodes.map((node) => node.toObject())
  }

  toPureArray(): PureSchemaObject[] {
    return this.rootNodes
      .filter((node) => !node.getProp('hideRuntime'))
      .map((node) => node.toPureObject())
  }

  getDistinctNodes(): SchemaNode[] {
    return this.rootNodes.reduce(
      (prev: SchemaNode[], node: SchemaNode) => [
        ...prev,
        ...node.getDistinctNodes(),
      ],
      []
    )
  }

  insertChildren(at: number, node: SchemaNode): SchemaTree {
    this.rootNodes.splice(at, 0, node)
    return this
  }

  moveChildren(oldIndex: number, newIndex: number): SchemaTree {
    const deleted = this.rootNodes.splice(oldIndex, 1)
    this.rootNodes.splice(newIndex, 0, deleted[0])
    return this
  }

  removeChildrenAt(index: number) {
    this.rootNodes.splice(index, 1)
    return this
  }

  static parse(array: SchemaObject[]) {
    const nodes = array.map((obj) => SchemaNode.parse(obj, null))
    return new SchemaTree(nodes)
  }
}
