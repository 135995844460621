import styled from 'styled-components'

export const Bar = styled.div`
  font-size: 15px;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    p {
      font-weight: 500;
    }
  }

  & > div:nth-child(2) {
    background-color: #041b83;
    border-radius: 24.14px;
    height: 10.45px;
    margin-top: 2px;

    & > div {
      background: linear-gradient(to right, #031a82 0%, #18d3fd 100%);
      height: 10.45px;
      border-radius: 24.14px;
    }
  }
`

export const Bars = styled.div`
  width: 100%;
`
