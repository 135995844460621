export const WIDGET_GRID_TYPES = [
  {
    label: 'Varsayılan',
    value: 0,
  },
  {
    label: '1 sütun',
    value: 1,
  },
  {
    label: '2 sütun',
    value: 2,
  },
  {
    label: '3 sütun',
    value: 3,
  },
  {
    label: '4 sütun',
    value: 4,
  },
  {
    label: '6 sütun',
    value: 6,
  },
]
