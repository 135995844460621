import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { ActiveHoursProps } from './types'

const ActiveHours: React.FunctionComponent<ActiveHoursProps> = ({ data }) => {
  const activeHours: Props = {
    options: {
      chart: {
        id: 'activeHours',
      },
      colors: ['#ff6f00'],
      xaxis: {
        categories: data?.map((item) => item.Hours),
      },
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: 'Tweet sayısı',
        data: data?.map((item) => item.TweetCount) as number[],
      },
    ],
  }

  return data && data?.length > 0 ? (
    <Chart
      options={activeHours.options}
      series={activeHours.series}
      type="radar"
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default ActiveHours
