import React from 'react'
import WorkersImage from 'assets/images/newspaper.jpg'
import * as Styled from './News.styled'
import { NewsList } from '../../types'
// import './newsStyled.css'

export interface NewCard {
  news: NewsList
}

const NewsCrd: React.FunctionComponent<NewCard> = ({ news }) => {
  const dates = new Date(news?.createdAt)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    second: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  const formattedDate = dates.toLocaleDateString('tr-TR', options)
  return (
    <Styled.NewsCards>
      <a href={news?.url}>
        <div>
          <Styled.CardHead>
            <div>
              <img
                src={news.newsSourceImageUrl}
                alt=""
                width={50}
                height={50}
              />
            </div>
            <div>
              <h3>{news?.newsSourceName}</h3>
            </div>
          </Styled.CardHead>
          {news.imageUrl && (
            <Styled.CardMain>
              <img
                src={news.imageUrl && news.imageUrl}
                alt=""
                onError={(event) => {
                  event.currentTarget.src = WorkersImage
                }}
              />
            </Styled.CardMain>
          )}

          <Styled.CardText>
            <h3>{news?.title}</h3>
            <p>{news?.text}</p>
            <div className="createdat">
              <span>{formattedDate}</span>
            </div>
          </Styled.CardText>
        </div>
      </a>
    </Styled.NewsCards>
  )
}

export default NewsCrd
