import { QueryRunResponse } from 'services/http/bi-tool/query'

import { NameValueValueProps, NameValueVisualizationOptions } from '../types'

export const mapQueryForNameValue = (
  raw: QueryRunResponse,
  options: NameValueVisualizationOptions
): NameValueValueProps[] =>
  (raw.rows || []).map((row) => ({
    name: row[options?.label as string],
    value: row[options?.value as string],
  }))
