import React, { useCallback, useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import Operation from 'components/operation'
import MainLayout from 'layouts/main'
import definitionTagService, {
  DefinitionTag,
  DefinitionTagResponse,
} from 'services/http/backoffice/definition-tag'

import { DefinitionTagListProps } from './types'

const DefinitionTagList: React.FunctionComponent<
  DefinitionTagListProps
> = () => {
  const [definitionTag, setDefinitionTag] = useState<DefinitionTagResponse>()
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  const getDefinitionTag = useCallback(async () => {
    try {
      const response = await definitionTagService.get(10, pageIndex)

      setDefinitionTag(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }, [pageIndex])

  useEffect(() => {
    getDefinitionTag()
  }, [getDefinitionTag])

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/definition-tag/create">
        Tanımlama etiketi oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Tanımlama etiketi" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm tanımlama etiketi görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Tanımlama etiketi
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'name',
        text: 'Etiket',
      },
      {
        name: 'insertedDate',
        text: 'Eklenme tarihi',
        render: (props: DefinitionTag) => {
          return new Date(props.insertedDate).toLocaleString()
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: DefinitionTag) => (
          <Operation
            history=""
            href={`definition-tag/update/${item.id}`}
            service={definitionTagService.remove}
          />
        ),
      },
    ]

    if (definitionTag) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={definitionTag.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager
            pages={definitionTag.pageCount}
            onChange={(index) => setPageIndex(index - 1)}
          />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default DefinitionTagList
