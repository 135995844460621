import styled from 'styled-components'

export const Container = styled.div`

width: 100%;
background-color: #50aaf5;
display: flex;
position:relative;

.header-logo-container {
  width: 30%;
  height: 100%;
position:relative;

  border-radius: 0 0 40px 0;
  background-color: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  -webkit-print-color-adjust:exact;
  -webkit-filter:opacity(1);
  -webkit-box-shadow: 0px 4px 23px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 23px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 23px -6px rgba(0, 0, 0, 0.75);
  .header-logo {
    width: 90%;
    height: 100%;
   
  }
}
.header-main-title {
  left: -10px;
  width: 90%;
  height: 110px;
position:relative;

  background-color: #001441;
  border-radius: 0 0 15px 0;
  transform: skew(326deg);
  transform-origin: top right;
  
@media (width >= 2440px) {
  height: 180px !important;

}
}
  .header-title {
    font-size: 2rem;
    color: white;
    position: absolute;
    z-index: 30;
    top: 0px;
    left: 380px;
    color: white;

    @media (width >= 1600px) {
      left:450px !important;
      top:0px !important;
      font-size:2.1rem;
    }

    @media (width >= 1800px) {
      left:480px !important;
      top:0px !important;
      font-size:2.3rem;
    }

    @media (width >= 1920px) {
      left:550px !important;
      top:-5px !important;
      font-size:2.7rem;
    }

    @media (width >= 2160px) {
  left:600px !important;
  top:0px !important;
  font-size:2.7rem;
    }


@media (width >= 2880px) {
  left:820px !important;
  top:30px !important;
  font-size:2.7rem;
}

  }
}

.header-pager {
  display: flex;
position:relative;

  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding-right: 2rem;
  width: 20%;
  .header-pager-title {
    font-size: 2.3rem;
    font-weight: 800;
    
  }
  .header-pager-date {
    font-size: 1.3rem;
    font-weight: 800;
  @media (width >= 2440px) {
    font-size:2.4rem;
  }
  }
}
`
