export const SOCIALNUMBERS_FIELDS = [
  {
    label: 'Toplam Tweet Sayisi',
    value: 'totalTweetCount',
  },

  {
    label: 'Toplam Orjinal Tweet Sayisi',
    value: 'totalOriginalCount',
  },

  {
    label: 'Toplam Retweet Sayisi',
    value: 'totalRetweetCount',
  },

  {
    label: 'Number of Effects Count',
    value: 'totalFollowersCountOfTweetedAccounts',
  },

  {
    label: 'Total Tweet Accounts Count',
    value: 'uniqueAccountCount',
  },

  {
    label: 'Total Singular User Count',
    value: 'totalImpressionCount',
  },

  {
    label: 'Youtube Count',
    value: 'youtubecount',
  },

  {
    label: 'Newspaper Count',
    value: 'newspapercount',
  },

  {
    label: 'Instagram Count',
    value: 'instagramcount',
  },
  {
    label: 'Reddit Count',
    value: 'redditcount',
  },
]
