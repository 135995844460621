import styled from 'styled-components'

export const LayoutSafeArae = styled.div`
  padding: 6px 30px 30px 30px;
`

export const LayoutSpinner = styled.div`
  text-align: center;
  margin-top: 24px;
`
export const Container = styled.div`
  .css-10ot5d7-LeftSidebar {
    z-index: 99999;
  }
  .css-172spc3 {
    z-index: 99999;
  }
`
