import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'

import { validation } from 'app/functions'

import { EntityCommonRuleProps } from './types'
import { CreateParams } from 'services/http/backoffice/common-rule'

const validations = {
  botName: yup.string().required('Bot Adı metni girmelisiniz'),
  chatId: yup.string().required('ChatId metni girmelisiniz'),
}

const EntityCommonRule: React.FunctionComponent<EntityCommonRuleProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const form = useForm<CreateParams>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = (props: FormHookSubmitProps<CreateParams>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Bot Id giriniz"
              errorMessage={form.errors.botId}
              label="Bot Id"
              name="botId"
              placeholder="7710009996"
              value={form.values.botId}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Input
              description="Chat Id giriniz"
              errorMessage={form.errors.chatId}
              label="Chat Id"
              name="chatId"
              placeholder="1270674273"
              value={form.values.chatId}
              onChange={form.handleChange}
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default EntityCommonRule
