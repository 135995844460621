import React, { useImperativeHandle, useRef, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Column, Container, Row } from '@zera-admin/page'
import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'
import { getCriteriaInterval } from '../visualization/getters'
import * as Styled from './Hotel.styled'
import { mapQueryForNews } from './utils/mappers'
import { HotelProps, HotelValueProps, HotelVisualizationOptions } from './types'

const Hotel: React.ForwardRefRenderFunction<
  WidgetHandlerRef<HotelVisualizationOptions>,
  HotelProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<HotelValueProps[]>([])
  const [error, setError] = useState<string>()

  const hotelRefs = useRef<HTMLDivElement[]>([])

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const getColumn = () => {
    const { grid } = props.widget

    if (grid) {
      if (grid === 2) return 6
      else if (grid === 3) return 4
      else if (grid === 4) return 3
      else if (grid === 6) return 2
    }

    return 12
  }

  const addEllipsis = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + '...'
    }
    return text
  }

  const runQuery = (widget: Widget<HotelVisualizationOptions>) => {
    setIsLoading(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
      })
      .then((res) => {
        setValues(
          mapQueryForNews(
            res.data as QueryRunResponse,
            widget.visualization?.options
          )
        )
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }
  const renderNewsItem = (props: HotelValueProps, index: number) => {
    return (
      <Styled.Hotel
        key={index}
        ref={(element) =>
          (hotelRefs.current[index] = element as HTMLDivElement)
        }
      >
        <Styled.Container>
          <div className="hotel-card-img-container">
            <a href={props?.hotelLink}>
              {props?.mediaList && <img src={props?.mediaList} alt="" />}
            </a>
          </div>
          <div className="hotel-card-content-container">
            <div className="hotel-card-content-container-title">
              <h3>{props?.hotelName}</h3>
            </div>
            <div className="hotel-card-arrangement">
              <span>{props?.arrangement}</span>
            </div>
            <div className="hotel-card-content-container-location">
              <span>
                <i className="fa-solid fa-location-dot"></i>
              </span>
              <span className="hotel-card-location-name">{props?.adress}</span>
            </div>

            <div className="hotel-card-content-container-desc">
              <span>{addEllipsis(props?.hotelDescription, 430)}</span>
            </div>
            <div className="hotel-card-content-container-rating">
              <span>
                <span className="hotel-card-bold">Puan:</span> {props?.rating}
              </span>
            </div>
          </div>
        </Styled.Container>
      </Styled.Hotel>
    )
  }

  const renderContent = () => {
    const { grid } = props.widget

    if (grid) {
      const column = Math.round(values.length / grid)
      const group: { [key: string]: HotelValueProps[] } = {}

      for (var i = 0; i < values.length; i += column) {
        group[i] = values.slice(i, i + column)
      }

      return (
        <Row>
          {Object.keys(group).map((key) => {
            const value = group[key]

            return (
              <Column xs={{ size: getColumn() }}>
                {value.map((row, index) => renderNewsItem(row, index))}
              </Column>
            )
          })}
        </Row>
      )
    } else {
      return (
        <Container width="narrow">
          <Styled.HotelList>
            {values.map((row, index) => renderNewsItem(row, index))}
          </Styled.HotelList>
        </Container>
      )
    }
  }

  const renderNews = () => {
    if (isLoading) {
      return (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      )
    } else {
      if (values.length) {
        return renderContent()
      } else {
        return (
          <Alert
            appearance={error ? 'error' : 'warning'}
            title="Otel kartı oluşturulamadı"
          >
            {error ||
              'Otel Kartı oluşturacak veri bulunamadı. Lütfen sorgularınızı ve otel listesi ayarlarınızı kontrol ediniz.'}
          </Alert>
        )
      }
    }
  }

  return renderNews()
}

export default React.forwardRef(Hotel)
