import React from 'react'
import { InfluentialPeople } from '../../types'
import * as Styled from './Users.styled'
import UndefinedPerson from '../../../../../assets/images/undefinedperson.png'
export interface PeopleCard {
  people?: InfluentialPeople
}

const Users: React.FunctionComponent<PeopleCard> = ({ people }) => {
  return (
    <Styled.Container>
      <a href={`https://twitter.com/${people?.screenName}`}>
        <div className="news-logo-background">
          <img
            src={people?.profileBannerUrl}
            alt=""
            onError={(event) => {
              event.currentTarget.src = UndefinedPerson
            }}
          />
        </div>
        <div className="news-image-container">
          <div className="news-image">
            <img src={people?.profileImageUrl} alt="deneme" />
          </div>
        </div>
        <div className="news-content">
          <div className="news-content-title">
            <span className="news-title">{people?.name}</span>
            <span className="news-uri">{people?.screenName}</span>
          </div>
          <div className="news-action-container">
            <div className="news-action-location">
              <span>
                <span>
                  {' '}
                  <i className="fa-solid fa-location-dot" />
                </span>
                <span>{people?.location || '-'}</span>
              </span>
            </div>
            <div className="news-action-social">
              <span className="news-action-title">
                Tweet Sayisi{' '}
                <span className="news-action-count">
                  {people?.statusesCount}
                </span>
              </span>
              <span className="news-action-title">
                Takip Edilen{' '}
                <span className="news-action-count">
                  {people?.friendsCount}
                </span>
              </span>
              <span className="news-action-title">
                Takipçi{' '}
                <span className="news-action-count">
                  {people?.followersCount}
                </span>
              </span>
            </div>
          </div>
        </div>
      </a>
    </Styled.Container>
  )
}

export default Users
