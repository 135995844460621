import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Button, { LinkButton } from '@zera-admin/button'
import Form, { useForm } from '@zera-admin/form'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Spinner from '@zera-admin/spinner'
import { globalPageSize } from 'app/shared/constants'
import BIToolLayout from 'layouts/bi-tool'
import DashboardItem from './dashboard-item/DashboardItem'
import Pager from 'components/pager'
import dashboard, {
  Dashboard,
  DashboardResponse,
} from 'services/http/bi-tool/dashboard'
import * as Styled from './Dashboardlist.styled'

import { DashboardListProps } from './types'
import { deleteDashboards } from 'services/http/dashboard-ui/dashboard/endpoints'

const DashboardList: React.FunctionComponent<DashboardListProps> = ({
  location,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [dashboards, setDashboards] = useState<DashboardResponse>()
  const [pageSize, setPageSize] = useState(24)
  const [resetable, setResetable] = useState<boolean>(true)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [idList, setIdList] = useState<any>([])
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [selectedDashboard, setSelectedDashboard] = useState(false)
  const [successAlert, setSuccessAlert] = useState(false)
  const urlSearchParams = new URLSearchParams(location.search)

  const history = useHistory()

  useEffect(() => {
    const timer = setTimeout(() => {
      setSelectedDashboard(false)
      setSuccessAlert(false)
    }, 2500)

    return () => {
      clearTimeout(timer)
    }
  }, [selectedDashboard, successAlert])

  const form = useForm<Dashboard>({
    onSubmit: (props) => handleFilter(props),
  })

  const handleFilter = ({ values }: any) => {
    getDashboards(values.title, 0)
  }

  const deleteDashboard = () => {
    deleteDashboards(idList)
      .then(() => {
        history.push('/bi-tool/dashboards')
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsEdit(false)
      })
  }

  const getDashboards = useCallback(
    async (title?: string, pageIndex?: number) => {
      const params = parseInt(urlSearchParams.get('pageIndex') || '0')
      const index = pageIndex ? pageIndex - 1 : params ? params - 1 : 0

      try {
        const res = await dashboard.get(title, index, pageSize)

        setDashboards(res.data)
        setIsLoading(false)
      } catch (err) {
        const ex: any = err
        console.log(ex)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSize]
  )

  useEffect(() => {
    getDashboards()
  }, [getDashboards])

  const clearFilter = () => {
    getDashboards()
    setResetable(false)
    form.handleFieldChange('title', '')
    setTimeout(() => {
      setResetable(true)
      setIsDelete(false)
    }, 100)
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/bi-tool/dashboard/create">
        Dashboard oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Dashboardlar" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        BI Tool üzerindeki tüm dashboardları aşağıdan görüntüleyebilirsiniz,
        dilerseniz detayını görüntüleyip düzenleme yapabilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Dashboardlar
      </PageHeader>
    )
  }
  const handleDashboardItems = (id: string) => {
    if (idList.includes(id)) {
      setIdList(idList.filter((item: any) => item !== id))
    } else {
      setIdList([...idList, id])
    }
  }

  const renderList = () => {
    if (isLoading) {
      return <Spinner />
    } else {
      return (
        <div className="safearea">
          <Row>
            {dashboards?.list.map((dashboard, index) => (
              <Column key={index} xs={{ size: 3 }}>
                <DashboardItem
                  isEdit={isEdit}
                  setIsSelected={(id: string) => handleDashboardItems(id)}
                  dashboard={dashboard}
                  isSelected={idList.includes(dashboard.id)}
                />
              </Column>
            ))}
          </Row>
        </div>
      )
    }
  }

  const renderModal = () => {
    return (
      <>
        {isDelete === true && (
          <Styled.HandleDeleteContainer>
            <Row className="handle-delete-row">
              <Column>
                <h3>Seçilen Dashboardları silmek istediğinize eminmisiniz ?</h3>
              </Column>
              <Column className="btn-container">
                <Column xs={{ size: 3 }}>
                  <Button
                    appearance="error"
                    children="Sil"
                    type="button"
                    onClick={() => {
                      deleteDashboard()
                      setIsDelete(false)
                      setIdList([])
                      clearFilter()
                      setSuccessAlert(true)
                    }}
                  />
                </Column>
                <Column xs={{ size: 3 }}>
                  <Button
                    appearance="primary"
                    children="Hayır"
                    type="button"
                    onClick={() => setIsDelete(false)}
                  />
                </Column>
              </Column>
            </Row>
          </Styled.HandleDeleteContainer>
        )}
      </>
    )
  }

  const renderFilter = () => {
    const renderError = () => {
      return (
        selectedDashboard === true && (
          <Styled.Error>
            <p>
              <b>Dashboard seçmeniz gerekmektedir.</b>{' '}
            </p>
            <p className="error-text">
              &#x25B8; Dashboardları silmek icin bir veya birden fazla
              dashboardı seçmeniz gerekmektedir.
            </p>
          </Styled.Error>
        )
      )
    }

    const renderSuccessAlert = () => {
      return (
        successAlert === true && (
          <Styled.Success>
            <p>
              <b>Dashboard silme işlemi başarılı.</b>{' '}
            </p>
            <p className="success-text">
              &#x25B8; Seçilen Dashboardlar silindi.
            </p>
          </Styled.Success>
        )
      )
    }

    return (
      resetable && (
        <Form onSubmit={form.handleSubmit}>
          <Row xs={{ align: 'center' }}>
            <Column xs={{ size: 4 }}>
              <Input
                description="Geçerli bir dashboard adı giriniz"
                label="Dashboard adı"
                name="title"
                placeholder="Örnek metin"
                value={form.values.title}
                onChange={form.handleChange}
              />
            </Column>
            <Column xs={{ size: 3 }}>
              <Select
                errorMessage={form.errors.genderType}
                label="Gösterilecek kayıt sayısı"
                name="pageSize"
                options={globalPageSize}
                onChange={(props) =>
                  setPageSize(Number((props as SelectOptionProps)?.value))
                }
                value={globalPageSize.find((pager) => pager.value === pageSize)}
                description="Her sayfada görüntülenecek dashboard sayısını giriniz"
              />
            </Column>
            <Column>
              <Button
                appearance="primary"
                children="Arama yap"
                iconBefore={<Icon name="search" />}
                type="submit"
              />
              {(isEdit && (
                <Button
                  appearance="error"
                  children="Dashboardları Sil"
                  iconBefore={<Icon name="trash" />}
                  style={{ marginLeft: 20 }}
                  type="button"
                  onClick={() => {
                    if (idList.length < 1) {
                      setSelectedDashboard(true)
                    } else {
                      setIsDelete(!isDelete)
                      setSelectedDashboard(false)
                    }
                  }}
                />
              )) || (
                <Button
                  appearance="primary"
                  children="Düzenle"
                  iconBefore={<Icon name="edit" />}
                  style={{ marginLeft: 20 }}
                  type="button"
                  onClick={() => {
                    setIsEdit(!isEdit)
                  }}
                />
              )}
              <Button
                appearance="subtle"
                children="Filtreyi Temizle"
                iconBefore={<Icon name="send" />}
                style={{ marginLeft: 20 }}
                type="button"
                onClick={() => {
                  clearFilter()
                  setIsEdit(false)
                  setIdList([])
                  setSelectedDashboard(false)
                }}
              />
            </Column>
            {renderError()}
            {renderSuccessAlert()}
          </Row>
        </Form>
      )
    )
  }

  const renderPager = () => {
    if (dashboards) {
      return (
        <Pager
          pages={dashboards.pageCount}
          onChange={(index) => getDashboards('', index)}
        />
      )
    }

    return null
  }

  return (
    <BIToolLayout>
      <Page>
        {renderPageHeader()}
        {renderFilter()}
        {renderList()}
        {renderPager()}
        {renderModal()}
      </Page>
    </BIToolLayout>
  )
}

export default DashboardList
