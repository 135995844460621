import React, { useEffect, useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import * as yup from 'yup'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Form, { FormFooter } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps, SelectValueProps } from '@zera-admin/select'
import Textarea from '@zera-admin/textarea'

import { usePopupContext } from 'app/contexts/popup'
import { CLAIM_METHODS, CLAIM_TYPES } from 'app/shared/constants'
import {
  Claim,
  ClaimType,
  ClaimMethod,
} from 'services/http/identity-server/claim'
import appService from 'services/http/identity-server/app'
import roleService from 'services/http/identity-server/role'

import { ClaimFormProps } from '../types'

const ClaimForm: React.FunctionComponent<ClaimFormProps> = ({
  values,
  onSubmit,
  operation,
  loading,
}) => {
  const [form, setForm] = useState<Claim>(values)
  const [apps, setApps] = useState<SelectOptionProps[]>([])
  const [roles, setRoles] = useState<SelectOptionProps[]>([])
  const [popup] = usePopupContext()
  const validations = {
    title: yup.string().required('İzin başlığı girmelisiniz.'),
    value: yup.string().required('İzin değeri girmelisiniz.'),
    type: yup.string().required('İzin tipi seçmelisiniz.'),
    appId: yup.number().min(1, 'İzinle ilişkili uygulama seçmelisiniz.'),
  }

  useEffect(() => {
    getApps()
    getRoles()
  }, [])

  const getApps = async () => {
    const response = await appService.get({ pageIndex: 0, pageSize: 100 })
    const mapped = (response.data.data || []).map((app) => ({
      label: app.name,
      value: app.id,
    }))

    setApps(mapped)
  }

  const getRoles = async () => {
    const response = await roleService.get({ pageIndex: 0, pageSize: 100 })
    const mapped = (response.data.data || []).map((role) => ({
      label: role.title,
      value: role.id,
    }))

    setRoles(mapped)
  }

  const handleSubmit = () => {
    const schema = yup.object().shape(validations)

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        onSubmit(form)
      })
      .catch((err) => {
        popup.add({
          appearance: 'error',
          title: 'Doğrulama hatası',
          children: (
            <div>
              <p>
                Aşağıdaki kriterlere dikkate alarak, formu gözden geçiriniz.
              </p>
              <ul className="errors">
                {(err.errors || []).map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
        })
      })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Column xs={{ size: 6 }}>
          <Input
            label="İzin başlığı"
            description="İzninize bir başlık belirleyiniz. (ÖR: controller adı, menü başlığı)."
            name="title"
            value={form.title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, title: event.target.value })
            }
          />
        </Column>
        <Column xs={{ size: 6 }}>
          <Input
            label="İzin değeri"
            description="İzninize benzersiz bir değer giriniz. (ÖR: endpoint adı, menü anahtarı)"
            name="value"
            value={form.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, value: event.target.value })
            }
          />
        </Column>
        <Column xs={{ size: 12 }}>
          <Row>
            <Column>
              <Select
                name="type"
                label="İzin tipi"
                placeholder="Seçim yapınız"
                description="İznin uygulanacağı bir tip seçiniz."
                onChange={(options: SelectValueProps) => {
                  setForm({
                    ...form,
                    type: (options as SelectOptionProps).value as ClaimType,
                    method: undefined,
                  })
                }}
                value={
                  form.type
                    ? CLAIM_TYPES.find((claim) => claim.value === form.type)
                    : undefined
                }
                options={CLAIM_TYPES}
              />
            </Column>
            {form.type === ClaimType.Api && (
              <Column xs={{ size: 6 }}>
                <Select
                  name="type"
                  label="İzin methodu"
                  placeholder="Seçim yapınız"
                  description="İznin uygulanacağı API adresinin methodunu belirleyiniz."
                  onChange={(options: SelectValueProps) => {
                    setForm({
                      ...form,
                      method: (options as SelectOptionProps)
                        .value as ClaimMethod,
                    })
                  }}
                  value={
                    form.type
                      ? CLAIM_METHODS.find(
                          (claim) => claim.value === form.method
                        )
                      : undefined
                  }
                  options={CLAIM_METHODS}
                />
              </Column>
            )}
          </Row>
        </Column>
        <Column xs={{ size: 12 }}>
          <Select
            name="roleIds"
            label="İlişkili roller"
            placeholder="Seçim yapınız"
            description="Kullanıcıların sahip olacağı rolleri seçiniz. Kullanıcıların sistem üzerindeki yetenekleri rollerine göre belirlenir."
            isMultiple
            onChange={(props) => {
              const options = props as SelectOptionProps[]

              setForm({
                ...form,
                roles: options.map((option) => ({
                  id: option.value as number,
                  title: option.label,
                })),
              })
            }}
            value={
              form.roles?.length
                ? form.roles.map((role) => ({
                    label: role.title,
                    value: role.id,
                  }))
                : undefined
            }
            options={roles}
          />
        </Column>
        <Column xs={{ size: 12 }}>
          <Select
            name="type"
            label="İlişkili uygulama"
            placeholder="Seçim yapınız"
            description="İznin uygulanacağını bir uygulama seçimi yapınız."
            onChange={(options: SelectValueProps) => {
              setForm({
                ...form,
                appId: (options as SelectOptionProps).value as number,
              })
            }}
            value={
              form.appId
                ? apps.find((app) => app.value === form.appId)
                : undefined
            }
            options={apps}
          />
        </Column>
        <Column xs={{ size: 12 }}>
          <Textarea
            label="İzin açıklaması"
            description="Geçerli iznin amacını kolayca anlayabileceğiniz bir açıklama metni girebilirsiniz."
            name="description"
            value={form.description}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setForm({ ...form, description: event.target.value })
            }
          />
        </Column>
      </Row>
      <FormFooter>
        <ButtonGroup>
          <Button appearance="primary" isLoading={loading} type="submit">
            {operation === 'create' ? 'İzin oluştur' : 'İzni güncelle'}
          </Button>
          <LinkButton href="/claims">Vazgeç</LinkButton>
        </ButtonGroup>
      </FormFooter>
    </Form>
  )
}

export default ClaimForm
