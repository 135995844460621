import { useState, useEffect } from 'react'
import { SelectOptionProps } from '@zera-admin/select'

import trainingGroupService from 'services/http/dataset/training-group'

const useTrainingGroup = () => {
  const [trainingGroups, setTrainingGroups] = useState<SelectOptionProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getTraningGroups()
  }, [])

  const getTraningGroup = (id?: number, name?: string) => {
    if (id !== null && id !== undefined) {
      return {
        label:
          (trainingGroups.find((trainingGroup) => trainingGroup.value === id)
            ?.label as string) ||
          name ||
          '',
        value: id,
      }
    }
    return null
  }

  const getTraningGroups = async () => {
    setIsLoading(true)

    const response = await trainingGroupService.get()
    const mapped = response.data?.list.map((trainingGroup) => ({
      label: trainingGroup.name,
      value: trainingGroup.id as number,
    }))

    setTrainingGroups(mapped)
    setIsLoading(false)

    return mapped
  }

  return {
    trainingGroups,
    getTraningGroup,
    getTraningGroups,
    isLoading,
  }
}

export default useTrainingGroup
