import React from 'react'

import { Row, Column } from '@zera-admin/page'
import Form from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'

import { status } from './../../constant'
import { IReportFilterProps } from './types'

function ReportFilter({ form }: IReportFilterProps) {
  return (
    <Form onSubmit={form.handleSubmit} style={{ margin: '1.5rem 0' }}>
      <Row xs={{ align: 'center' }}>
        <Column xs={{ size: 4 }}>
          <Input
            description="Geçerli bir açıklama giriniz"
            label="Rapor Adı"
            name="description"
            placeholder="Örnek metin"
            value={form.values?.description || ''}
            onChange={form.handleChange}
          />
        </Column>

        <Column xs={{ size: 4 }}>
          <Select
            description="Geçerli bir durum seçimi yapınız"
            label="Durumu"
            name="status"
            options={status}
            placeholder="Seçim yapın"
            value={
              status?.find(
                (status) => status?.value === form.values?.status
              ) || { label: 'Tümü', value: '' }
            }
            onChange={(props) =>
              form.handleFieldChange(
                'status',
                (props as SelectOptionProps).value
              )
            }
          />
        </Column>
        <Column xs={{ size: 3 }}>
          <Button
            appearance="primary"
            children="Arama yap"
            iconBefore={<Icon name="search" />}
            type="submit"
          />
        </Column>
      </Row>
    </Form>
  )
}

export default ReportFilter
