import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'
import Lozenge from '@zera-admin/lozenge'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import { SentimentEnum } from 'services/http/dataset'
import sentimentDataSetsService, {
  SentimentDataSet,
  SentimentDataSetResponse,
} from 'services/http/dataset/sentiment-data-set'

import { SentimentDataSetListProps } from './types'

const SentimentDataSetList: React.FunctionComponent<
  SentimentDataSetListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [sentimentDataSet, setSentimentDataSet] =
    useState<SentimentDataSetResponse>()

  useEffect(() => {
    getSentimentDataSets()
  }, [])

  const getSentimentDataSets = async () => {
    try {
      const response = await sentimentDataSetsService.get()

      setSentimentDataSet(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton appearance="primary" href="/sentiment-data-set/create">
        Duygu veri seti oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Duygu veri setleri" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm duygu veri setlerini görüntüleyebilir, yeni oluşturabilir,
        varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Duygu veri setleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'text',
        text: 'Duygu veri seti metni',
      },
      {
        name: 'sourceName',
        text: 'Kaynak',
      },
      {
        name: 'trainingGroupName',
        text: 'Eğitim grubu',
      },
      {
        name: 'languageCode',
        text: 'Dil kodu',
      },
      {
        name: 'semanticScore',
        text: 'Duygu Skoru',
        render: (props: SentimentDataSet) => {
          if (props.semanticScore === SentimentEnum.Positive) {
            return <Lozenge appearance="success">Pozitif</Lozenge>
          } else if (props.semanticScore === SentimentEnum.Negative) {
            return <Lozenge appearance="error">Negatif</Lozenge>
          } else {
            return <Lozenge appearance="info">Nötr</Lozenge>
          }
        },
      },
      {
        name: 'createdDate',
        text: 'Oluşturulma Tarihi',
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: SentimentDataSet) => (
          <LinkButton
            appearance="subtle-link"
            href={`/sentiment-data-set/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (sentimentDataSet) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={sentimentDataSet.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={sentimentDataSet.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default SentimentDataSetList
