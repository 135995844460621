import {
  mapQueryForRequest,
  mapQueryForResponse,
} from 'services/http/bi-tool/query/mappers'

import { Dashboard, DashboardResponse } from './types'

export const mapDashboardForResponse = (raw: any): Dashboard => ({
  id: raw.data.id,
  title: raw.data.title,
  description: raw.data.description,
  query: mapQueryForResponse(raw.data.query),
  key: raw.data.key,
  schema: raw.data.schema,
})

export const mapDashboardForRequest = (raw: Dashboard) => ({
  id: raw.id,
  title: raw.title,
  description: raw.description,
  query: mapQueryForRequest(raw.query),
  key: raw.key || null,
  schema: raw.schema,
})

export const mapDashboardForList = (raw: any): DashboardResponse => ({
  list: raw.data.map((item: unknown) =>
    mapDashboardForResponse({ data: item })
  ),
  dataCount: raw.dataCount,
  pageCount: raw.pageCount,
})

export const mapDashboardPreviewForResponse = (raw: any): unknown[] => {
  return raw.data.schema || []
}
