export const SQL_WHERE_CLAUSE_REGEX =
  /(?<=WHERE).*(?=GROUP|ORDER|LIMIT|INNER|DISTINCT|COUNT|SELECT)/gims

export const TIME_SYNTAX_REGEX =
  /\w*\s*=\s*#{\s*([^{}]+?)\s*\}\w*|\w*>#{\s*([^{}]+?)\s*\}\w*|\w*<#{\s*([^{}]+?)\s*\}\w*/gm

export const INQUERY_SYNTAX_REGEX =
  /\w*\s*=\s*%{\s*([^{}]+?)\s*\}\w*|\w*>%{\s*([^{}]+?)\s*\}\w*|\w*<%{\s*([^{}]+?)\s*\}\w*/gm

export const PARANTHESES_REGEX = /(\s+|@|&|'|\(|\)|<|>|#)|=|#|%|{|}|/gm
