import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Pagination, { PaginationProps } from '@zera-admin/pagination'

const Pager: React.FunctionComponent<PaginationProps> = ({
  pages,
  onChange,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const history = useHistory()

  const getPageSize = useCallback(() => {
    const searchParams = new URLSearchParams(history.location.search)
    const pageIndex = searchParams.get('pageIndex')

    if (pageIndex) {
      return parseInt(pageIndex)
    }

    return 0
  }, [history.location.search])

  useEffect(() => {
    const pageIndex = getPageSize()
    const activePageIndex = activeIndex ? activeIndex - 1 : 0

    if (pageIndex !== activePageIndex) {
      const index = pageIndex - 1

      setActiveIndex(index < 0 ? 0 : index)
    }
  }, [history.location.search, activeIndex, getPageSize])

  const handleChange = (page = 0) => {
    const searchParams = new URLSearchParams(history.location.search)
    searchParams.set('pageIndex', page.toString())

    onChange?.(page)
    setActiveIndex(page - 1)

    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    })
  }

  if (activeIndex !== undefined) {
    return (
      <Pagination
        selectedIndex={activeIndex}
        onChange={handleChange}
        pages={Number(pages)}
      />
    )
  }

  return null
}

export default Pager
