import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import {
  VisualizationOptions,
  WidgetVisualization,
} from 'services/http/bi-tool/widget'

import {
  AreaChartVisualizationOptions,
  generateAreaChartOptions,
} from '../area-chart'
import {
  RadarChartVisualizationOptions,
  generateRadarChartOptions,
} from '../radar-chart'
import {
  BarChartVisualizationOptions,
  generateBarChartOptions,
} from '../bar-chart'
import {
  StackedChartVisualizationOptions,
  generateStackedChartOptions,
} from '../stacked-chart'
import { CounterVisualizationOptions, generateCounterOptions } from '../counter'
import {
  generatePieChartOptions,
  PieChartVisualizationOptions,
} from '../pie-chart'
import {
  generateTableOptions,
  generateTableVisualization,
  TableVisualizationOptions,
} from '../table'
import {
  generateTweetOptions,
  generateTweetVisualization,
  TweetVisualizationOptions,
} from '../tweet'
import {
  generateRedditOptions,
  generateRedditVisualization,
  RedditVisualizationOptions,
} from '../reddit'
import {
  generateNewsOptions,
  generateNewsVisualization,
  NewsVisualizationOptions,
} from '../news'
import {
  generateUserOptions,
  generateUserVisualization,
  UserVisualizationOptions,
} from '../user'
import {
  generateInfluentialMapOptions,
  generateInfluentialMapVisualization,
  InfluentialMapVisualizationOptions,
} from '../influential-map'
import { BarVisualizationOptions, generateBarOptions } from '../bar'
import {
  generateNameValueOptions,
  NameValueVisualizationOptions,
} from '../name-value'
import {
  ColoredMapVisualizationOptions,
  generateColoredMapOptions,
  generateColoredMapVisualization,
} from '../colored-map'
import {
  TimeSeriesVisualizationOptions,
  generateTimeSeriesOptions,
} from '../time-series'
import {
  WordCloudVisualizationOptions,
  generateWordCloudOptions,
} from '../word-cloud'

import {
  generateGraphChartOptions,
  GraphChartVisualizationOptions,
} from '../graph-chart'
import {
  generateSocialNumbersOptions,
  generateSocialNumbersVisualization,
  SocialNumbersVisualizationOptions,
} from '../social-numbers'
import { generateHotelOptions, HotelVisualizationOptions } from '../hotel'

export const mapVisualizationOptions = (
  visualization: WidgetVisualization,
  query: Query,
  fields: Field[]
) => {
  let options: VisualizationOptions = {
    ...visualization.options,
  }

  switch (visualization.type) {
    case 'table':
      options = generateTableOptions(
        options as TableVisualizationOptions,
        query,
        fields
      )
      break
    case 'pie-chart':
      options = generatePieChartOptions(
        options as PieChartVisualizationOptions,
        query,
        fields
      )
      break
    case 'bar-chart':
      options = generateBarChartOptions(
        options as BarChartVisualizationOptions,
        query,
        fields
      )
      break
    case 'stacked-chart':
      options = generateStackedChartOptions(
        options as StackedChartVisualizationOptions,
        query,
        fields
      )
      break
    case 'area-chart':
      options = generateAreaChartOptions(
        options as AreaChartVisualizationOptions,
        query,
        fields
      )
      break
    case 'radar-chart':
      options = generateRadarChartOptions(
        options as RadarChartVisualizationOptions,
        query,
        fields
      )
      break
    case 'counter':
      options = generateCounterOptions(
        options as CounterVisualizationOptions,
        query
      )
      break
    case 'colored-map':
      options = generateColoredMapOptions(
        options as ColoredMapVisualizationOptions,
        query,
        fields
      )
      break
    case 'tweet':
      options = generateTweetOptions(
        options as TweetVisualizationOptions,
        query,
        fields
      )
      break
    case 'user':
      options = generateUserOptions(
        options as UserVisualizationOptions,
        query,
        fields
      )
      break
    case 'reddit':
      options = generateRedditOptions(
        options as RedditVisualizationOptions,
        query,
        fields
      )
      break
    case 'news':
      options = generateNewsOptions(
        options as NewsVisualizationOptions,
        query,
        fields
      )
      break
    case 'hotel':
      options = generateHotelOptions(
        options as HotelVisualizationOptions,
        query,
        fields
      )
      break
    case 'influential-map':
      options = generateInfluentialMapOptions(
        options as InfluentialMapVisualizationOptions,
        query,
        fields
      )
      break
    case 'name-value':
      options = generateNameValueOptions(
        options as NameValueVisualizationOptions,
        query,
        fields
      )
      break
    case 'bar':
      options = generateBarOptions(
        options as BarVisualizationOptions,
        query,
        fields
      )
      break
    case 'time-series':
      options = generateTimeSeriesOptions(
        options as TimeSeriesVisualizationOptions,
        query,
        fields
      )
      break
    case 'word-cloud':
      options = generateWordCloudOptions(
        options as WordCloudVisualizationOptions,
        query,
        fields
      )
      break
    case 'tweet-activities':
      options = generateTweetOptions(
        options as TweetVisualizationOptions,
        query,
        fields
      )
      break
    case 'graph-chart':
      options = generateGraphChartOptions(
        options as GraphChartVisualizationOptions,
        query,
        fields
      )
      break
    case 'social-numbers':
      options = generateSocialNumbersOptions(
        options as SocialNumbersVisualizationOptions,
        query,
        fields
      )
      break
    default:
      break
  }

  return options
}

export const mapVisualization = (
  visualization: WidgetVisualization,
  fields: Field[]
) => {
  if (visualization.type === 'tweet') {
    return generateTweetVisualization(fields)
  } else if (visualization.type === 'user') {
    return generateUserVisualization(fields)
  } else if (visualization.type === 'reddit') {
    return generateRedditVisualization(fields)
  } else if (visualization.type === 'news') {
    return generateNewsVisualization(fields)
  } else if (visualization.type === 'influential-map') {
    return generateInfluentialMapVisualization(fields)
  } else if (visualization.type === 'colored-map') {
    return generateColoredMapVisualization(fields)
  } else if (visualization.type === 'social-numbers') {
    return generateSocialNumbersVisualization(fields)
  } else {
    return generateTableVisualization(fields)
  }
}
