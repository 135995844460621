import React, { useState } from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'
import { Column, Row } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Drawer from '@zera-admin/drawer'
import Input from '@zera-admin/input'
import Icon from '@zera-admin/icon'

import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'

import * as Styled from './BarChartDefinitions.styled'
import { BarChartDefinitionsProps } from './types'

import { NUMBER_FORMATS } from './constants'
import {
  BarChartVisualizationNumberFormatStyle,
  BarChartVisualizationSerie,
} from '../types'

const BarChartDefinitions: React.FunctionComponent<BarChartDefinitionsProps> =
  ({ aggregations = [], fields = [], groups = [], options, onChange }) => {
    const [activeField, setActiveField] =
      useState<BarChartVisualizationSerie | null>()
    const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
      getFieldOptions(fields)
    )

    const handleChangeFieldInput = (value: string) => {
      const option = {
        label: value,
        value,
      }
      const index = fieldOptions.findIndex(
        (reference) => reference.value === parseInt(option.value)
      )
      let optionsInstance = [...fieldOptions]

      if (value && index === -1) {
        optionsInstance[0] = option
      } else {
        optionsInstance = getFieldOptions(fields)
      }

      setFieldOptions(optionsInstance)
    }

    const handleUpdateField = (key: string, value: string | object) => {
      const result = { ...activeField, [key]: value }

      setActiveField(result as BarChartVisualizationSerie)
    }

    const handleSaveFieldSettings = () => {
      const result = [...(options.series || [])]
      const index = result.findIndex(
        (item) => item.field === activeField?.field
      )

      if (index !== -1 && activeField) {
        result[index] = activeField

        onChange({ ...options, series: result })
        setActiveField(null)
      }
    }

    const handleRemoveField = (name: string) => {
      const result = [...(options.series || [])]

      const index = (options.series || []).findIndex(
        (column) => column.field === name
      )

      if (index !== -1) {
        result.splice(index, 1)

        onChange({ ...options, series: result })
      }
    }

    const renderFieldOptions = () => {
      return (
        <Row
          xs={{ direction: 'column' }}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <Column>
            <Input
              name="name"
              label="Alan adı"
              value={activeField?.field}
              readOnly
            />
          </Column>
          <Column>
            <Input
              name="alias"
              label="Sütun görünüm adı"
              placeholder="Ziyaretçi sayısı"
              value={activeField?.alias}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleUpdateField('alias', event.target.value)
              }
            />
          </Column>
        </Row>
      )
    }

    const renderFieldsDrawer = () => {
      if (!activeField) return null

      const actions = (
        <ButtonGroup>
          <Button appearance="primary" onClick={handleSaveFieldSettings}>
            Ayarları uygula
          </Button>
          <Button onClick={() => setActiveField(null)}>Kapat</Button>
        </ButtonGroup>
      )

      return (
        <Drawer
          actions={actions}
          isOpen={activeField ? true : false}
          onClose={() => setActiveField(null)}
          title={`${string.textify(activeField.field)} ayarları`}
          width="narrow"
          description="Aşağıdan geçerli alana ait özellikleri güncelleyebilirsiniz."
          isBlock={true}
        >
          {renderFieldOptions()}
        </Drawer>
      )
    }

    const renderTabs = () => {
      return (
        <Tabs id="tabs">
          <TabTriggerGroup>
            <TabTrigger>Veri</TabTrigger>
            <TabTrigger>Görünüm</TabTrigger>
          </TabTriggerGroup>
          <TabPanel>{renderDataTab()}</TabPanel>
          <TabPanel>{renderFormatTab()}</TabPanel>
        </Tabs>
      )
    }

    const renderFormatTab = () => {
      return (
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Select
              name="style"
              label="Numaralandırma formatı"
              placeholder="Bir format seçiniz"
              options={NUMBER_FORMATS}
              value={
                options.format?.style
                  ? {
                      value: options.format.style,
                      label: NUMBER_FORMATS.find(
                        (item) => item.value === options.format?.style
                      )?.label as string,
                    }
                  : { label: 'Varsayılan', value: 'default' }
              }
              onChange={(props) =>
                onChange({
                  ...options,
                  format: {
                    ...options.format,
                    style: (props as SelectOptionProps)
                      .value as BarChartVisualizationNumberFormatStyle,
                  },
                })
              }
            />
          </Column>
          <Column>
            <Input
              name="prefix"
              label="Ön ek"
              placeholder="Fiyat"
              value={options.format?.prefix}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange({
                  ...options,
                  format: {
                    ...options.format,
                    prefix: event.target.value,
                  },
                })
              }
            />
          </Column>
          <Column>
            <Input
              name="suffix"
              label="Son ek"
              placeholder="Adet"
              value={options.format?.suffix}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange({
                  ...options,
                  format: {
                    ...options.format,
                    suffix: event.target.value,
                  },
                })
              }
            />
          </Column>
        </Row>
      )
    }

    const renderDataTab = () => {
      const series = [...(options?.series || [])]

      if (!series.length) {
        series.push({ field: '', alias: '', type: 'number' })
      }

      return (
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Select
              name="column"
              options={fieldOptions}
              onChange={(props) => {
                onChange({
                  ...options,
                  label: {
                    field: (props as SelectOptionProps).value as string,
                  },
                })
              }}
              onInputChange={handleChangeFieldInput}
              value={
                options.label
                  ? {
                      label: string.textify(options?.label?.field || ''),
                      value: options?.label?.field,
                    }
                  : { label: '', value: '' }
              }
              label="Kırılım"
              placeholder="Seçim yapınız"
              description="Chart üzerinde gösterilecek kırılımı seçin"
              isDisabled={aggregations.length || groups.length ? true : false}
            />
          </Column>
          <Column>
            {series.map((serie, index) => (
              <Styled.BarChartDefinitionField key={index}>
                <Row>
                  <Column>
                    <Select
                      name="column"
                      options={fieldOptions}
                      onChange={(props) => {
                        const field = (props as SelectOptionProps)
                          .value as string
                        const reference = fields.find(
                          (value) => value.field === field
                        )

                        series[index] = {
                          field: (reference?.field as string) || field,
                          type: reference?.type || 'string',
                          alias: reference?.alias || reference?.field || field,
                        }

                        onChange({
                          ...options,
                          series,
                        })
                      }}
                      onInputChange={handleChangeFieldInput}
                      value={
                        serie.field
                          ? {
                              label: string.textify(serie.field),
                              value: serie.field,
                            }
                          : { label: '', value: '' }
                      }
                      label="Değer"
                      placeholder="Seçim yapınız"
                      description="Chart üzerinde gösterilecek değeri seçin"
                      isDisabled={
                        aggregations.length || groups.length ? true : false
                      }
                    />
                  </Column>

                  <ButtonGroup className="button-group">
                    <Button
                      onClick={() =>
                        setActiveField(
                          activeField?.field === serie.field ? null : serie
                        )
                      }
                      size="small"
                      appearance="subtle"
                      isDisabled={!serie.field}
                    >
                      <Icon name="settings" size="small" />
                    </Button>
                    <Button
                      onClick={() => handleRemoveField(serie.field)}
                      size="small"
                      appearance="subtle"
                      isDisabled={
                        !serie.field ||
                        aggregations.length > 0 ||
                        groups.length > 0
                      }
                    >
                      <Icon name="cross" size="small" />
                    </Button>
                  </ButtonGroup>
                </Row>
              </Styled.BarChartDefinitionField>
            ))}
          </Column>
          <Column>
            {!aggregations.length && !groups.length && (
              <Button
                onClick={() => {
                  series.push({ alias: '', field: '', type: 'number' })

                  onChange({
                    ...options,
                    series,
                  })
                }}
              >
                Yeni değer ekle
              </Button>
            )}
          </Column>
          {renderFieldsDrawer()}
        </Row>
      )
    }

    return (
      <Styled.BarChartDefinitions>{renderTabs()}</Styled.BarChartDefinitions>
    )
  }

export default BarChartDefinitions
