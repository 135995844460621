import React from 'react'
import { Column, Row } from '@zera-admin/page'
import EmptyState from '@zera-admin/empty-state'
import TwitterProfileCardTrainingControls from 'components/twitter-profile-training-controls'

import { MostFollowedFollowersProps } from './types'

const MostFollowedFollowers: React.FunctionComponent<
  MostFollowedFollowersProps
> = (props) => {
  const { data, columnSize, cutName } = props

  return data && data.length > 0 ? (
    <React.Fragment>
      <Row style={{ marginTop: '2vh' }}>
        {data?.map((account: any, index: number) => (
          <Column xs={{ size: columnSize || 3 }} key={index}>
            <TwitterProfileCardTrainingControls
              key={index}
              account={{
                userId: account.entityKey,
                name: !cutName
                  ? account.name
                  : account.name.split('').length > 25
                  ? account.name.slice(0, 25) + '...'
                  : account.name,
                username: account.screenName,
                countryDetection: account.countryDetection,
                tweetCount: account.statusesCount,
                profilImage: account.profileImageUrl,
                follower: account.followersCount,
                followUp: account.friendsCount,
              }}
            />
          </Column>
        ))}
      </Row>
    </React.Fragment>
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default MostFollowedFollowers
