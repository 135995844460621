import React from 'react'
import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { botConclusion } from 'app/shared/constants'
import { validation } from 'app/functions'
import { BotControlSet } from 'services/http/dataset/bot-control-set'

import { BotControlSetFormProps } from './types'

const validations = {
  conclusion: yup.string().required('Bot durumu seçmelisiniz'),
  languageCode: yup.string().required('Dil kodu girmelisiniz'),
  text: yup.string().required('Bot kontrol seti metni girmelisiniz'),
}

const BotControlSetForm: React.FunctionComponent<BotControlSetFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const form = useForm<BotControlSet>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = (props: FormHookSubmitProps<BotControlSet>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Geçerli bir bot kontrol seti metni giriniz"
              errorMessage={form.errors.text}
              label="Bot kontrol seti metni"
              name="text"
              placeholder="Örnek metin"
              value={form.values.text}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Input
              description="Geçerli bir dil kodu giriniz"
              errorMessage={form.errors.languageCode}
              label="Dil kodu"
              name="languageCode"
              placeholder="Örnek: tr, en, vs.."
              value={form.values.languageCode}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Select
              description="Geçerli bir bot seçimi yapınız"
              errorMessage={form.errors.conclusion}
              label="Bot durumu"
              name="conclusion"
              options={botConclusion}
              value={botConclusion.find(
                (botConclusion) =>
                  botConclusion.value === form.values.conclusion
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'conclusion',
                  (props as SelectOptionProps).value
                )
              }
              placeholder="Seçim yapın"
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default BotControlSetForm
