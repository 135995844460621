import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'

import { AreaChartVisualizationOptions } from '../types'

export const generateAreaChartOptions = (
  options: AreaChartVisualizationOptions,
  query: Query,
  fields: Field[]
): AreaChartVisualizationOptions => {
  const result = options

  const aggregation = query.aggregations?.length
    ? query.aggregations[query.aggregations.length - 1]
    : null
  const group = query.groups?.length
    ? query.groups[query.groups.length - 1]
    : null

  if (aggregation) {
    result.value = {
      field: aggregation.field as string,
      type: aggregation.type,
      alias: aggregation?.alias || (aggregation.field as string),
    }
  }

  if (group) {
    result.label = {
      field: group.field as string,
    }
  }

  return result
}
