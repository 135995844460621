import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import InfoCard from '../report-card'
import { SentimentAnalysisProps } from './types'

const SentimentAnalysis: React.FunctionComponent<SentimentAnalysisProps> = ({
  data,
  chartHeight,
}) => {
  const categoriesByTime: Props = {
    options: {
      chart: {
        id: 'categoriesByTime',
      },
      xaxis: {
        categories: [...data?.map((row: any) => row.sentiment)],
      },
      legend: { show: false },
      colors: ['#ff6f00', '#ff6f00', '#ff6f00'],
      plotOptions: {
        bar: {
          borderRadius: 4,
          distributed: true,
        },
      },
    },
    series: [
      {
        name: 'sayısı',
        data: [...data?.map((row: any) => Number(row.rowCount))],
      },
    ],
  }

  return (
    <InfoCard title="Sentiment Analizi">
      <Chart
        options={categoriesByTime.options}
        series={categoriesByTime.series}
        type="bar"
        height={chartHeight}
      />
    </InfoCard>
  )
}

export default SentimentAnalysis
