import styled from 'styled-components'

export const NewsCards = styled.div`
  width: 30%;
  padding: 15px;
  border-right: 1px solid lightgray;
  @media (width >= 2440px) {
    width: 30%;
  }

  a {
    color: black;
    text-decoration: none;
  }
`

export const CardText = styled.div`
  color: black;
  margin-top: 1vh;
  font-size: 0.7rem;
  .createdat {
    margin-top: 3vh;
    @media (width >= 2440px) {
      font-size: 16px;
    }
  }
  p {
    padding-top: 5px;
    height: 200px;
    overflow: auto;
    nth-child(0) {
      height: 200px;
      overflow: auto;
    }
    @media (width >= 2440px) {
      font-size: 1.4rem;
    }
  }
`
export const CardMain = styled.div`
  width: 100%;
  height: 200px;
  @media (width >= 2440px) {
    height: 400px;
  }
  border: 2px solid lightgray;
  border-radius: 10px;
  margin-top: 20px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`

export const CardHead = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  h3 {
    padding-left: 10px;
  @media (width >= 2440px) {
    font-size:2rem;
  }
  }
  img{
  @media (width >= 2440px) {
    width:100px;
    height:100px;
  }
`
