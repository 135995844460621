import { request } from 'services/http/backoffice/instance'

import type { NewsList, NewsListResponse } from './types'

import { mapNewsListForList, mapNewsListForResponse } from './mappers'

export const get = (pageIndex: number, pageSize: number, Link: string) =>
  request<NewsListResponse>({
    method: 'get',
    url: 'back-office/news-source',
    params: {
      PageIndex: pageIndex,
      PageSize: pageSize,
      BlackListStatus: 0,
      Link,
    },
    mappers: {
      res: mapNewsListForList,
    },
  })

export const getById = (id: string) =>
  request<NewsList>({
    method: 'get',
    url: `back-office/news-source/get-by-id/${id}`,
    mappers: {
      res: mapNewsListForResponse,
    },
  })

export const update = (id: string) => {
  return request({
    method: 'put',
    url: `back-office/news-source/${id}`,
    data: { blackListStatus: 1 },
  })
}
