import React, { useState } from 'react'
import Form from '@zera-admin/form'
import MainLayout from 'layouts/main/Main'

import { Column, PageHeader, Row } from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'

import { CreateAlarmProps } from './types'
import AlarmDesigner from '../alarm-designer/AlarmDesigner'

import { getSettings } from 'services/http/backoffice/alarm-keyword/endpoints'
import { RadioGroup } from '@zera-admin/radio'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import Spinner from '@zera-admin/spinner'
import { alarmTypes } from '../constant'

const CreateAlarms: React.FunctionComponent<CreateAlarmProps> = ({}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [alarmType, setAlarmType] = useState<string>('')

  const [settings, setSettings] = useState<any>()

  const getAlarmSettings = (selectedAlarmType: string) => {
    setAlarmType('')
    setIsLoading(true)
    getSettings(Number(selectedAlarmType)).then((res) => {
      setSettings(res.data.data)
      setAlarmType(selectedAlarmType)
      setIsLoading(false)
    })
  }

  const renderPageHeader = () => {
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Alarmlar" href="/alarms" />
        <BreadcrumbItem text={'Alarm oluştur'} />
      </Breadcrumb>
    )

    return (
      <PageHeader
        // actions={actions}
        breadcrumbs={breadcrumbs}
      >
        Alarm oluştur
      </PageHeader>
    )
  }

  const renderContent = () => {
    return (
      <Form>
        <Row>
          <Column>
            <RadioGroup
              label="Alarm Tipi"
              name="alarmType"
              onChange={(props) => {
                getAlarmSettings(props.currentTarget.value)
              }}
              options={alarmTypes}
            />
          </Column>
        </Row>
        {isLoading ? (
          <LoadingView>
            <Spinner size="large" />
            <h5>Alarm tipine göre alarm formu hazırlanıyor...</h5>
          </LoadingView>
        ) : null}

        {alarmType ? (
          <Row>
            <Column xs={{ size: 12 }}>
              <AlarmDesigner
                settings={settings}
                alarmType={Number(alarmType)}
              />
            </Column>
          </Row>
        ) : null}
      </Form>
    )
  }

  return (
    <MainLayout>
      {renderPageHeader()}
      {renderContent()}
    </MainLayout>
  )
}

export default CreateAlarms
