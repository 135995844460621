import { EntityCategoryType, EntityCategoryTypeResponse } from './types'

export const mapEntityCategoryTypeForRequest = (
  raw: EntityCategoryType
): unknown => ({
  entity_category_type_id: raw.id,
  name: raw.name,
  parent_id: raw.parentId === 0 ? null : raw.parentId,
})

export const mapEntityCategoryTypeForResponse = (
  raw: any
): EntityCategoryType => ({
  id: raw.data.id,
  name: raw.data.name,
  parentId: raw.data.parent_id === null ? 0 : raw.data.parent_id,
  parentName: raw.data.parent_name,
})

export const mapEntityCategoryTypeForList = (
  raw: any
): EntityCategoryTypeResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapEntityCategoryTypeForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
