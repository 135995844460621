import styled from 'styled-components'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100%;
`
export const ModalContent = styled.div`
  margin-top: 4px;
`
