import { useCallback, useEffect, useRef } from 'react'

import * as Styled from './GeoChart.styled'
import { GeoChartProps } from './types'

let chart: google.visualization.GeoChart

const GeoChart: React.FunctionComponent<GeoChartProps> = ({
  height = 350,
  onLoad,
}) => {
  const visibilityRef = useRef(false)

  const initMap = useCallback(() => {
    const chartElement = document.getElementById('regions')

    if (chartElement) {
      chart = new window.google.visualization.GeoChart(chartElement)
    }

    if (onLoad) {
      onLoad(chart)
    }
  }, [onLoad])

  useEffect(() => {
    if (window.google && window.google.visualization) {
      initMap()
    } else {
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src = 'https://www.gstatic.com/charts/loader.js'
      script.onload = () => {
        google.charts
          .load('current', {
            packages: ['geochart'],
            mapsApiKey: 'AIzaSyCFMVfDomSWYXwciCLTs1Hxf0YgtPo4XK4',
          })
          .then(() => {
            initMap()
          })
      }

      document.body.appendChild(script)
    }
  }, [initMap])

  useEffect(() => {
    const visibilityChange = () => {
      if (visibilityRef.current) {
        visibilityRef.current = false
      } else {
        visibilityRef.current = true
      }
    }

    document.addEventListener('visibilitychange', visibilityChange)

    return function cleanup() {
      document.removeEventListener('visibilitychange', visibilityChange)
    }
  }, [])

  return <Styled.Chart id="regions" height={height} />
}

export default GeoChart
