import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import * as Styled from './StresGraph.styled'

export interface GraphCard {
  series?: any
  categories?: any
}
const StresGraph: React.FunctionComponent<GraphCard> = ({
  series,
  categories,
}) => {
  const horizontalBarChart: Props = {
    series: series,
    options: {
      chart: {
        height: 500,
        type: 'line',
        zoom: {
          enabled: false,
        },
        background: '#fff',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3, 3, 3, 3, 3, 3, 3],
        curve: 'straight',
        dashArray: [3, 3, 3, 3, 3, 3, 3, 3],
      },
      title: {
        text: 'Öne Çıkan Trendler',
        align: 'left',
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            ' - ' +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            ''
          )
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: categories,
      },
      colors: [
        '#E69E15',
        '#D13FD6',
        '#20D496',
        '#ff0000',
        '#00ffff',
        '#ffff00',
        '#006600',
        '#269FFB',
      ],
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val + ' (mins)'
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val + ' per session'
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
    },
  }

  return (
    <Styled.StresGraphContainer>
      <div
        className="chart-content"
        // className="footer-area-container"
        // style={{
        //   display: 'flex',
        //   // justifyContent: 'center',
        //   alignItems: 'center',
        //   backgroundColor: '#F1F8FF',
        //   borderRadius: 10,
        //   width: '130%',
        //   height: '100%',
        //   paddingLeft: '3%',
        // }}
      >
        <Chart
          type="line"
          series={horizontalBarChart.series}
          options={horizontalBarChart.options}
        />
      </div>
    </Styled.StresGraphContainer>
  )
}
export default StresGraph
