import styled from 'styled-components'

export const Container = styled.div`
  a {
    color: black;
    text-decoration: none;
  }
  color: black;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid lightgray;
  margin-top: 1vh;
  width: 320px;
  background-color: white;
  @media (min-width: 768px) {
    width: 26%;
  }

  @media (width >= 2440px) {
    width: 30%;
  }
  .news-image-container {
    position: relative;
    top: -10%;
    left: 45%;

    @media screen and (min-width: 1420px) {
      top: -28px !important;
      left: 43% !important;
    }

    @media (width >= 2440px) {
      left: 45%;
      top: -13%;
    }
    @media (width >= 1440px) {
      left: 42%;
    }
    @media (width >= 1440px) {
      left: 43%;
    }
    @media (width >= 1920px) {
      left: 44%;
    }
    @media (width >= 2880px) {
      left: 42%;
    }
  }
  .news-image {
    border-radius: 50%;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      @media (width >= 2440px) {
        width: 110px;
        height: 100px;
      }
    }
  }
  .news-logo-background {
    width: 100%;
    background-color: lightgray;
    height: 90px;
    border-radius: 10px 10px 0 0;
    img {
      width: 100%;
      height: 100%;
    }
    @media (width >= 2440px) {
      height: 140px;
    }
  }
  .news-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    top: -8%;

    .news-content-title {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .news-title {
        font-size: 0.9rem;
        font-weight: 800;
        @media (width >= 2440px) {
          font-size: 1.6rem;
        }
      }
      .news-uri {
        opacity: 0.5;
        font-size: 0.6rem;
        @media (width >= 2440px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  .news-action-container {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.3rem;
    gap: 1em;
  }
  .news-action-location {
    font-size: 0.5rem;
    display: flex;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.4rem;
      @media (width >= 2440px) {
        font-size: 0.9rem;
      }
    }
    i {
      font-size: 0.8rem;

      @media (width >= 2440px) {
        font-size: 1.5rem;
      }
    }
  }
  .news-action-social {
    font-size: 0.8rem;
    display: flex;
    gap: 1rem;
    margin-bottom: 1.3rem;
  }
  .news-action-count {
    font-weight: 800;
    font-size: 0.5rem;
    @media (width >= 2440px) {
      font-size: 1.2rem;
    }
  }
  .news-action-title {
    font-size: 0.5rem;
    @media (width >= 2440px) {
      font-size: 1rem;
    }
  }
`
