import styled from 'styled-components'

export const QuoraCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content !important;

  .quora-red {
    border-radius: 0 0 8px 8px;
    background-color: #b92b27;
  }

  .post {
    display: flex;
    flex-direction: column;

    .post-header {
      display: flex;
      align-items: center;
      padding: 16px;

      .post-header-profile-picture {
        position: relative;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          object-fit: cover;
        }

        .verified-container {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: #0078ff;
          border-radius: 50%;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #fff;
          img {
            width: 6px;
            height: 6px;
          }
        }
      }

      .post-header-info {
        display: flex;
        flex-direction: column;

        margin-left: 16px;

        .post-header-info-name {
          a {
            font-weight: 600;
            color: #333;
          }
        }

        .post-header-info-details {
          display: flex;
          gap: 4px;
          font-size: 14px;
          color: #666;
        }
      }
    }

    .post-body {
      padding: 0 16px;
      margin-bottom: 16px;

      .post-body-title {
        span {
          font-size: 20px;
          font-weight: 600;
          color: #333;
        }
      }

      .post-body-description {
        div {
          font-size: 16px;
          color: #666;
        }

        button {
          background: none;
          border: none;
          color: #333;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .post-body-image {
        width: 100%;
        img {
          width: 100%;
          border-radius: 8px;
          margin-top: 16px;
          height: 200px;

          object-fit: cover;
        }
      }

      .post-body-stats {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 16px 0 0 0;

        gap: 16px;

        span {
          padding: 4px 8px;
          border-radius: 24px;
          color: #666;
          background-color: #f0f0f0;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .post-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e0e0e0;

      .post-footer-logo {
        .post-footer-logo-warpper {
          img {
            width: 32px;
            height: 32px;
          }
        }
      }

      .post-footer-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding: 8px 0;

        img {
          width: 90px;
          height: 24px;
          object-fit: cover;
        }
      }

      .post-footer-url {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 0;

        a {
          color: #fff;

          border-radius: 4px;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
`
