import { parser, string, druid, oracle } from 'app/functions'
import { request } from 'services/http/bi-tool/instance'

import { WidgetQueryType, WidgetVisualization } from '../widget'
import { WidgetVersionType } from '../widget/enums'

import {
  mapQueryRunForResponse,
  mapQueryForRequest,
  mapQueryHttpForRequest,
} from './mappers'
import * as middleware from './middleware'
import {
  QueryHttpRequest,
  QueryRaw,
  QueryRequest,
  QueryRunResponse,
} from './types'

export const json = (
  raw: QueryRequest,
  visualization?: WidgetVisualization,
  params?: object
) =>
  request<QueryRunResponse>({
    method: 'post',
    url: 'query/run/v2',
    params,
    data: raw,
    mappers: {
      req: () => mapQueryForRequest(raw, visualization),
      res: mapQueryRunForResponse,
    },
  })

export const sql = (url: string, sql: string) =>
  request<QueryRunResponse>({
    method: 'post',
    url,
    data: {
      query: sql,
      useApproximateCountDistinct: true,
      useApproximateTopN: true,
      useCache: true,
    },
    mappers: {
      res: (data) => mapQueryRunForResponse(data),
    },
  })

export const http = (url: string, raw: QueryHttpRequest) =>
  request<QueryRunResponse>({
    method: 'get',
    origin: string.isValidUrl(url) ? new URL(url).origin : '',
    url: string.isValidUrl(url) ? new URL(url).pathname.substring(1) : '',
    params: raw,
    mappers: {
      res: mapQueryRunForResponse,
    },
  })

export const getUserScreenNameList = (params?: object) =>
  request<{ data: string[] }>({
    method: 'post',
    url: 'back-office/active-account/user-screen-names',
    data: params,
  })

export const run = async (
  data: QueryRequest,
  visualization: WidgetVisualization,
  type: WidgetQueryType,
  params?: object
) => {
  if (type === 'raw') {
    let url = 'query/run/raw-query'
    let query = ''

    if (visualization.version === WidgetVersionType.Druid) {
      const result = await druid(data)

      query = result.query
    } else if (visualization.version === WidgetVersionType.Oracle) {
      url = 'query/run/oracle/raw-query'
      query = await oracle(data, params)
    } else {
      const request = await middleware.inquery(data)
      const parsed = parser.query(request?.raw as QueryRaw)

      query =
        parser.druid(
          parsed as string,
          1,
          data.where?.rules,
          data.interval,
          true
        ) || ''
    }

    return sql(url, query)
  } else if (type === 'http') {
    return http(data.http?.url || '', mapQueryHttpForRequest(data))
  } else {
    const request = await middleware.subquery(data)

    return json(request, visualization, params)
  }
}
