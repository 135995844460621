import React, { useState } from 'react'
import Form from '@zera-admin/form'
import MainLayout from 'layouts/main/Main'

import { Column, PageHeader, Row } from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'

import { DetailAlarmProps } from './types'
import AlarmDesigner from '../alarm-designer/AlarmDesigner'
import Spinner from '@zera-admin/spinner'

import {
  getById,
  getSettings,
} from 'services/http/backoffice/alarm-keyword/endpoints'
import { IAlarmDetail } from 'services/http/backoffice/alarm-keyword/types'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import { conditionBuilderDetail } from 'app/functions/parser/condition-builder/detail'
import { RadioGroup } from '@zera-admin/radio'
import { alarmTypes } from '../constant'

const DetailAlarm: React.FunctionComponent<DetailAlarmProps> = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [settings, setSettings] = useState<any>()
  const [alarmDetail, setAlarmDetail] = useState<IAlarmDetail>(
    {} as IAlarmDetail
  )

  const getAlarmGetById = () => {
    getById(String(match.params.id))
      .then((res) => {
        if (res.code === 200) {
          getSettings(res.data.data.alarmType).then((res) => {
            setSettings(res.data.data)
            setIsLoading(false)
          })
          setAlarmDetail({
            ...res.data.data,
            criteriaGroup: conditionBuilderDetail(
              res?.data?.data?.criteriaGroup || []
            ),
          })
        }
      })
      .catch((err) => {
        setIsLoading(false)
      })
      .finally(() => setIsLoading(false))
  }

  React.useEffect(() => {
    getAlarmGetById()
  }, [])

  const renderPageHeader = () => {
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Alarmlar" href="/alarms" />
        <BreadcrumbItem text={'Alarm Düzenle'} />
      </Breadcrumb>
    )

    return (
      <PageHeader
        // actions={actions}
        breadcrumbs={breadcrumbs}
      >
        Alarm Düzenle
      </PageHeader>
    )
  }

  const renderContent = () => {
    return isLoading ? (
      <LoadingView>
        <Spinner size="large" />
        <h5>Alarm detay bilgileri yükleniyor Hazırlanıyor...</h5>
      </LoadingView>
    ) : (
      <Form>
        <Row>
          <Column>
            <RadioGroup
              label="Alarm Tipi"
              name="alarmType"
              defaultValue={String(alarmDetail?.alarmType)}
              options={alarmTypes}
              isDisabled
            />
          </Column>
        </Row>
        <Column xs={{ size: 12 }} style={{ padding: 0, marginTop: '-10px' }}>
          <AlarmDesigner
            settings={settings}
            detail={alarmDetail}
            alarmType={Number(alarmDetail?.alarmType)}
            operation="detail"
          />
        </Column>
      </Form>
    )
  }

  return (
    <MainLayout>
      {renderPageHeader()}
      {renderContent()}
    </MainLayout>
  )
}

export default DetailAlarm
