import { ConditionBuilderValue } from '@zera-admin/condition-builder'

import { getIntervalPeriod } from 'app/functions'
import { TableVisualizationOptions } from 'bi-tool/visualizations/table'
import { TweetVisualizationOptions } from 'bi-tool/visualizations/tweet'

import { VisualizationOptions, WidgetVisualization } from '../widget'
import {
  QueryHttpPerception,
  QueryHttpSentiment,
  QueryHttpSource,
  QueryHttpTime,
} from './enums'
import {
  Query,
  QueryHttpRequest,
  QueryRequest,
  QueryRunResponse,
} from './types'

export const mapQueryForRequest = (
  raw?: Query,
  visualization?: WidgetVisualization<VisualizationOptions>
): unknown => ({
  aggregations: raw?.aggregations || [],
  distinct: raw?.distinct || false,
  fields: mapQueryFieldsForRequest(raw, visualization),
  groups: raw?.groups || [],
  http: raw?.http || {},
  interval: raw?.interval,
  latest: raw?.latest || false,
  limit: raw?.limit || 100,
  orders: raw?.orders || [],
  raw: {
    sql: raw?.raw?.sql,
    intervals: raw?.raw?.intervals,
    inqueries: raw?.raw?.inqueries?.map((inquery) => ({
      ...inquery,
      values: [],
    })),
    functions: raw?.raw?.functions || [],
  },
  source: raw?.source,
  table: raw?.table,
  where: raw?.where?.rules?.length ? raw.where : {},
})

export const mapQueryForResponse = (raw: any): Query => ({
  aggregations: raw?.aggregations || [],
  distinct: raw?.distinct || false,
  fields: raw?.fields || [],
  groups: raw?.groups || [],
  http: raw?.http || {},
  interval: raw?.interval,
  latest: raw?.latest || false,
  limit: raw?.limit,
  orders: raw?.orders || [],
  raw: raw?.raw,
  source: raw?.source,
  table: raw?.table,
  where: raw?.where || {},
})
export const mapDashboardKeysForResponse = (raw: any): any => ({
  id: raw?.id,
  key: raw?.key,
  title: raw?.title,
})

export const mapQueryRunForResponse = (raw: any): QueryRunResponse => ({
  headers:
    raw.headers ||
    (raw?.columns || []).map((column: string) => ({
      name: column,
    })) ||
    [],
  rows: raw.rows.slice(0, 200) || [],
})

export const mapQueryFieldsForRequest = (
  raw?: Query,
  visualization?: WidgetVisualization<VisualizationOptions>
): unknown => {
  if (visualization?.type === 'table') {
    const options = visualization.options as TableVisualizationOptions

    if (options.columns?.length) {
      return options.columns?.map((column) => ({
        name: column.name,
        alias: column.alias,
      }))
    }
  } else if (
    visualization?.type === 'tweet' ||
    visualization?.type === 'reddit' ||
    visualization?.type === 'news' ||
    visualization?.type === 'user'
  ) {
    const options = visualization.options as TweetVisualizationOptions

    if (Object.keys(options?.fields).length) {
      return Object.keys(options?.fields)
        .filter((field) => options?.fields[field])
        .map((field) => ({
          name: options?.fields[field],
        }))
    }
  } else {
    return raw?.fields || []
  }
}

export const mapQueryWhereForRequest = (where: ConditionBuilderValue) => {
  if (where?.rules?.length) {
    where.rules = where.rules?.filter((rule) => rule.operator !== 'query')
  }

  if (!where?.rules?.length) {
    where = {} as ConditionBuilderValue
  }

  return where
}

export const mapQueryHttpForRequest = (
  query: QueryRequest
): QueryHttpRequest => {
  const request = {
    perception: QueryHttpPerception.General,
    sentiment: QueryHttpSentiment.General,
    source: QueryHttpSource.OtherAndTurkey,
    time: QueryHttpTime.Daily,
  }

  const getSentiment = (): QueryHttpSentiment => {
    const sentiment = (query.where?.rules || []).find(
      (rule) => rule.field === 'sentiment'
    )

    if (sentiment?.value === 'positive') {
      return QueryHttpSentiment.Positive
    } else if (sentiment?.value === 'negative') {
      return QueryHttpSentiment.Negative
    } else return QueryHttpSentiment.General
  }

  const getSource = (): QueryHttpSource => {
    const source = (query.where?.rules || []).find(
      (rule) => rule.field === 'detectedSourceLanguage'
    )

    if (source?.operator === 'in') {
      return QueryHttpSource.Turkey
    } else if (source?.operator === 'notIn') {
      return QueryHttpSource.Other
    } else return QueryHttpSource.OtherAndTurkey
  }

  const getPerception = (): QueryHttpPerception => {
    const perception = (query.where?.rules || []).find(
      (rule) => rule.field === 'perception'
    )

    if (perception?.value === 'tourism') {
      return QueryHttpPerception.Tourism
    }
    return QueryHttpPerception.General
  }

  const getTime = (): QueryHttpTime => {
    const period = getIntervalPeriod(query.interval)

    if (period === 'hourly') {
      return QueryHttpTime.Hourly
    } else if (period === 'weekly') {
      return QueryHttpTime.Weekly
    } else if (period === 'monthly') {
      return QueryHttpTime.Monthly
    } else return QueryHttpTime.Daily
  }

  request.time = getTime()
  request.sentiment = getSentiment()
  request.source = getSource()
  request.perception = getPerception()

  return request
}
