import { request } from 'services/http/dataset/instance'

import type {
  CreateNamedEntityRecognitionRequest,
  NamedEntityRecognition,
  NamedEntityRecognitionResponse,
  UpdateNamedEntityRecognitionRequest,
} from './types'

import {
  mapNamedEntityRecognitionForList,
  mapNamedEntityRecognitionForRequest,
  mapNamedEntityRecognitionForResponse,
} from './mappers'

export const create = (data: CreateNamedEntityRecognitionRequest) =>
  request<NamedEntityRecognition>({
    method: 'post',
    url: 'named-entity-recognition',
    data,
    mappers: {
      req: mapNamedEntityRecognitionForRequest,
    },
  })

export const get = () =>
  request<NamedEntityRecognitionResponse>({
    method: 'get',
    url: 'named-entity-recognition',
    mappers: {
      res: mapNamedEntityRecognitionForList,
    },
  })

export const getById = (id: number) =>
  request<NamedEntityRecognition>({
    method: 'get',
    url: `named-entity-recognition/${id}`,
    mappers: {
      res: mapNamedEntityRecognitionForResponse,
    },
  })

export const update = (data: UpdateNamedEntityRecognitionRequest) =>
  request<NamedEntityRecognition>({
    method: 'put',
    url: `named-entity-recognition/${data.id}`,
    data,
    mappers: {
      req: mapNamedEntityRecognitionForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `named-entity-recognition/${id}`,
  })
