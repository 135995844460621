import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'
import EmptyState from '@zera-admin/empty-state'

import { TurkeyPerceptionSentimentCountsProps } from './types'

const TurkeyPerceptionSentimentCounts: React.FunctionComponent<TurkeyPerceptionSentimentCountsProps> =
  ({ data }) => {
    const tweetsByTimeAfter: Props = {
      options: {
        chart: {
          id: 'TurkeyPerceptionSentimentCountsGraph',
        },
        colors: [colors.B200],
        xaxis: {
          categories: data?.map((item: any) => item.Sentiment),
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            distributed: true,
          },
        },
        tooltip: {},
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'Sayısı',
          data: data?.map((item: any) => item.TotalCount),
        },
      ],
    }
    return data && data.length ? (
      <Chart
        options={tweetsByTimeAfter.options}
        series={tweetsByTimeAfter.series}
        type="bar"
      />
    ) : (
      <EmptyState
        title="Veri bulunamadı"
        description="Kullanıcı verisi bulunamadı"
      />
    )
  }

export default TurkeyPerceptionSentimentCounts
