import React, { useState } from 'react'
import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Alert from '@zera-admin/alert'
import Button, { LinkButton } from '@zera-admin/button'
import Checkbox from '@zera-admin/checkbox'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Spinner from '@zera-admin/spinner'
import Switch from '@zera-admin/switch'

import { validation } from 'app/functions'
import TwitterProfile from 'components/twitter-profile'
import { PersonReport } from 'services/http/report/person-report/types'
import crawlTask from 'services/http/report/crawl-task'
import linkedinService from 'services/http/linkedin-dev/search'

import { checks, checksPerceptions, initialValues, switches } from './constant'
import { PersonReportRequestFormProps } from './types'

const validations = {
  userName: yup.string().required('Raporu oluşturulacak kişinin adını giriniz'),
}

const PersonReportRequestForm: React.FunctionComponent<PersonReportRequestFormProps> =
  ({ actions = [], onError, onSubmit, values }) => {
    const form = useForm<PersonReport>({
      initialValues: values,
      onSubmit: (props) => handleSubmit(props),
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })
    const [verifiedUser, setVerifiedUser] = useState<any>({})
    const [isLoading, setIsLoading] = useState(false)
    const [linkedinSource, setLinkedinSource] = useState<
      SearchboxOptionProps[]
    >([])
    const [perceptionKeyword, setPerceptionKeyword] = useState<string>()

    const handleSubmit = (props: FormHookSubmitProps<PersonReport>) => {
      if (!props.errors) {
        if (onSubmit) {
          onSubmit(props.values, () => {
            if (props.handleReset) {
              props.handleReset()
            }
          })
        }
      } else {
        if (onError) {
          onError(props.errors)
        }
      }
    }

    const searchUsername = (username: string) => {
      setIsLoading(true)
      crawlTask.searchUsername(username).then((response) => {
        setIsLoading(false)
        setVerifiedUser(response.data.data)
      })
    }

    const checkHandler = (form: any, name: string, prop: string): void => {
      if (!form.values[prop]) {
        form.handleFieldChange(prop, [name])
      } else if (!form.values[prop].includes(name)) {
        form.handleFieldChange(prop, [...form.values[prop], name])
      } else {
        const origin = form.values[prop]
        const index: number = form.values[prop].indexOf(name)
        origin.splice(index, 1)
        form.handleFieldChange(prop, origin)
      }
    }

    const getAutoComplete = async (value: string) => {
      const response = await linkedinService.getAutoComplete(value)
      const mapped = response?.data?.map((account: any) => ({
        label: account.title,
        value: account.id,
      }))

      setLinkedinSource(mapped)
      return mapped
    }

    const subDetails = () => {
      const details = []

      if (verifiedUser?.isUserExist === true) {
        details.push(
          <Column xs={{ size: 12 }}>
            <TwitterProfile data={verifiedUser?.profileInfo} />
          </Column>
        )

        if (verifiedUser?.isUserProtected === true) {
          details.push(
            <h3>
              Twitter kullanıcısının profili gizli olduğundan dolayı analiz
              yapılamaz
            </h3>
          )
        }

        if (verifiedUser?.processingGroupIdList?.length > 0) {
          details.push(
            <React.Fragment>
              <Alert
                appearance="error"
                title="Aktif rapor isteği bulunmaktadır"
              >
                <p>
                  Devam eden süreci bulunan kullanıcılar için rapor isteği
                  oluşturamazsınız.
                </p>
                <br />
                <LinkButton
                  appearance="primary"
                  href={`/person-report/process/detail/${verifiedUser?.processingGroupIdList[0]?.groupId}`}
                >
                  Raporu görmek için tıklayınız
                </LinkButton>
              </Alert>
              <hr style={{ width: '100%' }} color="#cecece" />
              <Row>
                <Column xs={{ size: 12 }}>
                  <h3>Bu kullanıcıya ait devam eden rapor istekleri</h3>
                </Column>
                <Column xs={{ size: 12 }}>
                  <Row>
                    {verifiedUser?.processingGroupIdList?.map((report: any) => (
                      <Column xs={{ size: 4 }}>
                        <p>
                          <a
                            style={{ marginRight: '1rem' }}
                            href={`/person-report/process/detail/${report.groupId}`}
                          >
                            {form.values.userName} (
                            {new Date(report.createdAt).toLocaleString()})
                          </a>
                        </p>
                      </Column>
                    ))}
                  </Row>
                </Column>
              </Row>
            </React.Fragment>
          )
        }

        if (verifiedUser?.completedGroupIdList?.length > 0) {
          details.push(
            <React.Fragment>
              <hr style={{ width: '100%' }} color="#cecece" />
              <Row>
                <Column xs={{ size: 12 }}>
                  <h3>Bu kullanıcıya ait geçmiş raporlar</h3>
                </Column>
                <Column xs={{ size: 12 }}>
                  <Row>
                    {verifiedUser?.completedGroupIdList.map((report: any) => (
                      <Column xs={{ size: 4 }}>
                        <p>
                          <a
                            style={{ marginRight: '1rem' }}
                            href={`/person-report/detail/${report.groupId}`}
                          >
                            {form.values.userName} (
                            {new Date(report.createdAt).toLocaleString()})
                          </a>
                        </p>
                      </Column>
                    ))}
                  </Row>
                </Column>
              </Row>
            </React.Fragment>
          )
        }
      } else if (verifiedUser?.isUserExist === false) {
        return <h3>X kullanıcısı bulunamadı</h3>
      }

      return details
    }

    const details = () => {
      return (
        verifiedUser?.isUserExist &&
        verifiedUser?.isUserProtected === false && (
          <Row>
            <Column xs={{ size: 12 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h3>Rapor Olayları</h3>
                <div>
                  <Switch
                    label="Tümünü seç"
                    name="all-check-switch"
                    size="large"
                    isChecked={form.values.selectedEvents?.length === 7}
                    onChange={(event) => {
                      if (event.currentTarget.checked) {
                        form.handleFieldChange('selectedEvents', [
                          'fetch-profile-details',
                          'fetch-user-tweets',
                          'create-word-count',
                          'fetch-user-followers',
                          'use-ai',
                          'use-big5',
                          'generate-statistics',
                        ])
                      } else {
                        form.handleFieldChange('selectedEvents', [
                          'fetch-profile-details',
                          'fetch-user-tweets',
                        ])
                      }
                    }}
                  />
                </div>
              </div>
            </Column>
            {switches.map((item, index) => (
              <Column key={index} xs={{ size: 4 }}>
                <Switch
                  isChecked={form.values.selectedEvents?.includes(item.id)}
                  isDisabled={item.disabled}
                  label={item.label}
                  name={item.name}
                  size="large"
                  onChange={() => checkHandler(form, item.id, 'selectedEvents')}
                />
              </Column>
            ))}
            <hr style={{ width: '100%' }} color="#cecece" />
            <Column xs={{ size: 12 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h3>Rapor İstatistikleri</h3>
                <div>
                  <Checkbox
                    label="Tümünü seç"
                    name="sa"
                    size="large"
                    isChecked={
                      form.values.requestField?.length === checks.length + 1
                    }
                    onChange={(event) => {
                      if (event.currentTarget.checked) {
                        form.handleFieldChange('requestField', [
                          'mostFollowedFollowers',
                          'mostTweetedFollowers',
                          'tweetsByTimeAfter2021',
                          'tweetsByTimeBefore2021',
                          'categoriesByTime',
                          'mostRetweeted20Tweets',
                          'followersByCountry',
                          'totalRetweetCount',
                          'totalReplyCount',
                          'userInfo',
                          'mostUsedHashtags',
                          'mostMentions',
                          'followersByGender',
                          'aspectAnalysis',
                          'bigFive',
                          'botDetection',
                          'wordCloud',
                          'mostRecentTweets',
                          'mostImpressedBy',
                          'activeHours',
                          'languagesUsedByUser',
                          'averageSentiments',
                          'tweetImpressions',
                          'googleSearch',
                          'wikipediaSearch',
                          'facebookSearch',
                          'linkedinSearch',
                          'emailSearch',
                          'generalCategories',
                          'mostCommonEntities',
                          'turkeyPerceptionTweets',
                          'turkeyPerceptionSentimentCounts',
                          'turkeyPerceptionTweetMetrics',
                        ])
                      } else {
                        form.handleFieldChange('requestField', [])
                      }
                    }}
                  />
                </div>
              </div>
            </Column>
            {checks.map((item, index) => (
              <Column key={index} xs={{ size: 4 }}>
                <Checkbox
                  isChecked={form.values.requestField?.includes(item.name)}
                  onChange={() => checkHandler(form, item.name, 'requestField')}
                  label={item.label}
                  name={item.name}
                  size="large"
                />
              </Column>
            ))}
            <Column xs={{ size: 12 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h6>Rapor - Algı İstatistikleri</h6>
              </div>
            </Column>
            {checksPerceptions.map((item, index) => (
              <Column key={index} xs={{ size: 4 }}>
                <Checkbox
                  isChecked={form.values.requestField?.includes(item.name)}
                  onChange={() => checkHandler(form, item.name, 'requestField')}
                  label={item.label}
                  name={item.name}
                  size="large"
                />
              </Column>
            ))}
            {(form.values.requestField?.includes('turkeyPerceptionTweets') ||
              form.values.requestField?.includes(
                'turkeyPerceptionSentimentCounts'
              ) ||
              form.values.requestField?.includes(
                'turkeyPerceptionTweetMetrics'
              )) && (
              <>
                <Column xs={{ size: 12 }}>
                  Türkiye ile ilgili algılarda kullanılan anahtar sözcükler;
                </Column>
                <Column xs={{ size: 12, align: 'center' }}>
                  {initialValues.turkeyPerceptionQueryKeywords.map((el) => (
                    <span
                      style={{
                        display: 'inline-block',
                        backgroundColor: '#82eb68',
                        padding: '2px 3px',
                        color: '#5a5959',
                        borderRadius: '2px',
                        marginRight: '5px',
                      }}
                    >
                      {`${el}`}
                    </span>
                  ))}
                  <Button
                    onClick={() => {
                      initialValues.turkeyPerceptionQueryKeywords.pop()
                      form.handleFieldChange(
                        'turkeyPerceptionQueryKeywords',
                        initialValues.turkeyPerceptionQueryKeywords
                      )
                    }}
                  >
                    Sil
                  </Button>
                </Column>
                <Column xs={{ size: 7 }}>
                  <Input
                    description="Yeni algi ekle"
                    errorMessage={form.errors.userName}
                    label="Anahtar kelime giriniz"
                    name="perceptionKeyword"
                    value={perceptionKeyword}
                    placeholder="Turkey"
                    onChange={(ctx) => {
                      setPerceptionKeyword(ctx.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4, align: 'center' }}>
                  <Button
                    onClick={() => {
                      if (perceptionKeyword) {
                        initialValues.turkeyPerceptionQueryKeywords.push(
                          perceptionKeyword
                        )
                        form.handleFieldChange(
                          'turkeyPerceptionQueryKeywords',
                          initialValues.turkeyPerceptionQueryKeywords
                        )
                        setPerceptionKeyword('')
                      }
                    }}
                  >
                    Ekle
                  </Button>
                </Column>
              </>
            )}
          </Row>
        )
      )
    }
    const linkedinValue = () => {
      if (form.values.linkedinValue) {
        return {
          label:
            (linkedinSource.find(
              (linkedin) => linkedin.value === form.values.linkedinValue
            )?.label as string) || form.values.linkedinValue,
          value: form.values.linkedinValue,
        }
      }
      return null
    }
    return (
      <Form onSubmit={form.handleSubmit}>
        <FormSection
          title="Genel bilgiler"
          description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
        >
          <Row>
            <Column xs={{ size: 9 }}>
              <Input
                description="Geçerli bir X kullanıcı adı giriniz"
                errorMessage={form.errors.userName}
                label="X kullanıcı adı giriniz"
                name="userName"
                placeholder="GLOMIL"
                value={form.values.userName}
                onChange={(ctx) => {
                  setVerifiedUser({})
                  form.handleChange(ctx)
                }}
              />
            </Column>
            <TwitterProfile data={null} />
            <Column xs={{ size: 3, align: 'center' }}>
              <Button
                onClick={() => searchUsername(form.values.userName || '')}
                isLoading={isLoading}
              >
                Doğrula
              </Button>
            </Column>
            <Column xs={{ size: 12 }}>{subDetails()}</Column>
            <hr style={{ width: '100%' }} color="#cecece" />
            <Column xs={{ size: 6 }}>
              <Input
                label="Google'da aranmasını istediğiniz değeri giriniz"
                name="googleSearchValue"
                placeholder="GLOMIL"
                value={form.values.googleSearchValue}
                onChange={form.handleChange}
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Input
                label="Wikipedia'da aranmasını istediğiniz kişiyi giriniz"
                name="wikipediaValue"
                placeholder="GLOMIL"
                value={form.values.wikipediaValue}
                onChange={form.handleChange}
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <SearchBox
                label="Linkedin de aranacak kişinin adını giriniz"
                name="linkedinValue"
                options={linkedinSource}
                value={linkedinValue()}
                onFetchOptions={(value) => getAutoComplete(value)}
                onChange={(props) =>
                  form.handleFieldChange(
                    'linkedinValue',
                    (props as SearchboxOptionProps).value
                  )
                }
                placeholder="Arama yapın"
                isLoading={isLoading}
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Input
                label="Facebook'da aranmasını istediğiniz değeri giriniz"
                name="facebookValue"
                placeholder="GLOMIL"
                value={form.values.facebookValue}
                onChange={form.handleChange}
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Input
                label="Email'i aranmasını istediğiniz değeri giriniz"
                name="emailValue"
                placeholder="GLOMIL"
                value={form.values.emailValue}
                onChange={form.handleChange}
              />
            </Column>

            {!isLoading ? (
              !(verifiedUser?.isProcessing === true) &&
              verifiedUser?.isUserExist &&
              details()
            ) : (
              <Spinner />
            )}
          </Row>
        </FormSection>
        {!isLoading && (
          <FormFooter>
            {!(verifiedUser?.isProcessing === true) &&
              verifiedUser?.isUserExist &&
              actions}
          </FormFooter>
        )}
      </Form>
    )
  }

export default PersonReportRequestForm
