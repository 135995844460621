import styled from 'styled-components'

export const AgendaReportForm = styled.div`
  footer {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 0.6rem 0;
  }

  table {
    tbody {
      td {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;

        button {
          padding: 0.2rem;
        }
      }
    }
  }
  .def-check-container {
    display: flex;
    position: relative;
    align-items: center;
    gap: 4px;
  }
  .eng-definition-container {
    display: flex;
    gap: 10px;
    position: relative;
    .q-mark-container {
      left: 320px !important;
      top: 0px !important;
    }
  }
  .q-mark {
    cursor: pointer;
    background-color: lightgray;
    padding: 2px 5px;
    border-radius: 50%;
    font-size: 9px;
    font-weight: 800;
    &:hover {
      opacity: 0.7;
      transition: 0.6s;
      .q-mark-container {
        display: block;
      }
    }
  }
  .wordgroup-btn-container {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    span {
      position: relative;
      cursor: pointer;

      &:hover {
        .info-container {
          display: block;
        }
      }
    }
  }
  .info-container {
    position: absolute;
    bottom: -62px;
    left: 0px;
    background-color: #f4f5f7;
    padding: 1rem;
    border-radius: 8px;
    width: 280px;
    height: 20px;
    display: none;
    text-align: center;
  }
  .q-mark-container {
    position: absolute;
    bottom: -20px;
    left: 30px;
    background-color: #f4f5f7;
    padding: 1rem;
    border-radius: 8px;
    width: 280px;
    height: 20px;
    display: none;
    text-align: center;
  }
  .word-container {
    width: 35%;
    border-bottom: 1px solid lightgray;
    color: gray;
    display: flex;
    justify-content: space-between;
    button {
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
        i {
          &:hover {
            transition: 0.6s;
            background: gray;
            padding: 0.3rem;
            border-radius: 4px;
            font-size: 0.6rem;
            color: white;
          }
        }
      }
    }
  }
  .css-1scvf87-FormFooter {
    background: transparent !important;
  }
  .criteria-fields {
    width: 100%;
    padding-left: 1rem;
    &-container {
      width: 100%;
      padding-left: 1rem;
    }
  }
  .criteria-table-container {
    margin-top: 2rem;
  }
`

export const AgendaReportFormInput = styled.div`
  position: relative;

  & > span {
    position: absolute;
    right: 1rem;
    top: 2rem;
  }
`
