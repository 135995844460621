import React from 'react'

import InfoCard from '../report-card'
import { TalkListReportProps } from './types'
import TwitterIcon from '../../../../../assets/images/twitter-new-logo-dark.png'

const TalkListReport: React.FunctionComponent<TalkListReportProps> = ({
  data,
}) => {
  return (
    <InfoCard icon={TwitterIcon} title="X En Çok Konuşulanlar Listesi">
      <table className="info-card-table">
        <tbody>
          {data?.map((talked: any) => (
            <tr>
              <td className="title-bold">
                {talked.trendTopicName}
                <span>{`${talked.rowCount.toLocaleString('tr-TR')} Post`}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </InfoCard>
  )
}
export default TalkListReport
