import { uid } from '@zera-admin/utils'
import _ from 'lodash'

import { useDashboardContext } from 'app/contexts/dashboard'
import { Widget } from 'services/http/bi-tool/widget'

import { SchemaNode, SchemaObject, SchemaSourceOptions } from './node'
import { SchemaTree } from './tree'

export const useSchemaService = () => {
  const context = useDashboardContext()

  const changeProperty = (element: string, prop: string, value: any) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = _.cloneDeep(SchemaTree.parse(pageSchema))
    const node = schemaTree.search(element)

    if (node) {
      node.setProp(prop, value)

      context.set(schemaTree.toArray())
    }
  }

  const changeSource = (element: string, value: any) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = _.cloneDeep(SchemaTree.parse(pageSchema))
    const node = schemaTree.search(element)

    if (node) {
      node.setSourceObject(value)

      context.set(schemaTree.toArray())
    }
  }

  const changeProperties = (
    element: string,
    props: any,
    source?: SchemaSourceOptions
  ) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = _.cloneDeep(SchemaTree.parse(pageSchema))
    const node = schemaTree.search(element)

    if (node) {
      node.setProps(props)
      if (source) {
        node.setSourceObject(source)
      }

      context.set(schemaTree.toArray())
    }
  }

  const removeNode = (id: string) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = SchemaTree.parse(pageSchema)
    const node = schemaTree.search(id)

    if (node) {
      if (node.parent === null) {
        let index = schemaTree.rootNodes.findIndex(
          (x) => x.getDefinition('id') === id
        )
        schemaTree.removeChildrenAt(index)
      } else {
        let index = node.parent.children.findIndex(
          (x) => x.getDefinition('id') === id
        )
        node.parent.removeChildren(index)
      }

      context.set(schemaTree.toArray())
    }
  }

  const insertRoot = (type: string, props: object, index: number): string => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = SchemaTree.parse(pageSchema)
    const node = new SchemaNode(null)
    const sameNodes = schemaTree.searchBy((r) => r.name === type)

    node
      .setType(type)
      .setProps(props)
      .setDefinitions({
        id: uid(),
        name: type + ((sameNodes.length || 0) + 1 || ''),
      })

    schemaTree.insertChildren(index, node)

    context.set(schemaTree.toArray())

    return node.getDefinition('id')
  }

  const moveRoot = (oldIndex: number, newIndex: number) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = SchemaTree.parse(pageSchema)

    schemaTree.moveChildren(oldIndex, newIndex)

    context.set(schemaTree.toArray())
  }

  const moveElement = (
    fromId: string,
    toId: string,
    nodeId: string,
    oldIndex: number,
    newIndex: number
  ) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = SchemaTree.parse(pageSchema)
    const node = schemaTree.search(nodeId)

    if (node) {
      if (fromId === 'root') {
        schemaTree.removeChildrenAt(oldIndex)
      } else {
        node.parent?.removeChildren(oldIndex)
      }

      if (toId === 'root') {
        schemaTree.insertChildren(newIndex, node)
      } else {
        const toNode = schemaTree.search(toId)

        if (toNode) {
          toNode.insertChildren(newIndex, node)
        }
      }

      context.set(schemaTree.toArray())
    }
  }

  const generateChildrens = (
    children: SchemaNode[],
    count?: number
  ): SchemaNode[] => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = SchemaTree.parse(pageSchema)
    let latest = 0
    let childrenCount = count ? count : 0

    children.forEach((childrenItem) => {
      childrenCount = childrenCount + 1
      const sameNodes = schemaTree.searchBy((r) => r.name === childrenItem.name)
      sameNodes.forEach((x) => {
        const match = x.getName().match(/\d+$/)
        if (match) {
          const endingNumber = parseInt(match[0], 10)

          if (endingNumber + 1 > latest) {
            latest = endingNumber + 1
          }
        }
      })
      const clonedProps = _.cloneDeep(childrenItem.props)
      const clonedSource = _.cloneDeep(childrenItem.source)

      childrenItem
        .setDefinitions({
          id: uid(),
          name: childrenItem.name + (latest + childrenCount + ''),
        })
        .setProps(clonedProps)
        .setSourceObject(clonedSource)

      if (childrenItem.children && childrenItem.children.length > 0) {
        generateChildrens(childrenItem.children, childrenCount)
      }
    })

    return children
  }

  const insertAt = (
    parentId: string,
    type: string,
    props: object,
    index: number,
    children?: SchemaNode[],
    options?: Widget,
    source?: SchemaSourceOptions
  ) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = SchemaTree.parse(pageSchema)
    const parentNode = schemaTree.search(parentId)

    if (parentNode) {
      const node = new SchemaNode(parentNode)
      const sameNodes = schemaTree.searchBy((r) => r.name === type)
      const clonedProps = _.cloneDeep(props)
      const clonedSource = _.cloneDeep(source)

      if (type === 'widget') {
        node
          .setType('column')
          .setProps({
            sizes: {
              md: 6,
              lg: 6,
              sm: 6,
              xs: 6,
            },
          })
          .setDefinitions({
            id: uid(),
          })

        const widgetNode = new SchemaNode(node)

        widgetNode
          .setType('widget')
          .setDefinitions({
            id: uid(),
          })
          .setSourceObject(source)
          .setProps(props)

        node.insertChildren(1, widgetNode)
      } else {
        node
          .setType(type)
          .setProps(clonedProps)
          .setOptions(options as Widget)
          .setDefinitions({
            id: uid(),
            name: type + ((sameNodes.length || 0) + 1 || ''),
          })
          .setSourceObject(clonedSource || {})

        if (children && children.length > 0) {
          const generated = generateChildrens(children)
          node.appendChildrens(generated)
        }
      }

      parentNode.insertChildren(index, node)

      context.set(schemaTree.toArray())

      return node.getDefinition('id')
    }
  }

  const cloneWidget = (
    id: string,
    props: object,
    source: SchemaSourceOptions
  ) => {
    const pageSchema: SchemaObject[] =
      (context.state.schema as SchemaObject[]) || []
    const schemaTree = SchemaTree.parse(pageSchema)
    const node = schemaTree.search(id) as SchemaNode
    const parentId = node.parent?.definitions?.id || ''
    const parentNode = schemaTree.search(parentId)

    if (id && parentId && parentNode) {
      insertAt(
        'row-default',
        'widget',
        props,
        parentNode?.children.length + 1,
        [],
        undefined,
        source
      )
    }
  }

  return {
    changeProperties,
    changeProperty,
    changeSource,
    cloneWidget,
    generateChildrens,
    insertAt,
    insertRoot,
    moveElement,
    moveRoot,
    removeNode,
  }
}
