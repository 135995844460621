interface Criteria {
  fieldName: string
  value: any
  clause: string
  fieldType: any | null
}

interface Group {
  operation: number
  criteria: Criteria[]
  groups: Group[]
}

interface Rule {
  id: string
  type: string
  field: string
  value: any
  operator: string
}

interface OutputGroup {
  // field?: string
  // dataType?: any | null
  // operator: string
  // value: any
  id: string
  type?: string
  condition?: string
  rules?: (Rule | OutputGroup)[]
}

export function conditionBuilderDetail(input: Group): OutputGroup {
  function generateId(): string {
    return 'xxxxxx'.replace(/x/g, () => ((Math.random() * 16) | 0).toString(16))
  }

  const transformCriteriaToRule = (criteria: Criteria): Rule => ({
    id: generateId(),
    type: 'rule',
    field: criteria.fieldName,
    value:
      criteria.clause.toLowerCase() === 'false' ||
      criteria.clause.toLowerCase() === 'true'
        ? String(criteria.value)
        : criteria.value,
    operator:
      criteria.clause.toLowerCase() === 'false' ||
      criteria.clause.toLowerCase() === 'true'
        ? 'equal'
        : criteria.clause,
  })

  const transformGroupToOutputGroup = (group: Group): OutputGroup => ({
    id: generateId(),
    type: 'group',
    condition: group.operation === 1 ? 'and' : 'or',
    rules: [
      ...group.groups.map(transformGroupToOutputGroup),
      ...group.criteria.map(transformCriteriaToRule),
    ],
  })

  const output: OutputGroup = {
    id: generateId(),
    type: 'group',
    condition: input.operation === 1 ? 'and' : 'or',
    rules: [
      ...input.criteria.map(transformCriteriaToRule),
      ...input.groups.map(transformGroupToOutputGroup),
    ],
  }

  return output
}
