import React from 'react'
import * as Styled from './TwitterTweetCard.styled'
import WorkersImage from 'assets/images/newspaper.jpg'
export interface Tweet {
  entityKey: string
  text: string
  createdAt: string
  userName: string
  screenName: string
  profileImageUrl: string
  isVerified: boolean
  replyCount: number
  favoriteCount: number
  userFollowerCount: number
  userFollowingCount: number
  userStatusesCount: number
  retweetCount: number
  sentiment: string
  replyCountStr: string
  favoriteCountStr: string
  userFollowerCountStr: string
  userFollowingCountStr: string
  userStatusesCountStr: string
  retweetCountStr: string
  medias: string
}
export interface TweetCard {
  tweet: Tweet
}
const TwitterTweetCard: React.FunctionComponent<TweetCard> = ({ tweet }) => {
  const dates = new Date(tweet.createdAt)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    second: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  const formattedDate = dates.toLocaleDateString('tr-TR', options)
  return (
    <Styled.TwitterTweetCard>
      <a
        href={`https://twitter.com/${tweet.screenName}/status/${tweet.entityKey}`}
      >
        <div className="tweet-card-header">
          <div className="twitter-card-header-img">
            <img id="profileShadow" src={tweet.profileImageUrl} alt="" />
          </div>
          <div className="twitter-card-header-title">
            <span className="title">{tweet.userName}</span>
            <span className="uri">@{tweet.screenName}</span>
          </div>
        </div>
        <div
          className={`twitter-follow-container ${
            tweet.medias[0] ? 'is-media' : 'not-media'
          }`}
        >
          <span className="twitter-follow-count-container" id="card-box">
            <span className="span-weight">
              <i className="fa-solid fa-user-large"></i>Takip Edilen:{' '}
              {tweet.userFollowingCountStr}
            </span>
          </span>
          <span className="twitter-follow-count-container" id="card-box">
            <span className="span-weight span-second">
              <i className="fa-solid fa-user-group"></i>Takipçi:{' '}
              {tweet.userFollowerCountStr}
            </span>
          </span>
        </div>
        <div className="twitter-content-text-container">
          <p style={{ color: 'black' }}>{tweet.text}</p>
        </div>
        {tweet.medias[0] && (
          <div className="twitter-content-thumbnail-container">
            <img src={tweet.medias[0]} alt="" />
          </div>
        )}

        <div className="twitter-action-content-container">
          <div className="twitter-action-date">
            <span>{formattedDate}</span>{' '}
            {/* <span>
            <span className="thumbnail-mn">{tweet.userStatusesCountStr} </span>
            Goruntuleme
          </span> */}
          </div>
          <div className="twitter-footer-spans">
            <div className="twitter-footer-span">
              <span className="twitter-footer-count">
                {tweet.retweetCountStr}
              </span>
              <span className="twitter-footer-title">Repost</span>
            </div>
            <div className="twitter-footer-span">
              <span className="twitter-footer-count">
                {tweet.replyCountStr}
              </span>
              <span className="twitter-footer-title">Alinti Post</span>
            </div>
            <div className="twitter-footer-span">
              <span className="twitter-footer-count">
                {tweet.favoriteCount}
              </span>
              <span className="twitter-footer-title">Begeni</span>
            </div>
          </div>
        </div>
      </a>
    </Styled.TwitterTweetCard>
  )
}

export default TwitterTweetCard
