import { EntityType, EntityTypeResponse } from './types'

export const mapEntityTypeForRequest = (raw: EntityType): unknown => ({
  entity_type_id: raw.id,
  entity_category_type_id_list: raw.entityCategoryIdList?.map(
    (entityCategoryType) => entityCategoryType.value
  ),
  long_name: raw.longName,
  name: raw.name,
})

export const mapEntityTypeForResponse = (raw: any): EntityType => ({
  id: raw.data.id,
  longName: raw.data.long_name,
  name: raw.data.name,
  entityCategoryIdList: raw.data.entity_category_types.map(
    (entityCategoryType: any) => ({
      label: entityCategoryType.name,
      value: entityCategoryType.id,
    })
  ),
})

export const mapEntityTypeForList = (raw: any): EntityTypeResponse => {
  return {
    list: raw.data_list.map((item: unknown) =>
      mapEntityTypeForResponse({ data: item })
    ),
    dataCount: raw.data_count,
    pageCount: raw.page_count,
    message: raw.message,
    success: raw.success,
  }
}
