import styled from 'styled-components'

export const BarChart = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  .sBtn-text {
    cursor: pointer;
    font-size: 0.82rem;
    @media only screen and (max-width: 600px) {
      font-size: 0.8rem;
    }
    span {
      opacity: 0.5;
    }
  }
  .select-menu {
    width: 600px;
    label {
      color: rgb(107, 119, 140);
      display: block;
      font-size: 0.85em;
      font-style: inherit;
      font-weight: 600;
      line-height: 1.33333;
      margin-bottom: 6px;
    }
    .select-desc {
      font-size: 0.8571428571428571em;
      font-style: inherit;
      line-height: 1.3333333333333333;
      color: var(--text-lowEmphasis, #6b778c);
      font-weight: 600;
      margin-top: 16px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-top: 4px;
      -webkit-box-pack: baseline;
      -webkit-justify-content: baseline;
      -ms-flex-pack: baseline;
      justify-content: baseline;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      font-weight: normal;
      color: var(--text-lowEmphasis, #6b778c);
    }

    .select-btn {
      outline: none;
      border: 0;
      width: 100%;
      cursor: pointer;
      display: flex;
      height: 30px;
      background-color: #f4f5f7;
      padding: 20px;
      font-size: 18px;
      font-weight: 400;
      border-radius: 8px;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      .sort-container {
        display: flex;
        align-items: center;
        i {
          transition: 0.3s;
          cursor: pointer;
          font-size: 18px;
        }
      }
    }
  }

  .select-menu.active .select-btn i {
    transform: rotate(-180deg);
  }
  .select-menu .options {
    position: relative;
    padding: 20px;
    margin-top: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow: auto;
  }
  .select-menu.active .options {
    display: block;
  }
  .options {
    button {
      display: flex;
      height: 55px;
      cursor: pointer;
      padding: 0 16px;
      border-radius: 8px;
      align-items: center;
      background: #fff;
      gap: 0.3rem;
      border: none;
      width: 100%;
    }

    .img-container {
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 18px;
      height: 24px;
    }
  }
  .options .option:hover {
    background: #f2f2f2;
  }
  .option i {
    font-size: 25px;
    width: 25px;
  }
  .option {
    button {
      background: none;
      font-size: 14px;
      color: #333;
    }
  }
`
