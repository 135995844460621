import { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'

import { QueryRunResponse } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import {
  PieChartVisualizationNumberFormat,
  PieChartVisualizationOptions,
} from '../types'
import { numberFormat } from './helpers'

export const mapPieChartProps = (
  values: QueryRunResponse,
  visualization: WidgetVisualization<PieChartVisualizationOptions>
): Props => {
  let result: Props = {}
  const label = visualization.options.label
  const value = visualization.options.value

  if (values.rows.length) {
    result = {
      series: values.rows
        .filter((row) => (row[value?.field] as number) > 0)
        .map((row) => row[value?.field]),
      options: {
        chart: {
          animations: {
            enabled: false,
          },
        },
        colors: Object.entries(colors)
          .filter(
            (entries) => entries[0].includes('300') && !entries[0].includes('Y')
          )
          .map((entries) => entries[1])
          .slice(0, values.rows.length),
        theme: {
          mode: visualization.theme,
        },
        labels: values.rows
          .filter(
            (row) => row[label?.field] && (row[value?.field] as number) > 0
          )
          .map((row) => row[label?.field] as string),
        legend: {
          position: 'top',
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (data: number) => {
              return numberFormat(
                data,
                value?.format || ({} as PieChartVisualizationNumberFormat)
              )
            },
          },
        },
      },
    }
  }

  return result
}
