import { request } from 'services/http/dataset/instance'

import type {
  ClassificationDataSet,
  ClassificationDataSetResponse,
  CreateClassificationDataSetRequest,
  UpdateClassificationDataSetRequest,
} from './types'

import {
  mapClassificationDataSetForList,
  mapClassificationDataSetForRequest,
  mapClassificationDataSetForResponse,
} from './mappers'

export const create = (data: CreateClassificationDataSetRequest) =>
  request<ClassificationDataSet>({
    method: 'post',
    url: 'classification-data-set',
    data,
    mappers: {
      req: mapClassificationDataSetForRequest,
    },
  })

export const get = () =>
  request<ClassificationDataSetResponse>({
    method: 'get',
    url: 'classification-data-set',
    mappers: {
      res: mapClassificationDataSetForList,
    },
  })

export const getById = (id: number) =>
  request<ClassificationDataSet>({
    method: 'get',
    url: `classification-data-set/${id}`,
    mappers: {
      res: mapClassificationDataSetForResponse,
    },
  })

export const update = (data: UpdateClassificationDataSetRequest, id?: number) =>
  request<ClassificationDataSet>({
    method: 'put',
    url: `classification-data-set/${id || data.id}`,
    data,
    mappers: {
      req: mapClassificationDataSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `classification-data-set/${id}`,
  })
