import { request } from 'services/http/dataset/instance'

import type {
  CreateEntityCategoryTypeRequest,
  EntityCategoryType,
  EntityCategoryTypeResponse,
  UpdateEntityCategoryTypeRequest,
} from './types'

import {
  mapEntityCategoryTypeForList,
  mapEntityCategoryTypeForRequest,
  mapEntityCategoryTypeForResponse,
} from './mappers'

export const create = (data: CreateEntityCategoryTypeRequest) =>
  request<EntityCategoryType>({
    method: 'post',
    url: 'entity-category-type',
    data,
    mappers: {
      req: mapEntityCategoryTypeForRequest,
    },
  })

export const get = (value?: any) =>
  request<EntityCategoryTypeResponse>({
    method: 'get',
    url: 'entity-category-type',
    params: { name: value },
    mappers: {
      res: mapEntityCategoryTypeForList,
    },
  })

export const getById = (id: number) =>
  request<EntityCategoryType>({
    method: 'get',
    url: `entity-category-type/${id}`,
    mappers: {
      res: mapEntityCategoryTypeForResponse,
    },
  })

export const update = (data: UpdateEntityCategoryTypeRequest) =>
  request<EntityCategoryType>({
    method: 'put',
    url: `entity-category-type/${data.id}`,
    data,
    mappers: {
      req: mapEntityCategoryTypeForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `entity-category-type/${id}`,
  })
