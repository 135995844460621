import styled from 'styled-components'

export const User = styled.div<{ isLoaded: boolean; isRecent: boolean }>`
  width: 100%;
  position: relative;
  transform-origin: center;
  cursor: pointer;
  transform: scale(${(props) => (props.isLoaded ? '1' : '0.95')});
  filter: blur(${(props) => (props.isLoaded ? '0px' : '2.5px')});
  opacity: ${(props) => (props.isLoaded ? 1 : 0)};
  top: ${(props) => (props.isLoaded ? '0px' : '-60px')};

  &.is-loaded {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
    transition: all, 0.75s;
    -moz-transition: all, 0.75s;
    -webkit-transition: all, 0.75s;
    top: 0px;
  }

  & > div {
    pointer-events: none;
    background-color: ${(props) =>
      props.isRecent ? '#f8fffc' : 'transparent'};

    button {
      width: 60px;
      height: 60px;
    }
  }

  button {
    margin: 0;
  }
`

export const UserLozenge = styled.span`
  position: absolute;
  right: 12px;
  top: 8px;
  z-index: 1;
`

export const UserList = styled.div`
  max-height: calc(50vh);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 12px;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 20px;
  }
`
