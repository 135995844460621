import styled from 'styled-components'

export const UpdateDashboard = styled.div`
  button.success-outline {
    background: rgba(189, 245, 211, 0.4);

    * {
      color: #178259;
    }

    &:hover {
      background: rgba(189, 245, 211, 0.6);
    }
  }

  button.primary-outline {
    background: rgba(179, 212, 255, 0.4);

    * {
      color: #0052cc;
    }

    &:hover {
      background: rgba(179, 212, 255, 0.6);
    }
  }
`
export const DashboardTimePicker = styled.div`
  min-width: 350px;
`
