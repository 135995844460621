import { QueryRunResponse } from 'services/http/bi-tool/query'

import { NewsValueProps, NewsVisualizationOptions } from '../types'

export const mapQueryForNews = (
  raw: QueryRunResponse,
  options: NewsVisualizationOptions
): NewsValueProps[] =>
  (raw.rows || []).map((row) => ({
    createdAt: row[options?.fields?.createdAt as string],
    image: row[options?.fields?.image as string],
    impressionCount: row[options?.fields?.impressionCount as string],
    interactionCount: row[options?.fields?.interactionCount as string],
    rank: row[options?.fields?.rank as string],
    source: row[options?.fields?.source as string],
    text: row[options?.fields?.text as string],
    title: row[options?.fields?.title as string],
    url: row[options?.fields?.url as string],
  }))
