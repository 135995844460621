import { request } from 'services/http/dashboard-ui/instance'

import { mapCountryDetailResponse, mapProfileDetailResponse } from './mappers'
import {
  CreateSchedulerReport,
  DashboardAccountProps,
  DashboardCountryProps,
} from './types'

export const getProfileDetail = (userId: string) =>
  request<DashboardAccountProps>({
    method: 'get',
    url: 'dashboard/account-profile-detail',
    params: {
      entityKey: userId,
    },
    mappers: {
      res: mapProfileDetailResponse,
    },
  })

export const getCountryDetail = (userId: string) =>
  request<DashboardCountryProps>({
    method: 'get',
    url: 'dashboard/account-country-detail',
    params: {
      entityKey: userId,
    },
    mappers: {
      res: mapCountryDetailResponse,
    },
  })

export const createSchedulerReport = (data?: CreateSchedulerReport) =>
  request<void>({
    method: 'post',
    url: 'back-office​/dashboard​/create-scheduler-report',
    data,
  })

export const deleteDashboards = (ids: Array<string>) => {
  const queryParams = ids.map((id) => `ids=${id}`).join('&')
  const url = `back-office/dashboard?${queryParams}`

  return request<void>({
    method: 'delete',
    url,
  })
}
