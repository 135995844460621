import React, { useState } from 'react'
import Button from '@zera-admin/button'
import { storage } from '@zera-admin/helpers'
import Form, { FormFooter, FormHeader, FormSection } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Page, { Row, Column } from '@zera-admin/page'
import {
  forgotPassword,
  changeForgotPassword,
} from 'services/http/identity-server/user/endpoints'
import { usePopupContext } from 'app/contexts/popup'
import MembershipLayout from 'layouts/membership'
import { twoFactor } from 'services/http/identity-server/auth/endpoints'
import { LoginType } from 'services/http/identity-server/enums'
import { set } from 'app/contexts/dashboard/actions'
interface FormProps {
  email: string
  code: string
  newPassword: string
  againNewPassword: string
}

const ForgotPassword: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [visible, setVisible] = useState(false)
  const [username, setUsername] = useState('')
  const handleSubmit = async (data: FormProps) => {
    console.log(data)
    const userName = data.email
    const code = data.code
    const newPassword = data.newPassword
    const againNewPassword = data.againNewPassword
    if (step === 1) {
      try {
        const response = await forgotPassword(userName)
        if (response.code === 200) {
          setUsername(userName)
          setStep(2)
        }
      } catch (err) {
        alert('Kullanıcı adı ya da e-posta adresi hatalı')
      }
    } else if (step === 2) {
      try {
        const response = await twoFactor({
          email: username,
          code,
        })
        if (response.code === 200) {
          storage.set('username', username)
          storage.set('token', response?.data?.accessToken)
          storage.set('refreshToken', response.data.refreshToken)
          setStep(3)
        }
      } catch (err) {
        alert('Kullanıcı adı ya da e-posta adresi hatalı')
      }
    } else if (step === 3) {
      const response = await changeForgotPassword({
        userName: username,
        newPassword,
        againNewPassword,
      })
      if (response.code === 200) {
        alert('Şifreniz başarıyla değiştirildi')
        window.location.href = '/'
      }
    }
  }

  return (
    <MembershipLayout>
      <Page>
        <Form onSubmit={handleSubmit}>
          <FormHeader
            title="Alice - Yapay Zeka Platformu"
            description="Şifrenizi unuttuysanız, lütfen e-posta adresinizi veya kullanıcı adınızı giriniz"
          />
          {step === 1 ? (
            <FormSection>
              <Row>
                <Column xs={{ size: 12 }}>
                  <Input
                    name="email"
                    placeholder="Kullanıcı adınızı veya e-posta adresinizi giriniz"
                  />
                </Column>
              </Row>
            </FormSection>
          ) : step === 2 ? (
            <>
              <FormSection>
                <Row>
                  <Column xs={{ size: 12 }}>
                    <Input
                      value={username}
                      isDisabled
                      name="email"
                      placeholder="Kullanıcı adınızı veya e-posta adresinizi giriniz"
                    />
                  </Column>
                  <Column xs={{ size: 12 }}>
                    <Input
                      name="code"
                      placeholder="Mail adresinize gelen kodu giriniz"
                    />
                  </Column>
                </Row>
              </FormSection>
            </>
          ) : step === 3 ? (
            <FormSection>
              <Row>
                <Column xs={{ size: 12 }}>
                  <Input
                    type={visible ? 'text' : 'password'}
                    name="newPassword"
                    placeholder="Yeni şifrenizi giriniz"
                  />
                </Column>
                <Column xs={{ size: 12 }}>
                  <Input
                    type={visible ? 'text' : 'password'}
                    name="againNewPassword"
                    placeholder="Yeni şifrenizi tekrar giriniz"
                  />
                </Column>
              </Row>
              <Button
                isFullWidth
                appearance="link"
                onClick={() => setVisible(!visible)}
              >
                {visible ? 'Şifreyi Gizle' : 'Şifreyi Göster'}
              </Button>
            </FormSection>
          ) : null}
          <FormFooter>
            <Button
              appearance="primary"
              isFullWidth
              style={{ height: 38, alignItems: 'center' }}
              type="submit"
            >
              Gönder
            </Button>
          </FormFooter>
        </Form>
      </Page>
    </MembershipLayout>
  )
}

export default ForgotPassword
