import React from 'react'
import InfoCard from '../report-card'

import { InfoCardProps } from './types'
import * as Styled from './reportCardTwitter.styled'

import TwitterIconGreen from './twitter-new-logo-green.png'
import TwitterIconRed from './twitter-new-logo-red.png'

const ReportCardTwitter: React.FunctionComponent<InfoCardProps> = ({
  data,
  title,
}) => {
  const Icon =
    title === 'Olumlu İçerikler ve Etkileşim'
      ? TwitterIconGreen
      : TwitterIconRed

  return (
    <InfoCard icon={Icon} title={title}>
      {data.map((twitterPerson: any) => (
        <Styled.TwitterCard className="twitter">
          <div className="twitter-header">
            <div className="twitter-image">
              <img src={twitterPerson.userProfileImageUrl} alt="profil" />
            </div>
            <div className="twitter-profil">
              <span>{twitterPerson.userName}</span>
              <span>{`@${twitterPerson.userScreenName}`}</span>
            </div>
          </div>
          <div className="twitter-content">{twitterPerson.text}</div>
          <div className="twitter-footer">
            <span>{`${twitterPerson.retweetCount} Repost`}</span>
            <span>{`${twitterPerson.replyCount} Alıntı Post`}</span>
            <span>{`${twitterPerson.favoriteCount} Beğeni`}</span>
          </div>
        </Styled.TwitterCard>
      ))}
    </InfoCard>
  )
}
export default ReportCardTwitter
