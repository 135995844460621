import styled from 'styled-components'
import { colors } from '@atlaskit/theme'

import LoginBackground from 'assets/images/login-background.jpg'

export const MembershipFlex = styled.div`
  width: 100%;
  height: 1px;
  min-height: 100%;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    width: 102%;
    height: 102%;
    background: url(${LoginBackground});
    position: fixed;
    left: -1%;
    top: -1%;
    z-index: -1;
    -webkit-filter: blur(8px);
    filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const MembershipHeader = styled.div`
  width: 100%;
  padding: 2.5rem 0px;
  text-align: center;
`

export const MembershipContent = styled.div`
  width: 100%;
  flex: 1;

  .verification-code {
    input {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      font-weight: 500;

      &:focus {
        border: 1px solid ${colors.P100};
        caret-color: ${colors.P100};
      }
    }
  }
`

export const MembershipWindow = styled.section`
  width: 425px;
  max-width: 100%;
  box-sizing: border-box;
  color: ${colors.N50};
  margin: 0px auto;
  border-radius: 3px;
  padding: 2rem 2.5rem;
  background: rgb(24 42 86 / 85%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;

  form {
    label,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      white-space: normal;
      color: #fff;
    }
  }
`

export const Links = styled.div`
  color: ${colors.N200};
  padding-top: 18px;
  margin-top: 28px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  border-top: 1px solid ${colors.N100};

  ul {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    list-style: none;
    padding: 0px;

    li {
      display: inline-block;
      margin: 0;

      &:not(:first-child):before {
        content: '•';
        margin: 0px 8px;
      }

      a {
        color: #fff;
      }
    }
  }
`

export const Board = styled.div`
  font-size: 12px;
  max-width: 100%;
  width: 400px;
  text-align: center;
  margin: 0px auto;
  padding: 24px 0px;
  color: ${colors.N200};

  ul {
    padding: 0;

    li {
      display: inline-block;
      margin: 0;

      &:not(:first-child):before {
        content: '•';
        margin: 0px 8px;
      }

      a {
        color: #fff;
      }
    }
  }
`

export const MembershipFooter = styled.div`
  width: 100%;
`

export const MembershipFooterScope = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  max-width: 100%;
  width: 400px;
  font-size: 12px;
  color: rgb(107, 119, 140);
  padding: 24px 0px;
  margin: 0px auto;
  border-top: 1px solid ${colors.N30};
`
