import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'

import { validation } from 'app/functions'
import { sentiment } from 'app/shared/constants'
import useSources from 'app/hooks/sources'
import { SentimentDataSet } from 'services/http/dataset/sentiment-data-set'

import { SentimentDataSetFormProps } from './types'
import useTrainingGroup from 'app/hooks/training-group'

const validations = {
  languageCode: yup.string().required('Dil kodu girmelisiniz'),
  semanticScore: yup.string().required('Duygu Skoru seçmelisiniz'),
  sourceId: yup.string().required('Kaynak seçmelisiniz'),
  text: yup.string().required('Duygu veri seti metni girmelisiniz'),
}

const SentimentDataSetForm: React.FunctionComponent<SentimentDataSetFormProps> =
  ({ actions = [], onError, onSubmit, values }) => {
    const form = useForm<SentimentDataSet>({
      initialValues: values,
      onSubmit: (props) => handleSubmit(props),
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })

    const { sources, getSources, getSource, isLoading } = useSources()

    const {
      trainingGroups,
      getTraningGroup,
      isLoading: isLoadingTraining,
    } = useTrainingGroup()

    const handleSubmit = (props: FormHookSubmitProps<SentimentDataSet>) => {
      if (!props.errors) {
        if (onSubmit) {
          onSubmit(props.values, () => {
            if (props.handleReset) {
              props.handleReset()
            }
          })
        }
      } else {
        if (onError) {
          onError(props.errors)
        }
      }
    }

    return (
      <Form onSubmit={form.handleSubmit}>
        <FormSection
          title="Genel bilgiler"
          description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
        >
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                description="Geçerli bir duygu veri seti metni giriniz"
                errorMessage={form.errors.text}
                label="Duygu veri seti metni"
                name="text"
                placeholder="Örnek metin"
                value={form.values.text}
                onChange={form.handleChange}
              />
            </Column>
            <Column>
              <Input
                description="Geçerli bir dil kodu giriniz"
                errorMessage={form.errors.languageCode}
                label="Dil kodu"
                name="languageCode"
                placeholder="Örnek: tr, en, vs.."
                value={form.values.languageCode}
                onChange={form.handleChange}
              />
            </Column>
            <Column>
              <Select
                description="Geçerli bir Duygusallık skoru seçimi yapınız"
                errorMessage={form.errors.semantic_score}
                label="Duygusallık skoru"
                name="semanticScore"
                options={sentiment}
                value={sentiment.find(
                  (sentiment) => sentiment.value === form.values.semanticScore
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'semanticScore',
                    (props as SelectOptionProps).value
                  )
                }
                placeholder="Seçim yapın"
              />
            </Column>
            <Column>
              <SearchBox
                description="Geçerli bir kaynak seçimi yapınız"
                errorMessage={form.errors.sourceId}
                label="Kaynak"
                name="sourceId"
                options={sources}
                value={getSource(form.values.sourceId)}
                onFetchOptions={(value) => getSources(value)}
                onChange={(props) =>
                  form.handleFieldChange(
                    'sourceId',
                    (props as SearchboxOptionProps).value
                  )
                }
                placeholder="Seçim yapın"
                isLoading={isLoading}
              />
            </Column>
            <Column>
              <Select
                description="Geçerli bir eğitim grubu seçin"
                label="Eğitim grubu seçiniz"
                name="trainingGroupId"
                value={getTraningGroup(
                  form.values.trainingGroupId,
                  form.values.trainingGroupName
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'trainingGroupId',
                    (props as SelectOptionProps).value
                  )
                }
                options={trainingGroups}
                isLoading={isLoadingTraining}
              />
            </Column>
          </Row>
        </FormSection>
        <FormFooter>{actions}</FormFooter>
      </Form>
    )
  }

export default SentimentDataSetForm
