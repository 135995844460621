import {
  ClassificationControlSet,
  ClassificationControlSetResponse,
} from './types'

export const mapClassificationControlSetForRequest = (
  raw: ClassificationControlSet
): unknown => ({
  classification_control_set_id: raw.id,
  conclusion: raw.conclusion,
  language_code: raw.languageCode,
  text: raw.text,
})

export const mapClassificationControlSetForResponse = (
  raw: any
): ClassificationControlSet => ({
  id: raw.data.id,
  conclusion: raw.data.conclusion,
  currentVersion: raw.data.current_version,
  languageCode: raw.data.language_code,
  text: raw.data.text,
  userId: raw.data.user_id,
})

export const mapClassificationControlSetForList = (
  raw: any
): ClassificationControlSetResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapClassificationControlSetForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
