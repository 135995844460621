import { useCallback, useEffect, useRef } from 'react'

import * as themes from '../utils/themes'

import * as Styled from './GoogleMaps.styled'
import { GoogleMapsProps } from './types'

let map: google.maps.Map

const GoogleMaps: React.FunctionComponent<GoogleMapsProps> = ({
  height = 350,
  onLoad,
  options,
  theme = 'dark',
}) => {
  const visibilityRef = useRef(false)

  const initMap = useCallback(() => {
    const mapOptions: google.maps.MapOptions = {
      center: {
        lat: 35,
        lng: 45,
      },
      disableDefaultUI: true,
      fullscreenControl: false,
      styles: themes[theme],
      zoom: 2.5,
      zoomControl: false,
      ...options,
    }

    const mapDiv = document.getElementById('map')

    if (mapDiv) {
      map = new window.google.maps.Map(mapDiv, mapOptions)
    }

    if (onLoad) {
      onLoad(map)
    }
  }, [onLoad, options, theme])

  useEffect(() => {
    if (window.google && window.google.maps) {
      initMap()
    } else {
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCFMVfDomSWYXwciCLTs1Hxf0YgtPo4XK4'
      script.onload = () => {
        initMap()
      }

      document.body.appendChild(script)
    }
  }, [initMap])

  useEffect(() => {
    const visibilityChange = () => {
      if (visibilityRef.current) {
        visibilityRef.current = false
      } else {
        visibilityRef.current = true
      }
    }

    document.addEventListener('visibilitychange', visibilityChange)

    return function cleanup() {
      document.removeEventListener('visibilitychange', visibilityChange)
    }
  }, [])

  return <Styled.Map id="map" height={height} />
}

export default GoogleMaps
