import React, { useState } from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import { Column, Row } from '@zera-admin/page'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'

import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'

import * as Styled from './SocialNumbersDefinitions.styled'
import { SOCIALNUMBERS_FIELDS } from './constants'
import { SocialNumbersDefinitionsProps } from './types'

const SocialNumbers: React.FunctionComponent<SocialNumbersDefinitionsProps> = ({
  fields,
  onChange,
  options,
}) => {
  const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
    getFieldOptions(fields)
  )

  const handleChangeFieldInput = (value: string) => {
    const option = {
      label: value,
      value,
    }
    const index = fieldOptions.findIndex(
      (reference) => reference.value === parseInt(option.value)
    )
    let optionsInstance = [...fieldOptions]

    if (value && index === -1) {
      optionsInstance[0] = option
    } else {
      optionsInstance = getFieldOptions(fields)
    }

    setFieldOptions(optionsInstance)
  }

  const renderTabs = () => {
    return (
      <Tabs id="tabs">
        <TabTriggerGroup>
          <TabTrigger>Veri </TabTrigger>
        </TabTriggerGroup>
        <TabPanel>{renderDataTab()}</TabPanel>
      </Tabs>
    )
  }

  const renderDataTab = () => {
    const values = options?.fields || {}

    return SOCIALNUMBERS_FIELDS.map((socialNumbers, index) => (
      <Row key={index} xs={{ direction: 'column' }}>
        <Column>
          <Select
            name={socialNumbers.value}
            options={fieldOptions}
            onChange={(props) => {
              onChange({
                ...options,
                fields: {
                  ...options.fields,
                  [socialNumbers.value]: (props as SelectOptionProps)
                    .value as string,
                },
              })
            }}
            onInputChange={handleChangeFieldInput}
            value={
              values[socialNumbers.value]
                ? {
                    label: string.textify(values[socialNumbers.value]),
                    value: values[socialNumbers.value],
                  }
                : { label: '', value: '' }
            }
            label={socialNumbers.label}
            placeholder="Seçim yapınız"
          />
        </Column>
      </Row>
    ))
  }

  return <Styled.NewsDefinitions>{renderTabs()}</Styled.NewsDefinitions>
}

export default SocialNumbers
