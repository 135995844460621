import * as Styled from './NewsPagesByCountry.styled'

import { NewsPagesByCountryProps } from './types'
import Analysis from '../../components/analysis'
import NewCard from '../../components/new-card'

import { Row, Column } from '@zera-admin/page'
import { useEffect, useState } from 'react'
import UpdateButton from '../../components/update-button/UpdateButton'
import { News } from '../../widgets/types'
import WidgetLayout from '../../widgets/widget-layout'
import { getAgendaReportNews } from 'services/http/report/agenda-report-4/endpoint'
import { useFlagcardContext } from 'app/contexts/flagcard'

const NewsPagesByCountry: React.FC<NewsPagesByCountryProps> = ({
  date,
  isEditValue,
  setIsEditValue,
  pageNumber,
  data,
  title,
  metaData,
}) => {
  const [newsData, setNewsData] = useState<News | undefined>(data)
  const [newsId, setNewsId] = useState<string | undefined>('')
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  const [flagCard] = useFlagcardContext()

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setNewsData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const handleDeleteNews = () => {
    setNewsData(undefined)
  }

  const addNews = () => {
    getAgendaReportNews({
      GroupId: metaData?.GroupId,
      Url: newsId,
    })
      .then((res) => {
        setNewsData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
        flagCard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Veritabanında haber bulunamadı. Lütfen haber URL bilgisini kontrol ediniz.',
        })
      })
  }

  const onChange = (key: string, value: string) => {
    newsData && setNewsData({ ...newsData, [key]: value })
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      <Styled.NewsWrapper>
        <UpdateButton
          {...editProps}
          contentData={{
            News: newsData,
          }}
          title={pageData.title}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <Styled.NewsContainer>
          <Row xs={{ justify: 'center' }}>
            {newsData ? (
              <Column
                className="news"
                xs={{ size: newsData?.Analysis ? 7 : 8 }}
              >
                <NewCard
                  data={newsData}
                  editMode={editMode}
                  onChange={onChange}
                />
                {editMode && (
                  <button className="delete-news" onClick={handleDeleteNews}>
                    X
                  </button>
                )}
              </Column>
            ) : (
              <div className="add-news">
                <input
                  type="text"
                  placeholder="Add news"
                  value={newsId}
                  onChange={(e) => setNewsId(e.target.value)}
                />
                <button onClick={addNews}>Add</button>
              </div>
            )}

            {newsData?.Analysis && (
              <Column xs={{ size: 5 }}>
                <Analysis
                  editMode={editMode}
                  onChange={onChange}
                  data={newsData}
                />
              </Column>
            )}
          </Row>
        </Styled.NewsContainer>
      </Styled.NewsWrapper>
    </WidgetLayout>
  )
}

export default NewsPagesByCountry
