import { request } from 'services/http/backoffice/instance'
import { fieldLanguages } from './mappers'
import { FieldLanguagesResponse } from './types'

export const getFieldLanguages = () =>
  request<FieldLanguagesResponse>({
    method: 'get',
    url: 'field/languages',
    mappers: {
      res: fieldLanguages,
    },
  })
