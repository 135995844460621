import Icon from '@zera-admin/icon'
import { Column } from '@zera-admin/page'
import { Email, Facebook } from '../report-detail'
import PdfPageLayout from './PdfPageLayout'
import { PdfPageProps } from './types'
import { getMonthAndYear } from 'app/functions'

const PageNine: React.FunctionComponent<PdfPageProps> = ({ personReport }) => {
  return (
    <PdfPageLayout personReport={personReport}>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Email
            </h2>
            <Email data={personReport.data?.result?.Statistic?.email} />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
      <div style={{ width: '40vw', border: '1px solid grey' }}>
        <div style={{ width: '100%', textAlign: 'right', fontSize: '18px' }}>
          <p>{new Date(personReport.data.crawlingEndedAt).toLocaleString()} </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Column xs={{ size: 12 }}>
            <h2
              style={{
                display: 'flex',
                margin: 2,
                justifyContent: 'center',
                fontWeight: 500,
                color: '#4c5153',
                fontSize: 14,
              }}
            >
              Facebook
            </h2>
            <Facebook data={personReport.data?.result?.Statistic?.facebook} />
          </Column>
        </div>
        <Column>
          <div style={{ width: '100%', textAlign: 'center', fontSize: '12px' }}>
            <Icon name="calendar" size="small" color="blue" />
            &nbsp;{getMonthAndYear(personReport.data.firstTweet)} - &nbsp;
            {getMonthAndYear(personReport.data.lastTweet)} arasındaki tweet
            akışı gösterilmektedir
          </div>
        </Column>
      </div>
    </PdfPageLayout>
  )
}
export default PageNine
