import styled from 'styled-components'
import { InfoCardStyledProps } from './types'

export const InfoCard = styled.div`
  box-sizing: border-box;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: fit-content;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px #ccc;
  margin-bottom: 24px;
  border: 1.5px #dddddd solid;
  .general-info {
    padding: 5px 10px;
    .general-info-title {
      font-weight: bold;
      font-size: 14;
    }
    .general-info-content {
      padding: 0px 0px;
      font-size: 14;
    }
  }
  .twitter:nth-last-child(1) {
    border: none;
  }
`
export const PdfContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  background-color: #fafafa;
  .pdf-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    img:nth-child(1) {
      margin-right: 20px;
      width: 140px;
    }
    img:nth-child(2) {
      border-left: 2px #000000 solid;
      width: 230px;
      padding: 20px;
    }
  }
`
export const InfoCardHeader = styled.div<InfoCardStyledProps>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 16px;
  height: 80px;
  .info-card-title {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
    font-weight: bold;
    margin-left: 50px;
    color: #000000;
  }
  .info-card-title--passive {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    color: #000000;
  }
  .info-card-icon {
    padding: 20px;
    box-shadow: 0px 0px 5px 1px #ccc;
    background-color: #fafafa;
    border-radius: 24px;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -24px;
    top: 2px;
    border: 1.5px #dddddd solid;

    img {
      border-radius: 8px;
    }
  }
`

export const InfoCardContent = styled.div<InfoCardStyledProps>`
  margin-top: ${(props) =>
    props.contentMarginTop ? props.contentMarginTop : '0px'};
  padding: 10px;
  .info-card-table {
    color: #000000;
    thead {
      font-weight: 650;
      border-bottom: none;
      tr {
        border-bottom: 2px #ffffff solid;
        th {
          background-color: #eeeeee;
          vertical-align: middle;
          color: #bbbbbb;
        }
        th:nth-child(1) {
          padding: 0px 30px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border-right: 1px #eeeeee solid;
        }
        th:nth-child(2) {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          border-left: 1px #eeeeee solid;
          text-align: center;
        }
      }
    }
    tbody {
      font-weight: 600;
      font-size: 16px;
      color: #202020;
      border: none;
    }
    tr {
      height: 50px;
      border-bottom: 1px #eeeeee solid;
      td {
        padding: 14px 15px;
        span {
          display: block;
          font-size: 13px;
          font-weight: 300;
          color: #585858;
        }
      }

      td:nth-child(1) {
        padding: 14px 30px;
      }
      td.title-bold {
        padding: 8px 4px;
        font-weight: bold;
      }
      td:nth-child(2) {
        text-align: center;
      }
      td.edit {
        padding: 14px 10px;
      }
    }
    tr:nth-last-child(1) {
      border: none;
    }
    td,
    th {
      padding: 10px;
    }
  }
  .info-card-table--three {
    color: #000000;
    thead {
      font-weight: 650;
      border-bottom: none;
      tr {
        border-bottom: 2px #ffffff solid;
        th {
          background-color: #eeeeee;
          vertical-align: middle;
          color: #bbbbbb;
        }
        th:nth-child(1) {
          padding: 0px 30px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border-right: 1px #eeeeee solid;
        }
        th:nth-child(2) {
          min-width: 100px;
        }
        th:nth-child(3) {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          border-left: 1px #eeeeee solid;
          text-align: center;
          padding: 0px 10px 0px 0px;
        }
      }
    }
    tbody {
      font-weight: 600;
      font-size: 16px;
      color: #202020;
      border: none;
    }
    tr {
      height: 50px;
      border-bottom: 1px #eeeeee solid;
      td {
        padding: 14px 15px;
        span {
          display: block;
          font-size: 12px;
          color: #aaaaaa;
        }
      }
      td:nth-child(1) {
        padding: 14px 30px;
      }
      td:nth-child(2) {
        padding: 10px 10px;
      }
      td:nth-child(3) {
        padding: 10px 50px;
      }
    }
    tr:nth-last-child(1) {
      border: none;
    }
    td,
    th {
      padding: 10px;
    }
  }
`
