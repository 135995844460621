import React from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import Table from '@zera-admin/table'
import Lozenge from '@zera-admin/lozenge'
import EmptyState from '@zera-admin/empty-state'
import Checkbox from '@zera-admin/checkbox'
import { LinkButton } from '@zera-admin/button'

import { CLAIM_TYPES } from 'app/shared/constants'

import NotFoundImage from 'assets/images/not-found.png'

import { RoleFormClaimsProps } from '../types'
import * as Styled from './RoleForm.styled'

const RoleFormClaims: React.FunctionComponent<RoleFormClaimsProps> = ({
  claimIds = [],
  claims,
  onChange,
}) => {
  const getClaimIndex = (value: number) => {
    return claimIds.findIndex((id) => id === value)
  }

  const renderClaimsContent = (name: 'services' | 'others' | 'pages') => {
    if (!claims[name] || !claims[name].length) {
      return (
        <EmptyState
          title="İzin bulunamadı"
          description="Seçtiğiniz türde kayıtlı izin bulunamadı. Aşağıdaki buton yardımıyla izin eklemesi yapabilirsiniz."
          image={{ src: NotFoundImage, maxWidth: 100 }}
          actions={
            <LinkButton appearance="button" href="/claim/create">
              Yeni izin oluştur
            </LinkButton>
          }
        />
      )
    } else {
      const selecteds = claims[name].filter((claim) =>
        claimIds.includes(claim.id)
      )
      const isAllSelected = selecteds.length === claims[name].length

      return (
        <React.Fragment>
          <Table
            height="400px"
            columns={
              [
                {
                  name: 'claim',
                  text: 'İzin',
                  render: (row: any) => (
                    <Checkbox
                      name="claim"
                      isChecked={getClaimIndex(row.id) !== -1 ? true : false}
                      onChange={() => {
                        const ids = [...claimIds]
                        const index = getClaimIndex(row.id)

                        if (index !== -1) {
                          ids.splice(index, 1)
                        } else {
                          ids.push(row.id)
                        }

                        onChange(ids)
                      }}
                    />
                  ),
                },
                {
                  name: 'title',
                  text: 'Başlık',
                },
                {
                  name: 'value',
                  text: 'Değer',
                },
                {
                  name: 'type',
                  text: 'Tip',
                  render: (row: any) => {
                    const type = CLAIM_TYPES.find(
                      (claim) => claim.value === row.type
                    )

                    return (
                      <Lozenge appearance="success">
                        {type?.label || '-'}
                      </Lozenge>
                    )
                  },
                },
                {
                  name: 'method',
                  text: 'Method',
                  render: (row: any) => (
                    <Lozenge appearance={row.method ? 'info' : 'default'}>
                      {row.method ? row.method.toUpperCase() : 'Seçilmedi'}
                    </Lozenge>
                  ),
                },
                {
                  name: 'description',
                  text: 'Açıklama',
                  render: (row: any) => `${row.description || '-'}`,
                },
              ] as any
            }
            rows={claims[name]}
            helperMessage="Aradığınız kriterlere ait sonuç bulunamadı, lütfen tekrar deneyin."
          />
          <Styled.RoleFormTableButton
            onClick={() => {
              let ids = [...claimIds]

              if (isAllSelected) {
                ids = ids.filter(
                  (id) => !selecteds.find((selected) => selected.id === id)
                )
              } else {
                ids = [...ids, ...claims[name].map((claim) => claim.id)]
              }

              onChange(ids)
            }}
          >
            {isAllSelected ? 'Tümünü kaldır' : 'Tümünü seç'}
          </Styled.RoleFormTableButton>
        </React.Fragment>
      )
    }
  }

  return (
    <Styled.RoleFormClaims>
      <Tabs id="tabs">
        <TabTriggerGroup>
          <TabTrigger>Servis izinleri</TabTrigger>
          <TabTrigger>Sayfa izinleri</TabTrigger>
          <TabTrigger>Web bileşen izinleri</TabTrigger>
        </TabTriggerGroup>
        <TabPanel>{renderClaimsContent('services')}</TabPanel>
        <TabPanel>{renderClaimsContent('pages')}</TabPanel>
        <TabPanel>{renderClaimsContent('others')}</TabPanel>
      </Tabs>
    </Styled.RoleFormClaims>
  )
}

export default React.memo(RoleFormClaims)
