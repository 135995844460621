import React, { useImperativeHandle, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Column, Container, Row } from '@zera-admin/page'

import queryService from 'services/http/bi-tool/query'
import {
  Widget,
  WidgetHandlerRef,
  WidgetVisualization,
} from 'services/http/bi-tool/widget'
import NameValues from 'widgets/names-values/NameValues'

import { getCriteriaInterval } from '../visualization/getters'
import { mapQueryForNameValue } from './utils/mappers'
import * as Styled from './NameValue.styled'
import {
  NameValueProps,
  NameValueValueProps,
  NameValueVisualizationOptions,
} from './types'

const NameValue: React.ForwardRefRenderFunction<
  WidgetHandlerRef<NameValueVisualizationOptions>,
  NameValueProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<NameValueValueProps[]>([])
  const [error, setError] = useState<string>()

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const getColumn = () => {
    const { grid } = props.widget

    if (grid) {
      if (grid === 2) return 6
      else if (grid === 3) return 4
      else if (grid === 4) return 3
      else if (grid === 6) return 2
    }

    return 12
  }

  const runQuery = (widget: Widget) => {
    setIsLoading(true)

    queryService
      .run(
        widget?.query,
        widget?.visualization as WidgetVisualization,
        widget.type,
        {
          criteriaType: props.widget.criteria,
          dateType: getCriteriaInterval(props.widget),
        }
      )
      .then((res) => {
        setValues(mapQueryForNameValue(res.data, widget.visualization?.options))
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderContent = () => {
    const { grid } = props.widget

    if (grid) {
      const column = Math.round(values.length / grid)
      const group: { [key: string]: NameValueValueProps[] } = {}

      for (var i = 0; i < values.length; i += column) {
        group[i] = values.slice(i, i + column)
      }

      return (
        <Row>
          {Object.keys(group).map((key) => {
            const value = group[key]

            return (
              <Column xs={{ size: getColumn() }}>
                <NameValues data={value} />
              </Column>
            )
          })}
        </Row>
      )
    } else {
      return (
        <Container width="narrow">
          <Styled.NameValue theme={props.widget.visualization?.theme}>
            <NameValues data={values} />
          </Styled.NameValue>
        </Container>
      )
    }
  }

  const renderChart = () => {
    if (isLoading) {
      return (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      )
    } else {
      if (values.length) {
        return renderContent()
      } else {
        return (
          <Alert
            appearance={error ? 'error' : 'warning'}
            title="Metin-değer listesi oluşturulamadı"
          >
            {error ||
              'Metin-değer listesini oluşturacak veri bulunamadı. Lütfen sorgularınızı ve metin-değer listesi ayarlarınızı kontrol ediniz.'}
          </Alert>
        )
      }
    }
  }

  return renderChart()
}

export default React.forwardRef(NameValue)
