import React, { useCallback, useEffect, useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import * as yup from 'yup'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Form, { FormFooter, FormSection } from '@zera-admin/form'
import Input from '@zera-admin/input'
import { string } from '@zera-admin/helpers'

import { usePopupContext } from 'app/contexts/popup'
import { Role } from 'services/http/identity-server/role'
import claimService, { ClaimType } from 'services/http/identity-server/claim'

import { RoleFormClaims, RoleFormProps } from '../types'
import RoleFormClaimsComponent from './RoleFormClaims'

const RoleForm: React.FunctionComponent<RoleFormProps> = ({
  values,
  onSubmit,
  operation,
  loading,
}) => {
  const [form, setForm] = useState<Role>(values)
  const [claims, setClaims] = useState<RoleFormClaims>({
    pages: [],
    services: [],
    others: [],
  })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [popup] = usePopupContext()
  const validations = {
    title: yup.string().required('Rol başlığı girmelisiniz.'),
    name: yup.string().required('Rol anahtarı girmelisiniz.'),
  }

  useEffect(() => {
    getClaims()
  }, [])

  const handleUpdateClaimIds = useCallback((claimIds: number[]) => {
    setForm((form) => ({ ...form, claimIds }))
  }, [])

  const getClaims = async () => {
    const response = await claimService.get({
      pageIndex: 0,
      pageSize: 2000,
      ascending: true,
    })
    const { data } = response.data

    if (data.length) {
      const pages = data.filter((claim) => claim.type === ClaimType.Page)
      const services = data.filter((claim) => claim.type === ClaimType.Api)
      const others = data.filter((claim) => claim.type === ClaimType.View)

      setIsLoading(false)
      setClaims({ pages, services, others })
    }
  }

  const handleSubmit = () => {
    const schema = yup.object().shape(validations)

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        onSubmit(form)
      })
      .catch((err) => {
        popup.add({
          appearance: 'error',
          title: 'Doğrulama hatası',
          children: (
            <div>
              <p>
                Aşağıdaki kriterlere dikkate alarak, formu gözden geçiriniz.
              </p>
              <ul className="errors">
                {(err.errors || []).map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
        })
      })
  }

  const renderClaims = () => {
    if (isLoading) return null

    return (
      <RoleFormClaimsComponent
        claimIds={form.claimIds}
        claims={claims}
        onChange={handleUpdateClaimIds}
      />
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Column xs={{ size: 6 }}>
          <Input
            label="Rol başlığı"
            description="Role bir başlık belirleyiniz. Kullanıcılara yapılan atamalarda görüntülenecektir."
            name="title"
            value={form.title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, title: event.target.value })
            }
          />
        </Column>
        <Column xs={{ size: 6 }}>
          <Input
            label="Rol anahtarı"
            description="Rolünüze benzersiz bir anahtar (key) veriniz."
            name="name"
            value={form.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, name: event.target.value })
            }
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, name: string.slugify(event.target.value) })
            }
          />
        </Column>
      </Row>
      <FormSection
        title="İzin ayarları"
        description="Aşağıdan geçerli rolün izinlerini yönetebilirsiniz. API erişim izinlerinde, sayfa erişim izinlerinde ve web bileşenleri erişim izinlerinde ayarlamalar yapabilirsiniz."
      >
        <Row>
          <Column xs={{ size: 12 }}>{renderClaims()}</Column>
        </Row>
      </FormSection>
      <FormFooter>
        <ButtonGroup>
          <Button appearance="primary" isLoading={loading} type="submit">
            {operation === 'create' ? 'Rol oluştur' : 'Rolü güncelle'}
          </Button>
          <LinkButton href="/roles">Vazgeç</LinkButton>
        </ButtonGroup>
      </FormFooter>
    </Form>
  )
}

export default RoleForm
