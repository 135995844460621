import React, { useEffect, useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import Form, { FormFooter, FormSection } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Button from '@zera-admin/button'
import Select, { SelectOptionProps } from '@zera-admin/select'
import DateTimePicker from '@zera-admin/datetime-picker'
import * as Styled from './AgendaReportForm.styled'
import { AgendaIndependentFormProps } from './types'

import { DateTimePickerHours } from 'app/shared/enums'
import Checkbox from '@zera-admin/checkbox'
import ConditionBuilder, {
  ConditionRuleField,
} from '@zera-admin/condition-builder'
import { ICreateAgendaReportV4Req } from 'services/http/report/agenda-report-4/types'
import { getLanguageService } from 'services/http/report/agenda-report/endpoint'

const AgendeSecondForm: React.FunctionComponent<AgendaIndependentFormProps> = ({
  data,
  onSubmit,
  operation,
  source,
  isLoading,
  countryPerception,
  criteriaFields,
  countries,
}) => {
  const [languages, setLanguages] = useState([])

  useEffect(() => {
    getLanguageService().then((res) => {
      if (res?.code === 200) {
        const resData = res.data as any
        setLanguages(
          resData?.data?.map((item: any) => ({
            label: item?.name,
            value: item?.code,
          }))
        )
      } else {
        setLanguages([])
      }
    })
  }, [])
  const [values, setValues] = useState<ICreateAgendaReportV4Req>({
    ...data,
  })

  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  useEffect(() => {
    if (values.table === 1) {
      setValues((prev) => ({ ...prev, countryPerceptions: ['All'] }))
    } else if (values.table === 3) {
      setValues((prev) => ({ ...prev, countryPerceptions: ['FX'] }))
    }
  }, [values.table])

  const parseCriteriaFields = () => {
    const fields: ConditionRuleField[] = []

    if (criteriaFields && criteriaFields?.length > 0) {
      criteriaFields.forEach((setting: any) => {
        const operationOptions =
          setting?.conditions?.map((x: any) => ({
            label: x.value || '',
            value: x.key || '',
          })) || undefined

        const options =
          setting?.data?.values?.map((x: any) => ({
            label: x.value || '',
            value: x.key || '',
          })) || undefined

        const optionData =
          setting?.type === 'boolean' && !options?.length
            ? operationOptions
            : options
        fields.push({
          name: setting.field,
          label: setting.alias,
          type: setting.type as any,
          options:
            setting.field === 'detected_source_language'
              ? languages
              : setting.field === 'user_country_detection'
              ? countries?.map((item: any) => ({
                  label: item.country,
                  value: item.alpha3,
                }))
              : optionData,
          operationOptions,
          hideOperation: setting?.type === 'boolean' && !options?.length,
        })
      })
    }

    return fields
  }
  const renderCriteriaFields = () => {
    const fParseCriteriaFields = parseCriteriaFields()
    return (
      <div className="criteria-fields">
        <FormSection
          title="Kriter Grupları"
          description="Rapor oluşturmak aşağıdaki icin kriter gruplarını seçebilirsiniz."
          className="criteria-fields-containe"
        >
          <div className="condition-builder">
            <ConditionBuilder
              defaultValue={values?.criteriaGroup as any}
              fields={fParseCriteriaFields}
              onChange={(e: any) => {
                console.log(e, 'ConditionBuilder')

                setValues((prev) => ({ ...prev, criteriaGroup: e }))
              }}
            />
          </div>
        </FormSection>
      </div>
    )
  }

  const renderSource = () => (
    <Column xs={{ size: 12 }} className="source-container-select">
      <Select
        name="source"
        label="Kaynak Tipi"
        placeholder="Kaynak tipini seçiniz"
        options={
          (source || [])?.map((item: string) => ({
            label: item,
            value: item,
          })) || []
        }
        isMultiple={true}
        onChange={(props) => {
          const value = (props as SelectOptionProps[]).map(
            (source) => source.value as string
          )

          setValues({
            ...values,
            dataSources: value,
          })
        }}
      />
    </Column>
  )
  const renderCountryList = () => {
    return (
      <>
        <Column className="source-container-select">
          <Checkbox
            name="CalculateByCountries"
            label="Ülkelere Göre Hesaplama Yapılsın mı?"
            onChange={(props) => {
              setValues((prev) => ({
                ...prev,
                shouldCalculateByCountries: props.target.checked,
              }))
            }}
            isChecked={values?.shouldCalculateByCountries || false}
          />
        </Column>
        {values?.shouldCalculateByCountries && (
          <Column xs={{ size: 12 }} style={{ marginTop: 10 }}>
            <React.Fragment>
              <Select
                isMultiple={true}
                label="Ülkeler"
                name="countryList"
                options={countries?.map((item: any) => ({
                  label: item.country,
                  value: item.alpha3,
                }))}
                value={countries
                  ?.filter((item: any) =>
                    values.countryList?.includes(item.alpha3)
                  )
                  ?.map((itm: any) => ({
                    label: itm.country,
                    value: itm.alpha3,
                  }))}
                onChange={(props) => {
                  setValues({
                    ...values,
                    countryList: (props as any)?.map(
                      (item: any) => item?.value
                    ),
                  })
                }}
                placeholder="Seçim yapın"
              />
            </React.Fragment>
          </Column>
        )}
      </>
    )
  }

  const renderTable = () => {
    const tableOptions = [
      { label: 'All', value: 1 },
      { label: 'Diğer Algılar', value: 2 },
    ]
    return (
      <Row>
        <Column xs={{ size: 6 }} style={{ marginTop: 10 }}>
          <Select
            label="Tablo"
            name="table"
            options={tableOptions}
            value={
              tableOptions.find((item) => values.table === item.value) as any
            }
            onChange={(props: any) =>
              setValues({
                ...values,
                table: props.value as number,
              })
            }
            placeholder="Seçim yapın"
          />
        </Column>
        {values.table === 2 && (
          <Column xs={{ size: 6 }} style={{ marginTop: 10 }}>
            <Select
              isMultiple={true}
              description="Tablo diğer değerler seçiliyse bu alanı tanımlayınız."
              label="CountryPerceptions"
              name="CountryPerceptions"
              options={countryPerception?.map((item: any) => ({
                label: item.description,
                value: item.value,
              }))}
              value={countryPerception
                .filter((item: any) =>
                  values.countryPerceptions?.includes(item.value)
                )
                .map((itm: any) => ({
                  label: itm.description,
                  value: itm.value,
                }))}
              onChange={(props) =>
                setValues({
                  ...values,
                  countryPerceptions: (props as any).map(
                    (item: any) => item.value
                  ),
                })
              }
              placeholder="Seçim yapın"
            />
          </Column>
        )}
      </Row>
    )
  }

  return (
    <Styled.AgendaReportForm>
      <Form>
        <Row>
          <Column xs={{ size: 12 }}>
            <Input
              label="Gündem raporu başlığı"
              name="reportname"
              value={values.reportName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValues({ ...values, reportName: event.target.value })
              }}
            />
          </Column>
          <Column xs={{ size: 6 }}>
            <DateTimePicker
              description="İşlenecek veri aralağının başlangıç tarihini seçiniz"
              label="Başlangıç tarihi"
              locale="tr"
              name="startDate"
              onChange={(props) =>
                setValues({
                  ...values,
                  startDate: props,
                })
              }
              type="default"
              value={values.startDate}
              times={hoursArray}
              datePickerProps={{
                maxDate: values.endDate || undefined,
              }}
            />
          </Column>
          <Column xs={{ size: 6 }}>
            <DateTimePicker
              description="İşlenecek veri aralağının bitiş tarihini seçiniz"
              label="Bitiş tarihi"
              locale="tr"
              name="endDate"
              onChange={(props) =>
                setValues({
                  ...values,
                  endDate: props,
                })
              }
              type="default"
              value={values.endDate}
              times={hoursArray}
              datePickerProps={{
                minDate: values.startDate || undefined,
              }}
            />
          </Column>
          {renderSource()}

          <Column className="source-container-select">
            <Checkbox
              name="AIAnalysis"
              label="AI Analizi Yapılsın mı?"
              isChecked={values?.shouldAiAnalyze || false}
              onChange={(props) => {
                setValues((prev) => ({
                  ...prev,
                  shouldAiAnalyze: props.target.checked,
                }))
              }}
            />
          </Column>
          <Column className="source-container-select">
            <Checkbox
              name="shouldCalculateGraphAnalysis"
              label="Graph Analiz’i yapılsın mı?"
              isChecked={values?.shouldCalculateGraphAnalysis || false}
              onChange={(props) => {
                setValues((prev) => ({
                  ...prev,
                  shouldCalculateGraphAnalysis: props.target.checked,
                }))
              }}
            />
          </Column>
          {renderCountryList()}
        </Row>
        {renderTable()}
        <Row>{renderCriteriaFields()}</Row>

        <FormFooter>
          <Button
            children="Gündem raporunu oluştur"
            appearance="primary"
            isDisabled={false}
            onClick={() => onSubmit(values as any)}
            isLoading={isLoading}
          />
        </FormFooter>
      </Form>
    </Styled.AgendaReportForm>
  )
}

export default AgendeSecondForm
