import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const VisualizationFields = styled.div`
  margin-top: 1rem;
`

export const VisualizationField = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  background: ${(props) => (props.isSelected ? '#ecfbf0' : '#f3f3f3')};
  color: ${(props) => (props.isSelected ? '#3f9a58' : 'inherit')};
  margin-bottom: 4px;
  padding: 0.75rem;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 500;
  transition: all, 0.25s;
  -webkit-transition: all, 0.25s;
  position: relative;

  span {
    display: block;
    margin-bottom: 4px;
  }

  &:hover {
    background: ${(props) => (props.isSelected ? '#ddf5e3' : '#ececec')};
  }
`

export const VisualizationFieldBrand = styled.span`
  position: absolute;
  right: 4px;
  top: 4px;
  background: ${colors.green};
  color: #fff;
  font-size: 9px;
  padding: 2px;
  border-radius: 4px;
`

export const VisualizationOptions = styled.div`
  h4 {
    button {
      line-height: 25px;
      height: 25px;
      margin-right: 4px;
      margin-top: -4px;
      padding: 0;
    }
  }
`
