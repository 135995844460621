import {
  Data2,
  IReportTask,
  IReportTaskEntity,
  Root,
} from 'pages/agenda-report-v4/detail/widgets/types'
import { request } from '../instance'
import {
  ICriteriaFieldSettings,
  IV3InfluentialPerson,
  CreateAgendaReportV4Req,
  IAgendaReportInsertUpdate,
} from './types'
import { getByGroupId } from '../person-report/endpoints'

export const LanguageService = () =>
  request<any>({
    method: 'get',
    url: 'api/definition/languages',
  })

export const createAgendaReportV4 = (data: CreateAgendaReportV4Req) =>
  request<any>({
    method: 'post',
    url: 'agenda-report/4/create/independent',
    data,
    mappers: {
      res: (values) => values.data,
    },
  })

export const availableCountriesV4 = () =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/available-countries',
  })
export const availableDataSourcesV4 = () =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/available-data-sources',
  })
export const listAgendaReportV4 = ({
  ReportName,
  Status,
  Environment,
  PageSize,
  PageIndex,
}: {
  ReportName?: string
  Status?: string
  Environment?: string
  PageSize?: number
  PageIndex?: number
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/all',
    params: {
      ReportName,
      Status,
      Environment,
      PageSize,
      PageIndex,
    },
  })

export const criteriaFieldSettingsV4 = () =>
  request<ICriteriaFieldSettings>({
    method: 'get',
    url: 'agenda-report/4/criteria-field-settings',
  })

export const agendaReportV4 = (GroupId: string, ReportType: any) =>
  request<Root>({
    method: 'get',
    url: 'agenda-report/4/report',
    params: {
      GroupId,
      ReportType,
    },
  })

export const agendaTaskV4 = (groupId: string) =>
  request<IReportTask>({
    method: 'get',
    url: `agenda-report/4/task/${groupId}`,
  })

export const updateAgendaTaskPage = (params: IReportTaskEntity) =>
  request<any>({
    method: 'put',
    url: `agenda-report/4/task/${params?.groupId}`,
    data: {
      ...params,
    },
  })

export const getInfluentialUser = (GroupId: string, UserScreenName: string) =>
  request<IV3InfluentialPerson>({
    method: 'get',
    url: 'agenda-report/4/twitter/account',
    params: {
      GroupId,
      UserScreenName,
    },
  })

export const deleteAgendaReportPage = (metaData: any) =>
  request<any>({
    method: 'delete',
    url: 'agenda-report/4/page',
    params: {
      GroupId: metaData?.GroupId,
      PageId: metaData?.PageId,
      ReportType: metaData?.ReportType === 'Original' ? 1 : 2,
    },
  })

export const updateAgendaReportPage = (params: IAgendaReportInsertUpdate) =>
  request<any>({
    method: 'put',
    url: 'agenda-report/4/page',
    data: {
      groupId: params?.groupId,
      reportType: params?.reportType === 'Original' ? 1 : 2,
      pageId: params?.pageId,
      pageNumber: params?.pageNumber,
      title: params?.title,
      data: params?.data,
    },
  })

export const getAgendaReportTweet = ({
  GroupId,
  TweetId,
}: {
  GroupId?: string
  TweetId?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/twitter/tweet',
    params: {
      GroupId,
      TweetId,
    },
  })

export const getAgendaReportNews = ({
  GroupId,
  Url,
}: {
  GroupId?: string
  Url?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/news',
    params: {
      GroupId,
      Url,
    },
  })

export const getAgendaReportTelegram = ({
  GroupId,
  Url,
}: {
  GroupId?: string
  Url?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/telegram',
    params: {
      GroupId,
      Url,
    },
  })

export const getAgendaReportEksiSozluk = ({
  EntryId,
  GroupId,
}: {
  EntryId?: string
  GroupId?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/eksisozluk/entry',
    params: {
      EntryId,
      GroupId,
    },
  })

export const getAgendaReportInstagram = ({
  GroupId,
  ShortCode,
}: {
  GroupId?: string
  ShortCode?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/instagram/media',
    params: {
      GroupId,
      ShortCode,
    },
  })

export const getAgendaReportMastodon = ({
  GroupId,
  PostUri,
}: {
  GroupId?: string
  PostUri?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/mastodon/post',
    params: {
      GroupId,
      PostUri,
    },
  })

export const getAgendaReportReddit = ({
  GroupId,
  Url,
}: {
  GroupId?: string
  Url?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/reddit',
    params: {
      GroupId,
      Url,
    },
  })

export const getAgendaReportQuora = ({
  GroupId,
  PostUrl,
}: {
  GroupId?: string
  PostUrl?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/quora/post',
    params: {
      GroupId,
      PostUrl,
    },
  })

export const getAgendaReportTiktok = ({
  GroupId,
  VideoId,
}: {
  GroupId?: string
  VideoId?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/tiktok/video',
    params: {
      GroupId,
      VideoId,
    },
  })

export const getAgendaReportYoutube = ({
  GroupId,
  VideoId,
}: {
  GroupId?: string
  VideoId?: string
}) =>
  request<any>({
    method: 'get',
    url: 'agenda-report/4/youtube/video',
    params: {
      GroupId,
      VideoId,
    },
  })

export const addPage = (params: {
  groupId: string
  reportType: number
  pageType: string
  pageNumber: number
  title: string
  data: Data2
}) =>
  request<any>({
    method: 'post',
    url: 'agenda-report/4/page',
    data: {
      groupId: params.groupId,
      reportType: params.reportType,
      pageType: params.pageType,
      pageNumber: params.pageNumber,
      title: params.title,
      data: params.data,
    },
  })
