import React, { useState, useCallback, useEffect } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container } from '@zera-admin/page'
import Button from '@zera-admin/button'
import { uid } from '@zera-admin/utils'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'
import MainLayout from 'layouts/main'
import claimService, {
  Claim,
  ClaimType,
} from 'services/http/identity-server/claim'

import { UpdateClaimProps } from './types'
import ClaimForm from './components/ClaimForm'

const UpdateClaim: React.FunctionComponent<UpdateClaimProps> = ({
  match,
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()
  const [values, setValues] = useState<Claim>({
    title: '',
    value: '',
    type: ClaimType.Api,
    appId: 0,
    roles: [],
  })

  const handleUpdate = async (data: Claim) => {
    if (values) {
      setIsLoading(true)

      try {
        await claimService.update({ ...data, id: match.params.id })

        flagcard.add({
          title: 'İzin güncellendi',
          children: 'Geçerli izin başarıyla güncellendi.',
        })
      } catch {
        popup.add({
          title: 'İzin güncellenemedi',
          children:
            'İzin güncellenirken bir sorun oluştu, lütfen tekrar deneyin.',
        })
      }

      setIsLoading(false)
    }
  }

  const handleDelete = () => {
    const popupId = uid()

    const onClick = () => {
      if (values) {
        setIsLoading(true)

        claimService
          .del(match.params.id)
          .then(() => {
            flagcard.add({
              title: 'İzin silindi',
              children: 'Geçerli izin başarıyla silindi.',
            })

            history.push('/claims')
          })
          .catch(() => {
            popup.add({
              title: 'İzin silinemedi',
              children:
                'İzin silinirken bir sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
            })
          })
          .finally(() => {
            setIsLoading(false)
            popup.remove(popupId)
          })
      }
    }

    const onClose = () => {
      popup.remove(popupId)
    }

    popup.add({
      id: popupId,
      appearance: 'warning',
      title: 'İzin silinsin mi?',
      children:
        'Bu izni silmek istediğinize emin misiniz? Bu işlem geri alınamaz.',
      actions: [
        {
          isLoading,
          onClick: onClick,
          text: 'Sil',
        },
        {
          onClick: onClose,
          text: 'Vazgeç',
        },
      ],
      onClose,
    })
  }

  const getValues = useCallback(async () => {
    const id = match.params.id

    try {
      const response = await claimService.getById(id)

      setValues(response.data)
    } catch {
      const popupId = uid()

      const onClose = () => {
        history.push('/claims')
        popup.remove(popupId)
      }

      popup.add({
        id: popupId,
        title: 'İzin getirilemedi',
        children:
          'İzin bilgisi getirilirken bi sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
        onClose,
        actions: [
          {
            appearance: 'primary',
            text: 'Geri dön',
            onClick: onClose,
          },
        ],
      })
    }

    setIsReady(true)
  }, [match.params.id, popup, history])

  useEffect(() => {
    getValues()
  }, [getValues])

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="İzinler" href="/claims" />
      <BreadcrumbItem text="İzin detayı" />
    </Breadcrumb>
  )

  return (
    <MainLayout isLoading={!isReady}>
      <Container width="narrow">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            actions={
              <Button
                appearance="error"
                isDisabled={isLoading}
                onClick={handleDelete}
              >
                İzni sil
              </Button>
            }
            bottomBar={
              <p>
                Oluşturduğunuz izin bilgilerini aşağıdan güncelleyebilirsiniz.
              </p>
            }
          >
            İzin görüntüleniyor: {values?.title || '-'}
          </PageHeader>
          <ClaimForm
            loading={isLoading}
            values={values}
            operation="update"
            onSubmit={handleUpdate}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default UpdateClaim
