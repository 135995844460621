import React from 'react'
import { TelegramCardProps } from './types'
import defaultUserProfile from './images/default-user.png'
import logo from './images/telegram.svg'
import blueLogo from './images/telegram-blue.svg'
import * as Styled from './TelegramCard.styled'

const TelegramCard: React.FC<TelegramCardProps> = ({
  data,
  editmode,
  onChange,
}) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  const toLocaleDate = (date: string) => {
    // ISO tarihini alalım
    const isoDate = date // Örnek ISO formatlı tarih

    // ISO tarihini Date nesnesine dönüştürelim
    const dateObject = new Date(isoDate)

    // Tarihi Türkçe formatında yerel tarihe çevirip yazdıralım
    const localDate = dateObject.toLocaleDateString('tr-TR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })

    return localDate
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', logo)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfile)
  }

  const textLength = data?.Message && data?.Message?.length >= 200

  return (
    <Styled.TelegramCardWrapper>
      <div className="post">
        <div className="post-body">
          <div className="post-body-profile-image">
            <img
              src={defaultUserProfile}
              onError={hasErrorImageProfil}
              alt="Telegram Profile"
            />
          </div>
          <div className="post-message-baloon">
            <i className="baloon-tail">
              <svg
                className="bubble_icon"
                width="9px"
                height="20px"
                viewBox="0 0 9 20"
              >
                <g fill="none">
                  <path
                    className="background"
                    fill="#ffffff"
                    d="M8,1 L9,1 L9,20 L8,20 L8,18 C7.807,15.161 7.124,12.233 5.950,9.218 C5.046,6.893 3.504,4.733 1.325,2.738 L1.325,2.738 C0.917,2.365 0.89,1.732 1.263,1.325 C1.452,1.118 1.72,1 2,1 L8,1 Z"
                  ></path>
                  <path
                    className="border_1x"
                    fill="#d7e3ec"
                    d="M9,1 L2,1 C1.72,1 1.452,1.118 1.263,1.325 C0.89,1.732 0.917,2.365 1.325,2.738 C3.504,4.733 5.046,6.893 5.95,9.218 C7.124,12.233 7.807,15.161 8,18 L8,20 L9,20 L9,1 Z M2,0 L9,0 L9,20 L7,20 L7,20 L7.002,18.068 C6.816,15.333 6.156,12.504 5.018,9.58 C4.172,7.406 2.72,5.371 0.649,3.475 C-0.165,2.729 -0.221,1.464 0.525,0.649 C0.904,0.236 1.439,0 2,0 Z"
                  ></path>
                  <path
                    className="border_2x"
                    d="M9,1 L2,1 C1.72,1 1.452,1.118 1.263,1.325 C0.89,1.732 0.917,2.365 1.325,2.738 C3.504,4.733 5.046,6.893 5.95,9.218 C7.124,12.233 7.807,15.161 8,18 L8,20 L9,20 L9,1 Z M2,0.5 L9,0.5 L9,20 L7.5,20 L7.5,20 L7.501,18.034 C7.312,15.247 6.64,12.369 5.484,9.399 C4.609,7.15 3.112,5.052 0.987,3.106 C0.376,2.547 0.334,1.598 0.894,0.987 C1.178,0.677 1.579,0.5 2,0.5 Z"
                  ></path>
                  <path
                    className="border_3x"
                    d="M9,1 L2,1 C1.72,1 1.452,1.118 1.263,1.325 C0.89,1.732 0.917,2.365 1.325,2.738 C3.504,4.733 5.046,6.893 5.95,9.218 C7.124,12.233 7.807,15.161 8,18 L8,20 L9,20 L9,1 Z M2,0.667 L9,0.667 L9,20 L7.667,20 L7.667,20 L7.668,18.023 C7.477,15.218 6.802,12.324 5.64,9.338 C4.755,7.064 3.243,4.946 1.1,2.983 C0.557,2.486 0.52,1.643 1.017,1.1 C1.269,0.824 1.626,0.667 2,0.667 Z"
                  ></path>
                </g>
              </svg>
            </i>
            <div className="post-body-content">
              <div className="post-body-content-username">
                <span>{data?.ChannelName}</span>
                <div className="post-body-content-username-logo">
                  <img src={blueLogo} alt="Telegram Logo" />
                </div>
              </div>
              {data?.ImageUrl ? (
                <div className="post-body-content-image">
                  <img
                    className="post-body-image"
                    src={data?.ImageUrl}
                    onError={hasErrorImage}
                    alt="Instagram Post"
                  />
                </div>
              ) : null}

              <div className="post-body-content-message">
                <div
                  contentEditable={editmode}
                  onBlur={(e) => handleBlurElement(e, 'Message')}
                  className="post-body-content-text"
                >
                  {textLength && !isOpen && !editmode
                    ? data?.Message?.slice(0, 200) + '...'
                    : data?.Message}
                </div>
                {textLength && !editmode && (
                  <button onClick={() => setIsOpen(!isOpen)}>
                    {isOpen ? ' Daha Az' : ' Daha Fazla'}
                  </button>
                )}
              </div>

              <div className="post-body-content-info">
                <div className="post-body-content-info-reactions">
                  {data?.ReactionResults.map((reaction, index) => (
                    <div key={index}>
                      <span className="reaction">{reaction.Reaction}</span>
                      <span className="count">{reaction.Count}</span>
                    </div>
                  ))}
                </div>
                <div className="post-body-content-info-date">
                  <span>{toLocaleDate(data?.PublishedDate as string)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="post-footer">
          <div className="post-footer-logo">
            <div className="post-footer-logo-warpper">
              <img src={logo} alt="Telegram Logo" />
            </div>
          </div>
          <div className="post-footer-stats">
            <span>{data?.Views} Görüntülenme</span>
            <span>{data?.Forwards} Forwards</span>
          </div>
          <div className="post-footer-url gradient">
            <a href={data?.Url} target="_blank" rel="noreferrer">
              Post'u görüntüle
            </a>
          </div>
        </div>
      </div>
    </Styled.TelegramCardWrapper>
  )
}

export default TelegramCard
