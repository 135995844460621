import React from 'react'
import { Column, Row } from '@zera-admin/page'
import CodeMirror from '@uiw/react-codemirror'
import { sql } from '@codemirror/lang-sql'

import { RawTabProps } from '../types'

const RawTab: React.FunctionComponent<RawTabProps> = ({
  value,
  onChange,
  intervals,
}) => {
  const renderRaw = () => {
    return (
      <Row xs={{ direction: 'column' }}>
        <Column>
          <p>
            Aşağıdan widget sorgunuzu SQL ile birlikte yazabilirsiniz. Aynı
            zamanda özel tarih aralıklarını kullanabilirsiniz.
          </p>
          <code>
            {`Özel Filtre (v2): `}
            {`$ {filter('key: string', 'value: string', 'operator': '=, <=, >=, etc..', 'seperator': 'and, or')}`}
          </code>
          <code>
            {`Özel Tarih, Tarih Parsing (v2): `}
            {`$ {time('key: string', 'ref: string', 'operator': '=, <=, >=, etc..')}, $ {timeParse('key: string', 'ref: string', 'operator': '=, <=, >=, etc..')}`}
          </code>
          <code>
            {`Özel Tarih, Tarih Parsing (v2): `}
            {`$ {time('key: string', 'ref: string', 'operator': '=, <=, >=, etc..')}, $ {timeParse('key: string', 'ref: string', 'operator': '=, <=, >=, etc..')}`}
          </code>
          <code>
            {`Özel IN Query (v2): `}
            {`$ {inquery('key: string', 'params: { 'key': value }', 'seperator': 'and, or')}`}
          </code>
          <code>
            {`Özel Extracts (v2): `}
            {`$ {extractSubstring()}, $ {extractGroup()}`}
          </code>
        </Column>
        <Column>
          <CodeMirror
            value={value}
            height="300px"
            extensions={[sql()]}
            onChange={(value) => onChange(value)}
          />
        </Column>
      </Row>
    )
  }

  return renderRaw()
}

export default RawTab
