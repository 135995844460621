import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Lozenge from '@zera-admin/lozenge'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import { BotConclusionEnum } from 'services/http/dataset'
import classificationControlSetsService, {
  ClassificationControlSet,
  ClassificationControlSetResponse,
} from 'services/http/dataset/classification-control-set'

import { ClassificationControlSetListProps } from './types'

const ClassificationControlSetList: React.FunctionComponent<
  ClassificationControlSetListProps
> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [classificationControlSet, setClassificationControlSet] =
    useState<ClassificationControlSetResponse>()

  useEffect(() => {
    getClassificationControlSets()
  }, [])

  const getClassificationControlSets = async () => {
    try {
      const response = await classificationControlSetsService.get()

      setClassificationControlSet(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    const actions = (
      <LinkButton
        appearance="primary"
        href="/classification-control-set/create"
      >
        Sınıflandırma kontrol seti oluştur
      </LinkButton>
    )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Sınıflandırma kontrol setleri" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm sınıflandırma kontrol setlerini görüntüleyebilir, yeni
        oluşturabilir, varolanları düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={actions}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Sınıflandırma kontrol setleri
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'text',
        text: 'Sınıflandırma kontrol seti metni',
      },
      {
        name: 'languageCode',
        text: 'Dil kodu',
      },
      {
        name: 'conclusion',
        text: 'Karar',
        render: (props: ClassificationControlSet) => {
          if (props.conclusion === BotConclusionEnum.Bot) {
            return <Lozenge appearance="error">Bot</Lozenge>
          }

          return <Lozenge appearance="success">Bot Değil</Lozenge>
        },
      },
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: ClassificationControlSet) => (
          <LinkButton
            appearance="subtle-link"
            href={`/classification-control-set/update/${item.id}`}
          >
            Görüntüle
          </LinkButton>
        ),
      },
    ]

    if (classificationControlSet) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={classificationControlSet.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={classificationControlSet.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default ClassificationControlSetList
