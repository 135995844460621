import React, { useEffect, useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import MainLayout from 'layouts/main'
import agendaReportsService from 'services/http/report/agenda-report-4'

import agendaReportService from 'services/http/report/agenda-report-4'

import { DetailAgendaReportProps } from './types'

import * as Styled from './DetailAgendaReport.styled'
import Spinner from '@zera-admin/spinner'
import { LoadingView } from 'pages/person-report/PersonReport.styled'
import { crypto } from '@zera-admin/helpers'
import { Countries } from 'services/http/report/agenda-report/types'
import { useFlagcardContext } from 'app/contexts/flagcard'
import countryService, {
  CountryPerceptionsResponse,
} from 'services/http/backoffice/country-perception'
import AgendeSecondUpdateForm from '../secondform/AgendeSecondUpdateForm'
import { conditionBuilderDetail } from 'app/functions/parser/condition-builder/detail'
import { conditionBuilderInsert } from 'app/functions/parser/condition-builder/insert'
import { getVideoService } from 'services/http/video/url/endpoint'
import Button from '@zera-admin/button'
const DetailAgendaReport: React.FunctionComponent<DetailAgendaReportProps> = ({
  match,
}) => {
  const [values, setValues] = useState<any>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [source, setSource] = useState<string[]>([])

  const [modalOpen, setModalOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string>('')

  const [countries, setCountries] = useState<Countries[]>([])
  const [criteriaFields, setCriteriaFields] = useState<any>()
  const [flagcard] = useFlagcardContext()
  const [countryPerceptions, setCountryPerceptions] =
    useState<CountryPerceptionsResponse>()

  useEffect(() => {
    agendaReportService.availableDataSourcesV4().then((res) => {
      setSource(res.data.data)
    })
    agendaReportService.availableCountriesV4().then((res) => {
      setCountries(res.data.data.countries)
    })
    agendaReportService.criteriaFieldSettingsV4().then((res) => {
      setCriteriaFields(res.data.data)
    })

    getVideoService({ key: 'alice-agenda-report-v4-creation' }).then((res) => {
      setVideoUrl(res?.data?.data?.url || '')
    })

    handleGetCountryPerceptions()
  }, [])

  const handleGetCountryPerceptions = (pageIndex: number = 0) => {
    setIsLoading(true)
    countryService
      .getCountryPerceptions(1000, pageIndex)
      .then((res) => {
        setCountryPerceptions(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getValues()
  }, [])

  const onSubmit = (data: any) => {
    setIsLoading(true)
    const updateTask: any = {
      ...data,
      criteriaGroup: conditionBuilderInsert(data?.criteriaGroup) || undefined,
    }
    agendaReportService
      .updateAgendaTaskPage(updateTask)
      .then((res) => {
        flagcard.add({
          id: crypto.id(),
          appearance: 'success',
          title: 'Gündem raporu task bilgileri güncellendi',
          children:
            'Girmiş olduğunuz bilgiler ile birlikte gündem raporu, düzenlenmek üzere işlem sırası alınmıştır. İlgili adımları takip etmek üzere yönlendiriliyorsunuz.',
        })

        setTimeout(() => {
          window.location.reload()
        }, 300)
      })
      .catch((error: string) => {
        flagcard.add({
          id: crypto.id(),
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Gündem raporu güncellendirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
        })

        setIsLoading(false)
      })
  }

  const getValues = () => {
    setIsLoading(true)
    agendaReportsService
      .agendaTaskV4(match?.params?.id)
      .then((res) => {
        setIsLoading(false)
        setValues({
          ...res?.data?.data,
          criteriaGroup: conditionBuilderDetail(
            res?.data?.data?.criteriaGroup || []
          ),
        })
      })
      .catch((err: any) => {
        if (err?.reason?.includes('404')) {
          agendaReportsService
            .agendaTaskV4(match?.params?.id)
            .then((res) => {
              setIsLoading(false)

              setValues({
                ...res?.data?.data,
                criteriaGroup: conditionBuilderDetail(
                  res?.data?.data?.criteriaGroup || []
                ),
              })
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
  }

  useEffect(() => {
    setTimeout(() => {
      if (sessionStorage.getItem('scrollPosition')) {
        window.scrollTo({
          top: parseInt(sessionStorage.getItem('scrollPosition') || '0'),
          behavior: 'smooth',
        })
      }
    }, 1000)
  })

  const renderModal = () => (
    <Styled.ModalContainer>
      <div id="modal-overlay" className="modal-overlay" />
      <div className="modal-content">
        <div className="modal-content-button">
          <button onClick={() => setModalOpen(false)}>Kapat</button>
        </div>
        <div className="modal-content-video">
          {videoUrl && (
            <iframe
              src={videoUrl}
              width="640"
              height="480"
              title="Video Player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          )}
        </div>
      </div>
    </Styled.ModalContainer>
  )

  const renderContent = () => (
    <div>
      <Page id="printThis" className="fa-Page">
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem
                text="Gündem Raporu Listesi"
                href="/agenda-reportv4"
              />
              <BreadcrumbItem text="Gündem raporu detayı" />
            </Breadcrumb>
          }
          bottomBar={
            <p>
              Oluşturmuş olduğunuz taskın detaylarını aşağıdan
              görüntüleyebilirsiniz.
            </p>
          }
          actions={
            <Styled.HeaderButtonsContainer>
              <div>
                <Button
                  appearance="primary"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  Yardım al
                </Button>
              </div>
            </Styled.HeaderButtonsContainer>
          }
        >
          {values?.reportName || 'Gündem Raporu'}
        </PageHeader>
        <AgendeSecondUpdateForm
          source={source}
          countries={countries}
          criteriaFields={criteriaFields?.criteriaFields || []}
          countryPerception={countryPerceptions?.data}
          data={values}
          isLoading={isLoading}
          onSubmit={(x) => onSubmit(x)}
        />
      </Page>
    </div>
  )

  return isLoading ? (
    <LoadingView>
      <Spinner size="large" />
      <h5>Rapor yükleniyor...</h5>
    </LoadingView>
  ) : values && values?.criteriaGroup ? (
    <MainLayout>
      {renderContent()}
      {modalOpen && renderModal()}
    </MainLayout>
  ) : null
}

export default DetailAgendaReport
