import React from 'react'
import * as Styled from './YoutubeStyled'

import EyeIcon from './images/eye-icon.svg'
import LikeIcon from './images/like-icon.png'
import defaultYoutubeImage from './images/default-youtube.jpg'
import defaultUserPp from './images/default-user.png'
import { IYoutubeProps } from './types'
import { numberFormat } from 'app/functions/number'

const YoutubeCard: React.FC<IYoutubeProps> = ({ data, editMode, onChange }) => {
  const [isExpanded, setExpanded] = React.useState(false)

  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultYoutubeImage)
  }

  function hasErrorImageProfile(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserPp)
  }
  return (
    <Styled.AgendaYoutubeCard $isOpen={editMode ? true : isExpanded}>
      <div className="agenda-youtube-header">
        <div className="agenda-youtube-header-profile-image">
          {data?.ChannelProfilePictureUrl ? (
            <img
              src={data?.ChannelProfilePictureUrl}
              onError={hasErrorImageProfile}
              alt="ProfilePhoto"
            />
          ) : (
            <img src={defaultUserPp} alt="ProfilePhoto" />
          )}
        </div>
        <div className="agenda-youtube-header-profile-des">
          <span className="agenda-youtube-header-profile-des-name">
            {data?.ChannelName}
          </span>
          <span className="agenda-youtube-header-profile-des-link">
            {numberFormat(data?.ChannelFollowerCount)} Abone
          </span>
        </div>
      </div>
      <div className="agenda-youtube-video">
        <div className="agenda-youtube-video-desc">
          <span
            contentEditable={editMode}
            onBlur={(e) => handleBlurElement(e, 'Text')}
          >
            {data?.Text}
          </span>
        </div>
        {data && data?.Text?.length >= 69 && !editMode && (
          <button
            className="agenda-youtube-video-button"
            onClick={() => setExpanded(!isExpanded)}
          >
            {isExpanded ? 'Daha Az' : 'Daha Fazla'}
          </button>
        )}
        <div className="agenda-youtube-video-date">
          {new Date(data?.CreatedAt || 0).toLocaleString('tr-TR', {
            dateStyle: 'medium',
          })}
        </div>
      </div>
      <div className="agenda-youtube-body">
        {data?.MediaUrl && (
          <img
            className="agenda-youtube-body-post-image"
            src={data?.MediaUrl}
            onError={hasErrorImage}
            alt="PostImage"
          />
        )}

        <div className="agenda-youtube-body-post-infos">
          <div className="agenda-youtube-body-post-infos-item">
            <img src={EyeIcon} alt="EyeIcon" />
            <strong>{numberFormat(data?.ViewCount)}</strong>
            <span>Görüntülenme</span>
          </div>
          <div className="agenda-youtube-body-post-infos-item">
            <img src={LikeIcon} alt="LikeIcon" />
            <strong>{numberFormat(data?.FavoriteCount)}</strong>
            <span>Beğeni</span>
          </div>
        </div>
      </div>
    </Styled.AgendaYoutubeCard>
  )
}
export default YoutubeCard
