import React, { useCallback, useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import { usePopupContext } from 'app/contexts/popup'
import MainLayout from 'layouts/main'
import roleService, {
  RoleListRequest,
  RoleListResponse,
} from 'services/http/identity-server/role'
import Pager from 'components/pager'

import { RoleListProps } from './types'

const RoleList: React.FunctionComponent<RoleListProps> = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<RoleListResponse>()
  const [popup] = usePopupContext()

  const getValues = useCallback(
    async (request: RoleListRequest) => {
      try {
        const response = await roleService.get(request)

        setValues(response.data)
      } catch {
        popup.add({
          title: 'Roller getirilemedi',
          children:
            'Rol listesi getirilirken bir sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
        })
      }

      setIsLoading(false)
    },
    [popup]
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    getValues({
      pageSize: 15,
      pageIndex: parseInt(searchParams.get('pageIndex') || '1') - 1,
    })
  }, [getValues, location.search])

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="Roller" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Page>
        <PageHeader
          actions={
            <LinkButton appearance="primary" href="/role/create">
              Rol oluştur
            </LinkButton>
          }
          bottomBar={
            <p>
              Kullanıcı yönetimi üzerinde kayıtlı tüm rolleri görüntüleyebilir,
              yeni rol oluşturabilir veya düzenleyebilirsiniz.
            </p>
          }
          breadcrumbs={breadcrumbs}
        >
          Roller
        </PageHeader>
        <Table
          columns={
            [
              {
                name: 'title',
                text: 'Rol başlığı',
              },
              {
                name: 'name',
                text: 'Rol anahtarı',
              },
              {
                name: 'operation',
                text: 'İşlem',
                width: '100px',
                render: (row: any) => (
                  <LinkButton
                    appearance="subtle-link"
                    href={`/role/update/${row.id}`}
                    size="none"
                    style={{ padding: '5px 0px' }}
                  >
                    Görüntüle
                  </LinkButton>
                ),
              },
            ] as any
          }
          rows={values ? values.data : []}
          helperMessage="Aradığınız kriterlere ait sonuç bulunamadı, lütfen tekrar deneyin."
          isLoading={isLoading}
        />
        <Pager pages={values?.meta.totalPage || 1} />
      </Page>
    </MainLayout>
  )
}

export default RoleList
