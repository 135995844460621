import styled from 'styled-components'

export const RedditCardWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  height: fit-content !important;

  .reddit-orange {
    border-radius: 0 0 8px 8px;
    background-color: #ff5700;
  }

  .post {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-header {
      padding: 4px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-logo {
        img {
          width: 36px;
          height: 36px;
        }
      }

      &-info {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 8px;
      }

      &-profile {
        display: flex;
        align-items: center;

        &-image {
          margin-right: 6px;
          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }

        &-name {
          span {
            font-weight: 500;
            font-size: 14px;
            color: #666;
          }
        }
      }

      &-dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #666;
      }

      &-time {
        span {
          font-size: 14px;
          color: #666;
        }
      }
    }

    &-body {
      &-text {
        text-align: start;
        padding: 4px 16px;
        div {
          font-size: 20px;
          color: #333;
          font-weight: 600;
          word-break: break-all;
        }
        button {
          background: none;
          border: none;
          color: #333;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &-media {
        &-image {
          width: 95%;

          border-radius: 8px;
        }
      }
    }

    &-footer {
      position: relative;

      &-logo {
        position: absolute;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;

        &-wrapper {
          padding: 4px;
          border-radius: 100%;
          background: #fff;
          border: 2px solid #333;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      &-info {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px;

        &-like {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: #dbe4e9;
          padding: 4px 8px;
          border-radius: 1.25rem;
          span {
            font-size: 14px;
            color: #333;
            font-weight: 600;
          }
        }

        &-comments {
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: #dbe4e9;
          padding: 4px 8px;
          border-radius: 1.25rem;
          span {
            font-size: 14px;
            color: #333;
            font-weight: 600;
          }
        }
      }

      &-url {
        text-decoration: none;
        color: #fff;
        font-weight: 600;
        padding: 8px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          color: #fff;
        }
        a:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }
`
