import React, { useState } from 'react'
import Button from '@zera-admin/button'
import EmptyState from '@zera-admin/empty-state'
import Icon from '@zera-admin/icon'
import Modal from '@zera-admin/modal'
import { Column, Row } from '@zera-admin/page'
import { Editor } from '@tinymce/tinymce-react'

import { useSchemaService } from 'app/library/layout-builder'
import NotFoundParagraph from 'assets/images/not-found.png'

import ComponentHeader from '../ComponentHeader'
import * as Styled from './Paragraph.styled'
import { ParagraphProps } from './types'

const Paragraph: React.FunctionComponent<ParagraphProps> = ({
  content,
  designing,
  entry,
  id,
}) => {
  const [modal, setModal] = useState<ParagraphProps | null>(null)

  const schemaService = useSchemaService()

  const handleChangeProperties = (value: object) => {
    schemaService.changeProperties(id as string, value)
  }

  const renderDesigner = () => (
    <Styled.ParagraphEmpty>
      <EmptyState
        title="Paragraf"
        description="Görsellerinizi dashboard üzerinde görüntüleyebilirsiniz."
        image={{ src: NotFoundParagraph }}
        actions={
          <Button
            iconBefore={<Icon name="preferences" size="small" />}
            onClick={() => {
              setModal({ content })
            }}
          >
            Paragraf ayarları
          </Button>
        }
      />
    </Styled.ParagraphEmpty>
  )

  const renderComponentSettings = () => {
    if (modal) {
      const actions = [
        {
          text: 'Ayarları kaydet',
          onClick: () => {
            setModal(null)
            handleChangeProperties(modal)
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]

      return (
        <Modal
          title="Paragraf ayarları"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>
            Dashboard'a paragraf ekleyerek ve paragrafı zenginleştirerek
            görüntüleyebilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Editor
                apiKey="xngmkszheksy8uv8kyyilyy4vg0nag2y0hp1fgueq83qmn1o"
                value={(modal?.content as string) || ''}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
                onEditorChange={(content) => {
                  setModal({ ...modal, content })
                }}
              />
            </Column>
          </Row>
          <br />
        </Modal>
      )
    }
  }

  const renderParagraph = () => (
    <Styled.Paragraph dangerouslySetInnerHTML={{ __html: content || '' }} />
  )

  return (
    <div
      data-schema-id={id}
      className={['dnd-widget', designing && 'fake-draggable']
        .filter((e) => !!e)
        .join(' ')}
    >
      {designing ? (
        <React.Fragment>
          {renderComponentSettings()}
          <ComponentHeader
            componentEntry={entry}
            componentSchemaId={id as string}
          />
          {renderDesigner()}
        </React.Fragment>
      ) : (
        renderParagraph()
      )}
    </div>
  )
}

export default Paragraph
