import styled from 'styled-components'

export const DashboardDesignerFooter = styled.div`
  background: #fff;
  border-top: 1px solid #ececec;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  z-index: 101;

  button.primary-outline {
    background: rgba(179, 212, 255, 0.4);

    * {
      color: #0052cc;
    }

    &:hover {
      background: rgba(179, 212, 255, 0.6);
    }
  }
`

export const DashboardScreenEmptyContent = styled.div`
  max-width: 450px;
  margin: 0px auto;
  padding-bottom: 100px;
`

export const DashboardDesigner = styled.div``
