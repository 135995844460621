import React from 'react'
import DataBox from '@zera-admin/alice-data-box'
import EmptyState from '@zera-admin/empty-state'

import { LinkedinProps } from './types'

const Linkedin: React.FunctionComponent<LinkedinProps> = ({ data }) => {
  return data && Object.keys(data).length > 0 ? (
    <DataBox
      values={[
        {
          text: `${data.FirstName} ${data.LastName}`,
          title: 'İsim',
        },
        {
          text: data.Level ? data.Level : 'Bulunamadı',
          title: 'Seviye',
        },
        {
          text: data.SearchUrl ? data.SearchUrl : 'Bulunamadı',
          title: 'Arama',
        },
        {
          text: data.Position ? data.Position : 'Bulunamadı',
          title: 'Pozisyon',
        },
        {
          text: data.City ? data.City : 'Bulunamadı',
          title: 'Şehir',
        },
        {
          text: data.Department1 ? data.Department1 : 'Bulunamadı',
          title: 'Departman',
        },
        {
          text: data.Updated
            ? new Date(data.Updated).toLocaleString()
            : 'Bulunamadı',
          title: 'Updated',
        },
      ]}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Linkedin
