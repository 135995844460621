import * as React from 'react'

import { Query } from 'services/http/bi-tool/query'
import { Field } from 'services/http/bi-tool/field'
import { WidgetRowHandler } from 'services/http/bi-tool/widget'
import {
  SchemaNode,
  Container,
  ContainerEntry,
} from 'app/library/layout-builder'

export interface DragDropComponentProps {
  className?: string
  container?: Container
  designing?: boolean
  entry?: ContainerEntry
  hideOnMobile?: boolean
  hideOnTablet?: boolean
  hideRuntime?: boolean
  id?: string
  dashboard?: Query
  fields?: Field[]
  schemaNode?: SchemaNode
  style?: any
  index?: number
  reference?: {
    raw: { [key: string]: unknown }
    onClick: WidgetRowHandler
  }
}

export class DragDropComponent<
  T1 extends DragDropComponentProps,
  T2 = any
> extends React.Component<T1, T2> {
  checkDimension(dimension: 'mobile' | 'tablet' | 'desktop') {
    if (dimension === 'mobile') {
      return window.innerWidth > 240 && window.innerWidth <= 767
    } else if (dimension === 'tablet') {
      return window.innerWidth >= 768 && window.innerWidth <= 1199
    } else return true
  }
}
