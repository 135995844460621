import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { Big5Props } from './types'

const Big5: React.FunctionComponent<Big5Props> = ({ data }) => {
  const config: Props = {
    options: {
      chart: {
        id: 'big5Data',
        height: '33vh',
      },
      colors: ['#e08500', '#ff13a4', '#7e3f8e', '#2449cd', '#0f7301'],
      xaxis: {
        categories: [
          'Uyumluluk',
          'Dürüstlük',
          'Dışa dönüklük',
          'Nevrotiklik',
          'Açıklık',
        ],
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          distributed: true,
          barHeight: '80',
        },
      },
      legend: {
        show: true,
        position: 'top',
      },
    },
    series: [
      {
        name: 'Değer',
        data:
          data &&
          Object?.values(data)?.map((item: any) => {
            if (item?.prediction === 'True') {
              return item?.score.toFixed(2)
            } else {
              return (item?.score * -1).toFixed(2)
            }
          }),
      },
    ],
  }

  return data && Object.keys(data).length > 0 && data !== null ? (
    <Chart options={config.options} series={config.series} type="bar" />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Big5
