import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { getCountryPerceptions } from '../../../services/http/backoffice/country-perception/endpoints'
import backofficeDefinitionService from 'services/http/backoffice/definition/'

import { CreateBlueskyResponse } from './../../../services/http/backoffice/definition/types'

import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, Row, PageHeader } from '@zera-admin/page'
import DateTimePicker from '@zera-admin/datetime-picker'
import { RadioGroup } from '@zera-admin/radio'
import Button from '@zera-admin/button'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Checkbox from '@zera-admin/checkbox'
import Icon from '@zera-admin/icon'
import Form, {
  FormSection,
  useForm,
  FormHookSubmitProps,
  FormFooter,
  FormHeader,
} from '@zera-admin/form'
import { current30DaysBeforeString, currentDateString } from 'app/functions'
import { DateTimePickerHours } from 'app/shared/enums'

const Bluesky = () => {
  const [formValues, setFormValues] = useState<{
    countryPerceptions: Array<SelectOptionProps>
  }>({
    countryPerceptions: [],
  })
  const [flagcard] = useFlagcardContext()
  const [blackListWord, setBlackListWord] = useState<string>('')

  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  useEffect(() => {
    getCountryPerceptions()
      .then((res) => res.data.data)
      .then((res) =>
        setFormValues((state) => ({
          ...state,
          countryPerceptions: res.map((country: any) => {
            return { label: country?.description, value: country?.key }
          }),
        }))
      )
  }, [])

  const initialValues: CreateBlueskyResponse = {
    crawlingType: 'fetch',
    definitionType: 'keyword',
    definitionValue: '',
    countryPerception: 1,
    allowedLanguages: [],
    allowedDbs: [],
    useIncomingDefinition: false,
    environment: 'Alice',
    definitionBlackLists: [],
    sort: 'latest',
    since: '',
    until: '',
    mentions: '',
    author: '',
    language: '',
    domain: '',
    url: '',
    tag: '',
    periodicFetch: false,
    period: '',
    changeTimeIntervalByRefreshInterval: false,
    updateTimeParametersByTimeInterval: false,
    timeIntervalInHours: 0,
  }

  const form = useForm<CreateBlueskyResponse>({
    initialValues,
    onSubmit: (props) => handlerSubmit(props),
  })

  const handlerSubmit = (props: FormHookSubmitProps<CreateBlueskyResponse>) => {
    console.log(form.values)
    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createBluesky(form.values)
          .then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
            form.handleBatchUpdate(initialValues)
          })
          .catch((error) => {
            console.log(error)
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
      }
    }
  }

  const handleAddBlackListWord = () => {
    if (blackListWord.trim() !== '') {
      form.handleFieldChange('definitionBlackLists', [
        ...(form.values.definitionBlackLists || []),
        blackListWord.trim(),
      ])
      setBlackListWord('')
    }
  }

  const handleRemoveBlackListWord = (index: number) => {
    const updatedBlackLists = form.values.definitionBlackLists?.filter(
      (_, i) => i !== index
    )
    form.handleFieldChange('definitionBlackLists', updatedBlackLists)
  }

  const renderPageContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormHeader
            title="Bluesky Tanımlama Formu"
            description={
              <div style={{ marginBottom: '1.5rem' }}>
                <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
                <p style={{ margin: '0', color: '#A73121' }}>
                  Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
                </p>
              </div>
            }
          />
          <FormSection>
            <Row>
              <Column md={{ size: 3 }}>
                <RadioGroup
                  label="Crawling Tipi(*)"
                  name="crawlingType"
                  isRequired
                  options={[{ label: 'Fetch', value: 'fetch' }]}
                  value={form.values.crawlingType}
                  onChange={(e) =>
                    form.handleFieldChange(
                      'crawlingType',
                      e.currentTarget.value
                    )
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column md={{ size: 3 }}>
                <RadioGroup
                  label="Tanımlama Tipi(*)"
                  name="definitionType"
                  isRequired
                  options={[{ label: 'Keyword', value: 'keyword' }]}
                  value={form.values.definitionType}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'definitionType',
                      e.currentTarget.value
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="Tanımlama Değeri(*)"
                  name="definitionValue"
                  placeholder="Takip edilecek kelime giriniz."
                  isRequired
                  value={form.values.definitionValue}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'definitionValue',
                      e.currentTarget.value
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Algı Seçiniz(*)"
                  name="countryPerception"
                  placeholder="Seçim yapın"
                  options={formValues.countryPerceptions}
                  description="*Bir algı seçiniz."
                  defaultValue={
                    formValues.countryPerceptions.find(
                      (option) => option.label === 'Türkiye'
                    ) || { label: 'Türkiye', value: 1 }
                  }
                  value={formValues.countryPerceptions.find(
                    (option) => option.value === form.values.countryPerception
                  )}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'countryPerception',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row>
            {/* <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Dil Seçiniz"
                  name="allowedLanguages"
                  description="*İzin verilen diller listesi"
                  placeholder="Seçim yapın"
                  isMultiple
                  options={[
                    { label: 'Turkish', value: 'Turkish' },
                    { label: 'English', value: 'English' },
                  ]}
                  value={form.values.allowedLanguages?.map((lang) => {
                    return { label: lang, value: lang }
                  })}
                  onChange={(e) => {
                    const value = e as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedLanguages',
                      value.length > 0
                        ? value.map((lang) => lang.value)
                        : undefined
                    )
                  }}
                />
              </Column>
            </Row> */}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Database Seçimi(*)"
                  name="allowedDbs"
                  description="*Çekilen verilerin işlendikten sonra hangi db 'lere kaydedileceğini belirtir."
                  placeholder="Seçim yapın"
                  isMultiple
                  options={[
                    { label: 'Oracle', value: 'oracle' },
                    // { label: 'Druid', value: 'druid' },
                    // { label: 'Neo4j', value: 'neo4j' },
                  ]}
                  value={form.values.allowedDbs?.map((db) => ({
                    label: db,
                    value: db,
                  }))}
                  onChange={(e) => {
                    const value = e as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedDbs',
                      value.length > 0 ? value.map((db) => db.value) : undefined
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 4 }}>
                <Input
                  name="blackListWord"
                  label="Tanımlama Kara Listesi"
                  placeholder="Bir kara liste kelimesi giriniz"
                  description="Bir kara liste kelimesi giriniz"
                  value={blackListWord}
                  onChange={(props) => {
                    setBlackListWord(props.currentTarget.value)
                  }}
                />
              </Column>
              <Column xs={{ size: 4 }}>
                <Button
                  onClick={handleAddBlackListWord}
                  appearance="primary"
                  children="Ekle"
                  style={{ marginTop: '16px' }}
                />
              </Column>
            </Row>
            {form.values.definitionBlackLists?.map((word, index) => (
              <Row key={index}>
                <Column xs={{ size: 4 }}>{word}</Column>
                <Column>
                  <Button
                    onClick={() => handleRemoveBlackListWord(index)}
                    appearance="error"
                    children="Kaldır"
                    style={{ marginTop: '16px' }}
                  />
                </Column>
              </Row>
            ))}
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  label="Bu tanımlamadan gelen postlar direkt olarak bu definition ile işaretlensin mi işaretlenmesin mi?"
                  name="useIncomingDefinition"
                  isChecked={form.values.useIncomingDefinition}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      e.currentTarget.checked
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                {/* <Input
                  label="Sıralama"
                  name="sort"
                  placeholder="Sort giriniz."
                  value={form.values.sort}
                  onChange={(e) => {
                    form.handleFieldChange('sort', e.currentTarget.value)
                  }}
                /> */}
                <Select
                  label="Sıralama"
                  name="sort"
                  placeholder="Seçim yapın"
                  description='*Sıralama tipi seçiniz. "En Yeni" veya "Trend" seçeneklerinden birini seçiniz.'
                  options={[
                    { label: 'En Yeni', value: 'latest' },
                    { label: 'Trend', value: 'top' },
                  ]}
                  value={{
                    label: form.values.sort === 'latest' ? 'En Yeni' : 'Trend',
                    value: form.values.sort,
                  }}
                  onChange={(e) => {
                    const value = e as SelectOptionProps
                    form.handleFieldChange('sort', value.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <DateTimePicker
                  type="date"
                  label="Seçili Tarihten"
                  name="since"
                  locale="tr"
                  placeholder="Başlangıç tarihi seçin"
                  description="İşlenecek veri aralığının başlangıç tarihini seçiniz."
                  datePickerProps={{
                    maxDate: form?.values?.until && form?.values?.until,
                    minDate: '2006-03-21 03:00',
                  }}
                  value={
                    form?.values?.since
                      ? form?.values?.since
                      : current30DaysBeforeString()
                  }
                  onChange={(props) => {
                    form.handleFieldChange('since', props)
                  }}
                  times={hoursArray}
                />
              </Column>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <DateTimePicker
                  type="date"
                  label="Seçili Tarihe"
                  name="until"
                  locale="tr"
                  description="İşlenecek veri aralığının bitiş tarihini seçiniz."
                  placeholder="Bitiş tarihi seçin"
                  value={
                    form?.values?.until
                      ? form?.values?.until
                      : currentDateString()
                  }
                  onChange={(props) => {
                    form.handleFieldChange('until', props)
                  }}
                  datePickerProps={{
                    minDate: form?.values?.since
                      ? form?.values?.since
                      : '2006-03-21 03:00',
                    maxDate: currentDateString(),
                  }}
                  times={hoursArray}
                />
              </Column>
            </Row>
            <Row></Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Mentions"
                  name="mentions"
                  placeholder="Mentions giriniz."
                  description="Post içinde mention edilen kullanıcının BluSku kullanıcı adını giriniz."
                  value={form.values.mentions}
                  onChange={(e) => {
                    form.handleFieldChange('mentions', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Yazar"
                  name="author"
                  placeholder="Author giriniz."
                  description="Postu yazan kişinin BluSky kullanıcı adını giriniz."
                  value={form.values.author}
                  onChange={(e) => {
                    form.handleFieldChange('author', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Dil"
                  name="language"
                  placeholder="Dil giriniz."
                  value={form.values.language}
                  onChange={(e) => {
                    form.handleFieldChange('language', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Link"
                  name="domain"
                  description="Post içerisinde geçen, girilen domain&#39;e sahip linklerin filtrelemesini yapar. "
                  placeholder="Link giriniz."
                  value={form.values.domain}
                  onChange={(e) => {
                    form.handleFieldChange('domain', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="URL"
                  name="url"
                  placeholder="URL giriniz."
                  description="Post içerisinde geçen, girilen URL&#39;ye sahip linklerin filtrelemesini yapar."
                  value={form.values.url}
                  onChange={(e) => {
                    form.handleFieldChange('url', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  label="Tag"
                  name="tag"
                  placeholder="Tag giriniz."
                  description="Post içerisinde geçen, girilen tag&#39;e sahip linklerin filtrelemesini yapar.# olmadan giriniz."
                  value={form.values.tag}
                  onChange={(e) => {
                    form.handleFieldChange('tag', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Checkbox
                  label="Periyodik plarak çekilsin mi?"
                  name="periodicFetch"
                  isChecked={form.values.periodicFetch}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      e.currentTarget.checked
                    )
                  }}
                />
              </Column>
            </Row>
            {form.values.periodicFetch && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 3 }}>
                  <Input
                    label="Periyot"
                    name="period"
                    placeholder="Periyot giriniz."
                    value={form.values.period}
                    onChange={(e) => {
                      form.handleFieldChange('period', e.currentTarget.value)
                    }}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Checkbox
                  label="Zaman Aralığı Yenileme Aralığına Göre Değiştirilsin mi?"
                  name="changeTimeIntervalByRefreshInterval"
                  isChecked={form.values.changeTimeIntervalByRefreshInterval}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'changeTimeIntervalByRefreshInterval',
                      e.currentTarget.checked
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Checkbox
                  label="Zaman Aralığına Göre Zaman Parametreleri Güncellensin mi?"
                  name="updateTimeParametersByTimeInterval"
                  isChecked={form.values.updateTimeParametersByTimeInterval}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'updateTimeParametersByTimeInterval',
                      e.currentTarget.checked
                    )
                  }}
                />
              </Column>
            </Row>
            {form.values.updateTimeParametersByTimeInterval && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 3 }}>
                  <Input
                    type="number"
                    label="Saat Cinsinden Zaman Aralığı"
                    name="timeIntervalInHours"
                    placeholder="Time Interval In Hours giriniz."
                    value={form.values.timeIntervalInHours}
                    onChange={(e) => {
                      form.handleFieldChange(
                        'timeIntervalInHours',
                        e.currentTarget.value
                      )
                    }}
                  />
                </Column>
              </Row>
            )}
          </FormSection>
          <FormFooter align="start">
            <Button
              type="submit"
              isDisabled={
                form.values.definitionValue === '' ||
                form.values.crawlingType === '' ||
                form.values.definitionType === '' ||
                form.values.countryPerception === 0 ||
                form.values.definitionValue === '' ||
                (form.values.allowedDbs?.length ?? 0) === 0 ||
                (form.values.updateTimeParametersByTimeInterval &&
                  form.values.timeIntervalInHours === 0) ||
                (form.values.periodicFetch && form.values.period === '')
              }
              appearance="primary"
              children="Tanımlama Oluştur"
              iconBefore={<Icon name="add-item" />}
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem text="Tanımlama" href="/definition" />
              <BreadcrumbItem
                text="Tanımlama oluştur"
                href="/definition/create"
              />
              <BreadcrumbItem text="BlueSky" />
            </Breadcrumb>
          }
        />
        {renderPageContent()}
      </Page>
    </MainLayout>
  )
}

export default Bluesky
