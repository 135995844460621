import { colors } from '@atlaskit/theme'
import Icon from '@zera-admin/icon'
import Menu from '@zera-admin/menu'
import Page, { Container, Column, Row } from '@zera-admin/page'

import MainLayout from 'layouts/main'

import { AccountLayoutProps } from './types'

const AccountLayout: React.FunctionComponent<AccountLayoutProps> = ({
  children,
  header,
  isLoading,
}) => {
  const menu: any = [
    {
      menugroup: [
        {
          description: 'Kişisel bilgilerinizi güncelleyin',
          href: '/account/profile',
          iconBefore: <Icon name="edit" />,
          text: 'Profil',
        },
        {
          description: 'Şifrenizi güncelleyin',
          href: '/account/password',
          iconBefore: <Icon name="lock" />,
          text: 'Şifre güncelle',
        },
      ],
      title: 'Kişisel bilgilerim',
      type: 'section',
    },
  ]

  return (
    <MainLayout isLoading={isLoading}>
      <Container width="medium">
        <Page>
          {header}
          <Row xs={{ gutter: 'large' }}>
            <Column xs={{ size: 3 }}>
              <div style={{ backgroundColor: colors.N20 }}>
                <Menu data={menu} />
              </div>
            </Column>
            <Column xs={{ size: 9 }}>{children}</Column>
          </Row>
        </Page>
      </Container>
    </MainLayout>
  )
}

export default AccountLayout
