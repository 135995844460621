import React, { useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import Button, { ButtonGroup } from '@zera-admin/button'
import { Column, Row } from '@zera-admin/page'
import Drawer from '@zera-admin/drawer'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Icon from '@zera-admin/icon'
import { string } from '@zera-admin/helpers'

import { Field } from 'services/http/bi-tool/field'
import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'

import { DATE_FORMATS, DATE_LOCALES, NUMBER_FORMATS } from './constants'
import * as Styled from './TableDefinitions.styled'
import {
  TableVisualizationColumn,
  TableVisualizationDateFormat,
  TableVisualizationNumberFormat,
} from '../types'
import { TableDefinitionsProps } from './types'

const TableDefinitions: React.FunctionComponent<TableDefinitionsProps> = ({
  aggregations = [],
  fields = [],
  groups = [],
  options,
  onChange,
}) => {
  const [activeField, setActiveField] =
    useState<TableVisualizationColumn | null>()
  const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
    getFieldOptions(fields)
  )

  const handleChangeFieldInput = (value: string) => {
    const option = {
      label: value,
      value,
    }
    const index = fieldOptions.findIndex(
      (reference) => reference.value === parseInt(option.value)
    )
    let optionsInstance = [...fieldOptions]

    if (value && index === -1) {
      optionsInstance[0] = option
    } else {
      optionsInstance = getFieldOptions(fields)
    }

    setFieldOptions(optionsInstance)
  }

  const handleInsertField = (value: Field) => {
    const result = [
      ...(options.columns || []),
      {
        id: value.field,
        name: value.field,
        alias: value.alias,
        type: value.type,
      },
    ]

    onChange({ ...options, columns: result })
  }

  const handleUpdateField = (key: string, value: string | object) => {
    const result = { ...activeField, [key]: value }

    setActiveField(result as TableVisualizationColumn)
  }

  const handleSaveFieldSettings = () => {
    const result = [...(options.columns || [])]
    const index = result.findIndex((item) => item.name === activeField?.name)

    if (index !== -1 && activeField) {
      result[index] = activeField

      onChange({ ...options, columns: result })
      setActiveField(null)
    }
  }

  const handleRemoveField = (name: string) => {
    const result = [...(options.columns || [])]

    const index = (options.columns || []).findIndex(
      (column) => column.name === name
    )

    if (index !== -1) {
      result.splice(index, 1)

      onChange({ ...options, columns: result })
    }
  }

  const renderFieldProperties = () => {
    const field = fields.find((value) => value.field === activeField?.name)

    if (field) {
      switch (field.type) {
        case 'number':
          return renderFieldNumberOptions()
        case 'date':
          return renderFieldDateOptions()
        default:
          return null
      }
    }
    return null
  }

  const renderFieldNumberOptions = () => {
    const options =
      (activeField?.format as TableVisualizationNumberFormat) || {}

    return (
      <Row
        xs={{ direction: 'column' }}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <Column>
          <Select
            name="style"
            label="Numaralandırma formatı"
            placeholder="Bir format seçiniz"
            options={NUMBER_FORMATS}
            value={
              options.style
                ? {
                    value: options.style,
                    label: NUMBER_FORMATS.find(
                      (format) => format.value === options.style
                    )?.label as string,
                  }
                : { label: 'Varsayılan', value: 'default' }
            }
            onChange={(props) =>
              handleUpdateField('format', {
                ...options,
                style: (props as SelectOptionProps).value as string,
              })
            }
          />
        </Column>
        <Column>
          <Input
            name="prefix"
            label="Ön ek"
            placeholder="Fiyat"
            value={options.prefix}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleUpdateField('format', {
                ...options,
                prefix: event.target.value,
              })
            }
          />
        </Column>
        <Column>
          <Input
            name="suffix"
            label="Son ek"
            placeholder="Türk lirası"
            value={options.suffix}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleUpdateField('format', {
                ...options,
                suffix: event.target.value,
              })
            }
          />
        </Column>
      </Row>
    )
  }

  const renderFieldDateOptions = () => {
    const options = (activeField?.format as TableVisualizationDateFormat) || {}

    return (
      <Row
        xs={{ direction: 'column' }}
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <Column>
          <Select
            name="style"
            label="Tarih / saat formatı"
            placeholder="Bir format seçiniz"
            options={DATE_FORMATS}
            value={
              options.style
                ? {
                    value: options.style,
                    label: DATE_FORMATS.find(
                      (format) => format.value === options.style
                    )?.label as string,
                  }
                : { label: 'Varsayılan', value: '' }
            }
            onChange={(props) =>
              handleUpdateField('format', {
                ...options,
                style: (props as SelectOptionProps).value as string,
              })
            }
          />
        </Column>
        <Column>
          <Select
            name="style"
            label="Tarih lokalizasyonu"
            placeholder="Bir lokalizasyon seçiniz"
            options={DATE_LOCALES}
            value={
              options.locale
                ? {
                    value: options.locale,
                    label: DATE_LOCALES.find(
                      (format) => format.value === options.locale
                    )?.label as string,
                  }
                : { label: 'Türkiye', value: 'tr' }
            }
            onChange={(props) =>
              handleUpdateField('format', {
                ...options,
                locale: (props as SelectOptionProps).value as string,
              })
            }
          />
        </Column>
      </Row>
    )
  }

  const renderFieldsDrawer = () => {
    if (!activeField) return null

    const actions = (
      <ButtonGroup>
        <Button appearance="primary" onClick={handleSaveFieldSettings}>
          Ayarları uygula
        </Button>
        <Button onClick={() => setActiveField(null)}>Kapat</Button>
      </ButtonGroup>
    )

    return (
      <Drawer
        actions={actions}
        isOpen={activeField ? true : false}
        onClose={() => setActiveField(null)}
        title={`${string.textify(activeField.name)} ayarları`}
        width="narrow"
        description="Aşağıdan geçerli sütuna ait özellikleri güncelleyebilirsiniz."
      >
        <Row
          xs={{ direction: 'column' }}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <Column>
            <Input
              name="name"
              label="Alan adı"
              value={activeField?.name}
              readOnly
            />
          </Column>
          <Column>
            <Input
              name="alias"
              label="Sütun görünüm adı"
              placeholder="Ziyaretçi sayısı"
              value={activeField?.alias}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleUpdateField('alias', event.target.value)
              }
            />
          </Column>
        </Row>

        {renderFieldProperties()}
      </Drawer>
    )
  }

  const renderTableFields = () => {
    const tableFields = fields.filter(
      (value) =>
        !(options.columns || []).find((column) => column.name === value.field)
    )

    if (aggregations.length || groups.length) return null

    return (
      <React.Fragment>
        <h5>Yeni sütun ekle ({10 - (options.columns?.length || 0)})</h5>
        {!tableFields || !tableFields.length ? (
          <p>
            Tablo üzerinde herhangi bir alan bulunamadı. Tüm alanları seçmiş
            olabilirsiniz.
          </p>
        ) : (
          <Styled.WidgetSettingsFields>
            <Row xs={{ gutter: 'small', direction: 'column' }}>
              <Column>
                <Select
                  name="new-column"
                  options={fieldOptions}
                  onInputChange={handleChangeFieldInput}
                  onChange={(props) => {
                    if ((options.columns?.length as number) < 10) {
                      const option = (props as SelectOptionProps).value
                      const field = tableFields.find(
                        (value) => value.field === option
                      ) as Field

                      if (field) {
                        handleInsertField(field)
                      } else {
                        handleInsertField({
                          field: option.toString(),
                          type: 'string',
                        })
                      }
                    }
                  }}
                  value={{ label: '', value: '' }}
                  placeholder="Ekleyeceğiniz sütunu seçin"
                />
              </Column>
            </Row>
          </Styled.WidgetSettingsFields>
        )}
      </React.Fragment>
    )
  }

  const renderSelectedFields = () => {
    const fields = options?.columns || []

    return (
      <React.Fragment>
        <h5>Gösterilecek sütunlar (Maksimum 10)</h5>
        {!fields || !fields.length ? (
          <p>
            Seçilmiş bir sütun bulunamadı. Alt taraftan tablo alanları üzerinden
            seçebilirsiniz.
          </p>
        ) : (
          <Styled.WidgetSettingsFields>
            <ReactSortable
              direction="vertical"
              animation={150}
              swapThreshold={0.75}
              list={fields || []}
              setList={(columns: TableVisualizationColumn[]) =>
                onChange({ ...options, columns })
              }
            >
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <Styled.WidgetSettingsField isSelected={true}>
                    {string.textify(field.name)}

                    <ButtonGroup className="button-group">
                      <Button
                        onClick={() =>
                          setActiveField(
                            activeField?.name === field.name ? null : field
                          )
                        }
                        size="small"
                        appearance="subtle"
                      >
                        <Icon name="settings" size="small" />
                      </Button>
                      <Button
                        onClick={() => handleRemoveField(field.name)}
                        size="small"
                        appearance="subtle"
                      >
                        <Icon name="cross" size="small" />
                      </Button>
                    </ButtonGroup>
                  </Styled.WidgetSettingsField>
                </React.Fragment>
              ))}
            </ReactSortable>
            {renderFieldsDrawer()}
          </Styled.WidgetSettingsFields>
        )}
      </React.Fragment>
    )
  }

  const renderOptions = () => {
    return (
      <React.Fragment>
        {renderSelectedFields()}
        {renderTableFields()}
      </React.Fragment>
    )
  }

  return renderOptions()
}

export default TableDefinitions
