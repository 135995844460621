import React from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import {
  ContainerEntry,
  SchemaObject,
  useSchemaService,
} from 'app/library/layout-builder'
import { DESIGNER_COMPONENTS } from 'app/shared/designer'

interface ComponentHeaderProps {
  componentEntry?: ContainerEntry
  componentIsColumn?: any
  componentIsDefault?: boolean
  componentIsNotEditable?: boolean
  componentSchemaId: string
  componentSize?: string | number
  componentType?: string
  designSchema?: SchemaObject[]
  onClone?: () => void
  onEdit?: () => void
  titleRender?: () => React.ReactNode
}

const ComponentHeader: React.FunctionComponent<ComponentHeaderProps> = (
  props
) => {
  const schemaService = useSchemaService()
  const getComponentDefinition = () => {
    return DESIGNER_COMPONENTS.find(
      (x) => x.schema === props.componentEntry?.name
    )
  }

  const onChangeProperty = (name: string, value: any) => {
    schemaService.changeProperty(props.componentSchemaId, name, value)
  }

  const onRemove = () => {
    schemaService.removeNode(props.componentSchemaId)
  }

  const renderComponentHeader = () => {
    if (props.componentIsDefault) return null

    return (
      <div className="component-header row">
        <div>
          <ButtonGroup>
            <Button
              title="Sil"
              onClick={() => onRemove()}
              size="small"
              iconBefore={<Icon name="cross" size="small" />}
            />
            {props.componentIsColumn && (
              <Button
                title="Sütun'u Küçült"
                onClick={() =>
                  onChangeProperty(
                    'sizes',
                    props.componentSize &&
                      (props.componentSize as number) <= 12 &&
                      (props.componentSize as number) > 1
                      ? {
                          lg: parseInt(props.componentSize as string) - 1,
                          xs: 12,
                          sm: 12,
                          md: 12,
                        }
                      : { lg: 1, xs: 1, sm: 1, md: 1, xl: 1 }
                  )
                }
                className="decrase-column"
                size="small"
                iconBefore={<Icon name="arrow-left" />}
              />
            )}
            {props.componentIsColumn && (
              <Button
                onClick={() =>
                  onChangeProperty(
                    'sizes',
                    props.componentSize &&
                      (props.componentSize as number) >= 1 &&
                      (props.componentSize as number) < 12
                      ? {
                          lg: parseInt(props.componentSize as string) + 1,
                          xs: 12,
                          sm: 12,
                          md: 12,
                        }
                      : { lg: 12, xs: 12, sm: 12, md: 12, xl: 12 }
                  )
                }
                title="Sütun'u Büyült"
                className="increase-column"
                size="small"
                iconBefore={<Icon name="arrow-right" />}
              />
            )}
            {props.componentType === 'widget' && (
              <Button
                title="Klonla"
                onClick={() => (props.onClone ? props.onClone() : {})}
                size="small"
                iconBefore={<Icon name="copy" size="small" />}
              />
            )}
            {props.onEdit && (
              <Button
                title="Düzenle"
                onClick={() => (props.onEdit ? props.onEdit() : {})}
                size="small"
                iconBefore={<Icon name="edit" size="small" />}
              />
            )}
          </ButtonGroup>
        </div>
        <div style={{ flex: 1 }}>
          <div className="title">
            {props.titleRender
              ? props.titleRender()
              : getComponentDefinition()?.name}
          </div>
        </div>
      </div>
    )
  }

  return renderComponentHeader()
}
export default ComponentHeader
