import React from 'react'

import InfoCard from '../report-card'
import { reportCardGeneralDataProps } from './types'

import TwitterIcon from '../../../../../assets/images/twitter-new-logo-dark.png'

const ReportCardGeneralData: React.FunctionComponent<reportCardGeneralDataProps> =
  ({ data }) => {
    return (
      <InfoCard icon={TwitterIcon} title="Genel Veriler">
        <table className="info-card-table">
          <tbody>
            <tr>
              <td className="edit">Toplam Post</td>
              <td>{data?.tweetCount.toLocaleString('tr-TR')}</td>
            </tr>
            <tr>
              <td className="edit">Kullanıcı Sayısı</td>
              <td>{data?.userCount.toLocaleString('tr-TR')}</td>
            </tr>
            <tr>
              <td className="edit">Bahsedilen Hesap</td>
              <td>{data?.numberOfAccountsMentioned.toLocaleString('tr-TR')}</td>
            </tr>
            <tr>
              <td className="edit">Toplam Hashtag</td>
              <td>{data?.hashtagCount.toLocaleString('tr-TR')}</td>
            </tr>
            <tr>
              <td className="edit"> Lokasyon</td>
              <td>{data?.locationCount.toLocaleString('tr-TR')}</td>
            </tr>
          </tbody>
        </table>
      </InfoCard>
    )
  }
export default ReportCardGeneralData
