import React from 'react'
import { Column, Row } from '@zera-admin/page'
import Input from '@zera-admin/input'
import TimeRangePicker from '@zera-admin/alice-time-range-picker'
import Label from '@zera-admin/label'
import Switch from '@zera-admin/switch'

import { FilterTabProps } from '../types'

const GeneralTab: React.FunctionComponent<FilterTabProps> = ({
  values,
  onChange,
  operation,
}) => {
  const renderGeneralTab = () => {
    return (
      <React.Fragment>
        <Row xs={{ direction: 'column' }}>
          <Column xs={{ size: 12 }}>
            <p>
              Aşağıdan limitasyon gibi değerler seçerek, verilerinizi hızlıca
              filtreleyebilirsiniz.
            </p>
          </Column>
          {operation === 'dashboard-widget' && (
            <Column>
              <Label>Zaman aralığı</Label>
              <TimeRangePicker
                defaultValues={values.interval}
                onChange={(interval) => onChange({ ...values, interval })}
              />
            </Column>
          )}
          <Column>
            <Input
              name="limit"
              label="Veri limiti"
              placeholder="Bir değer giriniz"
              type="number"
              value={values.limit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange({
                  ...values,
                  limit: parseInt(event.target.value || '1'),
                })
              }
              max={1000}
              min={1}
            />
          </Column>
        </Row>
        {operation !== 'dashboard' && (
          <Row xs={{ gutter: 'none' }} className="drawer-safearea">
            <Column xs={{ size: 6 }}>
              <Switch
                name="distinct"
                label="Benzersiz verilere göre sorgulansın mı?"
                isChecked={values.distinct}
                onChange={(event) =>
                  onChange({
                    ...values,
                    distinct: event.target.checked,
                  })
                }
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Switch
                name="latest"
                label="En son gelen veriye göre sorgulansın mı?"
                isChecked={values.latest}
                onChange={(event) =>
                  onChange({
                    ...values,
                    latest: event.target.checked,
                  })
                }
              />
            </Column>
          </Row>
        )}
      </React.Fragment>
    )
  }

  return renderGeneralTab()
}

export default GeneralTab
