import { uid } from '@zera-admin/utils'
import {
  ConditionBuilderValue,
  ConditionRuleItemProps,
} from '@zera-admin/condition-builder'

import { getIntervalPeriod } from 'app/functions'

import { getUserScreenNameList, json } from './endpoints'
import { mapQueryWhereForRequest } from './mappers'
import { QueryCriteriaInterval } from './enums'
import { QueryRawInquerie, QueryRequest } from './types'

export const subquery = async (raw: QueryRequest) => {
  const where = { ...raw.where } as ConditionBuilderValue
  const queries = where?.rules?.filter(
    (rule) => rule.operator === 'query' && rule.value
  )

  if (queries?.length) {
    const criterias = await Promise.all(
      queries.map(async (property): Promise<ConditionRuleItemProps> => {
        const result = await json(
          JSON.parse((property?.value as string) || '') as QueryRequest
        )
        const criteria: ConditionRuleItemProps = {
          id: uid(),
          condition: 'or',
          type: 'group',
          rules: result.data?.rows?.map((row) => {
            const key = Object.keys(row).filter((key) => key !== 'rowCount')[0]
            const value = row[key]

            return {
              id: uid(),
              type: 'rule',
              field: property.field,
              operator: 'equal',
              value,
            }
          }),
        }

        return criteria
      })
    )

    where.rules = [...where.rules, ...criterias]
  }

  return { ...raw, where: mapQueryWhereForRequest(where) }
}

export const inquery = async (data: QueryRequest) => {
  const result = data

  if (result.raw?.inqueries?.length) {
    const rules = result.where?.rules || []
    const sentiment = rules.find((rule) => rule.field === 'sentiment')?.value
    const country = rules.find(
      (rule) =>
        rule.field === 'countryDetection' ||
        rule.field === 'userCountryDetection'
    )?.value
    const period = getIntervalPeriod(result.interval)
    let dateType = QueryCriteriaInterval.Daily

    if (period === 'monthly') {
      dateType = QueryCriteriaInterval.Monthly
    } else if (period === 'weekly') {
      dateType = QueryCriteriaInterval.Weekly
    }

    try {
      const queries = await Promise.all(
        result.raw.inqueries.map(
          async (property): Promise<QueryRawInquerie> => {
            const result = await getUserScreenNameList({
              ...property.params,
              sentiment,
              country,
              dateType,
            })

            return {
              ...property,
              values: result.data.data,
            }
          }
        )
      )

      result.raw.inqueries = queries
    } catch (err) {
      console.log(err)
    }
  }

  return result
}
