import React, { useEffect } from 'react'
import { cookie } from '@zera-admin/helpers'

import { SignOutProps } from './types'

const SignOut: React.FunctionComponent<SignOutProps> = ({ history }) => {
  useEffect(() => {
    cookie.remove('token')
    history.push('/signin')
  }, [history])

  return null
}

export default SignOut
