import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const WidgetSettingsFields = styled.div`
  margin-top: 12px;
  width: 100%;
`

export const WidgetSettingsField = styled.div<{ isSelected?: boolean }>`
  p {
    font-weight: 600;
    opacity: 0.6;
  }
  button {
    margin-left: auto;
    padding: 0px;

    &:hover {
      background: transparent;
    }

    &.button-subtle--delete {
      color: ${colors.red} !important;
      text-decoration: underline;
    }
  }
`
