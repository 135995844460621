import styled from 'styled-components'

export const PieChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & [type='pie'] {
    width: 700px;
  }
  .jpBdzv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .apexcharts-legend {
    max-height: 300px;
    min-width: 200px;
    overflow-y: auto;
    top: 110px !important;
    &-text {
      & div {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        & span:nth-child(2) {
          font-weight: 600;
        }
      }
    }
    &-series {
    }
    &-marker {
      display: none;
    }
    & div {
    }

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
  .pie-chart-container {
    margin-left: 0px;
  }

  .country-list-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;
    padding: 8px;
    min-width: 200px;
    border-radius: 6px;
    gap: 0.5rem;
    max-height: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    & div {
      display: flex;
      gap: 0.3rem;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid lightgray;
    }
    & button {
      background: none;
      border: none;
      cursor: pointer;
      &:hover {
        transition: 0.3s;
        color: red;
      }
    }
  }
`

export const PieInputContainer = styled.div`
  width: 95%;
  .new-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 2rem;
    width: 100%;

    & input {
      border: none;
      border-bottom: 1px solid black;
    }
    & button {
      background-color: #08a0e5;
      border: none;
      color: white;
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`
