import { request } from 'services/http/dataset/instance'

import type { FilterProps, Tweet, TweetResponse } from './types'

import { mapTweetForList, mapTweetForResponse } from './mappers'

export const get = (params?: FilterProps) =>
  request<TweetResponse>({
    method: 'get',
    params,
    url: 'tweet',
    mappers: {
      res: mapTweetForList,
    },
  })

export const getById = (id: number) =>
  request<Tweet>({
    method: 'get',
    url: `tweet/${id}`,
    mappers: {
      res: mapTweetForResponse,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `tweet/${id}`,
  })
