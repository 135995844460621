import { SentimentDataSet, SentimentDataSetResponse } from './types'

export const mapSentimentDataSetForRequest = (
  raw: SentimentDataSet
): unknown => ({
  source_id: raw.sourceId,
  language_code: raw.languageCode,
  semantic_score: raw.semanticScore,
  text: raw.text,
  entity_key: raw.entityKey,
})

export const mapSentimentDataSetForResponse = (raw: any): SentimentDataSet => ({
  id: raw.data.id,
  createdDate: raw.data.created_date,
  currentVersion: raw.data.current_version,
  languageCode: raw.data.language_code,
  semanticScore: raw.data.semantic_score,
  sourceId: raw.data.source_id,
  text: raw.data.text,
  trainingGroupId: raw.data.training_group_id,
  trainingGroupName: raw.data.training_group_name,
  userId: raw.data.user_id,
  sourceName: raw.data.source_name,
})

export const mapSentimentDataSetForList = (
  raw: any
): SentimentDataSetResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapSentimentDataSetForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
