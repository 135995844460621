import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import DateTimePicker from '@zera-admin/datetime-picker'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { validation } from 'app/functions'
import { dataSetType, runnigStatus, status } from 'app/shared/constants'
import { TrainingGroup } from 'services/http/dataset/training-group'

import { TrainingGroupFormProps } from './types'
import { DateTimePickerHours } from 'app/shared/enums'

const TrainingGroupForm: React.FunctionComponent<TrainingGroupFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
  operation,
}) => {
  const hoursArray: string[] = []
  for (const key in DateTimePickerHours) {
    if (typeof DateTimePickerHours[key] === 'string') {
      hoursArray.push(DateTimePickerHours[key])
    }
  }

  const validations = {
    dataSetType: yup.string().required('Veri seti tipi seçiniz'),
    languageCode: yup.string().required('Dil kodu girmelisiniz'),
    name: yup.string().required('Eğitim grubu adı girmelisiniz'),
    planningStartDate: yup.string().required('Planlanmış zaman seçiniz'),
    runningStatus:
      operation === 'update' && yup.string().required('Çalışma durumu seçiniz'),
  }

  const form = useForm<TrainingGroup>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = (props: FormHookSubmitProps<TrainingGroup>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Geçerli bir Eğitim grubu adı giriniz"
              errorMessage={form.errors.name}
              label="Eğitim grubu adı"
              name="name"
              placeholder="Örnek metin"
              value={form.values.name}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Input
              description="Geçerli bir dil kodu giriniz"
              errorMessage={form.errors.languageCode}
              label="Dil kodu"
              name="languageCode"
              placeholder="Örnek: tr, en, vs.."
              value={form.values.languageCode}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Select
              description="Geçerli bir veri seti tipi seçimi yapınız"
              errorMessage={form.errors.dataSetType}
              label="Veri seti tipi"
              name="dataSetType"
              options={dataSetType}
              value={dataSetType.find(
                (dataSetType) => dataSetType.value === form.values.dataSetType
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'dataSetType',
                  (props as SelectOptionProps).value
                )
              }
              placeholder="Seçim yapın"
            />
          </Column>
          {operation === 'update' && (
            <Column>
              <Select
                description="Geçerli bir çalışma durumu seçimi yapınız"
                errorMessage={form.errors.runningStatus}
                label="Çalışma durumu"
                name="runningStatus"
                options={runnigStatus}
                value={runnigStatus.find(
                  (runnigStatus) =>
                    runnigStatus.value === form.values.runningStatus
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'runningStatus',
                    (props as SelectOptionProps).value
                  )
                }
                placeholder="Seçim yapın"
              />
            </Column>
          )}
          {operation === 'update' && (
            <Column>
              <Select
                description="Geçerli bir durumu seçimi yapınız"
                errorMessage={form.errors.status}
                label="Durumu"
                name="status"
                options={status}
                value={status.find(
                  (status) => status.value === form.values.status
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'status',
                    (props as SelectOptionProps).value
                  )
                }
                placeholder="Seçim yapın"
              />
            </Column>
          )}
          <Column>
            <DateTimePicker
              description="Geçerli bir planlanacak zaman seçimi yapınız"
              errorMessage={form.errors.planningStartDate}
              label="Planlanmış zaman"
              locale="tr"
              name="planningStartDate"
              type="date"
              validMessage="Planlanan zaman seçildi"
              value={form.values.planningStartDate}
              onChange={(props) => {
                form.handleFieldChange(
                  'planningStartDate',
                  props.split('-').reverse().join('.')
                )
              }}
              times={hoursArray}
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default TrainingGroupForm
