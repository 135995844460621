import { request } from 'services/http/dataset/instance'

import type {
  CreateNamedEntityControlSetRequest,
  NamedEntityControlSet,
  NamedEntityControlSetResponse,
  UpdateNamedEntityControlSetRequest,
} from './types'

import {
  mapNamedEntityControlSetForList,
  mapNamedEntityControlSetForRequest,
  mapNamedEntityControlSetForResponse,
} from './mappers'

export const create = (data: CreateNamedEntityControlSetRequest) =>
  request<NamedEntityControlSet>({
    method: 'post',
    url: 'named-entity-control-set',
    data,
    mappers: {
      req: mapNamedEntityControlSetForRequest,
    },
  })

export const get = () =>
  request<NamedEntityControlSetResponse>({
    method: 'get',
    url: 'named-entity-control-set',
    mappers: {
      res: mapNamedEntityControlSetForList,
    },
  })

export const getById = (id: number) =>
  request<NamedEntityControlSet>({
    method: 'get',
    url: `named-entity-control-set/${id}`,
    mappers: {
      res: mapNamedEntityControlSetForResponse,
    },
  })

export const update = (data: UpdateNamedEntityControlSetRequest) =>
  request<NamedEntityControlSet>({
    method: 'put',
    url: `named-entity-control-set/${data.id}`,
    data,
    mappers: {
      req: mapNamedEntityControlSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `named-entity-control-set/${id}`,
  })
