import styled from 'styled-components'

export const EmptyCreateAlarmsContent = styled.div`
  max-width: 450px;
  margin: 0px auto;
  padding-bottom: 100px;
`

export const AlarmDesignerContainer = styled.div`
  .row-line {
    border-top: 1px solid lightgray;
  }
  .condition-builder {
    margin: 16px -16px 0px -16px;
  }
  .input-col {
    display: flex;
    gap: 0.4rem;
    padding-bottom: 1rem;
    &-btn {
      padding-top: 9px;
    }
  }
  .btn-container {
    position: fixed;
    bottom: 10%;
    left: 90%;
  }
  .fields-area {
    margin: 0px !important;
  }
  .alarm-score-container {
    margin-top: 2rem;
    margin-left: 0;
    border-top: 1px solid lightgray;
    &-title {
      & h5 {
        font-size: 1rem;
      }
      & p {
        font-size: 0.8rem;
        color: rgb(107, 119, 140);
        padding-bottom: 2rem;
      }
    }
  }
  .criteria-table-container {
    margin-top: 2rem;
  }

  .code-content-field {
    font-size: 12px;
    color: green;
  }
  .code-content-functions {
    color: orange;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .code-field {
    color: green;
  }
  .code-functions {
    color: orange;
  }

  .code-container-message {
    border-color: green;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 0px 2px;
  }
  .code-container-function {
    border-color: orange;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin: 0px 2px;
  }
`

export const TitleForm = styled.div`
  margin-top: 1rem;
  .alarm-name-btn {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }
`
