import styled from 'styled-components'

export const SocialCardContainer = styled.div`
  max-width: 140px;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  & img {
    width: 50px;
    height: 50px;
  }
  & span {
    font-size: 1rem;
  }
  & h5 {
    font-size: 0.9rem;
    font-weight: 700;
  }
`
