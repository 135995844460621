import { request } from '../instance'

import {
  mapUserForResponse,
  mapUserForChangePasswordRequest,
  mapUserForCreateRequest,
  mapUserForUpdateRequest,
  mapUserListForRequest,
  mapUserListForResponse,
} from './mappers'
import {
  UserUpdateRequest,
  UserListResponse,
  UserListRequest,
  UserCreateRequest,
  UserChangePasswordRequest,
  UserResponse,
  UserChangeForgotPasswordRequest,
} from './types'

export const create = (data: UserCreateRequest) =>
  request<UserResponse>({
    method: 'post',
    url: 'user',
    data,
    mappers: {
      req: mapUserForCreateRequest,
      res: mapUserForResponse,
    },
  })

export const get = (params?: UserListRequest) =>
  request<UserListResponse>({
    method: 'get',
    params,
    url: 'user',
    mappers: {
      req: mapUserListForRequest,
      res: mapUserListForResponse,
    },
  })

export const getById = (id: number) =>
  request<UserResponse>({
    method: 'get',
    url: `user/${id}`,
    mappers: {
      res: mapUserForResponse,
    },
  })

export const update = (data: UserUpdateRequest) =>
  request<UserResponse>({
    method: 'put',
    url: 'user',
    data,
    mappers: {
      req: mapUserForUpdateRequest,
    },
  })

export const changePassword = (data: UserChangePasswordRequest) =>
  request<boolean>({
    method: 'put',
    url: 'user/change-password',
    data,
    mappers: {
      req: mapUserForChangePasswordRequest,
    },
  })
export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `user/${id}`,
  })

export const forgotPassword = (userName: string) =>
  request<void>({
    method: 'post',
    url: 'user/forgot-password',
    data: { userName },
  })

export const changeForgotPassword = (data: UserChangeForgotPasswordRequest) =>
  request<void>({
    method: 'put',
    url: 'user/change-forgot-password',
    data,
  })
