import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { HotelVisualizationFields, HotelVisualizationOptions } from '../types'

export const generateHotelOptions = (
  options: HotelVisualizationOptions,
  query: Query,
  fields: Field[]
): HotelVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      fields: {
        ...result.fields,
        [group.field]: group.field as string,
      },
    }
  })

  return result
}

export const generateHotelVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as HotelVisualizationFields)

  return {
    type: 'hotel',
    options: {
      fields: {
        hotelName: options['hotelName'],
        hotelLink: options['hotelLink'],
        hotelDescription: options['hotelDescription'],
        mediaList: options['mediaList'],
        arrangement: options['arrangement'],
        adress: options['adress'],
        rating: options['rating'],
      },
    },
  }
}
