import styled from 'styled-components'

export const InfluentialPeoplesContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); */
`

export const PeopleCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 20px 48px;

  @media only screen and (max-width: 1520px) {
    grid-gap: 0;
  }

  .people-card-text {
    width: 65%;
    padding-left: 0px;
    @media (width >= 2880px) {
      width: 0% !important;
    }
  }
  .people-cards {
    width: 240px;
    display: flex;
    margin-top: 1rem;
  }
  .people-card-img {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (width >= 2880px) {
      width: 15%;
    }
  }
  .people-card-img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .people-card-twitter-name {
    display: flex;
    justify-content: space-between;
    button {
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
      opacity: 0.5;

      :hover {
        color: red;
        opacity: 1;
      }
    }
    span {
      font-weight: bold;
      font-size: 0.7rem;
      padding-bottom: 0.5vh;
    }
  }
  .people-card-twitter-url {
    margin-top: 0.6vh;
    span a {
      color: #07aeeb;
      text-decoration: underline;
      font-size: 0.7rem;
    }
  }
  .people-card-twitter-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span:nth-child(1) {
      padding-right: 3px;
      font-size: 0.7rem;

      span {
        font-size: 0.7rem;
        font-weight: bold;
      }
    }
    span:nth-child(2) {
      opacity: 0.5;
    }
    span:nth-child(3) {
      font-size: 0.7rem;
      padding-left: 3px;
      span {
        font-size: 0.7rem;
        font-weight: bold;
      }
    }
  }
  .influential-card-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 96%;

    .influent-height {
      width: 100%;
      height: 40px;
    }

    .influential-bg {
      position: relative;
      height: 180px;
      border-radius: 8px 8px 0px 0px;
      object-fit: cover;
    }

    @media only screen and (max-width: 1920px) {
      .influent-height {
        height: 30px;
      }

      .influential-bg {
        height: 140px;
      }
    }

    @media only screen and (max-width: 1520px) {
      width: 96%;
      margin-bottom: 15px;
      .influent-height {
        height: 30px;
      }

      .influential-bg {
        height: 125px;
      }
    }

    .influential-body {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      max-height: fit-content;

      .influential-header-bg {
        position: relative;
        background: #000;
        opacity: 0.8;
        width: 100%;
        border-radius: 8px 8px 0px 0px;
        z-index: 0;
        height: 100%;
        min-height: 90px;
      }
      /* @media only screen and (max-width: 1920px) {
        .influential-header-bg {
          height: 39%;
        }
      }

      @media only screen and (max-width: 1520px) {
        .influential-header-bg {
          height: 39%;
        }
      } */

      .influential-header {
        position: absolute;
        z-index: 1;
        width: calc(100% - 2rem);
        height: calc(28%);
        color: white;
        display: flex;
        border-radius: 8px 8px 0px 0px;
        padding: 0.3rem 1rem;
        gap: 0.5rem;
        background: #000000d6;

        @media only screen and (max-width: 1490px) {
          height: 24%;
        }

        .influential-header-info {
          position: absolute;
          display: flex;
          gap: 0.5rem;
          .influential-header-img-container {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: white;
            padding: 4px;

            @media only screen and (max-width: 1520px) {
              width: 40px;
              height: 40px;
              padding: 3px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .influential-header-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            button {
              background: none;
              border: none;
              &:hover {
                opacity: 0.5;
                cursor: pointer;
              }
            }
            span {
              &:nth-child(1) {
                color: white;
                font-weight: 600;
                font-size: 13px;
              }
              &:nth-child(2) {
                font-weight: 400;
                opacity: 0.8;
                font-size: 10px;
              }

              @media only screen and (max-width: 1520px) {
                &:nth-child(1) {
                  font-size: 12px;
                }
                &:nth-child(2) {
                  font-size: 9px;
                }
              }
            }
          }
        }
      }
      .influential-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        .x-container {
          position: absolute;
          border-radius: 50%;
          z-index: 1;
          left: 25px;
          top: 15px;

          @media only screen and (max-width: 1520px) {
            left: 10px;
          }

          /* @media only screen and (max-width: 1320px) {
            left: 10px;
          } */

          @media only screen and (max-width: 548px) {
            left: 3px;
          }

          img {
            border: 1px solid white;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            @media only screen and (max-width: 1520px) {
              width: 30px;
              height: 30px;
            }

            /* @media only screen and (max-width: 1520px) {
              width: 32px;
              height: 32px;
            } */
          }
        }
        .influential-counts {
          background-color: white;
          display: flex;
          gap: 0.3rem;
          width: 100%;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 0rem;
          .influential-fs-follow {
            font-size: 12px;
            @media only screen and (max-width: 1520px) {
              span {
                &:nth-child(1) {
                  font-size: 11px;
                }
                &:nth-child(2) {
                  font-size: 11px;
                }
              }
            }
          }

          .influential-fs {
            border-right: 0.5px solid lightgray;
            padding-right: 4px;

            span {
              &:nth-child(2) {
                font-size: 12px;
              }

              @media only screen and (max-width: 1520px) {
                &:nth-child(1) {
                  font-size: 11px;
                }
                &:nth-child(2) {
                  font-size: 11px;
                }
              }
            }
          }

          span {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            span {
              &:nth-child(1) {
                color: black;
                font-weight: 700;
                font-size: 12px;
              }
            }
          }
        }
        .influential-show {
          background-color: #000000;
          border-radius: 0px 0px 8px 8px;
          width: 100%;
          padding: 0.5rem 0rem;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            cursor: pointer;

            a {
              text-decoration: none;
              color: lightgray;
              font-size: 11px;
              cursor: pointer;

              &:hover {
                color: #45beff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .tick-button-container {
    position: absolute;
    z-index: 10;
    right: 5px;
    top: 5px;
  }
  .tick-button {
    background: none;
    border: none;
    position: relative;
    top: 0;
    right: 0;
    cursor: pointer;
    color: white;
    background-color: red;
    border-radius: 8px;
    padding: 4px 12px;
    margin-bottom: 4px;
    &:hover {
      transition: 0.6s;
      background-color: #bf0a13;
    }
  }
`

export const BtnContainer = styled.div`
  .btn-save {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    float: right;
    .fa-circle-check {
      color: green;
    }
    .fa-pen-to-square {
      color: #03618b;
    }
    i {
      font-size: 1.5rem;
    }
  }
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.print {
    align-items: center;
    align-self: center;
  }

  .edit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
      display: flex;
      gap: 2rem;
      align-items: center;

      &-input {
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
      }

      & input {
        padding: 0.5rem;
        border: none;
        border-bottom: 1px solid black;
      }

      & button {
        background-color: #08a0e5;
        border: none;
        color: white;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          transition: 0.3s;
          background-color: lightgreen;
        }
      }
    }
  }
`
