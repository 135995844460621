import { request } from 'services/http/backoffice/instance'

import type { NewsAlarmsListResponse } from './types'

import { mapNewsAlarmsListForList } from './mappers'

export const get = (
  pageIndex: number,
  pageSize: number,
  channelName: string | null,
  expiredAlarmDate?: string | number | null
) =>
  request<NewsAlarmsListResponse>({
    method: 'get',
    url: 'alarm/news-main-telegram',
    params: {
      PageIndex: pageIndex,
      PageSize: pageSize,
      ExpiredAlarmDate: expiredAlarmDate,
      ChannelName: channelName,
    },
    mappers: {
      res: mapNewsAlarmsListForList,
    },
  })

export const put = ({ id, ...rest }: any) => {
  return request({
    method: 'put',
    url: `alarm/news-main-telegram/${id}`,
    data: {
      ...rest,
    },
  })
}
