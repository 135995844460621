import React from 'react'
import DataBox from '@zera-admin/alice-data-box'
import EmptyState from '@zera-admin/empty-state'

import { FacebookProps } from './types'

const Facebook: React.FunctionComponent<FacebookProps> = ({ data }) => {
  return data && Object.keys(data).length > 0 ? (
    <DataBox
      values={[
        {
          text: `${data.FirstName} ${data.LastName}`,
          title: 'İsim',
        },
        {
          text: data.Email ? data.Email : 'Bulunamadı',
          title: 'E-Posta',
        },
        {
          text: data.Gender === 'female' ? 'Kadın' : 'Erkek',
          title: 'Cinsiyet',
        },
        {
          text: data.Phone ? data.Phone : 'Bulunamadı',
          title: 'Telefon',
        },
        {
          text: data.City ? data.City : 'Bulunamadı',
          title: 'Şehir',
        },
        {
          text: data.CreationTime
            ? new Date(data.CreationTime).toLocaleString()
            : 'Bulunamadı',
          title: 'Oluşturulma Tarihi',
        },
        {
          text: data.Country ? data.Country : 'Bulunamadı',
          title: 'Ülke',
        },
        {
          text: data.Location ? data.Location : 'Bulunamadı',
          title: 'Lokasyon',
        },
        {
          text: data.Birthday
            ? new Date(data.Birthday).toLocaleString()
            : 'Bulunamadı',
          title: 'Doğum tarihi',
        },
        {
          text: data.RelationshipStatus
            ? data.RelationshipStatus
            : 'Bulunamadı',
          title: 'İlişki durumu',
        },
        {
          text: data.Work ? data.Work : 'Bulunamadı',
          title: 'Work',
        },
      ]}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default Facebook
