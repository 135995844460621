import React from 'react'

import InfoCard from '../report-card'
import { MostTweetedLocationProps } from './types'
import TopicsIcon from './subject_icon.png'

const ReportSubject: React.FunctionComponent<MostTweetedLocationProps> = ({
  data,
}) => {
  return (
    <InfoCard icon={TopicsIcon} title="Konular">
      <table className="info-card-table">
        <thead>
          <tr>
            <th>Konular</th>
            <th>Post Sayıları</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((topic: any) => (
            <tr>
              <td>{topic.absKeywords}</td>
              <td className="center">
                {topic.rowCount.toLocaleString('tr-TR')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </InfoCard>
  )
}
export default ReportSubject
