import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'
import EmptyState from '@zera-admin/empty-state'

import { getMonths } from 'app/functions'

import { TweetsByTimeAfterProps } from './types'

const TweetsByTimeAfter: React.FunctionComponent<TweetsByTimeAfterProps> = ({
  data,
}) => {
  const tweetsByTimeAfter: Props = {
    options: {
      chart: {
        id: 'tweetsByTimeAfter',
      },
      colors: [colors.B200],
      xaxis: {
        categories: data?.map((item: any, index: number) =>
          getMonths(index + 1)
        ),
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          distributed: true,
        },
      },
      tooltip: {},
      legend: {
        show: false,
      },
    },
    series: [
      {
        name: 'Attığı tweet sayısı',
        data: data?.map((item: any) => item?.tweetCount.toFixed(2)),
      },
    ],
  }

  return data && data.length > 0 ? (
    <Chart
      options={tweetsByTimeAfter.options}
      series={tweetsByTimeAfter.series}
      type="bar"
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default TweetsByTimeAfter
