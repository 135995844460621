import React, { useEffect, useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import * as Styled from './CreatePageForm.styled'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import profileImage from '../instagram-card/images/default-user.png'
import { pageTypeOptions, countryOptions } from '../../widgets/enums'
import Form, {
  FormFooter,
  FormHeader,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import {
  addPage,
  getAgendaReportTweet,
  getInfluentialUser,
  getAgendaReportYoutube,
  getAgendaReportTiktok,
  getAgendaReportNews,
  getAgendaReportInstagram,
  getAgendaReportReddit,
  getAgendaReportTelegram,
  getAgendaReportEksiSozluk,
  getAgendaReportMastodon,
  getAgendaReportQuora,
} from 'services/http/report/agenda-report-4/endpoint'
import { Account, Data2, Tweet } from '../../widgets/types'
import { useFlagcardContext } from 'app/contexts/flagcard'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import { ProfilImage } from 'pages/person-report/report-detail/pdf/wikipedia/Wikipedia.styled'

const CreatePageForm = ({
  metaData,
  onClose,
}: {
  onClose: () => void
  metaData: {
    flagShortCode?: string
    PageId: string
    ReportType: string
    GroupId: string
    PageType: string
    pageNumber: number
  }
}) => {
  const [flagcard] = useFlagcardContext()
  const [id, setId] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const form = useForm<{
    title: string
    pageNumber: number
    pageType:
      | 'tikTokPosts'
      | 'youtubePosts'
      | 'TelegramPosts'
      | 'InstagramPosts'
      | 'EksiSozlukPosts'
      | 'RedditPosts'
      | 'QuoraPosts'
      | 'MastodonPosts'
      | 'tweetsPagesByCountry'
      | 'influentialAccountTweetPagesByCountry'
      | 'influentialAccountPagesByCountry'
      | 'newsPagesByCountry'
    data: Data2
  }>({
    initialValues: {
      title: '',
      pageNumber: metaData.pageNumber,
      pageType: '',
      data: {},
    },
    onSubmit: (props) => handleSubmit(props),
  })

  useEffect(() => {
    form.handleFieldChange('data', {})
    setId('')
  }, [form.values.pageType])

  const handleSubmit = (
    props: FormHookSubmitProps<{
      title: string
      pageNumber: number
      pageType:
        | 'tikTokPosts'
        | 'youtubePosts'
        | 'TelegramPosts'
        | 'InstagramPosts'
        | 'RedditPosts'
        | 'EksiSozlukPosts'
        | 'QuoraPosts'
        | 'MastodonPosts'
        | 'tweetsPagesByCountry'
        | 'influentialAccountTweetPagesByCountry'
        | 'influentialAccountPagesByCountry'
        | 'newsPagesByCountry'
      data: Data2
    }>
  ) => {
    if (!props.errors) {
      if (form.values) {
        console.log(metaData.ReportType)
        addPage({
          groupId: metaData.GroupId,
          reportType: metaData.ReportType === 'Edited' ? 2 : 1,
          pageType: form.values.pageType,
          pageNumber: form.values.pageNumber,
          title: form.values.title,
          data: form.values.data,
        })
          .then(() => {
            setId('')
            flagcard.add({
              appearance: 'success',
              title: 'Sayfa eklendi',
              children: 'Sayfa başarıyla eklendi.',
            })
            form.handleReset()
            form.handleFieldChange('pageNumber', 0)
            form.handleFieldChange('pageType', '')
            form.handleFieldChange('data', {})
            form.handleFieldChange('title', '')
            onClose()

            setTimeout(() => {
              window.location.reload()
            }, 100)
          })
          .catch((err) => {
            console.log(err)
            flagcard.add({
              appearance: 'error',
              title: 'Sayfa eklenemedi',
              children: 'Sayfa eklenirken bir hata oluştu.',
            })
          })
      }
    }
  }

  return (
    <Styled.FormContainer>
      <Form onSubmit={form.handleSubmit}>
        <FormHeader
          title="Sayfa Ekle"
          description={
            <div style={{ marginBottom: '1.5rem' }}>
              <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
              <p style={{ margin: '0', color: '#A73121' }}>
                Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
              </p>
            </div>
          }
        />
        <FormSection>
          <Row>
            <Column xs={{ size: 6 }}>
              <Input
                value={form.values.title}
                onChange={(e) =>
                  form.handleFieldChange('title', e.currentTarget.value)
                }
                isRequired
                label="*Başlık"
                placeholder="Başlık giriniz"
                name="title"
                type="text"
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Input
                value={form.values.pageNumber}
                onChange={(e) =>
                  form.handleFieldChange(
                    'pageNumber',
                    Number(e.currentTarget.value)
                  )
                }
                placeholder="Sayfa numarası giriniz"
                isRequired
                label="*Sayfa Numarası"
                name="pageNumber"
                type="number"
              />
            </Column>
            <Column xs={{ size: 6 }}>
              <Select
                label="*Sayfa Tipi"
                name="pageType"
                placeholder="Sayfa tipi seçiniz"
                value={pageTypeOptions.find(
                  (item) => item.value === form.values.pageType
                )}
                onChange={(e) =>
                  form.handleFieldChange(
                    'pageType',
                    (e as SelectOptionProps).value
                  )
                }
                options={pageTypeOptions}
              />
            </Column>
          </Row>
          {form.values.pageType === 'youtubePosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Video ID giriniz"
                  isRequired
                  label="*Video ID"
                  name="videoId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Youtube Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)

                    getAgendaReportYoutube({
                      GroupId: metaData.GroupId,
                      VideoId: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Video bulundu',
                          children: 'Video eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Video bulunamadı',
                          children: 'Video veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={ProfilImage}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />

                        <p>{form.values.data.Post.ChannelName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}

          {form.values.pageType === 'MastodonPosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Post URL giriniz"
                  isRequired
                  label="*Post URL"
                  name="postId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Mastodon Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportMastodon({
                      GroupId: metaData.GroupId,
                      PostUri: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Post bulundu',
                          children: 'Post eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Post bulunamadı',
                          children: 'Post veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={profileImage}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />

                        <p>{form.values.data.Post.ChannelName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}

          {form.values.pageType === 'QuoraPosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Post URL giriniz"
                  isRequired
                  label="*Post URL"
                  name="postId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Quora Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportQuora({
                      GroupId: metaData.GroupId,
                      PostUrl: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Post bulundu',
                          children: 'Post eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Post bulunamadı',
                          children: 'Post veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={profileImage}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />

                        <p>
                          {form.values.data.Post.PageName
                            ? form.values.data.Post.PageName
                            : form.values.data.Post.UserFullName}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}

          {form.values.pageType === 'EksiSozlukPosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Entry ID giriniz"
                  isRequired
                  label="*Entry ID"
                  name="entryId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Eksi Sözlük Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportEksiSozluk({
                      GroupId: metaData.GroupId,
                      EntryId: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Entry bulundu',
                          children: 'Entry eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Entry bulunamadı',
                          children: 'Entry veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={profileImage}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />

                        <p>{form.values.data.Post.ChannelName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}

          {form.values.pageType === 'InstagramPosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Post Short Code giriniz"
                  isRequired
                  label="*Post Short Code"
                  name="postId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Instagram Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportInstagram({
                      GroupId: metaData.GroupId,
                      ShortCode: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Post bulundu',
                          children: 'Post eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Post bulunamadı',
                          children: 'Post veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={profileImage}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />

                        <p>{form.values.data.Post.ChannelName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}

          {form.values.pageType === 'RedditPosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Post Url giriniz"
                  isRequired
                  label="*Post Url"
                  name="postId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Reddit Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportReddit({
                      GroupId: metaData.GroupId,
                      Url: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Post bulundu',
                          children: 'Post eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Post bulunamadı',
                          children: 'Post veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={profileImage}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />

                        <p>{form.values.data.Post.ChannelName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}

          {form.values.pageType === 'TelegramPosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Post URL giriniz"
                  isRequired
                  label="*Post URL"
                  name="postId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Telegram Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportTelegram({
                      GroupId: metaData.GroupId,
                      Url: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Post bulundu',
                          children: 'Post eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Post bulunamadı',
                          children: 'Post veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={profileImage}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />

                        <p>{form.values.data.Post.ChannelName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}

          {form.values.pageType === 'tikTokPosts' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Video ID giriniz"
                  isRequired
                  label="*Video ID"
                  name="videoId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="TikTok Verilerini Getir"
                  isDisabled={form.values.data.Post ? true : false}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportTiktok({
                      GroupId: metaData.GroupId,
                      VideoId: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Post: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Video bulundu',
                          children: 'Video eklendi.',
                        })
                      })
                      .catch((err) => {
                        setId('')
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Video bulunamadı',
                          children: 'Video veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Post && (
                    <div className="post">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Post: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={form.values.data.Post.ChannelProfilePictureUrl}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                          }}
                        />

                        <p>{form.values.data.Post.ChannelName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}
          {form.values.pageType === 'tweetsPagesByCountry' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Tweet ID giriniz"
                  isRequired
                  label="*Tweet ID"
                  name="tweetId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Tweet Verilerini Getir"
                  isDisabled={form.values.data.Tweet}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportTweet({
                      GroupId: metaData.GroupId,
                      TweetId: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          Tweet: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Tweet bulundu',
                          children: 'Tweet eklendi.',
                        })
                      })
                      .catch((err) => {
                        setIsLoading(false)
                        setId('')
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Tweet bulunamadı',
                          children: 'Tweet veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  {form.values.data.Tweet && (
                    <div className="tweet">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            Tweet: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={form.values.data.Tweet.UserProfilePictureUrl}
                          alt="profile"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                          }}
                        />

                        <p>{form.values.data.Tweet.UserName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}
          {form.values.pageType === 'newsPagesByCountry' && (
            <Row xs={{ align: 'end' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Haber URL giriniz"
                  isRequired
                  label="*Haber URL"
                  name="newsId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Haber Verilerini Getir"
                  isDisabled={form.values.data.News}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportNews({
                      GroupId: metaData.GroupId,
                      Url: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        form.handleFieldChange('data', {
                          ...form.values.data,
                          News: res.data.data,
                        })
                        flagcard.add({
                          appearance: 'success',
                          title: 'Haber bulundu',
                          children: 'Haber eklenmiştir.',
                        })
                      })
                      .catch((err) => {
                        setIsLoading(false)
                        setId('')
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Haber bulunamadı',
                          children: 'Haber veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>

              <Column>
                <div className="data">
                  {form.values.data.News && (
                    <div className="news">
                      <button
                        type="button"
                        onClick={() =>
                          form.handleFieldChange('data', {
                            ...form.values.data,
                            News: undefined,
                          })
                        }
                        className="close"
                      >
                        X
                      </button>
                      <div className="content">
                        <img
                          src={form.values.data.News.ImageUrl}
                          alt="news"
                          style={{
                            objectFit: 'scale-down',
                            width: '50px',
                            height: '50px',
                            borderRadius: '50%',
                          }}
                        />

                        <p>{form.values.data.News.NewsSourceName}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Column>
            </Row>
          )}
          {form.values.pageType === 'influentialAccountTweetPagesByCountry' && (
            <Row xs={{ align: 'center' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Tweet ID giriniz"
                  description="2 adet tweet Id girebilirsiniz."
                  isRequired={form.values.data?.Tweets?.length < 1}
                  label="*Tweet ID"
                  name="tweetId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Tweet Verilerini Getir"
                  isDisabled={form.values.data?.Tweets?.length >= 2}
                  onClick={() => {
                    setIsLoading(true)
                    getAgendaReportTweet({
                      GroupId: metaData.GroupId,
                      TweetId: id,
                    })
                      .then((res) => {
                        setIsLoading(false)
                        setId('')

                        !form.values.data.Tweets &&
                          flagcard.add({
                            appearance: 'success',
                            title: 'Tweet bulundu',
                            children: '1. Tweet eklendi.',
                          })

                        form.values.data.Tweets &&
                          !(
                            form.values.data.Tweets[0].EntityKey ===
                            res.data.data.EntityKey
                          ) &&
                          flagcard.add({
                            appearance: 'success',
                            title: 'Tweet bulundu',
                            children: '2. Tweet eklendi.',
                          })

                        form.values.data.Tweets &&
                        form.values.data.Tweets[0].EntityKey ===
                          res.data.data.EntityKey
                          ? flagcard.add({
                              appearance: 'error',
                              title: 'Tweet zaten ekli',
                              children: 'Tweet verisi zaten ekli.',
                            })
                          : form.handleFieldChange('data', {
                              ...form.values.data,
                              Tweets: form.values.data.Tweets
                                ? [...form.values.data.Tweets, res.data.data]
                                : [res.data.data],
                            })
                      })
                      .catch((err) => {
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Tweet bulunamadı',
                          children: 'Tweet veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  <div className="tweets">
                    {form.values.data.Tweets &&
                      form.values.data.Tweets.length > 0 &&
                      form.values.data.Tweets.map((tweet: Tweet) => (
                        <div className="tweet">
                          {/* delete tweet  */}
                          <button
                            type="button"
                            onClick={() => {
                              form.handleFieldChange('data', {
                                ...form.values.data,
                                Tweets: form.values.data.Tweets.filter(
                                  (item: Tweet) =>
                                    item.EntityKey !== tweet.EntityKey
                                ),
                              })
                            }}
                            className="close"
                          >
                            X
                          </button>

                          <div className="content">
                            <img
                              src={tweet.UserProfilePictureUrl}
                              alt="profile"
                              style={{
                                objectFit: 'scale-down',
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                              }}
                            />

                            <p>{tweet.UserName}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </Column>
            </Row>
          )}
          {form.values.pageType === 'influentialAccountPagesByCountry' && (
            <Row xs={{ align: 'center' }}>
              <Column xs={{ size: 6 }}>
                <Input
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Kullanıcı adı giriniz"
                  isRequired
                  label="*Kullanıcı Adı"
                  name="accountId"
                  type="text"
                />
              </Column>
              <Column xs={{ size: 6 }}>
                <Button
                  appearance="primary"
                  children="Hesap Verilerini Getir"
                  isDisabled={
                    form.values.data.Accounts &&
                    form.values.data.Accounts.length >= 6
                  }
                  onClick={() => {
                    setIsLoading(true)
                    getInfluentialUser(metaData.GroupId, id)
                      .then((res) => {
                        setIsLoading(false)
                        !form.values.data.Accounts &&
                          flagcard.add({
                            appearance: 'success',
                            title: 'Hesap bulundu',
                            children: 'Hesap eklendi.',
                          })

                        form.values.data.Accounts &&
                          form.values.data.Accounts.filter(
                            (item: Account) =>
                              item.ScreenName === res.data.data.ScreenName
                          ).length === 0 &&
                          flagcard.add({
                            appearance: 'success',
                            title: 'Hesap bulundu',
                            children: 'Hesap eklendi.',
                          })

                        form.values.data.Accounts &&
                        form.values.data.Accounts.filter(
                          (item: Account) =>
                            item.ScreenName === res.data.data.ScreenName
                        ).length > 0
                          ? flagcard.add({
                              appearance: 'error',
                              title: 'Hesap zaten ekli',
                              children: 'Hesap verisi zaten ekli.',
                            })
                          : form.handleFieldChange('data', {
                              ...form.values.data,
                              Accounts: form.values.data.Accounts
                                ? [...form.values.data.Accounts, res.data.data]
                                : [res.data.data],
                            })
                      })
                      .catch((err) => {
                        setIsLoading(false)
                        console.log(err)
                        flagcard.add({
                          appearance: 'error',
                          title: 'Hesap bulunamadı',
                          children: 'Hesap veritabanında bulunamadı.',
                        })
                      })
                  }}
                />
              </Column>
              <Column>
                <div className="data">
                  <div className="tweets">
                    {form.values.data.Accounts &&
                      form.values.data.Accounts.length > 0 &&
                      form.values.data.Accounts.map((account: Account) => (
                        <div className="tweet">
                          <button
                            type="button"
                            onClick={() =>
                              form.handleFieldChange('data', {
                                ...form.values.data,
                                Accounts: form.values.data.Accounts?.filter(
                                  (item: Account) =>
                                    item.ScreenName !== account.ScreenName
                                ),
                              })
                            }
                            className="close"
                          >
                            X
                          </button>
                          <div className="content">
                            <img
                              src={account.ProfileImageUrl}
                              alt="profile"
                              style={{
                                objectFit: 'scale-down',
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                              }}
                            />

                            <a
                              href={account.Url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              @{account.ScreenName}
                            </a>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </Column>
            </Row>
          )}
          {(form.values.pageType === 'tweetsPagesByCountry' ||
            form.values.pageType === 'influentialAccountTweetPagesByCountry' ||
            form.values.pageType === 'newsPagesByCountry' ||
            form.values.pageType === 'influentialAccountPagesByCountry') && (
            <Row xs={{ align: 'center' }}>
              <Column xs={{ size: 6 }}>
                <Select
                  label="Ülke"
                  name="country"
                  placeholder="Ülke seçiniz"
                  value={[
                    {
                      label: 'YOK',
                      value: '',
                    },
                    ...countryOptions,
                  ].find((item) => item.value === form.values.data.Country)}
                  onChange={(e) =>
                    form.handleFieldChange('data', {
                      ...form.values.data,
                      Country: (e as SelectOptionProps).value,
                    })
                  }
                  options={[
                    {
                      label: 'YOK',
                      value: '',
                    },
                    ...countryOptions,
                  ]}
                />
              </Column>
            </Row>
          )}
        </FormSection>

        <FormFooter align="start">
          <Button
            isDisabled={isLoading}
            type="submit"
            appearance="primary"
            children="Sayfa Oluştur"
            iconBefore={<Icon name="add-item" />}
          />
        </FormFooter>
      </Form>
    </Styled.FormContainer>
  )
}

export default CreatePageForm
