import { createContext, useContext, useReducer } from 'react'

import * as actions from './actions'
import reducer from './reducer'
import { State, RootContextProps } from './types'

const initialState: State = {
  withBots: true,
}

export const RootContext = createContext<RootContextProps>({
  setWithBots: () => null,
  state: initialState,
})

export const RootProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <RootContext.Provider
      value={{
        setWithBots: (data) => dispatch(actions.setWithBots(data)),
        state,
      }}
    >
      {children}
    </RootContext.Provider>
  )
}

export const useRootContext = (): RootContextProps => {
  return useContext(RootContext)
}
