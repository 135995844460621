import React, { useRef } from 'react'
import SortableJS from 'sortablejs'
import Icon from '@zera-admin/icon'

import CompoundButton from 'components/compound-button'
import { container } from 'bi-tool/drag-drop/container'
import { DESIGNER_COMPONENTS } from 'app/shared/designer'
import { useDashboardContext } from 'app/contexts/dashboard'
import { render, SchemaObject } from 'app/library/layout-builder'

import Sortable from './Sortable'
import * as Styled from './Designer.styled'

const Designer: React.FunctionComponent<any> = (props) => {
  const dashboardContext = useDashboardContext()
  const designAreaRef = useRef<HTMLDivElement>(null)
  const getSortableOptions = (): SortableJS.Options => {
    return {
      group: {
        pull: (toElement: SortableJS, _, fromElement: HTMLElement) => {
          const from = fromElement.getAttribute('data-schema-type')
          const to = toElement.el.getAttribute('data-schema-type')

          if (to === 'column') {
            return 'clone'
          }

          if (to === 'page' && from !== 'page') {
            return 'clone'
          }

          if (
            (from === 'widget' || from === 'column' || from === 'page') &&
            to === 'grid'
          ) {
            return 'clone'
          }

          return false
        },
        name: 'droppable',
      },
      sort: false,
      animation: 0,
      swapThreshold: 0.5,
    }
  }

  const renderDesignerHeader = () => {
    if (props.preview) {
      return null
    }

    return (
      <div className="designer-header">
        <Sortable
          className="designer-components row"
          sortableOptions={getSortableOptions()}
        >
          {DESIGNER_COMPONENTS.filter((component) => !component.disabled).map(
            (component, index) => (
              <div
                key={index}
                className={`fa-sortable-item item size-${component.size || 0}`}
                data-schema-size={component?.size || ''}
                data-schema-type={component.schema}
              >
                <CompoundButton
                  iconBefore={<Icon name={component.icon}></Icon>}
                  appearance="button"
                >
                  {component.name}
                </CompoundButton>
              </div>
            )
          )}
        </Sortable>
      </div>
    )
  }

  const renderDesignerContent = () => {
    return (
      <div
        style={{ position: 'relative' }}
        ref={designAreaRef}
        className="designer-content droppable-area"
      >
        {render(
          (dashboardContext.state.schema as SchemaObject[]) || [],
          container,
          {
            designing: !props.preview,
            dashboard: props.query || undefined,
            fields: props.fields || [],
            reference: {
              onClick: (widget: any, row: any) => console.log(widget, row),
            },
          }
        )}
      </div>
    )
  }

  return (
    <Styled.Designer id="designer-container">
      <div className="designer">
        {renderDesignerHeader()}
        {renderDesignerContent()}
      </div>
    </Styled.Designer>
  )
}
export default React.memo(Designer)
