import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const AgendaReportWizard = styled.div`
  margin-top: 2rem;
  display: block;
`

export const AgendaReportWizardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0%;
    top: 50%;
    width: 100%;
    height: 2px;
    background: ${colors.N30};
  }
`

export const AgendaReportWizardHeaderStep = styled.div<{
  isActive: boolean
  isCompleted: boolean
}>`
  background-color: ${(props) =>
    props.isActive ? colors.G300 : props.isCompleted ? '#77c2a9' : colors.N30};
  color: ${(props) =>
    props.isActive || props.isCompleted ? colors.N0 : colors.N400};
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
  pointer-events: ${(props) => (props.isActive ? 'all' : 'none')};
  border-radius: 1rem;
  position: relative;
  z-index: 1;

  span {
    opacity: ${(props) => (props.isCompleted ? '0.9' : '1')};
  }
`

export const AgendaReportWizardBody = styled.div`
  & > section {
    padding: 8px 16px;
    margin: 1rem 0;
    font-size: 13px;
  }
`
