import React, { useState, useEffect } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import { uid } from '@zera-admin/utils'
import TimeRangePicker from '@zera-admin/alice-time-range-picker'

import { useDashboardContext } from 'app/contexts/dashboard'
import { useFlagcardContext } from 'app/contexts/flagcard'
import datasourceService, {
  Datasource,
  Table,
} from 'services/http/bi-tool/datasource'
import fieldService, { Field, FieldRequest } from 'services/http/bi-tool/field'
import dashboardService, { Dashboard } from 'services/http/bi-tool/dashboard'
import Loader from 'components/loader'
import BIToolLayout from 'layouts/bi-tool'
import DashboardDesigner from 'pages/bi-tool/dashboard/dashboard-designer'

import * as Styled from './CreateDashboard.styled'
import { DEFAULT_SCHEMA } from './constants'
import { CreateDashboardProps } from './types'

const CreateDashboard: React.FunctionComponent<CreateDashboardProps> = ({
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [filterVisibility, setFilterVisibility] = useState(false)
  const [widgetCount, setWidgetCount] = useState(2)
  const [sources, setSources] = useState<Datasource[]>([])
  const [tables, setTables] = useState<Table[]>([])
  const [fields, setFields] = useState<Field[]>([])
  const [values, setValues] = useState<Dashboard>({
    title: 'Yeni dashboard',
    description: '',
    query: {
      source: '',
      table: '',
      interval: {
        start: {
          type: 'relative',
          unit: 'days',
          value: 1,
        },
        end: {
          type: 'now',
        },
      },
      limit: 100,
    },
    schema: [],
  })
  const [interval, setInterval] = useState(values.query?.interval)

  const [flagcard] = useFlagcardContext()
  const dashboardContext = useDashboardContext()

  const createUniqueId = (): string => {
    return Math.random().toString(36).substr(2, 9) // Benzersiz bir ID oluşturmak için rastgele bir dize döndürüyoruz
  }

  const createSchemaWithColumns = (widgetCount: number): any[] => {
    const newSchema: any = {
      ...DEFAULT_SCHEMA[0], // İlk öğeyi kopyalayın (row)
      children: [], // Yeni sütunları içerecek boş bir dizi oluşturun
    }
    let colounmExp = {
      definitions: {
        id: 'column',
        name: 'Column title',
      },
      name: 'column',
      props: {
        sizes: {
          md: 6,
          lg: 6,
          sm: 6,
          xs: 6,
        },
      },
      source: {},
      children: [
        {
          definitions: {
            id: 'section',
            name: 'Widget title',
          },
          name: 'section',
          props: { height: 400 },
          source: {},
          children: [
            {
              definitions: {
                id: 'widget',
                name: 'Widget title',
              },
              name: 'widget',
              props: {},
              source: {},
              children: [],
            },
          ],
        },
      ],
    }

    if (newSchema) {
      const newColumn: any = colounmExp // İlk sütunu kopyalayın
      for (let i = 0; i < widgetCount; i++) {
        newColumn.definitions.id = `column-${createUniqueId()}` // Benzersiz bir ID atayın
        newColumn.children[0].definitions.id = `section-${createUniqueId()}` // Benzersiz bir ID atayın
        newColumn.children[0].children[0].definitions.id = `widget-${createUniqueId()}` // Benzersiz bir ID atayın
        newSchema.children.push(newColumn)
      }
    }

    return [newSchema]
  }

  // useEffect(() => {
  //   setSchema(createSchemaWithColumns(widgetCount))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [widgetCount])

  useEffect(() => {
    //burayı kaybetme
    getDatasources()
    dashboardContext.set(createSchemaWithColumns(widgetCount) || DEFAULT_SCHEMA)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetCount])

  const getDatasources = () => {
    datasourceService
      .get()
      .then((res) => {
        setSources(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getTables = (datasource: string) => {
    datasourceService
      .getTables(datasource)
      .then((res) => {
        setTables(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getFields = (request: FieldRequest) => {
    fieldService
      .get(request)
      .then((res) => {
        if (res.data) {
          setFields(res.data)
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const handleSubmit = () => {
    if (values) {
      setIsLoading(true)

      dashboardService
        .create({ ...values, schema: dashboardContext.state.schema })
        .then((res) => {
          const value = res.data

          if (value) {
            history.push(`/bi-tool/dashboard/update/${value.data}`)

            flagcard.add({
              id: uid(),
              appearance: 'success',
              title: 'Dashboard oluşturuldu',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte dashboard sisteme başarıyla kayıt edilmiştir. Oluşturduğunuz dashboard`ı düzenleyebilirsiniz.',
            })
          }
        })
        .catch((error) => {
          console.log(error)

          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children:
              'Dashboard eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleChangePreview = (checked: boolean) => {
    if (checked) {
      setIsLoading(true)

      dashboardService
        .preview({ schema: dashboardContext.state.schema })
        .then((response) => {
          dashboardContext.set(response.data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    setIsPreview(checked)
  }

  const handleChangeValues = (values: Dashboard) => {
    setValues(values)
  }

  const renderPageHeader = () => {
    const bottomBar = (
      <p>
        {values.description ||
          'Görselleştirilmiş verileri bir arada görüntülemek için panonuzu tasarlayın.'}
      </p>
    )
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Dashboardlar" href="/bi-tool/dashboards" />
        <BreadcrumbItem text="Dashboard oluştur" />
      </Breadcrumb>
    )
    const actions = isPreview ? (
      <Row xs={{ direction: 'row' }}>
        <Column>
          <Styled.DashboardTimePicker>
            <TimeRangePicker defaultValues={interval} onChange={setInterval} />
          </Styled.DashboardTimePicker>
        </Column>
        <Column>
          <ButtonGroup>
            <Button
              appearance="primary"
              iconBefore={<Icon name="refresh" size="small" />}
              onClick={() =>
                handleChangeValues({
                  ...values,
                  query: { ...values.query, interval },
                })
              }
            >
              Yenile
            </Button>
            <Button
              iconBefore={<Icon name="filter" size="small" />}
              onClick={() => setFilterVisibility(true)}
            >
              Filtre ve hesaplamalar
            </Button>
            <Button
              className="primary-outline"
              iconBefore={<Icon name="lightbulb" size="small" />}
              onClick={() => handleChangePreview(!isPreview)}
            >
              Tasarım Modu
            </Button>
          </ButtonGroup>
        </Column>
      </Row>
    ) : (
      <Button
        className="success-outline"
        iconBefore={<Icon name="vid-play" size="small" />}
        onClick={() => handleChangePreview(!isPreview)}
      >
        Önizleme Modu
      </Button>
    )

    return (
      <PageHeader
        actions={values?.query?.table ? actions : undefined}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        {values?.title || 'Dashboard'}
      </PageHeader>
    )
  }

  return (
    <BIToolLayout>
      <Page>
        <Styled.CreateDashboard>
          {renderPageHeader()}
          {isLoading ? (
            <Loader
              show={isLoading}
              size="medium"
              label="Dashboard oluşturuluyor..."
            />
          ) : (
            <DashboardDesigner
              fields={fields}
              filterVisibility={filterVisibility}
              isPreview={isPreview}
              onChange={handleChangeValues}
              onValue={setWidgetCount}
              onGetFields={getFields}
              onGetTables={getTables}
              onSave={handleSubmit}
              operation="create"
              setFilterVisibility={setFilterVisibility}
              sources={sources}
              tables={tables}
              values={values}
            />
          )}
        </Styled.CreateDashboard>
      </Page>
    </BIToolLayout>
  )
}

export default CreateDashboard
