import { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'

import { QueryRunResponse } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import {
  StackedChartVisualizationNumberFormat,
  StackedChartVisualizationOptions,
} from '../types'
import { numberFormat } from './helpers'

export const mapStackedChartProps = (
  values: QueryRunResponse,
  visualization: WidgetVisualization<StackedChartVisualizationOptions>
): Props => {
  let result: Props = {}
  const { options } = visualization
  const label = options.label
  const series = options.series

  if (values.rows.length) {
    result = {
      options: {
        chart: {
          stacked: true,
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          categories: values.rows
            .filter((row) => row[label?.field])
            .map((row) => row[label?.field] as string),
        },
        dataLabels: {
          enabled: false,
          formatter: (data: number) => {
            return numberFormat(
              data,
              options?.format || ({} as StackedChartVisualizationNumberFormat)
            )
          },
          style: {
            fontSize: '12px',
            colors: [colors.N30],
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        yaxis: {
          labels: {
            formatter: (data: number) => {
              return numberFormat(
                data,
                options?.format || ({} as StackedChartVisualizationNumberFormat)
              )
            },
          },
        },
        colors: Object.entries(colors)
          .filter(
            (entries) => entries[0].includes('300') && !entries[0].includes('Y')
          )
          .map((entries) => entries[1])
          .slice(0, values.rows.length),
        theme: {
          mode: visualization.theme,
        },
      },
      series: (series || []).map((serie) => ({
        name: serie?.alias,
        data: values.rows
          .filter((row) => (row[serie?.field] as number) > 0)
          .map((row) => row[serie?.field]),
      })),
    }
  }

  return result
}
