import React from 'react'
import * as Styled from './SocialCard.styled'
import { SocialCardProps } from './types'

import X from 'assets/images/social-platforms/x.png'
import Youtube from 'assets/images/social-platforms/youtube.png'
import Reddit from 'assets/images/social-platforms/reddit.png'
import News from 'assets/images/social-platforms/news.png'
import TikTok from 'assets/images/social-platforms/tiktok.png'
import Instagram from 'assets/images/social-platforms/instagram.png'
import Telegram from 'assets/images/social-platforms/telegram.png'
import EksiSozluk from 'assets/images/social-platforms/eksi.png'
import Quora from 'assets/images/social-platforms/quora.png'
import Mastodon from 'assets/images/social-platforms/mastodon.png'

import { numberFormat } from 'app/functions/number'

const SocialCard: React.FC<SocialCardProps> = ({
  data,
  editMode,
  onChange,
}) => {
  const platformSwitcher = (platform: string) => {
    switch (platform) {
      case 'Twitter':
        return X
      case 'Youtube':
        return Youtube
      case 'Reddit':
        return Reddit
      case 'News':
        return News
      case 'TikTok':
        return TikTok
      case 'Instagram':
        return Instagram
      case 'Telegram':
        return Telegram
      case 'EksiSozluk':
        return EksiSozluk
      case 'Quora':
        return Quora
      case 'Mastodon':
        return Mastodon
      default:
        return null
    }
  }

  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData, data.Source, data.Type)
  }

  return data?.Count ? (
    <Styled.SocialCardContainer>
      <img
        width={50}
        height={50}
        src={platformSwitcher(data.Source) || ''}
        alt={data.Title}
      />

      <h5
        contentEditable={editMode}
        onBlur={(e) => handleBlurElement(e, 'Title')}
      >
        {data.Title}
      </h5>
      <span
        contentEditable={editMode}
        onBlur={(e) => handleBlurElement(e, 'Count')}
      >
        {numberFormat(data.Count)}
      </span>
    </Styled.SocialCardContainer>
  ) : null
}

export default SocialCard
