import styled from 'styled-components'

export const MulticronBuilder = styled.div`
  width: 100%;
`

export const MulticronBuilderGroup = styled.div<{ isChildren: boolean }>`
  background-color: ${(props) =>
    props.isChildren ? 'rgba(0, 0, 0, 0.025)' : '#fff'};
  padding: 1rem;
  margin-top: ${(props) => (props.isChildren ? '15px' : '0px')};
`

export const MulticronBuilderGroupHeader = styled.div`
  margin-bottom: 15px;
`

export const MulticronBuilderGroupBody = styled.div``

export const MulticronBuilderRules = styled.div``

export const ListField = styled.div`
  background: #fff;
  border: 1px solid #efefef;
  padding: 1rem;
  margin-top: 10px;

  button.list-field-button {
    margin-top: 22px;
    padding: 0px 5px;
    margin-left: 8px;
    height: 2.4rem;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    width: 100%;
  }
`
