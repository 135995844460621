import { QueryRunResponse } from 'services/http/bi-tool/query'

import { WordCloudValueProps, WordCloudVisualizationOptions } from '../types'

export const mapQueryForWordCloud = (
  raw: QueryRunResponse,
  options: WordCloudVisualizationOptions
): WordCloudValueProps[] =>
  (raw.rows || []).map((row) => ({
    text: row[options?.label.field as string],
    value: row[options?.value?.field as string],
  }))
