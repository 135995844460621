import { ClassificationDataSet, ClassificationDataSetResponse } from './types'

export const mapClassificationDataSetForRequest = (
  raw: ClassificationDataSet
): unknown => ({
  classification_category_id: raw.classificationCategoryId,
  language_code: raw.languageCode,
  source_id: raw.sourceId,
  text: raw.text,
  entity_key: raw.entityKey,
})

export const mapClassificationDataSetForResponse = (
  raw: any
): ClassificationDataSet => ({
  id: raw.data.id,
  classificationCategoryId: raw.data.classification_category_id,
  classificationCategoryName: raw.data.classification_category_name,
  createdDate: raw.data.created_date,
  currentVersion: raw.data.current_version,
  languageCode: raw.data.language_code,
  sourceId: raw.data.source_id,
  sourceName: raw.data.source_name,
  text: raw.data.text,
  trainingGroupId: raw.data.training_group_id,
  trainingGroupName: raw.data.training_group_name,
  userId: raw.data.user_id,
})

export const mapClassificationDataSetForList = (
  raw: any
): ClassificationDataSetResponse => ({
  list: raw.data_list.map((item: unknown) =>
    mapClassificationDataSetForResponse({ data: item })
  ),
  dataCount: raw.data_count,
  pageCount: raw.page_count,
  message: raw.message,
  success: raw.success,
})
