import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'
import EmptyState from '@zera-admin/empty-state'

import { MostCommonEntitiesProps } from './types'

const MostCommonEntities: React.FunctionComponent<MostCommonEntitiesProps> = ({
  data,
}) => {
  const mostCommonEntities: Props = {
    options: {
      chart: {
        id: 'mostCommonEntities',
      },
      colors: [colors.G400, colors.R400, colors.DN100],
      xaxis: {
        categories: data?.map((item: any) => item.entity),
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
      },
    },
    series: [
      {
        data: data?.map((item: any) => item.posCount),
        name: 'Pozitif',
      },
      {
        data: data?.map((item: any) => item.negCount),
        name: 'Negatif',
      },
      {
        data: data?.map((item: any) => item.neuCount),
        name: 'Nötr',
      },
    ],
  }

  return data && data.length > 0 ? (
    <Chart
      options={mostCommonEntities.options}
      series={mostCommonEntities.series}
      type="bar"
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default MostCommonEntities
