import React, { useEffect, useState } from 'react'
import Button, { ButtonGroup } from '@zera-admin/button'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import EmptyState from '@zera-admin/empty-state'
import Form from '@zera-admin/form'
import Icon from '@zera-admin/icon'
import Select from '@zera-admin/select'
import Pager from 'components/pager'
import * as XLSX from 'xlsx'

import { useFlagcardContext } from 'app/contexts/flagcard'
import activeAccountServices, {
  ActivePerson,
  ActivePersonListResponse,
  FilterResponse,
} from 'services/http/backoffice/active-account'
import MainLayout from 'layouts/main'
import TwitterProfileCard from 'components/twitter-profile-card/TwitterProfileCard'
import NotFoundImage from 'assets/images/not-found.png'

import SelectedPersonModal from './selected-person-modal'
import MailModal from './mail-modal'
import { InfluentialPeopleListProps } from './types'

const InfluentialPeopleList: React.FunctionComponent<InfluentialPeopleListProps> =
  () => {
    const [flagcard] = useFlagcardContext()
    const [modalSelectedPerson, setModalSelectedPerson] =
      useState<boolean>(false)
    const [modalMail, setModalMail] = useState<boolean>(false)
    const [influentialPersons, setInfluentialPersons] =
      useState<ActivePersonListResponse | null>()
    const [personList, setPersonList] = useState<Array<ActivePerson>>([])
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [filter, setFilter] = useState<FilterResponse>()
    const [countries, setCountries] = useState<string | null>('')
    const [category, setCategory] = useState<string | null>('')
    const [loader, setLoader] = useState<boolean>(false)
    const [year, setYear] = useState<number | null>(null)
    const [month, setMonth] = useState<number | null>(null)

    const downloadExcel = (personList: ActivePerson[]) => {
      const worksheet = XLSX.utils.json_to_sheet(personList)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'users')
      XLSX.writeFile(workbook, 'Influential-User-L.xlsx', {
        bookType: 'xlsx',
        type: 'buffer',
      })

      flagcard.add({
        appearance: 'success',
        title: 'Excel indirme başarılı',
        children:
          'Seçmiş olduğunuz kişilerin verileri başarıyle excel olarak indirildi.',
      })
    }

    const getActivePeople = async () =>
      // country: string | number | null,
      // category: string | number | null,
      // Month: string | number,
      // Year: string | number
      {
        setLoader(true)
        try {
          const params = {
            PageSize: 24,
            PageIndex: pageIndex,
            Country: '',
            UserCategory: '',
            Year: null,
            Month: null,
          }
          const response = await activeAccountServices.get(params)
          setInfluentialPersons(response.data)
        } catch (err) {
          const ex: any = err
          setInfluentialPersons(null)
        }
        setLoader(false)
      }

    const postMail = async (entityKeys: number[], mails: string[]) => {
      flagcard.add({
        appearance: 'info',
        title: 'Mail Gönderiliyor...',
        children: 'Girmiş olduğunuz mail adresine mail gönderiliyor.',
      })
      try {
        const response = await activeAccountServices
          .postMail({
            mails,
            entityKeys,
          })
          .then((res) => {
            flagcard.add({
              appearance: 'success',
              title: 'Mail işlemi başarılı',
              children:
                'Girmiş olduğunuz mailler ile seçmiş olduğunuz kişiler mail başarıyla mail olarak gönderildi.',
            })
          })
      } catch (err) {
        console.log(err)
      }
    }

    const putStatus = async (entityKeys: any) => {
      try {
        const response = await activeAccountServices
          .put({
            publishStatus: 1,
            entityKeys,
          })
          .then((res) => {
            flagcard.add({
              appearance: 'success',
              title: 'Durum güncelleme başarılı',
              children:
                'Seçmiş olduğunuz kişilerin durumu başarıyla güncellendi.',
            })
          })
      } catch (err) {
        console.log(err)
      }
    }
    const filterHandler = async () => {
      const response = await activeAccountServices.get({
        Year: year,
        Month: month,
        UserCategory: category,
        Country: countries,
        PageSize: 24,
        PageIndex: pageIndex,
      })
      setLoader(true)
      try {
        setInfluentialPersons(response.data)
      } catch (err) {
        setInfluentialPersons(null)
      }
      setLoader(false)
    }

    const getFilterHandler = async () => {
      try {
        const response = await activeAccountServices.getFilters()
        setFilter(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    useEffect(() => {
      getActivePeople()
      getFilterHandler()
    }, [])

    const addPersonToList = (person: ActivePerson) => {
      setPersonList([...personList, person])
    }

    const removePersonFromList = (person: ActivePerson) => {
      setPersonList(
        personList.filter((per) => {
          return per.entityKey !== person.entityKey
        })
      )
    }

    const personIsSelected = (id: number) => {
      let isThere = personList.find((el) => el.entityKey === id)
      if (isThere) return true
      return false
    }

    const resetFilter = () => {
      setCategory(null)
      setCountries(null)
      setYear(null)
      setMonth(null)
    }

    const renderFilter = () => {
      return (
        <Form style={{ marginBottom: '10px' }}>
          <Row xs={{ align: 'center' }}>
            <Column xs={{ size: 3 }}>
              <Select
                description="Filtrelemek istediğiniz ülkeyi seçiniz."
                placeholder="Bir ülke seçiniz"
                label="Ülke"
                name="country"
                value={
                  countries
                    ? filter?.countries
                        .map((country) => ({
                          label: country,
                          value: country,
                        }))
                        .find((x) => x.value === countries)
                    : { label: 'Ülke seçiniz', value: '' }
                }
                options={filter?.countries.map((country) => ({
                  label: country,
                  value: country,
                }))}
                onChange={(countries: any) => {
                  setCountries(countries?.value)
                }}
              />
            </Column>
            <Column xs={{ size: 3 }}>
              <Select
                description="Filtrelemek istediğiniz kategoriyi seçiniz."
                label="Kategori"
                name="category"
                value={
                  category
                    ? filter?.classifications
                        .map((classification) => ({
                          label: classification,
                          value: classification,
                        }))
                        .find((x) => x.value === category)
                    : { label: 'Kategori seçiniz', value: '' }
                }
                options={filter?.classifications.map((classification) => ({
                  label: classification,
                  value: classification,
                }))}
                onChange={(category: any) => {
                  setCategory(category?.value)
                }}
                placeholder="Bir kategori seçiniz"
              />
            </Column>
            <Column xs={{ size: 3 }}>
              <Select
                description="Filtrelemek istediğiniz yılı seçiniz."
                label="Yıl"
                name="year"
                value={
                  year
                    ? filter?.availableDates
                        .map((availableDate) => ({
                          label: '' + availableDate.year,
                          value: availableDate.year,
                        }))
                        .find((x) => x.value === year)
                    : { label: 'Yılı seçiniz', value: '' }
                }
                options={filter?.availableDates.map((availableDate) => ({
                  label: '' + availableDate.year,
                  value: availableDate.year,
                }))}
                onChange={(year: any) => {
                  setYear(year?.value)
                }}
                placeholder="Yılı seçiniz"
              />
            </Column>
            <Column xs={{ size: 3 }}>
              <Select
                description="Filtrelemek istediğiniz ayı seçiniz."
                label="Ay"
                name="month"
                value={
                  month
                    ? filter?.availableDates
                        .map((availableDate) => ({
                          label: '' + availableDate.month,
                          value: availableDate.month,
                        }))
                        .find((x) => x.value === month)
                    : { label: 'Ayı seçiniz', value: '' }
                }
                options={filter?.availableDates.map((availableDate) => ({
                  label: '' + availableDate.month,
                  value: availableDate.month,
                }))}
                onChange={(month: any) => {
                  setMonth(month?.value)
                }}
                placeholder="Bir ay seçiniz"
              />
            </Column>

            <Column>
              <ButtonGroup>
                <Button
                  appearance="primary"
                  children="Filtrele"
                  iconBefore={<Icon size="small" name="filter" />}
                  type="submit"
                  onClick={filterHandler}
                />
                <Button
                  appearance="button"
                  children="Filtreyi Temizle"
                  iconBefore={<Icon size="small" name="retry" />}
                  type="submit"
                  onClick={() => resetFilter()}
                />
              </ButtonGroup>
            </Column>
          </Row>
        </Form>
      )
    }

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Etkili Kişiler" />
      </Breadcrumb>
    )

    const twitterCard = influentialPersons?.data?.map((data: ActivePerson) => (
      <TwitterProfileCard
        addList={addPersonToList}
        removeList={removePersonFromList}
        data={data}
        isSelected={personIsSelected(data.entityKey)}
      />
    ))

    return (
      <MainLayout>
        <Page>
          <PageHeader
            bottomBar={
              <p>
                Tüm etkili kişileri görüntüleyebilir, indirebilir, mail olarak
                gönderebilir ve yayınlama durumlarını güncelleyebilirsiniz.
              </p>
            }
            breadcrumbs={breadcrumbs}
            actions={
              personList.length ? (
                <ButtonGroup>
                  <Button
                    iconBefore={<Icon name={'person'} />}
                    onClick={() => {
                      setModalSelectedPerson(true)
                    }}
                  >
                    Seçilenler
                  </Button>
                  <Button
                    iconBefore={<Icon name="arrow-down" size="small" />}
                    onClick={() => downloadExcel(personList)}
                  >
                    Excel olarak indir
                  </Button>
                  <Button
                    iconBefore={<Icon name={'email'} />}
                    onClick={() => {
                      setModalMail(true)
                    }}
                  >
                    Mail olarak gönder
                  </Button>
                </ButtonGroup>
              ) : (
                <></>
              )
            }
          >
            Etkili Kişiler
          </PageHeader>
          {renderFilter()}
          <Row xs={{ justify: 'around' }}>
            {loader ? (
              <EmptyState title="Veriler bekleniyor" />
            ) : twitterCard ? (
              twitterCard
            ) : (
              <EmptyState
                title="Veri bulunamadı"
                description="Aradığınız kriterde veri bulunamadı"
                image={{ src: NotFoundImage }}
              />
            )}
          </Row>

          <Pager
            pages={
              influentialPersons?.pageCount ? influentialPersons?.pageCount : 10
            }
            onChange={(index) => setPageIndex(index - 1)}
          />
        </Page>

        {modalSelectedPerson && (
          <SelectedPersonModal
            onClose={() => {
              setModalSelectedPerson(false)
            }}
            selectedPerson={personList}
            onDeletePerson={removePersonFromList}
            putStatus={putStatus}
          />
        )}
        {modalMail && personList && (
          <MailModal
            onClose={() => {
              setModalMail(false)
            }}
            data={personList}
            onMailSend={postMail}
          />
        )}
      </MainLayout>
    )
  }

export default InfluentialPeopleList
