import React, { useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import Form, { FormFooter, FormSection } from '@zera-admin/form'
import Input from '@zera-admin/input'
import Button from '@zera-admin/button'
import Table from '@zera-admin/table'
import Icon from '@zera-admin/icon'
import Spinner from '@zera-admin/spinner'
import { debounce } from 'lodash'

import agendaReportService from 'services/http/report/agenda-report'
import { CreateAgendaReportRequest } from 'services/http/report/agenda-report/types'

import * as Styled from './AgendaReportForm.styled'
import { AgendaReportFormProps, AgendaReportFormTexts } from './types'
import Checkbox from '@zera-admin/checkbox'

const AgendaReportForm: React.FunctionComponent<AgendaReportFormProps> = ({
  data,
  onSubmit,
  operation,
  isLoading,
}) => {
  const [values, setValues] = useState<CreateAgendaReportRequest>(data)
  const [searchTexts, setSearchTexts] = useState<AgendaReportFormTexts>({
    link: { value: '', creatable: false },
    user: { value: '', creatable: false },
    keyword: { value: '', creatable: false },
  })

  const twitterValues = values.taskInfos[0]
  const isValidate =
    values.description &&
    (twitterValues.links.length ||
      twitterValues.userNames.length ||
      twitterValues.keywords.length)
      ? true
      : false

  const handleInsertDefinition = (name: string, search: string) => {
    const searched = searchTexts[search]
    const items = twitterValues ? (twitterValues[name] as string[]) : []
    const reference = items.find((item) => item === searched.value)

    if (!reference) {
      const result = { ...values }

      result.taskInfos[0][name] = [...items, searched.value]
      setValues(result)
      setSearchTexts({
        ...searchTexts,
        [search]: { value: '', creatable: false },
      })
    }
  }

  const handleRemoveDefinition = (name: string, index: number) => {
    const items = twitterValues ? (twitterValues[name] as string[]) : []

    if (items[index]) {
      items.splice(index, 1)
      const result = { ...values }

      result.taskInfos[0][name] = items
      setValues(result)
    }
  }

  const handleChangeUsername = debounce(async (value: string) => {
    setSearchTexts({
      ...searchTexts,
      user: {
        ...searchTexts.user,
        error: '',
        loading: true,
      },
    })

    const response = await agendaReportService.search(value)

    if (response.data) {
      setSearchTexts({
        ...searchTexts,
        user: {
          creatable: response.data.isUserExist,
          error: !response.data.isUserExist
            ? 'Girdiğiniz kullanıcı adı geçerli değil.'
            : '',
          loading: false,
          value,
        },
      })
    } else {
      setSearchTexts({
        ...searchTexts,
        user: {
          creatable: false,
          error: 'Arama yapılırken bir hata oluştu.',
          loading: false,
          value,
        },
      })
    }
  }, 1000)

  const renderUsernameDefinitions = () => (
    <Column
      xs={{ size: operation === 'modal' ? 12 : 4 }}
      style={{ marginTop: -20 }}
    >
      <FormSection title="Kullanıcı Tanımlamaları">
        {operation !== 'detail' && (
          <React.Fragment>
            <Styled.AgendaReportFormInput>
              <Input
                label="Twitter kullanıcı adı giriniz"
                description="Geçerli bir twitter kullanıcı adı giriniz"
                name="username"
                errorMessage={searchTexts.user.error}
                isDisabled={searchTexts.user.loading}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeUsername(event.target.value)
                }
              />
              {searchTexts.user.loading && (
                <Spinner className="spinner" size="small" delay={0} />
              )}
            </Styled.AgendaReportFormInput>
            <Button
              style={{ marginTop: 10, marginBottom: 10 }}
              isDisabled={
                !searchTexts.user.creatable || searchTexts.user.loading
              }
              onClick={() => handleInsertDefinition('userNames', 'user')}
            >
              Kullanıcı tanımlaması ekle
            </Button>
          </React.Fragment>
        )}
      </FormSection>

      {twitterValues.userNames.length ? (
        <Table
          columns={
            [
              { name: 'label', text: 'Kullanıcı adı' },
              {
                name: 'operation',
                text: 'İşlem',
                render: (row: any, index: number) => (
                  <Button
                    size="none"
                    onClick={() => handleRemoveDefinition('userNames', index)}
                  >
                    <Icon name="cross" size="small" />
                  </Button>
                ),
              },
            ] as any
          }
          rows={(twitterValues.userNames as string[]).map((username) => ({
            label: username,
          }))}
        />
      ) : operation === 'detail' ? (
        'Geçerli bir kullanıcı tanımlaması bulunamadı.'
      ) : (
        ''
      )}
    </Column>
  )

  const renderKeywordDefinitions = () => (
    <Column
      xs={{ size: operation === 'modal' ? 12 : 4 }}
      style={{ marginTop: -20 }}
    >
      <FormSection title="Anahtar Kelime Tanımlamaları">
        {operation !== 'detail' && (
          <React.Fragment>
            <Input
              label="Anahtar kelime giriniz"
              description="Geçerli bir anahtar kelime giriniz"
              name="keyword"
              errorMessage={searchTexts.keyword.error}
              isDisabled={searchTexts.keyword.loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTexts({
                  ...searchTexts,
                  keyword: {
                    value: event.target.value,
                    error: '',
                    loading: false,
                    creatable: true,
                  },
                })
              }
            />
            <Button
              style={{ marginTop: 10, marginBottom: 10 }}
              isDisabled={!searchTexts.keyword.value}
              onClick={() => handleInsertDefinition('keywords', 'keyword')}
            >
              Anahtar kelime tanımlaması ekle
            </Button>
          </React.Fragment>
        )}
      </FormSection>

      {twitterValues.keywords.length ? (
        <Table
          columns={
            [
              { name: 'label', text: 'Anahtar kelime' },
              {
                name: 'operation',
                text: 'İşlem',
                render: (row: any, index: number) => (
                  <Button
                    size="none"
                    onClick={() => handleRemoveDefinition('keywords', index)}
                  >
                    <Icon name="cross" size="small" />
                  </Button>
                ),
              },
            ] as any
          }
          rows={(twitterValues.keywords as string[]).map((keyword) => ({
            label: keyword,
          }))}
        />
      ) : operation === 'detail' ? (
        'Geçerli bir anahtar kelime tanımlaması bulunamadı.'
      ) : (
        ''
      )}
    </Column>
  )

  const renderLinkDefinitions = () => (
    <Column
      xs={{ size: operation === 'modal' ? 12 : 4 }}
      style={{ marginTop: -20 }}
    >
      <FormSection title="Link Tanımlamaları">
        {operation !== 'detail' && (
          <React.Fragment>
            <Input
              label="Tweet adresi giriniz"
              description="Geçerli bir tweet URL adresi giriniz"
              name="link"
              errorMessage={searchTexts.link.error}
              isDisabled={searchTexts.link.loading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTexts({
                  ...searchTexts,
                  link: {
                    value: event.target.value,
                    error: '',
                    loading: false,
                    creatable: true,
                  },
                })
              }
            />
            <Button
              style={{ marginTop: 10, marginBottom: 10 }}
              isDisabled={!searchTexts.link.value}
              onClick={() => handleInsertDefinition('links', 'link')}
            >
              Link tanımlaması ekle
            </Button>
          </React.Fragment>
        )}
      </FormSection>

      {twitterValues.links.length ? (
        <Table
          columns={
            [
              { name: 'label', text: 'Bağlantı adresi' },
              {
                name: 'operation',
                text: 'İşlem',
                render: (row: any, index: number) => (
                  <Button
                    size="none"
                    onClick={() => handleRemoveDefinition('links', index)}
                  >
                    <Icon name="cross" size="small" />
                  </Button>
                ),
              },
            ] as any
          }
          rows={(twitterValues.links as string[]).map((link) => ({
            label: link,
          }))}
        />
      ) : operation === 'detail' ? (
        'Geçerli bir link tanımlaması bulunamadı.'
      ) : (
        ''
      )}
    </Column>
  )

  return (
    <Styled.AgendaReportForm>
      <Form>
        <Row>
          {operation === 'create' && (
            <Column xs={{ size: 12 }}>
              <Input
                label="Gündem raporu başlığı"
                name="description"
                value={values.description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({ ...values, description: event.target.value })
                }}
              />
            </Column>
          )}
          {renderUsernameDefinitions()}
          {renderKeywordDefinitions()}
          {renderLinkDefinitions()}
        </Row>
        <Column xs={{ size: 12 }}>
          <Checkbox
            name=" includeActiveUsersAndCommunities"
            label="Aktif Kullanıcı ve Lobi Hesaplaması Yapılsın Mı?"
            onChange={(event) =>
              !event.currentTarget.checked
                ? (setValues({
                    ...values,
                    includeActiveUsersAndCommunities:
                      event.currentTarget.checked,
                  }),
                  setValues({
                    ...values,
                    includeActiveUsersAndCommunities: true,
                  }))
                : setValues({
                    ...values,
                    includeActiveUsersAndCommunities:
                      event.currentTarget.checked,
                  })
            }
          />
        </Column>

        <FormFooter>
          <Button
            children={
              operation === 'modal'
                ? 'Tanımlamaları kaydet'
                : 'Gündem raporunu oluştur'
            }
            appearance="primary"
            isDisabled={!isValidate}
            onClick={() => onSubmit(values)}
            isLoading={isLoading}
          />
        </FormFooter>
      </Form>
    </Styled.AgendaReportForm>
  )
}

export default AgendaReportForm
