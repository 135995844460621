import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import { RedditVisualizationFields, RedditVisualizationOptions } from '../types'

export const generateRedditOptions = (
  options: RedditVisualizationOptions,
  query: Query,
  fields: Field[]
): RedditVisualizationOptions => {
  let result = options

  query.groups?.forEach((group) => {
    result = {
      ...result,
      fields: {
        ...result.fields,
        [group.field]: group.field as string,
      },
    }
  })

  return result
}

export const generateRedditVisualization = (
  fields: Field[]
): WidgetVisualization => {
  const options = fields.reduce((prev, next) => {
    prev[next.field] = next.field

    return prev
  }, {} as RedditVisualizationFields)

  return {
    type: 'reddit',
    options: {
      fields: {
        createdAt: options['createdAt'],
        permaLink: options['permaLink'],
        postFullId: options['postFullId'],
        score: options['score'],
        title: options['title'],
        userIconImg: options['userIconImg'],
        userName: options['userName'],
      },
    },
  }
}
