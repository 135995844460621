import {
  mapQueryForRequest,
  mapQueryForResponse,
} from 'services/http/bi-tool/query/mappers'

import { Widget, WidgetResponse, WidgetVisualization } from './types'

export const mapWidgetForRequest = (raw: Widget): unknown => ({
  id: raw.id,
  title: raw.title,
  type: raw.type || 'json',
  description: raw.description,
  criteria: raw.criteria,
  visualization: {
    ...raw.visualization,
    version: raw.version,
  },
  query: mapQueryForRequest(
    raw.query,
    raw.visualization as WidgetVisualization
  ),
})

export const mapWidgetForResponse = (raw: any): Widget => ({
  id: raw.data.id,
  title: raw.data.title,
  type: raw.data.type || 'json',
  version: raw.data.visualization.version,
  criteria: raw.data.criteria,
  description: raw.data.description,
  visualization: raw.data.visualization,
  query: mapQueryForResponse(raw.data.query),
  dashboardKeys: raw.data?.dashboardKeys,
})

export const mapWidgetForList = (raw: any): WidgetResponse => ({
  list: raw.data.map((item: unknown) => mapWidgetForResponse({ data: item })),
  dataCount: raw.dataCount,
  pageCount: raw.pageCount,
  message: raw.message,
  success: raw.success,
})
