import { request } from 'services/http/dataset/instance'

import type {
  CreatePartOfSpeechRequest,
  PartOfSpeech,
  PartOfSpeechResponse,
  UpdatePartOfSpeechRequest,
} from './types'

import {
  mapPartOfSpeechForList,
  mapPartOfSpeechForRequest,
  mapPartOfSpeechForResponse,
} from './mappers'

export const create = (data: CreatePartOfSpeechRequest) =>
  request<PartOfSpeech>({
    method: 'post',
    url: 'part-of-speech',
    data,
    mappers: {
      req: mapPartOfSpeechForRequest,
    },
  })

export const get = (value?: string) =>
  request<PartOfSpeechResponse>({
    method: 'get',
    url: 'part-of-speech',
    params: { value },
    mappers: {
      res: mapPartOfSpeechForList,
    },
  })

export const getById = (id: number) =>
  request<PartOfSpeech>({
    method: 'get',
    url: `part-of-speech/${id}`,
    mappers: {
      res: mapPartOfSpeechForResponse,
    },
  })

export const update = (data: UpdatePartOfSpeechRequest) =>
  request<PartOfSpeech>({
    method: 'put',
    url: `part-of-speech/${data.id}`,
    data,
    mappers: {
      req: mapPartOfSpeechForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `part-of-speech/${id}`,
  })
