import { request } from 'services/http/backoffice/instance'

import type {
  AlarmReq,
  CreateParams,
  ManualCreateParams,
  TwitterV2MainTelegramAlarmRuleResponse,
} from './types'
import { mapTwitterV2MainTelegramAlarmRuleForList } from './mappers'
import { listRequestParams } from 'app/functions/string'

// import { mapBlackListForList } from './mappers'

export const get = (
  value?: string,
  pageIndex?: number,
  paused?: number,
  pageSize?: number
) =>
  request<TwitterV2MainTelegramAlarmRuleResponse>({
    method: 'get',
    url: 'back-office/v2-main-telegram-alarm-rule',
    params: listRequestParams({
      PageIndex: pageIndex,
      alarmName: value,
      paused: paused === -1 ? '' : Boolean(paused),
      PageSize: pageSize,
    }),
    mappers: {
      res: mapTwitterV2MainTelegramAlarmRuleForList,
    },
  })

export const getById = (id: string) =>
  request<any>({
    method: 'get',
    url: `back-office/v2-main-telegram-alarm-rule/${id}`,
    params: {},
    // mappers: {
    //   res: mapBlackListForList,
    // },
  })

export const getSettings = (alarmType: number = 32) =>
  request<AlarmReq>({
    method: 'get',
    url: 'back-office/v2-main-telegram-alarm-rule/get-settings',
    params: {
      alarmType,
    },
    // mappers: {
    //   res: mapBlackListForList,
    // },
  })

export const create = (params: CreateParams) =>
  request<any>({
    method: 'post',
    url: 'back-office/v2-main-telegram-alarm-rule',
    data: params,
    // mappers: {
    //   req: mapBlackListForRequest,
    // },
  })

export const update = (params: CreateParams, id: string) =>
  request<any>({
    method: 'put',
    url: `back-office/v2-main-telegram-alarm-rule/${id}`,
    data: params,
    // mappers: {
    //   req: mapBlackListForRequest,
    // },
  })

export const putById = (id: string) =>
  request<any>({
    method: 'patch',
    url: `back-office/v2-main-telegram-alarm-rule/toggle-pause/${id}`,
  })

export const manualCreate = (params: ManualCreateParams) =>
  request<any>({
    method: 'post',
    url: 'back-office/alarm-v2/send-manual-alarm',
    data: params,
    // mappers: {
    //   req: mapBlackListForRequest,
    // },
  })
