import React, { useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import * as yup from 'yup'
import Button, { ButtonGroup, LinkButton } from '@zera-admin/button'
import Form, { FormFooter } from '@zera-admin/form'
import Input from '@zera-admin/input'
import { string } from '@zera-admin/helpers'

import { usePopupContext } from 'app/contexts/popup'
import { App } from 'services/http/identity-server/app'

import { AppFormProps } from '../types'

const AppForm: React.FunctionComponent<AppFormProps> = ({
  values,
  onSubmit,
  operation,
  loading,
}) => {
  const [form, setForm] = useState<App>(values)
  const [popup] = usePopupContext()
  const validations = {
    name: yup.string().required('Uygulama adı girmelisiniz.'),
  }

  const handleSubmit = () => {
    const schema = yup.object().shape(validations)

    schema
      .validate(form, { abortEarly: false })
      .then(() => {
        onSubmit(form)
      })
      .catch((err) => {
        popup.add({
          appearance: 'error',
          title: 'Doğrulama hatası',
          children: (
            <div>
              <p>
                Aşağıdaki kriterlere dikkate alarak, formu gözden geçiriniz.
              </p>
              <ul className="errors">
                {(err.errors || []).map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
        })
      })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Column xs={{ size: 12 }}>
          <Input
            label="Uygulama adı"
            description="Uygulamanıza benzersiz bir isim veriniz. Verdiğiniz isim küçük harflerle ve arasında boşluk olmadan olmalıdır."
            name="name"
            placeholder="anonymous-api"
            value={form.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, name: event.target.value })
            }
            onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, name: string.slugify(event.target.value) })
            }
          />
        </Column>
      </Row>
      <FormFooter>
        <ButtonGroup>
          <Button appearance="primary" isLoading={loading} type="submit">
            {operation === 'create'
              ? 'Uygulama oluştur'
              : 'Uygulamayı güncelle'}
          </Button>
          <LinkButton href="/apps">Vazgeç</LinkButton>
        </ButtonGroup>
      </FormFooter>
    </Form>
  )
}

export default AppForm
