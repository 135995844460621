import CompoundButton from 'components/compound-button'
import * as Styled from '../Dashboardlist.styled'

import Icon from '@zera-admin/icon'

function DashboardItem(props: any) {
  const { dashboard, isSelected, setIsSelected, isEdit } = props
  return (
    <>
      {!isEdit ? (
        <CompoundButton
          iconBefore={<Icon name="dashboard" />}
          appearance="button"
          href={`/bi-tool/dashboard/update/${dashboard.id}`}
        >
          {dashboard.title}

          <p>{dashboard.description || 'Tıklayarak görüntüleyebilirsiniz'}</p>
        </CompoundButton>
      ) : (
        <CompoundButton
          iconBefore={<Icon name="dashboard" />}
          appearance="button"
          style={{ cursor: 'default' }}
        >
          {dashboard.title}
          <Styled.DashboardList customProp={isSelected}>
            <button
              className="btn-i"
              onClick={() => {
                setIsSelected(dashboard.id)
              }}
            >
              {!isSelected ? (
                <i
                  style={{ fontSize: '1rem' }}
                  className="fa-regular fa-circle-xmark"
                />
              ) : (
                <i
                  style={{ fontSize: '1rem' }}
                  className="fa-regular fa-circle-check"
                />
              )}
            </button>
          </Styled.DashboardList>
          <p>{dashboard.description || 'Tıklayarak görüntüleyebilirsiniz'}</p>
        </CompoundButton>
      )}
    </>
  )
}

export default DashboardItem
