import React, { useEffect, useState } from 'react'
import { LinkButton } from '@zera-admin/button'
import Alert from '@zera-admin/alert'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import DataBox from '@zera-admin/alice-data-box'
import Page, { PageHeader, Container, Row, Column } from '@zera-admin/page'

import TwitterProfile from 'components/twitter-profile'
import MainLayout from 'layouts/main'
import crawlTasksService from 'services/http/report/crawl-task'

import { ProcessPersonReportProps } from './types'

const ProcessPersonReport: React.FunctionComponent<ProcessPersonReportProps> =
  ({ match, history }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [personReport, setPersonReport] = useState<any>([])
    const [refreshCount, setRefreshCount] = useState<number>(0)

    const groupId = match.params.id

    const fetchData = (groupId: string) => {
      crawlTasksService
        .event(groupId)
        .then((res) => {
          setPersonReport(res.data.data)
        })
        .catch((err: Error) => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    useEffect(() => {
      setIsLoading(true)
    }, [])

    useEffect(() => {
      fetchData(groupId)
    }, [refreshCount, groupId, history])

    useEffect(() => {
      let interval = setInterval(() => {
        if (
          personReport.find((item: any) => item.pathId === 'person-detail')
            ?.progressPercentage !== 100
        )
          setRefreshCount(refreshCount + 1)
      }, 10000)

      return () => clearInterval(interval)
    })

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Kişi raporu" href="/person-report" />
        <BreadcrumbItem text="Kişi raporu detayı" />
      </Breadcrumb>
    )

    const getCategory = (sa: string) => {
      switch (sa) {
        case 'CountryDetectionCompletedCount':
          return 'Tamamlanan ülke tespit'
        case 'TotalAccountCount':
          return 'Toplam hesap'
        case 'AbsCompletedCount':
          return 'Tamamlanan ABS'
        case 'ClassificationCompletedCount':
          return 'Tamamlanan sınıflandırma'
        case 'EmojisCompletedCount':
          return 'Tamamlanan emoji'
        case 'GenderCompletedCount':
          return 'Tamamlanan cinsiyet'
        case 'SentimentCompletedCount':
          return 'Tamamlanan duygu analizi'
        case 'TotalPostCount':
          return 'Toplam tweet'
        case 'TranslatorCompletedCount':
          return 'Tamamlanan çeviri'

        default:
          break
      }
    }

    return (
      <MainLayout isLoading={isLoading}>
        <Container width="medium">
          <Page>
            <PageHeader
              // actions={<Button appearance="error">Delete user</Button>}
              breadcrumbs={breadcrumbs}
              bottomBar={<p>Kişinin detaylarını görüntülüyorsunuz</p>}
            >
              Kullanıcı verisi listeleniyor:{' '}
              {personReport
                ? personReport?.find(
                    (item: any) => item.pathId === 'person-detail'
                  )?.crawlProperties?.TrackedUser?.ScreenName
                : null}
            </PageHeader>
            <TwitterProfile
              data={
                personReport?.find(
                  (item: any) => item.pathId === 'person-detail'
                )?.crawlProperties?.TrackedUser
              }
            />
            {personReport.find((item: any) => item.pathId === 'person-detail')
              ?.status === 'Completed' ? (
              <Alert appearance="success">
                <p>Rapor işlenmesi tamamlanmıştır.</p>
                <LinkButton
                  style={{ marginTop: 10 }}
                  appearance="primary"
                  href={`/person-report/detail/${match.params.id}`}
                >
                  Raporu göster
                </LinkButton>
              </Alert>
            ) : null}

            <br />
            <br />

            <Row>
              {personReport.map((item: any, index: number) => (
                <Column
                  key={index}
                  xs={{ size: index === personReport.length - 1 ? 12 : 4 }}
                >
                  <DataBox
                    appearance={
                      item.status === 'Processing'
                        ? 'info'
                        : item.status === 'Completed'
                        ? 'success'
                        : 'warning'
                    }
                    span={index === personReport.length - 1 ? 3 : 1}
                    information={{
                      icon:
                        item.progressPercentage === 100
                          ? 'check-circle'
                          : 'refresh',
                      text: `${item.status} %${item.progressPercentage.toFixed(
                        2
                      )}`,
                    }}
                    title={item.eventName}
                    values={[
                      {
                        text: item.pathId,
                        title: 'Path Id',
                      },
                      {
                        text: item.dataProcessInfo,
                        title: 'Veri',
                      },
                      {
                        text: new Date(item.insertedInQueueAt).toLocaleString(),
                        title: 'Kuyruk atılma',
                      },
                      {
                        text: new Date(item.crawledStartDate).toLocaleString(),
                        title: 'İşlem başlangıç',
                      },
                      {
                        text: item.crawledEndDate
                          ? new Date(item.crawledEndDate).toLocaleString()
                          : '-',
                        title: 'İşlem tamamlanma',
                      },
                      ...(item.pathId === 'person-detail-statistic-calculating'
                        ? [
                            ...Object.entries(
                              item.crawlProperties.FollowersProgressInformation
                            ).map((item) => ({
                              title: `${getCategory(item[0])} (Takipçi)`,
                              text: item[1],
                            })),
                          ]
                        : []),
                      ...(item.pathId === 'person-detail-statistic-calculating'
                        ? [
                            ...Object.entries(
                              item.crawlProperties.PostsProgressInformation
                            ).map((item) => ({
                              title: `${getCategory(item[0])} (Tweet)`,
                              text: item[1],
                            })),
                          ]
                        : []),
                    ]}
                  />
                </Column>
              ))}
            </Row>
          </Page>
        </Container>
      </MainLayout>
    )
  }

export default ProcessPersonReport
