import React, { useState } from 'react'
import { Column, Row } from '@zera-admin/page'
import { FormHookSubmitProps, useForm } from '@zera-admin/form'
import Spinner from '@zera-admin/spinner'

import Pager from 'components/pager'
import TweetTrainingControls from 'components/tweet-training-controls'
import tweetService, {
  FilterProps,
  TweetResponse,
} from 'services/http/dataset/tweet'

import { TrainingWizardProps } from './types'
import { renderFilter } from './constants'

const TrainingWizard: React.FunctionComponent<TrainingWizardProps> = () => {
  const [tweet, setTweet] = useState<TweetResponse>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(0)

  const form = useForm<FormHookSubmitProps<FilterProps>>({
    onSubmit: (props) => handleFilter(props),
  })

  const handleFilter = async ({ values }: any) => {
    setIsLoading(true)
    try {
      const response = await tweetService.get({
        'LikeCount.Max': values.LikeCount.Max
          ? Number(`${values}.LikeCount.Max`)
          : undefined,
        'LikeCount.Min': values.LikeCount.Min
          ? Number(`${values}.LikeCount.Min`)
          : undefined,
        'RetweetCount.Max': values.RetweetCount.Max
          ? Number(`${values}.RetweetCount.Max`)
          : undefined,
        'RetweetCount.Min': values.RetweetCount.Min
          ? Number(`${values}.RetweetCount.Min`)
          : undefined,
        EndDate: values.EndDate,
        StartDate: values.StartDate,
        UserName: values.UserName,
        PageIndex: pageIndex,
        PageSize: 30,
      })
      setTweet(response.data)
      setPageIndex(0)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      {renderFilter(form)}
      <br /> <br />
      {!isLoading ? (
        tweet?.list.map((tweet, index) => (
          <TweetTrainingControls
            key={index}
            entityKey={tweet.entityKey}
            favorite={tweet.likeCount}
            profilImage={tweet.profilePicture}
            retweet={tweet.retweetCount}
            tweet={tweet.content}
            username={tweet.userName}
          />
        ))
      ) : (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Column xs={{ size: 1, align: 'center' }}>
            <Spinner size="large" />
          </Column>
        </Row>
      )}
      {tweet && tweet.list && tweet.list.length > 0 && tweet.pageCount > 0 ? (
        <Pager
          pages={tweet.pageCount}
          onChange={(index) => setPageIndex(index - 1)}
        />
      ) : null}
    </React.Fragment>
  )
}

export default TrainingWizard
