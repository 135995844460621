import React, { useEffect, useState } from 'react'
import * as Styled from './RedditPosts.styled'
import { Row, Column } from '@zera-admin/page'

import Analysis from '../../components/analysis'
import { RedditCard } from '../../components/reddit-card'

import { RedditPostsProps } from './types'

import UpdateButton from '../../components/update-button/UpdateButton'
import { PostReddit } from '../../widgets/types'
import WidgetLayout from '../../widgets/widget-layout'
import { AnalysisData } from '../../components/analysis/types'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { getAgendaReportReddit } from 'services/http/report/agenda-report-4/endpoint'

const RedditPosts: React.FC<RedditPostsProps> = ({
  date,
  isEditValue,
  setIsEditValue,
  pageNumber,
  title,
  data,
  metaData,
}) => {
  const [postData, setPostData] = useState<PostReddit | undefined>(data)
  const [videoId, setVideoId] = useState<string | undefined>('')

  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  const [flagCard] = useFlagcardContext()

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setPostData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const handleDeleteVideo = () => {
    setPostData(undefined)
  }

  const addVideo = () => {
    getAgendaReportReddit({
      GroupId: metaData?.GroupId,
      Url: videoId,
    })
      .then((res) => {
        setPostData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
        flagCard.add({
          appearance: 'error',
          title: 'Bir hata oluştu',
          children:
            'Veritabanında post bulunamadı. Lütfen url bilgisini kontrol ediniz.',
        })
      })
  }

  const onChange = (key: string, value: string) => {
    postData && setPostData({ ...postData, [key]: value })
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      <Styled.RedditAnalysisWrapper>
        <UpdateButton
          {...editProps}
          contentData={{
            Post: postData,
          }}
          title={pageData.title}
          editMode={editMode}
          setEditMode={setEditMode}
        />

        <Styled.RedditAnalysisContainer>
          <Row xs={{ justify: 'center' }}>
            {postData ? (
              <Column
                className="reddit"
                xs={{ size: postData?.Analysis ? 7 : 8 }}
              >
                <RedditCard
                  data={postData}
                  editmode={editMode}
                  onChange={onChange}
                />
                {editMode && (
                  <button className="delete-reddit" onClick={handleDeleteVideo}>
                    X
                  </button>
                )}
              </Column>
            ) : (
              <Column xs={{ size: 8 }}>
                <div className="add-reddit">
                  <input
                    type="text"
                    placeholder="Reddit Url Giriniz"
                    onChange={(e) => setVideoId(e.target.value)}
                  />
                  <button onClick={addVideo}>Post Ekle</button>
                </div>
              </Column>
            )}

            {postData?.Analysis && (
              <Column xs={{ size: 5 }}>
                <Analysis
                  editMode={editMode}
                  onChange={onChange}
                  data={postData as AnalysisData}
                />
              </Column>
            )}
          </Row>
        </Styled.RedditAnalysisContainer>
      </Styled.RedditAnalysisWrapper>
    </WidgetLayout>
  )
}

export default RedditPosts
