import styled from 'styled-components'

export const EksiCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content !important;

  .eksi-green {
    border-radius: 0 0 8px 8px;
    background-color: #81c04b;
  }

  .post {
    display: flex;
    flex-direction: column;

    &-header {
      font-size: 22px;
      font-weight: bold;
      color: #333;
      padding: 16px;
    }

    &-body {
      padding: 0 16px;

      &-entry {
        word-wrap: break-word;
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }

      button {
        background: none;
        border: none;
        color: #333;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      &-info {
        padding-top: 32px;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;

        &-count {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          font-weight: 500;
          font-size: 14px;
          color: #888;
        }

        &-author {
          display: flex;
          gap: 10px;
          &-wrap {
            display: flex;
            flex-direction: column;
            line-height: 14px;
            font-size: 14px;
            justify-content: center;
            align-items: end;
          }
          &-name {
            color: #81c04b;
            justify-content: end;
            display: flex;
          }
          &-date {
            color: #888;
            justify-content: end;
            display: flex;
          }

          &-profile {
            width: 40px;
            height: 40px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 1px solid #e0e0e0;
            }
          }
        }
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #e0e0e0;
      &-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 8px 4px 8px;
        img {
          height: 20px;
          width: 120px;
          object-fit: cover;
        }
      }
      &-url {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 0;

        a {
          color: #fff;

          border-radius: 4px;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
`
