import styled from 'styled-components'

export const MainView = styled.button`
  border: 1px solid #034476;
  color: #fff;
  border-radius: 16px;
  padding: 12px 15px;
  background-color: rgba(0, 15, 44, 0.8);
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 111;
  box-sizing: border-box;

  p {
    font-size: 15px;
    font-weight: lighter;
  }

  b {
    font-size: 20px;
  }
`

export const NameValues = styled.div`
  width: 100%;
`
