import React, { useState, useEffect } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import { uid } from '@zera-admin/utils'
import TimeRangePicker from '@zera-admin/alice-time-range-picker'

import { useDashboardContext } from 'app/contexts/dashboard'
import { useFlagcardContext } from 'app/contexts/flagcard'
import datasourceService, {
  Datasource,
  Table,
} from 'services/http/bi-tool/datasource'
import fieldService, { Field, FieldRequest } from 'services/http/bi-tool/field'
import dashboardService, { Dashboard } from 'services/http/bi-tool/dashboard'
import Loader from 'components/loader'
import BIToolLayout from 'layouts/bi-tool'
import DashboardDesigner from 'pages/bi-tool/dashboard/dashboard-designer'

import * as Styled from './UpdateDashboard.styled'
import { UpdateDashboardProps } from './types'

const UpdateDashboard: React.FunctionComponent<UpdateDashboardProps> = ({
  history,
  match,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isPreviewLoading, setIsPreviewLoading] = useState(true)
  const [isPreview, setIsPreview] = useState(true)
  const [filterVisibility, setFilterVisibility] = useState(false)
  const [sources, setSources] = useState<Datasource[]>([])
  const [tables, setTables] = useState<Table[]>([])
  const [fields, setFields] = useState<Field[]>([])
  const [values, setValues] = useState<Dashboard>({} as Dashboard)
  const [interval, setInterval] = useState(values.query?.interval)
  const [copy, setCopy] = useState(false)

  const [flagcard] = useFlagcardContext()
  const dashboardContext = useDashboardContext()
  useEffect(() => {
    const id = match.params.id

    dashboardService
      .getById(id)
      .then((res) => {
        setIsLoading(false)
        setValues(res.data || {})
        getPreview(res.data.schema)

        getDatasources()
        getTables(res.data.query?.source)
        getFields({
          datasource: res.data.query?.source,
          table: res.data.query?.table,
        })
      })
      .catch((err: Error) => {
        setIsLoading(false)
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, history])

  useEffect(() => {
    if (values.query?.interval) {
      setInterval(values.query?.interval)
    }
  }, [values.query?.interval])

  const getDatasources = () => {
    datasourceService
      .get()
      .then((res) => {
        setSources(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getTables = (datasource: string) => {
    datasourceService
      .getTables(datasource)
      .then((res) => {
        setTables(res.data)
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getFields = (request: FieldRequest) => {
    fieldService
      .get(request)
      .then((res) => {
        if (res.data) {
          setFields(res.data)
        }
      })
      .catch((err: Error) => {
        console.log(err)
      })
  }

  const getPreview = (schema: unknown[]) => {
    dashboardService
      .preview({ schema })
      .then((response) => {
        dashboardContext.set(response.data)
      })
      .finally(() => {
        setIsPreviewLoading(false)
      })
  }

  const handleSubmit = () => {
    const id = match.params.id

    if (values) {
      setIsLoading(true)

      if (copy) {
        const { key, ...newValues } = values
        newValues.title += ' - copy'
        dashboardService
          .create({ ...newValues, schema: dashboardContext.state.schema })
          .then((res) => {
            const value = res.data

            if (value) {
              history.push(`/bi-tool/dashboard/update/${value.data}`)

              flagcard.add({
                id: uid(),
                appearance: 'success',
                title: 'Dashboard kopyalandı',
                children:
                  'Girmiş olduğunuz bilgiler ile birlikte dashboard sisteme başarıyla kayıt edilmiştir. Oluşturduğunuz dashboard`ı düzenleyebilirsiniz.',
              })
            }
          })
          .catch((error) => {
            console.log(error)

            flagcard.add({
              id: uid(),
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Dashboard eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      } else {
        dashboardService
          .update(id, { ...values, schema: dashboardContext.state.schema })
          .then((res) => {
            flagcard.add({
              id: uid(),
              appearance: 'success',
              title: 'Dashboard güncellendi',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte dashboard sistem üzerinde başarıyla güncellenmiştir.',
            })
          })
          .catch((error) => {
            console.log(error)

            flagcard.add({
              id: uid(),
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Dashboard eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }

  const handleDelete = () => {
    setIsLoading(true)

    dashboardService
      .del(match.params.id)
      .then(() => {
        history.push('/bi-tool/dashboards')
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleChangePreview = (checked: boolean, schema?: unknown[]) => {
    if (checked) {
      setIsPreviewLoading(true)

      getPreview(schema || dashboardContext.state.schema)
    }

    setIsPreview(checked)
  }

  const handleChangeValues = (values: Dashboard) => {
    setValues(values)
  }

  const renderPageHeader = () => {
    const bottomBar = (
      <p>
        {values.description ||
          'Görselleştirilmiş verileri bir arada görüntülemek için panonuzu tasarlayın.'}
      </p>
    )
    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Dashboardlar" href="/bi-tool/dashboards" />
        <BreadcrumbItem text="Dashboard güncelle" />
      </Breadcrumb>
    )
    const actions = isPreview ? (
      <Row xs={{ direction: 'row' }}>
        {interval && (
          <Column>
            <Styled.DashboardTimePicker>
              <TimeRangePicker
                defaultValues={interval}
                onChange={setInterval}
              />
            </Styled.DashboardTimePicker>
          </Column>
        )}
        <Column>
          <ButtonGroup>
            <Button
              appearance="primary"
              iconBefore={<Icon name="refresh" size="small" />}
              onClick={() =>
                handleChangeValues({
                  ...values,
                  query: { ...values.query, interval },
                })
              }
            >
              Yenile
            </Button>
            <Button
              iconBefore={<Icon name="filter" size="small" />}
              onClick={() => setFilterVisibility(true)}
            >
              Filtre ve hesaplamalar
            </Button>
            <Button
              className="primary-outline"
              iconBefore={<Icon name="lightbulb" size="small" />}
              onClick={() => handleChangePreview(!isPreview)}
            >
              Tasarım Modu
            </Button>
            <Button
              className="primary-outline"
              iconBefore={<Icon name="lightbulb" size="small" />}
              onClick={() => setCopy(!copy)}
            >
              {copy ? 'Kopyalamayı geri al' : 'Dashboard kopyala'}
            </Button>
          </ButtonGroup>
        </Column>
      </Row>
    ) : (
      <Button
        className="success-outline"
        iconBefore={<Icon name="vid-play" size="small" />}
        onClick={() => handleChangePreview(!isPreview)}
      >
        Önizleme Modu
      </Button>
    )

    return (
      <PageHeader
        actions={values?.query?.table ? actions : undefined}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        {values?.title || 'Dashboard'}
      </PageHeader>
    )
  }

  return (
    <BIToolLayout>
      <Page>
        <Styled.UpdateDashboard>
          {renderPageHeader()}

          {isLoading || isPreviewLoading ? (
            <Loader
              show={isLoading || isPreviewLoading}
              size="medium"
              label={
                values.id && !isPreviewLoading
                  ? 'Dashboard güncelleniyor...'
                  : 'Dashboard yükleniyor...'
              }
            />
          ) : (
            <DashboardDesigner
              fields={fields}
              filterVisibility={filterVisibility}
              isPreview={isPreview}
              onChange={handleChangeValues}
              onDelete={handleDelete}
              onGetFields={getFields}
              onGetTables={getTables}
              onSave={handleSubmit}
              operation="update"
              setFilterVisibility={setFilterVisibility}
              sources={sources}
              tables={tables}
              values={values}
              copy={copy}
            />
          )}
        </Styled.UpdateDashboard>
      </Page>
    </BIToolLayout>
  )
}

export default UpdateDashboard
