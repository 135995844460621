import React, { useEffect, useState } from 'react'
import * as Styled from './CountryReflections.styled'
import { CountryReflectionsProps, ICountryReflections } from './types'
import { numberFormat } from 'app/functions/number'
import WidgetLayout from 'pages/agenda-report-v4/detail/widgets/widget-layout'
import UpdateButton from 'pages/agenda-report-v4/detail/components/update-button/UpdateButton'

const CountryReflections: React.FC<CountryReflectionsProps> = ({
  date,
  data,
  title,
  isEditValue,
  setIsEditValue,
  pageNumber,
  metaData,
}) => {
  const [tableData, setTableData] = useState<ICountryReflections[] | undefined>(
    data
  )
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setTableData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChange = (key: string, value: string, source: string) => {
    // setTableData(
    //   tableData?.map((item) => {
    //     if (item.ScreenName === source) {
    //       return {
    //         ...item,
    //         [key]: value,
    //       }
    //     }
    //     return item
    //   })
    // )
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  const colors = ['#fcf2d0', '#F5E6D9', '#DEE2F1', '#E3EAF3', '#E3EAF3']

  const renderTable = (region: ICountryReflections, regionIndex: number) => {
    return (
      <div
        className="table"
        key={regionIndex}
        style={{ marginBottom: '20px', backgroundColor: colors[regionIndex] }}
      >
        <table>
          <thead>
            <tr>
              <th
                colSpan={region?.Countries?.[0]?.ReflectionCounts?.length + 1}
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                {region.Region}
              </th>
            </tr>
            <tr>
              <th>Ülke</th>
              {region.Countries[0].ReflectionCounts.map(
                (reflection, reflectionIndex) => (
                  <th key={`th-${reflectionIndex}`}>{reflection.DataType}</th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {region.Countries.map((country, countryIndex) => (
              <>
                <tr key={`${countryIndex}`}>
                  <td>{country.Country}</td>
                  {country.ReflectionCounts.map((reflection) => (
                    <td>{reflection.Count}</td>
                  ))}
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      {data && data?.length > 0 ? (
        <Styled.TableContent>
          <div className="edit-btn-container">
            <UpdateButton
              {...editProps}
              contentData={{ Accounts: tableData }}
              title={pageData?.title}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </div>

          <Styled.TableContainer>
            {data[0] && <div className="column">{renderTable(data[0], 0)}</div>}
            {data[1] && data[2] && (
              <div className="column">
                {renderTable(data[1], 1)}
                {renderTable(data[2], 2)}
              </div>
            )}
            {data[3] && data[4] && (
              <div className="column">
                {renderTable(data[3], 3)}
                {renderTable(data[4], 4)}
              </div>
            )}
          </Styled.TableContainer>
        </Styled.TableContent>
      ) : (
        <h3>Veri Bulunamadi</h3>
      )}
    </WidgetLayout>
  )
}

export default CountryReflections
