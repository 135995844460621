import React, { useState } from 'react'
import DataBox from '@zera-admin/alice-data-box'
import { Row, Column } from '@zera-admin/page'
import Button, { ButtonGroup } from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import { uid } from '@zera-admin/utils'
import Drawer from '@zera-admin/drawer'

import { usePopupContext } from 'app/contexts/popup'
import { useFlagcardContext } from 'app/contexts/flagcard'
import agendaReportService from 'services/http/report/agenda-report'
import { AgendaReportStatus } from 'services/http/report/agenda-report/enums'
import {
  CreateAgendaReportRequest,
  TaskInfos,
} from 'services/http/report/agenda-report/types'

import { AgendaReportForm } from '../form'
import * as Styled from './DetailAgendaReport.styled'
import { DetailAgendaReportProcessProps } from './types'

const DetailAgendaReportProcess: React.FunctionComponent<DetailAgendaReportProcessProps> =
  ({ groupId, task, onFetch }) => {
    const [popup] = usePopupContext()
    const [flagcard] = useFlagcardContext()
    const [isDeleteLoading, setIsDeleteLaoding] = useState(false)
    const [isPauseLoading, setIsPauseLoading] = useState(false)
    const [isStopLoading, setIsStopLoading] = useState(false)
    const [isDefinitionLoading, setIsDefinitionLoading] = useState(false)
    const [definitionDrawerVisibility, setDefinitionDrawerVisibility] =
      useState(false)

    const handleInsertDefinitions = (values: CreateAgendaReportRequest) => {
      setIsDefinitionLoading(true)
      setDefinitionDrawerVisibility(false)

      agendaReportService
        .addDefinitions({ ...values, groupId })
        .then(() => {
          flagcard.add({
            title: 'Tanımlamalar başarıyla eklendi',
            children:
              'İlgili gündem raporu üzerine yeni tanımlamalar başarıyla eklendi. Tanımlamaların veri toplama işlemine başlanıyor.',
          })

          onFetch()
        })
        .catch(() => {
          flagcard.add({
            appearance: 'error',
            title: 'Tanımlama eklenemedi.',
            children:
              'Tanımlama ekleme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyiniz.',
          })
        })
        .finally(() => {
          setIsDefinitionLoading(false)
        })
    }

    const handleStop = () => {
      const popupId = uid()

      const onClick = () => {
        if (groupId) {
          setIsStopLoading(true)
          popup.remove(popupId)

          agendaReportService
            .stopEvents(groupId)
            .then(() => {
              flagcard.add({
                title: 'Veri toplama durduruldu',
                children: `Veri toplama işlemi durduruldu. Diğer adıma yönlendiriliyorsunuz.`,
              })

              onFetch()
            })
            .catch(() => {
              flagcard.add({
                appearance: 'error',
                title: 'Veri toplama durdurulamadı',
                children:
                  'Durdurma işlemi sırasında bir hata oluştu. Lütfen tekrar deneyiniz.',
              })
            })
            .finally(() => {
              setIsStopLoading(false)
            })
        }
      }

      const onClose = () => {
        popup.remove(popupId)
      }

      popup.add({
        id: popupId,
        appearance: 'warning',
        title: 'Veri toplama durdurulsun mu?',
        children:
          'İlgili gündem raporuna ait veri toplama işlemi durdurulsun mu? Bu işlem geri alınamaz.',
        actions: [
          {
            isLoading: isStopLoading,
            onClick,
            text: 'Veri toplamayı durdur',
          },
          {
            onClick: onClose,
            text: 'Vazgeç',
          },
        ],
        onClose,
      })
    }

    const handlePauseTask = (crawlTaskId: string, pathId: string) => {
      const popupId = uid()

      const onClick = () => {
        if (crawlTaskId) {
          setIsPauseLoading(true)
          popup.remove(popupId)

          agendaReportService
            .stopDefinition(crawlTaskId)
            .then(() => {
              flagcard.add({
                title: 'İşlem durduruldu',
                children: `Seçmiş olduğunuz işlem (${pathId}) durduruldu.`,
              })

              onFetch()
            })
            .catch(() => {
              flagcard.add({
                appearance: 'error',
                title: 'İşlem durdurulamadı',
                children:
                  'Durdurma işlemi sırasında bir hata oluştu. Lütfen tekrar deneyiniz.',
              })
            })
            .finally(() => {
              setIsPauseLoading(false)
            })
        }
      }

      const onClose = () => {
        popup.remove(popupId)
      }

      popup.add({
        id: popupId,
        appearance: 'warning',
        title: 'İşlem durdurulsun mu?',
        children:
          "Seçmiş olduğunuz task'e ait veri akışı durdurulsun mu? Bu işlem geri alınamaz.",
        actions: [
          {
            isLoading: isPauseLoading,
            onClick,
            text: 'İşlemi durdur',
          },
          {
            onClick: onClose,
            text: 'Vazgeç',
          },
        ],
        onClose,
      })
    }

    const handleDeleteTask = (crawlTaskId: string, pathId: string) => {
      const popupId = uid()

      const onClick = () => {
        if (crawlTaskId) {
          setIsDeleteLaoding(true)
          popup.remove(popupId)

          agendaReportService
            .removeDefinition(crawlTaskId)
            .then(() => {
              flagcard.add({
                title: 'İşlem kaldırıldı',
                children: `Seçmiş olduğunuz işlem (${pathId}) gündem raporu üzerinden kaldırıldı.`,
              })

              onFetch()
            })
            .catch(() => {
              flagcard.add({
                appearance: 'error',
                title: 'İşlem kaldırılamadı',
                children:
                  'Kaldırma işlemi sırasında bir hata oluştu. Lütfen tekrar deneyiniz.',
              })
            })
            .finally(() => {
              setIsDeleteLaoding(false)
            })
        }
      }

      const onClose = () => {
        popup.remove(popupId)
      }

      popup.add({
        id: popupId,
        appearance: 'warning',
        title: 'İşlem kaldırılsın mı?',
        children:
          "Seçmiş olduğunuz task'e ait veri akışı silinsin mi? Bu işlem'le birlikte gündem raporu üzerinde, geçerli task'e ait veriler gözükmeyecektir. Aynı zamanda geçerli işlem'in ilişkili olduğu stream / fetch işlemleri de kaldırılacaktır.",
        actions: [
          {
            isLoading: isDeleteLoading,
            onClick,
            text: 'İşlemi kaldır',
          },
          {
            onClick: onClose,
            text: 'Vazgeç',
          },
        ],
        onClose,
      })
    }

    const renderDefinitionDrawer = () => {
      return (
        <Drawer
          title="Yeni tanımlama ekle"
          description=" Mevcut gündem raporu üzerine, aşağıdan yeni tanımlama / tanımlamalar
          ekleyebilirsiniz"
          width="medium"
          isOpen={definitionDrawerVisibility}
          position="right"
          onClose={() => setDefinitionDrawerVisibility(false)}
        >
          <AgendaReportForm
            data={{
              requestField: [],
              selectedEvents: [],
              description: 'Gündem raporu',
              includeActiveUsersAndCommunities: false,
              taskInfos: (task.main.crawlProperties.TaskInfos || []).map(
                (task) => ({
                  dataSource: 'Twitter',
                  keywords: [],
                  links: [],
                  userNames: [],
                })
              ),
            }}
            isLoading={isDefinitionLoading}
            onSubmit={(values) => handleInsertDefinitions(values)}
            operation="modal"
          />
        </Drawer>
      )
    }

    const taskStatus = (status: AgendaReportStatus): string => {
      if (status === AgendaReportStatus.Ready) return 'İşlenmeye Hazır'
      else if (status === AgendaReportStatus.Processing) return 'İşleniyor'
      else if (status === AgendaReportStatus.Stopped) return 'Durduruldu'
      else if (status === AgendaReportStatus.Pending) return 'Kuyrukta Bekliyor'
      else if (status === AgendaReportStatus.Exists)
        return 'Halihazırda Çalışıyor'

      return 'Tamamlandı'
    }

    const taskAppearance = (status: AgendaReportStatus) => {
      if (status === AgendaReportStatus.Processing) return 'info'
      else if (status === AgendaReportStatus.Completed) return 'success'

      return 'warning'
    }

    const renderMainTask = () => {
      const result = task.main
      const twitterTask = result.crawlProperties.TaskInfos?.length
        ? result.crawlProperties.TaskInfos[0]
        : ({} as TaskInfos)

      return (
        <Column xs={{ size: 4 }} style={{ position: 'relative' }}>
          <DataBox
            appearance={taskAppearance(result.status as AgendaReportStatus)}
            information={{
              icon:
                result.status === AgendaReportStatus.Completed
                  ? 'check-circle'
                  : 'refresh',
              text: `${taskStatus(result.status as AgendaReportStatus)}`,
            }}
            title="Ana İşlem"
            values={[
              {
                text: (
                  twitterTask.PostsProgressInformation?.TotalPostCount || 0
                )?.toString(),
                title: 'Toplam alınan post sayısı',
              },
              {
                text: (
                  twitterTask.AccountsProgressInformation?.TotalAccountCount ||
                  0
                )?.toString(),
                title: 'Toplam alınan hesap sayısı',
              },
              {
                text: new Date(result.insertedInQueueAt)?.toLocaleString(),
                title: 'Kuyruk atılma',
              },
              {
                text: result.crawledStartDate
                  ? new Date(result.crawledStartDate)?.toLocaleString()
                  : '-',
                title: 'İşlem başlangıç',
              },
              {
                text: result.crawledEndDate
                  ? new Date(result.crawledEndDate)?.toLocaleString()
                  : '-',
                title: 'İşlem tamamlanma',
              },
            ]}
          />
        </Column>
      )
    }

    const renderTasks = () => (
      <Row>
        {renderMainTask()}
        {task?.definitions
          .filter((item) => item.pathId !== task.main.pathId)
          .map((item, index: number) => (
            <Column
              key={index}
              xs={{ size: 4 }}
              style={{ position: 'relative' }}
            >
              <Styled.DetailAgendaReportHeader>
                <Styled.DetailAgendaReportHeaderTools>
                  <ButtonGroup>
                    {item.status !== AgendaReportStatus.Completed ? (
                      <Button
                        size="small"
                        isLoading={isPauseLoading}
                        onClick={() =>
                          handlePauseTask(item.crawlTaskId, item.pathId)
                        }
                      >
                        <Icon name="vid-pause" size="small" />
                      </Button>
                    ) : null}
                    <Button
                      size="small"
                      isLoading={isDeleteLoading}
                      onClick={() =>
                        handleDeleteTask(item.crawlTaskId, item.pathId)
                      }
                    >
                      <Icon name="cross" size="small" />
                    </Button>
                  </ButtonGroup>
                </Styled.DetailAgendaReportHeaderTools>
              </Styled.DetailAgendaReportHeader>
              <DataBox
                appearance={taskAppearance(item.status as AgendaReportStatus)}
                information={{
                  icon:
                    item.status === AgendaReportStatus.Completed
                      ? 'check-circle'
                      : 'refresh',
                  text: `${taskStatus(item.status as AgendaReportStatus)}`,
                }}
                values={[
                  {
                    text: item.eventName
                      ? `[${item.eventName}] - ${
                          item.crawlProperties.Value || '-'
                        }`
                      : item.crawlProperties.Value,
                    title: 'İşlem açıklaması',
                  },
                  {
                    text: (item.crawlProperties.InsertedPosts || 0)?.toString(),
                    title: 'Toplanan veri',
                  },
                  {
                    text: item.insertedInQueueAt
                      ? new Date(item.insertedInQueueAt)?.toLocaleString()
                      : '-',
                    title: 'Kuyruk atılma',
                  },
                  {
                    text: item.crawledStartDate
                      ? new Date(item.crawledStartDate)?.toLocaleString()
                      : '-',
                    title: 'İşlem başlangıç',
                  },
                  {
                    text: item.crawledEndDate
                      ? new Date(item.crawledEndDate)?.toLocaleString()
                      : '-',
                    title: 'İşlem tamamlanma',
                  },
                ]}
              />
            </Column>
          ))}
      </Row>
    )

    return (
      <Styled.DetailAgendaReportProcess>
        {renderTasks()}
        {renderDefinitionDrawer()}
        <footer>
          <ButtonGroup>
            <Button
              children="Tanımlama ekle"
              appearance="button"
              onClick={() => setDefinitionDrawerVisibility(true)}
              isLoading={isDefinitionLoading}
            />
            <Button
              children="Veri toplamayı durdur"
              appearance="primary"
              onClick={handleStop}
              isLoading={isStopLoading}
            />
          </ButtonGroup>
        </footer>
      </Styled.DetailAgendaReportProcess>
    )
  }

export default DetailAgendaReportProcess
