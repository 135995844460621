import RowDesigner from './RowDesigner'

import { RowProps } from './types'

const Row: React.FunctionComponent<RowProps> = (props) => {
  return (
    <div
      data-schema-id={props.id}
      className={`fa-draggable fa-Grid row ${
        props.designing ? 'designing' : ''
      } ${props.defaultElement ? 'is-default' : ''}`}
      style={{
        minHeight: props.designing ? '100px' : 'auto',
        position: 'relative',
      }}
    >
      {props.designing ? <RowDesigner {...props} /> : props.children}
    </div>
  )
}

export default Row
