import React from 'react'
import DataBox from '@zera-admin/alice-data-box'
import { Row, Column } from '@zera-admin/page'
import Lozenge from '@zera-admin/lozenge'

import { AgendaReportStatus } from 'services/http/report/agenda-report/enums'

import * as Styled from './DetailAgendaReport.styled'
import { DetailAgendaReportCalculatingProps } from './types'
import { TaskInfos } from 'services/http/report/agenda-report/types'

const DetailAgendaReportCalculating: React.FunctionComponent<DetailAgendaReportCalculatingProps> =
  ({ task }) => {
    const taskStatus = (status: AgendaReportStatus): string => {
      if (status === AgendaReportStatus.Ready) return 'Hesaplanmaya Hazır'
      else if (status === AgendaReportStatus.Processing) return 'İşleniyor'
      else if (status === AgendaReportStatus.Calculating) return 'Hesaplanıyor'
      else if (status === AgendaReportStatus.Stopped) return 'Durduruldu'
      else if (status === AgendaReportStatus.Pending) return 'Kuyrukta Bekliyor'
      else if (status === AgendaReportStatus.Exists)
        return 'Halihazırda Çalışıyor'

      return 'Tamamlandı'
    }

    const taskAppearance = (status: AgendaReportStatus) => {
      if (status === AgendaReportStatus.Processing) return 'info'
      else if (status === AgendaReportStatus.Completed) return 'success'

      return 'warning'
    }

    const renderMainTask = () => {
      const result = task.main
      const twitterTask = result.crawlProperties.TaskInfos?.length
        ? result.crawlProperties.TaskInfos[0]
        : ({} as TaskInfos)

      return (
        <Column xs={{ size: 4 }} style={{ position: 'relative' }}>
          <DataBox
            appearance={taskAppearance(result.status as AgendaReportStatus)}
            information={{
              icon:
                result.status === AgendaReportStatus.Completed
                  ? 'check-circle'
                  : 'refresh',
              text: `${taskStatus(result.status as AgendaReportStatus)}`,
            }}
            title="Ana İşlem"
            values={[
              {
                text: (
                  twitterTask.PostsProgressInformation?.TotalPostCount || 0
                )?.toString(),
                title: 'Toplam alınan post sayısı',
              },
              {
                text: (
                  twitterTask.AccountsProgressInformation?.TotalAccountCount ||
                  0
                )?.toString(),
                title: 'Toplam alınan hesap sayısı',
              },
              {
                text: new Date(result.insertedInQueueAt).toLocaleString(),
                title: 'Kuyruk atılma',
              },
              {
                text: result.crawledStartDate
                  ? new Date(result.crawledStartDate).toLocaleString()
                  : '-',
                title: 'İşlem başlangıç',
              },
              {
                text: result.crawledEndDate
                  ? new Date(result.crawledEndDate).toLocaleString()
                  : '-',
                title: 'İşlem tamamlanma',
              },
            ]}
          />
        </Column>
      )
    }

    const renderTasks = () => (
      <Row>
        {renderMainTask()}
        {task.definitions
          .filter((item) => item.pathId !== task.main.pathId)
          .map((item, index: number) => (
            <Column
              key={index}
              xs={{ size: 4 }}
              style={{ position: 'relative' }}
            >
              <Styled.DetailAgendaReportHeader>
                {item.pathId === task.main.pathId ? (
                  <Lozenge
                    appearance={taskAppearance(
                      item.status as AgendaReportStatus
                    )}
                  >
                    Ana işlem
                  </Lozenge>
                ) : null}
              </Styled.DetailAgendaReportHeader>
              <DataBox
                appearance={taskAppearance(item.status as AgendaReportStatus)}
                information={{
                  icon:
                    item.status === AgendaReportStatus.Completed
                      ? 'check-circle'
                      : 'refresh',
                  text: `${taskStatus(item.status as AgendaReportStatus)}`,
                }}
                values={[
                  {
                    text: item.eventName
                      ? `[${item.eventName}] - ${
                          item.crawlProperties.Value || '-'
                        }`
                      : item.crawlProperties.Value || item.pathId,
                    title: 'İşlem açıklaması',
                  },
                  {
                    text: (
                      item.crawlProperties.TotalFriendsCount ||
                      item.crawlProperties.TotalReplyCount ||
                      item.crawlProperties.ProcessedCount ||
                      0
                    )?.toString(),
                    title: 'Toplanan veri',
                  },
                  {
                    text: item.insertedInQueueAt
                      ? new Date(item.insertedInQueueAt).toLocaleString()
                      : '-',
                    title: 'Kuyruk atılma',
                  },
                  {
                    text: item.crawledStartDate
                      ? new Date(item.crawledStartDate).toLocaleString()
                      : '-',
                    title: 'İşlem başlangıç',
                  },
                  {
                    text: item.crawledEndDate
                      ? new Date(item.crawledEndDate).toLocaleString()
                      : '-',
                    title: 'İşlem tamamlanma',
                  },
                ]}
              />
            </Column>
          ))}
      </Row>
    )

    return (
      <Styled.DetailAgendaReportProcess>
        {renderTasks()}
      </Styled.DetailAgendaReportProcess>
    )
  }

export default DetailAgendaReportCalculating
