import React from 'react'

import * as Styled from './Analysis.styled'
import { AnalysisProps } from './types'
import { Tweet } from '../../widgets/types'

const Analysis: React.FC<AnalysisProps> = ({ data, editMode, onChange }) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement>,
    key: string
  ) => {
    const tweet = data as Tweet
    const newData = e.target.innerText as string
    onChange(key, newData, tweet?.EntityKey)
  }

  return (
    <Styled.Analysis>
      <h1 className="analysis-title">Analiz</h1>

      <p
        contentEditable={editMode}
        onBlur={(e) => handleBlurElement(e, 'Analysis')}
        className="analysis-text"
      >
        {data?.Analysis}
      </p>
    </Styled.Analysis>
  )
}

export default Analysis
