import React, { useImperativeHandle, useState } from 'react'
import Chart, { Props } from 'react-apexcharts'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Container, Row } from '@zera-admin/page'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'

import { getCriteriaInterval } from '../visualization/getters'
import { mapPieChartProps } from './utils/mappers'
import * as Styled from './PieChart.styled'
import { PieChartProps, PieChartVisualizationOptions } from './types'

const PieChart: React.ForwardRefRenderFunction<
  WidgetHandlerRef<PieChartVisualizationOptions>,
  PieChartProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<QueryRunResponse>({
    headers: [],
    rows: [],
  })
  const [error, setError] = useState<string>()

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const runQuery = (widget: Widget) => {
    setIsLoading(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
      })
      .then((res) => {
        setValues(res.data)
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues({ ...values, rows: [] })
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderChart = () => {
    const chart: Props = mapPieChartProps(values, props.widget.visualization)

    return (
      <Container width="narrow">
        {isLoading ? (
          <Row xs={{ align: 'center', justify: 'center' }}>
            <Spinner className="spinner" size="medium" />
          </Row>
        ) : chart.series?.length && chart.options?.labels?.length ? (
          <Styled.PieChart theme={props.widget.visualization?.theme}>
            <Chart
              options={chart.options}
              series={chart.series}
              height={600}
              type="pie"
            />
          </Styled.PieChart>
        ) : (
          <Alert
            appearance={error ? 'error' : 'warning'}
            title="Chart verisi oluşturulamadı"
          >
            {error ||
              'Chartı oluşturacak veri bulunamadı. Lütfen sorgularınızı ve chart ayarlarınızı kontrol ediniz.'}
          </Alert>
        )}
      </Container>
    )
  }

  return renderChart()
}

export default React.forwardRef(PieChart)
