import {
  mapAuditForResponse,
  mapMetaForResponse,
} from 'services/http/identity-server/mappers'

import {
  ClaimUpdateRequest,
  ClaimListResponse,
  ClaimListRequest,
  ClaimCreateRequest,
  ClaimResponse,
} from './types'

export const mapClaimForResponse = (raw: any): ClaimResponse => ({
  id: raw.data.id,
  title: raw.data.title,
  value: raw.data.value,
  method: raw.data.httpMethodType,
  type: raw.data.type,
  appId: raw.data.appId,
  description: raw.data.description,
  audit: mapAuditForResponse(raw.data),
  roles: raw.data.roles || [],
})

export const mapClaimForCreateRequest = (raw: ClaimCreateRequest): any => ({
  title: raw.title,
  value: raw.value,
  httpMethodType: raw.method || '',
  type: raw.type,
  description: raw.description,
  appId: raw.appId,
  roleIds: (raw.roles || []).map((role) => role.id),
})

export const mapClaimForUpdateRequest = (raw: ClaimUpdateRequest): any => ({
  id: raw.id,
  title: raw.title,
  value: raw.value,
  httpMethodType: raw.method || '',
  description: raw.description,
  type: raw.type,
  appId: raw.appId,
  roleIds: (raw.roles || []).map((role) => role.id),
})

export const mapClaimListForRequest = (raw: ClaimListRequest) => ({
  Ascending: raw.ascending || false,
  PageIndex: raw.pageIndex,
  PageSize: raw.pageSize,
  AppId: raw.appId,
  Type: raw.type,
  Value: raw.value,
})

export const mapClaimListForResponse = (raw: any): ClaimListResponse => ({
  data: raw.data.map((claim: any) => mapClaimForResponse({ data: claim })),
  meta: mapMetaForResponse(raw),
})
