import { request } from 'services/http/report/instance'

import { FollowersBotDetectionResponse, PersonReportResponse } from './type'

export const create = (data?: any) =>
  request<any>({
    method: 'post',
    data,
    url: 'person-report',
  })

export const getByGroupId = (groupId: string) =>
  request<PersonReportResponse>({
    method: 'get',
    url: 'person-report',
    params: { GroupId: groupId },
  })

export const botDetection = (groupId: string, pageIndex?: number) =>
  request<FollowersBotDetectionResponse>({
    method: 'get',
    url: 'person-report/bot-detection?PageSize=10',
    params: { GroupId: groupId, PageIndex: pageIndex },
  })
