import React from 'react'
import Chart, { Props } from 'react-apexcharts'
import EmptyState from '@zera-admin/empty-state'

import { getCategory, getCategoryName, getMonths } from 'app/functions'

import { CategoriesByTimeProps } from './types'

const CategoriesByTime: React.FunctionComponent<CategoriesByTimeProps> = ({
  data,
  chartHeight,
}) => {
  const categoriesByTime: Props = {
    options: {
      chart: {
        id: 'categoriesByTime',
      },

      colors: data?.classifications?.map((item: any) => getCategory(item.name)),
      xaxis: {
        categories: data?.classifications?.map((item: any) =>
          getCategoryName(item?.name)
        ),
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          distributed: true,
        },
      },
      legend: {
        show: true,
        position: 'top',
      },
      title: {
        align: 'center',
        text: getMonths(data.month),
      },
    },
    series: [
      {
        name: 'sayısı',
        data: data?.classifications?.map((item: any) => item?.score.toFixed(2)),
      },
    ],
  }

  return data ? (
    <Chart
      options={categoriesByTime.options}
      series={categoriesByTime.series}
      type="bar"
      height={chartHeight}
    />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default CategoriesByTime
