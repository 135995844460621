import TwitterProfile from '../report-detail/pdf/twitter-profile'
import { PdfPage, PdfPageSideBar } from './PageReport.styled'

const PdfPageLayout: React.FunctionComponent<any> = ({
  personReport,
  children,
}) => {
  return (
    <PdfPage className="printit">
      <PdfPageSideBar>
        <div className="sidebar">
          <TwitterProfile
            dataA={personReport.data?.result?.Statistic?.tweetImpressions}
            data={personReport.data?.result?.TrackingUser}
          />
        </div>
      </PdfPageSideBar>
      {children}
    </PdfPage>
  )
}
export default PdfPageLayout
