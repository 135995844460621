import styled from 'styled-components'

export const ImageEmpty = styled.div`
  padding: 30px 0px 20px 0px;

  & > div {
    margin: 0 auto;
    font-size: 0.8rem;

    img {
      max-height: 100px;
    }

    p {
      margin-bottom: 12px;
    }
  }
`

export const Image = styled.div`
  padding: 1rem;
  box-sizing: border-box;

  img {
    width: 100%;
    height: auto;
  }
`
