import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Column, Container, Row } from '@zera-admin/page'
import TwitterProfileCard from '@zera-admin/alice-twitter-profile-card'
import Lozenge from '@zera-admin/lozenge'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'

import { getCriteriaInterval } from '../visualization/getters'
import * as Styled from './User.styled'
import { mapQueryForUser, mapUserDifferences } from './utils/mappers'
import { UserProps, UserValueProps, UserVisualizationOptions } from './types'

const User: React.ForwardRefRenderFunction<
  WidgetHandlerRef<UserVisualizationOptions>,
  UserProps
> = (props, ref) => {
  const { widget, onClickRow = () => {} } = props

  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [values, setValues] = useState<UserValueProps[]>([])
  const [error, setError] = useState<string>()

  const userRefs = useRef<HTMLDivElement[]>([])
  const timeouts = useRef<NodeJS.Timeout[]>([])

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  console.log('UserProps:', props)

  console.log(values, 'values')

  useEffect(() => {
    const stream = widget.visualization?.options?.stream
    let interval: NodeJS.Timeout | null = null

    if (stream?.live) {
      interval = setInterval(() => {
        runStream()
      }, stream.interval || 10000)
    }

    return () => {
      clearInterval(interval as NodeJS.Timeout)
      handleClearTimeouts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget.visualization?.options?.stream, isFetching, values])

  const getColumn = () => {
    const { grid } = props.widget

    if (grid) {
      if (grid === 2) return 6
      else if (grid === 3) return 4
      else if (grid === 4) return 3
      else if (grid === 6) return 2
    }

    return 12
  }

  const runStream = () => {
    if (!isFetching) {
      const stream = widget.visualization?.options?.stream

      queryService
        .run(
          props?.widget.query,
          props?.widget.visualization,
          props.widget.type,
          {
            criteriaType: props.widget.criteria,
            dateType: getCriteriaInterval(props.widget),
            type: 'user',
          }
        )
        .then((res) => {
          const differences = mapUserDifferences(
            values,
            mapQueryForUser(res.data, {
              ...widget.visualization?.options,
              fields: {
                ...widget.visualization?.options?.fields,
                negativeStatusCount: 'negativeStatusCount',
                neutralStatusCount: 'neutralStatusCount',
                positiveStatusCount: 'positiveStatusCount',
              },
            })
          )

          if (differences.length) {
            handleClearTimeouts()

            differences.forEach((difference, index) => {
              timeouts.current[index] = setTimeout(() => {
                userRefs.current[0]?.classList.remove('is-loaded')

                setValues((values) => [
                  { ...difference, stream: true },
                  ...values.slice(0, (stream?.limit || 20) - 1),
                ])

                setTimeout(() => {
                  userRefs.current[0]?.classList.add('is-loaded')
                }, 250)
              }, ((stream?.timeout || 10000) / differences.length) * index + 1)
            })
          }

          setIsFetching(false)
        })
        .catch((err: Error) => {
          setIsFetching(false)
          setValues([])
          console.log(err)
        })
    }
  }

  const handleClearTimeouts = () => {
    if (timeouts.current.length) {
      timeouts.current.forEach((timeout) => clearTimeout(timeout))
    }
  }

  const runQuery = (widget: Widget<UserVisualizationOptions>) => {
    setIsLoading(true)
    setIsFetching(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
        type: 'user',
      })
      .then((res) => {
        const valuesData = mapQueryForUser(res.data as QueryRunResponse, {
          ...widget.visualization?.options,
          fields: {
            ...widget.visualization?.options?.fields,
            negativeStatusCount: 'negativeStatusCount',
            neutralStatusCount: 'neutralStatusCount',
            positiveStatusCount: 'positiveStatusCount',
          },
        })

        setValues(valuesData)
        setIsLoading(false)
        setIsFetching(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setIsFetching(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderUser = (
    props: UserValueProps,
    index: number,
    column?: number
  ) => {
    return (
      <Column key={index} xs={{ size: column || 4 }}>
        <Styled.User
          isLoaded={props.stream && index === 0 ? false : true}
          isRecent={props.stream && index === 0 ? true : false}
          ref={(element) =>
            (userRefs.current[index] = element as HTMLDivElement)
          }
          onClick={() => onClickRow(widget.visualization, widget.query, props)}
        >
          {props.stream && (
            <Styled.UserLozenge>
              <Lozenge children="Yeni" appearance="success" />
            </Styled.UserLozenge>
          )}
          <TwitterProfileCard key={index} account={props} />
        </Styled.User>
      </Column>
    )
  }

  const renderContent = () => {
    console.log(values, 'valuessss')
    if (props.widget.grid) {
      return (
        <Row>
          {values.map((row, index) => renderUser(row, index, getColumn()))}
        </Row>
      )
    } else {
      return (
        <Container width="narrow">
          <Styled.UserList>
            <Row>{values.map((row, index) => renderUser(row, index))}</Row>
          </Styled.UserList>
        </Container>
      )
    }
  }

  const renderUsers = () => {
    if (isLoading) {
      return (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      )
    } else {
      if (values.length) {
        return renderContent()
      } else {
        return (
          <Alert
            appearance={error ? 'error' : 'warning'}
            title="Kullanıcı listesi oluşturulamadı"
          >
            {error ||
              'Kullanıcı listesini oluşturacak veri bulunamadı. Lütfen sorgularınızı ve kullanıcı listesi ayarlarınızı kontrol ediniz.'}
          </Alert>
        )
      }
    }
  }

  return renderUsers()
}

export default React.forwardRef(User)
