import React, { useImperativeHandle, useState } from 'react'
import CountUp from 'react-countup'
import Spinner from '@zera-admin/spinner'
import { Container, Row } from '@zera-admin/page'
import Alert from '@zera-admin/alert'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import {
  Widget,
  WidgetHandlerRef,
  WidgetVisualization,
} from 'services/http/bi-tool/widget'

import { getCriteriaInterval } from '../visualization/getters'
import { mapCounterProps } from './utils/mappers'
import * as Styled from './Counter.styled'
import {
  CounterProps,
  CounterValueProps,
  CounterVisualizationOptions,
} from './types'

const Counter: React.ForwardRefRenderFunction<
  WidgetHandlerRef<CounterVisualizationOptions>,
  CounterProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<QueryRunResponse>({
    headers: [],
    rows: [],
  })
  const [error, setError] = useState<string>()

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const runQuery = (widget: Widget) => {
    setIsLoading(true)

    queryService
      .run(
        widget?.query,
        widget?.visualization as WidgetVisualization,
        widget.type,
        {
          criteriaType: props.widget.criteria,
          dateType: getCriteriaInterval(props.widget),
        }
      )
      .then((res) => {
        setValues(res.data)
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues({ ...values, rows: [] })
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderCounter = () => {
    const options: CounterValueProps = mapCounterProps(
      values,
      props.widget.visualization
    )

    return (
      <>
        <Container width="narrow">
          {isLoading ? (
            <Row xs={{ align: 'center', justify: 'center' }}>
              <Spinner className="spinner" size="medium" />
            </Row>
          ) : error ? (
            <Alert appearance="error" title="Sayaç oluşturulamadı">
              {error ||
                'Sayacı oluşturacak veri bulunamadı. Lütfen sorgularınızı ve sayaç ayarlarınızı kontrol ediniz.'}
            </Alert>
          ) : (
            <Styled.Counter theme={props.widget.visualization?.theme}>
              <Styled.CounterWrapper>
                {options?.prefix && <span>{options.prefix}</span>}
                <CountUp
                  className="counter"
                  end={options.value || 0}
                  duration={2.5}
                  separator=","
                />
                {options?.suffix && <span>{options.suffix}</span>}
              </Styled.CounterWrapper>
              {options?.label && <h3>{options.label}</h3>}
            </Styled.Counter>
          )}
        </Container>
        <Container width="narrow">
          {isLoading ? (
            <Row xs={{ align: 'center', justify: 'center' }}>
              <Spinner className="spinner" size="medium" />
            </Row>
          ) : error ? (
            <Alert appearance="error" title="Sayaç oluşturulamadı">
              {error ||
                'Sayacı oluşturacak veri bulunamadı. Lütfen sorgularınızı ve sayaç ayarlarınızı kontrol ediniz.'}
            </Alert>
          ) : (
            <Styled.Counter theme={props.widget.visualization?.theme}>
              <Styled.CounterWrapper>
                {options?.secondPrefix && <span>{options?.secondPrefix}</span>}
                <CountUp
                  className="counter"
                  end={options?.secondValue || 0}
                  duration={2.5}
                  separator=","
                />
                {options?.secondSuffix && <span>{options?.secondSuffix}</span>}
              </Styled.CounterWrapper>
              {options?.secondLabel && <h3>{options?.secondLabel}</h3>}
            </Styled.Counter>
          )}
        </Container>
      </>
    )
  }

  return renderCounter()
}

export default React.forwardRef(Counter)
