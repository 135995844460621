import React, { useState } from 'react'
import Page, { Column, PageHeader, Row } from '@zera-admin/page'
import MainLayout from 'layouts/main'
import { GraphProps } from './types'
import GraphChart from '../graph-chart/GraphChart'
import dataList from '../graph-chart/AKMV8.json'
import Tweet from '@zera-admin/alice-tweet'
import TwitterProfileCard from '@zera-admin/alice-twitter-profile-card'

const GraphList: React.FunctionComponent<GraphProps> = () => {
  const [user, setUser] = useState<any>()
  const [userTweetList, setUserTweetList] = useState<any>([])
  const [data, setData] = useState<any>(dataList)

  const job = (item: number) => {
    const userValue = data.nodes.filter((x: { id: number }) => x.id === item)
    setUser(userValue[0])

    //   const tweetList = data.edges.filter(
    //     (x: { target: number; source: number }) =>
    //       x.target === item || x.source === item
    //   )
    //   setUserTweetList(tweetList)
    // }

    const tweetList = data.edges.filter(
      (x: { target: number }) => x.target === item
    )
    setUserTweetList(tweetList)
  }

  let d = new Date(0)

  const renderContent = () => {
    return (
      <MainLayout>
        <Page>
          <PageHeader>Graph Model Denemesi</PageHeader>

          <Row
            xs={{ justify: 'around' }}
            style={{ height: '100%', width: '100%' }}
          >
            <Column>
              {user && (
                <section>
                  <TwitterProfileCard
                    account={{
                      countryDetection: user.country,
                      createdAt: user.created_at,
                      followUp: user.userFollowerCount,
                      follower: user.user_friends_count,
                      name: user.user_name,
                      profilImage: user.image,
                      protected: user.verified,
                      username: user.label,
                      verified: user.verified,
                    }}
                  />
                </section>
              )}
            </Column>

            <Column style={{ width: '50%' }}>
              <section>
                <div style={{ overflow: 'scroll', height: '350px' }}>
                  {userTweetList &&
                    userTweetList?.map(
                      (
                        tweet: {
                          text: string
                          retweet_count: number
                          user_screen_name: string
                          favorite_count: number
                          user_name: string
                          image: string
                          sentiment: string
                          entityKey: string
                        },
                        key: number
                      ) =>
                        key < 50 && (
                          <Tweet
                            entityKey={tweet.entityKey?.toString()}
                            favorite={tweet.favorite_count}
                            name={tweet.user_name}
                            absSentiment={tweet.sentiment}
                            profilImage={
                              data.nodes.filter(
                                (x: { label: string }) =>
                                  x.label === tweet.user_screen_name
                              )[0]?.image
                            }
                            retweet={tweet.retweet_count}
                            tweet={tweet.text}
                            username={tweet.user_screen_name}
                          />
                        )
                    )}
                </div>
              </section>
            </Column>
          </Row>
          <div style={{ marginTop: '120px' }}>
            <GraphChart nodePress={(item: any) => job(item)} />
          </div>
          <Row xs={{ justify: 'around' }}></Row>
        </Page>
      </MainLayout>
    )
  }

  return renderContent()
}

export default GraphList
