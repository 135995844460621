import styled from 'styled-components'

export const WidgetSettingsFields = styled.div`
  margin-top: 12px;
  width: 100%;
`

export const WidgetSettingsField = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  background: ${(props) => (props.isSelected ? '#ecfbf0' : '#f3f3f3')};
  margin-bottom: 4px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(props) => (props.isSelected ? 'move' : 'pointer')};
  font-size: 0.9em;
  font-weight: 500;
  transition: all, 0.25s;
  -webkit-transition: all, 0.25s;

  &:hover {
    background: ${(props) => (props.isSelected ? '#ddf5e3' : '#ececec')};
  }

  button {
    margin-left: auto;
    padding: 0px;

    &:hover {
      background: transparent;
    }
  }
`

export const VisualizationOptions = styled.div`
  h4 {
    button {
      line-height: 25px;
      height: 25px;
      margin-right: 4px;
      margin-top: -4px;
      padding: 0;
    }
  }
`

export const FieldOptions = styled.div`
  padding: 1rem;
  background: #fdfdfd;
  margin-bottom: 5px;
`
