import { colors } from '@atlaskit/theme'
import styled from 'styled-components'

export const StackedChartDefinitions = styled.div`
  margin-top: 12px;
  width: 100%;

  div[role='tabpanel'] {
    & > div {
      width: 100%;
    }
  }
`

export const StackedChartDefinitionField = styled.div`
  width: 100%;

  button {
    margin-left: auto;
    padding: 0px;
    margin-top: 40px;

    &:hover {
      background: transparent;
    }

    &.button-subtle--delete {
      color: ${colors.red} !important;
      text-decoration: underline;
    }
  }
`
