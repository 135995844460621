import React, { useEffect, useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader } from '@zera-admin/page'
import Table from '@zera-admin/table'

import Pager from 'components/pager'
import MainLayout from 'layouts/main'
import sourcesService, { SourceResponse } from 'services/http/dataset/source'

import { SourceListProps } from './types'

const SourceList: React.FunctionComponent<SourceListProps> = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [source, setSource] = useState<SourceResponse>()

  useEffect(() => {
    getSources()
  }, [])

  const getSources = async () => {
    try {
      const response = await sourcesService.get()

      setSource(response.data)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const renderPageHeader = () => {
    // const actions = (
    //   <LinkButton appearance="primary" href="/source/create">
    //     Kaynak oluştur
    //   </LinkButton>
    // )

    const breadcrumbs = (
      <Breadcrumb>
        <BreadcrumbItem text="Anasayfa" href="/" />
        <BreadcrumbItem text="Kaynak" />
      </Breadcrumb>
    )

    const bottomBar = (
      <p>
        Tüm Kaynak görüntüleyebilir, yeni oluşturabilir, varolanları
        düzenleyebilirsiniz.
      </p>
    )

    return (
      <PageHeader
        actions={undefined}
        bottomBar={bottomBar}
        breadcrumbs={breadcrumbs}
      >
        Kaynak
      </PageHeader>
    )
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'name',
        text: 'Kaynak Adı',
      },
      // {
      //   name: 'operation',
      //   text: 'İşlemler ',
      //   width: '100px',
      //   render: (item: Source) => (
      //     <LinkButton
      //       appearance="subtle-link"
      //       href={`/source/update/${item.id}`}
      //     >
      //       Görüntüle
      //     </LinkButton>
      //   ),
      // },
    ]

    if (source) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={source.list}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
          <Pager pages={source.pageCount} />
        </React.Fragment>
      )
    }

    return null
  }

  return (
    <MainLayout>
      <Page>
        {renderPageHeader()}
        {renderPageTable()}
      </Page>
    </MainLayout>
  )
}

export default SourceList
