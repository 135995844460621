import React from 'react'

import { ReportTitleProps } from './types'
import * as Styled from './ReportTitle.styled'

const ReportTitle: React.FunctionComponent<ReportTitleProps> = ({
  backgroundColor,
  children,
  color,
  title,
  width,
  linearGradient,
  height,
  fontSize,
}) => {
  return (
    <Styled.ReportTitle>
      <Styled.Title
        backgroundColor={backgroundColor}
        color={color}
        width={width}
        linearGradient={linearGradient}
        height={height}
        fontSize={fontSize}
      >
        <div />
        <p>{title}</p>
      </Styled.Title>
      <Styled.Children>{children}</Styled.Children>
    </Styled.ReportTitle>
  )
}

export default ReportTitle
