import { SentimentType } from 'app/shared/types'

export function isValidUrl(value: string): boolean {
  let url

  try {
    url = new URL(value)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const upperFirstCharacter = (value: string) =>
  value.slice(0, 1).toUpperCase() + value.slice(1)

export const getSentimentTitle = (sentiment: SentimentType) => {
  switch (sentiment) {
    case 'negative':
      return 'Negatif'
    case 'neutral':
      return 'Nötr'
    case 'positive':
      return 'Pozitif'
    default:
      return ''
  }
}

export const getSentimentColor = (label: SentimentType) => {
  switch (label) {
    case 'positive':
      return '#1CD797'
    case 'negative':
      return '#E3000F'
    case 'neutral':
      return 'gray'
    default:
      return 'gray'
  }
}

export const phoneNumber = (value: string) => {
  if (!value) return '-'

  const replaced = value
    .replace(/\D/g, '')
    .match(/(\d{3})(\d{3})(\d{4})/) as string[]

  return value.split('').length === 7
    ? '(' + replaced[1] + ') ' + replaced[2] + '-' + replaced[3]
    : value
}

export const zpad = (integer: number) => {
  return `0${integer}`.slice(-2)
}

export const listRequestParams = (params: any) => {
  const tmpRequstData: any = {
    ...params,
  }

  const requestData: any = {}
  for (const key in tmpRequstData) {
    if (
      tmpRequstData[key] !== null &&
      tmpRequstData[key] !== undefined &&
      tmpRequstData[key] !== '' &&
      tmpRequstData[key] !== 0
    ) {
      requestData[key] = tmpRequstData[key]
    }
  }

  return requestData
}
