import { request } from 'services/http/dataset/instance'

import type {
  BotControlSet,
  BotControlSetResponse,
  CreateBotControlSetRequest,
  UpdateBotControlSetRequest,
} from './types'

import {
  mapBotControlSetForList,
  mapBotControlSetForRequest,
  mapBotControlSetForResponse,
} from './mappers'

export const create = (data: CreateBotControlSetRequest) =>
  request<BotControlSet>({
    method: 'post',
    url: 'bot-control-set',
    data,
    mappers: {
      req: mapBotControlSetForRequest,
    },
  })

export const get = () =>
  request<BotControlSetResponse>({
    method: 'get',
    url: 'bot-control-set',
    mappers: {
      res: mapBotControlSetForList,
    },
  })

export const getById = (id: number) =>
  request<BotControlSet>({
    method: 'get',
    url: `bot-control-set/${id}`,
    mappers: {
      res: mapBotControlSetForResponse,
    },
  })

export const update = (data: UpdateBotControlSetRequest) =>
  request<BotControlSet>({
    method: 'put',
    url: `bot-control-set/${data.id}`,
    data,
    mappers: {
      req: mapBotControlSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `bot-control-set/${id}`,
  })
