import React from 'react'
import { LinkButton } from '@zera-admin/button'
import { uid } from '@zera-admin/utils'
import Icon from '@zera-admin/icon'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { Error } from 'app/shared/types'

import { OperationProps } from './types'

const Operation: React.FunctionComponent<OperationProps> = ({
  history,
  href,
  service,
}) => {
  const [flagCard] = useFlagcardContext()

  const push = href.split('/')[0].toString()

  const handleDelete = () => {
    service &&
      service
        .remove()
        .then(() => {
          flagCard.add({
            id: uid(),
            appearance: 'success',
            title: 'İşlem Başarılı',
            children: 'Geçerli veri, sistem üzerinde başarıyla silinmiştir.',
          })
          history.push(push)
        })
        .catch((error: Error) => {
          console.log(error)

          flagCard.add({
            id: uid(),
            appearance: 'error',
            title: 'Hata Oluştu',
            children:
              'Geçerli veri güncellenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
          })
        })
  }

  return (
    <React.Fragment>
      <LinkButton appearance="subtle-link" href={href}>
        <Icon name="watch-filled" />
      </LinkButton>
      <LinkButton appearance="subtle-link" onClick={() => handleDelete()}>
        <Icon name="trash" color="red" />
      </LinkButton>
    </React.Fragment>
  )
}

export default Operation
