import { QueryRunResponse } from 'services/http/bi-tool/query'

import { GraphChartValueProps, GraphChartVisualizationOptions } from '../types'

export const mapQueryForTweet = (
  raw: QueryRunResponse,
  options: GraphChartVisualizationOptions
): GraphChartValueProps[] =>
  (raw.rows || []).map((row) => ({
    edges: row[options?.fields?.edges as any],
    nodes: row[options?.fields?.nodes as any],
    tweet: row[options?.fields?.tweet as any],
  }))

export const mapTweetDifferences = (
  previous: GraphChartValueProps[],
  next: GraphChartValueProps[]
) => {
  const differences: GraphChartValueProps[] = next

  previous.forEach((prev) => {
    const index = differences.findIndex(
      (difference) => difference?.tweet?.entityKey === prev.tweet?.entityKey
    )

    if (index !== -1) {
      differences.splice(index, 1)
    }
  })

  return differences
}
