import { getIntervalPeriod } from 'app/functions'
import { Field } from 'services/http/bi-tool/field'
import { Query } from 'services/http/bi-tool/query'
import { Widget } from 'services/http/bi-tool/widget'

import { TimeSeriesVisualizationOptions } from '../types'

export const generateTimeSeriesOptions = (
  options: TimeSeriesVisualizationOptions,
  query: Query,
  fields: Field[]
): TimeSeriesVisualizationOptions => {
  const result = options

  const aggregation = query.aggregations?.length
    ? query.aggregations[query.aggregations.length - 1]
    : null
  const group = query.groups?.length
    ? query.groups[query.groups.length - 1]
    : null

  if (aggregation) {
    result.value = {
      field: aggregation.field as string,
      type: aggregation.type,
      alias: aggregation?.alias || (aggregation.field as string),
    }
  }

  if (group) {
    result.label = {
      field: group.field as string,
    }
  }

  return result
}

export const generateTimeSeriesRawQuery = (widget: Widget) => {
  const query = JSON.parse(JSON.stringify(widget.query))

  if (widget.type === 'raw' && query.raw?.sql) {
    let sql = query.raw.sql

    if (sql) {
      sql = sql.replaceAll(
        '+{timeseries_select}',
        generateTimeSeriesSelectClause(widget) || ''
      )
      sql = sql.replaceAll(
        '+{timeseries_group}',
        generateTimeSeriesGroupClause(widget) || ''
      )
      sql = sql.replaceAll(
        '+{timeseries_extract}',
        generateTimeSeriesExtractClause(widget) || ''
      )

      query.raw.sql = sql
    }
  }

  return query
}

export const generateTimeSeriesSelectClause = (widget: Widget) => {
  const { interval } = widget.query

  if (interval) {
    const period = getIntervalPeriod(interval)

    if (period === 'hourly') {
      return `SELECT TIME_PARSE(CONCAT(years, '-' , months , '-' , days,'T',hours,':',minutes))  as zaman , sayi`
    } else if (period === 'daily') {
      return `SELECT TIME_PARSE(CONCAT(years, '-' , months , '-' , days,'T',hours,':00'))  as zaman , sayi`
    } else {
      return `SELECT TIME_PARSE(CONCAT(years, '-' , months , '-' , days))  as zaman , sayi`
    }
  }
}

export const generateTimeSeriesGroupClause = (widget: Widget) => {
  const { interval } = widget.query

  if (interval) {
    const period = getIntervalPeriod(interval)

    if (period === 'hourly') {
      return `GROUP BY 1,2,3,4,5`
    } else if (period === 'daily') {
      return `GROUP BY 1,2,3,4`
    } else {
      return `GROUP BY 1,2,3`
    }
  }
}

export const generateTimeSeriesExtractClause = (widget: Widget) => {
  const { interval } = widget.query

  if (interval) {
    const period = getIntervalPeriod(interval)

    if (period === 'hourly') {
      return `EXTRACT (MINUTE FROM TIME_PARSE(createdAt)) as minutes,
      EXTRACT (HOUR FROM TIME_PARSE(createdAt)) as hours,
      EXTRACT (DAY FROM TIME_PARSE(createdAt)) as days,
      EXTRACT(MONTH FROM TIME_PARSE(createdAt)) as months,
      EXTRACT(YEAR FROM TIME_PARSE(createdAt)) as years,`
    } else if (period === 'daily') {
      return `EXTRACT (HOUR FROM TIME_PARSE(createdAt)) as hours,
      EXTRACT (DAY FROM TIME_PARSE(createdAt)) as days,
      EXTRACT(MONTH FROM TIME_PARSE(createdAt)) as months,
      EXTRACT(YEAR FROM TIME_PARSE(createdAt)) as years,`
    } else {
      return `EXTRACT (DAY FROM TIME_PARSE(createdAt)) as days,
      EXTRACT(MONTH FROM TIME_PARSE(createdAt)) as months,
      EXTRACT(YEAR FROM TIME_PARSE(createdAt)) as years,`
    }
  }
}

// export const generateTimeSeriesExtractClause = (widget: Widget) => {
//   const { interval } = widget.query

//   if (interval) {
//     const period = getIntervalPeriod(interval)

//     if (period === 'hourly') {
//       return `
//         SUBSTR(createdAt,15,2) minutes,
//         SUBSTR(createdAt,12,2) hours
//         SUBSTR(createdAt,9,2) days,
//         SUBSTR(createdAt,6,2) months,
//         SUBSTR(createdAt,1,4) years,
//       `
//     } else if (period === 'daily') {
//       return `SUBSTR(createdAt,12,2) hours
//         SUBSTR(createdAt,9,2) days,
//         SUBSTR(createdAt,6,2) months,
//         SUBSTR(createdAt,1,4) years,
//       `
//     } else {
//       return `SUBSTR(createdAt,9,2) days,
//         SUBSTR(createdAt,6,2) months,
//         SUBSTR(createdAt,1,4) years,
//       `
//     }
//     // if (period === 'hourly') {
//     //   return `EXTRACT (MINUTE FROM TIME_PARSE(createdAt)) as minutes,
//     //   EXTRACT (HOUR FROM TIME_PARSE(createdAt)) as hours,
//     //   EXTRACT (DAY FROM TIME_PARSE(createdAt)) as days,
//     //   EXTRACT(MONTH FROM TIME_PARSE(createdAt)) as months,
//     //   EXTRACT(YEAR FROM TIME_PARSE(createdAt)) as years,`
//     // } else if (period === 'daily') {
//     //   return `EXTRACT (HOUR FROM TIME_PARSE(createdAt)) as hours,
//     //   EXTRACT (DAY FROM TIME_PARSE(createdAt)) as days,
//     //   EXTRACT(MONTH FROM TIME_PARSE(createdAt)) as months,
//     //   EXTRACT(YEAR FROM TIME_PARSE(createdAt)) as years,`
//     // } else {
//     //   return `EXTRACT (DAY FROM TIME_PARSE(createdAt)) as days,
//     //   EXTRACT(MONTH FROM TIME_PARSE(createdAt)) as months,
//     //   EXTRACT(YEAR FROM TIME_PARSE(createdAt)) as years,`
//     // }
//   }
// }
