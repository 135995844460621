import { Http, HttpConfig } from '@zera-admin/classes'

const http = new Http({
  origin: process.env.REACT_APP_LINKEDIN_DEV,
})

export function request<T>(config: HttpConfig) {
  config.headers = {
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIxIiwiVXNlclR5cGUiOiIxIiwibmJmIjoxNjIzMzEwMDY0LCJleHAiOjE2NTQ4NDYwNjQsImlhdCI6MTYyMzMxMDA2NCwiaXNzIjoiWERlbmVtZSIsImF1ZCI6IlhEZW5lbWUifQ.nrJsA6IklcYYlX6GiwT4_tZMgChwOcNXZPq9fhfDVg8',
    ...config.headers,
  }

  return http.request<T>(config)
}

export default http
