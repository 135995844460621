export enum AgendaReportStatus {
  Ready = 'Ready',
  Create = 'Create',
  Processing = 'Processing',
  Completed = 'Completed',
  Stopped = 'Stopped',
  Pending = 'Pending',
  Exists = 'Exists',
  Calculating = 'Calculating',
  Error = 'Error',
}

export enum AgendaReportType {
  All = 0,
  Dependent = 1,
  Independent = 2,
}
