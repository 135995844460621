import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { getCountryPerceptions } from 'services/http/backoffice/country-perception/endpoints'
import backofficeDefinitionService from 'services/http/backoffice/definition/'

import { CreateGoogleTrendsResponse } from 'services/http/backoffice/definition/types'

import Page from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { Column, Row, PageHeader } from '@zera-admin/page'
import { RadioGroup } from '@zera-admin/radio'
import Button from '@zera-admin/button'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Checkbox from '@zera-admin/checkbox'
import Icon from '@zera-admin/icon'
import Form, {
  FormSection,
  useForm,
  FormHookSubmitProps,
  FormFooter,
  FormHeader,
} from '@zera-admin/form'
import googleTrendsCategories from '../../../assets/data/google-trends-categories.json'

const GoogleTrends = () => {
  const [formValues, setFormValues] = useState<{
    countryPerceptions: Array<SelectOptionProps>
  }>({
    countryPerceptions: [],
  })
  const [flagcard] = useFlagcardContext()

  const groupOptions = [
    { label: 'Web Search', value: 0 },
    { label: 'Image Search', value: 1 },
    { label: 'News Search', value: 2 },
    { label: 'Youtube Search', value: 3 },
    { label: 'GoogleShopping', value: 4 },
  ]

  const dateOptions = [
    { label: 'Past Hour ', value: 1 },
    { label: 'Past Four Hours ', value: 2 },
    { label: 'Past Day  ', value: 3 },
    { label: 'Past Seven Days  ', value: 4 },
    { label: 'Past Month  ', value: 5 },
    { label: 'Past Three Months  ', value: 6 },
    { label: 'Past Year  ', value: 7 },
    { label: 'Past Five Years  ', value: 8 },
    { label: 'Till 2004  ', value: 9 },
  ]
  const regionOptions = [
    { label: 'Country', value: 1 },
    { label: 'SubRegion   ', value: 2 },
    { label: 'SubRegion', value: 3 },
    { label: 'City ', value: 4 },
  ]

  useEffect(() => {
    getCountryPerceptions()
      .then((res) => res.data.data)
      .then((res) =>
        setFormValues((state) => ({
          ...state,
          countryPerceptions: res.map((country: any) => {
            return { label: country?.description, value: country?.key }
          }),
        }))
      )
  }, [])

  const initialValues: CreateGoogleTrendsResponse = {
    crawlingType: 'fetch',
    definitionType: '',
    definitionValue: '',
    countryPerception: 1,
    allowedLanguages: [],
    allowedDbs: [],
    useIncomingDefinition: false,
    environment: 'Alice',
    definitionBlackLists: [],
    periodicFetch: false,
    period: '',
    query: '',
    geo: '',
    category: '',
    group: 1,
    date: 0,
    label: '',
    region: null,
    timeZone: '400',
  }

  const form = useForm<CreateGoogleTrendsResponse>({
    initialValues,
    onSubmit: (props) => handlerSubmit(props),
  })

  const handlerSubmit = (
    props: FormHookSubmitProps<CreateGoogleTrendsResponse>
  ) => {
    console.log(form.values)
    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createGoogleTrends(form.values)
          .then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
            form.handleBatchUpdate(initialValues)
          })
          .catch((error) => {
            console.log(error)
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
      }
    }
  }

  const renderPageContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={form.handleSubmit}>
          <FormHeader
            title="GoogleTrends Tanımlama Formu"
            description={
              <div style={{ marginBottom: '1.5rem' }}>
                <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
                <p style={{ margin: '0', color: '#A73121' }}>
                  Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
                </p>
              </div>
            }
          />
          <FormSection>
            <Row>
              <Column md={{ size: 3 }}>
                <RadioGroup
                  label="*Crawling Type"
                  name="crawlingType"
                  isRequired
                  options={[{ label: 'Fetch', value: 'fetch' }]}
                  value={form.values.crawlingType}
                  onChange={(e) =>
                    form.handleFieldChange(
                      'crawlingType',
                      e.currentTarget.value
                    )
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column md={{ size: 3 }}>
                <RadioGroup
                  label="*Definition Type"
                  name="definitionType"
                  isRequired
                  options={[
                    {
                      label: 'Compared Break Down By Region',
                      value: 'compared-break-down-by-region',
                    },
                    {
                      label: 'Interest By Region',
                      value: 'interest-by-region',
                    },
                    {
                      label: 'Interest Over Time',
                      value: 'interest-over-time',
                    },
                    {
                      label: 'Related Queries',
                      value: 'related-queries',
                    },
                    {
                      label: 'Related Topics',
                      value: 'related-topics',
                    },
                  ]}
                  value={form.values.definitionType}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'definitionType',
                      e.currentTarget.value
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="*Definition Value"
                  name="definitionValue"
                  placeholder="Takip edilecek kelime giriniz."
                  isRequired
                  value={form.values.definitionValue}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'definitionValue',
                      e.currentTarget.value
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Algı Seçiniz"
                  name="countryPerception"
                  placeholder="Seçim yapın"
                  options={formValues.countryPerceptions}
                  defaultValue={
                    formValues.countryPerceptions.find(
                      (option) => option.label === 'Türkiye'
                    ) || { label: 'Türkiye', value: 1 }
                  }
                  value={formValues.countryPerceptions.find(
                    (option) => option.value === form.values.countryPerception
                  )}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'countryPerception',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row>
            {/* <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Dil Seçiniz"
                  name="allowedLanguages"
                  description="*İzin verilen diller listesi"
                  placeholder="Seçim yapın"
                  isMultiple
                  options={[
                    { label: 'Turkish', value: 'Turkish' },
                    { label: 'English', value: 'English' },
                  ]}
                  value={form.values.allowedLanguages?.map((lang) => {
                    return { label: lang, value: lang }
                  })}
                  onChange={(e) => {
                    const value = e as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedLanguages',
                      value.length > 0
                        ? value.map((lang) => lang.value)
                        : undefined
                    )
                  }}
                />
              </Column>
            </Row> */}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Database Seçimi"
                  name="allowedDbs"
                  description="*Çekilen verilerin işlendikten sonra hangi db 'lere kaydedileceğini belirtir."
                  placeholder="Seçim yapın"
                  isMultiple
                  options={[
                    { label: 'Oracle', value: 'oracle' },

                    { label: 'Neo4j', value: 'neo4j' },
                  ]}
                  value={form.values.allowedDbs?.map((db) => ({
                    label: db,
                    value: db,
                  }))}
                  onChange={(e) => {
                    const value = e as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedDbs',
                      value.length > 0 ? value.map((db) => db.value) : undefined
                    )
                  }}
                />
              </Column>
            </Row>
            {/* <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Environment"
                  name="environment"
                  placeholder="Seçim yapın"
                  options={[{ label: 'Alice', value: 'Alice' }]}
                  value={
                    form.values.environment
                      ? [
                          {
                            label: form.values.environment,
                            value: form.values.environment,
                          },
                        ]
                      : null
                  }
                  onChange={(e) => {
                    form.handleFieldChange(
                      'environment',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row> */}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Input
                  name="definitionBlackLists"
                  placeholder="Tanımlama bazlı blacklist için anahtar kelimeleri giriniz"
                  description="Blackliste eklemek istediginiz kelimeleri giriniz(Her kelimeden sonra , eklemeniz gerekmektedir)"
                  onChange={(props) => {
                    const inputString = props.currentTarget.value
                    const stringToArray = inputString.split(',')

                    form.handleFieldChange(
                      'definitionBlackLists',
                      stringToArray
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  label="Bu definitiondan gelen postlar direkt olarak bu definition ile işaretlensin mi işaretlenmesin mi?"
                  name="useIncomingDefinition"
                  isChecked={form.values.useIncomingDefinition}
                  onChange={(e) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      e.currentTarget.checked
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  label="Periyodik olarak çekilsin mi ?"
                  name="periodicFetch"
                  isChecked={form.values.periodicFetch}
                  onChange={(e) =>
                    form.handleFieldChange(
                      'periodicFetch',
                      e.currentTarget.checked
                    )
                  }
                />
              </Column>
            </Row>
            {form.values.periodicFetch && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Input
                    label="Period"
                    name="period"
                    placeholder="Periyot giriniz (0 * * * *)"
                    description="Geçerli bir periyot giriniz"
                    isRequired={form.values.periodicFetch}
                    value={form.values.period}
                    onChange={(e) => {
                      form.handleFieldChange('period', e.currentTarget.value)
                    }}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="*Sorgu"
                  name="query"
                  isRequired
                  placeholder="Sorgu giriniz"
                  description="Araştırılacak kelime/kelimeler değişebilir."
                  value={form.values.query}
                  onChange={(e) => {
                    form.handleFieldChange('query', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="Geo"
                  name="geo"
                  placeholder="Geo giriniz"
                  description="Araştırma nereden yapılmış gibi sonuç bulunacak. Boş bırakılırsa dünya çapında araştırılır."
                  value={form.values.geo}
                  onChange={(e) => {
                    form.handleFieldChange('geo', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Select
                  label="Kategori"
                  name="category"
                  placeholder="Seçim yapın"
                  options={googleTrendsCategories.data}
                  value={
                    form.values.category
                      ? [
                          {
                            label: googleTrendsCategories.data.filter(
                              (category) =>
                                category.value === form.values.category
                            )[0].label,
                            value: form.values.category,
                          },
                        ]
                      : googleTrendsCategories.data[0]
                  }
                  onChange={(e) => {
                    form.handleFieldChange(
                      'category',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Select
                  label="Grup"
                  name="group"
                  placeholder="Seçim yapın"
                  options={groupOptions}
                  value={
                    form.values.group
                      ? [
                          {
                            label: groupOptions[form.values.group].label,
                            value: form.values.group,
                          },
                        ]
                      : groupOptions[0]
                  }
                  onChange={(e) => {
                    form.handleFieldChange(
                      'group',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Select
                  label="*Tarih"
                  name="date"
                  placeholder="Seçim yapın"
                  description="Seçim yapmazsanız null olarak kaydedilir."
                  options={dateOptions}
                  value={
                    form.values.date
                      ? [
                          {
                            label: dateOptions.filter(
                              (dt) => dt.value === form.values.date
                            )[0].label,
                            value: form.values.date,
                          },
                        ]
                      : null
                  }
                  onChange={(e) => {
                    form.handleFieldChange(
                      'date',
                      (e as SelectOptionProps).value
                    )
                  }}
                />
              </Column>
            </Row>
            {form.values.definitionType === 'compared-break-down-by-region' && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Select
                    label="Bölge"
                    name="region"
                    placeholder="Seçim yapın"
                    description="Boş bırakılırsa geo parametresine göre otomatik seçilir"
                    options={regionOptions}
                    value={
                      form.values.region
                        ? [
                            {
                              label: regionOptions[form.values.region].label,
                              value: form.values.region,
                            },
                          ]
                        : null
                    }
                    onChange={(e) => {
                      form.handleFieldChange(
                        'region',
                        (e as SelectOptionProps).value
                      )
                    }}
                  />
                </Column>
              </Row>
            )}
            {form.values.definitionType === 'interest-by-region' && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Select
                    label="Bölge"
                    name="region"
                    placeholder="Seçim yapın"
                    description="Boş bırakılırsa geo parametresine göre otomatik seçilir"
                    options={regionOptions}
                    value={
                      form.values.region
                        ? [
                            {
                              label: regionOptions[form.values.region].label,
                              value: form.values.region,
                            },
                          ]
                        : null
                    }
                    onChange={(e) => {
                      form.handleFieldChange(
                        'region',
                        (e as SelectOptionProps).value
                      )
                    }}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="Operasyon Etikei"
                  name="label"
                  placeholder="Etiket giriniz"
                  description="Geçerli bir etiket giriniz"
                  value={form.values.label}
                  onChange={(e) => {
                    form.handleFieldChange('label', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="Zaman Dilimi"
                  name="timeZone"
                  placeholder="Zaman dilimi giriniz"
                  description="-1439 ile 1439 arası bir sayı giriniz"
                  value={form.values.timeZone}
                  defaultValue={400}
                  onChange={(e) => {
                    form.handleFieldChange('timeZone', e.currentTarget.value)
                  }}
                />
              </Column>
            </Row>
          </FormSection>
          <FormFooter align="start">
            <Button
              type="submit"
              isDisabled={
                (form.values.periodicFetch && !form.values.period) ||
                form.values.definitionValue === ''
              }
              appearance="primary"
              children="Tanımlama Oluştur"
              iconBefore={<Icon name="add-item" />}
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem text="Tanımlama" href="/definition" />
              <BreadcrumbItem
                text="Tanımlama oluştur"
                href="/definition/create"
              />
              <BreadcrumbItem text="GoogleTrends" />
            </Breadcrumb>
          }
        />
        {renderPageContent()}
      </Page>
    </MainLayout>
  )
}

export default GoogleTrends
