import React from 'react'
import { DashedLineChartProps } from './types'
import * as Styled from './DashedLineChart.styled'
import { useLocation } from 'react-router-dom'
import { usePrintDataParam } from 'app/functions/navigation'
import { Chart } from 'react-google-charts'
import { TimeSery } from 'services/http/report/agenda-report-4/types'

const DashedLineChart: React.FC<DashedLineChartProps> = ({ data, title }) => {
  const location = useLocation()
  const isPrint = usePrintDataParam(location)
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false)
  }, [])
  if (!data || !Array.isArray(data) || data?.length === 0) {
    return <p>No data available</p>
  }

  const chartData = [
    ['Date', 'Value'],
    ...data?.map((item: TimeSery) => [
      new Date(item['AsDateTime']),
      item['TotalCount'],
    ]),
  ]

  const options = {
    title: title || 'Line Chart with Trend Line',
    curveType: 'function',
    legend: { position: 'bottom' },
    trendlines: {
      0: {
        type: 'linear',
        visibleInLegend: false,
      },
    },
    tooltip: { trigger: 'none' },
    hAxis: { title: 'Date', format: 'dd/MM/yyyy' },
  }

  const renderTimeSeries = () => {
    if (data) {
      return (
        <Chart
          chartType="LineChart"
          height={650}
          width={dimensions.width - (isPrint ? 420 : 550)}
          data={chartData}
          options={options}
        />
      )
    }

    return null
  }

  return (
    <Styled.DashedLineContainer>
      {renderTimeSeries()}
    </Styled.DashedLineContainer>
  )
}

export default DashedLineChart
