// Import statements...
import React, { useEffect, useState } from 'react'
import XWhite from './assets/xwhite.png'
import NotFoundImage from './assets/default_profile_400x400.png'
import NotFoundBanner from './assets/NotFoundBanner.png'
import { InfluentialAccountPagesByCountryProps } from './types'
import UpdateButton from '../../components/update-button/UpdateButton'

import {
  PeopleCardContainer,
  InfluentialPeoplesContainer,
  MainContainer,
} from './InfluentialAccountPagesByCountry.styled'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { numberFormat } from 'app/functions/number'
import { Account } from '../../widgets/types'
import WidgetLayout from '../../widgets/widget-layout'
import { getInfluentialUser } from 'services/http/report/agenda-report-4/endpoint'

const InfluentialAccountPagesByCountry: React.FunctionComponent<InfluentialAccountPagesByCountryProps> =
  ({
    date,
    isEditValue,
    pageNumber,
    setIsEditValue,
    title,
    data,
    metaData,
  }) => {
    const [accounts, setAccounts] = useState<Account[] | undefined>(data)
    const [pageData, setPageData] = useState<{
      title: string
      pageNumber: number
    }>({ title: title, pageNumber: pageNumber })
    const [editMode, setEditMode] = useState(false)
    const [userName, setUserName] = useState<string>('')

    const [flagCard] = useFlagcardContext()

    useEffect(() => {
      if (isEditValue === pageNumber) {
        setEditMode(true)
      }
    }, [isEditValue, pageNumber])

    useEffect(() => {
      if (isEditValue === -1) {
        setAccounts(data)
        setPageData({ title: title, pageNumber: pageNumber })
      }
    }, [isEditValue, data, title, pageNumber])

    const getAccount = (GroupID: string, userName: string) => {
      if (userName) {
        getInfluentialUser(GroupID, userName)
          .then((response: any) => {
            if (response) {
              const updatedData = accounts ? [...accounts] : []
              updatedData.push({ ...response.data.data })
              console.log(response.data)
              setAccounts(updatedData)
            }
          })
          .catch((error) => {
            console.log(error)
            flagCard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen geçerli bir kullanıcı adı girip tekrar deneyin.',
            })
          })
      }
    }
    const onChangePageData = (key: string, value: string) => {
      setPageData({
        ...pageData,
        [key]: value,
      })
    }

    const handleInputValue = (
      e: React.ChangeEvent<HTMLInputElement>,
      setter: any
    ) => {
      setter(e.target.value)
    }

    const handleRemoveCard = (indexToRemove: number) => {
      const updatedData = accounts ? [...accounts] : []
      updatedData.splice(indexToRemove, 1)
      setAccounts(updatedData)
      console.log('updatedData', updatedData)
    }

    const editProps = {
      isEditValue: isEditValue,
      setIsEditValue: setIsEditValue,
      pageNumber: pageData.pageNumber,
      metaData,
    }

    return (
      <WidgetLayout
        asideDate={date}
        asideTitle={pageData.title}
        pageNumber={pageData.pageNumber}
        onChange={onChangePageData}
        editMode={editMode}
        flagShortCode={metaData?.flagShortCode}
      >
        <MainContainer>
          <div>
            <UpdateButton
              {...editProps}
              contentData={{ Accounts: accounts }}
              title={pageData?.title}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          </div>
          {editMode && (
            <div className="edit">
              <div className="edit-container">
                <div className="edit-container-input">
                  <label htmlFor="username">Kullanıcı Adı: </label>
                  <input
                    placeholder="Geçerli Kullanıcı adı giriniz"
                    type="text"
                    id="username"
                    value={userName}
                    onChange={(e) => handleInputValue(e, setUserName)}
                  />
                </div>
                <div className="edit-container-input">
                  <button
                    onClick={() =>
                      getAccount(metaData?.GroupId as string, userName)
                    }
                  >
                    Hesap Ekle
                  </button>
                </div>
              </div>
            </div>
          )}
          <InfluentialPeoplesContainer>
            <PeopleCardContainer>
              {accounts?.slice(0, 6).map((people, index) => (
                <div className="influential-card-container" key={index}>
                  <div className="influential-body">
                    {/* <div className="influential-header-bg"></div> */}

                    <div className="influential-header">
                      <div className="influential-header-info">
                        <div className="influential-header-img-container">
                          <img
                            src={people?.ProfileImageUrl}
                            onError={(e) =>
                              (e.currentTarget.src = NotFoundImage)
                            }
                            alt=""
                          />
                        </div>
                        <div className="influential-header-title">
                          <span>{people?.Name}</span>
                          <span>@{people?.ScreenName}</span>
                        </div>
                      </div>
                    </div>
                    <img
                      className="influential-bg"
                      src={
                        (people?.ProfileBannerImageUrl &&
                          people?.ProfileBannerImageUrl) ||
                        NotFoundBanner
                      }
                      alt=""
                    />
                    <div className="influential-bottom">
                      <div className="x-container">
                        <img
                          src={XWhite}
                          alt="x"
                          onError={(event) => {
                            event.currentTarget.src = NotFoundImage
                          }}
                        />
                      </div>
                      <div className="influential-counts">
                        <span className="influential-fs">
                          <span>{numberFormat(people?.StatusCount)}</span>
                          <span>Gönderiler</span>
                        </span>
                        <span className="influential-fs">
                          <span>{numberFormat(people?.FollowersCount)}</span>
                          <span>Takipçi</span>
                        </span>
                        <span>
                          <span>{numberFormat(people?.FriendsCount)}</span>
                          <span className="influential-fs-follow">Takip</span>
                        </span>
                      </div>
                      <div className="influential-show">
                        <div>
                          <a href={`https://${people?.Url}`}>
                            <i className="fa-solid fa-right-from-bracket"></i>
                            &nbsp; Etkili hesabı görüntülemek için tıklayınız
                          </a>
                        </div>
                      </div>
                    </div>
                    {editMode && (
                      <div className="tick-button-container">
                        <button
                          className="tick-button"
                          onClick={() => handleRemoveCard(index)}
                        >
                          X
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </PeopleCardContainer>
          </InfluentialPeoplesContainer>
        </MainContainer>
      </WidgetLayout>
    )
  }

export default InfluentialAccountPagesByCountry
