import React, { useState } from 'react'
import Tabs, { TabPanel, TabTrigger, TabTriggerGroup } from '@zera-admin/tabs'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import { string } from '@zera-admin/helpers'
import { Column, Row } from '@zera-admin/page'

import { getFieldOptions } from 'bi-tool/visualizations/visualization/getters'

import * as Styled from './ColoredMapDefinitions.styled'
import { ColoredMapDefinitionsProps } from './types'

const ColoredMapDefinitions: React.FunctionComponent<ColoredMapDefinitionsProps> =
  ({ aggregations = [], fields = [], groups = [], options, onChange }) => {
    const [fieldOptions, setFieldOptions] = useState<SelectOptionProps[]>(
      getFieldOptions(fields)
    )

    const handleChangeFieldInput = (value: string) => {
      const option = {
        label: value,
        value,
      }
      const index = fieldOptions.findIndex(
        (reference) => reference.value === parseInt(option.value)
      )
      let optionsInstance = [...fieldOptions]

      if (value && index === -1) {
        optionsInstance[0] = option
      } else {
        optionsInstance = getFieldOptions(fields)
      }

      setFieldOptions(optionsInstance)
    }

    const renderTabs = () => {
      return (
        <Tabs id="tabs">
          <TabTriggerGroup>
            <TabTrigger>Veri</TabTrigger>
          </TabTriggerGroup>
          <TabPanel>{renderDataTab()}</TabPanel>
        </Tabs>
      )
    }

    const renderDataTab = () => {
      return (
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              name="prefix"
              label="Metin"
              placeholder="Yoğunluk"
              value={options.label}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange({
                  ...options,
                  label: event.target.value,
                })
              }
              description="Harita üzerindeki yoğunluk metriğinin adını yazınız"
            />
          </Column>
          <Column>
            <Select
              name="column"
              options={fieldOptions}
              onChange={(props) => {
                onChange({
                  ...options,
                  country: (props as SelectOptionProps).value as string,
                })
              }}
              onInputChange={handleChangeFieldInput}
              value={
                options.country
                  ? {
                      label: string.textify(options.country),
                      value: options.country,
                    }
                  : { label: '', value: '' }
              }
              label="Ülke"
              placeholder="Seçim yapınız"
              description="Harita üzerinde gösterilecek ülke alanını seçin"
              isDisabled={aggregations.length || groups.length ? true : false}
            />
          </Column>
          <Column>
            <Styled.ColoredMapDefinitionField>
              <Row>
                <Column>
                  <Select
                    name="column"
                    options={fieldOptions}
                    onChange={(props) => {
                      onChange({
                        ...options,
                        count: (props as SelectOptionProps).value as string,
                      })
                    }}
                    onInputChange={handleChangeFieldInput}
                    value={
                      options.count
                        ? {
                            label: string.textify(options.count),
                            value: options.count,
                          }
                        : { label: '', value: '' }
                    }
                    label="Değer"
                    placeholder="Seçim yapınız"
                    description="Harita üzerinde gösterilecek değer alanını seçin"
                    isDisabled={
                      aggregations.length || groups.length ? true : false
                    }
                  />
                </Column>

                <Column>
                  <Select
                    name="column"
                    options={fieldOptions}
                    onChange={(props) => {
                      onChange({
                        ...options,
                        description: (props as SelectOptionProps)
                          .value as string,
                      })
                    }}
                    onInputChange={handleChangeFieldInput}
                    value={
                      options.description
                        ? {
                            label: string.textify(options.description),
                            value: options.description,
                          }
                        : { label: '', value: '' }
                    }
                    label="Açıklama"
                    placeholder="Seçim yapınız"
                    description="Harita üzerinde gösterilecek açıklama alanını seçin"
                    isDisabled={
                      aggregations.length || groups.length ? true : false
                    }
                  />
                </Column>
              </Row>
            </Styled.ColoredMapDefinitionField>
          </Column>
        </Row>
      )
    }

    return (
      <Styled.ColoredMapDefinitions>
        {renderTabs()}
      </Styled.ColoredMapDefinitions>
    )
  }

export default ColoredMapDefinitions
