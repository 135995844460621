import React from 'react'

import { DragDropComponent } from 'bi-tool/drag-drop/DragDropComponent'
import ComponentHeader from 'bi-tool/drag-drop/ComponentHeader'
import Droppable from 'components/designer/Droppable'

import { PageProps } from './types'

export default class PageDesigner extends DragDropComponent<PageProps> {
  renderInformation() {
    if (this.props.children) {
      return null
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderInformation()}
        <ComponentHeader
          componentEntry={this.props.entry}
          componentSchemaId={this.props.id as string}
        />
        <Droppable
          elementType="page"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'baseline',
            paddingBottom: '50px',
          }}
          elementId={this.props.id as string}
          className={
            this.props.children ? 'fa-droppable-row' : 'fa-droppable-row row'
          }
        >
          {this.props.children}
        </Droppable>
      </React.Fragment>
    )
  }
}
