import { request } from 'services/http/dataset/instance'

import type {
  CreateSentimentDataSetRequest,
  SentimentDataSet,
  SentimentDataSetResponse,
  UpdateSentimentDataSetRequest,
} from './types'

import {
  mapSentimentDataSetForList,
  mapSentimentDataSetForRequest,
  mapSentimentDataSetForResponse,
} from './mappers'

export const create = (data: CreateSentimentDataSetRequest) =>
  request<SentimentDataSet>({
    method: 'post',
    url: 'sentiment-data-set',
    data,
    mappers: {
      req: mapSentimentDataSetForRequest,
    },
  })

export const get = () =>
  request<SentimentDataSetResponse>({
    method: 'get',
    url: 'sentiment-data-set',
    mappers: {
      res: mapSentimentDataSetForList,
    },
  })

export const getById = (id: number) =>
  request<SentimentDataSet>({
    method: 'get',
    url: `sentiment-data-set/${id}`,
    mappers: {
      res: mapSentimentDataSetForResponse,
    },
  })

export const update = (data: UpdateSentimentDataSetRequest, id?: number) =>
  request<SentimentDataSet>({
    method: 'put',
    url: `sentiment-data-set/${id || data.id}`,
    data,
    mappers: {
      req: mapSentimentDataSetForRequest,
    },
  })

export const del = (id: number) =>
  request<void>({
    method: 'delete',
    url: `sentiment-data-set/${id}`,
  })
