import React from 'react'
import * as Styled from './Overlay.styled'

export interface IOverlay {
  children: any
  label?: string
  topic?: string
  icon?: string
  color?: string
  background?: string
  style?: string
  titleColor?: string
  img?: any
}

const Overlay: React.FunctionComponent<IOverlay> = ({
  children,
  label,
  topic,
  icon,
  color,
  background,
  style,
  titleColor,
  img,
}) => {
  return (
    <Styled.OverlayContainer
      style={{ backgroundColor: color, marginTop: '2.5%', width: '100%' }}
    >
      <div className="overlay-header">
        <div className="overlay-ls">
          <h3 style={{ color: 'white' }}>{label}</h3>
          {topic && <span style={{ color: titleColor }}>&#9679;#{topic}</span>}
        </div>
        <div>
          {icon ? (
            <span>
              <i className={icon}></i>
            </span>
          ) : img ? (
            <span>
              <img src={img} alt="Twitter Logo" />
            </span>
          ) : null}
        </div>
      </div>
      <div
        style={{ backgroundColor: background }}
        className="overlay-content-container"
      >
        {children}
      </div>
    </Styled.OverlayContainer>
  )
}

export default Overlay
