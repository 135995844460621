import React, { useState } from 'react'
import * as Styled from './AlarmDesignerStyled'

import { FormSection } from '@zera-admin/form'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import { useFlagcardContext } from 'app/contexts/flagcard'
import { uid } from '@zera-admin/utils'
import Table from '@zera-admin/table'
import { Row, Column } from '@zera-admin/page'
import Input from '@zera-admin/input'

import {
  create,
  update,
} from 'services/http/backoffice/alarm-keyword/endpoints'

import {
  AlarmDesignerProps,
  AlarmScoreSettingsTypes,
  IAutoCompletionData,
  IFunctionMessageFieldsData,
} from './types'
import Modal from '@zera-admin/modal'
import { HeadingProps } from 'bi-tool/drag-drop/Heading'
import CodeEditor from './CodeEditor'
import ConditionBuilder, {
  ConditionBuilderValue,
  ConditionRuleField,
} from '@zera-admin/condition-builder'
import { conditionBuilderInsert } from 'app/functions/parser/condition-builder/insert'
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow'

const AlarmDesigner: React.FunctionComponent<AlarmDesignerProps> = ({
  settings,
  detail,
  operation = 'create',
  alarmType,
}) => {
  const [modal, setModal] = useState<HeadingProps | null>(null)

  const [text, setText] = useState<string>(detail?.messageTemplate || '')

  const [criteriaFilters, setCriteriaFilters] = useState<any>(
    detail?.criteriaGroup || []
  )

  const [excludedKeywordsValue, setExcludedKeywordsValue] = useState<string>()
  const [excludedKeywordsGroup, setExcludedKeywordsGroup] = useState<string[]>(
    detail?.excludedKeywords || []
  )
  const [chatIdValue, setChatIdValue] = useState<string>()
  const [chatIds, setChatIds] = useState<string[]>(
    detail?.telegramChat?.chatIds || []
  )
  const [tokens, setTokens] = useState({
    trBotToken: detail?.telegramChat?.trBotToken || undefined,
    otherBotToken: detail?.telegramChat?.otherBotToken || undefined,
  })
  const [alarmNames, setAlarmNames] = useState<string>()
  const [alarmNamesList, setAlarmNamesList] = useState<string[]>(
    detail?.alarmNames || []
  )
  const [description, setDescription] = useState<string>(
    detail?.description || ''
  )
  const [timePeriod, setTimePeriod] = useState<string | number>(
    detail?.timeRange || ''
  )
  const [equalControlWords, setEqualControlWords] = useState<string[]>(
    detail?.equalControlWords || []
  )
  const [containsControlWords, setContainsControlWords] = useState<string[]>(
    detail?.containsControlWords || []
  )
  useState<string>()
  const [containsControlWordsValue, setContainsControlWordsValue] =
    useState<string>()

  const [equalControlWordsValue, setEqualControlWordsValue] = useState<string>()
  const [alarmDefinitionText, setAlarmDefinitionText] = useState<string>('')
  const [alarmSecondaryWordsText, setAlarmSecondaryWordsText] =
    useState<string>('')
  const [alarmThirdWordsText, setAlarmThirdWordsText] = useState<string>('')

  const [alarmDefinitionList, setAlarmDefinitionList] = useState<string[]>(
    detail?.alarmScoreSettings?.definitions || []
  )
  const [alarmSecondaryWordsList, setAlarmSecondaryWordsList] = useState<
    string[]
  >(detail?.alarmScoreSettings?.secondaryWords || [])
  const [alarmThirdWordsList, setAlarmThirdWordsList] = useState<string[]>(
    detail?.alarmScoreSettings?.thirdWords || []
  )

  const [alarmScoreSettings, setAlarmScoreSettings] =
    useState<AlarmScoreSettingsTypes>(
      detail?.alarmScoreSettings
        ? (detail?.alarmScoreSettings as any)
        : {
            definitions: [],
            secondaryWords: [],
            thirdWords: [],
            definitionRatio: undefined,
            secondaryWordRatio: undefined,
            thirdWordRatio: undefined,
            generalRatio: undefined,
          }
    )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [flagcard] = useFlagcardContext()

  const getFunctionMessageFieldsData = () => {
    let datas: any = {}
    let dataTypes: any = {}

    const tmpFncAutoCompleteData: IAutoCompletionData[] = []
    const tmpMsgAutoCompleteData: IAutoCompletionData[] = []
    const tmpData: string[] = []
    const functionMessageFieldsData: IFunctionMessageFieldsData = {
      datas: {},
      dataTypes: {},
      dataList: [],
      msgAutoCompleteData: [],
      fncAutoCompleteData: [],
    }
    if (settings?.messageFields?.length > 0) {
      settings?.messageFields.forEach((msg) => {
        tmpMsgAutoCompleteData.push({
          label: `${msg.name} (${msg.trName})`,
          type: 'variable',
          apply: `$${msg.name}$`,
          detail: '',
        })
        datas[msg.name] = msg.trName
        tmpData.push(msg.name)
        dataTypes[msg.name] = 'message'
      })
    }
    if (settings?.functions?.length > 0) {
      settings?.functions.forEach((fnc) => {
        tmpFncAutoCompleteData.push({
          label: `${fnc.name} (${fnc.trName})`,
          type: 'constant',
          apply: `$@${fnc.name}@$`,
          detail: '',
        })
        datas[fnc.name] = fnc.trName
        tmpData.push(fnc.name)
        dataTypes[fnc.name] = 'function'
      })
    }
    functionMessageFieldsData.msgAutoCompleteData = tmpMsgAutoCompleteData
    functionMessageFieldsData.fncAutoCompleteData = tmpFncAutoCompleteData
    functionMessageFieldsData.datas = datas
    functionMessageFieldsData.dataList = tmpData
    functionMessageFieldsData.dataTypes = dataTypes

    return functionMessageFieldsData
  }

  const createAlarm = () => {
    setIsLoading(true)
    const msgFncData = parseMsgFncData()

    const createAlarmSchema: any = {
      alarmNames: alarmNamesList || undefined,
      description: description || undefined,
      source: settings?.source || undefined,
      platform: settings?.platform || undefined,
      criteriaGroup: conditionBuilderInsert(criteriaFilters) || undefined,
      alarmType: alarmType || undefined,
      messageFields: msgFncData?.msg || undefined,
      functions: msgFncData?.fnc || undefined,
      messageTemplate: text || undefined,
      containsControlWords: containsControlWords || undefined,
      equalControlWords: equalControlWords || undefined,
      timeRange: timePeriod || undefined,
      alarmScoreSettings: {
        definitionRatio: alarmScoreSettings?.definitionRatio || undefined,
        secondaryWordRatio: alarmScoreSettings?.secondaryWordRatio || undefined,
        thirdWordRatio: alarmScoreSettings?.thirdWordRatio || undefined,
        generalRatio: alarmScoreSettings?.generalRatio || undefined,
        definitions: alarmDefinitionList || undefined,
        secondaryWords: alarmSecondaryWordsList || undefined,
        thirdWords: alarmThirdWordsList || undefined,
      },
      telegramChat: {
        chatIds: chatIds || undefined,
        trBotToken: tokens.trBotToken || undefined,
        otherBotToken: tokens.otherBotToken || undefined,
      },
      excludedKeywords: excludedKeywordsGroup || undefined,
    }

    if (operation === 'create') {
      create(createAlarmSchema)
        .then(() => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: `Alarm oluşturuldu`,
            children: `Girmiş olduğunuz bilgiler ile birlikte alarm sisteme başarıyla oluşturuldu.`,
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch(() => {
          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: `Alarm eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.`,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (operation === 'detail') {
      update(createAlarmSchema, detail?.id || '')
        .then(() => {
          flagcard.add({
            id: uid(),
            appearance: 'success',
            title: `Alarm düzenlendi`,
            children: `Girmiş olduğunuz bilgiler ile birlikte alarm sisteme başarıyla düzenlenmiştir.`,
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch(() => {
          flagcard.add({
            id: uid(),
            appearance: 'error',
            title: 'Bir hata oluştu',
            children: `Alarm düzenlenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.`,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const removeControlWordsDefinition = (
    index: number,
    arrList: any[],
    setArrList: ([]: any) => void
  ) => {
    const updatedControlWords = [...arrList]
    updatedControlWords.splice(index, 1)

    setArrList(updatedControlWords)
  }
  const handleControlWordsList = (
    text: string,
    controlList: any,
    setControlList: (arr: any) => void,
    setControlValue: (value: string) => void
  ) => {
    const reference = controlList?.find((item: string) => item === text)
    if (!reference) {
      const newArr: any = [...controlList]
      const splitText = text.split(',')
      newArr.push(splitText)

      setControlList(newArr)
      setControlValue('')
    }
  }

  const handleDeleteItem = (index: number) => {
    if (Array.isArray(alarmNamesList)) {
      const updatedList = [...alarmNamesList]
      updatedList.splice(index, 1)
      setAlarmNamesList(updatedList)
    }
  }

  const renderTitleForm = () => {
    return (
      <Styled.TitleForm>
        <Column xs={{ size: 12 }}>
          <Row>
            <Column>
              <Input
                name="name"
                label="Telegram Bot Kanal Adı"
                placeholder="Telegram Bot Kanal Adı"
                value={alarmNames}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAlarmNames(event.currentTarget.value)
                }}
              />
            </Column>

            <div className="alarm-name-btn">
              <Button
                isDisabled={alarmNames && alarmNames?.length > 0 ? false : true}
                onClick={() => {
                  setAlarmNamesList((prev: any) => {
                    if (Array.isArray(prev)) {
                      return [...prev, alarmNames]
                    } else {
                      return [alarmNames]
                    }
                  })

                  setAlarmNames('')
                }}
              >
                Ekle
              </Button>
            </div>
          </Row>
        </Column>
        {(alarmNamesList?.length && alarmNamesList?.length > 0 && (
          <Column>
            <Table
              columns={
                [
                  { name: 'label', text: 'Kanal Adı' },
                  {
                    name: 'operation',
                    text: 'İşlem',
                    render: (row: any, index: number) => (
                      <Button
                        size="none"
                        onClick={() => handleDeleteItem(index)}
                      >
                        <Icon name="cross" size="small" />
                      </Button>
                    ),
                  },
                ] as any
              }
              rows={
                (alarmNamesList &&
                  alarmNamesList?.map((format, index) => ({
                    label: format,
                  }))) ||
                []
              }
            />
          </Column>
        )) ||
          null}

        <Column xs={{ size: 12 }}>
          <Input
            name="description"
            label="Açıklama"
            placeholder="Açıklama"
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.currentTarget.value)
            }}
          />
        </Column>
      </Styled.TitleForm>
    )
  }

  const renderModalContent = () => {
    const description =
      operation === 'detail' ? 'düzenleyebilirsiniz' : 'oluşturabilirsiniz'
    if (modal) {
      const actions = [
        {
          text: operation === 'detail' ? 'Düzenle' : 'Oluştur',
          onClick: () => {
            createAlarm()
            setModal(null)
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]

      return (
        <Modal
          title="Genel Bilgiler"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>Genel bilgilerin tanımlamalarını yaparak alarm {description}</p>

          <Row xs={{ direction: 'column' }}>
            {renderTitleForm()}
            <Column xs={{ size: 12 }}>
              <Row>
                <Column>
                  <Input
                    name="chatId"
                    label="chatIdGroup"
                    placeholder="chatIdGroup"
                    value={chatIdValue}
                    onChange={(event: any) => {
                      setChatIdValue(event.currentTarget.value)
                    }}
                  />
                </Column>

                <div className="alarm-name-btn">
                  <Button
                    style={{ marginTop: '2rem' }}
                    isDisabled={!chatIdValue?.length}
                    onClick={() => {
                      setChatIds((prev: any) => {
                        if (Array.isArray(prev)) {
                          return [...prev, chatIdValue]
                        } else {
                          return [chatIdValue]
                        }
                      })
                      setChatIdValue('')
                    }}
                  >
                    Ekle
                  </Button>
                </div>
              </Row>
            </Column>

            {chatIds && chatIds.length > 0 && (
              <Column>
                <Table
                  columns={
                    [
                      { name: 'label', text: 'chatIdGroup' },
                      {
                        name: 'operation',
                        text: 'İşlem',
                        render: (row: any, index: number) => (
                          <Button
                            size="none"
                            onClick={() =>
                              removeControlWordsDefinition(
                                index,
                                chatIds,
                                setChatIds
                              )
                            }
                          >
                            <Icon name="cross" size="small" />
                          </Button>
                        ),
                      },
                    ] as any
                  }
                  rows={(chatIds as (string | string[])[]).map(
                    (value, index) => ({
                      label: Array.isArray(value) ? value.join(', ') : value,
                    })
                  )}
                />
              </Column>
            )}
          </Row>
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                name="trBotToken"
                label="trBotToken"
                placeholder="trBotToken"
                value={tokens.trBotToken}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTokens((prevTokens: any) => ({
                    ...prevTokens,
                    trBotToken: event.target.value,
                  }))
                }}
              />
            </Column>
            <Column>
              <Input
                name="otherBotToken"
                label="otherBotToken"
                placeholder="otherBotToken"
                value={tokens.otherBotToken}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTokens((prevTokens: any) => ({
                    ...prevTokens,
                    otherBotToken: event.target.value,
                  }))
                }}
              />
            </Column>
          </Row>

          <Row xs={{ direction: 'column' }}>
            <Column xs={{ size: 4 }}>
              <Input
                name="times"
                label="Zaman Aralığı"
                type="number"
                min={0}
                placeholder="Zaman Aralığı"
                value={timePeriod}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTimePeriod(Number(event.target.value))
                }}
              />
            </Column>
          </Row>
        </Modal>
      )
    }
  }

  const parseMsgFncData = () => {
    const msg: string[] = []
    const fnc: string[] = []
    const dataList: string[] = []
    // Düzenli ifade oluşturun
    const functionRegex = /\$\@([a-zA-Z]+)\@\$/g
    const msgRegex = /\$([a-zA-Z]+)\$/g

    // Metindeki tüm eşleşmeleri bulun
    const fncSync = text.match(functionRegex)
    const msgSync = text.match(msgRegex)

    if (msgSync && msgSync?.length > 0) {
      msgSync.forEach((m: string) => {
        const msgData = m.replace(/\$/g, '')
        dataList.push(msgData)
        msg.push(msgData)
      })
    }
    if (fncSync && fncSync?.length > 0) {
      fncSync.forEach((func: string) => {
        const msgData = func.replace(/\$\@|\@\$/g, '')
        dataList.push(msgData)
        fnc.push(msgData)
      })
    }
    return {
      msg,
      fnc,
      dataList,
    }
  }

  const parseCriteriaFields = () => {
    const fields: ConditionRuleField[] = []

    if (settings?.criteriaFields && settings?.criteriaFields?.length > 0) {
      settings.criteriaFields.forEach((setting) => {
        const operationOptions =
          setting?.conditions?.map((x) => ({
            label: x.value || '',
            value: x.key || '',
          })) || undefined

        const options =
          setting?.data?.values?.map((x) => ({
            label: x.value || '',
            value: x.key || '',
          })) || undefined

        const optionData =
          setting?.type === 'boolean' && !options?.length
            ? operationOptions
            : options
        fields.push({
          name: setting.field,
          label: setting.alias,
          type: setting.type as any,
          options: optionData,
          operationOptions,
          hideOperation: setting?.type === 'boolean' && !options?.length,
        })
      })
    }

    return fields
  }

  const functionMessageFieldsData = getFunctionMessageFieldsData()

  const msgFncData = parseMsgFncData()

  const criteriaFields = parseCriteriaFields()

  return (
    <React.Fragment>
      <Styled.AlarmDesignerContainer>
        <FormSection
          title="Kriter Grupları"
          description="Alarm oluşturmak aşağıdaki icin kriter gruplarını seçebilirsiniz."
        >
          <div className="condition-builder">
            <ConditionBuilder
              defaultValue={criteriaFilters as ConditionBuilderValue}
              fields={criteriaFields}
              onChange={(e) => {
                console.log(e, 'ConditionBuilder')
                setCriteriaFilters(e)
              }}
            />
          </div>
        </FormSection>

        <FormSection
          className="mesagge-section"
          title="Mesaj Şablonu"
          description="Alarm olusturmak icin mesaj şablonu oluşturabilirsiniz."
        >
          <Row xs={{ direction: 'column' }}>
            <Column className="code-content-field">
              <code>Fields: $Değer$ {'==>'} şeklinde oluşturabilirsiniz.</code>
            </Column>
            <Column className="code-content-functions">
              <code>
                Functions: $@Değer@$ {'==>'} şeklinde oluşturabilirsiniz.
              </code>
            </Column>
          </Row>

          <CodeEditor
            value={text}
            onChange={(data) => setText(data)}
            msgAutoCompletionData={
              functionMessageFieldsData?.msgAutoCompleteData || []
            }
            fncAutoCompletionData={
              functionMessageFieldsData?.fncAutoCompleteData || []
            }
          />
          {msgFncData?.dataList?.length > 0 && (
            <FormSection title="Mesaj Şablonu Tanımları" description="">
              <Row>
                {msgFncData?.dataList?.map((data) => (
                  <Column md={{ size: 4 }}>
                    <Row
                      className={`code-container-${functionMessageFieldsData.dataTypes[data]}`}
                    >
                      <Column
                        className={
                          functionMessageFieldsData.dataTypes[data] ===
                          'message'
                            ? 'code-field'
                            : 'code-functions'
                        }
                      >
                        {data}
                      </Column>
                      <Column>{functionMessageFieldsData.datas[data]}</Column>
                    </Row>
                  </Column>
                ))}
              </Row>
            </FormSection>
          )}

          {msgFncData?.dataList &&
          msgFncData?.dataList?.length > 0 &&
          msgFncData?.dataList?.includes('keywordControl') ? (
            <div className="row-line">
              <FormSection title="Kontrol Kelime Grupları">
                <Row xs={{ direction: 'column' }}>
                  <Column xs={{ size: 4 }}>
                    <div className="input-col">
                      <div className="input-col-item">
                        <Input
                          name="containsControlWords"
                          label="İçerilen Kontrol Kelimeleri"
                          placeholder="İçerilen Kontrol Kelimeleri"
                          description="İçerilen kelimeleri kontrol etmek icin kelime grubu ekleyiniz.
                (* Kelime Grubu oluşturabilmek icin her kelimeden sonra (,) ekleyerek yeni kelimenizi yazabilirsiniz)
                "
                          value={containsControlWordsValue}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setContainsControlWordsValue(event.target.value)
                          }}
                        />
                      </div>

                      <div className="input-col-btn">
                        <Button
                          style={{ marginTop: '1rem' }}
                          isDisabled={!containsControlWordsValue?.length}
                          onClick={() =>
                            handleControlWordsList(
                              containsControlWordsValue as string,
                              containsControlWords,
                              setContainsControlWords,
                              setContainsControlWordsValue
                            )
                          }
                        >
                          Ekle
                        </Button>
                      </div>
                    </div>
                  </Column>
                  {containsControlWords.length > 0 && (
                    <Column>
                      <Table
                        columns={
                          [
                            {
                              name: 'label',
                              text: 'İçerilen Kontrol Kelimeleri',
                            },
                            {
                              name: 'operation',
                              text: 'İşlem',
                              render: (row: any, index: number) => (
                                <Button
                                  size="none"
                                  onClick={() =>
                                    removeControlWordsDefinition(
                                      index,
                                      containsControlWords,
                                      setContainsControlWords
                                    )
                                  }
                                >
                                  <Icon name="cross" size="small" />
                                </Button>
                              ),
                            },
                          ] as any
                        }
                        rows={(
                          containsControlWords as (string | string[])[]
                        ).map((value, index) => ({
                          label: Array.isArray(value)
                            ? value.join(', ')
                            : value,
                        }))}
                      />
                    </Column>
                  )}
                </Row>
                <Row xs={{ direction: 'column' }}>
                  <Column xs={{ size: 4 }}>
                    <div className="input-col">
                      <div className="input-col-item">
                        <Input
                          name="equalControlWords"
                          label="Eşit Kontrol Kelimeleri"
                          description="Eşitlik içeren kelimeleri kontrol etmek icin kelime grubu ekleyiniz.
                (* Kelime Grubu oluşturabilmek icin her kelimeden sonra (,) ekleyerek yeni kelimenizi yazabilirsiniz)
                "
                          placeholder="Eşit Kontrol Kelimeleri"
                          value={equalControlWordsValue}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setEqualControlWordsValue(event.target.value)
                          }}
                        />
                      </div>

                      <div className="input-col-btn">
                        <Button
                          style={{ marginTop: '1rem' }}
                          isDisabled={!equalControlWordsValue?.length}
                          onClick={() =>
                            handleControlWordsList(
                              equalControlWordsValue as string,
                              equalControlWords,
                              setEqualControlWords,
                              setEqualControlWordsValue
                            )
                          }
                        >
                          Ekle
                        </Button>
                      </div>
                    </div>
                  </Column>
                  {equalControlWords?.length > 0 && (
                    <Column>
                      <Table
                        columns={
                          [
                            { name: 'label', text: 'Eşit Kontrol Kelimeleri' },
                            {
                              name: 'operation',
                              text: 'İşlem',
                              render: (row: any, index: number) => (
                                <Button
                                  size="none"
                                  onClick={() =>
                                    removeControlWordsDefinition(
                                      index,
                                      equalControlWords,
                                      setEqualControlWords
                                    )
                                  }
                                >
                                  <Icon name="cross" size="small" />
                                </Button>
                              ),
                            },
                          ] as any
                        }
                        rows={(equalControlWords as (string | string[])[]).map(
                          (value, index) => ({
                            label: Array.isArray(value)
                              ? value.join(', ')
                              : value,
                          })
                        )}
                      />
                    </Column>
                  )}
                </Row>
                <Row xs={{ direction: 'column' }}>
                  <Column xs={{ size: 4 }}>
                    <div className="input-col">
                      <div className="input-col-item">
                        <Input
                          name="excludedKeywords"
                          label="Engellenecek Kelimeler"
                          placeholder="Engellenecek Kelimeler"
                          description="Engellenecek kelimeleri kontrol etmek icin kelime ekleyiniz."
                          value={excludedKeywordsValue}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setExcludedKeywordsValue(event.currentTarget.value)
                          }}
                        />
                      </div>
                      <div className="input-col-btn">
                        <Button
                          style={{ marginTop: '1rem' }}
                          isDisabled={!excludedKeywordsValue?.length}
                          onClick={() => {
                            setExcludedKeywordsGroup((prev: any) => [
                              ...prev,
                              excludedKeywordsValue,
                            ])
                            setExcludedKeywordsValue('')
                          }}
                        >
                          Ekle
                        </Button>
                      </div>
                    </div>
                  </Column>

                  {excludedKeywordsGroup.length > 0 && (
                    <Column>
                      <Table
                        columns={
                          [
                            { name: 'label', text: 'Engellenecek Kelimeler' },
                            {
                              name: 'operation',
                              text: 'İşlem',
                              render: (row: any, index: number) => (
                                <Button
                                  size="none"
                                  onClick={() =>
                                    removeControlWordsDefinition(
                                      index,
                                      excludedKeywordsGroup,
                                      setExcludedKeywordsGroup
                                    )
                                  }
                                >
                                  <Icon name="cross" size="small" />
                                </Button>
                              ),
                            },
                          ] as any
                        }
                        rows={(
                          excludedKeywordsGroup as (string | string[])[]
                        ).map((value, index) => ({
                          label: Array.isArray(value)
                            ? value.join(', ')
                            : value,
                        }))}
                      />
                    </Column>
                  )}
                </Row>
              </FormSection>
            </div>
          ) : null}

          {msgFncData?.dataList &&
          msgFncData?.dataList?.length > 0 &&
          msgFncData?.dataList?.includes('alarmScore') ? (
            <Row className="alarm-score-container" xs={{ direction: 'column' }}>
              <FormSection
                title="Alarm Skor Ayarları"
                description="Alarm oluşturmak icin alarm skor degerlerini girebilirsiniz."
              >
                <Row style={{ marginTop: 20 }}>
                  <Column xs={{ size: 4 }}>
                    <Input
                      name="definitionRatio"
                      type="number"
                      min={0}
                      label="Tanımlama Oranı"
                      placeholder="Tanımlama Oranı"
                      value={alarmScoreSettings?.definitionRatio}
                      onChange={(event) => {
                        const value = event.currentTarget.value
                        setAlarmScoreSettings((prevSettings: any) => ({
                          ...prevSettings,
                          definitionRatio: Number(value),
                        }))
                      }}
                    />
                  </Column>
                  <Column xs={{ size: 4 }}>
                    <Input
                      name="secondaryWordRatio"
                      type="number"
                      min={0}
                      label="İkincil Kelime Oranı"
                      placeholder="İkincil Kelime Oranı"
                      value={alarmScoreSettings?.secondaryWordRatio}
                      onChange={(event) => {
                        const value = event.currentTarget.value
                        setAlarmScoreSettings((prevSettings: any) => ({
                          ...prevSettings,
                          secondaryWordRatio: Number(value),
                        }))
                      }}
                    />
                  </Column>
                  <Column xs={{ size: 4 }}>
                    <Input
                      name="thirdWordRatio"
                      type="number"
                      min={0}
                      label="Üçüncü Kelime Oranı"
                      placeholder="Üçüncü Kelime Oranı"
                      value={alarmScoreSettings?.thirdWordRatio}
                      onChange={(event) => {
                        const value = event.currentTarget.value
                        setAlarmScoreSettings((prevSettings: any) => ({
                          ...prevSettings,
                          thirdWordRatio: Number(value),
                        }))
                      }}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column xs={{ size: 12 }}>
                    <Input
                      name="generalRatio"
                      label="Genel Oran"
                      type="number"
                      min={0}
                      placeholder="Genel Oran"
                      value={alarmScoreSettings?.generalRatio}
                      onChange={(event) => {
                        const value = event.currentTarget.value
                        setAlarmScoreSettings((prevSettings: any) => ({
                          ...prevSettings,
                          generalRatio: Number(value),
                        }))
                      }}
                    />
                  </Column>
                </Row>

                <Row>
                  <Column
                    xs={{
                      size: 4,
                    }}
                  >
                    <Row xs={{ direction: 'column' }}>
                      <Column>
                        <div className="input-col">
                          <div className="input-col-item">
                            <Input
                              name="alarmDefinitionText"
                              label="Tanımlamalar"
                              placeholder="Tanımlamalar"
                              description="Her tanımlamadan sonra ekle buttonuna basarak birden fazla tanımlama ekleyebilirsiniz."
                              value={alarmDefinitionText}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setAlarmDefinitionText(event.target.value)
                              }}
                            />
                          </div>

                          <div className="input-col-btn">
                            <Button
                              style={{ marginTop: '1rem' }}
                              isDisabled={!alarmDefinitionText?.length}
                              onClick={() => {
                                setAlarmDefinitionList([
                                  ...alarmDefinitionList,
                                  alarmDefinitionText,
                                ])
                                setAlarmDefinitionText('')
                              }}
                            >
                              Ekle
                            </Button>
                          </div>
                        </div>
                      </Column>

                      {alarmDefinitionList?.length > 0 && (
                        <Column>
                          <Table
                            className="alarmSkorScroll"
                            columns={
                              [
                                {
                                  name: 'label',
                                  text: 'Tanımlamalar',
                                  width: '300px',
                                  render: (row: any) => {
                                    return (
                                      <div style={{ overflowX: 'scroll' }}>
                                        {row?.label}
                                      </div>
                                    )
                                  },
                                },
                                {
                                  name: 'operation',
                                  text: 'İşlem',
                                  render: (row: any, index: number) => (
                                    <Button
                                      size="none"
                                      onClick={() =>
                                        removeControlWordsDefinition(
                                          index,
                                          alarmDefinitionList,
                                          setAlarmDefinitionList
                                        )
                                      }
                                    >
                                      <Icon name="cross" size="small" />
                                    </Button>
                                  ),
                                },
                              ] as any
                            }
                            rows={alarmDefinitionList.map((value: string) => ({
                              label: value,
                            }))}
                          />
                        </Column>
                      )}
                    </Row>
                  </Column>
                  <Column
                    xs={{
                      size: 4,
                    }}
                  >
                    <Row xs={{ direction: 'column' }}>
                      <Column>
                        <div className="input-col">
                          <div className="input-col-item">
                            <Input
                              name="alarmSecondaryWordsText"
                              label="İkincil Kelimeler"
                              placeholder="İkincil Kelimeler"
                              description="Her ikincil kelime sonra ekle buttonuna basarak birden fazla ikinci kelime ekleyebilirsiniz."
                              value={alarmSecondaryWordsText}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setAlarmSecondaryWordsText(event.target.value)
                              }}
                            />
                          </div>

                          <div className="input-col-btn">
                            <Button
                              style={{ marginTop: '1rem' }}
                              isDisabled={!alarmSecondaryWordsText?.length}
                              onClick={() => {
                                setAlarmSecondaryWordsList([
                                  ...alarmSecondaryWordsList,
                                  alarmSecondaryWordsText,
                                ])
                                setAlarmSecondaryWordsText('')
                              }}
                            >
                              Ekle
                            </Button>
                          </div>
                        </div>
                      </Column>

                      {alarmSecondaryWordsList?.length > 0 && (
                        <Column>
                          <Table
                            columns={
                              [
                                { name: 'label', text: 'İkincil Kelimeler' },
                                {
                                  name: 'operation',
                                  text: 'İşlem',
                                  render: (row: any, index: number) => (
                                    <Button
                                      size="none"
                                      onClick={() =>
                                        removeControlWordsDefinition(
                                          index,
                                          alarmSecondaryWordsList,
                                          setAlarmSecondaryWordsList
                                        )
                                      }
                                    >
                                      <Icon name="cross" size="small" />
                                    </Button>
                                  ),
                                },
                              ] as any
                            }
                            rows={alarmSecondaryWordsList.map(
                              (value: string) => ({
                                label: value,
                              })
                            )}
                          />
                        </Column>
                      )}
                    </Row>
                  </Column>
                  <Column
                    xs={{
                      size: 4,
                    }}
                  >
                    <Row xs={{ direction: 'column' }}>
                      <Column>
                        <div className="input-col">
                          <div className="input-col-item">
                            <Input
                              name="alarmThirdWordsText"
                              label="Üçüncü Kelimeler"
                              placeholder="Üçüncü Kelimeler"
                              description="Her üçüncü kelimeden sonra ekle buttonuna basarak birden fazla üçüncü kelime ekleyebilirsiniz."
                              value={alarmThirdWordsText}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                setAlarmThirdWordsText(event.target.value)
                              }}
                            />
                          </div>

                          <div className="input-col-btn">
                            <Button
                              style={{ marginTop: '1rem' }}
                              isDisabled={!alarmThirdWordsText?.length}
                              onClick={() => {
                                setAlarmThirdWordsList([
                                  ...alarmThirdWordsList,
                                  alarmThirdWordsText,
                                ])
                                setAlarmThirdWordsText('')
                              }}
                            >
                              Ekle
                            </Button>
                          </div>
                        </div>
                      </Column>

                      {alarmThirdWordsList?.length > 0 && (
                        <Column>
                          <Table
                            columns={
                              [
                                { name: 'label', text: 'Üçüncü Kelimeler' },
                                {
                                  name: 'operation',
                                  text: 'İşlem',
                                  render: (row: any, index: number) => (
                                    <Button
                                      size="none"
                                      onClick={() =>
                                        removeControlWordsDefinition(
                                          index,
                                          alarmThirdWordsList,
                                          setAlarmThirdWordsList
                                        )
                                      }
                                      key={row.label}
                                    >
                                      <Icon name="cross" size="small" />
                                    </Button>
                                  ),
                                },
                              ] as any
                            }
                            rows={alarmThirdWordsList.map((value: string) => ({
                              label: value,
                            }))}
                          />
                        </Column>
                      )}
                    </Row>
                  </Column>
                </Row>
              </FormSection>
            </Row>
          ) : null}
        </FormSection>
        {renderModalContent()}
        <div className="btn-container">
          <Button
            appearance="primary"
            onClick={() => {
              setModal({
                caption: 'modal',
                title: 'modal',
                nodeType: 'h1',
              })
            }}
            isDisabled={isLoading}
          >
            {operation === 'detail' ? 'Alarm Düzenle' : 'Alarm Oluştur'}
          </Button>
        </div>
      </Styled.AlarmDesignerContainer>
    </React.Fragment>
  )
}

export default AlarmDesigner
