import React, { useState } from 'react'
import { Column, PageHeader, Row } from '@zera-admin/page'
import * as yup from 'yup'
import Button, { ButtonGroup } from '@zera-admin/button'
import Form, { FormFooter } from '@zera-admin/form'
import Input from '@zera-admin/input'
import { cookie } from '@zera-admin/helpers'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'
import AccountLayout from 'layouts/account'
import userService, {
  UserChangePasswordRequest,
} from 'services/http/identity-server/user'
import { jvt } from 'app/functions'

import { PasswordProps } from './types'

const validations: { [key: string]: any } = {
  oldPassword: yup.string().required('Bu alan zorunludur.'),
  newPassword: yup.string().required('Bu alan zorunludur.'),
  againNewPassword: yup
    .string()
    .required('Bu alan zorunludur.')
    .oneOf([yup.ref('newPassword')], 'Şifreler uyuşmuyor.'),
}

const Password: React.FunctionComponent<PasswordProps> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()
  const token = jvt()

  const updatePassword = async (data: UserChangePasswordRequest) => {
    setIsLoading(true)

    try {
      await userService.changePassword({
        ...data,
        id: parseInt(token?.sub || ''),
      })

      flagcard.add({
        title: 'Şifre güncellendi',
        children:
          'Şifreniz başarıyla güncellendi. Tekrar giriş yapmanız gerekiyor.',
      })

      cookie.remove('token')
      history.push('/signin')
    } catch {
      popup.add({
        title: 'Şifre güncellenemedi',
        children:
          'Şifre güncellemesi gerçekleşirken bi sorun oluştu, lütfen terkar deneyin.',
      })
    }

    setIsLoading(false)
  }

  const handleSubmit = (data: UserChangePasswordRequest) => {
    const schema = yup.object().shape(validations)

    schema
      .validate(data, { abortEarly: false })
      .then(() => {
        updatePassword(data)
      })
      .catch(() => {
        popup.add({
          title: 'Eksik alanlar var',
          children: 'Lütfen tüm zorunlu alanları doldurun.',
        })
      })
  }

  return (
    <AccountLayout
      header={
        <PageHeader
          bottomBar={<p>Kişisel bilgilerinizi görüntüleyin ve güncelleyin.</p>}
        >
          Şifre
        </PageHeader>
      }
    >
      <Form onSubmit={handleSubmit}>
        <Row>
          <Column xs={{ size: 12 }}>
            <Input
              description="Değiştirmek için mevcut şifrenizi sağlamalısınız."
              label="Şimdiki şifre"
              name="oldPassword"
              placeholder="******"
              type="password"
            />
          </Column>
          <Column xs={{ size: 12 }}>
            <Input
              description="Değiştirmek istediğini yeni şifreyi girin."
              label="Yeni şifre"
              name="newPassword"
              placeholder="******"
              type="password"
            />
          </Column>
          <Column xs={{ size: 12 }}>
            <Input
              description="Değiştirmek istediğini yeni şifreyi tekrar girin."
              label="Yeni şifre (Tekrar)"
              name="againNewPassword"
              placeholder="******"
              type="password"
            />
          </Column>
        </Row>
        <FormFooter>
          <ButtonGroup>
            <Button appearance="primary" isLoading={isLoading} type="submit">
              Şifreyi güncelle
            </Button>
          </ButtonGroup>
        </FormFooter>
      </Form>
    </AccountLayout>
  )
}

export default Password
