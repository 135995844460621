import React, { useEffect, useState } from 'react'
import * as Styled from './NewsCountryDistribution.styled'
import WidgetLayout from 'pages/agenda-report-v4/detail/widgets/widget-layout'
import UpdateButton from 'pages/agenda-report-v4/detail/components/update-button/UpdateButton'
import { NewsCountryDistributionProps } from './types'
import { CountryDistribution } from '../../widgets/types'
import PieChart from '../../components/pie-chart/PieChart'

const NewsCountryDistribution: React.FC<NewsCountryDistributionProps> = ({
  date,
  isEditValue,
  pageNumber,
  setIsEditValue,
  title,
  data,
  metaData,
}) => {
  const [pieChartData, setPieChartData] = useState<
    CountryDistribution[] | undefined
  >(data)
  const [pageData, setPageData] = useState<{
    title: string
    pageNumber: number
  }>({ title: title, pageNumber: pageNumber })

  const [editMode, setEditMode] = useState<boolean>(false)

  useEffect(() => {
    if (isEditValue === pageNumber) {
      setEditMode(true)
    }
  }, [isEditValue, pageNumber])

  useEffect(() => {
    if (isEditValue === -1) {
      setPieChartData(data)
      setPageData({ title: title, pageNumber: pageNumber })
    }
  }, [isEditValue, data, title, pageNumber])

  const onChange = (key: string, value: string, source: string) => {
    setPieChartData(
      pieChartData?.map((item) => {
        if (item.Country === source) {
          return {
            ...item,
            [key]: value,
          }
        }
        return item
      })
    )
  }

  const onChangePageData = (key: string, value: string) => {
    setPageData({
      ...pageData,
      [key]: value,
    })
  }

  const editProps = {
    isEditValue: isEditValue,
    setIsEditValue: setIsEditValue,
    pageNumber: pageData.pageNumber,
    metaData,
  }

  return (
    <WidgetLayout
      asideDate={date}
      asideTitle={pageData.title}
      pageNumber={pageData.pageNumber}
      onChange={onChangePageData}
      editMode={editMode}
      flagShortCode={metaData?.flagShortCode}
    >
      <Styled.ContentContainer>
        <div className="edit-btn-container">
          <UpdateButton
            {...editProps}
            contentData={{ CountryDistribution: pieChartData }}
            title={pageData?.title}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </div>
        <div>
          <PieChart
            setData={setPieChartData}
            editMode={editMode}
            onChange={onChange}
            data={pieChartData}
          />
        </div>
      </Styled.ContentContainer>
    </WidgetLayout>
  )
}

export default NewsCountryDistribution
