import React from 'react'
import Modal from '@zera-admin/modal'
import CreatePageForm from '../createPageForm/CreatePageForm'

import { ModalContent } from './ReportAddModal.styled'
import { ReportAddModalProps } from './types'

const ReportAddModal: React.FunctionComponent<ReportAddModalProps> = ({
  onClose,
  visible,
  metaData,
}) => {
  const actions = [
    {
      text: 'Kapat',
      onClick: () => {
        onClose()
      },
    },
  ]

  return visible ? (
    <Modal width={1200} height={800} actions={actions}>
      <ModalContent>
        <CreatePageForm onClose={onClose} metaData={metaData} />
      </ModalContent>
    </Modal>
  ) : null
}
export default ReportAddModal
