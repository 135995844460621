import { request } from 'services/http/bi-tool/instance'

import type {
  Dashboard,
  DashboardResponse,
  CreateDashboardRequest,
  DashboardPreview,
  CreateDashboardResponse,
  UpdateDashboardRequest,
} from './types'

import {
  mapDashboardForList,
  mapDashboardForRequest,
  mapDashboardForResponse,
  mapDashboardPreviewForResponse,
} from './mappers'

export const get = (title?: string, pageIndex?: number, pageSize = 200) =>
  request<DashboardResponse>({
    method: 'get',
    url: 'back-office/dashboard',
    params: { title, pageIndex, pageSize },
    mappers: {
      res: mapDashboardForList,
    },
  })

export const getById = (id: string) =>
  request<Dashboard>({
    method: 'get',
    url: `back-office/dashboard/${id}`,
    mappers: {
      res: mapDashboardForResponse,
    },
  })

export const preview = (data: DashboardPreview) =>
  request<unknown[]>({
    method: 'post',
    url: 'back-office/dashboard/preview',
    data,
    mappers: {
      res: mapDashboardPreviewForResponse,
    },
  })

export const create = (data?: CreateDashboardRequest) =>
  request<CreateDashboardResponse>({
    method: 'post',
    url: 'back-office/dashboard',
    data,
  })

export const update = (id: string, data: UpdateDashboardRequest) =>
  request<Dashboard>({
    method: 'put',
    url: `back-office/dashboard/${id}`,
    data,
    mappers: {
      req: mapDashboardForRequest,
    },
  })

export const del = (id: string) =>
  request<void>({
    method: 'delete',
    url: `back-office/dashboard/${id}`,
  })
