import React from 'react'
import EmptyState from '@zera-admin/empty-state'
import WidgetGeoChart from 'widgets/geo-chart-map'
import { FollowersByCountryProps } from './types'

const FollowersByCountry: React.FunctionComponent<FollowersByCountryProps> = ({
  data,
}) => {
  const geoData = data?.map((item: any) => ({
    country: item.country,
    count: item.accountCount,
  }))

  return data && data.length > 0 ? (
    <WidgetGeoChart height={300} label="Takipçi" values={geoData} />
  ) : (
    <EmptyState
      title="Veri bulunamadı"
      description="Kullanıcı verisi bulunamadı"
    />
  )
}

export default FollowersByCountry
