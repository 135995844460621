import styled from 'styled-components'

export const CompoundButton = styled.div`
  width: 100%;

  button,
  a {
    text-align: left;
    height: auto;
    padding: 1rem;
    width: 100%;

    p {
      font-size: 0.8em;
      margin: -15px 0 0 0;
    }

    span {
      display: block;
    }
  }
`
