import { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'

import { QueryRunResponse } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import {
  AreaChartVisualizationNumberFormat,
  AreaChartVisualizationOptions,
} from '../types'
import { numberFormat } from './helpers'

export const mapAreaChartProps = (
  values: QueryRunResponse,
  visualization: WidgetVisualization<AreaChartVisualizationOptions>
): Props => {
  let result: Props = {}
  const label = visualization.options.label
  const value = visualization.options.value

  if (values.rows.length) {
    result = {
      options: {
        chart: {
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          categories: values?.rows
            .filter(
              (row) => row[label?.field] && (row[value?.field] as number) > 0
            )
            .map((row) => row[label?.field] as string),
        },
        stroke: {
          curve: 'straight',
        },
        dataLabels: {
          enabled: false,
          formatter: (data: number) => {
            return numberFormat(
              data,
              value?.format || ({} as AreaChartVisualizationNumberFormat)
            )
          },
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: [colors.N600],
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            dataLabels: {
              position: 'top',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (data: number) => {
              return numberFormat(
                data,
                value?.format || ({} as AreaChartVisualizationNumberFormat)
              )
            },
          },
        },
        colors: ['#1382a3'],
        theme: {
          mode: visualization.theme,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
      },
      series: [
        {
          name: value?.alias || value?.field,
          data: values.rows
            .filter((row) => (row[value?.field] as number) > 0)
            .map((row) => row[value?.field]),
        },
      ],
    }
  }

  return result
}
