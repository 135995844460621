import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { validation } from 'app/functions'
import useSources from 'app/hooks/sources'
import { ClassificationDataSet } from 'services/http/dataset/classification-data-set'

import { ClassificationDataSetFormProps } from './types'
import useTrainingGroup from 'app/hooks/training-group'
import useClassificationCategory from 'app/hooks/classification-category'

const validations = {
  // category_type_id_list: yup.string().required('Kategori listesi seçmelisiniz'),
  languageCode: yup.string().required('Dil kodu girmelisiniz'),
  sourceId: yup.string().required('Kaynak seçmelisiniz'),
  text: yup.string().required('Sınıflandırma veri seti metni girmelisiniz'),
}

const ClassificationDataSetForm: React.FunctionComponent<ClassificationDataSetFormProps> =
  ({ actions = [], onError, onSubmit, values }) => {
    const form = useForm<ClassificationDataSet>({
      initialValues: values,
      onSubmit: (props) => handleSubmit(props),
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })

    const {
      sources,
      getSources,
      getSource,
      isLoading: isLoadingSources,
    } = useSources()

    const {
      trainingGroups,
      getTraningGroup,
      isLoading: isLoadingTraining,
    } = useTrainingGroup()

    const {
      classificationCategories,
      getClassificationCategory,
      isLoading: isLoadingClassificationCategory,
    } = useClassificationCategory()

    const handleSubmit = (
      props: FormHookSubmitProps<ClassificationDataSet>
    ) => {
      if (!props.errors) {
        if (onSubmit) {
          onSubmit(props.values, () => {
            if (props.handleReset) {
              props.handleReset()
            }
          })
        }
      } else {
        if (onError) {
          onError(props.errors)
        }
      }
    }

    return (
      <Form onSubmit={form.handleSubmit}>
        <FormSection
          title="Genel bilgiler"
          description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
        >
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                description="Geçerli bir ssınıflandırma veri seti metni giriniz"
                errorMessage={form.errors.text}
                label="Sınıflandırma veri seti metni"
                name="text"
                placeholder="Örnek metin"
                value={form.values.text}
                onChange={form.handleChange}
              />
            </Column>
            <Column>
              <Input
                description="Geçerli bir dil kodu giriniz"
                errorMessage={form.errors.languageCode}
                label="Dil kodu"
                name="languageCode"
                placeholder="Örnek: tr, en, vs.."
                value={form.values.languageCode}
                onChange={form.handleChange}
              />
            </Column>
            <Column>
              <SearchBox
                description="Geçerli bir kaynak seçimi yapınız"
                errorMessage={form.errors.sourceId}
                label="Kaynak"
                name="sourceId"
                options={sources}
                value={getSource(form.values.sourceId, form.values.sourceName)}
                onFetchOptions={(value) => getSources(value)}
                onChange={(props) =>
                  form.handleFieldChange(
                    'sourceId',
                    (props as SearchboxOptionProps).value
                  )
                }
                placeholder="Seçim yapın"
                isLoading={isLoadingSources}
              />
            </Column>
            <Column>
              <Select
                description="Geçerli bir kategori seçin"
                label="Kategori seçiniz"
                name="classificationCategoryId"
                value={getClassificationCategory(
                  form.values.classificationCategoryId,
                  form.values.classificationCategoryName
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'classificationCategoryId',
                    (props as SelectOptionProps).value
                  )
                }
                options={classificationCategories}
                isLoading={isLoadingClassificationCategory}
              />
            </Column>
            <Column>
              <Select
                description="Geçerli bir eğitim grubu seçin"
                label="Eğitim grubu seçiniz"
                name="trainingGroupId"
                value={getTraningGroup(
                  form.values.trainingGroupId,
                  form.values.trainingGroupName
                )}
                onChange={(props) =>
                  form.handleFieldChange(
                    'trainingGroupId',
                    (props as SelectOptionProps).value
                  )
                }
                options={trainingGroups}
                isLoading={isLoadingTraining}
              />
            </Column>
          </Row>
        </FormSection>
        <FormFooter>{actions}</FormFooter>
      </Form>
    )
  }

export default ClassificationDataSetForm
