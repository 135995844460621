import React from 'react'

import { DragDropComponent } from 'bi-tool/drag-drop/DragDropComponent'
import ComponentHeader from 'bi-tool/drag-drop/ComponentHeader'
import Droppable from 'components/designer/Droppable'

import { RowProps } from './types'

export default class RowDesigner extends DragDropComponent<RowProps> {
  renderInformation() {
    if (this.props.children || !this.props.defaultElement) {
      return null
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderInformation()}
        <ComponentHeader
          componentEntry={this.props.entry}
          componentSchemaId={this.props.id as string}
          componentIsDefault={this.props.defaultElement}
        />
        <Droppable
          style={{ display: 'flex', flexDirection: 'row' }}
          elementId={this.props.id as string}
          className={
            this.props.children ? 'fa-droppable-row' : 'fa-droppable-row row'
          }
          groupName="layout"
          disableSort={true}
          elementType="grid"
        >
          {this.props.children}
        </Droppable>
      </React.Fragment>
    )
  }
}
