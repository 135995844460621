import { QueryRunResponse } from 'services/http/bi-tool/query'

import { BarValueProps, BarVisualizationOptions } from '../types'

export const mapQueryForBar = (
  raw: QueryRunResponse,
  options: BarVisualizationOptions
): BarValueProps[] =>
  (raw.rows || []).map((row) => ({
    title: row[options?.label as string],
    count: row[options?.value as string],
  }))
