import React, { useState } from 'react'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import Page, { PageHeader, Container } from '@zera-admin/page'

import { useFlagcardContext } from 'app/contexts/flagcard'
import { usePopupContext } from 'app/contexts/popup'
import MainLayout from 'layouts/main'
import claimService, {
  Claim,
  ClaimType,
} from 'services/http/identity-server/claim'

import { CreateClaimProps } from './types'
import ClaimForm from './components/ClaimForm'

const CreateClaim: React.FunctionComponent<CreateClaimProps> = ({
  history,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()

  const handleCreate = async (data: Claim) => {
    setIsLoading(true)

    try {
      const response = await claimService.create(data)

      flagcard.add({
        title: 'İzin oluşturuldu',
        children:
          'Geçerli izin başarıyla oluşturuldu. Detay sayfasına yönlendiriliyorsunuz.',
      })

      if (response.data.id) {
        history.push(`/claim/update/${response.data.id}`)
      }
    } catch {
      popup.add({
        title: 'İzin oluşturulamadı',
        children: 'İzin oluşturulurken bir sorun oluştu lütfen tekrar deneyin.',
      })
    }

    setIsLoading(false)
  }

  const breadcrumbs = (
    <Breadcrumb>
      <BreadcrumbItem text="Anasayfa" href="/" />
      <BreadcrumbItem text="İzinler" href="/claims" />
      <BreadcrumbItem text="İzin oluştur" />
    </Breadcrumb>
  )

  return (
    <MainLayout>
      <Container width="narrow">
        <Page>
          <PageHeader
            breadcrumbs={breadcrumbs}
            bottomBar={
              <p>
                Roller üzerine tanımlamak adına bir izin oluşturunuz.
                Ekleyeceğiniz izin bir API izni, bir menü izni veya UI mantıksal
                izni olabilir.
              </p>
            }
          >
            İzin oluştur
          </PageHeader>
          <ClaimForm
            loading={isLoading}
            values={{
              title: '',
              value: '',
              type: ClaimType.Api,
              method: 'get',
              appId: 0,
              roles: [],
            }}
            operation="create"
            onSubmit={handleCreate}
          />
        </Page>
      </Container>
    </MainLayout>
  )
}

export default CreateClaim
