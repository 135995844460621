import styled from 'styled-components'

export const PdfPage = styled.div`
  min-height: 100vh;
  display: flex;
  border: 1px solid grey;
  & > div.half {
    width: 40vw;
    border: 1px solid grey;
  }
`

export const PdfPageSideBar = styled.div`
  background-color: #333;
  width: 20vw;
  border: 1px solid grey;
  color: #ffffff;
  & > div.sidebar {
    background-color: #87cfed;
    height: 100px;
    margin-bottom: -88px;
  }
`
