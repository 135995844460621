import React from 'react'

import Bar from './Bar'
import * as Styled from './Bars.styled'
import { BarsProps } from './types'

const Bars: React.FunctionComponent<BarsProps> = ({ bars = [] }) => {
  const max =
    bars.reduce((prev, current) =>
      prev.count > current.count ? prev : current
    )?.count || 0

  return (
    <Styled.Bars>
      {bars.map((bar, index) => (
        <Bar key={index} {...bar} width={`${(bar.count * 100) / max}`} />
      ))}
    </Styled.Bars>
  )
}

export default Bars
