import styled from 'styled-components'

export const TelegramCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  height: fit-content !important;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .baloon-tail {
    margin-right: -2px;
  }

  .blue {
    border-radius: 0 0 8px 8px;
    background: #24a1de;
  }

  .post {
    display: flex;
    flex-direction: column;

    height: 100%;

    &-message-baloon {
      display: flex;
    }

    &-body {
      padding: 16px 16px 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 8px;
      &-profile-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 36px;
          height: 36px;

          border-radius: 100%;
          border: 1px solid #d7e3ec;
        }
      }
      &-content {
        border: 1px solid #d7e3ec;
        border-radius: 0px 8px 8px 8px;

        &-username {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px 8px;

          span {
            font-weight: 600;
            font-size: 16px;
            color: #1c93e3;
          }

          &-logo {
            img {
              width: 36px;
            }
          }
        }

        &-image {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-message {
          padding: 8px 12px;
          font-size: 14px;
          color: #333;
          font-weight: 500;
          line-height: 1.5;
          button {
            background: none;
            border: none;
            color: #24a1de;
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
          }
        }

        &-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px 8px;

          &-reactions {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            div {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              background-color: #f0f0f0;
              padding: 2px 4px;
              border-radius: 8px;

              color: #333;

              .reaction {
                font-size: 16px;
              }
              .count {
                font-size: 14px;
                font-weight: 700;
              }
            }
          }

          &-date {
            display: flex;
            gap: 8px;
            span {
              font-size: 14px;
              font-weight: 400;
              color: #333;
            }
          }
        }
      }
    }

    &-footer {
      position: relative;
      border-top: 1px solid #f0f0f0;
      padding: 0 16px;

      &-url {
        text-decoration: none;
        color: #24a1de;
        font-weight: 600;
        padding: 4px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          color: #333;
        }
        a:hover {
          color: #24a1de;
          text-decoration: underline;
        }
      }

      &-stats {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        padding: 4px;
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }

      &-logo {
        position: absolute;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        &-warpper {
          padding: 4px;
          border-radius: 100%;
          background: #24a1de;
          border: 2px solid #fff;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 40px;
          }
        }
      }
    }
  }
`
