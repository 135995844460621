import { Props } from 'react-apexcharts'
import { colors } from '@atlaskit/theme'

import { QueryRunResponse } from 'services/http/bi-tool/query'
import { WidgetVisualization } from 'services/http/bi-tool/widget'

import {
  BarChartVisualizationNumberFormat,
  BarChartVisualizationOptions,
} from '../types'
import { numberFormat } from './helpers'

export const mapBarChartProps = (
  values: QueryRunResponse,
  visualization: WidgetVisualization<BarChartVisualizationOptions>
): Props => {
  let result: Props = {}
  const { options } = visualization
  const label = options.label
  const series = options.series

  if (values.rows.length) {
    result = {
      options: {
        chart: {
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          categories: values.rows
            .filter((row) => row[label?.field])
            .map((row) => row[label?.field] as string),
        },
        dataLabels: {
          enabled: true,
          formatter: (data: number) => {
            return numberFormat(
              data,
              options?.format || ({} as BarChartVisualizationNumberFormat)
            )
          },
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: [visualization.theme === 'dark' ? colors.N30 : colors.N600],
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: (data: number) => {
              return numberFormat(
                data,
                options?.format || ({} as BarChartVisualizationNumberFormat)
              )
            },
          },
        },
        colors: Object.entries(colors)
          .filter(
            (entries) => entries[0].includes('300') && !entries[0].includes('Y')
          )
          .map((entries) => entries[1])
          .slice(0, values.rows.length),
        theme: {
          mode: visualization.theme,
        },
      },
      series: (series || []).map((serie) => ({
        name: serie?.alias,
        data: values.rows
          .filter((row) => (row[serie?.field] as number) > 0)
          .map((row) => row[serie?.field]),
      })),
    }
  }

  return result
}
