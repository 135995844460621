import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

export function pdf(selector: string, title?: string) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<string>(async (resolve) => {
    const elements = document.querySelectorAll<HTMLElement>(selector)

    if (elements.length) {
      document.body.classList.add('is-snapshot')

      const pdf = new jsPDF({
        orientation: 'landscape',
      })
      const images = await canvas(elements)

      images.forEach((image) => {
        const options = pdf.getImageProperties(image)
        const width = pdf.internal.pageSize.getWidth()
        const height = (options.height * width) / options.width

        pdf.addImage(image, 'PNG', 0, 0, width, height)
        pdf.addPage('p', 'landscape')
      })

      pdf.deletePage(images.length + 1)

      const date = new Date()
        .toLocaleDateString('tr-TR', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        })
        .replaceAll(':', '_')
      const fileName = `${title}-${date}`.replaceAll(' ', '_')

      pdf.save(`${fileName}.pdf`)
      document.body.classList.remove('is-snapshot')

      resolve(fileName)
    }
  })
}

async function canvas(elements: NodeListOf<HTMLElement>) {
  const canvases: string[] = []

  await asyncForEach(elements, async (element) => {
    await html2canvas(element, {
      scale: 1.5,
      useCORS: true,
    }).then((canvas) => {
      canvases.push(canvas.toDataURL('image/jpeg'))
    })
  })

  return canvases
}

async function asyncForEach(
  array: NodeListOf<HTMLElement>,
  callback: (data: HTMLElement, index: number) => void
) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index)
  }
}
