import React, { useState } from 'react'
import { Column, Row } from '@zera-admin/page'
import Modal from '@zera-admin/modal'

import { useSchemaService } from 'app/library/layout-builder'
import ComponentHeader from 'bi-tool/drag-drop/ComponentHeader'
import Droppable from 'components/designer/Droppable'

import { SectionProps } from './types'
import Input from '@zera-admin/input'

const SectionDesigner: React.FunctionComponent<SectionProps> = ({
  children,
  defaultElement,
  entry,
  height,
  id,
  title,
}) => {
  const [modal, setModal] = useState<SectionProps | null>(null)

  const schemaService = useSchemaService()

  const handleChangeProperties = (value: object) => {
    schemaService.changeProperties(id as string, value)
  }

  const renderComponentSettings = () => {
    if (modal) {
      const actions = [
        {
          text: 'Ayarları kaydet',
          onClick: () => {
            setModal(null)
            handleChangeProperties(modal)
          },
        },
        {
          text: 'Kapat',
          onClick: () => setModal(null),
        },
      ]

      return (
        <Modal
          title="Section ayarları"
          actions={actions}
          onClose={() => setModal(null)}
        >
          <p>
            Dashboard'a section ekleyerek ve özelliklerini yöneterek
            görüntüleyebilirsiniz.
          </p>
          <br />
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                name="title"
                label="Başlık"
                value={modal.title}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({ ...modal, title: event.target.value })
                }
              />
            </Column>
            <Column>
              <Input
                name="height"
                label="Yükseklik"
                value={modal.height}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setModal({ ...modal, height: parseInt(event.target.value) })
                }
                type="number"
              />
            </Column>
          </Row>
          <br />
        </Modal>
      )
    }
  }

  const renderInformation = () => {
    if ((children && children.length > 0) || !defaultElement) {
      return null
    }
  }

  return (
    <React.Fragment>
      {renderInformation()}
      {renderComponentSettings()}
      <ComponentHeader
        componentEntry={entry}
        key={id}
        componentSchemaId={id as string}
        componentIsDefault={defaultElement}
        onEdit={() => setModal({ title, height })}
      />
      <Droppable
        elementId={id as string}
        elementType="column"
        style={{ alignContent: 'baseline', paddingBottom: '50px' }}
        className="fa-droppable-act row"
      >
        {children}
      </Droppable>
    </React.Fragment>
  )
}

export default SectionDesigner
