import React, { useState } from 'react'
import Modal from '@zera-admin/modal'
import Button from '@zera-admin/button'
import Icon from '@zera-admin/icon'
import Input from '@zera-admin/input'
import { ModalContent, MailList, MailCreat, Mail } from './MailModal.styled'
import { MailModalProps } from './types'
import { ActivePerson } from 'services/http/backoffice/active-account'
import Lozenge from '@zera-admin/lozenge'

const MailModal: React.FunctionComponent<MailModalProps> = ({
  onClose,
  data,
  onMailSend,
}) => {
  const [mail, setMail] = useState<string>()
  const [mailList, setMailList] = useState<string[]>([])

  const getEntityKey = (data: ActivePerson[]) => {
    return data.map((person) => person.entityKey)
  }
  const sendMail = (mailList: string[], data: ActivePerson[]) => {
    const entityKeys = getEntityKey(data)
    onMailSend(entityKeys, mailList)
  }
  const [message, setMessage] = useState<boolean>(false)
  const mailValidate = (mailValue: string) => {
    /* eslint-disable no-useless-escape */
    const mailformat =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (mailformat.test(mailValue)) {
      return true
    } else {
      return false
    }
  }
  const msg = (
    <div className="msg-warning">
      <Lozenge
        appearance="warning"
        children="Lütfen geçerli bir mail adresi giriniz!"
        maxWidth="min-content"
      />
    </div>
  )
  const mails = mailList.map((mail, index) => (
    <Mail>
      <span>{index + 1}</span>
      <span>{mail}</span>
      <Button
        appearance="subtle"
        onClick={() => {
          setMailList(mailList.filter((value, i) => index !== i))
        }}
        iconBefore={<Icon name={'trash'} size="small" />}
      />
    </Mail>
  ))
  const actionsMails = [
    {
      text: 'Mail Gönder',
      onClick: () => {
        onClose()
        sendMail(mailList, data)
      },
    },
    {
      text: 'Kapat',
      onClick: () => {
        onClose()
      },
    },
  ]

  const actions = [
    {
      text: 'Kapat',
      onClick: () => {
        onClose()
      },
    },
  ]

  return (
    <Modal
      width={600}
      height={600}
      actions={mailList.length === 0 ? actions : actionsMails}
    >
      <ModalContent>
        <MailCreat>
          <Input
            placeholder="Mail Giriniz"
            value={mail}
            onChange={(event) => setMail(event.currentTarget.value)}
            name="mail"
          />
          {mail && (
            <Button
              appearance="button"
              onClick={() => {
                if (mailValidate(mail)) {
                  setMailList([...mailList, mail])
                  setMail('')
                } else {
                  setMessage(true)
                  setTimeout(() => {
                    setMessage(false)
                  }, 2000)
                }
              }}
            >
              Ekle
            </Button>
          )}
        </MailCreat>
        {message && msg}
        <MailList>{mails ? mails : 'maail yok'}</MailList>
      </ModalContent>
    </Modal>
  )
}
export default MailModal
