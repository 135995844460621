import React from 'react'
import { useHistory } from 'react-router-dom'
import { Column } from '@zera-admin/page'
import Form, { FormSection } from '@zera-admin/form'

import SelectIcon from 'components/select-icon/SelectIcon'
import X from '../../../assets/images/xwhite.png'
import { DefinitionFormProps } from '../types'

const DefinitionQueryForm: React.FunctionComponent<DefinitionFormProps> =
  () => {
    const socialList: any = [
      {
        label: 'X',
        value: 'x',
        img: X,
      },
    ]

    const history = useHistory()

    return (
      <Form>
        <FormSection
          title="Tanımlama Veri Sayısı Sorgulama"
          description="Tanımlama Veri Sayısı Sorgulama oluşturmak için bir sosyal medya kaynağı seçmeniz gerekmektedir."
        >
          <Column xs={{ size: 8 }}>
            <SelectIcon
              list={socialList}
              title="Sorgulama yapacağınız kaynağı seçiniz"
              placeholder="Seçim yapınız"
              onChange={(props: any) => history.push(`query/${props}`)}
            />
          </Column>
        </FormSection>
      </Form>
    )
  }

export default DefinitionQueryForm
