import React, { useImperativeHandle, useRef, useState } from 'react'
import Alert from '@zera-admin/alert'
import Spinner from '@zera-admin/spinner'
import { Column, Container, Row } from '@zera-admin/page'
import ZeraNewsCard from '@zera-admin/alice-news-card'

import queryService, { QueryRunResponse } from 'services/http/bi-tool/query'
import { Widget, WidgetHandlerRef } from 'services/http/bi-tool/widget'

import { getCriteriaInterval } from '../visualization/getters'
import * as Styled from './News.styled'
import { mapQueryForNews } from './utils/mappers'
import { NewsProps, NewsValueProps, NewsVisualizationOptions } from './types'

const News: React.ForwardRefRenderFunction<
  WidgetHandlerRef<NewsVisualizationOptions>,
  NewsProps
> = (props, ref) => {
  const [isLoading, setIsLoading] = useState(true)
  const [values, setValues] = useState<NewsValueProps[]>([])
  const [error, setError] = useState<string>()

  const tweetRefs = useRef<HTMLDivElement[]>([])

  useImperativeHandle(ref, () => ({
    runQuery,
  }))

  const getColumn = () => {
    const { grid } = props.widget

    if (grid) {
      if (grid === 2) return 6
      else if (grid === 3) return 4
      else if (grid === 4) return 3
      else if (grid === 6) return 2
    }

    return 12
  }

  const runQuery = (widget: Widget<NewsVisualizationOptions>) => {
    setIsLoading(true)

    queryService
      .run(widget?.query, widget?.visualization, widget.type, {
        criteriaType: props.widget.criteria,
        dateType: getCriteriaInterval(props.widget),
      })
      .then((res) => {
        setValues(
          mapQueryForNews(
            res.data as QueryRunResponse,
            widget.visualization?.options
          )
        )
        setIsLoading(false)

        if (error) {
          setError('')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        setValues([])
        setError(
          err?.data?.errorMessage ||
            'Sorgunuzda bir hata bulunmaktadır. Lütfen sorgunuzu kontrol ediniz.'
        )
      })
  }

  const renderNewsItem = (props: NewsValueProps, index: number) => {
    return (
      <Styled.News
        key={index}
        ref={(element) =>
          (tweetRefs.current[index] = element as HTMLDivElement)
        }
      >
        <ZeraNewsCard {...props} />
      </Styled.News>
    )
  }

  const renderContent = () => {
    const { grid } = props.widget

    if (grid) {
      const column = Math.round(values.length / grid)
      const group: { [key: string]: NewsValueProps[] } = {}

      for (var i = 0; i < values.length; i += column) {
        group[i] = values.slice(i, i + column)
      }

      return (
        <Row>
          {Object.keys(group).map((key) => {
            const value = group[key]

            return (
              <Column xs={{ size: getColumn() }}>
                {value.map((row, index) => renderNewsItem(row, index))}
              </Column>
            )
          })}
        </Row>
      )
    } else {
      return (
        <Container width="narrow">
          <Styled.NewsList>
            {values.map((row, index) => renderNewsItem(row, index))}
          </Styled.NewsList>
        </Container>
      )
    }
  }

  const renderNews = () => {
    if (isLoading) {
      return (
        <Row xs={{ align: 'center', justify: 'center' }}>
          <Spinner className="spinner" size="medium" />
        </Row>
      )
    } else {
      if (values.length) {
        return renderContent()
      } else {
        return (
          <Alert
            appearance={error ? 'error' : 'warning'}
            title="Haberler oluşturulamadı"
          >
            {error ||
              'Haberler listesini oluşturacak veri bulunamadı. Lütfen sorgularınızı ve haberler listesi ayarlarınızı kontrol ediniz.'}
          </Alert>
        )
      }
    }
  }

  return renderNews()
}

export default React.forwardRef(News)
