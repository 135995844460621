import { useEffect, useState } from 'react'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'

import { dependent } from 'app/shared/constants'
import { validation } from 'app/functions'
import useSources from 'app/hooks/sources'
import { NamedEntityRecognition } from 'services/http/dataset/named-entity-recognition'
import partOfSpeechsService from 'services/http/dataset/part-of-speech'
import entityTypesService from 'services/http/dataset/entity-type'

import { NamedEntityRecognitionFormProps } from './types'
import Button from '@zera-admin/button'
import React from 'react'
import Table from '@zera-admin/table'
import useTrainingGroup from 'app/hooks/training-group'

const validations = {}

const NamedEntityRecognitionForm: React.FunctionComponent<
  NamedEntityRecognitionFormProps
> = ({ actions = [], onError, onSubmit, values }) => {
  const [isLoadingEnity, setIsLoadingEntity] = useState(true)
  const [isLoadingPart, setIsLoadingPart] = useState(true)
  const [entityType, setEntityType] = useState<SearchboxOptionProps[]>([])
  const [partOfSpeech, setPartOfSpeech] = useState<SearchboxOptionProps[]>([])

  const form = useForm<NamedEntityRecognition>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const [subform, setSubform] = useState<any>({})
  const handleSubformChange = (field: string, value: any) => {
    setSubform({ ...subform, [field]: value })
  }

  const { sources, getSources, getSource, isLoading } = useSources()

  const {
    trainingGroups,
    getTraningGroup,
    isLoading: isLoadingTraining,
  } = useTrainingGroup()

  useEffect(() => {
    getEntityType()
    getPartOfSpeech()
  }, [])

  const getEntityType = async (value?: string) => {
    setIsLoadingEntity(true)

    const response = await entityTypesService.get(value)
    const mapped = response.data?.list.map((entityType) => ({
      label: entityType.name,
      value: entityType.id as number,
    }))

    setEntityType(mapped)
    setIsLoadingEntity(false)

    return mapped
  }

  const getPartOfSpeech = async (value?: string) => {
    setIsLoadingPart(true)

    const response = await partOfSpeechsService.get(value)
    const mapped = response.data?.list.map((partOfSpeech) => ({
      label: partOfSpeech.name,
      value: partOfSpeech.id as number,
    }))

    setPartOfSpeech(mapped)
    setIsLoadingPart(false)

    return mapped
  }

  const handleSubmit = (props: FormHookSubmitProps<NamedEntityRecognition>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  const handleSubformSubmit = () => {
    form.handleFieldChange('wordList', [
      ...(form.values.wordList || []),
      subform,
    ])
    setSubform({})
  }

  const wordListRemove = (index: number) => {
    const newValue = form.values.wordList?.slice() || []
    newValue.splice(index, 1)
    form.handleFieldChange('wordList', newValue)
  }

  const renderPageTable = () => {
    const columns = [
      {
        name: 'text',
        text: 'Kelime',
      },
      {
        name: 'order',
        text: 'Sıra',
      },
      {
        name: 'entityTypeName',
        text: 'Varlık türü',
        render: (item: any) => {
          return entityType.find(
            (childrenItem: any) => childrenItem.value === item.entityTypeId
          )?.label
        },
      },
      {
        name: 'partOfSpeechId',
        text: 'Kelime parçası',
        render: (item: any) => {
          return partOfSpeech.find(
            (childrenItem: any) => childrenItem.value === item.partOfSpeechId
          )?.label
        },
      },
      {
        name: 'dependentStr',
        text: 'Bağımlılık',
        render: (item: any) =>
          item.dependent === 1
            ? 'Sol'
            : item.dependent === 2
            ? 'Sağ'
            : 'Seçilmedi',
      }, //kaydet
      {
        name: 'operation',
        text: 'İşlemler ',
        width: '100px',
        render: (item: any, index: number) => (
          <Button appearance="error" onClick={() => wordListRemove(index)}>
            Sil
          </Button>
        ),
      },
    ]

    if (form.values.wordList) {
      return (
        <React.Fragment>
          <Table
            columns={columns as any}
            rows={form.values.wordList}
            isLoading={isLoading}
            helperMessage="Aradığınız kriterlere uygun bir veri bulunamadı"
          />
        </React.Fragment>
      )
    }

    return null
  }
  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Geçerli bir isimlendirilmiş varlık tanıma metni giriniz"
              errorMessage={form.errors.text}
              label="İsimlendirme varlık tanıma metni"
              name="text"
              placeholder="Örnek metin"
              value={form.values.text}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Input
              description="Geçerli bir dil kodu giriniz"
              errorMessage={form.errors.languageCode}
              label="Dil kodu"
              name="languageCode"
              placeholder="Örnek: tr, en, vs.."
              value={form.values.languageCode}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <SearchBox
              description="Geçerli bir kaynak seçimi yapınız"
              errorMessage={form.errors.sourceId}
              label="Kaynak"
              name="sourceId"
              options={sources}
              value={getSource(form.values.sourceId)}
              onFetchOptions={(value) => getSources(value)}
              onChange={(props) =>
                form.handleFieldChange(
                  'sourceId',
                  (props as SearchboxOptionProps).value
                )
              }
              placeholder="Seçim yapın"
              isLoading={isLoading}
            />
          </Column>
          <Column xs={{ size: 12 }}>
            <Select
              description="Geçerli bir eğitim grubu seçin"
              label="Eğitim grubu seçiniz"
              name="trainingGroupId"
              value={getTraningGroup(
                form.values.trainingGroupId,
                form.values.trainingGroupName
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'trainingGroupId',
                  (props as SelectOptionProps).value
                )
              }
              options={trainingGroups}
              isLoading={isLoadingTraining}
            />
          </Column>
          <Column xs={{ size: 12 }}>
            <Row>
              <Column xs={{ size: 3 }}>
                <Input
                  label="Kelime"
                  name="text"
                  value={subform.text}
                  placeholder="Örnek"
                  onChange={(props) => {
                    handleSubformChange('text', props.currentTarget.value)
                  }}
                />
              </Column>
              <Column xs={{ size: 3 }}>
                <SearchBox
                  label="Varlık türü"
                  name="entityTypeId"
                  options={entityType}
                  onFetchOptions={(value) => getEntityType(value)}
                  value={entityType.find(
                    (item: any) => item.value === subform.entityTypeId
                  )}
                  onChange={(props) =>
                    handleSubformChange(
                      'entityTypeId',
                      (props as SearchboxOptionProps).value
                    )
                  }
                  placeholder="Seçim yapın"
                  isLoading={isLoadingEnity}
                />
              </Column>
              <Column xs={{ size: 2 }}>
                <SearchBox
                  label="Kelime parçası"
                  name="partOfSpeechId"
                  options={partOfSpeech}
                  value={partOfSpeech.find(
                    (item: any) => item.value === subform.partOfSpeechId
                  )}
                  onFetchOptions={(value) => getPartOfSpeech(value)}
                  onChange={(props) =>
                    handleSubformChange(
                      'partOfSpeechId',
                      (props as SearchboxOptionProps).value
                    )
                  }
                  placeholder="Seçim yapın"
                  isLoading={isLoadingPart}
                />
              </Column>
              <Column xs={{ size: 2 }}>
                <Select
                  label="Bağımlılık durumu"
                  name="dependent"
                  options={dependent}
                  value={dependent.find(
                    (item: any) => item.value === subform.dependent
                  )}
                  onChange={(props) => {
                    return handleSubformChange(
                      'dependent',
                      (props as SelectOptionProps).value
                    )
                  }}
                  placeholder="Seçim yapın"
                />
              </Column>
              <Column xs={{ size: 1 }}>
                <Input
                  label="Order"
                  name="order"
                  placeholder="Örnek"
                  value={subform.order}
                  onChange={(props) =>
                    handleSubformChange('order', props.currentTarget.value)
                  }
                />
              </Column>
              <Column xs={{ size: 1, align: 'end' }}>
                <Button
                  type="button"
                  appearance="primary"
                  children="Ekle"
                  onClick={() => handleSubformSubmit()}
                />
              </Column>
            </Row>
          </Column>
        </Row>
        {renderPageTable()}
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default NamedEntityRecognitionForm
