import * as Styled from './NewCard.style'
import defaultNewImage from './images/news-default-big.png'
import defaultUserProfil from './images/default-user.png'
import { OpenLink } from './OpenLink'

import { NewsCardProps } from './types'

const NewCard: React.FC<NewsCardProps> = ({ data, editMode, onChange }) => {
  const handleBlurElement = (
    e: React.ChangeEvent<HTMLDivElement | HTMLSpanElement>,
    key: string
  ) => {
    const newData = e.target.innerText as string
    onChange(key, newData)
  }

  function hasErrorImage(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultNewImage)
  }

  function hasErrorImageProfil(e: React.SyntheticEvent) {
    e.currentTarget.setAttribute('src', defaultUserProfil)
  }

  return (
    <Styled.AgendaNewCard>
      <div className="agenda-new-header">
        <div className="agenda-new-header-profile-image">
          {data?.NewsSourcePictureUrl ? (
            <img
              src={data?.NewsSourcePictureUrl}
              onError={hasErrorImageProfil}
              alt="New Logo"
            />
          ) : (
            <img src={defaultUserProfil} alt="New Logo" />
          )}
        </div>
        <span className="agenda-new-header-profile-new-name">
          {data?.NewsSourceName}
        </span>
        <div className="agenda-new-header-profile-link">
          <a
            href={data?.NewsUrl ? data?.NewsUrl : '#'}
            target="_blank"
            rel="noreferrer"
          >
            Haber Linki <OpenLink />
          </a>
        </div>
      </div>
      <div className="agenda-new-body">
        <div className="agenda-new-body-image">
          {data?.NewsImageUrl && (
            <img
              src={data?.NewsImageUrl}
              onError={hasErrorImage}
              alt="New logo"
            />
          )}
        </div>
        <span className="agenda-new-body-post-info">
          {new Date(data?.PublishedDate as string).toLocaleString('tr-TR', {
            dateStyle: 'long',
            timeStyle: 'short',
          })}
        </span>
        <div className="agenda-new-body-post-detail">
          <h3
            contentEditable={editMode}
            onBlur={(e) => handleBlurElement(e, 'Title')}
            className="agenda-new-body-post-detail-title"
          >
            {data?.Title}
          </h3>
          <p
            contentEditable={editMode}
            onBlur={(e) => handleBlurElement(e, 'Content')}
            className="agenda-new-body-post-detail-text"
          >
            {data?.Content}
          </p>
        </div>
      </div>
    </Styled.AgendaNewCard>
  )
}

export default NewCard
