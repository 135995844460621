import React from 'react'
import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import Select, { SelectOptionProps } from '@zera-admin/select'

import { blackList, dataSource } from 'app/shared/constants'
import { validation } from 'app/functions'
import { BlackList } from 'services/http/backoffice/black-list'

import { BlackListFormProps } from './types'

const validations = {
  type: yup.string().required('Tip seçmelisiniz'),
  value: yup.string().required('Değer girmelisiniz'),
}

const BlackListForm: React.FunctionComponent<BlackListFormProps> = ({
  actions = [],
  onError,
  onSubmit,
  values,
}) => {
  const form = useForm<BlackList>({
    initialValues: values,
    onSubmit: (props) => handleSubmit(props),
    onValidate: async (values, prop) =>
      await validation(validations, values, prop),
  })

  const handleSubmit = (props: FormHookSubmitProps<BlackList>) => {
    if (!props.errors) {
      if (onSubmit) {
        onSubmit(props.values, () => {
          if (props.handleReset) {
            props.handleReset()
          }
        })
      }
    } else {
      if (onError) {
        onError(props.errors)
      }
    }
  }

  return (
    <Form onSubmit={form.handleSubmit}>
      <FormSection
        title="Genel bilgiler"
        description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
      >
        <Row xs={{ direction: 'column' }}>
          <Column>
            <Input
              description="Geçerli bir değer metni giriniz"
              errorMessage={form.errors.value}
              label="Değer"
              name="value"
              placeholder="Örnek metin"
              value={form.values.value}
              onChange={form.handleChange}
            />
          </Column>
          <Column>
            <Select
              description="Geçerli bir Tip seçimi yapınız"
              errorMessage={form.errors.type}
              label="Tip"
              name="type"
              options={blackList}
              value={blackList.find(
                (blackList) => blackList.value === form.values.type
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'type',
                  (props as SelectOptionProps).value
                )
              }
              placeholder="Seçim yapın"
            />
          </Column>
          <Column>
            <Select
              description="Geçerli bir veri kaynağı seçimi yapınız"
              errorMessage={form.errors.dataSource}
              label="Veri kaynağı"
              name="dataSource"
              options={dataSource.map((option) => {
                if (option.label.toLowerCase() === 'twitter') {
                  return { ...option, label: 'X' }
                }
                return option
              })}
              value={dataSource.find(
                (blackList) => blackList.value === form.values.dataSource
              )}
              onChange={(props) =>
                form.handleFieldChange(
                  'dataSource',
                  (props as SelectOptionProps).value
                )
              }
              placeholder="Seçim yapın"
            />
          </Column>
        </Row>
      </FormSection>
      <FormFooter>{actions}</FormFooter>
    </Form>
  )
}

export default BlackListForm
