import { QueryRunResponse } from 'services/http/bi-tool/query'

import { TweetValueProps, TweetVisualizationOptions } from '../types'

export const mapQueryForTweet = (
  raw: QueryRunResponse,
  options: TweetVisualizationOptions
): TweetValueProps[] =>
  (raw.rows || []).map((row) => ({
    tweet: {
      classifications: Array.isArray(
        row[options?.fields?.classifications as string]
      )
        ? row[options?.fields?.classification as string]?.map((item: any) => ({
            apperance: item?.score > 0.5 ? 'success' : 'default',
            category: item?.name,
            score: item?.score,
          }))
        : [],
      aspects: Array.isArray(row[options?.fields?.aspects as string])
        ? row[options?.fields?.aspects as string]?.map((item: any) => ({
            score: item.score,
            sentiment: item.sentiment,
            subject: item.entity,
          }))
        : [],
      comment: row[options?.fields?.commentCount as string],
      entityKey: row[options?.fields?.entityKey as string],
      favorite: row[options?.fields?.favoriteCount as string],
      profilImage: row[options?.fields?.userProfileImage as string],
      retweet: row[options?.fields?.retweetCount as string],
      tweet: row[options?.fields?.tweetText as string],
      userCountryDetection: row[options?.fields?.countryDetection as string],
      username: row[options?.fields?.userVisibleText as string],
      date: row[options?.fields?.createdAt as string],
      absKeyword: row[options?.fields?.absKeyword as string],
      absSentiment: row[options?.fields?.absSentiment as string],
      sentiment: row[options?.fields?.sentiment as string],
      gallery: row[options?.fields?.gallery as string],
      negativeStatusCount: row[options?.fields?.negativeStatusCount as any],
      positiveStatusCount: row[options?.fields?.positiveStatusCount as any],
      neutralStatusCount: row[options?.fields?.neutralStatusCount as any],
    },
    account: {
      countryDetection: row[options?.fields?.countryDetection as string],
      createdAt: row[options?.fields?.userCreatedAt as string],
      followUp: row[options?.fields?.userFriends as string],
      follower: row[options?.fields?.userFollowers as string],
      name: row[options?.fields?.userName as string],
      profilImage: row[options?.fields?.userProfileImage as string],
      protected: row[options?.fields?.userProtected as string],
      tweetCount: row[options?.fields?.userTweetCount as string],
      username: row[options?.fields?.userVisibleText as string],
      verified: row[options?.fields?.userVerified as any],
      negativeStatusCount: row[options?.fields?.negativeStatusCount as any],
      positiveStatusCount: row[options?.fields?.positiveStatusCount as any],
      neutralStatusCount: row[options?.fields?.neutralStatusCount as any],
    },
  }))

export const mapTweetDifferences = (
  previous: TweetValueProps[],
  next: TweetValueProps[]
) => {
  const differences: TweetValueProps[] = next

  previous.forEach((prev) => {
    const index = differences.findIndex(
      (difference) => difference?.tweet?.entityKey === prev.tweet?.entityKey
    )

    if (index !== -1) {
      differences.splice(index, 1)
    }
  })

  return differences
}
