import React, { useEffect, useState } from 'react'
import MainLayout from 'layouts/main'
import { CreateInstagramResponse } from 'services/http/backoffice/definition/types'
import { getCountryPerceptions } from '../../../services/http/backoffice/country-perception/endpoints'
import { useFlagcardContext } from 'app/contexts/flagcard'

import backofficeDefinitionService from 'services/http/backoffice/definition/'
import { IFormValues } from './types'

import Page from '@zera-admin/page'
import { PageHeader, Row, Column } from '@zera-admin/page'
import Breadcrumb, { BreadcrumbItem } from '@zera-admin/breadcrumb'
import { RadioGroup } from '@zera-admin/radio'
import Button from '@zera-admin/button'
import Input from '@zera-admin/input'
import DateTimePicker from '@zera-admin/datetime-picker'
import Checkbox from '@zera-admin/checkbox'
import Select, { SelectOptionProps } from '@zera-admin/select'
import Icon from '@zera-admin/icon'
import Form, {
  FormHeader,
  FormFooter,
  FormHookSubmitProps,
  useForm,
  FormSection,
} from '@zera-admin/form'
import { boolean } from 'yup'

function Instagram() {
  const [formValues, setFormValues] = useState<IFormValues>({
    countryPerceptions: [],
  })
  const [flagcard] = useFlagcardContext()
  const [blackListWord, setBlackListWord] = useState<string>('')

  useEffect(() => {
    getCountryPerceptions()
      .then((res) => res.data.data)
      .then((res) =>
        setFormValues((state) => ({
          ...state,
          countryPerceptions: res.map((country: any) => {
            return { label: country?.description, value: country?.key }
          }),
        }))
      )
  }, [])

  const formOptions = {
    crawlingType: [
      {
        label: 'Fetch',
        value: 'fetch',
      },
      {
        label: 'Alarm',
        value: 'alarm',
      },
    ],
    definitionType: {
      fetch: [
        {
          label: 'Keyword',
          value: 'keyword',
        },
        {
          label: 'Comment',
          value: 'comment',
        },
        {
          label: 'Account',
          value: 'account',
        },
        {
          label: 'Search',
          value: 'search',
        },
      ],
      alarm: [
        {
          label: 'Account',
          value: 'account',
        },
      ],
    },

    allowedDbs: [{ value: 'oracle', label: 'Oracle' }],
    environment: [{ label: 'Alice', value: 'Alice' }],
    definitionBlackLists: [{ label: 'BlackListWord', value: 'BlackListWord' }],
  }

  const initialValues: CreateInstagramResponse = {
    crawlingType: undefined,
    definitionType: undefined,
    definitionValue: undefined,
    countryPerception: 1,
    useIncomingDefinition: false,
    environment: 'Alice',
    definitionBlackLists: undefined,
    periodicFetch: false,
    period: undefined,
    instagramFetchKeywordOption: {
      limit: 0,
      fetchAccountDetail: false,
      since: undefined,
    },
    instagramAlarmOption: {
      fetchMedia: false,
      fetchStory: false,
      fetchBroadcast: false,
    },
    instagramFetchAllMediasByUserNameOption: {
      fetchMedia: false,
      fetchStory: false,
      fetchBroadcast: false,
      limit: 0,
      since: undefined as unknown as string,
    },
    instagramSearchMediasOption: {
      limit: 0,
      pageLimit: 0,
    },
    fetchComments: false,
  }

  const handleAddBlackListWord = () => {
    if (blackListWord) {
      form.handleFieldChange('definitionBlackLists', [
        ...(form.values.definitionBlackLists || []),
        blackListWord,
      ])
      setBlackListWord('')
    }
  }

  const handleRemoveBlackListWord = (index: number) => {
    form.handleFieldChange(
      'definitionBlackLists',
      form.values.definitionBlackLists?.filter((_, i) => i !== index)
    )
  }

  const form = useForm<CreateInstagramResponse>({
    initialValues,
    onSubmit: (props) => handlerSubmit(props),
  })

  const handlerSubmit = (
    props: FormHookSubmitProps<CreateInstagramResponse>
  ) => {
    console.log(form.values)

    if (!props.errors) {
      if (form.values) {
        backofficeDefinitionService
          .createInstagram(form.values)
          .then(() => {
            flagcard.add({
              appearance: 'success',
              title: 'Kayıt işlemi başarılı',
              children:
                'Girmiş olduğunuz bilgiler ile birlikte veri sisteme başarıyla kayıt edilmiştir. Dilerseniz yeni veri ekleyebilirsiniz.',
            })
            form.handleBatchUpdate(initialValues)
          })
          .catch((error) => {
            console.log(error)
            flagcard.add({
              appearance: 'error',
              title: 'Bir hata oluştu',
              children:
                'Veri eklenirken bir hata oluştu. Lütfen tekrar deneyin. Sorununuz düzelmediyse servis sağlayıcısı ile iletişime geçiniz.',
            })
          })
      }
    }
  }
  const renderDefinitionKeyword = () => {
    return (
      <>
        <Row>
          <Column xs={{ size: 12 }} md={{ size: 3 }}>
            <Input
              type="number"
              min={0}
              label="Limit"
              required
              name="instagramFetchKeywordOptionLimit"
              placeholder="Çekilecek veri sayısını girin."
              description="Eğer 0 ise çekilebilen tüm veriler çekilir."
              isRequired
              onChange={(props) =>
                form.handleFieldChange('instagramFetchKeywordOption', {
                  ...form.values.instagramFetchKeywordOption,
                  limit: props.currentTarget.value,
                })
              }
              value={form.values.instagramFetchKeywordOption?.limit}
            />
          </Column>
        </Row>
        <Row>
          <Column xs={{ size: 12 }} md={{ size: 3 }}>
            <DateTimePicker
              label="Tarih Seçin"
              description="*Belirtilen tarihten sonraki veriler çekilir."
              placeholder="Seçim Yapın"
              name="instagramFetchKeywordOptionSince"
              type="date"
              value={form.values.instagramFetchKeywordOption?.since?.toString()}
              onChange={(e) => {
                e
                  ? form.handleFieldChange('instagramFetchKeywordOption', {
                      ...form.values.instagramFetchKeywordOption,
                      since: new Date(e).toISOString(),
                    })
                  : form.handleFieldChange('toDate', undefined)
              }}
            />
          </Column>
          <Column style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type="button"
              appearance="button"
              children="Tarihi Temizle"
              iconBefore={<Icon name="cross-circle" />}
              onClick={() =>
                form.handleFieldChange('instagramFetchKeywordOption', {
                  ...form.values.instagramFetchKeywordOption,
                  since: undefined,
                })
              }
            />
          </Column>
        </Row>

        <Row>
          <Column xs={{ size: 12 }} xl={{ size: 6 }}>
            <Checkbox
              label="Çekilen medya verilerinin sahibi olan kullanıcıların da bilgileri çekilsin mi?"
              name="instagramFetchKeywordOptionFetchAccountDetail"
              onChange={(props) => {
                form.handleFieldChange('instagramFetchKeywordOption', {
                  ...form.values.instagramFetchKeywordOption,
                  fetchAccountDetail: props.currentTarget.checked,
                })
              }}
              isChecked={
                form.values.instagramFetchKeywordOption?.fetchAccountDetail
              }
            />
          </Column>
        </Row>
      </>
    )
  }

  const renderDefinitionAccount = () => {
    return (
      <>
        <FormSection>
          <span style={{ lineHeight: '32px', color: '#A73121' }}>
            *Aşağıdaki ifadelerden en az biri seçilmelidir.
          </span>
          <Row>
            <Column xs={{ size: 12 }} xl={{ size: 6 }}>
              <Checkbox
                label="Kullanıcının medyaları çekilsin mi?"
                name="instagramAlarmOptionFetchMedia"
                onChange={(props) => {
                  form.handleFieldChange('instagramAlarmOption', {
                    ...form.values.instagramAlarmOption,
                    fetchMedia: props.currentTarget.checked,
                  })
                }}
                isChecked={form.values.instagramAlarmOption?.fetchMedia}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} xl={{ size: 6 }}>
              <Checkbox
                label="Kullanıcının hikayeleri çekilsin mi?"
                name="instagramAlarmOptionFetchStory"
                onChange={(props) => {
                  form.handleFieldChange('instagramAlarmOption', {
                    ...form.values.instagramAlarmOption,
                    fetchStory: props.currentTarget.checked,
                  })
                }}
                isChecked={form.values.instagramAlarmOption?.fetchStory}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={{ size: 12 }} xl={{ size: 6 }}>
              <Checkbox
                label="Kullanıcının yayınları çekilsin mi?"
                name="instagramAlarmOptionFetchBroadcast"
                onChange={(props) => {
                  form.handleFieldChange('instagramAlarmOption', {
                    ...form.values.instagramAlarmOption,
                    fetchBroadcast: props.currentTarget.checked,
                  })
                }}
                isChecked={form.values.instagramAlarmOption?.fetchBroadcast}
              />
            </Column>
          </Row>
        </FormSection>
      </>
    )
  }

  const renderFormContent = () => {
    return (
      <React.Fragment>
        <Form onSubmit={handlerSubmit}>
          <FormHeader
            title="Instagram Tanımlama Formu"
            description={
              <div style={{ marginBottom: '1.5rem' }}>
                <p>Form üzerinden aşağıdaki genel bilgileri doldurunuz.</p>
                <p style={{ margin: '0', color: '#A73121' }}>
                  Doldurulması zorunlu alanlar (*) işareti ile belirtilmiştir.
                </p>
              </div>
            }
          />
          <FormSection>
            <Row>
              <Column xs={{ size: 4 }}>
                <RadioGroup
                  label="*Crawling Type"
                  name="crawlingType"
                  isRequired
                  onChange={(props) => {
                    form.handleFieldChange('definitionType', undefined)
                    form.handleFieldChange(
                      'crawlingType',
                      props.currentTarget.value
                    )
                  }}
                  options={formOptions.crawlingType}
                  value={form.values.crawlingType}
                />
              </Column>
            </Row>
            {form.values.crawlingType && (
              <Row>
                <Column xs={{ size: 4 }}>
                  <RadioGroup
                    label="*Definition Type"
                    name="definitionType"
                    isRequired
                    onChange={(props) => {
                      form.handleFieldChange(
                        'definitionType',
                        props.currentTarget.value
                      )
                    }}
                    options={
                      form.values.crawlingType === 'fetch'
                        ? formOptions.definitionType.fetch
                        : form.values.crawlingType === 'alarm'
                        ? formOptions.definitionType.alarm
                        : []
                    }
                    value={form.values.definitionType}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                <Input
                  label="*Definition Value"
                  name="definitionValue"
                  placeholder={
                    form.values.definitionType === 'keyword'
                      ? 'Takip edilecek hashtag belirtin.'
                      : form.values.definitionType === 'comment'
                      ? 'Yorumları çekilecek medyanın shortcode girin.'
                      : form.values.definitionType === 'account'
                      ? 'Takip edilecek username girin.'
                      : 'Lütfen önce Definition Type seçin...'
                  }
                  isRequired
                  onChange={(props) =>
                    form.handleFieldChange(
                      'definitionValue',
                      props.currentTarget.value
                    )
                  }
                  value={form.values.definitionValue}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Algı Seçiniz"
                  name="countryPerception"
                  placeholder="Seçim yapın"
                  options={formValues.countryPerceptions}
                  defaultValue={
                    formValues.countryPerceptions.find(
                      (option) => option.label === 'Türkiye'
                    ) || { label: 'Türkiye', value: 1 }
                  }
                  onChange={(props) => {
                    form.handleFieldChange(
                      'countryPerception',
                      (props as SelectOptionProps).value
                    )
                  }}
                  value={formValues.countryPerceptions.find(
                    (option) => option.value === form.values.countryPerception
                  )}
                />
              </Column>
            </Row>

            <Row>
              <Column xs={{ size: 12 }} md={{ size: 3 }}>
                <Select
                  label="Database Seçimi"
                  name="allowedDbs"
                  placeholder="Seçim yapın"
                  description="*Çekilen verilerin işlendikten sonra hangi db 'lere kaydedileceğini belirtir."
                  isMultiple
                  options={formOptions.allowedDbs}
                  onChange={(props) => {
                    const value = props as Array<SelectOptionProps>
                    form.handleFieldChange(
                      'allowedDbs',
                      value.length > 0 ? value.map((db) => db.value) : undefined
                    )
                  }}
                  value={form.values.allowedDbs?.map((db) => {
                    return { value: db.toLowerCase(), label: db }
                  })}
                />
              </Column>
            </Row>
            <>
              <Row>
                <Column xs={{ size: 4 }}>
                  <Input
                    name="blackListWord"
                    label="Tanımlama Kara Listesi"
                    placeholder="Bir kara liste kelimesi giriniz"
                    description="Bir kara liste kelimesi giriniz"
                    value={blackListWord}
                    onChange={(props) => {
                      setBlackListWord(props.currentTarget.value)
                    }}
                  />
                </Column>
                <Column xs={{ size: 4 }}>
                  <Button
                    onClick={handleAddBlackListWord}
                    appearance="primary"
                    children="Ekle"
                    style={{ marginTop: '16px' }}
                  />
                </Column>
              </Row>
              {form.values.definitionBlackLists?.map((word, index) => (
                <Row key={index}>
                  <Column xs={{ size: 4 }}>{word}</Column>
                  <Column>
                    <Button
                      onClick={() => handleRemoveBlackListWord(index)}
                      appearance="error"
                      children="Kaldır"
                      style={{ marginTop: '16px' }}
                    />
                  </Column>
                </Row>
              ))}
            </>
            {form.values.definitionType === 'account' &&
              form.values.crawlingType === 'fetch' && (
                <>
                  <Row>
                    <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                      <Checkbox
                        label="Medya çekilsin mi?"
                        name="fetchMedia"
                        onChange={(props) => {
                          form.handleFieldChange(
                            'fetchMedia',
                            props.currentTarget.checked
                          )
                        }}
                        isChecked={
                          form.values.instagramFetchAllMediasByUserNameOption
                            ?.fetchMedia
                        }
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                      <Checkbox
                        label="Hikaye çekilsin mi?"
                        name="fetchStory"
                        onChange={(props) => {
                          form.handleFieldChange(
                            'fetchStory',
                            props.currentTarget.checked
                          )
                        }}
                        isChecked={
                          form.values.instagramFetchAllMediasByUserNameOption
                            ?.fetchStory
                        }
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                      <Checkbox
                        label="Yayın çekilsin mi?"
                        name="fetchBroadcast"
                        onChange={(props) => {
                          form.handleFieldChange(
                            'fetchBroadcast',
                            props.currentTarget.checked
                          )
                        }}
                        isChecked={
                          form.values.instagramFetchAllMediasByUserNameOption
                            ?.fetchBroadcast
                        }
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={{ size: 12 }} md={{ size: 3 }}>
                      <Input
                        type="number"
                        min={0}
                        label="Limit"
                        name="limit"
                        placeholder="Çekilecek veri sayısını girin."
                        description="Eğer 0 ise çekilebilen tüm veriler çekilir."
                        onChange={(props) =>
                          form.handleFieldChange(
                            'limit',
                            props.currentTarget.value
                          )
                        }
                        value={
                          form.values.instagramFetchAllMediasByUserNameOption
                            ?.limit
                        }
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={{ size: 12 }} md={{ size: 3 }}>
                      <DateTimePicker
                        label="Tarih Seçin"
                        description="*Belirtilen tarihten sonraki veriler çekilir."
                        placeholder="Seçim Yapın"
                        name="since"
                        type="date"
                        value={form.values.instagramFetchAllMediasByUserNameOption?.since?.toString()}
                        onChange={(e) => {
                          e
                            ? form.handleFieldChange('since', new Date(e))
                            : form.handleFieldChange('since', undefined)
                        }}
                      />
                    </Column>
                    <Column style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        type="button"
                        appearance="button"
                        children="Tarihi Temizle"
                        iconBefore={<Icon name="cross-circle" />}
                        onClick={() =>
                          form.handleFieldChange('since', undefined)
                        }
                      />
                    </Column>
                  </Row>
                </>
              )}
            {form.values.definitionType === 'search' && (
              <>
                <Row>
                  <Column xs={{ size: 12 }} md={{ size: 3 }}>
                    <Input
                      type="number"
                      min={0}
                      label="Limit"
                      name="limit"
                      placeholder="Çekilecek veri sayısını girin."
                      description="Eğer 0 ise çekilebilen tüm veriler çekilir."
                      onChange={(props) =>
                        form.handleFieldChange(
                          'limit',
                          props.currentTarget.value
                        )
                      }
                      value={form.values.instagramSearchMediasOption?.limit}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column xs={{ size: 12 }} md={{ size: 3 }}>
                    <Input
                      type="number"
                      min={0}
                      label="Page Limit"
                      name="pageLimit"
                      placeholder="Çekilecek sayfa sayısını girin."
                      description="Eğer 0 ise çekilebilen tüm sayfalar çekilir."
                      onChange={(props) =>
                        form.handleFieldChange(
                          'pageLimit',
                          props.currentTarget.value
                        )
                      }
                      value={form.values.instagramSearchMediasOption?.pageLimit}
                    />
                  </Column>
                </Row>
              </>
            )}
            {form.values.definitionType === 'account' ||
            form.values.crawlingType === 'alarm' ? (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Checkbox
                    name="fetchComments"
                    label="Kullanıcıların yorumları çekilsin mi?"
                    onChange={(props) => {
                      form.handleFieldChange(
                        'fetchComments',
                        props.currentTarget.checked
                      )
                    }}
                    isChecked={form.values.fetchComments}
                  />
                </Column>
              </Row>
            ) : null}

            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  name="useIncomingDefinition"
                  label="Bu definitiondan gelen postlar direkt olarak bu definition ile
                  işaretlensin mi ?"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'useIncomingDefinition',
                      props.currentTarget.checked
                    )
                  }}
                  defaultChecked={form.values.useIncomingDefinition}
                  isChecked={form.values.useIncomingDefinition}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={{ size: 12 }} xl={{ size: 6 }}>
                <Checkbox
                  label="Periyodik olarak çekilsin mi ?"
                  name="periodicFetch"
                  onChange={(props) => {
                    form.handleFieldChange(
                      'periodicFetch',
                      props.currentTarget.checked
                    )
                  }}
                  isChecked={form.values.periodicFetch}
                />
              </Column>
            </Row>
            {form.values.periodicFetch && (
              <Row>
                <Column xs={{ size: 12 }} md={{ size: 6 }} xl={{ size: 4 }}>
                  <Input
                    name="period"
                    placeholder="Periyot giriniz (0 * * * *)"
                    description="Geçerli bir periyot giriniz"
                    onChange={(props) =>
                      form.handleFieldChange(
                        'period',
                        props.currentTarget.value
                      )
                    }
                    value={form.values.period}
                    isRequired={form.values.periodicFetch}
                  />
                </Column>
              </Row>
            )}
            {form.values.definitionType === 'keyword'
              ? renderDefinitionKeyword()
              : form.values.definitionType === 'account' &&
                form.values.crawlingType === 'alarm' &&
                renderDefinitionAccount()}
          </FormSection>
          <FormFooter align="start">
            <Button
              appearance="primary"
              iconBefore={<Icon name="add-item" />}
              children="Tanımlama Oluştur"
              type="submit"
            />
          </FormFooter>
        </Form>
      </React.Fragment>
    )
  }

  return (
    <MainLayout>
      <Page>
        <PageHeader
          breadcrumbs={
            <Breadcrumb>
              <BreadcrumbItem text="Anasayfa" href="/" />
              <BreadcrumbItem text="Tanımlama" href="/definition" />
              <BreadcrumbItem
                text="Tanımlama oluştur"
                href="/definition/create"
              />
              <BreadcrumbItem text="Instagram" />
            </Breadcrumb>
          }
        />
        {renderFormContent()}
      </Page>
    </MainLayout>
  )
}

export default Instagram
