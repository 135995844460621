import styled from 'styled-components'

export const TwitterTweetCard = styled.div`
  a {
    color: black;
    text-decoration: none;
  }

  .is-media {
    margin-top: 3vh;
    @media (width >= 2160px) {
      margin-top: 0vh !important;
    }
    @media (width >= 1440px) {
      margin-top: 2.6vh !important;
    }
  }
  .not-media {
    margin-top: 2vh;
    @media (width >= 2880px) {
      margin-top: 0vh !important;
    }
    @media (width >= 2160px) {
      margin-top: 0vh !important;
    }
    @media (width >= 1440px) {
      margin-top: 2.6vh !important;
    }
  }

  width: 30%;
  padding: 15px;
  border-right: 1px solid lightgray;
  @media (width >= 2440px) {
    width: 30%;
  }
  border-right: 1px solid #ccc;

  @media (width >= 2440px) {
    width: 30%;
    min-height: 800px;
  }

  .tweet-card-header {
    width: 100%;
    height: 20%;
    display: flex;
    gap: 0.5vw;
  }
  .twitter-card-header-img {
    border-radius: 50%;
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid white;
      padding: 8px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 20px 50px -20px,
        rgba(0, 0, 0, 0.3) 0px 0px 0px 0px,
        rgba(10, 37, 64, 0.35) 0px 2px 2px 0px inset;
      @media (width >= 2440px) {
        width: 150px;
        height: 130px;
      }
    }
  }
  .twitter-card-header-title {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 0.95rem;
      font-weight: 700;
      @media (width >= 2440px) {
        font-size: 2rem;
      }
    }
    .uri {
      font-size: 0.7rem;
      font-weight: 500;
      @media (width >= 2440px) {
        font-size: 1.6rem;
      }
    }
  }
  .twitter-follow-container {
    width: 100%;
    display: flex;
    gap: 0.6rem;
    @media (width >= 2440px) {
      gap: 1.5rem;
    }
    @media (width >= 2160px) {
      margin-top: 2.4vh;
    }
    @media (width >= 1920px) {
      margin-top: 2.4vh;
    }
    @media (width >= 1800px) {
      margin-top: 2.6vh;
    }
    @media (width >= 2880px) {
      margin-top: 0 !important;
    }
    .twitter-follow-count-container {
      font-weight: 800;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.1) 0px 2px 3px;

      display: flex;
      border-radius: 30px;
      background-color: #f5f8fa;
      padding: 0.8rem;
      font-size: 0.8rem;
      color: #1da1f2;

      @media (min-width: 768px) {
        padding: 0.5rem;
      }

      @media (width >= 2440px) {
        padding: 1.1rem;
        margin-top: 3vh;
      }

      i {
        margin-right: 0.5rem;
        color: #1da1f2;
        font-size: 1.2rem;
        @media (width >= 2440px) {
          font-size: 1.6rem;
        }
      }
    }
  }
  .span-weight {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.65rem;
    padding: 1px 10px;

    i {
      font-size: 1rem !important;
      @media (width >= 2440px) {
        font-size: 1.9rem !important;
      }
    }
    @media (width >= 2440px) {
      font-size: 1.5rem;
    }
  }
  .span-second {
    color: #203c73;
    padding: 0px 10px;
    @media (width >= 2440px) {
      font-size: 1.4rem;
    }
    i {
      color: #203c73 !important;
      font-size: 1rem !important;
      @media (width >= 2440px) {
        font-size: 1.9rem !important;
      }
    }
  }
  .twitter-content-text-container {
    width: 100%;
    height: 50px;
    overflow: auto;
    font-size: 0.7rem;
    overflow: auto;
    font-weight: 450;
    margin-top: 1.6rem;
    line-height: 1rem;
    @media (width >= 2440px) {
      font-size: 1.4rem;
      line-height: 2rem;
      height: 90px;
    }
  }
  .twitter-content-thumbnail-container {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    font-size: 0.8rem;
    margin-top: 3vh;

    @media (width >= 2440px) {
      height: 300px;
      margin-top: 4vh;
    }
    .thumbnail-mn {
      font-weight: 800;
    }
    span {
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .twitter-action-content-container {
    color: black;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    margin-top: 0.8vh;
  }
  .twitter-footer-span {
    font-size: 0.8rem;
    display: flex;
    gap: 0.2rem;

    @media (min-width: 768px) {
      font-size: 0.6rem;
    }
    @media (width >= 2440px) {
      font-size: 1.2rem;
    }

    align-items: center;
    .twitter-footer-count {
      font-weight: 700;
      font-size: 0.66rem;
      @media (width >= 2440px) {
        font-size: 1.2rem;
      }
    }
    .twitter-footer-title {
      font-weight: 600;
    }
  }
  .twitter-action-date {
    margin-top: 1rem;
    font-size: 0.7rem;
    opacity: 0.6;
    @media (width >= 2440px) {
      font-size: 1.2rem !important;
    }
  }
  .twitter-footer-spans {
    display: flex;
    gap: 0.5rem;
    padding-bottom: 2.5rem;
  }
`
