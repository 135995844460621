import React, { useState, useCallback, useEffect } from 'react'
import { PageHeader } from '@zera-admin/page'
import { uid } from '@zera-admin/utils'

import { usePopupContext } from 'app/contexts/popup'
import { useFlagcardContext } from 'app/contexts/flagcard'
import AccountLayout from 'layouts/account'
import userService, { User } from 'services/http/identity-server/user'
import UserForm from 'pages/identity-server/user/components/UserForm'

import { ProfileProps } from './types'
import { jvt } from 'app/functions'

const Profile: React.FunctionComponent<ProfileProps> = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [values, setValues] = useState<User>({
    name: '',
    surname: '',
    phoneNumber: '',
    password: '',
    email: '',
    userName: '',
    roles: [],
    apps: [],
  })
  const [flagcard] = useFlagcardContext()
  const [popup] = usePopupContext()
  const token = jvt()

  const handleUpdate = async (data: User) => {
    if (values) {
      setIsLoading(true)

      try {
        await userService.update({ ...data, id: parseInt(token?.sub || '') })

        flagcard.add({
          title: 'Kullanıcı güncellendi',
          children: 'Geçerli kullanıcı başarıyla güncellendi.',
        })
      } catch {
        popup.add({
          title: 'Kullanıcı güncellenemedi',
          children:
            'Kullanıcı güncellenirken bir sorun oluştu, lütfen tekrar deneyin.',
        })
      }

      setIsLoading(false)
    }
  }

  const getValues = useCallback(async () => {
    const id = parseInt(token?.sub || '')

    try {
      const response = await userService.getById(id)

      setValues(response.data)
    } catch {
      const popupId = uid()

      const onClose = () => {
        popup.remove(popupId)
      }

      popup.add({
        id: popupId,
        title: 'Profil getirilemedi',
        children:
          'Profil bilgisi getirilirken bi sorun oluştu, lütfen sayfayı yenileyip tekrar deneyin.',
        onClose,
        actions: [
          {
            appearance: 'primary',
            text: 'Geri dön',
            onClick: onClose,
          },
        ],
      })
    }

    setIsReady(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getValues()
  }, [getValues])

  return (
    <AccountLayout
      isLoading={!isReady}
      header={
        <PageHeader
          bottomBar={<p>Kişisel bilgilerinizi görüntüleyin ve güncelleyin.</p>}
        >
          Profile
        </PageHeader>
      }
    >
      <UserForm
        loading={isLoading}
        values={values}
        operation="update"
        onSubmit={handleUpdate}
      />
    </AccountLayout>
  )
}

export default Profile
