import { useState, useEffect } from 'react'
import { SearchboxOptionProps } from '@zera-admin/searchbox'

import definitionService from 'services/http/backoffice/definition'

const useDefinitions = () => {
  const [definitions, setDefinitions] = useState<SearchboxOptionProps[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    getDefinitions()
  }, [])

  const getDefinition = (id?: number, name?: string) => {
    if (id !== null && id !== undefined) {
      return {
        label:
          (definitions.find((definition) => definition.value === id)
            ?.label as string) ||
          name ||
          '',
        value: id,
      }
    }
    return null
  }

  const getDefinitions = async (value?: string) => {
    setIsLoading(true)

    const response = await definitionService.getAllDefinitions(value)
    const mapped = response.data.map((source) => ({
      label: source.crawlProperties.Value,
      value: source.crawlProperties.Value,
    }))

    setDefinitions(mapped)
    setIsLoading(false)

    return mapped
  }

  return {
    definitions,
    getDefinitions,
    getDefinition,
    isDefinitionsLoading: isLoading,
  }
}

export default useDefinitions
