import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Row, Column } from '@zera-admin/page'
import Form, {
  FormFooter,
  FormHookSubmitProps,
  FormSection,
  useForm,
} from '@zera-admin/form'
import Input from '@zera-admin/input'
import SearchBox, { SearchboxOptionProps } from '@zera-admin/searchbox'

import { validation } from 'app/functions'
import categoryTypesService, {
  ClassificationCategory,
} from 'services/http/dataset/classification-category'

import { ClassificationCategoryFormProps } from './types'

const validations = {
  name: yup.string().required('Kategori adı girmelisiniz'),
}

const ClassificationCategoryForm: React.FunctionComponent<ClassificationCategoryFormProps> =
  ({ actions = [], onError, onSubmit, values }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [categoryTypes, setCategoryTypes] = useState<SearchboxOptionProps[]>(
      []
    )

    useEffect(() => {
      getCategoryTypes()
    }, [])

    const getCategoryTypes = async (value?: string) => {
      setIsLoading(true)

      const response = await categoryTypesService.get(value)
      const mapped = response.data?.list.map((categoryType) => ({
        label: categoryType.name,
        value: categoryType.id as number,
      }))

      setCategoryTypes(mapped)
      setIsLoading(false)

      return mapped
    }

    const form = useForm<ClassificationCategory>({
      initialValues: values,
      onSubmit: (props) => handleSubmit(props),
      onValidate: async (values, prop) =>
        await validation(validations, values, prop),
    })

    const handleSubmit = (
      props: FormHookSubmitProps<ClassificationCategory>
    ) => {
      if (!props.errors) {
        if (onSubmit) {
          onSubmit(props.values, () => {
            if (props.handleReset) {
              props.handleReset()
            }
          })
        }
      } else {
        if (onError) {
          onError(props.errors)
        }
      }
    }

    const getCategoryTypeValue = () => {
      if (form.values.parentId) {
        return {
          label:
            form.values.parentName ||
            (categoryTypes.find(
              (categoryType) => categoryType.value === form.values.parentId
            )?.label as string),
          value: form.values.parentId,
        }
      }
      return null
    }

    return (
      <Form onSubmit={form.handleSubmit}>
        <FormSection
          title="Genel bilgiler"
          description="Form üzerinden aşağıdaki genel bilgileri doldurunuz"
        >
          <Row xs={{ direction: 'column' }}>
            <Column>
              <Input
                description="Geçerli bir kategori adı giriniz"
                errorMessage={form.errors.name}
                label="Kategori adı"
                name="name"
                placeholder="Örnek metin"
                value={form.values.name}
                onChange={form.handleChange}
              />
            </Column>
            <Column>
              <SearchBox
                description="Varsa geçerli bir üst kategori seçimi yapınız"
                errorMessage={form.errors.parentId}
                label="Üst kategori"
                name="parentId"
                options={categoryTypes}
                value={getCategoryTypeValue()}
                onFetchOptions={(value) => getCategoryTypes(value)}
                onChange={(props) =>
                  form.handleFieldChange(
                    'parentId',
                    (props as SearchboxOptionProps).value
                  )
                }
                placeholder="Seçim yapın"
                isLoading={isLoading}
              />
            </Column>
          </Row>
        </FormSection>
        <FormFooter>{actions}</FormFooter>
      </Form>
    )
  }

export default ClassificationCategoryForm
