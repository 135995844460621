import styled from 'styled-components'

export const InfluentialPeoplesTweetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .tweet {
    position: relative;
    width: 100%;
    height: fit-content;
  }

  .add-tweet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    gap: 1rem;
    input {
      width: 40%;
      padding: 0.5rem;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
    }

    button {
      background-color: #4caf50;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }

  .delete-tweet {
    background-color: red;
    top: 0;
    right: 0;
    margin: 1rem;

    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    position: absolute;
  }

  .update-btn {
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
    align-items: start;
  }

  &.print {
    align-items: center;
    align-self: center;
  }

  .influential-peoples-tweet-row .col > div:first-child {
    height: auto !important;
  }
  .tweet-analysis {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #1da4ea;
    border-radius: 20px;
    height: 85%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    h1 {
      font-size: 26px;
      line-height: 33px;
      font-weight: 600;
      color: #2e2f31;
      padding: 1rem 1.3rem 0 1.3rem;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: normal;
      color: #2e2f31;
      max-height: 200px;
      padding: 0 1.3rem;
    }
  }

  .agenda-tweet-body {
    height: fit-content;
    .agenda-tweet-body-post-image {
      width: 50%;
    }
  }

  .agenda-tweet-body-post-text {
    -webkit-line-clamp: 2 !important;
  }

  & > .row:first-child {
    margin-bottom: 0.5rem;
  }
  & > .row:last-child {
    height: 65%;

    .col {
      height: 100%;
      & > div:first-child {
        height: 100%;
      }
    }
  }
`
